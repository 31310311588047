import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "../Admin_Dashboard/Updt_Manual_Payment.css";
import AdminDashboard from './AdminDashboard';

const Manual_Fee_Updt = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { student } = location.state || {};
  
  const [formData, setFormData] = useState({
    orderId: '',
    txnId: '',
    bankTxnId: '',
    paymentMode: '',
    txnDate: '',
    txnAmount: '',
    responseMessage: '',
    status: '',
    studentId: student ? student._id : '',
    name: student ? student.name : '',
    randomId: student ? student.randomId : '',
    email: student ? student.email : '',
    mobile: student ? student.mobile : '',
    fathersname: student ? student.fathersname : '',
    mothersname: student ? student.mothersname : '',
    courseName: student ? student.courseName : '',
    courseType: student ? student.courseType : '',
    courseBranch: student ? student.courseBranch : '',
    session: student ? student.session : '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://sssutms.ac.in/apitest/v1/save-payment', formData);
      const payment = response.data.payment;
      navigate('/manual/fee/receipt', { state: { student, payment } });
    } catch (error) {
      console.error(error);
      alert('Failed to save payment information');
    }
  };
  
  return (
    <>
    <AdminDashboard/>
    <div className="payment-form-container">
      <h2 className="form-title">Payment Information</h2>
      <form onSubmit={handleSubmit} className="payment-form">
        {Object.keys(formData).map((key) => (
          key !== 'status' && key !== 'studentId' && (
            <div key={key} className="form-group">
              <label className="form-label">{key.replace(/([A-Z])/g, ' $1')}</label>
              <input
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className="form-input"
                required
              />
            </div>
          )
        ))}
        <button type="submit" className="submit-button">
          Save Payment
        </button>
      </form>
    </div>
    </>
  );
};

export default Manual_Fee_Updt;
