


// import Nav from "../Nav";
// import Footerpage from "../Footerpage";
// import {Link} from "react-router-dom"

// function BHMCTsyllabus() {
//   return (
//     <>
//     <Nav />
//       <style>
//         {`
//           .ddd {
//             margin: 10px auto; /* Center the table */
//             padding: 30px;
//             overflow-x: auto; /* Allow horizontal scrolling on small screens */
//             counter-reset: examples approaches;
//             background-color: Gainsboro;
//             color: #333;
//             font-size: 93.75%;
//             line-height: 1.5;
//             max-width: 1680px;
//           }

//           table {
//             width: 70%; /* Make the table fill the container */
//             border-collapse: collapse; /* Remove default spacing between table cells */
//           }

//           th, td {
//             padding: .5em;
//             border: 1px solid #999;
//           }

//           @media screen and (max-width: 768px) {
//             /* Apply responsive styles for screens smaller than 768px */
//             .ddd {
//               overflow-x: auto;
//             }

//             table {
//               max-width: 100%; /* Allow horizontal scrolling on small screens */
//             }
//           }
//           h2 {
//             text-align: center;
//             background-color:#14adad;
//             // Width : 100%;
//             // margin-left:10px;
//             border-radius: 25px;
//             font-size: 30px; /* Default font size */
//             margin: 0; /* Remove default margin */
           
//           }
//           @media screen and (max-width: 600px) {
//             h2 {
//                 font-size: 18px; /* Adjust font size for smaller screens */
//             }
//         }
//         @media screen and (max-width: 768px) {
//           table {
//             width: 100%;
//           }
//           table thead {
//             display: none;
//           }
//           table, table tbody, table tr, table td {
//             display: block;
//             width: 100%;
//           }
//           table tr {
//             margin-bottom: 15px;
//             display: block;
//             border: 1px solid #ccc;
//           }
//           table td {
//             text-align: left;
//             padding: 10px;
//           }
//         }
//         `}
//       </style>
    
//       <div className="ddd" style={{ color: "black" }}>
//         <h2>B.H.M.C.T. </h2>
//         <br />
//         <h4 style={{ textAlign: "center", textDecoration: "underline" }}>
//           <b>Bachelor of Hotel management and Catering Technology</b>
//         </h4>{" "}
//         <br />
//         <table style={{ marginLeft: "15%", textAlign: "center" }}>
//           <thead style={{ backgroundColor: "SteelBlue" }}>
//             <tr>
//               <th scope="col">
//                 <h6>
//                   <b>Choice Based Credit System(CBCS)</b>
//                 </h6>
//               </th>
//               <th scope="col">
//                 <h6>
//                   <b>Non-CBCS </b>
//                 </h6>
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
           
//               <th scope="row">
//               <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/BHMCT_ISYLCBCS.pdf">
//              First Semester <br/> (wef. Academic Session 2016-17){" "}</Link>
//               </th>
             
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/SyllabusIIIsem/Syllabus2015/SYHM_I.pdf">First Semester</Link> </th>
//             </tr>

//             <tr>
//               <th scope="row">
//                 <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/BHMCTII_SYL.pdf"> Second Semester <br/> (wef. Academic Session 2016-17){" "}</Link>
               
//               </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/SyllabusIIIsem/Syllabus2015/SYHM_II.pdf">Second Semester</Link></th>
//             </tr>

//             <tr>
//               <th scope="row">
//                 {" "}
//                 <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYHM_III.pdf">  Third Semester <br/> (wef. Academic Session 2017-18){" "}</Link>
              
//               </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/SyllabusIIIsem/Syllabus2015/SYHM_III.pdf">Third Semester</Link></th>
//             </tr>

//             <tr>
//               <th scope="row">
//                 <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYBHMCT_IV.pdf">    Fourth Semester <br/> (wef. Academic Session 2017-18)</Link>
            
//               </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/syllsbus_iv_sem/BHMCT123SEM.pdf">Fourth Semester </Link></th>
//             </tr>

//             <tr>
//               <th scope="row">
//                 {" "}
//                 <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYBHMCT_5thsem.pdf">  Fifth Semester <br/> (wef. Academic Session 2017-18){" "}</Link>
              
//               </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/BEVSEMSY/BHMCTSYV.pdf">Fifth Semester</Link></th>
//             </tr>

//             <tr>
//               <th scope="row">
//                 {" "}
//                 <Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYBHMCT_6thsem.pdf">    Sixth Semester  <br/>(wef. Academic Session 2018-19 ){" "}</Link>
            
//               </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/6%20sem%20syllabus/BHMCT_VISYL.pdf">Six Semester </Link></th>
//             </tr>

//             <tr>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYBHMCT_7thsem.pdf">Seven Semester</Link>  </th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/7%20sem%20syllabus/SYHM_VII.pdf">Seven Semester</Link></th>
//             </tr>

//             <tr>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/CBCS%20SYLLABUS/Syllabus%20HMCT/SYBHMCT_8thsem.pdf">Eight Semester </Link></th>
//               <th scope="row"><Link to = "https://www.sssutms.co.in/cms/Linkreas/Website/Files/Link/SYLLABUS/7%20sem%20syllabus/BHMCT_VIIIr.pdf">Eight Semester</Link></th>
//             </tr>
//           </tbody>
//         </table>{" "}
//         <br />
//         <br />
//       </div>
// <Footerpage />
   
//     </>
//   );
// }

// export default BHMCTsyllabus;


import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from 'react-router-dom';


function BHMCTsyllabus() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:40px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '20px' }}>BHMCT</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">

            <br />

            {/* <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '20px' }}>Scheme: B. Pharmacy </h5> */}

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
    margin-left:20px;
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>

 <br></br>
            <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '20px' }}>Syllabus:  Bachelor of Hotel management and Catering Technology </h5>
            <table style={{ width: '90%', marginLeft: '70px', marginTop: '20px', borderCollapse: 'collapse' }}>
              <tbody>
            <tr><th>S.No.</th>
            <th>Choice Based Credit System </th>
          </tr><tr><td>1</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_I SYL.pdf'>First Semester</Link></td> </tr><tr><td>2</td>
<td> <Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_II SYL.pdf'>Second Semester</Link></td> </tr><tr><td>3</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_III SYL.pdf'>Third Semester</Link></td> </tr><tr><td>4</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_IV SYL.pdf'>Fourth Semester</Link></td>


</tr>
<tr><td>5</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_V SYL.pdf'>  Fifth Semester</Link></td></tr>
<tr><td>6</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_VI SYL.pdf'>  Sixth Semester</Link></td></tr>

<tr><td>7</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_VII SYL.pdf'> Seventh Semester</Link></td></tr>

<tr><td>8</td>
<td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/SyllabusPDF/BHMCT/BHMCT_VIII SYL.pdf'> Eighth Semester</Link></td></tr>

                </tbody></table>
           <br></br>
           <br></br>




          
           
          </div>



          <div className="col-lg-3">

<ul className='list-icon' >
  <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>Syllabus</h4>
  <br></br> 
   <li className='lli'>
    <Link to='/B.E-Syllabus'>
      <i class="fa fa-adjust" aria-hidden="true"></i> BE  </Link> </li>
  
    
      <li className='lli'>
<Link to='/Pharmacy-syllabus'>
      <i class="fa fa-adjust" aria-hidden="true"></i> Pharmacy</Link> </li>
      <li className='lli'>
      <Link to='/MTECH-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> MTECH </Link></li>
      <li className='lli'>
      <Link to='/BHMCT-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> BHMCT</Link> </li>
      <li className='lli'>
      <Link to='/MBA-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> MBA </Link></li>
      <li className='lli'>
      <Link to='/MCA-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> MCA </Link></li>
      <li className='lli'>
      <Link to='/Education-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> Education </Link></li>
      <li className='lli'>
      <Link to='/PhysicalEducation-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> Physical Education</Link> </li>
      <li className='lli'>
      <Link to='/BScHonsAG-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> B.Sc.(Hons).(Ag)</Link> </li>
      <li className='lli'>
      <Link to='/BHMS-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> BHMS</Link> </li>
      <li className='lli'>
      <Link to='/Paramedical-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> Paramedical</Link> </li>
      <li className='lli'>
      <Link to='/PolytechnicEngineerin-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> Polytechnic Engineering</Link> </li>
      <li className='lli'>
      <Link to='/BLibISc -syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i> B.Lib.I.Sc </Link></li>
      <li className='lli'>
      <Link to='/Bacheloroflaws-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i>Bachelor of Laws </Link> </li>
      <li className='lli'>
      <Link to='/BScHMCS-syllabus'>

      <i class="fa fa-adjust" aria-hidden="true"></i>B.Sc.[HMCS] </Link> </li>
    



</ul>
</div>
        </div>

      </div>
      <br></br>

      <Footerpage /></>
  )
}

export default BHMCTsyllabus