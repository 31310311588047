import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Eye, EyeOff } from "lucide-react";
import axios from "axios";
import StudentDashboard from "../../AdminComponent/StudentDashboard";

const Student_Rst_Pass = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  const Id = user._id
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v1/reset_password",
        {
          oldPassword,
          newPassword,
          Id
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage("server error");
      }
    }
  };
  return (
    <>
      <style>
        {`
        .password {
  width: 90%;
  padding: 15px 12px;
  margin-bottom: 5px;
  border: 1px solid #e5e5e5;
  border-bottom: 2px solid #ddd;
  background: rgba(255, 255, 255, 0.2) !important;
  color: #555;
}

.unmask {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #aaa;
}

.unmask:hover {
  color: #06a;
}
//   html,body{
//   min-height:100%
// }
// body {
//   background: #f6f6f6 url(https://goo.gl/1yhE3P) top center no-repeat;
//   background-size:cover;
//   font-family: "Open Sans", sans-serif;
//   font-size: 14px;
//   margin:0
// }
#box {
  width: 400px;
  margin: 10% auto;
  text-align: center;
  background:rgba(255,255,255,0.6);
  padding:20px 50px;
  box-sizing:border-box;
  box-shadow:0 3px 12px rgba(0,0,0,0.2);
  border-radius:2%
}


.but{
height:40px;
width:110px;
padding:10px;
margin-top:10px;}
h1 {
  margin-bottom: 1.5em;
  font-size: 30px;
  color: #484548;
  font-weight: 100;
}
 h1 span, small {
  display:block;
  font-size: 14px;
  color: #989598;
 }
small{ font-style: italic; 
  font-size: 11px;}
form p { position: relative; }

.password {
  width: 90%;
  padding: 15px 12px;
  margin-bottom: 5px;
  border: 1px solid #e5e5e5;
  border-bottom: 2px solid #ddd;
  background: rgba(255,255,255,0.2) !important;
  color: #555;
}
.password + .unmask {
  position: absolute;
  right: 9%;
  top: 27px;
  width: 25px;
  height: 25px;
  background: transparent;
  border-radius: 50%;
  cursor:pointer;
  border: none;
  font-family:'fontawesome';
  font-size:14px;
  line-height:24px;
  -webkit-appearance:none;
  outline:none
}
.password + .unmask:before {
  content: "\f06e";
  position:absolute;
  top:0; left:0;
  width: 25px;
  height: 25px;
  background: rgba(205,205,205,0.2);
  z-index:1;
  color:#aaa;
  border:2px solid;
  border-radius: 50%;
}
.password[type="text"] + .unmask:before {
 content:"\f070";
  background: rgba(105,205,255,0.2);
  color:#06a
}
#valid{
  font-size:12px;
  color:#daa;
  height:15px
}
#strong{
  height:20px;
  font-size:12px;
  color:#daa;
  text-transform:capitalize;
  background:rgba(205,205,205,0.1);
  border-radius:5px;
  overflow:hidden
}

#strong span{  
  display:block;
  box-shadow:0 0 0 #fff inset;
  height:100%;
  transition:all 0.8s
}
#strong .weak{
   box-shadow:5em 0 0 #daa inset;
}
#strong .medium{
   color:#da6;
   box-shadow:10em 0 0 #da6 inset
}
#strong .strong{
   color:#595;
   box-shadow:50em 0 0 #ada inset
}




/* Media query for smaller screens */
@media (max-width: 420px) {



#box{
margin-left:20px;
margin-top:90px;}}`}
      </style>
      <StudentDashboard/>

      <div id="box">
        <form id="myform-search" method="post" autoComplete="off">
          <h1>
            Reset Password <span>choose a good one!</span>
          </h1>
          <p>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Enter Old Password"
              id="p"
              className="password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            {passwordVisible ? (
              <EyeOff className="unmask" onClick={togglePasswordVisibility} />
            ) : (
              <Eye className="unmask" onClick={togglePasswordVisibility} />
            )}
          </p>
          <p>
            <input
              type={confirmPasswordVisible ? "text" : "password"}
              placeholder="Enter New Password"
              id="p-c"
              className="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            {confirmPasswordVisible ? (
              <EyeOff
                className="unmask"
                onClick={toggleConfirmPasswordVisibility}
              />
            ) : (
              <Eye
                className="unmask"
                onClick={toggleConfirmPasswordVisibility}
              />
            )}

            <Button className="but" onClick={handleSubmit} >Reset</Button>
            {message && <p>{message}</p>}
            <small style={{ color: "red" }}>
              Must be 6+ characters long and contain at least 1 upper case
              letter, 1 number, 1 special character
            </small>
          </p>
        </form>
      </div>
    </>
  );
};

export default Student_Rst_Pass;
