// import React, { useState, useEffect } from "react";
// import Spinner from "react-bootstrap/Spinner";
// import axios from "axios";
// import { Form, Row, Col, Button } from "react-bootstrap";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import { CardContent } from "@mui/material";
// import jsPDF from "jspdf";
// import StudentDashboard from "../../../AdminComponent/StudentDashboard";

// const theme = createTheme();

// const EStudent_Slip = () => {
//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//   const isEnrolledPaid = userData.isEnrollPaid;

//   const studentId = userData._id;
//   const randomId = userData.randomId;
//   const [approvedStudents, setApprovedStudents] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPaymentDetails = async () => {
//       try {
//         const { data } = await axios.get(
//           "http://localhost:7786/apitest/student/get-payment-details2",
//           {
//             params: { id: studentId, randomId },
//           }
//         );

//         const paymentDetails = {};

//         if (data.Epravesh_payment_details) {

//           paymentDetails.epravesh = data.Epravesh_payment_details;
//         }

//         if (data.provisional_payment_details) {
//           paymentDetails.epravesh = data.provisional_payment_details;
//         }

//         if (data.enrollment_fee_details) {
//           paymentDetails.enrollment = [data.enrollment_fee_details];
//         }
//         console.log(paymentDetails, "paymentdetails");
//         setApprovedStudents(paymentDetails);
//         setLoading(false);
//       } catch (err) {
//         console.error("Error while fetching payment details:", err);
//         setError("Failed to fetch payment details");
//         setLoading(false);
//       }
//     };

//     fetchPaymentDetails();
//   }, [studentId]);

//   const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

//   const generatePDF = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0); // Set text color to red for the title
//     doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0); // Set text color back to black

//     // Set the initial y position
//     let yPos = 60;

//     // Define the line height
//     const lineHeight = 10;

//     doc.text(
//       `                                     Name of Candidate  :  ${approvedStudents.epravesh[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Father's Name          :  ${approvedStudents.epravesh[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Email                         :  ${approvedStudents.epravesh[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Mobile                       :  ${approvedStudents.epravesh[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Type            :  ${approvedStudents.epravesh[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Name           :  ${approvedStudents.epravesh[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Branch        :  ${approvedStudents.epravesh[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     TXN ID                      :   ${approvedStudents.epravesh[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     ORDER ID                :  ${approvedStudents.epravesh[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     PAYMENT MODE    :  ${approvedStudents.epravesh[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     BANKTXN ID           :  ${approvedStudents.epravesh[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Paid Amount           :  ${approvedStudents.epravesh[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Date & Time            :  ${approvedStudents.epravesh[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.epravesh[0].name} EPravesh Fee`);
//   };
//   const generatePDF2 = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0); // Set text color to red for the title
//     doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0); // Set text color back to black

//     let yPos = 60;

//     const lineHeight = 10;

//     doc.text(
//       `                                     Name of Candidate  :  ${approvedStudents.epravesh[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Father's Name          :  ${approvedStudents.epravesh[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Email                         :  ${approvedStudents.epravesh[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Mobile                       :  ${approvedStudents.epravesh[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Type            :  ${approvedStudents.epravesh[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Name           :  ${approvedStudents.epravesh[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Course Branch        :  ${approvedStudents.epravesh[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     TXN ID                      :   ${approvedStudents.epravesh[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     ORDER ID                :  ${approvedStudents.epravesh[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     PAYMENT MODE    :  ${approvedStudents.epravesh[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     BANKTXN ID           :  ${approvedStudents.epravesh[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Paid Amount           :  ${approvedStudents.epravesh[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                     Date & Time            :  ${approvedStudents.epravesh[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.epravesh[0].name} EPravesh Fee`);
//   };
//   const generatePDF1 = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0); // Set text color to red for the title
//     doc.text("Enrollment Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0); // Set text color back to black

//     // Set the initial y position
//     let yPos = 60;

//     // Define the line height
//     const lineHeight = 10;

//     doc.text(
//       `                                    Name of Candidate  :   ${approvedStudents.enrollment[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Father's Name          :  ${approvedStudents.enrollment[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Email                         :  ${approvedStudents.enrollment[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Mobile                       :  ${approvedStudents.enrollment[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Type            :  ${approvedStudents.enrollment[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Name           :  ${approvedStudents.enrollment[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Branch        :  ${approvedStudents.enrollment[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    TXN ID                      :  ${approvedStudents.enrollment[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    ORDER ID                :  ${approvedStudents.enrollment[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    PAYMENT MODE    :  ${approvedStudents.enrollment[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    BANKTXN ID           :  ${approvedStudents.enrollment[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Paid Amount           :  ${approvedStudents.enrollment[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Date & Time            :  ${approvedStudents.enrollment[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.enrollment[0].name} Enrollment Fee`);
//   };

//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         <StudentDashboard />

//         <style>
//           {`
//         .conttable{
//             background-color: #e8e5d5;
//             width: 90%;
//             margin-left: 120px;
//             margin-right: auto;
//             margin-top: 80px;
//             text-align: center;
//             max-width: 1200px;
//         }
// .h4d{
//   font-weight: inherit;
//   height:60px;
//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       padding: 5px;

//                       }

//         .shadow{
//      margin-top: 80px;
//       margin-left:8%}

//         @media screen and (max-width: 480px){
//           .custom-card-content{
//             width: 100%; /* Adjust the percentage as needed */
//             max-width: 1200px; /* Set a maximum width */
//             margin: 0 auto; /* Center align */
//           }

//             .shadow{
//   margin-top: -20px;
//       margin-left:0%}

//         .h4d{
//   font-weight: inherit;
//   height:60px;

//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       font-size:18px;
//                       padding: 5px;
//                       margin-top:70px;

//                       }

//          .form-group-custom {
//     flex: 0 0 100%; /* Make each form group take full width */
//     max-width: 100%;
//   }

//         .forum{
//         margin-left:10px;}              }
//     `}
//         </style>

//         {loading ? (
//           <div
//             className="d-flex justify-content-center align-items-center"
//             style={{ minHeight: "200px", marginTop: "40px" }}
//           >
//             <Spinner animation="border" role="status">
//               <span className="visually-hidden">Loading...</span>
//             </Spinner>
//           </div>
//         ) : error ? (
//           <span>{error}</span>
//         ) : Object.keys(approvedStudents).length === 0 ? (
//           <span>No Slip Available</span>
//         ) : (
//           <>
//             {approvedStudents.epravesh &&
//               approvedStudents.epravesh.length > 0 && (
//                 <CardContent className="shadow p-3 bg-body rounded">
//                   <h4 className="h4d"> Download E-Pravesh Registration Receipt </h4>
//                   <Form style={{ marginTop: "1%" }}>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="courseType"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Type</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseType"
//                           value={approvedStudents.epravesh[0].courseType || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseName"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseName"
//                           value={approvedStudents.epravesh[0].courseName || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseBranch"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Branch</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseBranch"
//                           value={
//                             approvedStudents.epravesh[0].courseBranch || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="name"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           value={approvedStudents.epravesh[0].name || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="fathersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Father's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="fathersname"
//                           value={approvedStudents.epravesh[0].fathersname || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mothersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mother's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mothersname"
//                           value={approvedStudents.epravesh[0].mothersname || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     {/* <Row className="mb-3">

//               </Row> */}

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="email"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Email</Form.Label>
//                         <Form.Control
//                           type="email"
//                           name="email"
//                           value={approvedStudents.epravesh[0].email || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mobile"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mobile</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mobile"
//                           value={approvedStudents.epravesh[0].mobile || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="orderId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Order ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="orderId"
//                           value={approvedStudents.epravesh[0].orderId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnId"
//                           value={approvedStudents.epravesh[0].txnId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="randomId">
//                         <Form.Label>Student ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="randomId"
//                           value={approvedStudents.epravesh[0].randomId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="paymentMode">
//                         <Form.Label>Payment Mode</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="paymentMode"
//                           value={approvedStudents.epravesh[0].paymentMode || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnDate"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction Date</Form.Label>
//                         <Form.Control
//                           type="dd-mm-yyyy"
//                           name="txnDate"
//                           value={approvedStudents.epravesh[0].txnDate || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="txnAmount"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction Amount</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnAmount"
//                           value={approvedStudents.epravesh[0].txnAmount || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="bankTxnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Bank Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="bankTxnId"
//                           value={approvedStudents.epravesh[0].bankTxnId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="status"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Status</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="status"
//                           value={
//                             approvedStudents.epravesh[0].status || "TXN_SUCCESS"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Button
//                       type="submit"
//                       style={{ marginLeft: "10px", backgroundColor: "#243642" }}
//                       onClick={generatePDF}
//                     >
//                       Download Receipt
//                     </Button>
//                   </Form>
//                 </CardContent>
//               )}
//             {approvedStudents.epravesh &&
//               approvedStudents.epravesh.length > 0 && (
//                 <CardContent className="shadow p-3 bg-body rounded">
//                   <h4 className="h4d">Provisional Payment </h4>
//                   <Form className="forum" style={{ marginTop: "1%" }}>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="session"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Admission Session</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="session"
//                           value={
//                             approvedStudents.enrollment[0].session || "2024"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="courseType"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Type</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseType"
//                           value={
//                             approvedStudents.enrollment[0].courseType || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseName"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseName"
//                           value={
//                             approvedStudents.enrollment[0].courseName || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseBranch"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Branch</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseBranch"
//                           value={
//                             approvedStudents.enrollment[0].courseBranch || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="name"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           value={approvedStudents.enrollment[0].name || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="fathersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Father's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="fathersname"
//                           value={
//                             approvedStudents.enrollment[0].fathersname || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mothersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mother's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mothersname"
//                           value={
//                             approvedStudents.enrollment[0].mothersname || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     {/* <Row className="mb-3">

//               </Row> */}

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="email"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Email</Form.Label>
//                         <Form.Control
//                           type="email"
//                           name="email"
//                           value={approvedStudents.enrollment[0].email || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mobile"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mobile</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mobile"
//                           value={approvedStudents.enrollment[0].mobile || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="orderId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Order ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="orderId"
//                           value={approvedStudents.enrollment[0].orderId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnId"
//                           value={approvedStudents.enrollment[0].txnId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="randomId">
//                         <Form.Label>Student ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="randomId"
//                           value={approvedStudents.enrollment[0].randomId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="paymentMode">
//                         <Form.Label>Payment Mode</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="paymentMode"
//                           value={
//                             approvedStudents.enrollment[0].paymentMode || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnDate"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction Date</Form.Label>
//                         <Form.Control
//                           type="dd-mm-yyyy"
//                           name="txnDate"
//                           value={approvedStudents.enrollment[0].txnDate || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="txnAmount">
//                         <Form.Label>Transaction Amount</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnAmount"
//                           value={approvedStudents.enrollment[0].txnAmount || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="bankTxnId">
//                         <Form.Label>Bank Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="bankTxnId"
//                           value={approvedStudents.enrollment[0].bankTxnId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="status">
//                         <Form.Label>Status</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="status"
//                           value={
//                             approvedStudents.enrollment[0].status ||
//                             "TXN_SUCCESS"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Button
//                       variant="primary"
//                       type="submit"
//                       style={{ marginLeft: "10px", backgroundColor: "#243642" }}
//                       onClick={generatePDF1}
//                     >
//                       Download Receipt
//                     </Button>
//                   </Form>
//                 </CardContent>
//               )}
//             {isEnrolledPaid &&
//               approvedStudents.enrollment &&
//               approvedStudents.enrollment.length > 0 && (
//                 <CardContent className="shadow p-3 bg-body rounded">
//                   <h4 className="h4d">Enrollment Fee Payment Receipt</h4>
//                   <Form className="forum" style={{ marginTop: "1%" }}>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="session"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Admission Session</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="session"
//                           value={
//                             approvedStudents.enrollment[0].session || "2024"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="courseType"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Type</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseType"
//                           value={
//                             approvedStudents.enrollment[0].courseType || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseName"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseName"
//                           value={
//                             approvedStudents.enrollment[0].courseName || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseBranch"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Branch</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseBranch"
//                           value={
//                             approvedStudents.enrollment[0].courseBranch || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="name"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           value={approvedStudents.enrollment[0].name || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="fathersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Father's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="fathersname"
//                           value={
//                             approvedStudents.enrollment[0].fathersname || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mothersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mother's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mothersname"
//                           value={
//                             approvedStudents.enrollment[0].mothersname || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     {/* <Row className="mb-3">

//               </Row> */}

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="email"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Email</Form.Label>
//                         <Form.Control
//                           type="email"
//                           name="email"
//                           value={approvedStudents.enrollment[0].email || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mobile"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mobile</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mobile"
//                           value={approvedStudents.enrollment[0].mobile || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="orderId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Order ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="orderId"
//                           value={approvedStudents.enrollment[0].orderId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnId"
//                           value={approvedStudents.enrollment[0].txnId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="randomId">
//                         <Form.Label>Student ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="randomId"
//                           value={approvedStudents.enrollment[0].randomId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="paymentMode">
//                         <Form.Label>Payment Mode</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="paymentMode"
//                           value={
//                             approvedStudents.enrollment[0].paymentMode || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnDate"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction Date</Form.Label>
//                         <Form.Control
//                           type="dd-mm-yyyy"
//                           name="txnDate"
//                           value={approvedStudents.enrollment[0].txnDate || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="txnAmount">
//                         <Form.Label>Transaction Amount</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnAmount"
//                           value={approvedStudents.enrollment[0].txnAmount || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="bankTxnId">
//                         <Form.Label>Bank Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="bankTxnId"
//                           value={approvedStudents.enrollment[0].bankTxnId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="status">
//                         <Form.Label>Status</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="status"
//                           value={
//                             approvedStudents.enrollment[0].status ||
//                             "TXN_SUCCESS"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Button
//                       variant="primary"
//                       type="submit"
//                       style={{ marginLeft: "10px", backgroundColor: "#243642" }}
//                       onClick={generatePDF1}
//                     >
//                       Download Receipt
//                     </Button>
//                   </Form>
//                 </CardContent>
//               )}
//           </>
//         )}
//       </ThemeProvider>
//     </>
//   );
// };

// export default EStudent_Slip;

import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { Form, Row, Col, Button } from "react-bootstrap";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CardContent } from "@mui/material";
import jsPDF from "jspdf";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";

const theme = createTheme();

const EStudent_Slip = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const isEnrolledPaid = userData.isEnrollPaid;

  console.log(isEnrolledPaid, "isenrollrkefkek");

  const studentId = userData._id;

  const [approvedStudents, setApprovedStudents] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const { data } = await axios.get(
          "https://sssutms.ac.in/apitest/student/get-payment-details2",
          {
            params: { id: studentId },
          }
        );

        const paymentDetails = {};

        if (data.Epravesh_payment_details) {
          paymentDetails.epravesh = data.Epravesh_payment_details;
        }

        if (data.enrollment_fee_details) {
          paymentDetails.enrollment = [data.enrollment_fee_details];
        }

        setApprovedStudents(paymentDetails);
        setLoading(false);
      } catch (err) {
        console.error("Error while fetching payment details:", err);
        setError("Failed to fetch payment details");
        setLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [studentId]);

  const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addImage(logo, "JPEG", 3, 5, 30, 28);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0); // Set text color to red for the title
    doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Set text color back to black

    // Set the initial y position
    let yPos = 60;

    // Define the line height
    const lineHeight = 10;

    doc.text(
      `                                     Name of Candidate  :  ${approvedStudents.epravesh[0].name}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Father's Name          :  ${approvedStudents.epravesh[0].fathersname}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Email                         :  ${approvedStudents.epravesh[0].email}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Mobile                       :  ${approvedStudents.epravesh[0].mobile}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Type            :  ${approvedStudents.epravesh[0].courseType}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Name           :  ${approvedStudents.epravesh[0].courseName}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Branch        :  ${approvedStudents.epravesh[0].courseBranch}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     TXN ID                      :   ${approvedStudents.epravesh[0].txnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     ORDER ID                :  ${approvedStudents.epravesh[0].orderId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     PAYMENT MODE    :  ${approvedStudents.epravesh[0].paymentMode}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     BANKTXN ID           :  ${approvedStudents.epravesh[0].bankTxnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Paid Amount           :  ${approvedStudents.epravesh[0].txnAmount}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Date & Time            :  ${approvedStudents.epravesh[0].txnDate}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.save(`${approvedStudents.epravesh[0].name} EPravesh Fee`);
  };
  const generatePDF1 = () => {
    const doc = new jsPDF();
    doc.addImage(logo, "JPEG", 3, 5, 30, 28);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0);
    doc.text("Enrollment Fee Receipt", 118, 40, { align: "center" });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    let yPos = 60;

    const lineHeight = 10;

    doc.text(
      `                                    Name of Candidate  :   ${approvedStudents.enrollment[0].name}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Father's Name          :  ${approvedStudents.enrollment[0].fathersname}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Email                         :  ${approvedStudents.enrollment[0].email}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Mobile                       :  ${approvedStudents.enrollment[0].mobile}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Course Type            :  ${approvedStudents.enrollment[0].courseType}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Course Name           :  ${approvedStudents.enrollment[0].courseName}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Course Branch        :  ${approvedStudents.enrollment[0].courseBranch}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    TXN ID                      :  ${approvedStudents.enrollment[0].txnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    ORDER ID                :  ${approvedStudents.enrollment[0].orderId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    PAYMENT MODE    :  ${approvedStudents.enrollment[0].paymentMode}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    BANKTXN ID           :  ${approvedStudents.enrollment[0].bankTxnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Paid Amount           :  ${approvedStudents.enrollment[0].txnAmount}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                    Date & Time            :  ${approvedStudents.enrollment[0].txnDate}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.save(`${approvedStudents.enrollment[0].name} Enrollement Fee`);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <StudentDashboard />

        <style>
          {`

.radd{
 margin-left: 120px;
  margin-top: 80px;
  }

  .smallh{
  font-size:13px;
  color:#16325B;
  margin-top:-20px}
        .conttable{
            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;
        }
.h4d{
  font-weight: inherit;
  height:60px;
margin-top:-15px;
                      // background-color: #003049;
                      border-radius: 10px;
                      color: black;
                      font-weight:700;
                      padding: 5px;

                      }

        .shadow{
     margin-top: 10px;
      margin-left:8%}

        @media screen and (max-width: 480px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

.radd{
 margin-left: 20px;
  margin-top: 80px;
  }

            .shadow{
  margin-top: -60px;
      margin-left:0%}

        .h4d{
  font-weight: inherit;
  height:60px;

                      // background-color: #003049;
                      border-radius: 10px;
                      color: black;
                      font-weight:700;
                      font-size:18px;
                      padding: 5px;
                      margin-top:70px;

                      }

         .form-group-custom {
    flex: 0 0 100%; /* Make each form group take full width */
    max-width: 100%;
  }

        .forum{
        margin-left:10px;}              }
    `}
        </style>

        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "200px", marginTop: "40px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : error ? (
          <span>{error}</span>
        ) : Object.keys(approvedStudents).length === 0 ? (
          <span>No Slip Available</span>
        ) : (
          <>
            {/* Radio selection for receipt type */}
            {approvedStudents.epravesh &&
              approvedStudents.epravesh.length > 0 && (
                <>
                  <div className="radd">
                    <h3>Select Fee Receipt Type :</h3>
                    <div>
                      <label style={{ marginRight: "20px" }}>
                        <input
                          type="radio"
                          value="epravesh"
                          checked={selectedOption === "epravesh"}
                          onChange={handleOptionChange}
                        />
                        Epravesh Fee Receipt
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="enrollment"
                          checked={selectedOption === "enrollment"}
                          onChange={handleOptionChange}
                        />
                        Enrollment Fee Receipt
                      </label>
                    </div>
                  </div>
                  <hr></hr>
                  {/* E-Pravesh Receipt */}
                  {selectedOption === "epravesh" && (
                    <CardContent className="shadow p-3 bg-body rounded">
                      <h4 className="h4d" style={{ color: "#A04747" }}>
                        E-Pravesh Registration Receipt
                      </h4>
                      <h6 className="smallh">
                        Fill the below details to download your receipt.{" "}
                      </h6>
                      <Form style={{ marginTop: "1%" }}>
                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="courseType"
                            className="form-group-custom"
                          >
                            <Form.Label>Course Type</Form.Label>
                            <Form.Control
                              type="text"
                              name="courseType"
                              value={
                                approvedStudents.epravesh[0].courseType || ""
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="courseName"
                            className="form-group-custom"
                          >
                            <Form.Label>Course Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="courseName"
                              value={
                                approvedStudents.epravesh[0].courseName || ""
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="courseBranch"
                            className="form-group-custom"
                          >
                            <Form.Label>Course Branch</Form.Label>
                            <Form.Control
                              type="text"
                              name="courseBranch"
                              value={
                                approvedStudents.epravesh[0].courseBranch || ""
                              }
                            />
                          </Form.Group>
                        </Row>

                        {/* <Row className="mb-3">

      </Row> */}

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="name"
                            className="form-group-custom"
                          >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="name"
                              value={approvedStudents.epravesh[0].name || ""}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="fathersname"
                            className="form-group-custom"
                          >
                            <Form.Label>Father's Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="fathersname"
                              value={
                                approvedStudents.epravesh[0].fathersname || ""
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="mothersname"
                            className="form-group-custom"
                          >
                            <Form.Label>Mother's Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="mothersname"
                              value={
                                approvedStudents.epravesh[0].mothersname || ""
                              }
                            />
                          </Form.Group>
                        </Row>

                        {/* <Row className="mb-3">

      </Row> */}

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="email"
                            className="form-group-custom"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={approvedStudents.epravesh[0].email || ""}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="mobile"
                            className="form-group-custom"
                          >
                            <Form.Label>Mobile</Form.Label>
                            <Form.Control
                              type="text"
                              name="mobile"
                              value={approvedStudents.epravesh[0].mobile || ""}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="orderId"
                            className="form-group-custom"
                          >
                            <Form.Label>Order ID</Form.Label>
                            <Form.Control
                              type="text"
                              name="orderId"
                              value={approvedStudents.epravesh[0].orderId || ""}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="txnId"
                            className="form-group-custom"
                          >
                            <Form.Label>Transaction ID</Form.Label>
                            <Form.Control
                              type="text"
                              name="txnId"
                              value={approvedStudents.epravesh[0].txnId || ""}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} controlId="randomId">
                            <Form.Label>Student ID</Form.Label>
                            <Form.Control
                              type="text"
                              name="randomId"
                              value={
                                approvedStudents.epravesh[0].randomId || ""
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} controlId="paymentMode">
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control
                              type="text"
                              name="paymentMode"
                              value={
                                approvedStudents.epravesh[0].paymentMode || ""
                              }
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="txnDate"
                            className="form-group-custom"
                          >
                            <Form.Label>Transaction Date</Form.Label>
                            <Form.Control
                              type="dd-mm-yyyy"
                              name="txnDate"
                              value={approvedStudents.epravesh[0].txnDate || ""}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="txnAmount"
                            className="form-group-custom"
                          >
                            <Form.Label>Transaction Amount</Form.Label>
                            <Form.Control
                              type="text"
                              name="txnAmount"
                              value={
                                approvedStudents.epravesh[0].txnAmount || ""
                              }
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group
                            as={Col}
                            controlId="bankTxnId"
                            className="form-group-custom"
                          >
                            <Form.Label>Bank Transaction ID</Form.Label>
                            <Form.Control
                              type="text"
                              name="bankTxnId"
                              value={
                                approvedStudents.epravesh[0].bankTxnId || ""
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            controlId="status"
                            className="form-group-custom"
                          >
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                              type="text"
                              name="status"
                              value={
                                approvedStudents.epravesh[0].status ||
                                "TXN_SUCCESS"
                              }
                            />
                          </Form.Group>
                        </Row>

                        <Button
                          type="submit"
                          style={{
                            marginLeft: "10px",
                            backgroundColor: "#243642",
                          }}
                          onClick={generatePDF}
                        >
                          Download Receipt
                        </Button>
                      </Form>
                    </CardContent>
                  )}
                </>
              )}

            {/* Enrollment Receipt */}
            {isEnrolledPaid &&
              approvedStudents.enrollment &&
              approvedStudents.enrollment.length > 0 &&
              selectedOption === "enrollment" && (
                <CardContent className="shadow p-3 bg-body rounded">
                  <h4 className="h4d" style={{ color: "#A04747" }}>
                    Enrollment Fee Payment Receipt
                  </h4>
                  <h6 className="smallh">
                    Fill the below details to download your receipt.{" "}
                  </h6>
                  <Form className="forum" style={{ marginTop: "1%" }}>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="session"
                        className="form-group-custom"
                      >
                        <Form.Label>Admission Session</Form.Label>
                        <Form.Control
                          type="text"
                          name="session"
                          value={
                            approvedStudents.enrollment[0].session || "2024"
                          }
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="courseType"
                        className="form-group-custom"
                      >
                        <Form.Label>Course Type</Form.Label>
                        <Form.Control
                          type="text"
                          name="courseType"
                          value={
                            approvedStudents.enrollment[0].courseType || ""
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="courseName"
                        className="form-group-custom"
                      >
                        <Form.Label>Course Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="courseName"
                          value={
                            approvedStudents.enrollment[0].courseName || ""
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="courseBranch"
                        className="form-group-custom"
                      >
                        <Form.Label>Course Branch</Form.Label>
                        <Form.Control
                          type="text"
                          name="courseBranch"
                          value={
                            approvedStudents.enrollment[0].courseBranch || ""
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="name"
                        className="form-group-custom"
                      >
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          value={approvedStudents.enrollment[0].name || ""}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="fathersname"
                        className="form-group-custom"
                      >
                        <Form.Label>Father's Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="fathersname"
                          value={
                            approvedStudents.enrollment[0].fathersname || ""
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="mothersname"
                        className="form-group-custom"
                      >
                        <Form.Label>Mother's Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="mothersname"
                          value={
                            approvedStudents.enrollment[0].mothersname || ""
                          }
                        />
                      </Form.Group>
                    </Row>

                    {/* <Row className="mb-3">

   </Row> */}

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="email"
                        className="form-group-custom"
                      >
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          value={approvedStudents.enrollment[0].email || ""}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="mobile"
                        className="form-group-custom"
                      >
                        <Form.Label>Mobile</Form.Label>
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={approvedStudents.enrollment[0].mobile || ""}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        controlId="orderId"
                        className="form-group-custom"
                      >
                        <Form.Label>Order ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="orderId"
                          value={approvedStudents.enrollment[0].orderId || ""}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="txnId"
                        className="form-group-custom"
                      >
                        <Form.Label>Transaction ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="txnId"
                          value={approvedStudents.enrollment[0].txnId || ""}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="randomId">
                        <Form.Label>Student ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="randomId"
                          value={approvedStudents.enrollment[0].randomId || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="paymentMode">
                        <Form.Label>Payment Mode</Form.Label>
                        <Form.Control
                          type="text"
                          name="paymentMode"
                          value={
                            approvedStudents.enrollment[0].paymentMode || ""
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group
                        as={Col}
                        controlId="txnDate"
                        className="form-group-custom"
                      >
                        <Form.Label>Transaction Date</Form.Label>
                        <Form.Control
                          type="dd-mm-yyyy"
                          name="txnDate"
                          value={approvedStudents.enrollment[0].txnDate || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="txnAmount">
                        <Form.Label>Transaction Amount</Form.Label>
                        <Form.Control
                          type="text"
                          name="txnAmount"
                          value={approvedStudents.enrollment[0].txnAmount || ""}
                        />
                      </Form.Group>
                    </Row>

                    <Row className="mb-3">
                      <Form.Group as={Col} controlId="bankTxnId">
                        <Form.Label>Bank Transaction ID</Form.Label>
                        <Form.Control
                          type="text"
                          name="bankTxnId"
                          value={approvedStudents.enrollment[0].bankTxnId || ""}
                        />
                      </Form.Group>
                      <Form.Group as={Col} controlId="status">
                        <Form.Label>Status</Form.Label>
                        <Form.Control
                          type="text"
                          name="status"
                          value={
                            approvedStudents.enrollment[0].status ||
                            "TXN_SUCCESS"
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Button
                      variant="primary"
                      type="submit"
                      style={{ marginLeft: "10px", backgroundColor: "#243642" }}
                      onClick={generatePDF1}
                    >
                      Download Receipt
                    </Button>
                  </Form>
                </CardContent>
              )}
          </>
        )}
      </ThemeProvider>
    </>
  );
};

export default EStudent_Slip;

// import React, { useState, useEffect } from "react";
// import Spinner from "react-bootstrap/Spinner";
// import axios from "axios";
// import { Form, Row, Col, Button } from "react-bootstrap";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import {
//   Box,
//   CardContent,
//   CircularProgress,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   // Button,
// } from "@mui/material";
// import jsPDF from "jspdf";
// import StudentDashboard from "../../../AdminComponent/StudentDashboard";

// const theme = createTheme();

// const EStudent_Slip = () => {
//   const [selectedOption, setSelectedOption] = useState("");

//   const handleOptionChange = (event) => {
//     setSelectedOption(event.target.value);
//   };
//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//   const isEnrolledPaid = userData.isEnrollPaid;

//   console.log(isEnrolledPaid, "isenrollrkefkek");

//   const studentId = userData._id;

//   const [approvedStudents, setApprovedStudents] = useState({});
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPaymentDetails = async () => {
//       try {
//         const { data } = await axios.get(
//           "https://sssutms.ac.in/apitest/student/get-payment-details2",
//           {
//             params: { id: studentId },
//           }
//         );

//         const paymentDetails = {};

//         if (data.Epravesh_payment_details) {
//           paymentDetails.epravesh = data.Epravesh_payment_details;
//         }

//         if (data.enrollment_fee_details) {
//           paymentDetails.enrollment = [data.enrollment_fee_details];
//         }

//         setApprovedStudents(paymentDetails);
//         setLoading(false);
//       } catch (err) {
//         console.error("Error while fetching payment details:", err);
//         setError("Failed to fetch payment details");
//         setLoading(false);
//       }
//     };

//     fetchPaymentDetails();
//   }, [studentId]);

//   const logo = "https://cdn.sssutms.ac.in/media/static/images/sssutms.jpg";

//   const generatePDF1 = () => {
//     const doc = new jsPDF();
//     doc.addImage(logo, "JPEG", 3, 5, 30, 28);
//     doc.setFont("helvetica", "bold");
//     doc.setFontSize(16);
//     doc.setTextColor(0, 0, 0);
//     doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
//       align: "center",
//     });
//     doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
//     doc.setFontSize(13);
//     doc.text(
//       "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
//       118,
//       24,
//       { align: "center" }
//     );
//     doc.text(
//       "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
//       118,
//       30,
//       { align: "center" }
//     );
//     doc.setFontSize(14);
//     doc.setTextColor(255, 0, 0);
//     doc.text("Enrollment Fee Receipt", 118, 40, { align: "center" });

//     doc.setFontSize(12);
//     doc.setTextColor(0, 0, 0);

//     let yPos = 60;

//     const lineHeight = 10;

//     doc.text(
//       `                                    Name of Candidate  :   ${approvedStudents.enrollment[0].name}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Father's Name          :  ${approvedStudents.enrollment[0].fathersname}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Email                         :  ${approvedStudents.enrollment[0].email}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Mobile                       :  ${approvedStudents.enrollment[0].mobile}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Type            :  ${approvedStudents.enrollment[0].courseType}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Name           :  ${approvedStudents.enrollment[0].courseName}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Course Branch        :  ${approvedStudents.enrollment[0].courseBranch}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    TXN ID                      :  ${approvedStudents.enrollment[0].txnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    ORDER ID                :  ${approvedStudents.enrollment[0].orderId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    PAYMENT MODE    :  ${approvedStudents.enrollment[0].paymentMode}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    BANKTXN ID           :  ${approvedStudents.enrollment[0].bankTxnId}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Paid Amount           :  ${approvedStudents.enrollment[0].txnAmount}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.text(
//       `                                    Date & Time            :  ${approvedStudents.enrollment[0].txnDate}`,
//       10,
//       yPos
//     );
//     yPos += lineHeight;

//     doc.save(`${approvedStudents.enrollment[0].name} Enrollement Fee`);
//   };
//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         <StudentDashboard />

//         <style>
//           {`

// .radd{
//  margin-left: 120px;
//   margin-top: 80px;
//   }

//   .smallh{
//   font-size:13px;
//   color:#16325B;
//   margin-top:-20px}
//         .conttable{
//             background-color: #e8e5d5;
//             width: 90%;
//             margin-left: 120px;
//             margin-right: auto;
//             margin-top: 80px;
//             text-align: center;
//             max-width: 1200px;
//         }
// .h4d{
//   font-weight: inherit;
//   height:60px;
// margin-top:-15px;
//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       padding: 5px;

//                       }

//         .shadow{
//      margin-top: 10px;
//       margin-left:8%}

//         @media screen and (max-width: 480px){
//           .custom-card-content{
//             width: 100%; /* Adjust the percentage as needed */
//             max-width: 1200px; /* Set a maximum width */
//             margin: 0 auto; /* Center align */
//           }

// .radd{
//  margin-left: 20px;
//   margin-top: 80px;
//   }

//             .shadow{
//   margin-top: -60px;
//       margin-left:0%}

//         .h4d{
//   font-weight: inherit;
//   height:60px;

//                       // background-color: #003049;
//                       border-radius: 10px;
//                       color: black;
//                       font-weight:700;
//                       font-size:18px;
//                       padding: 5px;
//                       margin-top:70px;

//                       }

//          .form-group-custom {
//     flex: 0 0 100%; /* Make each form group take full width */
//     max-width: 100%;
//   }

//         .forum{
//         margin-left:10px;}              }
//     `}
//         </style>

//         {loading ? (
//           <div
//             className="d-flex justify-content-center align-items-center"
//             style={{ minHeight: "200px", marginTop: "40px" }}
//           >
//             <Spinner animation="border" role="status">
//               <span className="visually-hidden">Loading...</span>
//             </Spinner>
//           </div>
//         ) : error ? (
//           <span>{error}</span>
//         ) : Object.keys(approvedStudents).length === 0 ? (
//           <span>No Slip Available</span>
//         ) : (
//           <>
//             {approvedStudents.epravesh &&
//               approvedStudents.epravesh.length > 0 && (
//                 <>
//                   <div className="radd">
//                     <Typography variant="h4" gutterBottom>
//                       <h3> Select Payment Transaction Detail:</h3>
//                     </Typography>

//                     <div>
//                       <label style={{ marginRight: "20px" }}>
//                         <input
//                           type="radio"
//                           value="epravesh"
//                           checked={selectedOption === "epravesh"}
//                           onChange={handleOptionChange}
//                         />
//                         Registration/Subscription Payment
//                       </label>
//                       <label>
//                         <input
//                           type="radio"
//                           value="enrollment"
//                           checked={selectedOption === "enrollment"}
//                           onChange={handleOptionChange}
//                         />
//                         Tution Examination Fee
//                       </label>
//                     </div>
//                   </div>
//                   <hr></hr>
//                   {/* E-Pravesh Receipt */}
//                   {selectedOption === "epravesh" && (
//                     <CardContent className="shadow p-3 bg-body rounded">
//                       {/* <h4 className="h4d" style={{ color: "#A04747" }}>
//                         E-Pravesh Registration Receipt
//                       </h4> */}

//                       <TableContainer sx={{ maxHeight: 450 }}>
//                         <Table stickyHeader aria-label="sticky table">
//                           <TableHead>
//                             <TableRow>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 2,
//                                   left: 0,
//                                   color: "white",
//                                 }}
//                               >
//                                 S.No.
//                               </TableCell>

//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 2,
//                                   minWidth: "100px",
//                                   left: 50,
//                                   color: "white",
//                                 }}
//                               >
//                                 Registration Id
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   color: "white",
//                                   minWidth: "200px",
//                                 }}
//                               >
//                                 Enrollment No.
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   color: "white",
//                                   minWidth: "70px",
//                                 }}
//                               >
//                                 Semster
//                               </TableCell>

//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   left: 210,
//                                   zIndex: 2,
//                                   color: "white",
//                                   minWidth: "200px",
//                                 }}
//                                 // /////////////for sorting name//////////////////
//                                 // onClick={() => handleSort("name")}
//                               >
//                                 Fee Type
//                                 {/* /////////////name sort////////////////////////// */}
//                                 {/* {sortBy === "name" && (
//                             <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
//                           )} */}
//                               </TableCell>

//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   minWidth: "100px",
//                                   zIndex: 1,
//                                   color: "white",
//                                 }}
//                               >
//                                 Reference No.
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   minWidth: "200px",
//                                   color: "white",
//                                 }}
//                               >
//                                 Amount
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   color: "white",
//                                 }}
//                               >
//                                 Date
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   color: "white",
//                                 }}
//                               >
//                                 Status
//                               </TableCell>

//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   backgroundColor: "rgb(2, 48, 71)",
//                                   position: "sticky",
//                                   top: 0,
//                                   zIndex: 1,
//                                   minWidth: "140px",
//                                   color: "white",
//                                 }}
//                               >
//                                 Action
//                               </TableCell>
//                             </TableRow>
//                           </TableHead>
//                           <TableBody>
//                             <TableRow>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   position: "sticky",
//                                   top: 0,
//                                   left: 0,
//                                   zIndex: 1,
//                                   backgroundColor: "white",
//                                 }}
//                               >
//                                 1
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{
//                                   position: "sticky",
//                                   left: 50,
//                                   top: 0,
//                                   fontWeight: 700,
//                                   zIndex: 1,

//                                   backgroundColor: "white",
//                                   color: "red",
//                                 }}
//                               >
//                                 241716013002
//                                 {/* {student?.enrollmentNumber || "Not Generated"} */}
//                               </TableCell>
//                               {/* <TableCell align="left">
//                             <DatePicker
//                               selected={
//                                 student?.createdAt
//                                   ? new Date(student.createdAt)
//                                   : null
//                               }
//                               readOnly
//                               dateFormat="dd/MM/yyyy HH:mm"
//                               className="text-left"
//                             />
//                           </TableCell> */}
//                               <TableCell align="left">
//                                 2426270965
//                                 {/* {student?.randomId} */}
//                               </TableCell>
//                               <TableCell align="left">
//                                 Dec-2022
//                                 {/* {student?.randomId} */}
//                               </TableCell>
//                               <TableCell align="left">
//                                 EPravesh Fees
//                                 {/* {student?.randomId} */}
//                               </TableCell>

//                               <TableCell
//                                 align="left"
//                                 sx={{
//                                   // color: "#e85d04",
//                                   fontWeight: "bold",
//                                   position: "sticky",
//                                   left: 210,
//                                   backgroundColor: "white",
//                                 }}
//                               >
//                                 12321564655846584564654 5{" "}
//                                 {/* {student?.name} */}
//                               </TableCell>
//                               <TableCell align="left">
//                                 5000
//                                 {/* {student?.fathersname} */}
//                               </TableCell>
//                               <TableCell align="left">
//                                 08/02/2024 17:01:16
//                                 {/* {student?.mothersname} */}
//                               </TableCell>
//                               <TableCell
//                                 align="left"
//                                 style={{ color: "#840032", fontWeight: 700 }}
//                               >
//                                 Success
//                                 {/* {student?.email} */}
//                               </TableCell>
//                               {/* <TableCell align="left">{student?.mobile}</TableCell> */}
//                               <button>edit kro</button>
//                             </TableRow>
//                           </TableBody>
//                         </Table>
//                       </TableContainer>
//                     </CardContent>
//                   )}
//                 </>
//               )}

//             {/* Enrollment Receipt */}
//             {isEnrolledPaid &&
//               approvedStudents.enrollment &&
//               approvedStudents.enrollment.length > 0 &&
//               selectedOption === "enrollment" && (
//                 <CardContent className="shadow p-3 bg-body rounded">
//                   <h4 className="h4d" style={{ color: "#A04747" }}>
//                     Enrollment Fee Payment Receipt
//                   </h4>
//                   <h6 className="smallh">
//                     Fill the below details to download your receipt.{" "}
//                   </h6>
//                   <Form className="forum" style={{ marginTop: "1%" }}>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="session"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Admission Session</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="session"
//                           value={
//                             approvedStudents.enrollment[0].session || "2024"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>
//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="courseType"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Type</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseType"
//                           value={
//                             approvedStudents.enrollment[0].courseType || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseName"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseName"
//                           value={
//                             approvedStudents.enrollment[0].courseName || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="courseBranch"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Course Branch</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="courseBranch"
//                           value={
//                             approvedStudents.enrollment[0].courseBranch || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="name"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="name"
//                           value={approvedStudents.enrollment[0].name || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="fathersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Father's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="fathersname"
//                           value={
//                             approvedStudents.enrollment[0].fathersname || ""
//                           }
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mothersname"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mother's Name</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mothersname"
//                           value={
//                             approvedStudents.enrollment[0].mothersname || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="email"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Email</Form.Label>
//                         <Form.Control
//                           type="email"
//                           name="email"
//                           value={approvedStudents.enrollment[0].email || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="mobile"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Mobile</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="mobile"
//                           value={approvedStudents.enrollment[0].mobile || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group
//                         as={Col}
//                         controlId="orderId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Order ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="orderId"
//                           value={approvedStudents.enrollment[0].orderId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnId"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnId"
//                           value={approvedStudents.enrollment[0].txnId || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="randomId">
//                         <Form.Label>Student ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="randomId"
//                           value={approvedStudents.enrollment[0].randomId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="paymentMode">
//                         <Form.Label>Payment Mode</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="paymentMode"
//                           value={
//                             approvedStudents.enrollment[0].paymentMode || ""
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group
//                         as={Col}
//                         controlId="txnDate"
//                         className="form-group-custom"
//                       >
//                         <Form.Label>Transaction Date</Form.Label>
//                         <Form.Control
//                           type="dd-mm-yyyy"
//                           name="txnDate"
//                           value={approvedStudents.enrollment[0].txnDate || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="txnAmount">
//                         <Form.Label>Transaction Amount</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="txnAmount"
//                           value={approvedStudents.enrollment[0].txnAmount || ""}
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Row className="mb-3">
//                       <Form.Group as={Col} controlId="bankTxnId">
//                         <Form.Label>Bank Transaction ID</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="bankTxnId"
//                           value={approvedStudents.enrollment[0].bankTxnId || ""}
//                         />
//                       </Form.Group>
//                       <Form.Group as={Col} controlId="status">
//                         <Form.Label>Status</Form.Label>
//                         <Form.Control
//                           type="text"
//                           name="status"
//                           value={
//                             approvedStudents.enrollment[0].status ||
//                             "TXN_SUCCESS"
//                           }
//                         />
//                       </Form.Group>
//                     </Row>

//                     <Button
//                       variant="primary"
//                       type="submit"
//                       style={{ marginLeft: "10px", backgroundColor: "#243642" }}
//                       onClick={generatePDF1}
//                     >
//                       Download Receipt
//                     </Button>
//                   </Form>
//                 </CardContent>
//               )}
//           </>
//         )}
//       </ThemeProvider>
//     </>
//   );
// };

// export default EStudent_Slip;
