import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function BESchema() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
           margin-left:-20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}>BACHELOR OF ENGINEERING</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-10 padding-section">

            <br />

            <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Scheme: BE </h5>

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>



<br></br>

            <table style={{ width: '90%', marginLeft: '50px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody>
                <tr><th colSpan='6' style={{textAlign:'center'}}>As per AICTE Curriculum with effect from academic session 2022-23

</th></tr><tr><th>COURSE

</th> <th style={{textAlign:'center'}}>BRANCH</th> <th colSpan='4' style={{textAlign:'center'}}>SEMESTER

(As pre AICTE Curriculam)</th>
              </tr>
              <tr><th></th><th></th><th>I Year</th><th>II Year
              </th><th>III  Year</th><th>IV Year</th></tr>
               <tr>
                <th rowSpan='11' style={{textAlign:'center'}}>BE</th>
               <th>Aeronautical Engineering</th>


               <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
              <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'> II Sem</Link></td>

               <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_3SEM.pdf'>III Sem </Link><br></br>
              <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_4SEM.pdf'> IV Sem</Link></td>

               <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_5SEM.pdf'>V Sem </Link><br></br>
              <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_6SEM.pdf'> VI Sem</Link></td>

               <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_7SEM.pdf'>VII Sem </Link><br></br>
              <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_AE_8SEM.pdf'> VIII Sem</Link></td>
</tr>
            <tr>   
              <th> Chemical Engineering </th>
              <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_ISEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_IISEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_IIISEM.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_IVSEM.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_VSEM.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_VISEM.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_VIISEM.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CME_VIIISEM.pdf'>VIII Sem </Link>
              </td>

            </tr>
            <tr> 
               <th> Civil Engineering </th>

               
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_3SEM.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_4SEM.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_5SEM.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_6SEM.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_7SEM.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_CE_8SEM.pdf'>VIII Sem </Link>
              </td>
             </tr>





             <tr>   
              <th> Computer Science and Engineering </th>
          
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_3.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_4.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_5.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/CS_8.pdf'>VIII Sem </Link>
              </td>
            </tr>
























            <tr> 
               <th> Electrical Engineering </th>
              
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_ISEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_IISEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_3SEM.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_4SEM.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_5SEM.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_6SEM.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_7SEM.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_EE_8SEM.pdf'>VIII Sem </Link>
              </td>
</tr>
            <tr> 
               <th> Electrical and Electronics Engineering </th>
              
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_ISEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_IISEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_III.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_IV.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_V.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_VI.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_VII.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EEE_VIII.pdf'>VIII Sem </Link>
              </td>
              </tr>
            <tr>  
               <th>Electronics and Communication  </th>
              
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_ISEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_IISEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_3.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_4.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_5.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EC_8.pdf'>VIII Sem </Link>
              </td>
</tr>
               <tr>  
                 <th> Electronics and Instrumentation </th>
                
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_III.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_IV.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_5.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/EI_8.pdf'>VIII Sem </Link>
              </td>
              </tr>
            
               <tr>   
                <th> Information Technology </th>
            
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_ISEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_IISEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT_3.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT-4.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT_5.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/IT_8.pdf'>VIII Sem </Link>
              </td>
                </tr>
               <tr>  
                <th> Mechanical Engineering </th>

                
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_III.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_IV.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_V.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/ME_8.pdf'>VIII Sem </Link>
              </td>
                </tr>
               <tr>  
                <th>Mining Engineering</th>

                
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_I SEM.pdf'>I Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/BE_2SEM.pdf'>II Sem </Link>
              </td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_III.pdf'>III Sem </Link><br></br>
<Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_4.pdf'>IV Sem</Link></td>


<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_5.pdf'>V Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_6.pdf'>VI Sem </Link>
              </td>

<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_7.pdf'>VII Sem </Link><br></br>
             <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/BE/MI_8.pdf'>VIII Sem </Link>
              </td>
               </tr>



              </tbody>
            </table>
           
       
           
          </div>



          <div className="col-lg-2">

            <ul className='list-icon' >
              <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>Scheme</h4>
              <br></br> 
               <li className='lli'>
               <Link to ='/B.E-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BE </Link></li>
              
                  <li className='lli'>
               <Link to ='/Pharmacy-scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Pharmacy </Link></li>
                  <li className='lli'>
               <Link to ='/M.Tech-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MTECH </Link></li>
                  <li className='lli'>
               <Link to ='/BHMCT-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMCT </Link></li>
                  <li className='lli'>
               <Link to ='/MBA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MBA </Link></li>
                  <li className='lli'>
               <Link to ='/MCA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MCA </Link></li>
                  <li className='lli'>
               <Link to ='/Education-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Education </Link></li>
                  <li className='lli'>
               <Link to ='/PhysicalEducation-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Physical Education </Link></li>
                  <li className='lli'>
               <Link to ='/BscHonsAg-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Sc.(Hons).(Ag) </Link></li>
                  <li className='lli'>
               <Link to ='/BHMS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMS </Link></li>
                  <li className='lli'>
               <Link to ='/Paramedical-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Paramedical </Link></li>
                  <li className='lli'>
               <Link to ='/POLYTECHNIC(ENGINEERING)-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Polytechnic Engineering </Link></li>
                  <li className='lli'>
               <Link to ='/BLIBISC-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Lib.I.Sc </Link></li>
                  <li className='lli'>
               <Link to ='/BachelorofLaws-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Bachelor of Laws  </Link></li>
                  <li className='lli'>
               <Link to ='/BSCHMCS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>B.Sc.[HMCS]  </Link></li>
                
            


            </ul>
          </div>
        </div>

      </div>
      <br></br>

      <Footerpage /></>
  )
}

export default BESchema