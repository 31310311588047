import React from 'react';
import { Link } from 'react-router-dom';
import Nav from '../HomePage/Nav';
import Footerpage from '../HomePage/Footerpage';

function CoreValues() {
  return (
    <>
       <style>
        {
        `
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
   
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}

  .lli1 p {
        color: #4A4947; /* Sets green color for <p> text */
    }

    .lli1 p strong {
        color: black; /* Sets black color for <strong> text */
    }
.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


 .lli1{
 font-size:16px; 
// margin-left:20px;
margin-top:10px;
  font-family: 'poppins',sansSerif;
font-weight:700;
 list-style-type: none; /* Removes bullets */}

 }
.list-icon{
margin-top:30px;


  list-style-type: none; /* Removes bullets */
        display: flex;
        flex-direction: column; /* Default to vertical */
}
.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>
    <Nav/>
      <section className="section_all " id="about" >
        <div className="container">
        <div class="two">
  <h1>CORE VALUES
  
  </h1>
</div>
         <br></br>
       
         <div className='row' >
            <div className="col-lg-9">
            
            
             <ul >
              <li className='lli1'> <p> <strong>DESCIPLINE :</strong>It is the practice of training people to obey rules or a code of behaviour, using punishment to correct disobedience.</p>
               </li>
                  <li className='lli1'> <p> <strong>PUNCTUALITY OR RESPECT FOR TIME :</strong> We promote punctuality and Time Management among Stake Holders.</p>
             </li>

               <li className='lli1'> <p> <strong>FREEDOM OF THOUGHT AND EXPRESSION:</strong> We believe that Freedom of Thought and Expression is necessary, as without this overall development of individual cannot be completed.</p>
             </li>


               <li className='lli1'><p> <strong>HONESTY AND INTEGRITY: </strong>We are committed to practices that are fair, honest and objective in dealing with students, faculty members, staff and stake holders at all levels of Institution.</p>
             </li>


               <li className='lli1'>   <p> <strong> QUALITY EXCELLENCE:</strong> We promote Excellence in whatever constructive and productive work / activity Students /Faculties do.</p>
             </li>

               <li className='lli1'> <p> <strong>ACCOUNTABILITY AND TRANSPARENCY: </strong>We believe in having complete transparency at all levels of hierarchy to promote a healthier working atmosphere to all. We believe in Accountability for and Transparency in, all my deeds and actions.</p>
             </li>
             <li className='lli1'> <p> <strong>PERSEVERANCE: </strong> We encourage Perseverance in doing something despite difficulty or delay in achieving success.</p>
             </li>
               <li className='lli1'> <p> <strong> ENCOURAGEMENT: </strong>Encouragement is provided for critical and quantitative thinking, effective communication, ethical decision making and social obligation in our students.</p>
             </li>
             <li className='lli1'> <p> <strong>SOCIAL RESPONSIBILITY: </strong>We are focused on promoting the sense of social responsibilities in students by involving them in various social activities that gives them a broader perspective of understanding the causes and possible solutions related to various social issues.</p>
             </li>
                       </ul>
              </div>
              <div className="col-lg-3">

              <ul className='list-icon' >
              <li className='lli'>
                <Link to ='/History_Milestones'>
                <i class="fa fa-adjust" aria-hidden="true"></i>About</Link></li>
                <li className='lli'>
                <Link to ='/institutes'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Institutes</Link></li>
              <li className='lli'>
              <Link to ='/promotingsociety'>
              <i class="fa fa-adjust" aria-hidden="true"></i>Promoting Society</Link></li>
              <li className='lli'>
              <Link to ='/vision-mission'>
              <i class="fa fa-adjust" aria-hidden="true"></i>Vision & Mission</Link></li>
              <li className='lli'>  
                 <Link to ='/core-approvalsvalues'>
              <i class="fa fa-adjust" aria-hidden="true"></i>Core Values</Link></li>
              <li className='lli'>   <Link to ='/best-practices'>
              <i class="fa fa-adjust" aria-hidden="true"></i>Best Practices</Link></li>
            </ul>
              </div></div>
        
        </div>
      </section>
      <Footerpage/>
    </>
  );
}

export default CoreValues;
