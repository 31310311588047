import { useEffect } from "react";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import OnlyHeader from "../../AdminComponent/OnlyHeader";

const StudentWaiting = () => {
  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  console.log(userData, "user ka data");

  const navigate = useNavigate();

  // useEffect(() => {
  //   const handlePopState = (event) => {
  //     event.preventDefault();
  //     navigate("/erp/studentlogin", { replace: true });
  //   };

  //   window.addEventListener("popstate", handlePopState);

  //   return () => {
  //     window.removeEventListener("popstate", handlePopState);
  //   };
  // }, [navigate]);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      navigate("/erp/studentlogin", { replace: true });
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <style>
        {`
      
    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 20px;
    }

    .card {
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 10px 4px gray;
      padding: 20px;
      width: 90%;
      max-width: 800px;
      margin: 20px auto;
      
    }
      
     .main-div{
         display:flex;
         align-items:center;
         justify-content:space-between;
    }

    button:hover {
      opacity: 0.8;
    }

    .center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
      .first-div p{
      
         font-size:16px;
         font-weight:400;
      }

        .loading-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .spinner {
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-top: 4px solid #11acf3;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
      </style>

      <OnlyHeader />
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="card">
          <div className="main-div">
            <div className="first-div">
              <h3 style={{ marginBottom: "30px" }}>
                Congratulations {userData?.name}🎉
              </h3>

              <p>
              Your registration for the course <b>{userData?.courseName}</b> has been successfully completed. Please wait for approval from your Head of Department (HOD). After approval, log in using the ID and password that sent to your email, and pay the fee. If you have already paid at the university, please contact your HOD for confirmation.
              </p>
            </div>
          </div>
          <form>
            <Link to="/erp/studentlogin">
              <div className="col-12 center">
                <Button
                  style={{
                    margin: "30px auto",
                    display: "block",
                    width: "20%",
                    backgroundImage:
                      "linear-gradient(96deg, #ff5f6d 0%, #ffc371 100%)",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    fontSize: "14px !important",
                  }}
                  type="submit"
                >
                  Log Out
                </Button>
              </div>
            </Link>
          </form>
        </div>
      </div>
    </>
  );
};

export default StudentWaiting;
