import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { Box } from "@chakra-ui/react";
import swal from "sweetalert";

const EntranceEligibilityForm = ({
  eligible,
  eligibilityGradPer,
  courseType,
  courseName,
  courseBranch,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
  } = useForm();

  const [showSecondForm, setShowSecondForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileUrls, setFileUrls] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    PgMarksheet:"",
  });

  const [graduationPercentage, setGraduationPercentage] = useState();
  const [postGraduationPercentage, setPostGraduationPercentage] = useState();
  useEffect(() => {
    const calcPercentage = (obtained, total) => {
      return total && obtained ? ((obtained / total) * 100).toFixed(2) : 0;
    };

    const graduation = calcPercentage(
      getValues("graduationMarksObtain"),
      getValues("graduationMaxMarks")
    );

    const postGraduation = calcPercentage(
      getValues("postGraduationMarksObtain"),
      getValues("postGraduationMaxMarks")
    );

    setGraduationPercentage(graduation);
    setPostGraduationPercentage(postGraduation);
    // setPercentage({
    //  graduationPercentage:graduation,
    //   postGraduationPercentage: postGraduation,
    // });
  }, [
    watch("graduationMarksObtain"),
    watch("graduationMaxMarks"),
    watch("postGraduationMarksObtain"),
    watch("postGraduationMaxMarks"),
  ]);
  //////////////////////////////////////////////////

  const navigate = useNavigate();

  const uploadFileToCloudinary = async (file, fieldName) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "upload");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dszawxz7y/image/upload",
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload file to Cloudinary");
      }

      const data = await response.json();
      return data.secure_url;
    } catch (error) {
      console.error("Error uploading file to Cloudinary:", error);
      throw new Error("Error uploading file to Cloudinary");
    }
  };

  const validateFile = async (file, fieldName) => {
    try {
      if (!file || file.size > 1024 * 1024) {
        throw new Error("File size should be less than 1MB");
      }

      const fileUrl = await uploadFileToCloudinary(file, fieldName);

      setFileUrls((prevFileUrls) => ({
        ...prevFileUrls,
        [fieldName]: fileUrl,
      }));

      clearErrors(fieldName);
    } catch (error) {
      console.error("File validation/upload error:", error);
      throw error;
    }
  };

  const handleFileChange = async (file, fieldName) => {
    try {
      if (!file) {
        throw new Error("No file selected");
      }

      await validateFile(file, fieldName);
    } catch (error) {
      console.error("File validation/upload error:", error);
    }
  };

  //////////////////eligibility////////////////

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    try {
      const enteredCategory = data.category;
      const enteredPercentage1 = graduationPercentage;
      const enteredPercentage2 = postGraduationPercentage;
      const eligibilityPercentage = eligibilityGradPer[enteredCategory];

      // Perform the eligibility check immediately
      let isEligible = false;
      if (
        !isNaN(enteredPercentage1) &&
        enteredPercentage1 >= eligibilityPercentage &&
        !isNaN(enteredPercentage2) &&
        enteredPercentage2 >= eligibilityPercentage
      ) {
        isEligible = true;
      }

      // Check if not eligible
      if (!isEligible) {
        alert("You Are Not Eligible");
        setIsSubmitting(false);
        return; // Exit the submission process
      }

      // Proceed with form submission if eligible
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/v2/entrancedetails",
        {
          name: data.name,
          mobile: data.mobile,
          email: data.email,
          fathersname: data.fathersname,
          mothersname: data.mothersname,
          dob: data.dob,
          religion: data.religion,
          category: data.category,
          gender: data.gender,
          domicile: data.domicile,
          address: data.address,
          courseType: courseType,
          courseBranch: courseBranch,
          courseName: courseName,
          academicFormData: {
            tenthRollNo: data.tenthRollNo,
            tenthPassingYear: data.tenthPassingYear,
            tenthBoard: data.tenthBoard,
            tenthPercentage: data.tenthPercentage,
            twelfthPassingYear: data.twelfthPassingYear,
            twelfthRollNo: data.twelfthRollNo,
            twelfthBoard: data.twelfthBoard,
            twelfthPercentage: data.twelfthPercentage,
            graduationPassingYear: data.graduationPassingYear,
            graduationRollNo: data.graduationRollNo,
            graduationBoard: data.graduationBoard,
            graduationMarksObtain: data.graduationMarksObtain,
            graduationMaxMarks: data.graduationMaxMarks,
            graduationPercentage: graduationPercentage,
            postGraduationPassingYear: data.postGraduationPassingYear,
            postGraduationRollNo: data.postGraduationRollNo,
            postGraduationMarksObtain: data.postGraduationMarksObtain,
            postGraduationMaxMarks: data.postGraduationMaxMarks,
            postGraduationBoard: data.postGraduationBoard,
            postGraduationPercentage: postGraduationPercentage,
          },
          fileUrls,
        }
      );

      const id = response.data._id;
      const name = response.data.name;
      if (response.status === 500) {
        alert("Something went wrong");
      } else {
        swal({
          icon: "success",
          title: "Form Submitted",
          text: "Dear Student your Form has been submitted Successfully, Now pay the fee!",
        }).then(() => {
          navigate("/entrance/payment", {
            state: { courseType, courseName, courseBranch, id, name },
          });
        });
      }
    } catch (error) {
      // if (error.response?.data?.status === 402) {
      //   const registered = error.response.data.Registered;
      //   const id = registered[0]._id;
      //   const name = registered[0].name;
      //   navigate("/entrance/payment", {
      //     state: { courseType, courseName, courseBranch, id, name },
      //   });
      // }
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleContinue = () => {
    setShowSecondForm(true);
  };

  // console.log(fileUrls)

  return (
    <>
      <style>
        {`
      .eligible{
      max-width: 91%;
      margin-left:60px;
       overflow-y: auto;
        height: 42vh;
        }

        .basic{
        width: 1230px;
         margin-left:-50px;
         }

   @media (max-width:480px){

         .eligible{
      max-width: 91%;
      margin-left:20px;
       overflow-y: auto;
        height: 42vh;
        }

        .basic{
        width: 350px;
         margin-left:20px;
         }
   }

        `}
      </style>
      <div
        fluid
        className="container-overflow shadow p-3  mb-9 bg-body rounded eligible"
        // style={{ maxWidth: "91%",marginLeft:'60px', overflowY: "auto", height: "42vh" }}
      >
        <Box
          marginBottom={"20px"}
          backgroundColor={"#264653"}
          p={4}
          h={50}
          color="white"
          mt={8}
          borderRadius="md"
          shadow="md"
        >
          Check Eligibility
        </Box>
        <Table responsive>
          <tbody>
            <tr>
              <td>Eligibility</td>
              <td>:</td>
              <td
                style={{
                  color: "#e63946",
                  fontFamily: "inherit",
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
                {eligible.eligibility}
              </td>
            </tr>
            <tr>
              <td>Eligibility %</td>
              <td>:</td>
              <td>
                <Row className="equal-width-boxes">
                  <Col xs={6} md={2}>
                    Gen: {eligibilityGradPer.gen}
                  </Col>
                  <Col xs={6} md={2}>
                    SC: {eligibilityGradPer.sc}
                  </Col>
                  <Col xs={6} md={2}>
                    ST: {eligibilityGradPer.st}
                  </Col>
                  <Col xs={6} md={2}>
                    OBC: {eligibilityGradPer.obc}
                  </Col>
                  <Col xs={6} md={2}>
                    PH: {eligibilityGradPer.ph}
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      {!showSecondForm && (
        <Button
          style={{
            margin: "20px auto",
            display: "block",
            backgroundColor: "#264653",
            color: "white",
            width: "w-20 w-md-auto",
            border: "none",
            borderRadius: "5px",
            fontSize: "15px",
            padding: "10px 20px",
          }}
          onClick={handleContinue}
        >
          Continue
        </Button>
      )}
      {showSecondForm && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="container-xl px-1 mt-3">
              {/* <hr className="mt-0 mb-4" /> */}

              {/* <div className="col-xl-12" > */}
              <div fluid className="basic">
                <div className=" card mb-4">
                  <div
                    className="card-header"
                    style={{ backgroundColor: "#264653", color: "white" }}
                  >
                    Basic Details
                  </div>
                  <div className="card-body">
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          Name
                        </label>
                        <input
                          style={{
                            height: "40px",
                            borderRadius: "8px",
                            textTransform: "uppercase",
                          }}
                          className="form-control"
                          id="inputFirstName"
                          type="text"
                          autoComplete="off"
                          name="name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                          placeholder="Enter your Name"
                        />
                        {errors.name && (
                          <span className="text-danger">
                            {errors.name.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          Applicant Photo
                        </label>
                        <input
                          style={{ height: "40px" }}
                          className="form-control"
                          id="inputFirstName"
                          type="file"
                          name="applicantPhoto"
                          {...register("applicantPhoto", {
                            required: true,
                          })}
                          onChange={(event) =>
                            handleFileChange(
                              event.target.files[0],
                              "applicantPhoto"
                            )
                          }
                        />
                        {errors.applicantPhoto &&
                          errors.applicantPhoto.type === "required" && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        {errors.applicantPhoto &&
                          errors.applicantPhoto.message && (
                            <span className="text-danger">
                              {errors.applicantPhoto.message}
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          Mobile Number
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          name="mobile"
                          autoComplete="off"
                          id="inputOrgName"
                          {...register("mobile", {
                            required: true,
                            pattern: /^[0-9]{10}$/,
                          })}
                          type="NUMBER"
                        />

                        {errors.mobile && errors.mobile.type === "required" && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                        {errors.mobile && errors.mobile.type === "pattern" && (
                          <span className="text-danger">
                            Invalid phone number format
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          Signature
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          id="inputFirstName"
                          type="file"
                          name="applicantSignature"
                          {...register("applicantSignature", {
                            required: true,
                            // validate: validateFile
                          })}
                          onChange={(event) =>
                            handleFileChange(
                              event.target.files[0],
                              "applicantSignature"
                            )
                          }
                        />
                        {errors.applicantSignature &&
                          errors.applicantSignature.type === "required" && (
                            <span className="text-danger">
                              Applicant Signature is required
                            </span>
                          )}
                        {errors.applicantSignature &&
                          errors.applicantSignature.message && (
                            <span>{errors.applicantSignature.message}</span>
                          )}
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputOrgName">
                          Father's Name
                        </label>
                        <input
                          style={{
                            height: "40px",
                            borderRadius: "8px",
                            textTransform: "uppercase",
                          }}
                          className="form-control"
                          id="inputOrgName"
                          type="text"
                          autoComplete="off"
                          name="fathersname"
                          {...register("fathersname", {
                            required: "fathersname is required",
                          })}
                          placeholder="Enter your father's name"
                        />
                        {errors.fathersname && (
                          <span className="text-danger">
                            {errors.fathersname.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputLocation">
                          Mother's Name
                        </label>
                        <input
                          style={{
                            height: "40px",
                            borderRadius: "8px",
                            textTransform: "uppercase",
                          }}
                          className="form-control"
                          id="inputLocation"
                          type="text"
                          autoComplete="off"
                          name="mothersname"
                          {...register("mothersname", {
                            required: "mothersname is required",
                          })}
                          placeholder="Enter your mother's name"
                        />
                        {errors.mothersname && (
                          <span className="text-danger">
                            {errors.mothersname.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputPhone">
                          Date of Birth
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          id="inputPhone"
                          autoComplete="off"
                          type="date"
                          name="dob"
                          {...register("dob", {
                            required: "Date of Birth  is required",
                          })}
                          placeholder="Enter your date of birth"
                        />
                        {errors.dob && (
                          <span className="text-danger">
                            {errors.dob.message}
                          </span>
                        )}
                      </div>

                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputLocation">
                          Email
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          id="inputLocation"
                          autoComplete="off"
                          type="text"
                          {...register("email", {
                            required: true,
                            pattern: /^\S+@\S+$/i,
                          })}
                          placeholder="ENTER YOUR EMAIL"
                        />

                        {errors.email && errors.email.type === "required" && (
                          <span className="text-danger">
                            This field is required
                          </span>
                        )}
                        {errors.email && errors.email.type === "pattern" && (
                          <span className="text-danger">
                            Invalid email format
                          </span>
                        )}
                      </div>

            
                      <div className="row gx-3 mt-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputGender">
                          Category
                        </label>
                        <select
                          className="form-control"
                          id="inputGender"
                          name="category"
                          style={{ height: "45px", borderRadius: "8px" }}
                          {...register("category", {
                            required: "category is required",
                          })}
                        >
                          <option value="">Select Category </option>
                          <option value="gen">General</option>
                          <option value="obc">OBC</option>
                          <option value="sc">SC</option>
                          <option value="st">ST</option>
                        </select>
                        {errors.category && (
                          <span className="text-danger">
                            {errors.category.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputGender">
                          Gender
                        </label>
                        <select
                          className="form-control"
                          id="inputGender"
                          name="gender"
                          style={{ height: "45px", borderRadius: "8px" }}
                          {...register("gender", {
                            required: "gender is required",
                          })}
                        >
                          <option value="">Select Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.gender && (
                          <span className="text-danger">
                            {errors.gender.message}
                          </span>
                        )}
                      </div>     </div>
              
                    </div>
                    <div className="row gx-3 mb-3">
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputOrgName">
                          Domicile
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          id="inputOrgName"
                          type="text"
                            autoComplete="off"
                          name="domicile"
                          {...register("domicile", {
                            required: "Domicile is required",
                          })}
                          placeholder="ENTER YOUR DOMICILE"
                        />
                        {errors.domicile && (
                          <span className="text-danger">
                            {errors.domicile.message}
                          </span>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputLocation">
                          Religion
                        </label>
                        <input
                          style={{ height: "40px", borderRadius: "8px" }}
                          className="form-control"
                          id="inputLocation"
                            autoComplete="off"
                          type="text"
                          name="religion"
                          {...register("religion", {
                            required: "Religion is required",
                          })}
                          placeholder="ENTER YOUR RELIGION"
                        />
                        {errors.religion && (
                          <span className="text-danger">
                            {errors.religion.message}
                          </span>
                        )}
                      </div>
                    </div>{" "}
                    <div className="row gx-3 mb-3">

                    <div className="col-md-6">
                      <label className="small mb-1" htmlFor="inputEmailAddress">
                        Address
                      </label>
                      <textarea
                        style={{ height: "40px", borderRadius: "8px" }}
                        className="form-control"
                        id="inputAddress"
                        type="address"
                        name="address"
                        {...register("address", {
                          required: "Address is required",
                        })}
                        placeholder="ENTER YOUR ADDRESS "
                      />
                      {errors.address && (
                        <span className="text-danger">
                          {errors.address.message}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                        <label className="small mb-1" htmlFor="inputFirstName">
                          PG Marksheet
                        </label>
                        <input
                          style={{ height: "40px" }}
                          className="form-control"
                          id="inputFirstName"
                          type="file"
                          name="PgMarksheet"
                          {...register("PgMarksheet", {
                            required: true,
                          })}
                          onChange={(event) =>
                            handleFileChange(
                              event.target.files[0],
                              "PgMarksheet"
                            )
                          }
                        />
                        {errors.PgMarksheet &&
                          errors.PgMarksheet.type === "required" && (
                            <span className="text-danger">
                              This field is required
                            </span>
                          )}
                        {errors.PgMarksheet &&
                          errors.PgMarksheet.message && (
                            <span className="text-danger">
                              {errors.PgMarksheet.message}
                            </span>
                          )}
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* ////////////////////////////Academic details//////////////////////////////////////// */}
              {/* <div className="col-xl-12"> */}
              <div fluid className="basic">
                <div className="card mb-4">
                  <div
                    className="card-header"
                    style={{ backgroundColor: "#264653", color: "white" }}
                  >
                    Education Details
                  </div>
                  <div className="card-body">
                    {["UG", "PG", "Diploma", "PG_DIPLOMA", "Ph_D"].includes(
                      courseType
                    ) && (
                      <>
                        <div className="row gx-3 mb-3">
                          <h6>UG DETAILS</h6>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationRollNo"
                            >
                              Roll No.
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              autoComplete="off"
                              id="graduationRollNo"
                              {...register("graduationRollNo", {
                                required: "graduationRollNo is required",
                              })}
                              type="text"
                            />
                            {errors.graduationRollNo && (
                              <span className="text-danger">
                                {errors.graduationRollNo.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationPassingYear"
                            >
                              Passing Year
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                                autoComplete="off"
                              id="graduationPassingYear"
                              type="number"
                              {...register("graduationPassingYear", {
                                required: "graduationPassingYear is required",
                              })}
                            />
                            {errors.graduationPassingYear && (
                              <span className="text-danger">
                                {errors.graduationPassingYear.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationBoard"
                            >
                              University
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationBoard"
                              type="text"
                              autoComplete="off"
                              {...register("graduationBoard", {
                                required: "graduationBoard is required",
                              })}
                            />
                            {errors.graduationBoard && (
                              <span className="text-danger">
                                {errors.graduationBoard.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationMarksObtain"
                            >
                              Obtain Marks / Obtain CGPA
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationMarksObtain"
                                autoComplete="off"
                              type="number"
                              {...register("graduationMarksObtain", {
                                required: "graduationMarksObtain is required",
                              })}
                            />
                            {errors.graduationMarksObtain && (
                              <span className="text-danger">
                                {errors.graduationMarksObtain.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationMaxMarks"
                            >
                              Total Marks / Total CGPA
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationMaxMarks"
                                autoComplete="off"
                              type="number"
                              {...register("graduationMaxMarks", {
                                required: "graduationMaxMarks is required",
                              })}
                            />
                            {errors.graduationMaxMarks && (
                              <span className="text-danger">
                                {errors.graduationMaxMarks.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="graduationPercentage"
                            >
                              Percentage
                            </label>
                            {/* <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationPercentage"
                              type="number"
                              {...register("graduationPercentage", {
                                required: "graduationPercentage is required",
                              })}
                            /> */}
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationPercentage"
                              type="number"
                              value={graduationPercentage}
                              readOnly
                            />
                            {errors.graduationPercentage && (
                              <span className="text-danger">
                                {errors.graduationPercentage.message}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="row gx-3 mb-3">
                          <h6>PG DETAILS</h6>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="twelfthRollNo"
                            >
                              Roll No.
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationRollNo"
                              autoComplete="off"
                              {...register("postGraduationRollNo", {
                                required: "postGraduationRollNo is required",
                              })}
                              type="text"
                            />
                            {errors.postGraduationRollNo && (
                              <span className="text-danger">
                                {errors.postGraduationRollNo.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="postGraduationPassingYear"
                            >
                              Passing Year
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationPassingYear"
                              type="number"
                              {...register("postGraduationPassingYear", {
                                required:
                                  "postGraduationPassingYear is required",
                              })}
                            />
                            {errors.postGraduationPassingYear && (
                              <span className="text-danger">
                                {errors.postGraduationPassingYear.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="postGraduationBoard"
                            >
                              University
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationBoard"
                              autoComplete="off"
                              type="text"
                              {...register("postGraduationBoard", {
                                required: "postGraduationBoard is required",
                              })}
                            />
                            {errors.postGraduationBoard && (
                              <span className="text-danger">
                                {errors.postGraduationBoard.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="postGraduationMarksObtain"
                            >
                              Obtain Marks/Obtain CGPA
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationMarksObtain"
                              type="number"
                              {...register("postGraduationMarksObtain", {
                                required:
                                  "postGraduationMarksObtain is required",
                              })}
                            />
                            {errors.postGraduationMarksObtain && (
                              <span className="text-danger">
                                {errors.postGraduationMarksObtain.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="postGraduationMaxMarks"
                            >
                              Total Marks / Total CGPA
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationMaxMarks"
                              type="number"
                              {...register("postGraduationMaxMarks", {
                                required: "postGraduationMaxMarks is required",
                              })}
                            />
                            {errors.postGraduationMaxMarks && (
                              <span className="text-danger">
                                {errors.postGraduationMaxMarks.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-2">
                            <label
                              className="small mb-1"
                              htmlFor="postGraduationPercentage"
                            >
                              Percentage
                            </label>
                            {/* <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationPercentage"
                              type="number"
                              {...register("postGraduationPercentage", {
                                required:
                                  "postGraduationPercentage is required",
                              })}
                            /> */}
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="postGraduationPercentage"
                              type="number"
                              value={postGraduationPercentage}
                              readOnly
                            />
                            {errors.postGraduationPercentage && (
                              <span className="text-danger">
                                {errors.postGraduationPercentage.message}
                              </span>
                            )}
                          </div>
                          {/* Repeat similar input fields for 12th details */}
                        </div>
                      </>
                    )}

                    {courseType === "PG" && (
                      <>
                        <div className="row gx-3 mb-3">
                          <h6>UG DETAILS </h6>
                          <div className="col-md-3">
                            <label
                              className="small mb-1"
                              htmlFor="graduationRollNo"
                            >
                              Roll No.
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationRollNo"
                              type="text"
                            />
                            {errors.graduationRollNo && (
                              <span className="text-danger">
                                {errors.graduationRollNo.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3">
                            <label
                              className="small mb-1"
                              htmlFor="graduationPassingYear"
                            >
                              Passing Year
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationPassingYear"
                              type="number"
                            />
                            {errors.graduationPassingYear && (
                              <span className="text-danger">
                                {errors.graduationPassingYear.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3">
                            <label
                              className="small mb-1"
                              htmlFor="graduationBoard"
                            >
                              University
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationBoard"
                              type="text"
                            />
                            {errors.graduationBoard && (
                              <span className="text-danger">
                                {errors.graduationBoard.message}
                              </span>
                            )}
                          </div>
                          <div className="col-md-3">
                            <label
                              className="small mb-1"
                              htmlFor="graduationPercentage"
                            >
                              CGPA
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "8px" }}
                              className="form-control"
                              id="graduationPercentage"
                              type="number"
                            />
                            {errors.graduationPercentage && (
                              <span className="text-danger">
                                {errors.graduationPercentage.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {courseType === "PG_DIPLOMA" && (
                      <div className="row gx-3 mb-3">
                        <h6>PG DETAILS </h6>
                        <div className="col-md-3">
                          <label
                            className="small mb-1"
                            htmlFor="postGraduationRollNo"
                          >
                            Roll No.
                          </label>
                          <input
                            style={{ height: "40px", borderRadius: "8px" }}
                            className="form-control"
                            id="postGraduationRollNo"
                            type="text"
                          />
                          {errors.postGraduationRollNo && (
                            <span className="text-danger">
                              {errors.postGraduationRollNo.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label
                            className="small mb-1"
                            htmlFor="postGraduationPassingYear"
                          >
                            Passing Year
                          </label>
                          <input
                            style={{ height: "40px", borderRadius: "8px" }}
                            className="form-control"
                            id="postGraduationPassingYear"
                            type="number"
                          />
                          {errors.postGraduationPassingYear && (
                            <span className="text-danger">
                              {errors.postGraduationPassingYear.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label
                            className="small mb-1"
                            htmlFor="postGraduationBoard"
                          >
                            Board/University
                          </label>
                          <input
                            style={{ height: "40px", borderRadius: "8px" }}
                            className="form-control"
                            id="postGraduationBoard"
                            type="text"
                          />
                          {errors.postGraduationBoard && (
                            <span className="text-danger">
                              {errors.postGraduationBoard.message}
                            </span>
                          )}
                        </div>
                        <div className="col-md-3">
                          <label
                            className="small mb-1"
                            htmlFor="postGraduationPercentage"
                          >
                            CGPA
                          </label>
                          <input
                            style={{ height: "40px", borderRadius: "8px" }}
                            className="form-control"
                            id="postGraduationPercentage"
                            type="number"
                          />
                          {errors.postGraduationPercentage && (
                            <span className="text-danger">
                              {errors.postGraduationPercentage.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="row gx-3 mb-3">
                      <div className="col-md-3">
                        <button
                          disabled={isSubmitting}
                          className="btn btn"
                          type="submit"
                          style={{
                            backgroundColor: "#598392",
                            color: "white",
                            marginLeft: "15px",
                          }}
                        >
                          {" "}
                          {isSubmitting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default EntranceEligibilityForm;

// import React, { useState, useEffect } from "react";
// import { useForm } from "react-hook-form";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Container, Row, Col } from "react-bootstrap";
// import Table from "react-bootstrap/Table";
// import Button from "react-bootstrap/Button";
// import { Box } from "@chakra-ui/react";
// import swal from "sweetalert";

// const EntranceEligibilityForm = ({
//   eligible,
//   eligibilityGradPer,
//   courseType,
//   courseName,
//   courseBranch,
// }) => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     clearErrors,
//     watch,
//     getValues,
//   } = useForm();
//   const [IsEligible, setIsEligible] = useState(false);
//   const [showSecondForm, setShowSecondForm] = useState(false);
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [fileUrls, setFileUrls] = useState({
//     applicantPhoto: "",
//     applicantSignature: "",
//   });

//   ////////////////////////percentage///////////////////

//   // const [percentage, setPercentage] = useState({
//   //   graduation: 0,
//   //   postGraduation: 0,
//   // });
//   const [graduationPercentage, setGraduationPercentage] = useState();
//   const [postGraduationPercentage, setPostGraduationPercentage] = useState();

//   useEffect(() => {
//     const calcPercentage = (obtained, total) => {
//       return total && obtained ? ((obtained / total) * 100).toFixed(2) : 0;
//     };

//     const graduation = calcPercentage(
//       getValues("graduationMarksObtain"),
//       getValues("graduationMaxMarks")
//     );

//     const postGraduation = calcPercentage(
//       getValues("postGraduationMarksObtain"),
//       getValues("postGraduationMaxMarks")
//     );

//     setGraduationPercentage(graduation);
//     setPostGraduationPercentage(postGraduation);
//     // setPercentage({
//     //  graduationPercentage:graduation,
//     //   postGraduationPercentage: postGraduation,
//     // });
//   }, [
//     watch("graduationMarksObtain"),
//     watch("graduationMaxMarks"),
//     watch("postGraduationMarksObtain"),
//     watch("postGraduationMaxMarks"),
//   ]);
//   //////////////////////////////////////////////////

//   const navigate = useNavigate();

//   const uploadFileToCloudinary = async (file, fieldName) => {
//     try {
//       const formData = new FormData();
//       formData.append("file", file);
//       formData.append("upload_preset", "upload");

//       const response = await fetch(
//         "https://api.cloudinary.com/v1_1/dszawxz7y/image/upload",
//         {
//           method: "POST",
//           body: formData,
//         }
//       );

//       if (!response.ok) {
//         throw new Error("Failed to upload file to Cloudinary");
//       }

//       const data = await response.json();
//       return data.secure_url;
//     } catch (error) {
//       console.error("Error uploading file to Cloudinary:", error);
//       throw new Error("Error uploading file to Cloudinary");
//     }
//   };

//   const validateFile = async (file, fieldName) => {
//     try {
//       if (!file || file.size > 1024 * 1024) {
//         throw new Error("File size should be less than 1MB");
//       }

//       const fileUrl = await uploadFileToCloudinary(file, fieldName);

//       setFileUrls((prevFileUrls) => ({
//         ...prevFileUrls,
//         [fieldName]: fileUrl,
//       }));

//       clearErrors(fieldName);
//     } catch (error) {
//       console.error("File validation/upload error:", error);
//       throw error;
//     }
//   };

//   const handleFileChange = async (file, fieldName) => {
//     try {
//       if (!file) {
//         throw new Error("No file selected");
//       }

//       await validateFile(file, fieldName);
//     } catch (error) {
//       console.error("File validation/upload error:", error);
//     }
//   };

//   //////////////////eligibility////////////////

//   const onSubmit = async (data) => {
//     setIsSubmitting(true);

//     try {
//       const enteredCategory = data.category;
//       const enteredPercentage1 = graduationPercentage;
//       const enteredPercentage2 = postGraduationPercentage;
//       const eligibilityPercentage = eligibilityGradPer[enteredCategory];

//       // Perform the eligibility check immediately
//       let isEligible = false;
//       if (
//         !isNaN(enteredPercentage1) &&
//         enteredPercentage1 >= eligibilityPercentage &&
//         !isNaN(enteredPercentage2) &&
//         enteredPercentage2 >= eligibilityPercentage
//       ) {
//         isEligible = true;
//       }

//       // Check if not eligible
//       if (!isEligible) {
//         alert("You Are Not Eligible");
//         setIsSubmitting(false);
//         return; // Exit the submission process
//       }

//       // Proceed with form submission if eligible
//       const response = await axios.post(
//         "https://sssutms.ac.in/apitest/v2/entrancedetails",
//         {
//           name: data.name,
//           mobile: data.mobile,
//           email: data.email,
//           fathersname: data.fathersname,
//           mothersname: data.mothersname,
//           dob: data.dob,
//           religion: data.religion,
//           category: data.category,
//           gender: data.gender,
//           domicile: data.domicile,
//           address: data.address,
//           courseType: courseType,
//           courseBranch: courseBranch,
//           courseName: courseName,
//           academicFormData: {
//             tenthRollNo: data.tenthRollNo,
//             tenthPassingYear: data.tenthPassingYear,
//             tenthBoard: data.tenthBoard,
//             tenthPercentage: data.tenthPercentage,
//             twelfthPassingYear: data.twelfthPassingYear,
//             twelfthRollNo: data.twelfthRollNo,
//             twelfthBoard: data.twelfthBoard,
//             twelfthPercentage: data.twelfthPercentage,
//             graduationPassingYear: data.graduationPassingYear,
//             graduationRollNo: data.graduationRollNo,
//             graduationBoard: data.graduationBoard,
//             graduationMarksObtain: data.graduationMarksObtain,
//             graduationMaxMarks: data.graduationMaxMarks,
//             graduationPercentage: graduationPercentage,
//             postGraduationPassingYear: data.postGraduationPassingYear,
//             postGraduationRollNo: data.postGraduationRollNo,
//             postGraduationMarksObtain: data.postGraduationMarksObtain,
//             postGraduationMaxMarks: data.postGraduationMaxMarks,
//             postGraduationBoard: data.postGraduationBoard,
//             postGraduationPercentage: postGraduationPercentage,
//           },
//           fileUrls,
//         }
//       );

//       const id = response.data._id;
//       const name = response.data.name;
//       if (response.status === 500) {
//         alert("Something went wrong");
//       } else {
//         swal({
//           icon: "success",
//           title: "Form Submitted",
//           text: "Dear Student your Form has been submitted Successfully, Now pay the fee!",
//         }).then(() => {
//           navigate("/entrance/payment", {
//             state: { courseType, courseName, courseBranch, id, name },
//           });
//         });
//       }
//     } catch (error) {
//       // if (error.response?.data?.status === 402) {
//       //   const registered = error.response.data.Registered;
//       //   const id = registered[0]._id;
//       //   const name = registered[0].name;
//       //   navigate("/entrance/payment", {
//       //     state: { courseType, courseName, courseBranch, id, name },
//       //   });
//       // }
//       console.error("Error submitting form:", error);
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const handleContinue = () => {
//     setShowSecondForm(true);
//   };

//   // console.log(fileUrls)

//   return (
//     <>
//       <style>
//         {`
//       .eligible{
//       max-width: 91%;
//       margin-left:60px;
//        overflow-y: auto;
//         height: 42vh;
//         }

//         .basic{
//         width: 1230px;
//          margin-left:-50px;
//          }

//    @media (max-width:480px){

//          .eligible{
//       max-width: 91%;
//       margin-left:20px;
//        overflow-y: auto;
//         height: 42vh;
//         }

//         .basic{
//         width: 350px;
//          margin-left:20px;
//          }
//    }

//         `}
//       </style>
//       <div
//         fluid
//         className="container-overflow shadow p-3  mb-9 bg-body rounded eligible"
//       >
//         <Box
//           marginBottom={"20px"}
//           backgroundColor={"#264653"}
//           p={4}
//           h={50}
//           color="white"
//           mt={8}
//           borderRadius="md"
//           shadow="md"
//         >
//           Check Eligibility
//         </Box>
//         <Table responsive>
//           <tbody>
//             <tr>
//               <td>Eligibility</td>
//               <td>:</td>
//               <td
//                 style={{
//                   color: "#e63946",
//                   fontFamily: "inherit",
//                   fontSize: "15px",
//                   fontWeight: "bold",
//                 }}
//               >
//                 {eligible.eligibility}
//               </td>
//             </tr>
//             <tr>
//               <td>Eligibility %</td>
//               <td>:</td>
//               <td>
//                 <Row className="equal-width-boxes">
//                   <Col xs={6} md={2}>
//                     Gen: {eligibilityGradPer.gen}
//                   </Col>
//                   <Col xs={6} md={2}>
//                     SC: {eligibilityGradPer.sc}
//                   </Col>
//                   <Col xs={6} md={2}>
//                     ST: {eligibilityGradPer.st}
//                   </Col>
//                   <Col xs={6} md={2}>
//                     OBC: {eligibilityGradPer.obc}
//                   </Col>
//                   <Col xs={6} md={2}>
//                     PH: {eligibilityGradPer.ph}
//                   </Col>
//                 </Row>
//               </td>
//             </tr>
//           </tbody>
//         </Table>
//       </div>
//       {!showSecondForm && (
//         <Button
//           style={{
//             margin: "20px auto",
//             display: "block",
//             backgroundColor: "#264653",
//             color: "white",
//             width: "w-20 w-md-auto",
//             border: "none",
//             borderRadius: "5px",
//             fontSize: "15px",
//             padding: "10px 20px",
//           }}
//           onClick={handleContinue}
//         >
//           Continue
//         </Button>
//       )}
//       {showSecondForm && (
//         <>
//           <form onSubmit={handleSubmit(onSubmit)}>
//             <div className="container-xl px-1 mt-3">
//               {/* <hr className="mt-0 mb-4" /> */}

//               {/* <div className="col-xl-12" > */}
//               <div fluid className="basic">
//                 <div className=" card mb-4">
//                   <div
//                     className="card-header"
//                     style={{ backgroundColor: "#264653", color: "white" }}
//                   >
//                     Basic Details
//                   </div>
//                   <div className="card-body">
//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputFirstName">
//                           Name
//                         </label>
//                         <input
//                           style={{
//                             height: "40px",
//                             borderRadius: "8px",
//                             textTransform: "uppercase",
//                           }}
//                           className="form-control"
//                           id="inputFirstName"
//                           type="text"
//                           autoComplete="off"
//                           name="name"
//                           {...register("name", {
//                             required: "Name is required",
//                           })}
//                           placeholder="Enter your Name"
//                         />
//                         {errors.name && (
//                           <span className="text-danger">
//                             {errors.name.message}
//                           </span>
//                         )}
//                       </div>

//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputFirstName">
//                           Applicant Photo
//                         </label>
//                         <input
//                           style={{ height: "40px" }}
//                           className="form-control"
//                           id="inputFirstName"
//                           type="file"
//                           name="applicantPhoto"
//                           {...register("applicantPhoto", {
//                             required: true,
//                           })}
//                           onChange={(event) =>
//                             handleFileChange(
//                               event.target.files[0],
//                               "applicantPhoto"
//                             )
//                           }
//                         />
//                         {errors.applicantPhoto &&
//                           errors.applicantPhoto.type === "required" && (
//                             <span className="text-danger">
//                               This field is required
//                             </span>
//                           )}
//                         {errors.applicantPhoto &&
//                           errors.applicantPhoto.message && (
//                             <span className="text-danger">
//                               {errors.applicantPhoto.message}
//                             </span>
//                           )}
//                       </div>
//                     </div>
//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputFirstName">
//                           Mobile Number
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           name="mobile"
//                           autoComplete="off"
//                           id="inputOrgName"
//                           {...register("mobile", {
//                             required: true,
//                             pattern: /^[0-9]{10}$/,
//                           })}
//                           type="NUMBER"
//                         />

//                         {errors.mobile && errors.mobile.type === "required" && (
//                           <span className="text-danger">
//                             This field is required
//                           </span>
//                         )}
//                         {errors.mobile && errors.mobile.type === "pattern" && (
//                           <span className="text-danger">
//                             Invalid phone number format
//                           </span>
//                         )}
//                       </div>
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputFirstName">
//                           Signature
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           id="inputFirstName"
//                           type="file"
//                           name="applicantSignature"
//                           {...register("applicantSignature", {
//                             required: true,
//                             // validate: validateFile
//                           })}
//                           onChange={(event) =>
//                             handleFileChange(
//                               event.target.files[0],
//                               "applicantSignature"
//                             )
//                           }
//                         />
//                         {errors.applicantSignature &&
//                           errors.applicantSignature.type === "required" && (
//                             <span className="text-danger">
//                               Applicant Signature is required
//                             </span>
//                           )}
//                         {errors.applicantSignature &&
//                           errors.applicantSignature.message && (
//                             <span>{errors.applicantSignature.message}</span>
//                           )}
//                       </div>
//                     </div>
//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputOrgName">
//                           Father's Name
//                         </label>
//                         <input
//                           style={{
//                             height: "40px",
//                             borderRadius: "8px",
//                             textTransform: "uppercase",
//                           }}
//                           className="form-control"
//                           id="inputOrgName"
//                           type="text"
//                           autoComplete="off"
//                           name="fathersname"
//                           {...register("fathersname", {
//                             required: "fathersname is required",
//                           })}
//                           placeholder="Enter your father's name"
//                         />
//                         {errors.fathersname && (
//                           <span className="text-danger">
//                             {errors.fathersname.message}
//                           </span>
//                         )}
//                       </div>

//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputLocation">
//                           Mother's Name
//                         </label>
//                         <input
//                           style={{
//                             height: "40px",
//                             borderRadius: "8px",
//                             textTransform: "uppercase",
//                           }}
//                           className="form-control"
//                           id="inputLocation"
//                           type="text"
//                           autoComplete="off"
//                           name="mothersname"
//                           {...register("mothersname", {
//                             required: "mothersname is required",
//                           })}
//                           placeholder="Enter your mother's name"
//                         />
//                         {errors.mothersname && (
//                           <span className="text-danger">
//                             {errors.mothersname.message}
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputPhone">
//                           Date Of Birth
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           id="inputPhone"
//                           autoComplete="off"
//                           type="date"
//                           name="dob"
//                           {...register("dob", {
//                             required: "Date of Birth  is required",
//                           })}
//                           placeholder="Enter your date of birth"
//                         />
//                         {errors.dob && (
//                           <span className="text-danger">
//                             {errors.dob.message}
//                           </span>
//                         )}
//                       </div>

//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputLocation">
//                           Email
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           id="inputLocation"
//                           autoComplete="off"
//                           type="text"
//                           {...register("email", {
//                             required: true,
//                             pattern: /^\S+@\S+$/i,
//                           })}
//                           placeholder="ENTER YOUR EMAIL"
//                         />

//                         {errors.email && errors.email.type === "required" && (
//                           <span className="text-danger">
//                             This field is required
//                           </span>
//                         )}
//                         {errors.email && errors.email.type === "pattern" && (
//                           <span className="text-danger">
//                             Invalid email format
//                           </span>
//                         )}
//                       </div>

//                       <div className="col-md-6">
//                         <label className="mb-1" htmlFor="inputGender">
//                           Category
//                         </label>
//                         <select
//                           className="form-control"
//                           id="inputGender"
//                           name="category"
//                           style={{ height: "45px", borderRadius: "8px" }}
//                           {...register("category", {
//                             required: "category is required",
//                           })}
//                         >
//                           <option value="">Select Category </option>
//                           <option value="gen">Gen</option>
//                           <option value="obc">OBC</option>
//                           <option value="sc">SC</option>
//                           <option value="st">ST</option>
//                         </select>
//                         {errors.category && (
//                           <span className="text-danger">
//                             {errors.category.message}
//                           </span>
//                         )}
//                       </div>
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputGender">
//                           Gender
//                         </label>
//                         <select
//                           className="form-control"
//                           id="inputGender"
//                           name="gender"
//                           style={{ height: "45px", borderRadius: "8px" }}
//                           {...register("gender", {
//                             required: "gender is required",
//                           })}
//                         >
//                           <option value="">Select Gender</option>
//                           <option value="Male">Male</option>
//                           <option value="Female">Female</option>
//                         </select>
//                         {errors.gender && (
//                           <span className="text-danger">
//                             {errors.gender.message}
//                           </span>
//                         )}
//                       </div>
//                     </div>
//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputOrgName">
//                           Domicile
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           id="inputOrgName"
//                           type="text"
//                           name="domicile"
//                           {...register("domicile", {
//                             required: "Domicile is required",
//                           })}
//                           placeholder="ENTER YOUR DOMICILE"
//                         />
//                         {errors.domicile && (
//                           <span className="text-danger">
//                             {errors.domicile.message}
//                           </span>
//                         )}
//                       </div>
//                       <div className="col-md-6">
//                         <label className="small mb-1" htmlFor="inputLocation">
//                           Religion
//                         </label>
//                         <input
//                           style={{ height: "40px", borderRadius: "8px" }}
//                           className="form-control"
//                           id="inputLocation"
//                           type="text"
//                           name="religion"
//                           {...register("religion", {
//                             required: "Religion is required",
//                           })}
//                           placeholder="ENTER YOUR RELIGION"
//                         />
//                         {errors.religion && (
//                           <span className="text-danger">
//                             {errors.religion.message}
//                           </span>
//                         )}
//                       </div>
//                     </div>{" "}
//                     <div className="mb-3">
//                       <label className="small mb-1" htmlFor="inputEmailAddress">
//                         Address
//                       </label>
//                       <textarea
//                         style={{ height: "40px", borderRadius: "8px" }}
//                         className="form-control"
//                         id="inputAddress"
//                         type="address"
//                         name="address"
//                         {...register("address", {
//                           required: "Address is required",
//                         })}
//                         placeholder="ENTER YOUR ADDRESS "
//                       />
//                       {errors.address && (
//                         <span className="text-danger">
//                           {errors.address.message}
//                         </span>
//                       )}
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               {/* </div> */}
//               {/* ////////////////////////////Academic details//////////////////////////////////////// */}
//               {/* <div className="col-xl-12"> */}
//               <div fluid className="basic">
//                 <div className="card mb-4">
//                   <div
//                     className="card-header"
//                     style={{ backgroundColor: "#264653", color: "white" }}
//                   >
//                     Education Details
//                   </div>
//                   <div className="card-body">
//                     {["UG", "PG", "Diploma", "PG_DIPLOMA", "Ph_D"].includes(
//                       courseType
//                     ) && (
//                       <>
//                         <div className="row gx-3 mb-3">
//                           <h6>UG DETAILS</h6>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationRollNo"
//                             >
//                               Roll No.
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               autoComplete="off"
//                               id="graduationRollNo"
//                               {...register("graduationRollNo", {
//                                 required: "graduationRollNo is required",
//                               })}
//                               type="text"
//                             />
//                             {errors.graduationRollNo && (
//                               <span className="text-danger">
//                                 {errors.graduationRollNo.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationPassingYear"
//                             >
//                               Passing Year
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationPassingYear"
//                               type="number"
//                               {...register("graduationPassingYear", {
//                                 required: "graduationPassingYear is required",
//                               })}
//                             />
//                             {errors.graduationPassingYear && (
//                               <span className="text-danger">
//                                 {errors.graduationPassingYear.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationBoard"
//                             >
//                               University
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationBoard"
//                               type="text"
//                               autoComplete="off"
//                               {...register("graduationBoard", {
//                                 required: "graduationBoard is required",
//                               })}
//                             />
//                             {errors.graduationBoard && (
//                               <span className="text-danger">
//                                 {errors.graduationBoard.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationMarksObtain"
//                             >
//                               Obtain Marks / Obtain CGPA
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationMarksObtain"
//                               type="number"
//                               {...register("graduationMarksObtain", {
//                                 required: "graduationMarksObtain is required",
//                               })}
//                             />
//                             {errors.graduationMarksObtain && (
//                               <span className="text-danger">
//                                 {errors.graduationMarksObtain.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationMaxMarks"
//                             >
//                               Total Marks / Total CGPA
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationMaxMarks"
//                               type="number"
//                               {...register("graduationMaxMarks", {
//                                 required: "graduationMaxMarks is required",
//                               })}
//                             />
//                             {errors.graduationMaxMarks && (
//                               <span className="text-danger">
//                                 {errors.graduationMaxMarks.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationPercentage"
//                             >
//                               Percentage
//                             </label>
//                             {/* <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationPercentage"
//                               type="number"
//                               {...register("graduationPercentage", {
//                                 required: "graduationPercentage is required",
//                               })}
//                             /> */}
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationPercentage"
//                               type="number"
//                               value={graduationPercentage}
//                               readOnly
//                             />
//                             {errors.graduationPercentage && (
//                               <span className="text-danger">
//                                 {errors.graduationPercentage.message}
//                               </span>
//                             )}
//                           </div>
//                         </div>
//                         <div className="row gx-3 mb-3">
//                           <h6>PG DETAILS</h6>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="twelfthRollNo"
//                             >
//                               Roll No.
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationRollNo"
//                               autoComplete="off"
//                               {...register("postGraduationRollNo", {
//                                 required: "postGraduationRollNo is required",
//                               })}
//                               type="text"
//                             />
//                             {errors.postGraduationRollNo && (
//                               <span className="text-danger">
//                                 {errors.postGraduationRollNo.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="postGraduationPassingYear"
//                             >
//                               Passing Year
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationPassingYear"
//                               type="number"
//                               {...register("postGraduationPassingYear", {
//                                 required:
//                                   "postGraduationPassingYear is required",
//                               })}
//                             />
//                             {errors.postGraduationPassingYear && (
//                               <span className="text-danger">
//                                 {errors.postGraduationPassingYear.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="postGraduationBoard"
//                             >
//                               University
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationBoard"
//                               autoComplete="off"
//                               type="text"
//                               {...register("postGraduationBoard", {
//                                 required: "postGraduationBoard is required",
//                               })}
//                             />
//                             {errors.postGraduationBoard && (
//                               <span className="text-danger">
//                                 {errors.postGraduationBoard.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="postGraduationMarksObtain"
//                             >
//                               Obtain Marks/Obtain CGPA
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationMarksObtain"
//                               type="number"
//                               {...register("postGraduationMarksObtain", {
//                                 required:
//                                   "postGraduationMarksObtain is required",
//                               })}
//                             />
//                             {errors.postGraduationMarksObtain && (
//                               <span className="text-danger">
//                                 {errors.postGraduationMarksObtain.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="postGraduationMaxMarks"
//                             >
//                               Total Marks / Total CGPA
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationMaxMarks"
//                               type="number"
//                               {...register("postGraduationMaxMarks", {
//                                 required: "postGraduationMaxMarks is required",
//                               })}
//                             />
//                             {errors.postGraduationMaxMarks && (
//                               <span className="text-danger">
//                                 {errors.postGraduationMaxMarks.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-2">
//                             <label
//                               className="small mb-1"
//                               htmlFor="postGraduationPercentage"
//                             >
//                               Percentage
//                             </label>
//                             {/* <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationPercentage"
//                               type="number"
//                               {...register("postGraduationPercentage", {
//                                 required:
//                                   "postGraduationPercentage is required",
//                               })}
//                             /> */}
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="postGraduationPercentage"
//                               type="number"
//                               value={postGraduationPercentage}
//                               readOnly
//                             />
//                             {errors.postGraduationPercentage && (
//                               <span className="text-danger">
//                                 {errors.postGraduationPercentage.message}
//                               </span>
//                             )}
//                           </div>
//                           {/* Repeat similar input fields for 12th details */}
//                         </div>
//                       </>
//                     )}

//                     {courseType === "PG" && (
//                       <>
//                         <div className="row gx-3 mb-3">
//                           <h6>UG DETAILS </h6>
//                           <div className="col-md-3">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationRollNo"
//                             >
//                               Roll No.
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationRollNo"
//                               type="text"
//                             />
//                             {errors.graduationRollNo && (
//                               <span className="text-danger">
//                                 {errors.graduationRollNo.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-3">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationPassingYear"
//                             >
//                               Passing Year
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationPassingYear"
//                               type="number"
//                             />
//                             {errors.graduationPassingYear && (
//                               <span className="text-danger">
//                                 {errors.graduationPassingYear.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-3">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationBoard"
//                             >
//                               University
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationBoard"
//                               type="text"
//                             />
//                             {errors.graduationBoard && (
//                               <span className="text-danger">
//                                 {errors.graduationBoard.message}
//                               </span>
//                             )}
//                           </div>
//                           <div className="col-md-3">
//                             <label
//                               className="small mb-1"
//                               htmlFor="graduationPercentage"
//                             >
//                               CGPA
//                             </label>
//                             <input
//                               style={{ height: "40px", borderRadius: "8px" }}
//                               className="form-control"
//                               id="graduationPercentage"
//                               type="number"
//                             />
//                             {errors.graduationPercentage && (
//                               <span className="text-danger">
//                                 {errors.graduationPercentage.message}
//                               </span>
//                             )}
//                           </div>
//                         </div>
//                       </>
//                     )}
//                     {courseType === "PG_DIPLOMA" && (
//                       <div className="row gx-3 mb-3">
//                         <h6>PG DETAILS </h6>
//                         <div className="col-md-3">
//                           <label
//                             className="small mb-1"
//                             htmlFor="postGraduationRollNo"
//                           >
//                             Roll No.
//                           </label>
//                           <input
//                             style={{ height: "40px", borderRadius: "8px" }}
//                             className="form-control"
//                             id="postGraduationRollNo"
//                             type="text"
//                           />
//                           {errors.postGraduationRollNo && (
//                             <span className="text-danger">
//                               {errors.postGraduationRollNo.message}
//                             </span>
//                           )}
//                         </div>
//                         <div className="col-md-3">
//                           <label
//                             className="small mb-1"
//                             htmlFor="postGraduationPassingYear"
//                           >
//                             Passing Year
//                           </label>
//                           <input
//                             style={{ height: "40px", borderRadius: "8px" }}
//                             className="form-control"
//                             id="postGraduationPassingYear"
//                             type="number"
//                           />
//                           {errors.postGraduationPassingYear && (
//                             <span className="text-danger">
//                               {errors.postGraduationPassingYear.message}
//                             </span>
//                           )}
//                         </div>
//                         <div className="col-md-3">
//                           <label
//                             className="small mb-1"
//                             htmlFor="postGraduationBoard"
//                           >
//                             Board/University
//                           </label>
//                           <input
//                             style={{ height: "40px", borderRadius: "8px" }}
//                             className="form-control"
//                             id="postGraduationBoard"
//                             type="text"
//                           />
//                           {errors.postGraduationBoard && (
//                             <span className="text-danger">
//                               {errors.postGraduationBoard.message}
//                             </span>
//                           )}
//                         </div>
//                         <div className="col-md-3">
//                           <label
//                             className="small mb-1"
//                             htmlFor="postGraduationPercentage"
//                           >
//                             CGPA
//                           </label>
//                           <input
//                             style={{ height: "40px", borderRadius: "8px" }}
//                             className="form-control"
//                             id="postGraduationPercentage"
//                             type="number"
//                           />
//                           {errors.postGraduationPercentage && (
//                             <span className="text-danger">
//                               {errors.postGraduationPercentage.message}
//                             </span>
//                           )}
//                         </div>
//                       </div>
//                     )}

//                     <div className="row gx-3 mb-3">
//                       <div className="col-md-3">
//                         <button
//                           disabled={isSubmitting}
//                           className="btn btn"
//                           type="submit"
//                           style={{
//                             backgroundColor: "#598392",
//                             color: "white",
//                             marginLeft: "15px",
//                           }}
//                         >
//                           {" "}
//                           {isSubmitting ? (
//                             <span className="spinner-border spinner-border-sm"></span>
//                           ) : (
//                             "Submit"
//                           )}
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </form>
//         </>
//       )}
//     </>
//   );
// };

// export default EntranceEligibilityForm;
