import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function Result() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
        
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
 
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
        
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
                
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Results</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
                <br />

                <div className="tann">

                  <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>09 Nov 2024 :-</b></h4>
                  <br />

                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>  Diploma Pharmacy I Year (Supplementary) September – 2024</b></Link></h5>

                  <br />

                  <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>07 Nov 2024 :-</b></h4>
                  <br />

                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Arts (NEP) I Semester (Ex) June – 2024</b></Link></h5>
                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Arts (NEP) II Semester (Regular) June – 2024</b></Link></h5>
                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Arts (NEP) III Semester (Ex) June – 2024</b></Link></h5>
                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>  Bachelor of Arts (NEP) IV Semester (Regular) June – 2024</b></Link></h5>
                  <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Design VIII Semester (Regular) June – 2024</b></Link></h5>



                </div>

                <br />

                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>04 Nov 2024 :- </b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Science (NEP) II Semester (Regular) June – 2024</b></Link></h5>

                <br />

                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>30 Oct 2024 :- </b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Commerce (NEP) I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>  Bachelor of Commerce (NEP) II Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Commerce (NEP) III Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Commerce (NEP) IV Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Business Administration (NEP) I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Business Administration (NEP) II Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Business Administration (NEP) III Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Business Administration (NEP) IV Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Computer Application (NEP) II Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Computer Application (NEP) IV Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Science (NEP) I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Science (NEP) III Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Science (NEP) IV Semester (Regular) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>29 Oct 2024   :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma in Engineering II Semester (Regular) June  – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>26 Oct 2024    :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Pharmacy II Semester (Regular) June  – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>25 Oct 2024   :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Pharmacy I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Pharmacy II Semester (Ex) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>22 Oct 2024 :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Diploma Dialysis Technician I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Dialysis Technician I Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Dialysis Technician II Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>  Diploma Medical Lab Technician I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Medical Lab Technician I Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma AYURVEDA I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Diploma AYURVEDA II Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Diploma X-Ray Technician I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Diploma X-Ray Technician II Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma YOGA I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Homoeopathy I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.P.T. I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>B.P.T. II Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>B.P.T. III Year (Supplementary) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>M.P.T. (ORTHOPAEDIC) I Year (Regular) August – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=" "><b>M.M.L.T. (HAEMOTOLOGY) I Year (Regular) August – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>21 Oct 2024 :-  </b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=" "><b>B.Sc. (Nursing) I Semester (Ex) December – 2023</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=" "><b>B.Sc. (Nursing) II Semester (Regular/Ex) December – 2023</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=" "><b>B.Sc. (Nursing) III Semester (Regular) December – 2023</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=" "><b>  B.Sc. (Nursing) III Year (Regular) December – 2023</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.Sc. (Nursing) IV Year (Regular) December – 202319 Oct 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>19 Oct 2024  :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Architecture II Semester (Regular) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>17 Oct 2024 :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Engineering IV Semester (Ex) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>16 Oct 2024 :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Engineering IV Semester (Regular) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>15 Oct 2024  :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Engineering III Semester (Ex) June – 2024</b></Link></h5>
                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>14 Oct 2024  :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Engineering I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Engineering III Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Diploma Engineering IV Semester (Regular) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.A. B.Ed. I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.A. B.Ed. II Semester (Regular/Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.A. B.Ed. III Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>  B.A. B.Ed. IV Semester (Regular/Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> B.A. B.Ed. V Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>B.A. B.Ed. VI Semester (Regular/Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>B.A. B.Ed. VII Semester (Ex) June – 2024</b></Link></h5>

                <br />
                <h4 style={{ marginLeft: "40px", color: "#6f1d1b", textDecoration: "underline" }}><b>11 Oct 2024  :-</b></h4>
                <br />

                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b> Bachelor of Engineering I Semester (Ex) June – 2024</b></Link></h5>
                <h5 style={{ color: "#023e8a", marginLeft: "10%" }}><i class="fa fa-share" aria-hidden="true" style={{ marginRight: "30px", color: "#9b2226" }}> </i> <Link to=""><b>Bachelor of Engineering II Semester (Regular) June – 2024</b></Link></h5>


              </p>
              <br />

            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to="/Entrance-Exam-Alert">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Entrance Exam Alert
                    </Link>
                  </li>
                </Link>
                <Link to="/Exam_Notification">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Exam Notifications
                    </Link>
                  </li>
                </Link>
                <Link to="/Exam-Schedule">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Exam Schedule
                    </Link>
                  </li>
                </Link>
                <Link to="/Result">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Results
                    </Link>
                  </li>
                </Link>
                <Link to="/Interface">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Interface
                    </Link>
                  </li>
                </Link>

              </ul>
            </div>
          </div>

        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Result;

