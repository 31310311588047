// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import HodDashboard from "../HodDashboard";
// import "../../Main_Admin/Masters/Admin_Dashboard/Updt_Manual_Payment.css";

// const Provisional_Payment = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const { student } = location.state || {};
//   console.log(student);

//   const [formData, setFormData] = useState({
//     reciept_No: '',
//     paymentMode: '',
//     Amount:'',
//     Remark:"Student Admitted through Provisional Amount ",
//     payment_date:'',
//     status: '',
//     studentId: student ? student._id : '',
//     name: student ? student.name : '',
//     randomId: student ? student.randomId : '',
//     email: student ? student.email : '',
//     mobile: student ? student.mobile : '',
//     fathersname: student ? student.fathersname : '',
//     mothersname: student ? student.mothersname : '',
//     courseName: student ? student.courseName : '',
//     courseType: student ? student.courseType : '',
//     courseBranch: student ? student.courseBranch : '',
//     session: student ? student.session : '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post(
//         "https://sssutms.ac.in/apitest/provisional-payment",
//         formData
//       );
//       console.log();
//       const payment = response.data.payment;
//       navigate("/student/provisional/receipt", { state: { student, payment } });
//     } catch (error) {
//       console.error(error);
//       alert("Failed to save payment information");
//     }
//   };

//   return (
//     <>

//       <HodDashboard />
//       <div className="payment-form-container">
//         <h2 className="form-title">Payment Information</h2>
//         <form onSubmit={handleSubmit} className="payment-form">
//           {Object.keys(formData).map(
//             (key) =>
//               key !== "status" &&
//               key !== "studentId" && (
//                 <div key={key} className="form-group">
//                   <label className="form-label">
//                     {key.replace(/([A-Z])/g, " $1")}
//                   </label>
//                   <input
//                     type="text"
//                     name={key}
//                     value={formData[key]}
//                     onChange={handleChange}
//                     className="form-input"
//                     required
//                   />
//                 </div>
//               )
//           )}
//           <button type="submit" className="submit-button">
//             Save Payment
//           </button>
//         </form>
//       </div>
//     </>
//   );
// };

// export default Provisional_Payment;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import HodDashboard from "../HodDashboard";
import "../../Main_Admin/Masters/Admin_Dashboard/Updt_Manual_Payment.css";

const Provisional_Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { student } = location.state || {};


  const [formData, setFormData] = useState({
    reciept_No: "",
    paymentMode: "",
    Amount: "",
    Remark: "Student Admitted through Provisional Amount",
    payment_date: "",
    status: "",
    studentId: student ? student._id : "",
    name: student ? student.name : "",
    randomId: student ? student.randomId : "",
    email: student ? student.email : "",
    mobile: student ? student.mobile : "",
    fathersname: student ? student.fathersname : "",
    mothersname: student ? student.mothersname : "",
    courseName: student ? student.courseName : "",
    courseType: student ? student.courseType : "",
    courseBranch: student ? student.courseBranch : "",
    session: student ? student.session : "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/provisional-payment",
        formData
      );

      const payment = response.data.payment;
      navigate("/student/provisional/receipt", { state: { student, payment } });
    } catch (error) {
      console.error(error);
      alert("Failed to save payment information");
    }
  };

  return (
    <>
      <HodDashboard />
      <div className="payment-form-container">
        <h2 className="form-title">Payment Information</h2>
        <form onSubmit={handleSubmit} className="payment-form">
          {Object.keys(formData).map(
            (key) =>
              key !== "status" &&
              key !== "studentId" && (
                <div key={key} className="form-group">
                  <label className="form-label">
                    {key.replace(/([A-Z])/g, " $1")}
                  </label>
                  <input
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    className="form-input"
                    placeholder={
                      key === "payment_date"
                        ? "12-05-2024 01:23 pm"
                        : `Enter ${key.replace(/([A-Z])/g, " $1")}`
                    }
                    disabled={
                      key !== "reciept_No" &&
                      key !== "Amount" &&
                      formData[key] !== "" &&
                      key !== "payment_date" &&
                      key !== "session" &&
                      key !== "paymentMode"
                    }
                    required
                  />
                </div>
              )
          )}
          <button type="submit" className="submit-button">
            Save Payment
          </button>
        </form>
      </div>
    </>
  );
};

export default Provisional_Payment;
