import React, { useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import swal from "sweetalert";
import RegistrationDetailed from "./StudentRegistrationDetailed";

const EligibilityForm = ({
  eligible,
  eligibilityGradPer,
  courseType,
  courseName,
  courseBranch,
}) => {
  const [formData, setFormData] = useState({
    showRegistrationForm: false,
  });

  const openRegistrationForm = () => {
    swal({
      title: "Success",
      text: "Eligibility Check Successful!",
      icon: "success",
    }).then(() => {
      setFormData({
        ...formData,
        showRegistrationForm: true,
      });
    });
  };

  return (
    <>
    <Container
      className="shadow p-3 bg-light rounded"
      style={{
        maxWidth: "90%", 
        margin: "30px auto",
        padding: "20px",
      }}
    >
      <h4
        style={{
          backgroundColor: "#264653",
          color: "white",
          fontFamily: "'Poppins', sans-serif",
          padding: "10px",
          fontSize: "18px", 
          borderRadius: "5px",
          textAlign: "left",
        }}
      >
        Check Eligibility
      </h4>
      <Table bordered className="mt-3">
        <tbody>
          <tr>
            <td>Eligibility</td>
            <td>{eligible.eligibility}</td>
          </tr>
          <tr>
            <td>Eligibility %</td>
            <td>
              <Row>
                <Col>General: {eligibilityGradPer.gen}%</Col>
                <Col>SC: {eligibilityGradPer.sc}%</Col>
                <Col>ST: {eligibilityGradPer.st}%</Col>
                <Col>OBC: {eligibilityGradPer.obc}%</Col>
                <Col>PH: {eligibilityGradPer.ph}%</Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>

      {!formData.showRegistrationForm && (
        <div className="text-center">
        <Button
          className="mt-3"
          onClick={openRegistrationForm}
          style={{
            backgroundColor:  "#264653",
            border: "none",
            fontSize: "15px", padding: "10px 20px"
          }}
        >
          Search
        </Button>
        </div>
      )}

 
    </Container>
         {formData.showRegistrationForm && (
        <RegistrationDetailed
          courseType={courseType}
          courseName={courseName}
          courseBranch={courseBranch}
          eligibility={eligible}
          eligibilityGradPer={eligibilityGradPer}
        />
      )}
    
    </>
    
    
  );
};

export default EligibilityForm;

