import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
import sid from "../images/sid.jpg";
import { Link } from "react-router-dom";

function Chanceller() {
  return (
    <>
      <Nav />
      <style>
        {`
    .contentleader
{
text-align:justify;
width:100%;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;;
margin-left:10px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      {/* <section className="section_all " id="about" > */}

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Mr. Siddharth Kapoor
        </h2>
        <hr className="title-hr"></hr>
        <h2
          className="h3  chan text-bold"
          style={{ fontFamily: "'poppins',sansSerif" }}
        >
          Chancellor, SSSUTMS
        </h2>
        <div className="row dirr">
          <div class="col-lg-2 " style={{ marginTop: "40px" }}>
            {/* 
                            <div className="dean-img-box"> */}

            <img className="img-fluid max-height-500" src={sid} alt="img" />
          </div>
          {/* </div> */}

          <div className="col-lg-8 padding-section">
            {/* <div className="left_text_testi_contetn"> */}
            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif" }}
              >
                Young & Dynamic Chancellor of Sri Satya Sai University of
                Technology & Medical Sciences Honourable Mr. Siddharth Kapoor
                holds Degree in Family Management Business Administration (FMBA)
                from SP Jain, Mumbai. He also did his Masters of Business
                Administration (Finance) Degree from Temple University, Fox
                School of Business, Philadelphia after completing PA Bachelor of
                Business Administration (Finance) from Temple University, Fox
                School of Business, Philadelphia,PA. Mr. Kapoor is associated
                with Largest Educational Group of Central India from last 14
                years & is also Project head - Radio Popcorn. He has Led
                Analysis, design and Development of FM Station & is actively
                associated with total Diagnosis Private. Ltd., Bhopal, (India)
                from last 14 years.<br></br>
                <br></br>
                Mr. Kapoor is analyst of the Financial Management and
                Accountancy System. He is member of Ascend (SPO), an Association
                for Finance and Accounting Majors, Member & SOS Village Society,
                one of India’s largest Society for orphans. He has qualified and
                Won State level Swimming and Football competition. Honouable
                Governor of Madhya Pradesh Shri Ram Naresh Yadav has approved
                appointment of Shri Sidharth Kapoor as Chancellor of the
                University & under his guidance, the University will fulfil
                dreams & aspirations of young generation.
              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                University Officials
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to ="/chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chancellor
               </Link>
              </li>
              <li className="lli">
               <Link to ="/vice_chancellor">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Vice Chancellor
               </Link>
              </li>
              <li className="lli">
               <Link to ="/registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Registrar{" "}
               </Link>
              </li>
              <li className="lli">
               <Link to ="/exam_controller">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exam Controller
               </Link>
              </li>
              <li className="lli">
               <Link to ="/deputy_registrar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Deputy
                  Registrar{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/governing_body">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Governing Body{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/board_of_management">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Board of
                  Management{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/academic_council">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Council{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/finance_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Finance
                  committee{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/standing_committee">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Standing
                  Committee{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/statutes">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Statutes{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/audit_report">
                  <i class="fa fa-adjust" aria-hidden="true"></i>AUDIT REPORT{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/dean">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Dean{" "}
               </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to ="/head_of_department">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Head of the
                  Department{" "}
               </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default Chanceller;
