////////////////////////criteria 4/////////////////////////

import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function Criteria4() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          {" "}
          Criteria Four
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <br />

            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              Criteria 4
            </h5>

            <style>
              {`
      //   ///////////////////////////////////////////////////////////////////////////////////
      .tann{
      margin-left:120px;
      }
      
      .table {
           border-collapse: collapse;
           width: 95%;
            margin-left:50px;
              font-family: 'poppins',sansSerif;
         }
         
         .table,
         .table th,
         .table td {
           border: 1px solid #ccc;
         }
         
         .table th,
         .table td {
           padding: 0.5rem;
         }
         
         .table th {
           position: relative;
            cursor: grab;
               user-select: none;
         }   
            .table th:active {
               cursor: grabbing;
             }
               
             .wide-cell {
      width: 200px; 
      text-align:center;/* Adjust width as needed */
    }`}
            </style>
            {/* <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Training and Placement Cell</h5> */}
            <div className="tann">
              <table
                id="resizeMe"
                className="resizable-table table table-hover"
              >
                <tbody>
                  <tr>
                    <td>4.1.1</td>
                    <td>
                      Physical Facilites
                      <ul style={{ marginLeft: "20px" }}>
                        <li> Facilities for Teaching - Learning</li>
                      </ul>{" "}
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.1.1.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>4.1.2</td>
                    <td>
                      {" "}
                      Facilities for Cultural Activities, Yoga, Games and Sports
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.1.2.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>4.1.3</td>
                    <td>General Campus Facilities </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.1.3.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>4.1.4</td>
                    <td>
                      CA Certificate & Audited Income and Expenditure Statement{" "}
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.1.4.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.2</td>
                    <td>
                      Number of seats earmarked for reserved category as per
                      GOI/State Govt. Rule year-wise during last five year{" "}
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.2.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.2.1</td>
                    <td> Library as a Learning Resource </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.2.1.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.2.2</td>
                    <td>
                      Institution has subscription for e-Library resources{" "}
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.2.2.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.3.1</td>
                    <td>
                      {" "}
                      Percentage of classrooms and seminar halls with ICT -
                      enabled facilities such as LCD, smart board, Wi-Fi/LAN,
                      audio video recording facilities .(Data only for the
                      latest completed academic year){" "}
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.3.1.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.3.2</td>
                    <td> IT Services Policy</td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.3.2.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.3.3</td>
                    <td>
                      Summary of Computer Distribution , Declaration
                      Certificate, Computer Invoices
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.3.3.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>4.3.4</td>
                    <td>
                      {" "}
                      Available bandwidth of internet connection in the
                      Institution
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.3.4.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>

                  <tr>
                    <td>4.3.5</td>
                    <td>
                      Institution has the following Facilities for e-content
                      development
                    </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.3.5 .pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>4.4.2</td>
                    <td>Maintenance policy </td>
                    <td className="wide-cell">
                      <Link to="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/NAAC/4.4.2.pdf">
                        Click here
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h4
                style={{
                  fontFamily: "'poppins',sansSerif",
                  color: "#243642",
                  textDecoration: "underline",
                }}
              >
                NAAC
              </h4>
              <br></br>{" "}
              <li className="lli">
                <Link to="/SSR">
                  <i class="fa fa-adjust" aria-hidden="true"></i>SSR
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria1">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-1
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria2">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-2
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria3">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-3
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria4">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-4
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria5">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-5
                </Link>
              </li>
              <li className="lli">
                <Link to="/criteria6">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Criteria-6
                </Link>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <br></br>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default Criteria4;
