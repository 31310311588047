import React, { useState } from "react";
import swal from "sweetalert";
import { Link, useNavigate } from "react-router-dom";
import posImage from "./12.png";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const StudentDashboard = (props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    if (userData.courseName) {
      if (userData.isEnrolled === false) {
        swal({
          icon: "warning",
          title: "Enrollement form",
          text: "Please fill enrollement form first",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        }).then((value) => {
          if (value) {
            navigate("/student/Enrollement/Form");
          }
        });
      } else if (
        userData.isEnrolled === true &&
        userData.isEnrollPaid === false
      ) {
        swal({
          icon: "warning",
          title: "Enrollement form",
          text: "You haven't paid enrollment Fee yet please by click on OK ",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        }).then((value) => {
          if (value) {
            navigate("/Epravesh/Student/EnrollementFee");
          }
        });
      } else if (
        userData.isEnrolled === true &&
        userData.isEnrollPaid === true
      ) {
        swal({
          icon: "success",
          title: "Enrollement form",
          text: "Your have Already Filled Enrollement Form",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        });
      } else {
        swal({
          icon: "success",
          title: "Enrollement form",
          text: "Your have Already Filled Enrollement Form",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        });
      }
    }
  };

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  const isEnrolled = userData && userData.isEnrolled;
  const buttonClassName = isEnrolled ? "disabled" : "enabled";

  return (
    <>
      <style>
        {`
        /* Google Font Link */
        @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

        /* General styles */
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          font-family: "Poppins", sans-serif;
        }
        .logg{
          height: 50px;
           width: 50px;
           border-radius:10px;
            margin-left:-10px;
            }

           .dropdown-toggle{

                      padding: 3px;
                      white-space: normal;
                      word-wrap: break-word;
                      max-width: 250px;
                      display: inline-block;
                    margin-top:10px;  margin-left:10px;
                    }

            .profile_img1{
              width: 45px;

                      height: 45px;

                      object-fit: cover;
                      border-radius: 50%;
                      }
        /* Sidebar styles */
        .sidebar {
          position: fixed;
          left: 0;
          top: 0;
          height: 100%;
          width: 76px;
          // background: #11101D;
          // background:linear-gradient(to right  , #b15b86,#440f50);
          background: #003049;
          padding: 6px 14px;
          z-index: 99;
          transition: all 0.5s ease;
        }

        .sidebar.open {
          width: 250px;
        }

        .sidebar .logo-details {
          height: 60px;
          display: flex;
          align-items: center;
          position: relative;
        }

        .sidebar .logo-details .icon,
        .sidebar .logo-details .logo_name {
          opacity: 0;
          transition: all 0.5s ease;
        }

        .sidebar.open .logo-details .icon,
        .sidebar.open .logo-details .logo_name {
          opacity: 1;
        }

        .sidebar .logo-details #btn {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          font-size: 23px;
          text-align: center;
          cursor: pointer;
          transition: all 0.5s ease;
        }

        .sidebar.open .logo-details #btn {
          text-align: right;
        }

        .sidebar i {
          color: #fff;
          height: 60px;
          min-width: 50px;
          font-size: 28px;
          text-align: center;
          line-height: 60px;
        }

        .sidebar .nav-list {
          margin-top: 20px;
        }

        .sidebar li {
          position: relative;
          margin: 8px 0;
          list-style: none;
        }

        .sidebar li .tooltip {
          position: absolute;
          top: -20px;
          left: calc(100% + 15px);
          z-index: 3;
          background: #fff;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          padding: 6px 12px;
          border-radius: 4px;
          font-size: 15px;
          font-weight: 400;
          opacity: 0;
          white-space: nowrap;
          pointer-events: none;
          transition: 0s;
        }

        .sidebar li:hover .tooltip {
          opacity: 1;
          pointer-events: auto;
          transition: all 0.4s ease;
          top: 50%;
          transform: translateY(-50%);
        }

        .sidebar.open li .tooltip {
          display: none;
        }

        .sidebar input {
          font-size: 15px;
          color: #fff;
          font-weight: 400;
          outline: none;
          height: 50px;
          width: 50px;
          border: none;
          border-radius: 12px;
          transition: all 0.5s ease;
          background: #1d1b31;
        }

        .sidebar.open input {
          padding: 0 20px 0 50px;
          width: 100%;
        }

        .sidebar li a {
          display: flex;
          height: 100%;
          width: 100%;
          border-radius: 12px;
          align-items: center;
          text-decoration: none;
          transition: all 0.4s ease;
          background: #11101D;
        }

        .sidebar li a:hover {
          background: #FFF;
        }

        .sidebar li a .links_name {
          color: #fff;
          font-size: 15px;
          font-weight: 400;
          white-space: nowrap;
          opacity: 0;
          pointer-events: none;
          transition: 0.4s;
        }

        .sidebar.open li a .links_name {
          opacity: 1;
          pointer-events: auto;
        }

        .sidebar li a:hover .links_name,
        .sidebar li a:hover i {
          transition: all 0.5s ease;
          color: #11101D;
        }

        .sidebar li i {
          height: 50px;
          line-height: 50px;
          font-size: 18px;
          border-radius: 12px;
        }

        .sidebar li.profile {
          position: fixed;
          height: 60px;
          width: 78px;
          left: 0;
          bottom: -8px;
          padding: 10px 14px;
          background: #1d1b31;
          transition: all 0.5s ease;
          overflow: hidden;
        }

        .sidebar.open li.profile {
          width: 250px;
        }

        .sidebar li .profile-details {
          display: flex;
          align-items: center;
          flex-wrap: nowrap;
        }

        .sidebar li img {
          height: 45px;
          width: 45px;
          object-fit: cover;
          border-radius: 6px;
          margin-right: 10px;
        }

        .sidebar li.profile .name,
        .sidebar li.profile .job {
          font-size: 15px;
          font-weight: 400;
          color: #fff;
          white-space: nowrap;
        }

        .sidebar li.profile .job {
          font-size: 12px;
        }

        .sidebar .profile #log_out {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          background: #1d1b31;
          width: 100%;
          height: 60px;
          line-height: 60px;
          border-radius: 0px;
          transition: all 0.5s ease;
        }

        .sidebar.open .profile #log_out {
          width: 50px;
          background: none;
        }
        .navbar{
        display:none;
        }
        /* Home section */
        .home-section {
          position: relative;
          background: #E4E9F7;
          min-height: 100vh;
          top: 0;
          left: 78px;
          width: calc(100% - 78px);
          transition: all 0.5s ease;
          z-index: 2;
        }

        .sidebar.open ~ .home-section {
          left: 250px;
          width: calc(100% - 250px);
        }

        .home-section .text {
          display: inline-block;
          color: #11101d;
          font-size: 25px;
          font-weight: 500;
          margin: 18px;
        }

        /* Media query for smaller screens */
        @media (max-width: 480px) {
         .sidebar {
    display: none; /* Hides the sidebar */
  }

.logg{
display:none
}

// ///////////////////////////////////////////////////////
// //////////////////////////////////////////////////////

 /* Hamburger icon styles */
.openbtn {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin-left:10px;
}

.openbtn .openbtn-area {
    transition: all 0.4s;
}

.openbtn span {
    position: absolute;
    background: #fff;
    display: inline-block;
    border-radius: 3px;
    left: 12px;
    height: 3px;
    transition: all 0.4s;
    width: 7%;
}

.openbtn span:nth-of-type(1) {
    top: 16px;
        margin-left:10px;

}

.openbtn span:nth-of-type(2) {
    top: 24px;
        margin-left:10px;

}

.openbtn span:nth-of-type(3) {
    top: 32px;
        margin-left:10px;

}

.openbtn.active .openbtn-area {
    transform: rotateY(-360deg);
}

.openbtn.active span:nth-of-type(1) {
    width: 45%;
    top: 18px;
    left: 14px;
    transform: translateY(6px) rotate(-135deg);
}

.openbtn.active span:nth-of-type(2) {
    opacity: 0;
}

.openbtn.active span:nth-of-type(3) {
    width: 45%;
    top: 30px;
    left: 14px;
    transform: translateY(-6px) rotate(135deg);
}

/* Navbar styles */
.navbar {
    display: none; /* Hidden by default */
    position: absolute;
    top: 60px; /* Adjust based on your design */
    left: 0;
    right: 0;
    background: #10568f; /* Background color */
    padding: 10px 0;
    z-index: 999; /* Ensure it appears above other elements */
}.close-btn {
    background: none; /* Transparent background */
    border: none; /* No border */
    color: #fff; /* White color for visibility */
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer; /* Pointer cursor */
    position: absolute; /* Position it inside the navbar */
    top: 10px; /* Adjust top position */
    right: 10px; /* Adjust right position */
    transition: color 0.3s; /* Smooth color transition */
}

.close-btn:hover {
    color: #f00; /* Change color on hover */
}

/* Navbar styles */
.navbar {
    display: none; /* Hidden by default */
    transition: all 0.4s; /* Smooth transition */
}

.navbar.active {
    display: block; /* Show when active */
    background: #333; /* Background color for the navbar */
    padding: 20px; /* Padding for the navbar */
    width:auto;
    margin-left:-60px;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; /* Center the links */
}

.navbar ul li {
    margin: 0 15px; /* Spacing between links */
}
.dropdown-menu{
}
.navbar ul li a {
    color: #fff; /* Link color */
    text-decoration: none; /* Remove underline */
}

.navbar.active {
width:450px;
    display: block; /* Show the navbar when active */
}

.profile_img1{
margin-left:-30px}

.namee{

display:none}
          .heading{
    display:none}
        }
        }
        `}
      </style>

      <meta charSet="UTF-8" />
      <link
        href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
        rel="stylesheet"
      />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              top: 0,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              background: "#003049",
            }}
          >
            <Toolbar>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img className="logg" src={posImage} alt="" />
                <div>
                  <div
                    className={`openbtn ${isOpen ? "active" : ""}`}
                    onClick={toggleNavbar}
                  >
                    <div className="openbtn-area">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>

                  <nav className={`navbar ${isOpen ? "active" : ""}`}>
                    <button className="close-btn" onClick={closeNavbar}>
                      ✖
                    </button>
                    <ul>
                      <li>
                        <Link to="/studentalldetail">Home</Link>
                      </li>

                      <li>
                        <a className={buttonClassName} onClick={handleClick}>
                          Enrollment
                          <span className="links_name"></span>
                        </a>
                      </li>
                      <li>
                        <Link to ="/student/slip">Verify Online Payment</Link>
                      </li>
                      <li>
                        <Link to="#">Admit Card</Link>
                      </li>
                    </ul>
                  </nav>

                  <style jsx>{`
                    .close-icon {
                      margin-left: 8px; /* Spacing between link and icon */
                      cursor: pointer; /* Pointer cursor for hover */
                      color: #fff; /* Color of the close icon */
                    }
                    .close-icon:hover {
                      color: #f00; /* Change color on hover */
                    }
                  `}</style>
                </div>

                <Typography
                  className="heading"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    textShadow: "2px 2px 5px red",
                    marginLeft: "30px",
                    fontSize: "19px",
                  }}
                >
                  SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
                </Typography>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginLeft: "320px",
                  }}
                >
                  <img
                    class="profile_img1"
                    src={userData?.Documents?.applicantPhoto}
                    alt="img"
                  ></img>

                  <a
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="namee" style={{ wordBreak: "break-word" }}>
                      {userData?.name}
                    </span>
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <Link to="/student/reset/password">
                      <li>
                        <a className="dropdown-item">Change Password</a>
                      </li>
                    </Link>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <Link to="/erp/studentlogin">
                      <li>
                        <a className="dropdown-item">Log out</a>
                      </li>
                    </Link>
                  </ul>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
        <div className="sidebar">
          <div className="logo-details">
            <i className="bx bxl-c-plus-plus icon" />

            <i className="bx bx-menu" id="btn" />
          </div>
          <ul className="nav-list">
            <Link to="/studentalldetail">
              <li>
                <a>
                  <i className="bx bx-grid-alt" />
                  <span className="links_name"></span>
                </a>
                <span className="tooltip">Home</span>
              </li>
            </Link>

            <Link to="">
              <li>
                <a className={buttonClassName} onClick={handleClick}>
                  <i className="bx bx-book-open"></i>
                  <span className="links_name"></span>
                </a>
                <span className="tooltip">Enrollment</span>
              </li>
            </Link>

            <Link to="/student/slip">
              <li>
                <a>
                  <i class="bx bx-receipt"></i>
                  <span className="links_name"></span>
                </a>
                <span className="tooltip">Verify Online Payment</span>
              </li>
            </Link>
            {/* <Link to="/erp/student/studentexamform"> */}
              <li>
                <a>
                  <i class="bx bx-id-card"></i>
                  <span className="links_name"></span>
                </a>
                <span className="tooltip">Examination Form</span>
              </li>
            {/* </Link> */}
            <li>
              <Link to="/erp/studentlogin">
                <a>
                  <i class="bx bx-log-out"></i>
                  <span className="links_name"></span>
                </a>
                <span className="tooltip">Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </ThemeProvider>
    </>
  );
};

export default StudentDashboard;
