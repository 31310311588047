import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function EventCalender() {
  return (
    <>
      <Nav />
      <style>
        {`

  

.cont{
margin-top:20px;}



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-18px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 118px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}


 



@media only screen and (max-width: 480px){




.h2 title-main{
margin-left:18px}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
         EVENT CALENDER
        </h2>
        <hr className="title-hr"></hr>

        <div className="row dirr">
          <br></br>
        

          <div className="col-lg-9 padding-section">
       

            <br></br>
            <h5
            style={{
              fontFamily: "'poppins',sansSerif",
              textAlign: "center",
              color: "#FA812F",
              fontFamily: "'poppins',sansSerif",
            }}
          >
            Event Calender for the Year 2024-25
          </h5>
            <ul className="list-icon" style={{ marginTop: "50px" }}>
        
              <li className="llis">
 
    <i className="fa fa-share" aria-hidden="true"></i>{" "}
    <span
      style={{
        fontWeight: 700,
        fontFamily: "'poppins',sansSerif",
      }}
    >
      {" "}
      FACULTY OF EDUCATION  FOR THE YEAR
      2024-25{" "}
    </span>


  {/* Nested ul for additional li items */}
  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
      to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
        target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>

{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
          B.ED Department Calendar
        </span>
      </Link>
    </li>
    <li>
      <Link
         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
        target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
          B.A. B.ED Department Calendar
        </span>
      </Link>
    </li>
    <li>
      <Link
          to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
        target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
          B.P.ED Department Calendar
        </span>
      </Link>
    </li>
  </ul>
</li>

              <li className="llis">
              
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    {" "}
                    POLYTECHNIC PHARMACY FOR THE YEAR 2024-25{" "}
                  </span>
                
                <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Polytechnic%20Pharmacy.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
         PHARMACY DEPARTMENT
        </span>
      </Link>
    </li>

  </ul>





              </li>
              <li className="llis">
               
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    {" "}
                    SCHOOL OF POLYTECHNIC ENGINEERING  FOR THE YEAR 2024-25{" "}
                  </span>
             
                <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Polytechnic engineering.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
        MECHANICAL DEPARTMENT
        </span>
      </Link>
    </li>

  </ul>


                
              </li>
              <li className="llis">
              
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF ENGINEERING  FOR THE YEAR 2024-2025
                  </span>



                <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Aeronautical engineering department.pdf"

        target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
         AERONAUTICAL  DEPARTMENT
        </span>
      </Link>
    </li>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Chemical Engineering department.pdf"
        target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
        CHEMICAL DEPARTMENT
        </span>
      </Link>
    </li>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Civil engineering department.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
         CIVIL DEPARTMENT 
        </span>
      </Link>
    </li>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Computer science and engineering department.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
        COMPUTER SCIENCE AND ENGINEERING
        DEPARTMENT
        </span>
      </Link>
    </li>



    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Mechanical Engineering department.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
         MECHANICAL DEPARTMENT
        </span>
      </Link>
    </li>
  </ul>
              </li>
           
            
           
         
              <li className="llis">
               
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF AGRICULTURE  FOR THE YEAR
                    2024-25
                  </span>  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Agriculture.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
        AGRICULTURE DEPARTMENT
        </span>
      </Link>
    </li>

  </ul>

               
              </li>
              <li className="llis">
               
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF HOTEL MANAGEMENT FOR THE YEAR 2024-25
                  </span>
                  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Hotel Management.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
       HOTEL MANAGEMENT AND CATERING
        TECHNOLOGY
        </span>
      </Link>
    </li>

  </ul>
              </li>
              <li className="llis">
               
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF LAW  FOR THE YEAR 2024-25
                  </span>
                  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School%20of%20Law.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
        BACHELOR OF LAWS(LL.B.)
        </span>
      </Link>
    </li>

  </ul>
              </li>
              <li className="llis">
                
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF MANAGEMENT STUDIES  FOR THE
                    YEAR 2024-25
                  </span>
                  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Management Studies.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
       MANAGEMENT DEPARTMENT
        </span>
      </Link>
    </li>

  </ul>
              </li>
              <li className="llis">
               
                  <i class="fa fa-share" aria-hidden="true"></i>
                  <span
                    style={{
                      fontWeight: 700,
                      fontFamily: "'poppins',sansSerif",
                    }}
                  >
                    SCHOOL OF NURSING  FOR THE YEAR 2024-25
                  </span>
                  <ul style={{listStyle:'none',marginLeft:'50px'}}>
    <li>
      <Link
                  to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Nursing.pdf"
                  target="_blank"
      >
       <i class="fa fa-star" aria-hidden="true" style={{fontSize: "14px"}}></i>
{" "}
        <span
          style={{
            fontFamily: "'poppins',sansSerif",
          }}
        >
NURSING DEPARTMENT        </span>
      </Link>
    </li>

  </ul>
              </li>
            </ul>
            <br />
          </div>

          {/*//////////////////////////////////////////////////////////////////////*/}

          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Academic
              </h5>
              <br></br>{" "}
              <li className="lli">
                <Link to="/phd-page">
                  <i class="fa fa-adjust" aria-hidden="true"></i>PHD
                </Link>
              </li>
              <li className="lli">
                <Link to="/event-calender">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Event
                  Calender
                </Link>
              </li>
              <li className="lli">
                <Link to="/academic-calender">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Calender
                </Link>
              </li>
              <li className="lli">
                <Link to="/scholarship">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Scholarship{" "}
                </Link>
              </li>
              <li className="lli">
                <Link to="/constituent-units">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Constituent
                  Units
                </Link>
              </li>
              <li className="lli">
                <Link to="/Hei-handbook">
                  <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook{" "}
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link to="/iqac_cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link to="/mandatory_disclosures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory
                  Disclosures{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default EventCalender;

// import React from "react";
// import Nav from "../../HomePage/Nav";
// import Footerpage from "../../HomePage/Footerpage";
// import { Link } from "react-router-dom";

// function EventCalender() {
//   return (
//     <>
//       <Nav />
//       <style>
//         {`

  

// .cont{
// margin-top:20px;}



// h1 {
//     position: relative;
//     padding: 0;
//     margin: 0;
//     font-family: "Raleway", sans-serif;
//     font-weight: 700;
//     font-size: 30px;
//     color: #243642;
//     -webkit-transition: all 0.4s ease 0s;
//     -o-transition: all 0.4s ease 0s;
//     transition: all 0.4s ease 0s;
//   }
  
  
//   h1 em {
//     font-style: normal;
//     font-weight: 600;
//   }
//   .two h1 {
//     text-transform: capitalize;
//   }
//   .two h1:before {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 60px;
//     height: 2px;
//     content: "";
//     background-color: #F09319;
//   }
  
  
//   .alt-two h1 {
//     text-align:center;
//   }
  
//    .uul {
//               list-style-type: none;
//               padding: 0;
//               margin: 0;
//               list-style-type: square;
//               margin-left: 10px;
//             }
//   .alt-two h1:before {
//     left:50%; margin-left:-30px;
//   }
  
  
//   .lli{
//   font-size:14px; 
// //   margin-left:5px;

//   margin-top:-3px;
//    list-style-type: none; /* Removes bullets */}
  
  
// .listy{
// font-size:21px; 
//  margin-left:15px;
//  margin-top:8px;
// // font-weight:700;
//  list-style-type: disc; /* This will add bullets to the list */

  
// }

//    .llis{
//   font-size:17px; 
//  margin-left:15px;
//  margin-top:18px;

//   margin-top:-3px;
//    list-style-type: none; /* Removes bullets */}
  
//   .list-icon li i {
//       color: #555555;
//       font-size: 14px;
//       height: 28px;
//       line-height: 28px;
//       margin: 5px 10px 5px 0;
//       text-align: center;
//       width: 28px;
//   }



 
  
//      .list-icon {
//           list-style-type: none; /* Removes bullets */
//           padding: 0px; 
//           margin-top:-18px;
//           margin-left:50px;
//           display: flex;
//           flex-direction: column; /* Default to vertical */
//       }
  



// .title-main{
// margin-left:30px;

//  font-family: "Poppins", serif;
//   font-weight: 700;
//   font-style: normal;
//   }


// .title-hr {
// width: 118px;
// border-top: 5px solid #FA812F;
// margin-left: 30px;
// margin-top: -5px;
// margin-bottom:2px;
// }


 



// @media only screen and (max-width: 480px){




// .h2 title-main{
// margin-left:18px}




// h1 {
//   position: relative;
//   padding: 0;
//   margin: 0;
//   font-family: "Raleway", sans-serif;
//   font-weight: 700;
//   font-size: 30px;
//   color: #243642;
//   -webkit-transition: all 0.4s ease 0s;
//   -o-transition: all 0.4s ease 0s;
//   transition: all 0.4s ease 0s;
// }


// h1 em {
//   font-style: normal;
//   font-weight: 600;
// }
// .two h1 {
//   text-transform: capitalize;
// }
// .two h1:before {
//   position: absolute;
//   left: 0;
//   bottom: 0;
//   width: 60px;
//   height: 2px;
//   content: "";
//   background-color: #F09319;
// }


// .alt-two h1 {
//   text-align:center;
// }

//  .uul {
//             list-style-type: none;
//             padding: 0;
//             margin: 0;
//             list-style-type: square;
//             margin-left: 10px;
//           }
// .alt-two h1:before {
//   left:50%; 
//   margin-left:-30px;
// }


// .lli{
// font-size:15px; 
// // margin-left:px;
// margin-top:5px;
//  list-style-type: none; /* Removes bullets */}


// .list-icon li i {
//     color: #555555;
//     font-size: 14px;
//     height: 28px;
//     line-height: 28px;
//     margin: 5px 10px 5px 0;
//     text-align: center;
//     width: 28px;
// }

//    .list-icon {
//         list-style-type: none; /* Removes bullets */
//         padding: 0; 
//         margin: 0;
//         display: flex;
//         flex-direction: column; /* Default to vertical */
//     }


//        @media (max-width: 480px) {

  
//     .list-icon {
//     margin-left:-50px;
//             flex-direction: row; /* Makes the list horizontal */
//             flex-wrap: wrap; /* Allows wrapping if items are too wide */
//             // justify-content: space-between; /* Adds spacing between items */
//               flex: 1 1 50%; /* Makes each item take about 45% width */
//         }
   
//    }
    
//     `}
//       </style>

//       <div className="cont">
//         <h2 className="h2 title-main" style={{ color: "#780000" }}>
//          EVENT CALENDER
//         </h2>
//         <hr className="title-hr"></hr>

//         <div className="row dirr">
//           <br></br>
        

//           <div className="col-lg-9 padding-section">
       

//             <br></br>
//             <h5
//             style={{
//               fontFamily: "'poppins',sansSerif",
//               textAlign: "center",
//               color: "#FA812F",
//               fontFamily: "'poppins',sansSerif",
//             }}
//           >
//             Event Calender for the Year 2024-25
//           </h5>
//             <ul className="list-icon" style={{ marginTop: "50px" }}>
        
//               <li className="llis">
//   <Link
//     to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Faculty%20of%20Education.pdf"
//     target="_blank"
//   >
//     <i className="fa fa-share" aria-hidden="true"></i>{" "}
//     <span
//       style={{
//         fontWeight: 700,
//         fontFamily: "'poppins',sansSerif",
//       }}
//     >
//       {" "}
//       FACULTY OF EDUCATION (B.ED/B.A. B.ED/B.P.ED DEPARTMENT) FOR THE YEAR
//       2024-25{" "}
//     </span>
//   </Link>

//   {/* Nested ul for additional li items */}
//   <ul style={{listStyle:'none',marginLeft:'20px'}}>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//           B.ED Department Calendar
//         </span>
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/BA.BED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//           B.A. B.ED Department Calendar
//         </span>
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.P.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//           B.P.ED Department Calendar
//         </span>
//       </Link>
//     </li>
//   </ul>
// </li>

//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Polytechnic%20Pharmacy.pdf"
//                   target="_blank"
                
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>{" "}
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     {" "}
//                     POLYTECHNIC PHARMACY(PHARMACY DEPARTMENT) FOR THE YEAR 2024-25{" "}
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Polytechnic engineering.pdf"
//                   target="_blank"
                
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>{" "}
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     {" "}
//                     SCHOOL OF POLYTECHNIC ENGINEERING (MECHANICAL DEPARTMENT) FOR THE YEAR 2024-25{" "}
//                   </span>
//                 </Link>


                
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Aeronautical engineering department.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF ENGINEERING  FOR THE YEAR 2024-2025
//                   </span>
//                 </Link>



//                 <ul style={{listStyle:'none',marginLeft:'20px'}}>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//          AERONAUTICAL  DEPARTMENT
//         </span>
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/BA.BED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//         CHEMICAL DEPARTMENT
//         </span>
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.P.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//          CIVIL DEPARTMENT 
//         </span>
//       </Link>
//     </li>
//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.P.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//         COMPUTER SCIENCE AND ENGINEERING
//         DEPARTMENT
//         </span>
//       </Link>
//     </li>



//     <li>
//       <Link
//         to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/B.P.ED.pdf"
//         target="_blank"
//       >
//         <i className="fa fa-link" aria-hidden="true"></i>{" "}
//         <span
//           style={{
//             fontFamily: "'poppins',sansSerif",
//           }}
//         >
//          MECHANICAL DEPARTMENT
//         </span>
//       </Link>
//     </li>
//   </ul>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Chemical Engineering department.pdf
// "
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     {" "}
//                     SCHOOL OF ENGINEERING  FOR THE YEAR
//                     2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Civil engineering department.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF ENGINEERING (CIVIL DEPARTMENT   ) FOR THE YEAR
//                     2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Computer science and engineering department.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     {" "}
//                     SCHOOL OF ENGINEERING (COMPUTER SCIENCE AND ENGINEERING
//                     DEPARTMENT) FOR THE YEAR 2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/Mechanical Engineering department.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF ENGINEERING (MECHANICAL DEPARTMENT) FOR THE YEAR
//                     2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Agriculture.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF AGRICULTURE (AGRICULTURE DEPARTMENT) FOR THE YEAR
//                     2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Hotel Management.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF HOTEL MANAGEMENT (HOTEL MANAGEMENT AND CATERING
//                     TECHNOLOGY) FOR THE YEAR 2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School%20of%20Law.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF LAW (BACHELOR OF LAWS(LL.B.)) FOR THE YEAR 2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Management Studies.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF MANAGEMENT STUDIES (MANAGEMENT DEPARTMENT) FOR THE
//                     YEAR 2024-25
//                   </span>
//                 </Link>
//               </li>
//               <li className="llis">
//                 <Link
//                   to="https://cdn.sssutms.ac.in/media/static/ACADEMIC_CALENDER/School of Nursing.pdf"
//                   target="_blank"
//                 >
//                   <i class="fa fa-share" aria-hidden="true"></i>
//                   <span
//                     style={{
//                       fontWeight: 700,
//                       fontFamily: "'poppins',sansSerif",
//                     }}
//                   >
//                     SCHOOL OF NURSING (NURSING DEPARTMENT) FOR THE YEAR 2024-25
//                   </span>
//                 </Link>
//               </li>
//             </ul>
//             <br />
//           </div>

//           {/*//////////////////////////////////////////////////////////////////////*/}

//           <div className="col-lg-3">
//             <ul className="list-icon">
//               <h5
//                 style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
//               >
//                 Academic
//               </h5>
//               <br></br>{" "}
//               <li className="lli">
//                 <Link to="/phd-page">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>PHD
//                 </Link>
//               </li>
//               <li className="lli">
//                 <Link to="/event-calender">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>Event
//                   Calender
//                 </Link>
//               </li>
//               <li className="lli">
//                 <Link to="/academic-calender">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>Academic
//                   Calender
//                 </Link>
//               </li>
//               <li className="lli">
//                 <Link to="/scholarship">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>Scholarship{" "}
//                 </Link>
//               </li>
//               <li className="lli">
//                 <Link to="/constituent-units">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>Constituent
//                   Units
//                 </Link>
//               </li>
//               <li className="lli">
//                 <Link to="/Hei-handbook">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook{" "}
//                 </Link>
//               </li>
//               <li className="lli">
//                 {" "}
//                 <Link to="/iqac_cell">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell
//                 </Link>
//               </li>
//               <li className="lli">
//                 {" "}
//                 <Link to="/mandatory_disclosures">
//                   <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory
//                   Disclosures{" "}
//                 </Link>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       {/* </section> */}
//       <Footerpage />
//     </>
//   );
// }

// export default EventCalender;