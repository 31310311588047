import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function ConsultancyServices() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:60px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          margin-left:10%;

          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Consultancy Services
        </h2>
        <hr className="title-hr"></hr>


        <div className="row dirr">




          <div className="col-lg-8 padding-section" style={{ marginLeft: "50px" }}>

            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "1px" }}
              >
                <h6><b>1. Background :- </b></h6>
                <br />
                SSSUTMS University has strong focus on meaningful research activities which should benefit society. It also believes
                that expertise gained by the university should not only be used in improving teaching - learning and research system
                within the university but also should be used to benefit larger part of the society. In order to motivate university
                staff to share their knowledge and expertise for betterment of Society, University shall permit consultancy and project/work in industry, corporate sectors and other organisations by the university staff.
                The staff may use material resources of the University for such Consultancy Work. The university
                shall share the monitoring benefits occurring out of such work/association/assignments with the concerned staff. <br /> <br />

                <h6><b>2.Consultation Activities :- </b></h6><br />
                Following activities will fall under the consultancy- <br /><br />

                A ) For development of a product/part of product or services for any individual industry or organisation external to the university shall fall under consultancy where one or more university staff works for such development for a pre agreed cost and period. <br /><br />

                B)   For modification, augmentation or alteration of any product or process or services where one or more university staff extend their active participation for such job. <br /><br />

                C)   Any kind of professional advice given by one or more staff of the university to external organisation/firm/individual for a pre decided cost and time. <br /><br />

                D)   Any research work undertaken by one or more staff of the university for any external individual or organisation to develop product or process or services.<br /><br />

                E)  Conduct of any special courses, chairing/participation in organised activities, delivery expert advice/discourse for a fee to any outside organisation/individual. <br /><br />

                F)  Any royalty of fees received for any Intellectual Property by a staff and any fees received from outside. <br /><br />


                <h6><b> 3.Consultancy Process :- </b></h6><br />
                Following activities will fall under the consultancy- <br /><br />
                Research & Development Department (RDD) in the university will be the nodal agency for any consultancy activity
                in the university RDD. It will be the custodian of all documents for consultancy. Any staff, department or faculty may
                initiate the ground work and explore such possibilities. After the basic ground work it should be reported to RDD who will put it on their record. RDD will do the initial survey/preliminary inquiry and put up the matter to the DIRECTOR,
                who may form a team for further discussion with the client or he may himself discuss it with the client. <br /><br />

                After the negotiation and on arrival on agreement an Agreement Form will be initiated as per the format of the RDD. The format gives just the guidelines. It may be changed at the description of the DIRECTOR. It will be signed by the client and Registrar on behalf of the university. The payment received for consultancy will be deposited by the client/RDD in university bank account as per terms of the agreement.

                <br /><br />

                In case of faculty and/or university staff going for chairing an expert session, expert discourse on behalf of the university agreement form will not be raised. Money received from such consultancy event will be deposited in the university Account Section.

                <br /><br />

                <h6><b> 4. Contingency and Incidental Expenses :- </b></h6><br />
                Any contingency expenses incurred in respect of consultancy project will be met from the funds received from the same consultancy project. The fund left after deducting such expenses will be considered as net gain from the consultancy work. <br /><br />

                <h6><b> 5. Sharing Policy :- </b></h6><br />
                The net gain as worked out (Money Received from the client minus all incidental charges incurred for the consultation work) will be divided in ratio of 60:40 i.e. 60% of the gain will be paid to the faculty/staff who worked for the project and 40% will be retained by the university. University will plough back the share received by it in developing facilities to improve consultancy infrastructure. <br /><br />

                <h6><b> 6. Appraisal Report :- </b></h6><br />
                Consultancy work done by the staff will be entered in the Appraisal Report of the staff and will be given extra weightage in arriving Performance Index. <br /><br />

                <h6><b> 7. Closing Report :- </b></h6><br />
                After completion of the consultancy work a detailed report will be submitted by concerning staff in writing to DIRECTOR, in which he should mention complete details of work, resources of university used resources from outside, results and feedback of the second party for whom the task was undertaken.  <br /><br />
                <b style={{ textDecoration: "underline" }}>Objectives of Consultancy Services :- </b> <br /><br />

                To effectively utilize the University’s academic facilities, physical infrastructure including the engineering and scientific infrastructure, the available expertise to enter into an arrangement / interaction with the industry, other institutions or the bodies as the University may deem fit, in a manner consistent with the primary mission of teaching, research and public service; <br />

                To enrich the experience and knowledge of the Professionals in the knowledge sphere and provide an opportunity of finding solutions to the problems of industries / enterprises.<br />

                To provide opportunities to the Professionals to apply their knowledge and skill in real work situations. <br />

                To supplement the University’s financial resources to the possible extent.<br /><br />

                <b style={{ textDecoration: "underline" }}>Agencies / Organisations involved in Consultancy Services are :-</b>
                <br /><br />
                Total Diagnosis Pvt. Ltd. <br />

                Shruti media Services<br />

                Siddhart Kapoor Infrastructure Pvt. Ltd. <br />

                Double Tick Media Pvt. Ltd. <br />

                New Life Laboratories Pvt. Ltd. <br />

                Aran Pharmaceuticals <br />

                Sunrise Textiles, Mandideep, Raisen <br />

                LUNIA Law Associate, Bhopal <br />

                Noble Hospital, Bhopal  <br />

                Ganga Hospital, Bhopal <br />

                Konark Consultancy, Bhopal <br />



              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
          <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                   </Link>
              </li>
              <li className="lli">
               <Link to="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Patent">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/mou-collaboration">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                   </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default ConsultancyServices;

