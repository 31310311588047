import React, { useState } from "react";
import { FormControl } from "@mui/material";
import { Button, Row } from "react-bootstrap";
import Search from "./Search.js";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import axios from "axios";
import AdminUpdateStd_Manually from "./Admin_Dashboard/AdminUpdtStd_Manually";

function ManualEnrol_G() {
  const [showTable, setShowTable] = useState(false);

  const [userData, setUserData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const labelStyle = {
    color: "#264653",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "'Rubik', sans-serif",
  };
  ////////////////////////////search  by id//////////////////////////
  const handleSearchById = () => {
    if (!enteredValue) {
      alert("Please enter a Registration No.");
      return;
    }

    axios
      .get(`https://sssutms.ac.in/apitest/admin/search2?search=${enteredValue}`)
      .then((response) => {

        if (response.data && response.data.length > 0) {
          setSearchResult(response.data);
          setUserData(response.data);
          setShowTable(true);
        } else {
          alert("No results found."); // Inform user if no results
          setShowTable(false);
        }
   
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <AdminDashboard />

      <style>
        {`
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 20px;
            width: 100%;
            margin-left:75px;
            height:150px;
          }
         
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>
      <div>
        <div
          style={{
            width: "80%",
            height: "350px",
            padding: "10px",
            marginTop: "80px",
            marginLeft: "100px",
          }}
        >
          <h1
            style={{
              fontSize: "16px",
              fontWeight: 700,
              color: "#A02334",
              marginBottom: "8px",
              fontFamily: "'Rubik', sans-serif",
            }}
          >
            Manual Search Student
          </h1>

          <form style={{ padding: "15px" }}>
            <Row style={{ display: "flex", alignItems: "center" }}>
              <label
                style={{
                  marginRight: "10px",
                  flexShrink: 0,
                }}
              >
                Registration No. :
              </label>

              <FormControl style={{ flex: 1 }}>
                <input
                  style={{ marginTop: "1px" }}
                  type="number"
                  className="form-control"
                  value={enteredValue}
                  onChange={(e) => setEnteredValue(e.target.value)}
                />
              </FormControl>

              <Button
                style={{
                  marginLeft: "10px",
                  backgroundColor: "#004e92",
                  width: "90px",
                  height: "35px",
                  padding: "5px",
                }}
                onClick={handleSearchById}
              >
                Search
              </Button>
            </Row>
          </form>
        </div>

        <div style={{ width: "90%", marginTop: "-180px" }}>
          {showTable && userData.length > 0 && (
            <AdminUpdateStd_Manually userData={userData} />
          )}
        </div>
      </div>
    </>
  );
}

export default ManualEnrol_G;
