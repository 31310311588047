import React from "react";
import AICTE from "../images/AICTE.png";
import ugc from "../images/ugc.png";
import nptel from "../images/NPTEL.png";
import mpcst from "../images/MPCST.png";
import { Link } from "react-router-dom";

function Footerpage() {
  return (
    <>
      <style>
        {`


@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

// *{
//     margin:0;
//     padding:0;
//     box-sizing:border-box;
//   }
body{
  overflow-x: hidden;
  margin-top:-20px;
  padding: 0;

 box-sizing: border-box;
}

.h4class{
 color: #C80036 ;
 margin-top:30px;
 text-transform:uppercase;
 font-size:15px;
 font-family: "Roboto Flex", sans-serif;
        }


.footermain{
display:flex;
width:400px
}


.logoimage{
height:80px;
width:80px;

}

  .footer-text{
  margin-left:-70px;
  width:420px;
}

.footerlogo{
display:flex;
margin-left:-70px;
justify-content:space-between

}
.footerimage{
height:68px;
width:68px;

}


.footerimage1{
height:85px;
width:85px;
margin-top:-6px

}

.footerimage2{
height:100px;
width:130px;
margin-top:-16px;
margin-left:-30px
}

.footerp{
color:white;
}
    .footer-section {
      background: #151414;
      position: relative;
    }
   
.footer-widget li{
list-style:none;
}
    .footer-widget-cook li{
    margin-bottom:10px;
     list-style:none;
     margin-left:-20px
    }
 .footer-widget-tee li{
    margin-bottom:10px;
     list-style:none;
         margin-left:-10px
    }

    //  .footer-widget-you li{
    //  margin-bottom:10px;
    //   list-style:none;
    //  margin-left:-20px
    //     }
    .footer-widget-you li {
    margin-bottom: 10px;
    list-style: none;
    margin-left: -25px;
}
    
      
    .footer-content {
      position: relative;
      z-index: 2;
    }
   
    .footer-logo {
      margin-bottom: 30px;
    }
    .footer-logo img {
        max-width: 380px;
    }
    .footer-text p {
      margin-bottom: 14px;
   
          color: #7e7e7e;
      line-height: 28px;
    }

    .footer-menu {
    margin-left:250px;
       
    }
 
    .footer-widget-heading h3 {
      color: #fff;
      font-size: 20px;
      margin-left:-20px;
      font-weight: 600;
      margin-bottom: 40px;
      position: relative;
      margin-top:30px;
       list-style:none;
   
    }
    .footer-widget-heading h3::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -15px;
      height: 2px;
      width: 50px;
      background: #ff5e14;
    }

   


    .footer-widget ul li {
  list-style:none
      width: 50%;
       margin-bottom: 8px;

    }
  
    .footer-widget ul li a {
      color: #878787;
      list-style:none
      text-transform: capitalize;
      margin-left:-10px
    }
   
   
  
    .footer-menu li {
      display: inline-block;
      
     
      
    }
 
    





      .conatineer {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;
      }
     
      .copyright-text {
         text-align: center;
        margin-top: 15px;
      
      }
    
      
   


      .footer-section {
        background: #151414;
        position: relative;
      }
    
      .footer-pattern img {
        position: absolute;
        top: 0;
        left: 0;
        height: 330px;
        background-size: cover;
        background-position: 100% 100%;
      }
      .footer-logo {
        margin-bottom: 30px;
      }
      .footer-logo img {
          max-width: 300px;
      }
      .footer-text p {
        margin-bottom: 14px;
        font-size: 14px;
            color: #7e7e7e;
        line-height: 28px;
    
        font-family: helvetica;
      }


     

    
      .footer-div{
        margin-left:200px;
      }

    

      .ankita{
        display : flex ;
    }

  
      .footer-widget-heading h3 {
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        position: relative;
      }



      .footer-widget-heading h3::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -15px;
        height: 2px;
        width: 50px;
        background: #ff5e14;
      }
//       .footer-widget ul li {
// list-style:none
//         width: 100%;

//       }
    
      .copyright-area{
        background: #202020;
        padding: 25px 0;
   
      }
      .copyright-text p {
        margin: 0;
        font-size: 14px;
        color: #878787;
        margin-left:170px;
        margin-top:-20px
      }
      

      .footer-menu li {
        display: inline-block;
        margin-left: 20px;
      }
     
  
    //   .logos{
    //     display: flex;
    //     justify-content:center;
    //     gap: 20px;
    //     margin-left:400px;
    //     align-items:center;
    //     height: 70px;

    //   }
    //  .logoheight{
    //     height:100%;
    //  }
    

      @media screen and (max-width: 768px) {
       

       
        .footer-menu li{
           display:inline;
        
        }
        .footer-menu{
          margin-right:300px;

        }



      }


// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
.col-xl-4.col-md-4 {
margin-right: 400px; 
}

 
.footer-menu li{
display:inline-block;
   font-size:15px
}






}
@media screen and (max-width: 480px)

{



    .footer-widget ul li {
  list-style:none
      width: 50%;
       margin-bottom: 8px;

    }
.footer-widget-you li{
        
margin-left:-8px}

.foot{
overflow-x:hidden
}

.dividivi
 {
margin-top:30px;
}
 

  


.conatineerr{
  margin-left:-290px;

}





.footer-widget-heading1  h3{
  margin-right:0;
  color: #ff9f1c;
  footer-widget-heading
  }



// .footer-menu{
  
//   margin-left:-250px;
//   justify-content:space-between;
// width:200px;
// padding: 0;
// display: flex;
// gap:10px;
// }





  
  
  .footer-menu ul li {
    margin-right: 10px;
    white-space: nowrap;
  }




.footer-section{
height:1090px;

}





.footer-menu {
  margin-left: -125px; 
  width: auto;
  justify-content:space-around;
}



.footer-menu ul {
justify-content:space-between;
width:300px;

 list-style:none;
display: flex;

margin-left:100px

}




// .footer-widget ul li {
//   width: 100%;
//   margin-top:-15px
//   }

.footer-widget-heading  {
margin-left:-30px;
margin-top:-22px

}

.footy {
display: flex;
flex-wrap: wrap;
font-size:20px;
justify-content: space-between;
}

.footer-widget {
width: auto;
}


.footer-widget ul {
justify-content:space-between;
width:200px;
padding: 0;
display: flex;



width:300px;
margin-left:-44px;
margin-top:-26px;
}

.h4class{
font-size:18px
}

.footermain{
display:flex;

width:330px
}


.logoimage{
height:50px;
width:50px;
margin-left:10px

}

  .footer-text{
  margin-left:27px;
  width:400px;
  margin-top:-40px
}

.footerlogo{
display:flex;
margin-left:10px

}
.footerimage{
height:60px;
width:60px;
margin-left:3px
}

.footerp{
color:white;

}



// .footer-widget-cook ul {
// justify-content:space-between;
// width:300px;
//   list-style:none;
// padding: 0;
// display: flex;
// flex-wrap:wrap;
// // margin-left:20px;
// margin-left:-37px;
// margin-top:-15px;
// gap:2px;

// }


    .footer-widget-cook ul {
        justify-content: space-around;
        width: 301px;
        list-style: none;
        padding: 0;
        display: flex;
        // flex-wrap: wrap;
        margin-left: -49px;
        margin-top: -23px;
       row-gap: 26px;
        font-size:10px;
    }



.footer-widget-tee ul {
justify-content:space-between;
width:270px;
padding: 0;
 list-style:none;
display: flex;
gap:10px;
margin-left:-40px;
margin-top:-15px
}

// .footer-widget-you ul{

// width:343px;
// padding: 0;
// display: flex;
// gap:10px;
//  list-style:none;
// margin-left:-80px;
// margin-top:-15px;
// flex-wrap: wrap;
// justify-content: space-between;


// }


.footer-widget-you ul {
        width: 345px;
        padding: 0;
        display: flex;
        gap: 2px;
        list-style: none;
        margin-left: -61px;
        margin-top: -26px;
        flex-wrap: wrap;
             font-size:10px;
        justify-content: space-around;
    }


.footer-widget ul li {
//  margin-left:15px;
white-space: nowrap;
margin-right:30px;
width:100px;
list-style:none;

}


.copyright-text p{
margin-left:-891px
}


} 









// /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@media screen and (max-width: 768px) {
    .footer-widget {
      text-align: center;
    }
    .ankita {
      flex-wrap: wrap;
      justify-content: center;
    }
    .footer-widget ul li {
      width: 100%;
      margin-bottom: 10px;
    }
    .footer-div {
      margin-left: 0;
    }
    .footer-menu ul li {
      display: block;
      margin: 0;
    }
    .footer-menu {
      text-align: center;
      margin-top: 20px;
    }
  .copyright text{
  margin-left:-200px}
  
  }





  
  @media only screen and (max-width: 768px) {
    .footer-menu {
      text-align: center;
    }
 
    .copyright-text {
      margin-top: 20px;
      margin-left:600px
    }
  }

 
  
  @media (max-width: 768px) {
   
  }
  .ddd{
    color: white;
  }
  
    `}
      </style>
      <div className="foot">
        <footer className="footer-section">
          <div className="conatineer">
            <div className="footer-content pt-4 pb-4">
              <div className="row">
                <div className="dividivi col-xl-3 col-lg-3 mb-50">
                  <div className="footer-widget">
                    <div className="footer-text">
                      <div className="footermain">
                        {/* <img  className='logoimage'src={sssutms}></img> */}
                        <h4 className="h4class">
                          {" "}
                          Sri Satya Sai University of Technology & Medical
                          Sciences
                        </h4>
                      </div>
                      <h6 style={{ color: "white", marginTop: "10px" }}>
                        Opp.Oilfed Plant, Bhopal-Indore Road, <br></br>Sehore
                        (M.P), Pin - 466001{" "}
                      </h6>
                      <p style={{ marginTop: "3px" }}>
                        {" "}
                        (+91) 07562-292203 | 07562-292204 |  07562-292720
                      </p>
                      <p style={{ marginTop: "-10px" }}>
                        {" "}
                        (+91) 07562-292205 | 07562-292740
                      </p>
                      <p style={{ marginTop: "-10px" }}>
                        {" "}
                        (From 10:00 AM to 5:00 PM only)
                      </p>
                    </div>

                    <div className="footerlogo">
                      <img className="footerimage" src={mpcst}></img>
                      <img className="footerimage" src={AICTE}></img>
                      <img className="footerimage1" src={nptel}></img>
                      <img className="footerimage2" src={ugc}></img>
                    </div>
                  </div>
                </div>

                <div
                  className="col-xl-8 col-lg-8 col-md-8 mb-30 footy"
                  style={{
                    marginLeft: "80px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="footer-widget-you">
                    <div className="footer-widget-heading">
                      <h3
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontFamily: "'Roboto Flex', sans-serif",
                          color: "#C80036",
                        }}
                      >
                        LOGINS
                      </h3>
                    </div>
                    <ul>
                      {" "}
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="/adminlogin"
                        >
                          {" "}
                          Admin Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="/erp/studentlogin"
                        >
                          Student Login
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Verify Marksheet
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="/grievance-redressal"
                        >
                          {" "}
                          Student Grievance
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer-widget-cook">
                    <div className="footer-widget-heading">
                      <h3
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontFamily: "'Roboto Flex', sans-serif",
                          color: "#C80036",
                        }}
                      >
                        QUICK LINKS
                      </h3>
                    </div>
                    <ul>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Pay Fees
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Approvals
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        ></Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Announcements
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          AICTE feedback{" "}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-widget">
                    <div className="footer-widget-heading">
                      <h3
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontFamily: "'Roboto Flex', sans-serif",
                          color: "#C80036",
                        }}
                      >
                        INFORMATION
                      </h3>
                    </div>
                    <ul className="uni">
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="/faq"
                        >
                          FAQs
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Blog
                        </Link>
                      </li>{" "}
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Amenities
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="#"
                        >
                          Brochures
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="footer-widget-tee">
                    <div className="footer-widget-heading">
                      <h3
                        style={{
                          textTransform: "uppercase",
                          fontSize: "15px",
                          fontFamily: "'Roboto Flex', sans-serif",
                          color: "#C80036",
                        }}
                      >
                        USEFUL LINKS
                      </h3>
                    </div>
                    <ul>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="http://www.mpbse.nic.in/"
                          target="_blank"
                        >
                          MPBSE
                        </Link>
                      </li>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="https://www.ugc.gov.in/"
                          target="_blank"
                        >
                          UGC
                        </Link>
                      </li>

                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="https://www.aicte-india.org/"
                          target="_blank"
                        >
                          AICTE
                        </Link>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <Link
                          style={{
                            fontFamily: "Montserrate, Verdana",
                            color: "#edede9",
                          }}
                          to="https://ugcmoocs.inflibnet.ac.in/"
                          target="_blank"
                        >
                          MOOCs
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <div className="footer-widget-you">
                <div className="footer-widget-heading">
                <h3 style={{ textTransform:'uppercase',
 fontSize:'15px',
 fontFamily: "'Roboto Flex', sans-serif", color:"#C80036"}}>RESOURCES</h3>
                </div>
                <ul>
                  <li>
                    <a style={{ fontFamily: "Montserrate, Verdana", color: '#edede9' }} to="#">Monthly Magazine</Link>
                  </li>

                  <li>
                    <a style={{ fontFamily: "Montserrate, Verdana", color: '#edede9' }} to="#"> E-Content</Link>
                  </li> <li>
                    <a style={{ fontFamily: "Montserrate, Verdana", color: '#edede9' }} to="#">Brochure</Link>
                  </li>
                  <li>
                    <a style={{ fontFamily: "Montserrate, Verdana", color: '#edede9' }} to="#"> MOUs</Link>
                  </li>

                </ul>
              </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="copyright-area">
            <div className="conatineer">
              <div className="row">
                <div className="footer-menu">
                  <ul>
                    <li>
                      <Link to ="/" style={{ color: "white" }}>
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link to ="/terms & conditions" style={{ color: "white" }}>
                        Terms & Conditions
                      </Link>
                    </li>

                    <li>
                      <Link to ="/privacy-policy" style={{ color: "white" }}>
                        Privacy-Policy
                      </Link>
                    </li>

                    <li>
                      <Link style={{ color: "white" }}>Site Map</Link>
                    </li>

                    <li>
                     <Link to= "/Refund & Cancellation"
                        style={{ color: "white" }}
                      >
                        Refund & Cancellation
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-xl-11 col-lg-6 text-center text-lg-left">
                  <div className="copyright-text">
                    <p style={{ color: "white" }}>
                      ©2024, SSSUTMS. All Rights Reserved
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default Footerpage;
