// import { useState } from "react";
// import { Link } from "react-router-dom";
// import logo from "../images/sssutms.jpg";

// function Nav() {
//   const [isOpen, setIsOpen] = useState(false);
//   const [isOpen1, setIsOpen1] = useState(false);
//   const [isOpen2, setIsOpen2] = useState(false);
//   const [isOpen3, setIsOpen3] = useState(false);
//   const [isOpen4, setIsOpen4] = useState(false);
//   const [isOpen5, setIsOpen5] = useState(false);
//   const [isOpen6, setIsOpen6] = useState(false);

//   const [isOpen8, setIsOpen8] = useState(false);
//   const [isOpen9, setIsOpen9] = useState(false);
//   const [isOpen10, setIsOpen10] = useState(false);

//   const [isOpen12, setIsOpen12] = useState(false);
//   const [isOpen13, setIsOpen13] = useState(false);
//   const [isOpen14, setIsOpen14] = useState(false);
//   const [isOpen15, setIsOpen15] = useState(false);
//   const [isOpen16, setIsOpen16] = useState(false);
//   const [isOpen17, setIsOpen17] = useState(false);
//   const [isOpen18, setIsOpen18] = useState(false);
//   const [isOpen19, setIsOpen19] = useState(false);
//   const [isOpen20, setIsOpen20] = useState(false);
//   const [isOpen21, setIsOpen21] = useState(false);
//   const [isOpen22, setIsOpen22] = useState(false);
//   const [isOpen23, setIsOpen23] = useState(false);
//   const [isOpen24, setIsOpen24] = useState(false);

//   const [isOpen25, setIsOpen25] = useState(false);
//   const [isOpen26, setIsOpen26] = useState(false);
//   const [isOpen27, setIsOpen27] = useState(false);
//   const [isOpen28, setIsOpen28] = useState(false);

//   const [isOpen29, setIsOpen29] = useState(false);
//   const [isOpen30, setIsOpen30] = useState(false);
//   const [isOpen31, setIsOpen31] = useState(false);
//   const [isOpen32, setIsOpen32] = useState(false);
//   const [isOpen33, setIsOpen33] = useState(false);
//   const [isOpen34, setIsOpen34] = useState(false);
//   const [isOpen35, setIsOpen35] = useState(false);
//   const [isOpen36, setIsOpen36] = useState(false);
//   const [isOpen37, setIsOpen37] = useState(false);
//   const [isOpen38, setIsOpen38] = useState(false);
//   const [isOpen39, setIsOpen39] = useState(false);
//   const [isOpen40, setIsOpen40] = useState(false);
//   const handleMouseEnter = () => {
//     setIsOpen(true);
//   };

//   const handleMouseLeave = () => {
//     setIsOpen(false);
//   };

//   const handleMouseEnter1 = () => {
//     setIsOpen1(true);
//   };

//   const handleMouseLeave1 = () => {
//     setIsOpen1(false);
//   };
//   const handleMouseEnter2 = () => {
//     setIsOpen2(true);
//   };

//   const handleMouseLeave2 = () => {
//     setIsOpen2(false);
//   };
//   const handleMouseEnter3 = () => {
//     setIsOpen3(true);
//   };

//   const handleMouseLeave3 = () => {
//     setIsOpen3(false);
//   };
//   const handleMouseEnter4 = () => {
//     setIsOpen4(true);
//   };

//   const handleMouseLeave4 = () => {
//     setIsOpen4(false);
//   };
//   const handleMouseEnter5 = () => {
//     setIsOpen5(true);
//   };

//   const handleMouseLeave5 = () => {
//     setIsOpen5(false);
//   };

//   const handleMouseEnter6 = () => {
//     setIsOpen6(true);
//   };

//   const handleMouseLeave6 = () => {
//     setIsOpen6(false);
//   };

//   const handleMouseEnter8 = () => {
//     setIsOpen8(true);
//   };

//   const handleMouseLeave8 = () => {
//     setIsOpen8(false);
//   };

//   const handleMouseEnter9 = () => {
//     setIsOpen9(true);
//   };

//   const handleMouseLeave9 = () => {
//     setIsOpen9(false);
//   };
//   const handleMouseEnter10 = () => {
//     setIsOpen10(true);
//   };

//   const handleMouseLeave10 = () => {
//     setIsOpen10(false);
//   };

//   const handleMouseEnter12 = () => {
//     setIsOpen12(true);
//   };

//   const handleMouseLeave12 = () => {
//     setIsOpen12(false);
//   };

//   const handleMouseEnter13 = () => {
//     setIsOpen13(true);
//   };

//   const handleMouseLeave13 = () => {
//     setIsOpen13(false);
//   };
//   const handleMouseEnter14 = () => {
//     setIsOpen14(true);
//   };

//   const handleMouseLeave14 = () => {
//     setIsOpen14(false);
//   };
//   const handleMouseEnter15 = () => {
//     setIsOpen15(true);
//   };

//   const handleMouseLeave15 = () => {
//     setIsOpen15(false);
//   };
//   const handleMouseEnter16 = () => {
//     setIsOpen16(true);
//   };

//   const handleMouseLeave16 = () => {
//     setIsOpen16(false);
//   };
//   const handleMouseEnter17 = () => {
//     setIsOpen17(true);
//   };

//   const handleMouseLeave17 = () => {
//     setIsOpen17(false);
//   };
//   const handleMouseEnter18 = () => {
//     setIsOpen18(true);
//   };

//   const handleMouseLeave18 = () => {
//     setIsOpen18(false);
//   };
//   const handleMouseEnter19 = () => {
//     setIsOpen19(true);
//   };

//   const handleMouseLeave19 = () => {
//     setIsOpen19(false);
//   };
//   const handleMouseEnter20 = () => {
//     setIsOpen20(true);
//   };

//   const handleMouseLeave20 = () => {
//     setIsOpen20(false);
//   };
//   const handleMouseEnter21 = () => {
//     setIsOpen21(true);
//   };

//   const handleMouseLeave21 = () => {
//     setIsOpen21(false);
//   };
//   const handleMouseEnter22 = () => {
//     setIsOpen22(true);
//   };

//   const handleMouseLeave22 = () => {
//     setIsOpen22(false);
//   };
//   const handleMouseEnter23 = () => {
//     setIsOpen23(true);
//   };

//   const handleMouseLeave23 = () => {
//     setIsOpen23(false);
//   };
//   const handleMouseEnter24 = () => {
//     setIsOpen24(true);
//   };

//   const handleMouseLeave24 = () => {
//     setIsOpen24(false);
//   };

//   const handleMouseLeave25 = () => {
//     setIsOpen25(false);
//   };

//   const handleMouseLeave26 = () => {
//     setIsOpen26(false);
//   };

//   const handleMouseLeave27 = () => {
//     setIsOpen27(false);
//   };

//   const handleMouseLeave28 = () => {
//     setIsOpen28(false);
//   };

//   const handleMouseEnter25 = () => {
//     setIsOpen25(true);
//   };

//   const handleMouseEnter26 = () => {
//     setIsOpen26(true);
//   };

//   const handleMouseEnter27 = () => {
//     setIsOpen27(true);
//   };

//   const handleMouseEnter28 = () => {
//     setIsOpen28(true);
//   };

//   const handleMouseEnter29 = () => {
//     setIsOpen29(true);
//   };

//   const handleMouseLeave29 = () => {
//     setIsOpen29(false);
//   };
//   const handleMouseEnter30 = () => {
//     setIsOpen30(true);
//   };

//   const handleMouseLeave30 = () => {
//     setIsOpen30(false);
//   };
//   const handleMouseEnter31 = () => {
//     setIsOpen31(true);
//   };

//   const handleMouseLeave31 = () => {
//     setIsOpen31(false);
//   };
//   const handleMouseEnter32 = () => {
//     setIsOpen32(true);
//   };

//   const handleMouseLeave32 = () => {
//     setIsOpen32(false);
//   };
//   const handleMouseEnter33 = () => {
//     setIsOpen33(true);
//   };

//   const handleMouseLeave33 = () => {
//     setIsOpen33(false);
//   };
//   const handleMouseEnter34 = () => {
//     setIsOpen34(true);
//   };

//   const handleMouseLeave34 = () => {
//     setIsOpen34(false);
//   };
//   const handleMouseEnter35 = () => {
//     setIsOpen35(true);
//   };

//   const handleMouseLeave35 = () => {
//     setIsOpen35(false);
//   };
//   const handleMouseEnter36 = () => {
//     setIsOpen36(true);
//   };

//   const handleMouseLeave36 = () => {
//     setIsOpen36(false);
//   };
//   const handleMouseEnter37 = () => {
//     setIsOpen37(true);
//   };

//   const handleMouseLeave37 = () => {
//     setIsOpen37(false);
//   };

//   const handleMouseEnter38 = () => {
//     setIsOpen38(true);
//   };

//   const handleMouseLeave38 = () => {
//     setIsOpen38(false);
//   };
//   const handleMouseEnter39 = () => {
//     setIsOpen39(true);
//   };

//   const handleMouseLeave39 = () => {
//     setIsOpen39(false);
//   };

//   const handleMouseEnter40 = () => {
//     setIsOpen40(true);
//   };

//   const handleMouseLeave40 = () => {
//     setIsOpen40(false);
//   };

//   return (
//     <>
//       <style>{`

// .nav-buttons {
//   display: none; /* Hidden by default */
// }
// .brand-text {
//   display: none;
// }
//       .loggo{

//                   margin-left: -50px;

//                   height: 70px;

//                   width: 100px;
//                   border-radius: 20px;
//                 }
//       .navbar {
//         box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
//         background-color:#D2E0FB;
//         position:sticky;
//         margin-top:20px;
//       }

// .custom-sticky {
//   position: sticky !important;
// }

// a.dropdown-item p.pstyle {

//   font-size: 14px;
//   color: black;
// font-family: "Poppins", sans-serif;

//   margin-bottom:-10px;

//   padding: 0;

// }

// .down
// {
//    font-size: 20px;
//    font-family: 'Open Sans', sans-serif;
//     margin-top:-8px;
//       margin-bottom:-10px;
//       padding:0 ;
//      }

// a.dropdown-item p.pstyle1 {

//   font-size: 14px;
//   color: #C80036;
// font-family: "Poppins", sans-serif;

//   margin-bottom:-8px;
//  font-weight:700;
//   padding: 0;

// }

//       // .navbar-brand{
//       //   pading-right: 80px;
//       // }
//       .navbar .navbar-brand img {
//         max-width: 100px;
//       }
//       .navbar .navbar-nav .nav-link {
//         color: #000;
//       }
//       @media screen and (min-width: 1024px) {
//         .navbar {
//           letter-spacing: 0.1em;
//         }
//         .navbar .navbar-nav .nav-link {
//           padding: 0.5em 1em;
//         }
//         .search-and-icons {
//           width: 30%;
//         }
//         .search-and-icons form {
//           flex: 1;
//         }
//       }

//       @media screen and (max-width:480px){

//      .nav-buttons {
//     display: flex;
//     flex-direction:row;
//     width:220px;
//    justify-content: space-between; /* Center the buttons */
//     margin-top: 10px;
//   }

//   /* Adjust button styles for smaller screens */
//   .bt {

//     height: 20px;
//     color:#B8001F;
//     font-weight:700;
//     font-size: 15px;
//   }

//   .loggo{

//               margin-left: -5px;

//                   height: 50px;

//                   width: 50px;
//                   border-radius: 20px;
//                 }}
//       @media screen and (min-width: 768px) {
//         .navbar .navbar-brand img {
//           max-width: 4em;
//         }

//       .navbar-toggler{
//           backgound-color:oragne;
//       }

//         .navbar .navbar-collapse {
//           display: flex;
//           flex-direction: column-reverse;
//           align-items: flex-end;
//         }
//         .search-and-icons {
//           display: flex;
//           align-items: center;
//         }
//       }
//       .search-and-icons form input {
//         border-radius: 0;
//         height: 2em;
//         background: black
//           url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='grey' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E")
//           no-repeat 95%;
//       }
//       .search-and-icons form input:focus {
//         background: black;
//         box-shadow: none;
//       }
//       .search-and-icons .user-icons div {
//         padding-right: 1em;
//       }
//       .contact-info p,
//       .contact-info a {
//         font-size: 0.7em;
//         padding-right: 2em;
//         // color: grey;
//       }

//       .contact-info a {
//         padding-right: 0;
//       }

//       . {
//         font-size: 0.8rem; /* Adjust the size as needed */
//         font-weight:700er;

//       }

//   @import url('https://fonts.googleapis.com/css2?family=Basic&family=Doppio+One&display=swap')

//   .dropdown-menu {
//     max-height: 300px; /* Set a maximum height */
//     overflow-y: auto;  /* Enable vertical scrolling */
//     font-size: 0.8rem; /* Adjust the font size to be smaller */
// }

//     `}</style>

//       <nav
//         className="navbar navbar-expand-md bg-body-tertiary sticky-top"
//         style={{ width: "100%", backgroundColor: "#D2E0FB" }}
//       >
//         <div className="container-xl" style={{ width: "100%" }}>
//           <a className="navbar-brand d-flex align-items-center">
//             <Link to="/">
//               <img className="loggo" src={logo} alt="Logo" />
//             </Link>
//             <span className="brand-text">
//               SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
//             </span>
//           </a>{" "}
//           <div className="nav-buttons">
//             <Link to="/erp">
//               <h5 className="bt">ERP</h5>
//             </Link>
//             <Link to="/Epravesh/Student/Register">
//               <h5 className="bt"> E-Pravesh</h5>
//             </Link>
//             <Link to="/admission/enquiry">
//               <h5 className="bt"> Admission</h5>
//             </Link>
//           </div>
//           <button
//             style={{ backgroundColor: "#EEEEEE" }}
//             className="navbar-toggler "
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#navbarSupportedContent"
//             aria-controls="navbarSupportedContent"
//             aria-expanded="false"
//             aria-label="Toggle navigation"
//           >
//             <span>
//               <i class="fa-solid fa-bars"></i>
//             </span>
//           </button>
//           <div className="collapse navbar-collapse" id="navbarSupportedContent">
//             <div
//               className="contact-info d-md-flex"
//               style={{ marginLeft: "-10px" }}
//             >
//               <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter}
//                   onMouseLeave={handleMouseLeave}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       textTransform: "uppercase",
//                       fontFamily: "'Anton', sans-serif ",
//                       fontWeight: 700,
//                     }}
//                   >
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{
//                         textTransform: "upperCase",
//                         fontFamily: "'Roboto',",
//                         color: "#dd2d4a",
//                         fontWeight: 800,
//                         fontSize: "13px",
//                       }}
//                     >
//                       {" "}
//                       Academic{" "}
//                     </a>
//                   </h1>

//                   <div
//                     className={`dropdown-menu ${isOpen ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <Link
//                       className="dropdown-item"
//                       to="/phd-page"
//                       style={{ width: "250px" }}
//                     >
//                       {" "}
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         PHD
//                       </p>
//                     </Link>
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter15}
//                       onMouseLeave={handleMouseLeave15}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-8px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Faculties & Department
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen15 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-60px" }}
//                       >
//                         <Link className="dropdown-item" to="/enggtechno">
//                           <p className="pstyle1">Engineering & Technology</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/pharma">
//                           <p className="pstyle1">Pharmacy</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/edu">
//                           <p className="pstyle1">Education</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Management</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/design">
//                           <p className="pstyle1">Design</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/humanities&language"
//                         >
//                           <p className="pstyle1">Humanities & Language</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/commerce">
//                           <p className="pstyle1">Commerce</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/science">
//                           <p className="pstyle1">Science</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Ayurveda</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Law</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Homeopathy</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Paramedical</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Nursing</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Computer Science & Application
//                           </p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Bacheloroflaws-syllabus"
//                         >
//                           <p className="pstyle1">
//                             Hotel Management & Catering Technology
//                           </p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/PolytechnicEngineerin-syllabus"
//                         >
//                           <p className="pstyle1">Polytechnic (Engineering)</p>
//                         </Link>
//                       </div>
//                     </li>

//                     <Link
//                       className="dropdown-item"
//                       to="/academic-calender"
//                       style={{ marginTop: "-30px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-3px",
//                         }}
//                       >
//                         Acadmic Calender
//                       </p>
//                     </Link>
//                     <Link className="dropdown-item " to="/scholarship">
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "3px",
//                         }}
//                       >
//                         Scholarship
//                       </p>
//                     </Link>
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter20}
//                       onMouseLeave={handleMouseLeave20}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-5px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Committe
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen20 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-50px" }}
//                       >
//                         <Link className="dropdown-item" to="/anti-ragging">
//                           <p className="pstyle1">Anti Ragging</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/proctorial-board">
//                           <p className="pstyle1">Proctorial Board</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/internal-complaint"
//                         >
//                           <p className="pstyle1">Internal Complaint</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/grievance-redressal"
//                         >
//                           <p className="pstyle1">Grievance Redressal</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/sc-st">
//                           <p className="pstyle1">For SC-ST</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/edc">
//                           <p className="pstyle1">EDC</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/international-higher-education-cell"
//                         >
//                           <p className="pstyle1">
//                             International Higher Education Cell
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="/incubation-cell">
//                           <p className="pstyle1">Incubation Cell</p>
//                         </Link>
//                       </div>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter21}
//                       onMouseLeave={handleMouseLeave21}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-40px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Activities
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen21 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-60px" }}
//                       >
//                         <Link className="dropdown-item" to="/expert_lectures">
//                           <p className="pstyle1">Expert Lectures</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/webinar">
//                           <p className="pstyle1">Webinar</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/industrial_visits">
//                           <p className="pstyle1">Industrial Visits</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/activity/events">
//                           <p className="pstyle1">Events</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/fdp">
//                           <p className="pstyle1">FDP</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/workshop_seminars">
//                           <p className="pstyle1">Workshop & Seminars</p>
//                         </Link>
//                       </div>
//                     </li>
//                     <Link className="dropdown-item " to="/constituent-units">
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-32px",
//                         }}
//                       >
//                         Constituent Units
//                       </p>
//                     </Link>
//                     <Link className="dropdown-item " to="/Hei-handbook">
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-14px",
//                         }}
//                       >
//                         HEL Handbook
//                       </p>
//                     </Link>
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter22}
//                       onMouseLeave={handleMouseLeave22}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-7px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Training & Placement
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen22 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-60px" }}
//                       >
//                         <Link
//                           className="dropdown-item"
//                           to="/Academic/TrainingAndPlacementCell"
//                         >
//                           <p className="pstyle1">Training And Placement Cell</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Academic/TrainingPartner"
//                         >
//                           <p className="pstyle1">Training Partner</p>
//                         </Link>
//                       </div>
//                     </li>
//                     <Link className="dropdown-item " to="/iqac_cell">
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-33px",
//                         }}
//                       >
//                         IQAC Cell
//                       </p>
//                     </Link>
//                     <Link
//                       className="dropdown-item "
//                       to="/mandatory_disclosures"
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-14px",
//                         }}
//                       >
//                         Mandatory Disclosures
//                       </p>
//                     </Link>
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter23}
//                       onMouseLeave={handleMouseLeave23}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-7px",
//                           marginLeft: "15px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           NACC
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen23 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-60px" }}
//                       >
//                         <Link className="dropdown-item" to="/SSR">
//                           <p className="pstyle1">SSR</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/criteria1">
//                           <p className="pstyle1">Criteria - 1</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/criteria2">
//                           <p className="pstyle1">Criteria - 2</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/criteria3">
//                           <p className="pstyle1">Criteria - 3</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/criteria4">
//                           <p className="pstyle1">Criteria - 4</p>
//                         </Link>

//                         <Link className="dropdown-item" to="/criteria5">
//                           <p className="pstyle1">Criteria - 5</p>
//                         </Link>

//                         <Link className="dropdown-item" to="/criteria6">
//                           <p className="pstyle1">Criteria - 6</p>
//                         </Link>
//                       </div>
//                     </li>
//                     <Link className="dropdown-item " to="/approval">
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-33px",
//                         }}
//                       >
//                         NIRF
//                       </p>
//                     </Link>
//                   </div>
//                 </li>

//                 {/* ---- Faculty  of Education */}

//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter25}
//                   onMouseLeave={handleMouseLeave25}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       fontFamily: "'Anton', sans-serif ",
//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{ color: "#dd2d4a", fontSize: "13px" }}
//                     >
//                       Faculty Of Education
//                     </a>
//                   </h1>

//                   <ul
//                     className={`dropdown-menu ${isOpen25 ? "show" : ""}`}
//                     style={{
//                       borderRadius: "10px",
//                       width: "290px", // Adjust width
//                       minHeight: "200px",
//                     }}
//                   >
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter28}
//                       onMouseLeave={handleMouseLeave28}
//                       style={{ position: "relative" }}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-8px",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         <a
//                           className="nav-link dropdown-toggle"
//                           href="#"
//                           style={{
//                             color: "#C80036",
//                             fontWeight: 750,
//                             textDecoration: "none",
//                             cursor: "pointer",
//                           }}
//                           onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                         >
//                           School of Education
//                         </a>{" "}
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen28 ? "show" : ""}`}
//                         style={{
//                           marginLeft: "99%",
//                           marginTop: "-50px",
//                           display: isOpen28 ? "block" : "none", // Inline handling of visibility
//                           opacity: isOpen28 ? 1 : 0,
//                           transition: "opacity 0.3s ease-in-out",
//                         }}
//                       >
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Hyperlink of Council's Website
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Details of Students</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Facilities added during last quarter
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Number of books in Library</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Details of Sanctioned programmer along with annual
//                             intake
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Details of Faculty</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Details of Available infrastructure
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Fees Charged from Students</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Details of Biometric data</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Balance sheet as on the last date of the last year
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Income and Expenditure last financial year
//                           </p>
//                         </Link>
//                       </div>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter27}
//                       onMouseLeave={handleMouseLeave27}
//                       style={{ position: "relative" }}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-13%",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         <a
//                           className="nav-link dropdown-toggle"
//                           href="#"
//                           style={{
//                             color: "#C80036",
//                             fontWeight: 750,
//                             textDecoration: "none",
//                             cursor: "pointer",
//                           }}
//                           onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                         >
//                           School of Teaching Education
//                         </a>{" "}
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen27 ? "show" : ""}`}
//                         style={{
//                           marginLeft: "99%",
//                           marginTop: "-50px",
//                           display: isOpen27 ? "block" : "none", // Inline handling of visibility
//                           opacity: isOpen27 ? 1 : 0,
//                           transition: "opacity 0.3s ease-in-out",
//                         }}
//                       >
//                         <Link
//                           className="dropdown-item"
//                           to="https://ncte.gov.in/website/wrc.aspx"
//                           target="_blank"
//                         >
//                           <p className="pstyle1">
//                             Hyperlink of Council's Website
//                           </p>
//                         </Link>
//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter30}
//                           onMouseLeave={handleMouseLeave30}
//                           style={{ position: "relative", marginTop: "31px" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Students
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen30 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen30 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen30 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Dtails Of  Students 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter31}
//                           onMouseLeave={handleMouseLeave31}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Facilities added during last quarter
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen31 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen31 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen31 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                            to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/During the last Quarter 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter32}
//                           onMouseLeave={handleMouseLeave32}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Number of books in Library
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen32 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen32 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen32 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                       to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Library Facility 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"  to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter33}
//                           onMouseLeave={handleMouseLeave33}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Sanctioned programme along with annual
//                               intake
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen33 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen33 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen33 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                          to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/programme Anuual intake 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>
//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter34}
//                           onMouseLeave={handleMouseLeave34}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Faculty
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen34 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen34 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen34 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter35}
//                           onMouseLeave={handleMouseLeave35}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Available infrastructure
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen35 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen35 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen35 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                              to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Infrastructure 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter36}
//                           onMouseLeave={handleMouseLeave36}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Fees Charged from Students
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen36 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen36 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen36 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Fees Chargred 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter37}
//                           onMouseLeave={handleMouseLeave37}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Biometric data
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen37 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen37 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen37 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                            to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter38}
//                           onMouseLeave={handleMouseLeave38}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Balance sheet as on the last date of the last year
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen38 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen38 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen38 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter40}
//                           onMouseLeave={handleMouseLeave40}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of instructional facilities such as
//                               Laboratory & Library
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen40 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen40 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen40 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/LABORATRY & LIB 21-22 (BED)SOTE.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter39}
//                           onMouseLeave={handleMouseLeave39}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Income and Expenditure last financial year
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen39 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen39 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen39 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"  to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item"   to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>
//                       </div>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter26}
//                       onMouseLeave={handleMouseLeave26}
//                       style={{ position: "relative" }}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-13%",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         <a
//                           className="nav-link dropdown-toggle"
//                           href="#"
//                           style={{
//                             color: "#C80036",
//                             fontWeight: 750,
//                             textDecoration: "none",
//                             cursor: "pointer",
//                           }}
//                           onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                         >
//                           School of Physical Education
//                         </a>{" "}
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen26 ? "show" : ""}`}
//                         style={{
//                           marginLeft: "99%",
//                           marginTop: "-50px",
//                           display: isOpen26 ? "block" : "none", // Inline handling of visibility
//                           opacity: isOpen26 ? 1 : 0,
//                           transition: "opacity 0.3s ease-in-out",
//                         }}
//                       >
//                         <Link className="dropdown-item" to="/Engineering">
//                           <p className="pstyle1">
//                             Hyperlink of Council's Website
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Pharmacy">
//                           <p className="pstyle1">Details of Students</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Educaion">
//                           <p className="pstyle1">
//                             Facilities added during last quarter
//                           </p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Physical-Education"
//                         >
//                           <p className="pstyle1">Number of books in Library</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Management">
//                           <p className="pstyle1">
//                             Details of Sanctioned programmer along with annual
//                             intake
//                           </p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Computer-Application"
//                         >
//                           <p className="pstyle1">Details of Faculty</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Details of Available infrastructure
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Fees Charged from Students</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">Details of Biometric data</p>
//                         </Link>
//                         <Link className="dropdown-item" to="">
//                           <p className="pstyle1">
//                             Balance sheet as on the last date of the last year
//                           </p>
//                         </Link>
//                         <Link className="dropdown-item" to="/outcome/Commerce">
//                           <p className="pstyle1">
//                             Income and Expenditure last financial year
//                           </p>
//                         </Link>
//                       </div>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter13}
//                       onMouseLeave={handleMouseLeave13}
//                       style={{ position: "relative" }}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-13%",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         <a
//                           className="nav-link dropdown-toggle"
//                           href="#"
//                           style={{
//                             color: "#C80036",
//                             fontWeight: 750,
//                             textDecoration: "none",
//                             cursor: "pointer",
//                           }}
//                           onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                         >
//                           Faculty of Education (BABED)
//                         </a>{" "}
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen13 ? "show" : ""}`}
//                         style={{
//                           marginLeft: "99%",
//                           marginTop: "-50px",
//                           display: isOpen13 ? "block" : "none", // Inline handling of visibility
//                           opacity: isOpen13 ? 1 : 0,
//                           transition: "opacity 0.3s ease-in-out",
//                         }}
//                       >
//                         <Link
//                           className="dropdown-item"
//                           to="https://ncte.gov.in/website/wrc.aspx"
//                           target="_blank"
//                         >
//                           <p className="pstyle1">
//                             Hyperlink of Council's Website
//                           </p>
//                         </Link>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter30}
//                           onMouseLeave={handleMouseLeave30}
//                           style={{ position: "relative", marginTop: "31px" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Students
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen30 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen30 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen30 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Studentlist2.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Dtails Of  Students 22-23 (BA BED).pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter31}
//                           onMouseLeave={handleMouseLeave31}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Facilities added during last quarter
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen31 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen31 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen31 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Facilityduringlastq.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/During The last Quarter (BABED) 22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter32}
//                           onMouseLeave={handleMouseLeave32}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Number of books in Library
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen32 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen32 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen32 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Libraryfacilities.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Libarary (BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter33}
//                           onMouseLeave={handleMouseLeave33}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Sanctioned programme along with annual
//                               intake
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen33 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen33 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen33 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/DetailsSanction.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Proframme Along (BAMBED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter34}
//                           onMouseLeave={handleMouseLeave34}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Faculty
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen34 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen34 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen34 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LIST1F.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Staff Membars  (BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter35}
//                           onMouseLeave={handleMouseLeave35}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Available infrastructure
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen35 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen35 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen35 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Infrastructure.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/infrastructure (BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter36}
//                           onMouseLeave={handleMouseLeave36}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Fees Charged from Students
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen36 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen36 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen36 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/FeesCharged.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Fees Charged(BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter37}
//                           onMouseLeave={handleMouseLeave37}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of Biometric data
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen37 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen37 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen37 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter38}
//                           onMouseLeave={handleMouseLeave38}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Balance sheet as on the last date of the last year
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen38 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen38 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen38 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Balance sheet  (BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter40}
//                           onMouseLeave={handleMouseLeave40}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Details of instructional facilities such as
//                               Laboratory & Library
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen40 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen40 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen40 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LabLib.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Laborty and lib 22-23 (BABED).pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>

//                         {/* ================================ */}
//                         <li
//                           className="nav-item dropend"
//                           onMouseEnter={handleMouseEnter39}
//                           onMouseLeave={handleMouseLeave39}
//                           style={{ position: "relative" }}
//                         >
//                           <p
//                             style={{
//                               fontFamily: "'Open Sans',sans-serif",
//                               fontSize: "20px",
//                               color: "#C80036",
//                               marginTop: "-42px",
//                               marginLeft: "5px",
//                             }}
//                           >
//                             <a
//                               className="nav-link dropdown-toggle"
//                               href="#"
//                               style={{
//                                 color: "#C80036",
//                                 fontWeight: 750,
//                                 textDecoration: "none",
//                                 cursor: "pointer",
//                                 marginTop: "-12px",
//                               }}
//                               onClick={(e) => e.preventDefault()} // Prevent default click behavior
//                             >
//                               Income and Expenditure last financial year
//                             </a>{" "}
//                           </p>
//                           <div
//                             className={`dropdown-menu ${
//                               isOpen39 ? "show" : ""
//                             }`}
//                             style={{
//                               marginLeft: "99%",
//                               marginTop: "-50px",
//                               display: isOpen39 ? "block" : "none", // Inline handling of visibility
//                               opacity: isOpen39 ? 1 : 0,
//                               transition: "opacity 0.3s ease-in-out",
//                               width: "100px", // Increased width
//                             }}
//                           >
//                             <Link
//                               className="dropdown-item"
//                               to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
//                               target="_blank"
//                             >
//                               <p className="pstyle1">2021-2022</p>
//                             </Link> <hr style={{ margin: "5px 0", borderColor: "#1C325B" }} />
//                             <Link className="dropdown-item" to="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/income  (BABED)22-23.pdf">
//                               <p className="pstyle1">2022-2023</p>
//                             </Link>
//                           </div>
//                         </li>
//                       </div>
//                     </li>
//                   </ul>
//                 </li>

//                 {/* ---- */}
//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter5}
//                   onMouseLeave={handleMouseLeave5}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       fontFamily: "'Anton', sans-serif ",
//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{ color: "#dd2d4a", fontSize: "13px" }}
//                     >
//                       DOWNLOAD
//                     </a>
//                   </h1>

//                   <ul
//                     className={`dropdown-menu ${isOpen5 ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter13}
//                       onMouseLeave={handleMouseLeave13}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-8px",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Outcome Based Curriculum
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen13 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-50px" }}
//                       >
//                         <Link className="dropdown-item" to="/Engineering">
//                           <p className="pstyle1">Engineering</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Pharmacy">
//                           <p className="pstyle1">Pharmacy</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Educaion">
//                           <p className="pstyle1">Educaion</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Physical-Education"
//                         >
//                           <p className="pstyle1">Physical Education</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Management">
//                           <p className="pstyle1">Management</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Computer-Application"
//                         >
//                           <p className="pstyle1">Computer Application</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/BHMCT">
//                           <p className="pstyle1">BHMCT</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/outcome/Science">
//                           <p className="pstyle1">Science</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/Life-Science">
//                           <p className="pstyle1">Life Science</p>
//                         </Link>
//                         <Link
//                           className="dropdown-item"
//                           to="/Arts-And-Humanities"
//                         >
//                           <p className="pstyle1">Arts And Humanities</p>
//                         </Link>
//                         <Link className="dropdown-item" to="/outcome/Commerce">
//                           <p className="pstyle1">Commerce</p>
//                         </Link>
//                       </div>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter24}
//                       onMouseLeave={handleMouseLeave24}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           marginTop: "-42px",
//                           color: "#C80036",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{
//                             color: "#C80036",
//                             fontWeight: 800,
//                             textAlign: "right",
//                           }}
//                         >
//                           Scheme{" "}
//                         </a>
//                       </p>

//                       <ul
//                         className={`dropdown-menu ${isOpen24 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-44px" }}
//                       >
//                         <li>
//                           <Link to="/B.E-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">BE</p>
//                             </a>
//                           </Link>
//                         </li>{" "}
//                         <li>
//                           <Link to="/MBA-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">MBA</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/MCA-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">MCA</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/M.Tech-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">MTech</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/BHMCT-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">BHMCT</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/BHMS-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">BHMS</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/UTD-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">UTD</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/Education-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">Education</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/Pharmacy-scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">Pharmacy</p>
//                             </a>
//                           </Link>
//                         </li>{" "}
//                         <li>
//                           <Link to="/Paramedical-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">Paramedical</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/BLIBISC-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">B.Lib.I.Sc.</p>
//                             </a>
//                           </Link>
//                         </li>{" "}
//                         <li>
//                           <Link to="/BSCHMCS-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">B.Sc. [HMCS]</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/BscHonsAg-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">B.Sc.(Hons.) (Ag)</p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/PhysicalEducation-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">Physical Education</p>
//                             </a>
//                           </Link>
//                         </li>{" "}
//                         <li>
//                           <Link to="/BachelorofLaws-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">
//                                 Bachelor of Laws (LL.B.).
//                               </p>
//                             </a>
//                           </Link>
//                         </li>
//                         <li>
//                           <Link to="/POLYTECHNIC(ENGINEERING)-Scheme">
//                             <a
//                               className="dropdown-item"
//                               style={{ fontSize: "inherit" }}
//                             >
//                               <p className="pstyle1">
//                                 POLYTECHNIC (ENGINEERING)
//                               </p>
//                             </a>
//                           </Link>
//                         </li>
//                       </ul>
//                     </li>

//                     <li
//                       className="nav-item dropend"
//                       onMouseEnter={handleMouseEnter14}
//                       onMouseLeave={handleMouseLeave14}
//                     >
//                       <p
//                         style={{
//                           fontFamily: "'Open Sans',sans-serif",
//                           fontSize: "20px",
//                           color: "#C80036",
//                           marginTop: "-40px",
//                           marginLeft: "5px",
//                         }}
//                       >
//                         {" "}
//                         <a
//                           className="nav-link dropdown-toggle"
//                           data-bs-toggle="dropdown"
//                           aria-expanded="false"
//                           style={{ color: "#C80036", fontWeight: 800 }}
//                         >
//                           Syllabus{" "}
//                         </a>
//                       </p>
//                       <div
//                         className={`dropdown-menu ${isOpen14 ? "show" : ""}`}
//                         style={{ marginLeft: "99%", marginTop: "-45px" }}
//                       >
//                         <Link to="/B.E-Syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             {" "}
//                             <p className="pstyle1">B.E.</p>
//                           </a>
//                         </Link>
//                         <Link to="/MBA-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">MBA</p>
//                           </a>
//                         </Link>
//                         <Link to="/MCA-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">MCA</p>
//                           </a>
//                         </Link>
//                         <Link to="/UTD-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">UTD</p>
//                           </a>
//                         </Link>
//                         <Link to="/BHMS-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">BHMS</p>
//                           </a>
//                         </Link>
//                         <Link to="/MTECH-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">M.Tech</p>
//                           </a>
//                         </Link>

//                         <Link to="/Education-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Education</p>
//                           </a>
//                         </Link>
//                         <Link to="/Pharmacy-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Pharmacy</p>
//                           </a>
//                         </Link>
//                         <Link to="/BHMCT-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">B.H.M.C.T.</p>
//                           </a>
//                         </Link>

//                         <Link to="/BLibISc -syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">B.Lib.I.Sc.</p>
//                           </a>
//                         </Link>

//                         <Link to="/BScHMCS-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">B.Sc.[HMCS]</p>
//                           </a>
//                         </Link>
//                         <Link to="/BScNursing-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">B.Sc.[Nursing]</p>
//                           </a>
//                         </Link>
//                         <Link to="/BScHonsAG-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">B.Sc (Hons.)(AG)</p>
//                           </a>
//                         </Link>
//                         <Link to="/Paramedical-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Paramedical</p>
//                           </a>
//                         </Link>

//                         <Link to="/PhysicalEducation-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Physical Education</p>
//                           </a>
//                         </Link>
//                         <Link to="/Bacheloroflaws-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Bachelor Of Laws (LL.B.)</p>
//                           </a>
//                         </Link>

//                         <Link to="/PolytechnicEngineerin-syllabus">
//                           <a
//                             className="dropdown-item"
//                             style={{ fontSize: "inherit" }}
//                           >
//                             <p className="pstyle1">Polytechnic (Engineering)</p>
//                           </a>
//                         </Link>
//                       </div>
//                     </li>

//                     <Link to="/phd-notofication">
//                       <a
//                         className="dropdown-item"
//                         to={""}
//                         target="_blank"
//                         style={{
//                           fontSize: "inherit",
//                           marginTop: "-30px",
//                           marginLeft: "-10px",
//                         }}
//                       >
//                         {" "}
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Notification of Ph.D Award{" "}
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/forms">
//                       <a
//                         className="dropdown-item"
//                         target="_blank"
//                         style={{
//                           fontSize: "inherit",
//                           // marginTop: "30px",
//                           marginLeft: "-10px",
//                         }}
//                       >
//                         {" "}
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Forms{" "}
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/E-Content">
//                       <a
//                         className="dropdown-item"
//                         target="_blank"
//                         style={{
//                           fontSize: "inherit",
//                           // marginTop: "30px",
//                           marginLeft: "-10px",
//                         }}
//                       >
//                         {" "}
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           E-Content{" "}
//                         </p>
//                       </a>
//                     </Link>
//                   </ul>
//                 </li>

//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter10}
//                   onMouseLeave={handleMouseLeave10}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       textTransform: "uppercase",
//                       fontFamily: "'Anton', sans-serif ",
//                       fontWeight: 700,
//                     }}
//                   >
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{
//                         textTransform: "upperCase",
//                         fontFamily: "'Roboto',",
//                         color: "#dd2d4a",
//                         fontWeight: 800,
//                         fontSize: "13px",
//                       }}
//                     >
//                       {" "}
//                       RESEARCH{" "}
//                     </a>
//                   </h1>

//                   <div
//                     className={`dropdown-menu ${isOpen10 ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <Link
//                       className="dropdown-item "
//                       to="/Director"
//                       style={{ marginTop: "-8px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Director (R & D)
//                       </p>
//                     </Link>
//                     <Link className="dropdown-item " to="/R&D-Cell">
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         R & D Cell
//                       </p>
//                     </Link>
//                     <Link
//                       className="dropdown-item "
//                       to="/Council-For-Research"
//                       // target="_blank"
//                     >
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         {" "}
//                         Council For Research
//                       </p>
//                     </Link>
//                     <Link className="dropdown-item " to="/Research-Policy">
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Research Promotion Policy{" "}
//                       </p>
//                     </Link>
//                     <Link className="dropdown-item " to="/Consultancy-Services">
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         {" "}
//                         Counsultancy Services
//                       </p>
//                     </Link>
//                     <Link to="/Patent">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Patents
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/mou-collaboration">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Collaboration & Mou
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/IICCell">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           IIC Cell
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/E-Resources">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           E-Resources
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/Exposition">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Exposition
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/UGAndPGScholarsProject">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           UG & PG Scholars Projects
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/NPTEL">
//                       <a className="dropdown-item ">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           NPTEL
//                         </p>
//                       </a>
//                     </Link>
//                   </div>
//                 </li>

//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter1}
//                   onMouseLeave={handleMouseLeave1}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       textTransform: "uppercase",
//                       fontFamily: "'Anton', sans-serif ",
//                       fontWeight: 700,
//                     }}
//                   >
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{
//                         textTransform: "upperCase",
//                         fontFamily: "'Roboto',",
//                         color: "#dd2d4a",
//                         fontWeight: 800,
//                         fontSize: "13px",
//                       }}
//                     >
//                       {" "}
//                       Admissions{" "}
//                     </a>
//                   </h1>

//                   <div
//                     className={`dropdown-menu ${isOpen1 ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <Link
//                       className="dropdown-item"
//                       to="/Admission/AdmissionRegistration"
//                       style={{ marginTop: "-10px", width: "250px" }}
//                     >
//                       {" "}
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Admission Registration
//                       </p>
//                     </Link>
//                     <Link to="/Admission/AdmissionNotice">
//                       <a className="dropdown-item">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Admission Notice
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/Admission/UniversityAccountDetail">
//                       <a className="dropdown-item">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           University Account Detail
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/Admission/FeesStructure">
//                       <a className="dropdown-item">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Fees Structure
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/Admission/AdmissionProcedure">
//                       <a className="dropdown-item">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Admission Procedure
//                         </p>
//                       </a>
//                     </Link>
//                     <Link to="/Admission/Brochures">
//                       <a className="dropdown-item">
//                         <p
//                           className="pstyle"
//                           style={{ color: "#C80036", fontWeight: 700 }}
//                         >
//                           Brochures
//                         </p>
//                       </a>
//                     </Link>
//                   </div>
//                 </li>

//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter2}
//                   onMouseLeave={handleMouseLeave2}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       fontFamily: "'Anton', sans-serif ",
//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{ color: "#dd2d4a", fontSize: "13px" }}
//                     >
//                       Examination
//                     </a>
//                   </h1>

//                   <div
//                     className={`dropdown-menu ${isOpen2 ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <Link
//                       className="dropdown-item"
//                       to="/entrance-exam-alert"
//                       style={{ width: "250px" }}
//                     >
//                       {" "}
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "-5px",
//                         }}
//                       >
//                         Entrance Exam Alert
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to="/exam-notifications"
//                       style={{ marginTop: "-30px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "  28px",
//                         }}
//                       >
//                         Exam Notification
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to="/exam-schedule"
//                       style={{ marginTop: "-30px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "  28px",
//                         }}
//                       >
//                         Exam Schedule
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to="/result"
//                       style={{ marginTop: "-30px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "  28px",
//                         }}
//                       >
//                         Result
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to="/interface"
//                       style={{ marginTop: "-30px" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 700,
//                           marginTop: "  28px",
//                         }}
//                       >
//                         Interface
//                       </p>
//                     </Link>
//                   </div>
//                 </li>

//                 <li
//                   className="nav-item dropdown"
//                   onMouseEnter={handleMouseEnter12}
//                   onMouseLeave={handleMouseLeave12}
//                 >
//                   <h1
//                     style={{
//                       fontSize: "12px",
//                       fontFamily: "'Anton', sans-serif ",
//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     <a
//                       className="nav-link dropdown-toggle"
//                       style={{ color: "#dd2d4a", fontSize: "13px" }}
//                     >
//                       NIRF
//                     </a>
//                   </h1>

//                   <div
//                     className={`dropdown-menu ${isOpen12 ? "show" : ""}`}
//                     style={{ borderRadius: "10px" }}
//                   >
//                     <Link
//                       className="dropdown-item"
//                       to=''
//                       target="_blank"
//                       style={{ fontSize: "inherit", marginTop: "-8px" }}
//                     >
//                       {" "}
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Engineering{" "}
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to=''
//                       target="_blank"
//                       style={{ fontSize: "inherit" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Agriculture{" "}
//                       </p>
//                     </Link>

//                     <Link
//                       className="dropdown-item"
//                       to=''
//                       target="_blank"
//                       style={{ fontSize: "inherit" }}
//                     >
//                       <p
//                         className="pstyle"
//                         style={{ color: "#C80036", fontWeight: 700 }}
//                       >
//                         Pharmacy{" "}
//                       </p>
//                     </Link>
//                   </div>
//                 </li>

//                 {/* <li className="nav-item">
//                     <a
//                       className="nav-link active"
//                       aria-current="page"
//                       style={{
//                         color: "#4B0082",
//                         fontSize: "inherit",
//                         fontSize: "15px",
//                       }}
//                     >
//                       <h1
//                         style={{
//                           fontSize: "13px",
//                           fontFamily: "'Anton', sans-serif ",
//                           color: "purple",
//                           textTransform: "uppercase",
//                           fontWeight: 800,
//                         }}
//                       >
//                         ENTRANCE FORM
//                       </h1>
//                     </a>
//                     <style>
//                       {`
//                               @keyframes blink {
//                               0% { opacity: 1; }
//                              50% { opacity: 0; }
//                              100% { opacity: 1; }
//                                }
//                              `}
//                     </style>
//                   </li> */}

//                 <Link to="/alumini-form">
//                   <li className="nav-item">
//                     <a
//                       className="nav-link active"
//                       aria-current="page"
//                       style={{
//                         color: "#4B0082",
//                         // animation: "blink 1s infinite",
//                         fontSize: "inherit",
//                         fontSize: "15px",
//                       }}
//                     >
//                       {/* <h1
//                         style={{
//                           fontSize: "13px",
//                           fontFamily: "'Anton', sans-serif ",
//                           color: "purple",
//                           textTransform: "uppercase",
//                           fontWeight: "700er",
//                         }}
//                         // aria-disabled="true"
//                       >
//                         Entrance Exam
//                       </h1> */}

//                       <h1
//                         style={{
//                           fontSize: "13px",
//                           fontFamily: "'Anton', sans-serif ",
//                           color: "purple",
//                           textTransform: "uppercase",
//                           fontWeight: 800,
//                         }}
//                         // aria-disabled="true"
//                       >
//                         Alumni Form
//                       </h1>
//                     </a>
//                     <style>
//                       {`
//                               @keyframes blink {
//                               0% { opacity: 1; }
//                              50% { opacity: 0; }
//                              100% { opacity: 1; }
//                                }
//                              `}
//                     </style>
//                   </li>
//                 </Link>
//               </ul>
//             </div>

//             <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
//               <Link to="/">
//                 <li className="nav-item">
//                   <a className="nav-link active" aria-current="page">
//                     <h1
//                       style={{
//                         fontSize: "13px",
//                         fontFamily: "'Anton', sans-serif ",
//                         textTransform: "uppercase",
//                         fontWeight: 800,
//                       }}
//                     >
//                       Home
//                     </h1>
//                   </a>
//                 </li>
//               </Link>

//               <li
//                 className="nav-item dropdown"
//                 onMouseEnter={handleMouseEnter8}
//                 onMouseLeave={handleMouseLeave8}
//               >
//                 <h1
//                   style={{
//                     fontSize: "13px",
//                     fontFamily: "'Anton', sans-serif ",
//                     textTransform: "uppercase",
//                     fontWeight: 800,
//                   }}
//                 >
//                   <a className="nav-link dropdown-toggle">About</a>
//                 </h1>

//                 <div
//                   className={`dropdown-menu ${isOpen8 ? "show" : ""}`}
//                   style={{ borderRadius: "10px" }}
//                 >
//                   <Link
//                     className="dropdown-item  "
//                     to="/History_Milestones"
//                     style={{ marginTop: "-5px" }}
//                   >
//                     <p
//                       className="pstyle"
//                       style={{ color: "#C80036", fontWeight: 700 }}
//                     >
//                       About Us
//                     </p>
//                   </Link>
//                   <Link className="dropdown-item" to="/institutes">
//                     <p
//                       className="pstyle"
//                       style={{ color: "#C80036", fontWeight: 700 }}
//                     >
//                       {" "}
//                       Institutes
//                     </p>
//                   </Link>
//                   <li
//                     className="nav-item dropend"
//                     onMouseEnter={handleMouseEnter16}
//                     onMouseLeave={handleMouseLeave16}
//                   >
//                     <p
//                       style={{
//                         fontFamily: "'Open Sans',sans-serif",
//                         fontSize: "15px",
//                         color: "#C80036",
//                         marginTop: "-3px",
//                       }}
//                     >
//                       {" "}
//                       <a
//                         className="nav-link dropdown-toggle"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 600,
//                           marginLeft: "15px",
//                         }}
//                       >
//                         <b>University Officials</b>
//                       </a>
//                     </p>

//                     <div
//                       className={`dropdown-menu ${isOpen16 ? "show" : ""}`}
//                       style={{ marginLeft: "99%", marginTop: "-50px" }}
//                     >
//                       <Link className="dropdown-item" to="/chancellor">
//                         <p className="pstyle1">Chancellor</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/vice_chancellor">
//                         <p className="pstyle1">Vice Chancellor</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/registrar">
//                         <p className="pstyle1">Registrar</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/exam_controller">
//                         <p className="pstyle1">Exam Controller</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/deputy_registrar">
//                         <p className="pstyle1">Depty Registrar</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/governing_body">
//                         <p className="pstyle1">Governing Body</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/board_of_management">
//                         <p className="pstyle1">Bord Of management</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/academic_council">
//                         <p className="pstyle1">Academic Council</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/finance_committee">
//                         <p className="pstyle1">Finance Committee</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/standing_committee">
//                         <p className="pstyle1">Standing Committee</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/statutes">
//                         <p className="pstyle1">Statutes</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/audit_report">
//                         <p className="pstyle1">Adult Report</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/dean">
//                         <p className="pstyle1">Dean</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/head_of_department">
//                         <p className="pstyle1">Head Of The Department</p>
//                       </Link>
//                     </div>
//                   </li>

//                   <Link className="dropdown-item " to="/promotingsociety">
//                     <p
//                       className="pstyle"
//                       style={{
//                         color: "#C80036",
//                         fontWeight: 700,
//                         marginTop: "-28px",
//                       }}
//                     >
//                       Promoting Society
//                     </p>
//                   </Link>

//                   <li
//                     className="nav-item dropend"
//                     onMouseEnter={handleMouseEnter19}
//                     onMouseLeave={handleMouseLeave19}
//                   >
//                     <p
//                       style={{
//                         fontFamily: "'Open Sans',sans-serif",
//                         fontSize: "15px",
//                         color: "#C80036",
//                       }}
//                     >
//                       {" "}
//                       <a
//                         className="nav-link dropdown-toggle"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 600,
//                           marginLeft: "15px",
//                           marginTop: "-15px",
//                         }}
//                       >
//                         <b>Approvals & Ordinances</b>
//                       </a>
//                     </p>
//                     <div
//                       className={`dropdown-menu ${isOpen19 ? "show" : ""}`}
//                       style={{ marginLeft: "99%", marginTop: "-40px" }}
//                     >
//                       <Link className="dropdown-item" to="/approval">
//                         <p className="pstyle1">Approvals</p>
//                       </Link>

//                       <Link className="dropdown-item" to="">
//                         <p className="pstyle1">Ordinances</p>
//                       </Link>
//                     </div>
//                   </li>
//                   <Link className="dropdown-item " to="/vision-mission">
//                     <p
//                       className="pstyle"
//                       style={{
//                         color: "#C80036",
//                         fontWeight: 700,
//                         marginTop: "-30px",
//                       }}
//                     >
//                       {" "}
//                       Vision & Mission
//                     </p>
//                   </Link>
//                   <Link className="dropdown-item " to="/core-approvalsvalues">
//                     <p
//                       className="pstyle"
//                       style={{
//                         color: "#C80036",
//                         fontWeight: 700,
//                         marginTop: "-14px",
//                       }}
//                     >
//                       Core Values
//                     </p>
//                   </Link>

//                   <Link className="dropdown-item " to="/best-practices">
//                     <p
//                       className="pstyle"
//                       style={{ color: "#C80036", fontWeight: 700 }}
//                     >
//                       Best Practices
//                     </p>
//                   </Link>
//                   <li
//                     className="nav-item dropend"
//                     onMouseEnter={handleMouseEnter18}
//                     onMouseLeave={handleMouseLeave18}
//                   >
//                     <p
//                       style={{
//                         fontFamily: "'Open Sans',sans-serif",
//                         fontSize: "15px",
//                         color: "#C80036",
//                       }}
//                     >
//                       {" "}
//                       <a
//                         className="nav-link dropdown-toggle"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 600,
//                           marginLeft: "15px",
//                           marginTop: "-3px",
//                         }}
//                       >
//                         <b> Amenitie</b>
//                       </a>
//                     </p>
//                     <div
//                       className={`dropdown-menu ${isOpen18 ? "show" : ""}`}
//                       style={{ marginLeft: "99%", marginTop: "-40px" }}
//                     >
//                       <Link className="dropdown-item" to="/transprotation">
//                         <p className="pstyle1">Transportation</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/Hostel">
//                         <p className="pstyle1">Hostel</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/Library">
//                         <p className="pstyle1">Library</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/Medicalfacility">
//                         <p className="pstyle1">Medical Facillity</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/Guesthouse">
//                         <p className="pstyle1">Guest House</p>
//                       </Link>
//                     </div>
//                   </li>

//                   {/* <Link className="dropdown-item " to="/press-media">
//                     <p
//                       className="pstyle"
//                       style={{ color: "#C80036", fontWeight: 700 }}
//                     >
//                  Amenities
//                     </p>
//                   </Link> */}
//                   {/* <Link className="dropdown-item " to="/press-media">
//                     <p
//                       className="pstyle"
//                       style={{ color: "#C80036", fontWeight: 700 }}
//                     >
//                 NCC
//                     </p>
//                   </Link> */}

//                   <li
//                     className="nav-item dropend"
//                     onMouseEnter={handleMouseEnter17}
//                     onMouseLeave={handleMouseLeave17}
//                   >
//                     <p
//                       style={{
//                         fontFamily: "'Open Sans',sans-serif",
//                         fontSize: "15px",
//                         color: "#C80036",
//                       }}
//                     >
//                       {" "}
//                       <a
//                         className="nav-link dropdown-toggle"
//                         data-bs-toggle="dropdown"
//                         aria-expanded="false"
//                         style={{
//                           color: "#C80036",
//                           fontWeight: 600,
//                           marginLeft: "15px",
//                           marginTop: "-35px",
//                         }}
//                       >
//                         <b> NCC</b>
//                       </a>
//                     </p>
//                     <div
//                       className={`dropdown-menu ${isOpen17 ? "show" : ""}`}
//                       style={{ marginLeft: "99%", marginTop: "-30px" }}
//                     >
//                       <Link className="dropdown-item" to="/mou-collaboration">
//                         <p className="pstyle1">NCC MOU</p>
//                       </Link>
//                       <Link className="dropdown-item" to="/activity">
//                         <p className="pstyle1">Activity</p>
//                       </Link>
//                     </div>
//                   </li>
//                 </div>
//               </li>

//               <Link to="/placement">
//                 <li className="nav-item">
//                   <a className="nav-link">
//                     <h1
//                       style={{
//                         fontSize: "13px",
//                         fontFamily: "'Anton', sans-serif ",
//                         textTransform: "uppercase",
//                         fontWeight: 800,
//                       }}
//                     >
//                       Placement
//                     </h1>
//                   </a>
//                 </li>
//               </Link>

//               <li className="nav-item">
//                 <a className="nav-link" href="/eventss">
//                   <h1
//                     style={{
//                       fontSize: "13px",
//                       fontFamily: "'Anton', sans-serif ",
//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     EVENT
//                   </h1>
//                 </a>
//               </li>
//               <Link to="/contact">
//                 <li className="nav-item">
//                   <a className="nav-link">
//                     <h1
//                       style={{
//                         fontSize: "13px",
//                         fontFamily: "'Anton', sans-serif ",
//                         textTransform: "uppercase",
//                         fontWeight: 800,
//                       }}
//                     >
//                       Contact{" "}
//                     </h1>
//                   </a>
//                 </li>
//               </Link>
//               <li className="nav-item">
//                 <Link className="nav-link" to="/campus">
//                   <h1
//                     style={{
//                       fontSize: "13px",
//                       fontFamily: "'Anton', sans-serif ",

//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     Campus
//                   </h1>
//                 </Link>
//               </li>

//               <li className="nav-item">
//                 <Link className="nav-link" to="/campus">
//                   <h1
//                     style={{
//                       fontSize: "13px",
//                       fontFamily: "'Anton', sans-serif ",

//                       textTransform: "uppercase",
//                       fontWeight: 800,
//                     }}
//                   >
//                     {" "}
//                     Carrer
//                   </h1>
//                 </Link>
//               </li>
//               <Link to="/Epravesh/Student/Register">
//                 <li className="nav-item">
//                   <a
//                     className="nav-link active"
//                     aria-current="page"
//                     style={{
//                       color: "#4B0082",
//                       fontSize: "15px",
//                     }}
//                   >
//                     <h1
//                       style={{
//                         fontSize: "13px",
//                         fontFamily: "'Anton', sans-serif ",

//                         textTransform: "uppercase",
//                         fontWeight: 800,
//                       }}
//                     >
//                       {" "}
//                       E-Pravesh{" "}
//                     </h1>
//                   </a>
//                 </li>
//               </Link>

//               <Link to="/erp">
//                 <li className="nav-item">
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     style={{
//                       color: "white",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       marginLeft: "8px",
//                       height: "35px",
//                       width: "60px",
//                     }}
//                   >
//                     <b style={{ margin: "0" }}>ERP</b>
//                   </button>
//                 </li>
//               </Link>

//               <Link to="/admission/enquiry">
//                 <li className="nav-item">
//                   <button
//                     type="button"
//                     className="btn btn-danger"
//                     style={{
//                       color: "white",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       marginLeft: "8px",
//                       height: "35px",
//                       width: "120px",
//                     }}
//                   >
//                     <b style={{ margin: "0", animation: "blink 1s infinite" }}>
//                       ADMISSION
//                     </b>
//                   </button>
//                 </li>
//               </Link>
//             </ul>
//           </div>
//         </div>
//       </nav>
//     </>
//   );
// }

// export default Nav;

import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/sssutms.jpg";

export default function Nav() {
  return (
    <>
      <style>
        {`

.navbar-nav .nav-link {
  color: #fff;
}
.dropend .dropdown-toggle {
  color: salmon;
   margin-left: 1em;
}


.dropdown-item{
 font-family: "Poppins", serif;

  font-style: normal;

  }

.dropdown .dropdown-menu {
  display: none;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;

}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}
.nav-buttons {
  display: none; /* Hidden by default */
}
.brand-text {
  display: none;
}
      .loggo{
       
                  margin-left: -50px;

                  height: 70px;

                  width: 100px;
                  border-radius: 20px;
                }
      .navbar {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        background-color:#D2E0FB;
        position:sticky;
        margin-top:20px;
      }

.custom-sticky {
  position: sticky !important;
}

a.dropdown-item p.pstyle {
 
  font-size: 14px;
  color: black;
font-family: "Poppins", sans-serif;
 
  margin-bottom:-10px;
 
  padding: 0;
 
}

.down
{  
   font-size: 20px;
   font-family: 'Open Sans', sans-serif;
    margin-top:-8px;
      margin-bottom:-10px;
      padding:0 ;
     }


     
a.dropdown-item p.pstyle1 {
 
  font-size: 14px;
  color: #C80036;
font-family: "Poppins", sans-serif;
 
  margin-bottom:-8px;
 font-weight:700;
  padding: 0;
 
}

      // .navbar-brand{
      //   pading-right: 80px;
      // }
      .navbar .navbar-brand img {
        max-width: 100px;
      }
      .navbar .navbar-nav .nav-link {
        color: #000;
      }
      @media screen and (min-width: 1024px) {
        .navbar {
          letter-spacing: 0.1em;
        }
        .navbar .navbar-nav .nav-link {
          padding: 0.5em 1em;
        }
        .search-and-icons {
          width: 30%;
        }
        .search-and-icons form {
          flex: 1;
        }
      }

      @media screen and (max-width:480px){
 
     .nav-buttons {
    display: flex;
    flex-direction:row;
    width:220px;
   justify-content: space-between; /* Center the buttons */
    margin-top: 10px;
  }

  /* Adjust button styles for smaller screens */
  .bt {

    height: 20px;
    color:#B8001F;
    font-weight:700;
    font-size: 15px;
  }

  .loggo{
       
              margin-left: -5px;

                  height: 50px;

                  width: 50px;
                  border-radius: 20px;
                }}
      @media screen and (min-width: 768px) {
        .navbar .navbar-brand img {
          max-width: 4em;
        }


      .navbar-toggler{
          backgound-color:oragne;
      }

        .navbar .navbar-collapse {
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-end;
        }
        .search-and-icons {
          display: flex;
          align-items: center;
        }
      }
      .search-and-icons form input {
        border-radius: 0;
        height: 2em;
        background: black
          url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='grey' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E")
          no-repeat 95%;
      }
      .search-and-icons form input:focus {
        background: black;
        box-shadow: none;
      }
      .search-and-icons .user-icons div {
        padding-right: 1em;
      }
      .contact-info p,
      .contact-info a {
        font-size: 0.7em;
        padding-right: 2em;
        // color: grey;
      }
     
      .contact-info a {
        padding-right: 0;
      }

      . {
        font-size: 0.8rem; /* Adjust the size as needed */
        font-weight:700er;

      }

  @import url('https://fonts.googleapis.com/css2?family=Basic&family=Doppio+One&display=swap')

  .dropdown-menu {
    max-height: 300px; /* Set a maximum height */
    overflow-y: auto;  /* Enable vertical scrolling */
    font-size: 0.8rem; /* Adjust the font size to be smaller */
}
 
       `}
      </style>

      <nav
        className="navbar navbar-expand-md bg-body-tertiary sticky-top"
        style={{ width: "100%", backgroundColor: "#D2E0FB" }}
      >
        <div className="container-xl" style={{ width: "100%" }}>
          <a className="navbar-brand d-flex align-items-center">
            <Link to="/">
              <img className="loggo" src={logo} alt="Logo" />
            </Link>
            <span className="brand-text">
              SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
            </span>
          </a>{" "}
          <div className="nav-buttons">
            <Link to="/erp">
              <h5 className="bt">ERP</h5>
            </Link>
            <Link to="/Epravesh/Student/Register">
              <h5 className="bt"> E-Pravesh</h5>
            </Link>
            <Link to="/admission/enquiry">
              <h5 className="bt"> Admission</h5>
            </Link>
          </div>
          <button
            style={{ backgroundColor: "#EEEEEE" }}
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span>
              <i class="fa-solid fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div
              className="contact-info d-md-flex"
              style={{ marginLeft: "-10px" }}
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                {/* ==================================  Acadmic  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Academic
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{ height: "auto", width: "230px" }}
                  >
                    <li
                      className="nav-item dropend"
                      style={{
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                      }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#dd2d4a",
                        }}
                      >
                        Faculties & Department
                      </a>

                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "520px",
                          overflowY: "auto",
                          width: "390px",
                          // scrollbarWidth: 'thin',
                          // scrollbarColor:'#1C325B',
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/enggtechno"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Engineering & Technology
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/pharma"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/edu"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/design"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Design
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/humanities&languag"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Humanities & Language
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/commerce"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Commerce
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/science"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Ayurveda
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Law
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Homeopathy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Nursing
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href=""
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Computer Science & Application
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Bacheloroflaws-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Hotel Management & Catering Technology
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PolytechnicEngineerin-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Polytechnic (Engineering)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/phd-page"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        PHD
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/event-calender"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Event Calender
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/academic-calender"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Acadmic Calender
                      </a>
                    </li>


                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Committee
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/anti-ragging"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Anti Ragging
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/proctorial-board"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Proctorial Board
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            href="/internal-complaint"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Internal Complaint
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/grievance-redressal"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Grievance Redressal
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/sc-st"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            For SC-ST
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/edc"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            EDC
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/international-higher-education-cell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            International Higher Education Cell
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/incubation-cell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Incubation Cell
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/scholarship"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Scholarship
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Nacc
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            href="/SSR"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            SSR
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria1"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-1
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria2"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-2
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria3"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-3
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria4"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-4
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria5"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-5
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/criteria6"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Criteria-6
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/constituent-units"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Constituent Units
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Activites
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ width: "230px", height: "200px" }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/expert_lectures"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Expert Lecture
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/webinar"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Webinar
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/industrial_visits"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Industrial Visits
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/activity/events"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Events
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/fdp"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            FDP
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/workshop_seminars"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            WorkShop & Seminars
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Hei-handbook"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        HEL Handbook
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Training & Placement
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ height: "80px", width: "280px" }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Academic/TrainingAndPlacementCell"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Training And Placement Cell
                          </a>
                        </li>{" "}
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Academic/TrainingPartner"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Training Partner
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/iqac_cell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        IQAC Cell
                      </a>
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/mandatory_disclosures"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Mandatory Disclosures
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ================================== Faculty Of Education =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Faculty Of Education
                  </a>
                  <ul className="dropdown-menu">
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/studentlist.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/StudentDetails23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/FacilityAdded.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/FacilityAdded23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/LibFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/LibraryFacility23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        {/* <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li> */}

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                            intake
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/annual Intake.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/annual Intake23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/facultyD.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/facultyD.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/infrastrucesoe21.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/infrastrucesoe23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/FeeCharged.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/FeeCharged23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/Biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/Biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/InstructionalFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOE/22-23/Instructional Facility23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Teaching Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Dtails Of  Students 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/StudentDetails.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/During the last Quarter 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/FacilityLastQuarter.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Library Facility 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/LibraryFacility.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/programme Anuual intake 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/SanctionProgramI23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Faculty Details.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Faculty Details.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Infrastructure 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Available_Infrastructure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/Fees Chargred 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/FeesCharged23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BIOTMETRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BIOTMETRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/LABORATRY & LIB 21-22 (BED)SOTE.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/LibLab.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>

                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        School of Physical Education
                      </a>
                      <ul className="dropdown-menu">
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/details of students 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/details of students 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/added facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/added facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <Link
                            className="nav-link dropdown-toggle"
                            to={""}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Number of books in Library
                          </Link>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/librararyfac.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/library facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Receipt and Payment acccout for the last financial
                            year uploaded on Website
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                            intake
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/annual intake 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/annual intake 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/facultyd.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/facultyd.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/infrastructure facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/instructional facilities 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/fees charged 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/fees charged 2022-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/biometric.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/BalanceSheet22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance Sheet 2022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Details of Instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <Link
                                className="dropdown-item"
                                to="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/21-22/instructional facilities 2021-22.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </Link>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOP/22-23/incomeandexpenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                          fontFamily: "'Poppins',Serif",
                        }}
                      >
                        Faculty of Education (BABED)
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{ marginTop: "-80px" }}
                      >
                        <li className="nav-item dropend">
                          <a
                            href="https://ncte.gov.in/website/wrc.aspx"
                            target="_blank"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Hyperlink of Council's Website
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Studentlist2.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Dtails Of  Students 22-23 (BA BED).pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Facilities added during last quarter
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Facilityduringlastq.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/During The last Quarter (BABED) 22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Number of books in Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Libraryfacilities.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Libarary (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Sanctioned programme along with annual
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/DetailsSanction.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Proframme Along (BAMBED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Faculty
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LIST1F.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Staff Membars  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Available infrastructure
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Infrastructure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/infrastructure (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Fees Charged from Students
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/FeesCharged.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Fees Charged(BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of Biometric data
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                {" "}
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/BIOMATRIC.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Balance sheet as on the last date of the last year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/SOTE/22-23/Balance%20Sheet%202022-2023.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Balance sheet  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Details of instructional facilities such as
                            Laboratory & Library
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/LabLib.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/Laborty and lib 22-23 (BABED).pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li className="nav-item dropend">
                          <a
                            className="nav-link dropdown-toggle"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 700,
                              fontSize: "14px",
                              marginTop: "-10px",
                              marginBottom: "-8px",
                              marginLeft: "10px",
                            }}
                          >
                            Income and Expenditure last financial year
                          </a>
                          <ul className="dropdown-menu">
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/Expenditure.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2021-2022
                              </a>
                            </li>{" "}
                            <li>
                              <hr className="dropdown-divider" />
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="https://cdn.sssutms.ac.in/media/static/NCTE/FACULTY_OF_EDUCATION/22-23/income  (BABED)22-23.pdf"
                                target="_blank"
                                style={{
                                  color: "#dd2d4a",
                                  fontWeight: 800,
                                  fontSize: "14px",
                                  marginTop: "-5px",
                                  marginBottom: "-7px",
                                }}
                              >
                                2022-2023
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                {/* ==================================  DOWNLOAD  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    DOWNLOAD
                  </a>
                  <ul className="dropdown-menu" style={{ width: "280px" }}>
                    <li className="nav-item dropend">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontFamily: "'Anton', sans-serif ",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Outcome Based Curriculum
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "460px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Engineering"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Engineering
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Educaion"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Educaion
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Physical-Education"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Management"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Management
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Computer-Application"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Computer Application
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/outcome/Science"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Life-Science"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Life Science
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Arts-And-Humanities"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Arts And Humanities
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/outcome/Commerce"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Commerce
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          fontFamily: "'Anton', sans-serif ",
                        }}
                      >
                        Scheme
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "430px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/B.E-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BE
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MBA-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MBA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MCA-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MCA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/M.Tech-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MTech
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMS-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMS
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/UTD-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            UTD
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Education-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy-scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BLIBISC-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Lib.I.Sc.
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BSCHMCS-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc. [HMCS]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BscHonsAg-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.(Hons.) (Ag)
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PhysicalEducation-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BachelorofLaws-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Bachelor of Laws (LL.B.).
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/POLYTECHNIC(ENGINEERING)-Scheme"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            POLYTECHNIC (ENGINEERING)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li
                      className="nav-item dropend"
                      style={{ marginBottom: "-7px", marginTop: "-5px" }}
                    >
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          fontFamily: "'Anton', sans-serif ",
                        }}
                      >
                        Syllabus
                      </a>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: "430px", // Adjust the height as needed
                          overflowY: "auto",
                        }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="/B.E-Syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BE
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MBA-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MBA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MCA-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MCA
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/MTECH-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            MTech
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMCT-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMCT
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BHMS-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            BHMS
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/UTD-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            UTD
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Education-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Education
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Pharmacy-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Pharmacy
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BLibISc -syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Lib.I.Sc.
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScHMCS-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc. [HMCS]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScNursing-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.[Nursing]
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/BScHonsAG-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            B.Sc.(Hons.) (Ag)
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Paramedical-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Paramedical
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PhysicalEducation-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Physical Education
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/Bacheloroflaws-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            Bachelor of Laws (LL.B.).
                          </a>
                        </li>

                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/PolytechnicEngineerin-syllabus"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              color: "#dd2d4a",
                              fontWeight: 800,
                              fontSize: "13px",
                              marginTop: "-5px",
                              marginBottom: "-7px",
                            }}
                          >
                            POLYTECHNIC (ENGINEERING)
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/phd-notofication"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Notification of Ph.D Award{" "}
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/forms"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          marginLeft: "-10px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          fontWeight: 700,
                          fontSize: "13px",
                        }}
                      >
                        Forms
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================  RESEARCH  =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    RESEARCH
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="/R&D-Cell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        R & D Cell
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Council-For-Research"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Council For Research
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Research-Policy"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Research Promotion Policy{" "}
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Consultancy-Services"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Counsultancy Services
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Patent"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Patents
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/IICCell"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        IIC Cell
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/E-Resources"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        E-Resources
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Exposition"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        Exposition
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/UGAndPGScholarsProject"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        UG & PG Scholars Projects
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/NPTEL"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                        }}
                      >
                        NPTEL
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================  Admissions =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Admissions
                  </a>
                  <ul className="dropdown-menu" style={{ width: "250px" }}>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionRegistration"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Registration
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionNotice"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Notice
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/UniversityAccountDetail"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        University Account Detail
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/FeesStructure"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Fees Structure
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/AdmissionProcedure"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Admission Procedure
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/Admission/Brochures"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-7px",
                          marginTop: "-5px",
                          marginLeft: "-10px",
                        }}
                      >
                        Brochures
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================   Examination =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    Examination
                  </a>
                  <ul className="dropdown-menu" style={{ width: "200px" }}>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/entrance-exam-alert"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Entrance Exam Alert
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="/exam-notifications"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Exam Notification
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/exam-schedule"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Exam Schedule
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/result"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Result
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="dropdown-item"
                        href="/interface"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                          marginBottom: "-5px",
                          marginTop: "-3px",
                          marginLeft: "-5px",
                        }}
                      >
                        Interface
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ==================================  NIRF =========================================== */}

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style={{
                      fontSize: "12px",
                      textTransform: "uppercase",
                      fontFamily: "'Roboto',",
                      color: "#dd2d4a",
                      fontWeight: 800,
                      fontSize: "13px",
                    }}
                  >
                    NIRF
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://cdn.sssutms.ac.in/media/static/NIRF/NRIF_Eng.5e3ba35bf9376cd308b6.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Engineering
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://cdn.sssutms.ac.in/media/static/NIRF/NRIF_Agri.f7230e609082a9d1b2cd.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Agriculture
                      </a>
                    </li>

                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        href="https://cdn.sssutms.ac.in/media/static/NIRF/NIRF_Phar..67f8cc4e882a0eb616a4.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#dd2d4a",
                          fontWeight: 700,
                          fontSize: "14px",
                        }}
                      >
                        Pharmacy
                      </a>
                    </li>
                  </ul>
                </li>

                {/* ====================================== Alumni Form ================================== */}

                <Link to="/alumini-form">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      aria-current="page"
                      style={{
                        color: "#4B0082",
                        // animation: "blink 1s infinite",
                        fontSize: "inherit",
                        fontSize: "15px",
                      }}
                    >
                      {/* <h1
                        style={{
                          fontSize: "13px",
                          fontFamily: "'Anton', sans-serif ",
                          color: "purple",
                          textTransform: "uppercase",
                          fontWeight: "700er",
                        }}
                        // aria-disabled="true"
                      >
                        Entrance Exam
                      </h1> */}

                      <h1
                        style={{
                          fontSize: "13px",
                          fontFamily: "'Anton', sans-serif ",
                          color: "purple",
                          textTransform: "uppercase",
                          fontWeight: 800,
                        }}
                        // aria-disabled="true"
                      >
                        Alumni Form
                      </h1>
                    </a>
                    <style>
                      {`
                              @keyframes blink {
                              0% { opacity: 1; }
                             50% { opacity: 0; }
                             100% { opacity: 1; }
                               }
                             `}
                    </style>
                  </li>
                </Link>
              </ul>
            </div>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <Link to="/">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Home
                    </h1>
                  </a>
                </li>
              </Link>

              {/* ==================================  ABOUT =========================================== */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "12px",
                    textTransform: "uppercase",
                    fontFamily: "'Roboto',",
                    // color: "#dd2d4a",
                    fontWeight: 800,
                    fontSize: "13px",
                    marginTop: "-2px",
                  }}
                >
                  About
                </a>
                <ul
                  className="dropdown-menu"
                  style={{ height: "auto", width: "280px" }}
                >
                  <li>
                    <a
                      className="dropdown-item"
                      href="/History_Milestones"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Background
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/institutes"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "10px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Institutes
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{
                      marginBottom: "-7px",
                      marginTop: "-10px",
                      fontWeight: 700,
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "#dd2d4a",
                      }}
                    >
                      University Officials
                    </a>

                    <ul
                      className="dropdown-menu"
                      style={{
                        maxHeight: "520px",
                        overflowY: "auto",
                        width: "300px",
                        // scrollbarWidth: 'thin',
                        // scrollbarColor:'#1C325B',
                      }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/chancellor"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Chancellor
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/vice_chancellor"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Vice Chancellor
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/exam_controller"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Exam Controller
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/deputy_registrar"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Depty Registrar
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/governing_body"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Governing Body
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/board_of_management"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Bord Of management
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/academic_council"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Academic Council
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/finance_committee"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Finance Committee
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/standing_committee"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Standing Committee
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/statutes"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Statutes
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/audit_report"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Adult Report
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/dean"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Dean
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href=""
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Nursing
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/head_of_department"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Head Of The Department
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/promotingsociety"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Promoting Society
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{ marginBottom: "-7px", marginTop: "-10px" }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        color: "#dd2d4a",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      Approvals & Ordinances
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="/approval"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Approvals
                        </a>
                      </li>

                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href=""
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Ordinances
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/vision-mission"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Vision & Mission
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/core-approvalsvalues"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Core Values
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/best-practices"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Best Practices
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{
                      marginBottom: "-7px",
                      marginTop: "-10px",
                      fontWeight: 700,
                    }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        fontWeight: 700,
                        fontSize: "14px",
                        color: "#dd2d4a",
                      }}
                    >
                      Amenities
                    </a>

                    <ul
                      className="dropdown-menu"
                      style={{
                        maxHeight: "520px",
                        overflowY: "auto",
                        width: "260px",
                        // scrollbarWidth: 'thin',
                        // scrollbarColor:'#1C325B',
                      }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/transprotation"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Transportation
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Hostel"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Hostel
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Library"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Library
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Medicalfacility"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Medical Facillity
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/Guesthouse"
                          rel="noopener noreferrer"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Guest House
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li
                    className="nav-item dropend"
                    style={{ marginBottom: "-7px", marginTop: "-10px" }}
                  >
                    <a
                      className="nav-link dropdown-toggle"
                      // role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        color: "#dd2d4a",
                        fontWeight: 700,
                        fontSize: "14px",
                      }}
                    >
                      Collaboration & Mou
                    </a>
                    <ul
                      className="dropdown-menu"
                      style={{ height: "80px", width: "200px" }}
                    >
                      <li>
                        <a
                          className="dropdown-item"
                          href="/mou-collaboration"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          NCC MOU
                        </a>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="/activity"
                          style={{
                            color: "#dd2d4a",
                            fontWeight: 800,
                            fontSize: "13px",
                            marginTop: "-5px",
                            marginBottom: "-7px",
                          }}
                        >
                          Activity
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/Public-Self-Disclosure"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Public Self Disclosure
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/Central_facilities"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Central Facilities
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/InstitutionalDevPlan "
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Institutional Development Plan
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/annual_reports"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      Annual Reports
                    </a>
                  </li>

                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="dropdown-item"
                      href="/NSS"
                      rel="noopener noreferrer"
                      style={{
                        color: "#dd2d4a",
                        marginLeft: "-10px",
                        marginBottom: "-7px",
                        marginTop: "-5px",
                        fontWeight: 700,
                        fontSize: "13px",
                      }}
                    >
                      NSS
                    </a>
                  </li>
                </ul>
              </li>
          

              <Link to="/placement">
                <li className="nav-item">
                  <a className="nav-link">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Placement
                    </h1>
                  </a>
                </li>
              </Link>

              <li className="nav-item">
                <a className="nav-link" href="/eventss">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",
                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    EVENT
                  </h1>
                </a>
              </li>

              <Link to="/contact">
                <li className="nav-item">
                  <a className="nav-link">
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",
                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      Contact{" "}
                    </h1>
                  </a>
                </li>
              </Link>

              <li className="nav-item">
                <Link className="nav-link" to="/campus">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",

                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    Campus
                  </h1>
                </Link>
              </li>

              {/* <li className="nav-item">
                <Link className="nav-link" to="/campus">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",

                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    {" "}
                    Carrer
                  </h1>
                </Link>
              </li> */}
                 <li className="nav-item">
                <Link className="nav-link" to="/event-calender">
                  <h1
                    style={{
                      fontSize: "13px",
                      fontFamily: "'Anton', sans-serif ",
                      textTransform: "uppercase",
                      fontWeight: 800,
                    }}
                  >
                    EVENT-CALENDER
                  </h1>
                </Link>
              </li>


              <Link to="/Epravesh/Student/Register">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    style={{
                      color: "#4B0082",
                      fontSize: "15px",
                    }}
                  >
                    <h1
                      style={{
                        fontSize: "13px",
                        fontFamily: "'Anton', sans-serif ",

                        textTransform: "uppercase",
                        fontWeight: 800,
                      }}
                    >
                      {" "}
                      E-Pravesh{" "}
                    </h1>
                  </a>
                </li>
              </Link>

              <Link to="/erp">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "8px",
                      height: "35px",
                      width: "60px",
                    }}
                  >
                    <b style={{ margin: "0" }}>ERP</b>
                  </button>
                </li>
              </Link>

              <Link to="/admission/enquiry">
                <li className="nav-item">
                  <button
                    type="button"
                    className="btn btn-danger"
                    style={{
                      color: "white",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "8px",
                      height: "35px",
                      width: "120px",
                    }}
                  >
                    <b style={{ margin: "0", animation: "blink 1s infinite" }}>
                      ADMISSION
                    </b>
                  </button>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}


