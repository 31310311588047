import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function PhdPage() {
  return (
    <>
      <Nav />
      <style>
        {`

  

.cont{
margin-top:20px;}



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-18px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 118px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}


 



@media only screen and (max-width: 480px){




.h2 title-main{
margin-left:18px}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          PHD
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <p
              className="mt-3 "
              style={{
                textAlign: "justify",
                color: "black",
                marginLeft: "50px",
                fontFamily: "'poppins',sansSerif",
              }}
            >
              Sri Satya Sai University Of Technology And Medical Sciences Sehore
              offers Doctoral Programs (PHD) in various research areas. At
              present Ph. D programme is being offered in the Faculty of
              Engineering & Technology, Pharmacy, Management, Education, Basic &
              applied Sciences, Commerce, Social Sciences, Humanities and
              English etc...
            </p>

            <br />

            <h4
              style={{
                fontFamily: "'poppins',sansSerif",
                marginLeft: "80px",
                color: "#4C1F7A",
              }}
            >
              {" "}
              Information about Ph. D degree Awarded by The University during
              the Year 2022
            </h4>
            <br></br>
            <br></br>
            <ul className="list-icon">
              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/RESEARCH POLICY.pdf"
                  target="_blank"
                >
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    Research Policy{" "}
                  </span>
                </Link>
              </li>

              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/RAC (1).pdf"
                  target="_blank"
                >
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    Constitution of Research Advisory Committee(RAC)
                  </span>
                </Link>
              </li>
              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Ordinance.pdf"
                  target="_blank"
                >
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    {" "}
                    Admission Policy
                  </span>
                </Link>
              </li>

              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/fellowhip (1).pdf"
                  target="_blank"
                >
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    Fellowship/Scholarship Policy
                  </span>
                </Link>
              </li>

              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/RAC (1).pdf"
                  target="_blank"
                  style={{ color: "#3B1C32" }}
                >
                  <i className="fa fa-share" aria-hidden="true"></i>
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    Ethics Committee
                  </span>
                </Link>
              </li>

              <li className="llis">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Grievances (1) (1).pdf"
                  target="_blank"
                >
                  <i class="fa fa-share" aria-hidden="true"></i>{" "}
                  <span style={{ color: "#4C1F7A", fontWeight: 700 }}>
                    {" "}
                    Grievance Cell
                  </span>
                </Link>
              </li>
            </ul>

            <br></br>

            <ul className="list-icon">
              <br></br>

              <li className="listy">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Book1.pdf"
                  target="_blank"
                >
                  <span style={{ fontWeight: 700 }}>
                    {" "}
                    Recognized Ph.D. Supervisor
                  </span>
                </Link>
              </li>

              <li className="listy">
                <Link
                  to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHD_FEE2021.pdf"
                  target="_blank"
                >
                  <span
                    style={{ fontSize: "18px", fontFamily: "'Poppins', serif" }}
                  >
                    {" "}
                    Fees Structure of Ph.D (w.e.f. 03-08-2021)
                  </span>
                </Link>
              </li>

              <li className="listy">
                <Link to="">
                  <span
                    style={{
                      color: "#003161",
                      fontSize: "18px",
                      fontFamily: "'Poppins', serif",
                    }}
                  >
                    {" "}
                    Download Syllabus for Ph.D.(Course Work)
                  </span>
                </Link>
              </li>

              <li className="listy">
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: 700,
                    fontFamily: "'Poppins', serif",
                  }}
                >
                  {" "}
                  FIRST SEMESTER (w.e.f. 2021-22)
                  <li style={{ listStyleType: "none" }}>
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/RM_2021.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          color: "#003161",
                          fontSize: "15px",
                          fontFamily: "'Poppins', serif",
                          marginTop: "-15px",
                        }}
                      >
                        Research Methodology Subject Specialization- I
                      </span>
                    </Link>
                  </li>
                  <br></br>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/RDCSYP_2.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Format for RDC Synopsis{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHDCONFR.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Format of Confidential Progress Report{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHDNOT16.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Circular: Prior to submission of Ph.D. Thesis
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHDTHGUIDE_RRev.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Guideline for Ph.D. Pre Submission Viva Voce
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Phd_Pre_submission_Form_2.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Proforma for Pre-submission of the Thesis{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHDTHFORMAT_RR.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Guideline/Format for preparing Thesis
                        /Dissertation/Summary{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/PHDTHFORMAT_RR_(1)_02062022_1255.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Guideline/Format for preparing Thesis
                        /Dissertation/Summary (Hindi){" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Phd_Final_submission_Form_2.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Proforma for Final-submission of the Thesis{" "}
                      </span>
                    </Link>
                  </li>
                  <li className="listy">
                    <Link
                      to="https://cdn.sssutms.ac.in/media/static/PDF/PHD/Co_supervisior.pdf"
                      target="_blank"
                    >
                      <span
                        style={{
                          fontSize: "18px",
                          fontFamily: "'Poppins', serif",
                        }}
                      >
                        {" "}
                        Proforma for Approval of Co-Supervisor
                      </span>
                    </Link>
                  </li>
                </span>
              </li>
            </ul>
          </div>
          <div className="col-lg-3">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Academic
              </h5>
              <br></br>{" "}
              <li className="lli">
                <Link to="/phd-page" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>PHD
                </Link>
              </li>
              <li className="lli">
                <Link to="/academic-calender" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic
                  Calender
                </Link>
              </li>
              <li className="lli">
                <Link to="/scholarship" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Scholarship{" "}
                </Link>
              </li>
              <li className="lli">
                <Link to="/constituent-units" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Constituent
                  Units
                </Link>
              </li>
              <li className="lli">
                <Link to="/Hei-handbook" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook{" "}
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link to="/iqac_cell" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell
                </Link>
              </li>
              <li className="lli">
                {" "}
                <Link to="/mandatory_disclosures" target="_blank">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory
                  Disclosures{" "}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default PhdPage;
