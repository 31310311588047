import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Footerpage from "../HomePage/Footerpage";
import Nav from "../HomePage/Nav";

const media = [
  "https://cdn.sssutms.ac.in/media/static/constitution/v1.mp4", // Video link
  "https://cdn.sssutms.ac.in/media/static/constitution/co1.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co2.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co3.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co6.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co7.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/frame (2).jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co4.jpg",
  "https://cdn.sssutms.ac.in/media/static/constitution/co5.jpg",
];

const Constitution_Day = () => {
  const [showModal, setShowModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setShowModal(true);
  };

  const closeModal = () => setShowModal(false);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? media.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === media.length - 1 ? 0 : prevIndex + 1
    );
  };

  const isVideo = (src) => src.endsWith(".mp4");

  return (
    <>
      <Nav />
      <h5 style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
        Constitution Day Celebration
      </h5>
      <br />
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {media.map((src, index) => (
          <div
            key={index}
            style={{
              width: "32%", // Ensures 3 items per row with no extra space
              marginBottom: "10px",
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => openModal(index)}
          >
            {isVideo(src) ? (
              <div style={{ position: "relative" }}>
                <video
                  src={src}
                  style={{
                    width: "100%",
                    height: "200px",
                    objectFit: "cover",
                  }}
                  muted
                />
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "white",
                    fontSize: "40px",
                    // background: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  ▶
                </div>
              </div>
            ) : (
              <img
                src={src}
                alt={`Event ${index + 1}`}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                }}
              />
            )}
          </div>
        ))}
      </div>

      <Modal show={showModal} onHide={closeModal} centered size="md">
        <Modal.Body style={{ position: "relative", padding: 0 }}>
          {isVideo(media[currentIndex]) ? (
            <video
              src={media[currentIndex]}
              style={{ width: "100%", height: "auto" }}
              controls
              autoPlay
            />
          ) : (
            <img
              src={media[currentIndex]}
              alt={`Event ${currentIndex + 1}`}
              style={{ width: "100%", height: "auto" }}
            />
          )}
          <Button
            style={{
              position: "absolute",
              top: "50%",
              left: "-30px",
              transform: "translateY(-50%)",
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "23px",
              fontWeight: "bolder",
              opacity: 0.8,
            }}
            onClick={handlePrev}
          >
            {"<"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "50%",
              right: "-30px",
              transform: "translateY(-50%)",
              color: "#FFAF00",
              backgroundColor: "black",
              border: "none",
              fontSize: "23px",
              opacity: 0.8,
            }}
            onClick={handleNext}
          >
            {">"}
          </Button>
          <Button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              height: "30px",
              width: "30px",
              opacity: 0.8,
              backgroundColor: "black",
              border: "none",
              color: "#FABC3F",
              padding: "10px",
              fontSize: "15px",
            }}
            onClick={closeModal}
          >
            X
          </Button>
        </Modal.Body>
      </Modal>

      <Footerpage />
    </>
  );
};

export default Constitution_Day;
