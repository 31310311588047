import React from 'react';
import {
  Table,
  Button,
  Checkbox,
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Typography from "@mui/material/Typography";

const Examination_Form = ({
  userData,
  examForms,
  formData,
  sssutms,
  ischecked,
  handleCheckbox,
  generatePDF,
}) => {
  return (
    <div style={{ padding: '20px', maxWidth: '900px', margin: 'auto' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <img
          src={sssutms}
          alt="Logo"
          style={{ width: '100px', marginRight: '20px' }}
        />
        <div>
          <Typography
            style={{ margin: 0, fontSize: '15px', fontWeight: 'bold' }}
          >
            SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE
          </Typography>
          <Typography variant="body1" style={{ margin: 0, textAlign: 'center' }}>
            ( {userData?.courseBranch||"not found"} )
          </Typography>
          <Typography variant="body1" style={{ margin: 0, textAlign: 'center' }}>
            {examForms[0]?.examFormId?.sessionName}
          </Typography>
        </div>
      </div>

      {/* Form Details */}
      <Form>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>Enrollment No:</Form.Label>
            <span> {userData?.enrollmentNumber}</span>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Semester/Year:</Form.Label>
            <span> {examForms[0]?.examFormId?.semester}</span>
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Form.Label>Candidate's Name:</Form.Label>
            <span> {userData?.name}</span>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Father's Name:</Form.Label>
            <span> {userData?.fathersname}</span>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Mother's Name:</Form.Label>
            <span> {userData?.mothersname}</span>
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Status:</Form.Label>
            <span> {examForms[0]?.examFormId?.category}</span>
          </Form.Group>
        </Row>

        {/* Table for Subjects */}
        <Table striped bordered hover>
          <thead>
            <tr style={{ backgroundColor: 'rgba(224, 224, 224, 1)' }}>
              <th>Sr.No</th>
              <th>Subject Code</th>
              <th>Subject Name</th>
              <th>Appearing In</th>
            </tr>
          </thead>
          <tbody>
            {formData?.subjects.map((subject, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{subject.subjectCode}</td>
                <td>{subject.subjectName}</td>
                <td>All</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Candidate's Description */}
        <div style={{ marginTop: '20px' }}>
          <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
          <hr
            style={{
              width: '40%',
              height: '2px',
              backgroundColor: 'gray',
              margin: '-2px 0',
            }}
          />
          <Typography variant="body2" gutterBottom>
            <ul style={{ marginLeft: '20px', marginTop: '10px' }}>
              <li>
                I certify that this application has been filled by me and the
                information given therein is correct, and I shall be responsible if proved
                false later on.
              </li>
              <li>
                I am appearing as a regular candidate only in the examination conducted by
                the Sri Satya Sai University of Technology and Medical Sciences, Sehore.
              </li>
              {/* Add other points as required */}
              <li>
                <b>
                  I also request the University not to disclose my result to anybody in
                  any manner without my written consent, including RTI.
                </b>
              </li>
            </ul>
            <Checkbox checked={ischecked} onChange={handleCheckbox} /> I accept all above
            points.
          </Typography>
        </div>
      </Form>

      {/* Examination Fee Details */}
      <div
        style={{
          padding: '15px 20px',
          marginTop: '20px',
          backgroundColor: '#f9f9f9',
          border: '1px solid #ddd',
          borderRadius: '8px',
        }}
      >
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
          EXAMINATION FEE DETAILS
        </Typography>
        <hr
          style={{
            width: '30%',
            height: '2px',
            backgroundColor: '#333',
            margin: '-2px 0 10px 0',
          }}
        />
        <Typography variant="subtitle2" gutterBottom>
          Transaction No:- 348622023220112725dcd4, Paid On:- 20/02/2023 11:27:52,
          Exam Fee:- 1830
        </Typography>
      </div>

      {/* Buttons */}
      <div style={{ marginTop: '20px', textAlign: 'right' }}>
        <Button variant="outlined" onClick={generatePDF}>
          Download as PDF
        </Button>
      </div>
    </div>
  );
};

export default Examination_Form;
