import React from 'react';
import { useLocation } from 'react-router-dom';
import jsPDF from "jspdf";
import logo from "../../images/sssutms.jpg"
import AdminDashboard from './AdminDashboard';

const Manuual_Fee_Receipt = () => {
  const location = useLocation();
  const { student , payment } = location.state || {}; 


const transactionTime = payment.
txnDate

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addImage(logo, "JPEG", 3, 5, 30, 28);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0); // Set text color to red for the title
    doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Set text color back to black

    // Set the initial y position
    let yPos = 60;

    // Define the line height
    const lineHeight = 10;

    doc.text(
      `                                     Name of Candidate  :  ${student.name}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Father's Name          :  ${student.fathersname}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Email                         :  ${student.email}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Mobile                       :  ${student.mobile}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Type             :  ${student.courseType}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Name            :  ${student.courseName}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Branch         :  ${student.courseBranch}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     TXN ID                       :   ${payment.txnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     ORDER ID                 :  ${payment.orderId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     PAYMENT MODE     :  ${payment.paymentMode}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     BANKTXN ID            :  ${payment.bankTxnId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Paid Amount            :  ${payment.txnAmount}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Date & Time              :  ${payment.txnDate}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.save(`${student.name} EPravesh Fee`);
  };


  return (
    <>
    <style>
{`

.receipt-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    margin-top:100px;
  }
  
  .receipt-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .download-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  
`}
    </style>
<AdminDashboard/>
    <div className="receipt-container">
      <h2 className="receipt-title">Payment Successful</h2>
      <button onClick={generatePDF} className="download-button">
        Download Receipt
      </button>
    </div>
    </>
  );
};

export default Manuual_Fee_Receipt;