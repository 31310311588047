import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
// import Trans from "../../images/Amemities/Transprotation.jpg";
import { Link } from "react-router-dom";


function  AdmissionNotice() {
  return (
    <>
      <Nav />
      <br/>
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
        
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
 
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
        
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
                
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Admission Notification</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
         <br/>
         
            <div className="tann"> 
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/notification.pdf"><b> Notification</b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/TapScanner.pdf"><b>  प्रवेश अधिसूचना - 3 (2024-25)</b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/IMG_0001_31032024_1157.pdf"><b> admission notification 01 (2024-25)  </b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/IMG_0002_31032024_1151.pdf"><b> प्रवेश अधिसूचना - 2 (2024-25) </b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Admission_Notification_2023-24_01_04112023_0304.pdf"><b>  <h6><b>Admission Notification- 2 (2023-24) </b></h6>  <span style={{ fontSize: '12px',color:"#9b2226" }}>
        (B.E/B.PHARMA/D.PHARMA/M.TECH/M.PHARMA/MBA/MCA/BHMCT/B.Arch./B.Design/Diploma Engineering)
      </span> </b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/IMG_0001_29082023_1156.pdf"><b>  NOTIFICATION (Enrollment)  </b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/IMG.pdf"><b> Admission Notification -01 (2023-24) </b></Link></h5>
<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/IMG_0001.pdf"><b>Admission Notification (2023-24) </b></Link></h5>


<h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Admission_notification.pdf"><b>  <h6><b>  Admission Notification- 2 (2022-23) </b></h6>  <span style={{ fontSize: '12px', color:"#9b2226" }}>
(B.E/B.PHARMA/D.PHARMA/M.TECH/M.PHARMA/MBA/MCA/BHMCT/ B. Arch. / B. Design/Diploma Engineering)
      </span> </b></Link></h5>



      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Con_NRI_2022-23_20082022_0446.pdf"><b>  <h6><b>    Admission Notification for NRI Candidate (2022-23)  </b></h6>  <span style={{ fontSize: '12px', color:"#9b2226" }}>
      (BE, B.Tech, M.Tech and MBA)
      </span> </b></Link></h5>


      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/admission_notification_2022-23.pdf"><b>  <h6><b>  Admission Notification- I (2022-23)</b></h6>  <span style={{ fontSize: '12px' ,}}>
      (BA, B.Com, BBA, BCA, B.Sc., B.Lib, MA, M.Sc., M.Com., PGDCA, B.Ed., B.P.Ed., B.P.E.S, B.A.B.Ed., LLB)
      </span> </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Entrance_Exam_Admission notification.pdf"><b> Notification For Online Entrance Examination 2022-23  </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Enrollment_Notification21_22.pdf"><b>Enrollment form Open (2021-22)   </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = ""><b> Student Login 2021    </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/FOP.pdf"><b> Notice for Student Enrollment (Pharmacy ) (2021-22)   </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/AAA_ENROLLMENT_NUR.pdf"><b> Notice for Student Enrollment (2021-22) [For B.Sc.(Nursing )]   </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/AAA_ENROLLMENT_BEd.pdf"><b> Notice for Student Enrollment (2021-22) For B.Ed.     </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}></i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/AAdmission_Notice_technical_courses.pdf"><b> Admission Notice for Technical Courses (2021-22)   </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/NRI admission.pdf"><b> Admission Notice for NRI (2021-22)   </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/ad_notificaron3_05022022_1245.pdf"><b> Admission Counseling Schedule 2021-22 (Notification-3 )    </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/ad_notification2_05022022_1241.pdf"><b>   Admission Counseling Schedule 2021-22 (Notification 2)  </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/admission notification II 2021_22R.pdf"><b> Admission Counseling Schedule 2021-22   </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/admission notification III 2021_22.pdf"><b>  <h6><b>  Admission Counseling Schedule 2021-22</b></h6>  <span style={{ fontSize: '12px' ,}}>
      (Diploma Pharmacy/ Diploma Engineering )
      </span> </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Admission_utd_2021_22.pdf"><b>  <h6><b>   Admission Counseling Schedule 2021-22</b></h6>  <span style={{ fontSize: '12px' ,}}>
      (BA, BBA, BCA, B.Sc., B.Lib, B. Dance, B. Music, B.Com., MA, M.Sc., M.Com., PGDCA)
      </span> </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = ""><b> B. Design. Admission Open 2021-22 Session </b></Link></h5>

      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = ""><b> Download Paramedical Admission Form</b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Admission_Entrance_Exam_2021_22.pdf"><b> Notification For Online Entrance Examination 2021-22   </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Admission1.pdf"><b>  Admission Counseling Schedule 2020-21 </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Entrance_20_21.pdf"><b>Notification for Entrance Examination -2020 </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/paramedical_2019.pdf"><b>  Paramedical Admission Counseling Schedule 2019-20</b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Para_2018_19N.pdf"><b> Paramedical Admission Counseling Schedule 2018-19 </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Para_2017_18.pdf"><b> Paramedical Admission Counseling Schedule 2017-18</b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Para_2016_17.pdf"><b>  Paramedical Admission Counseling Schedule 2016-17 </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Para_2015_16.pdf"><b>Paramedical Admission Counseling Schedule 2015-16 </b></Link></h5>
      <h5 style={{color:"#023e8a" ,marginLeft:"10%"}}><i class= "fa fa-share" aria-hidden="true" style={{marginRight:"30px", color:"#9b2226"}}> </i> <Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Admission_Notice/Para_2014_15.pdf"><b>  Paramedical Admission Counseling Schedule 2014-15 </b></Link></h5>
</div>
              </p>
              <br/>
          
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to = "/Admission-Registration">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Registration
                 </Link>
                </li>
                </Link>
                <Link to = "/Admission-Notice">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Notice
                 </Link>
                </li>
                </Link>
                <Link to = "/University-Account-Detail">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>University Account Detail
                 </Link>
                </li>
                </Link>
                <Link to = "/Fees-Structure">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Fees Structure
                 </Link>
                </li>
                </Link>
                <Link to = "/Admission-Procedure">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Procedure
                 </Link>
                </li>
                </Link>
                <Link to = "/Brochures">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Brochures
                 </Link>
                </li>
                </Link>
             
              </ul>
            </div>
          </div>
         
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default AdmissionNotice;

