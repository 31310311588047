// import React, { useState, useEffect } from "react";
// import { FormControl } from "@mui/material";
// import { Tabs, Tab, Button, Row } from "react-bootstrap";
// import Admission_Slip from "./Admission_Slip";
// import axios from "axios";
// import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

// function Admission_Slip_Search() {
//   const [key, setKey] = useState("first");
//   const [session, setSession] = useState("");
//   const [courseType, setCourseType] = useState("");
//   const [course, setCourse] = useState("");
//   const [branch, setBranch] = useState("");
//   const [college, setCollege] = useState("");
//   const [showTable, setShowTable] = useState(false);
//   const [data, setData] = useState([]);
//   const [data2, setData2] = useState([]);
//   const [filteredColleges, setFilteredColleges] = useState([]);

//   const [enteredValue, setEnteredValue] = useState("");
//   const [searchResult, setSearchResult] = useState(null);

//   const handleSearch = () => {
//     if (!session || !courseType || !course || !branch || !college) {
//       alert("Please fill all fields first");
//       return;
//     }
//     if (session && courseType && course && branch && college) {
//       setEnteredValue("");
//     }

//     setShowTable(true);
//   };
//   useEffect(() => {
//     const shadowBox = document.querySelector(".shadow-box");
//     if (shadowBox) {
//       shadowBox.style.height = key === "first" ? "440px" : "250px";
//     }
//   }, [key]);
//   useEffect(() => {
//     axios
//       .get("https://sssutms.ac.in/apitest/v2/admin/courses3")
//       .then((response) => {
//         setData(response.data.courses);
//         setData2(response.data.colleges);
//       })

//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);

//   useEffect(() => {
//     const colleges = data2
//       .flatMap((college) => college.courses)
//       .filter((courseItem) => courseItem.courseName === course)
//       .map((college) => college.collegeName);

//     setFilteredColleges(colleges);
//   }, [course, data2]);

//   const getCourseTypes = () => {
//     const types = new Set(data.map((item) => item.courseType));
//     return Array.from(types);
//   };

//   const getCourses = () => {
//     if (!courseType) return [];
//     const courses = data
//       .filter((item) => item.courseType === courseType)
//       .flatMap((item) => item.courseNames.map((course) => course.courseName));
//     return courses;
//   };

//   const getBranches = () => {
//     if (!course) return [];
//     const branches = data
//       .flatMap((item) => item.courseNames)
//       .filter((courseItem) => courseItem.courseName === course)
//       .flatMap((courseItem) =>
//         courseItem.branches.map((branch) => branch.branchName)
//       );
//     return branches;
//   };
//   const getColleges = () => {
//     if (!course) return [];
//     const colleges = data
//       .flatMap((item) => item.courseNames)
//       .filter((courseItem) => courseItem.courseName === course) // Check if course matches
//       .flatMap((courseItem) => courseItem.colleges); // Extract the colleges
//     return colleges;
//   };
//   const labelStyle = {
//     color: "#264653",
//     fontSize: "15px",
//     fontWeight: 700,
//     fontFamily: "'Rubik', sans-serif",
//   };
//   ////////////////////////////search  by id//////////////////////////
//   const handleSearchById = () => {
//     if (!enteredValue) {
//       alert("Please enter a Registration No.");
//       return;
//     }

//     axios
//       .get(`https://sssutms.ac.in/apitest/admin/search2?search=${enteredValue}`)
//       .then((response) => {
//         console.log(response.data);
//         if (response.data && response.data.length > 0) {
//           setSearchResult(response.data);

//           setShowTable(true);
//         } else {
//           alert("No results found."); // Inform user if no results
//           setShowTable(false);
//         }
//         console.log(response.data, "search results");
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//       });
//   };

//   return (
//     <>
//       <AdminDashboard />

//       <style>
//         {`
//           .shadow-box {
//             box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//             border-radius: 10px;
//             padding: 10px;
//             margin-top: 20px;
//             width: 100%;
//             margin-left:75px;
//             height:440px;
//           }
//           .custom-tabs .nav-item {
//             height: 30px;
//           }
//           .custom-tabs .nav-link {
//             padding: 0px;
//             font-size: 14px;
//           }
//           .custom-tab {
//             padding: 5px 10px;
//             font-size: 14px;
//           }
//           #admission-tabs .nav-link {
//             padding: 5px 10px;
//             font-size: 14px;
//           }
//           .header {
//             height: 40px;
//             background-color: #001d3d;
//             border-radius: 10px;
//             color: white;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-size: 20px;
//             font-family: italic;
//           }
//           .login-input {
//             margin-bottom:8px;
//           }
//           .login-input:focus {
//             outline: none;
//             border-bottom: 2px solid #344e41;
//           }
//           @media (max-width: 768px) {
//             .shadow-box {
//               padding: 10px;
//               margin-top: 10px;
//             }
//             .header {
//               font-size: 16px;
//               height: 40px;
//             }
//             .login-input {
//               font-size: 0.9rem;
//             }
//           }
//           @media (max-width: 576px) {
//             .shadow-box {
//               margin: 10px;
//               margin-Left:76px;
//             }
//             .container-fluid {
//               width: 100%;
//               padding-right: 0;
//               padding-left: 0;
//             }
//           }
//           @media (min-width: 992px) {
//             .custom-laptop-style {
//               margin-left: 5%;
//             }
//           }
//         `}
//       </style>

//       <div style={{ display: "flex" }}>
//         <div
//           style={{
//             width: "20%",
//             height: "550px",
//             padding: "10px",
//             marginTop: "60px",
//           }}
//         >
//           <div className="shadow-box">
//             <h1
//               style={{
//                 fontSize: "16px",
//                 fontWeight: 700,
//                 color: "#A02334",
//                 marginBottom: "8px",
//                 fontFamily: "'Rubik', sans-serif",
//               }}
//             >
//               Admission Slip Search
//             </h1>
//             <Tabs
//               id="admission-tabs"
//               activeKey={key}
//               onSelect={(k) => setKey(k)}
//             >
//               <Tab eventKey="first" tabClassName="custom-tab" title="All">
//                 <form style={{ padding: "15px", marginTop: "-10px" }}>
//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}>Session</label>
//                       <select
//                         className="form-select form-select-sm login-input"
//                         required
//                         onChange={(e) => setSession(e.target.value)}
//                       >
//                         <option
//                           style={{
//                             fontFamily: "'Rubik', sans-serif",
//                             fontWeight: 700,
//                           }}
//                           value="option1"
//                         >
//                           Select
//                         </option>
//                         <option
//                           style={{
//                             fontFamily: "'Rubik', sans-serif",
//                             fontWeight: 700,
//                           }}
//                           value="2023"
//                         >
//                           2023
//                         </option>
//                         <option
//                           style={{
//                             fontFamily: "'Rubik', sans-serif",
//                             fontWeight: 700,
//                           }}
//                           value="2024"
//                         >
//                           2024
//                         </option>
//                       </select>
//                     </FormControl>
//                   </Row>
//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}>Course Type</label>
//                       <select
//                         className="form-select form-select-sm login-input"
//                         required
//                         onChange={(e) => setCourseType(e.target.value)}
//                       >
//                         <option
//                           style={{
//                             fontFamily: "'Rubik', sans-serif",
//                             fontWeight: 700,
//                           }}
//                           value="option1"
//                         >
//                           Select
//                         </option>
//                         {getCourseTypes().map((type, index) => (
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             key={index}
//                             value={type}
//                           >
//                             {type}
//                           </option>
//                         ))}
//                       </select>
//                     </FormControl>
//                   </Row>
//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}>Course</label>
//                       <select
//                         className="form-select form-select-sm login-input"
//                         required
//                         onChange={(e) => setCourse(e.target.value)}
//                       >
//                         <option value="option1">Select</option>
//                         {getCourses().map((courseName, index) => (
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             key={index}
//                             value={courseName}
//                           >
//                             {courseName}
//                           </option>
//                         ))}
//                       </select>
//                     </FormControl>
//                   </Row>
//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}>Branch</label>
//                       <select
//                         className="form-select form-select-sm login-input"
//                         required
//                         onChange={(e) => setBranch(e.target.value)}
//                       >
//                         <option
//                           style={{
//                             fontFamily: "'Rubik', sans-serif",
//                             fontWeight: 700,
//                           }}
//                           value="option1"
//                         >
//                           Select
//                         </option>
//                         {getBranches().map((branchName, index) => (
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             key={index}
//                             value={branchName}
//                           >
//                             {branchName}
//                           </option>
//                         ))}
//                       </select>
//                     </FormControl>
//                   </Row>

//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}> College</label>
//                       <select
//                         className="form-select form-select-sm login-input"
//                         required
//                         onChange={(e) => setCollege(e.target.value)}
//                       >
//                         <option value=""> Select</option>
//                         {getColleges().map((collegeName, index) => (
//                           <option
//                             style={{
//                               fontFamily: "'Rubik', sans-serif",
//                               fontWeight: 700,
//                             }}
//                             key={index}
//                             value={collegeName}
//                           >
//                             {collegeName}
//                           </option>
//                         ))}
//                       </select>
//                     </FormControl>
//                   </Row>

//                   <Row>
//                     <Button
//                       style={{
//                         marginLeft: "70px",
//                         backgroundColor: "#004e92",
//                         width: "90px",
//                         height: "35px",
//                         padding: "5px",
//                       }}
//                       onClick={handleSearch}
//                     >
//                       Search
//                     </Button>
//                   </Row>
//                 </form>
//               </Tab>
//               <Tab
//                 eventKey="second"
//                 style={{ fontFamily: "'Rubik', sans-serif" }}
//                 title="Registration No."
//               >
//                 <form style={{ padding: "15px" }}>
//                   <Row>
//                     <FormControl>
//                       <label style={labelStyle}>Registration No. :</label>
//                       <input
//                         style={{ marginTop: "1px" }}
//                         type="number"
//                         className="form-control"
//                         value={enteredValue}
//                         onChange={(e) => setEnteredValue(e.target.value)}
//                       />
//                     </FormControl>
//                   </Row>
//                   <Row>
//                     <Button
//                       style={{
//                         marginLeft: "70px",
//                         marginTop: "20px",
//                         backgroundColor: "#004e92",
//                         width: "90px",
//                         height: "35px",
//                         padding: "5px",
//                       }}
//                       onClick={handleSearchById}
//                     >
//                       Search
//                     </Button>
//                   </Row>
//                 </form>
//               </Tab>
//             </Tabs>
//           </div>
//         </div>
//         <div style={{ width: "80%", marginTop: "3px", marginLeft: "-20px" }}>
//           {showTable && (
//             <div>
//               <Admission_Slip
//                 session={session}
//                 courseType={courseType}
//                 course={course}
//                 branch={branch}
//                 college={college}
//                 searchResult={searchResult}
//               />
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Admission_Slip_Search;

import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Tabs, Tab, Button, Row } from "react-bootstrap";
import Admission_Slip from "./Admission_Slip";
import axios from "axios";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function Admission_Slip_Search() {
  const [key, setKey] = useState("first");
  const [session, setSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [filteredColleges, setFilteredColleges] = useState([]);

  const [enteredValue, setEnteredValue] = useState("");
  const [searchResult, setSearchResult] = useState(null);

  const handleSearch = () => {
    if (!session || !courseType || !course || !branch || !college) {
      alert("Please fill all fields first");
      return;
    }
    if (session && courseType && course && branch && college) {
      setEnteredValue("");
    }

    setShowTable(true);
  };
  useEffect(() => {
    const shadowBox = document.querySelector(".shadow-box");
    if (shadowBox) {
      shadowBox.style.height = key === "first" ? "440px" : "250px";
    }
  }, [key]);
  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses3")
      .then((response) => {
        setData(response.data.courses);
        setData2(response.data.colleges);
      })

      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const colleges = data2
      .flatMap((college) => college.courses)
      .filter((courseItem) => courseItem.courseName === course)
      .map((college) => college.collegeName);

    setFilteredColleges(colleges);
  }, [course, data2]);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };
  const getColleges = () => {
    if (!course) return [];
    const colleges = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course) // Check if course matches
      .flatMap((courseItem) => courseItem.colleges); // Extract the colleges
    return colleges;
  };
  const labelStyle = {
    color: "#264653",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "'Rubik', sans-serif",
  };
  ////////////////////////////search  by id//////////////////////////
  const handleSearchById = () => {
    if (!enteredValue) {
      alert("Please enter a Registration No.");
      return;
    }

    axios
      .get(`https://sssutms.ac.in/apitest/admin/search2?search=${enteredValue}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setSearchResult(response.data);

          setShowTable(true);
        } else {
          alert("No results found."); // Inform user if no results
          setShowTable(false);
        }

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  return (
    <>
      <AdminDashboard />

      <style>
        {`
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 20px;
            width: 100%;
            margin-left:75px;
            height:440px;
          }
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "550px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h1
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Admission Slip Search
            </h1>
            <Tabs
              id="admission-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="first" tabClassName="custom-tab" title="All">
                <form style={{ padding: "15px", marginTop: "-10px" }}>
                  <Row>
                    <FormControl>
                      <label style={labelStyle}>Session</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                        onChange={(e) => setSession(e.target.value)}
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="option1"
                        >
                          Select
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2023"
                        >
                          2023
                        </option>
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="2024"
                        >
                          2024
                        </option>
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label style={labelStyle}>Course Type</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                        onChange={(e) => setCourseType(e.target.value)}
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="option1"
                        >
                          Select
                        </option>
                        {getCourseTypes().map((type, index) => (
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            key={index}
                            value={type}
                          >
                            {type}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label style={labelStyle}>Course</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                        onChange={(e) => setCourse(e.target.value)}
                      >
                        <option value="option1">Select</option>
                        {getCourses().map((courseName, index) => (
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            key={index}
                            value={courseName}
                          >
                            {courseName}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Row>
                  <Row>
                    <FormControl>
                      <label style={labelStyle}>Branch</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                        onChange={(e) => setBranch(e.target.value)}
                      >
                        <option
                          style={{
                            fontFamily: "'Rubik', sans-serif",
                            fontWeight: 700,
                          }}
                          value="option1"
                        >
                          Select
                        </option>
                        {getBranches().map((branchName, index) => (
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            key={index}
                            value={branchName}
                          >
                            {branchName}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl>
                      <label style={labelStyle}> College</label>
                      <select
                        className="form-select form-select-sm login-input"
                        required
                        onChange={(e) => setCollege(e.target.value)}
                      >
                        <option value=""> Select</option>
                        {getColleges().map((collegeName, index) => (
                          <option
                            style={{
                              fontFamily: "'Rubik', sans-serif",
                              fontWeight: 700,
                            }}
                            key={index}
                            value={collegeName}
                          >
                            {collegeName}
                          </option>
                        ))}
                      </select>
                    </FormControl>
                  </Row>

                  <Row>
                    <Button
                      style={{
                        marginLeft: "70px",
                        backgroundColor: "#004e92",
                        width: "90px",
                        height: "35px",
                        padding: "5px",
                      }}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Row>
                </form>
              </Tab>
              <Tab
                eventKey="second"
                style={{ fontFamily: "'Rubik', sans-serif" }}
                title="Registration No."
              >
                <form style={{ padding: "15px" }}>
                  <Row>
                    <FormControl>
                      <label style={labelStyle}>Registration No. :</label>
                      <input
                        style={{ marginTop: "1px" }}
                        type="number"
                        className="form-control"
                        value={enteredValue}
                        onChange={(e) => setEnteredValue(e.target.value)}
                      />
                    </FormControl>
                  </Row>
                  <Row>
                    <Button
                      style={{
                        marginLeft: "70px",
                        marginTop: "20px",
                        backgroundColor: "#004e92",
                        width: "90px",
                        height: "35px",
                        padding: "5px",
                      }}
                      onClick={handleSearchById}
                    >
                      Search
                    </Button>
                  </Row>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
        <div style={{ width: "80%", marginTop: "-10px", marginLeft: "20px" }}>
          {showTable && (
            <div>
              <Admission_Slip
                session={session}
                courseType={courseType}
                course={course}
                branch={branch}
                college={college}
                searchResult={searchResult}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Admission_Slip_Search;
