import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { TableCell, Box } from "@mui/material";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import closebutton from "../../images/close-button.png";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const Search = ({
  session,
  courseType,
  course,
  branch,
  college,
  searchResult,
}) => {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [studentData, setStudentData] = useState(searchResult || []);
  const [buttonLoading, setButtonLoading] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [dataNotFound, setDataNotFound] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchResult) {
        try {
          setLoading(true);
          const url = "https://sssutms.ac.in/apitest/v2/newenrollmentrequest";
          const requestData = {
            session,
            courseType,
            course,
            branch,
            college,
          };

          const response = await axios.post(url, requestData);
          const data = response.data;

          if (data && data.length > 0) {
            setStudentData(data);
            setFilteredData(data);
            setDataNotFound(false);
          } else {
            setDataNotFound(true);
            setFilteredData([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setDataNotFound(true);
        } finally {
          setLoading(false);
        }
      } else {
        setFilteredData(searchResult);
      }
    };
    fetchData();
  }, [session, courseType, course, branch, college, searchResult]);

  const filterData = () => {
    const filtered = studentData.filter((student) => {
      const randomId = student.randomId || "";
      const name = student.name || "";
      const courseBranch = student.courseBranch || "";
      return (
        randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        courseBranch.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    filterData();
  }, [searchQuery, studentData]);

  const handleSort = (column) => {
    const order = column === sortBy && sortOrder === "asc" ? "desc" : "asc";
    setSortBy(column);
    setSortOrder(order);
  };

  const getSortedAndSlicedData = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      return 0;
    });

    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleClick = async (studentId) => {
    setButtonLoading((prev) => ({ ...prev, [studentId]: true })); // Set loading to true for the specific student

    try {
      const response = await axios.post(
        "https://sssutms.ac.in/apitest/generate-enrollment2/",
        {
          branchname: studentData[0]?.courseBranch,
          studentId,
          coursename: studentData[0]?.courseName,
          courseType: studentData[0]?.courseType,
          collegename: studentData[0]?.assignedCollege,
        }
      );
      swal({
        title: "Success",
        text: `Enrollment Generated Successfully!`,
        icon: "success",
        buttons: "OK",
      });

      // Remove student from the table after successful generation
      setFilteredData((prevFilteredData) =>
        prevFilteredData.filter((student) => student._id !== studentId)
      );
    } catch (error) {
      console.error("Error generating enrollment:", error);
    } finally {
      setButtonLoading((prev) => ({ ...prev, [studentId]: false })); // Set loading to false after the request
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />
      <>
        <Box
          sx={{
            width: "87%",
            marginLeft: "100px",
            marginTop: "90px",
            overflow: "hidden",
          }}
        >
          <Box style={{ float: "left", marginBottom: "5px" }}>
            <SearchIcon sx={{ mr: 1 }} />
            <input
              type="text"
              placeholder="Search by ID, Name"
              onChange={handleSearchChange}
              value={searchQuery}
            />
          </Box>
          {dataNotFound ? (
            <div style={{ marginTop: "50px", marginLeft: "20px" }}>
              <h2>Oops! No Student Available in this Course!!!!</h2>
            </div>
          ) : (
            <Paper sx={{ width: "100%", marginBottom: "0px" }}>
              {loading ? (
                <CircularProgress
                  color="success"
                  style={{ marginTop: "80px", marginLeft: "50%" }}
                />
              ) : (
                <TableContainer sx={{ maxHeight: "420px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "60px",
                            position: "sticky",
                            zIndex: "2",
                            top: 0,
                            left: 0,
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "120px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          Enrollment Status
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "100px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          Registration No
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "180px",
                            position: "sticky",
                            zIndex: "2",
                            top: 0,
                            left: 60,
                          }}
                          // /////////////for sorting name//////////////////
                          onClick={() => handleSort("name")}
                        >
                          Candidate Name
                          {sortBy === "name" && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "180px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "230px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          Branch
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            fontWeight: "bold",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                          }}
                        >
                          College Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            zIndex: "1",
                            top: 0,
                            fontWeight: "bold",
                            color: "white",
                          }}
                        >
                          Generate Enrollement
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {filteredData.length > 0 ? (
                        getSortedAndSlicedData().map((student, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              style={{
                                zIndex: "1",
                                backgroundColor: "white",
                                top: 0,
                                position: "sticky",
                                left: 0,
                              }}
                            >
                              {index + 1}
                            </TableCell>

                            <TableCell align="left">
                              <span
                                style={{ color: "red", fontWeight: "bold" }}
                              >
                                {" "}
                                {student.IsEnrollGenerated ? (
                                  <img
                                    src={closebutton}
                                    alt=""
                                    height="40px"
                                    width="40px"
                                  />
                                ) : (
                                  "Not Generatd"
                                )}
                              </span>
                            </TableCell>
                            <TableCell align="left">
                              {student.randomId}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                color: "#6a040f",
                                fontWeight: "bold",
                                zIndex: "1",
                                backgroundColor: "white",
                                top: 0,
                                position: "sticky",
                                left: 60,
                              }}
                            >
                              {student.name}
                            </TableCell>
                            <TableCell align="left">
                              {student.fathersname}
                            </TableCell>
                            <TableCell align="left">
                              {student.courseBranch}
                            </TableCell>
                            <TableCell align="left">
                              {student.courseName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              {student.assignedCollege}
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleClick(student._id)}
                                disabled={buttonLoading[student._id]} // Disable the button while loading
                              >
                                {buttonLoading[student._id] ? (
                                  <CircularProgress size={24} color="inherit" />
                                ) : (
                                  "Generate"
                                )}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            style={{ marginLeft: "300px" }}
                          >
                            No data found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <TablePagination
                rowsPerPageOptions={[100, 200, 500]}
                component="div"
                count={filteredData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          <br />
        </Box>
      </>
    </ThemeProvider>
  );
};

export default Search;
