import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function BScHMCSScheme() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:40px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}>B.Sc. HMCS</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">

            <br />

            {/* <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Scheme: B. Pharmacy </h5> */}

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>


           <br></br>
            {/* <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Scheme: B.Ed. </h5> */}
            <table style={{ width: '90%', marginLeft: '70px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody>
          <tr>
            <th>S.No</th>
            <th style={{textAlign:'center'}}>COURSES</th>
            <th>SEMESTER</th>
          </tr>

          <tr>
          <td>1</td>
          <th rowSpan='6' style={{textAlign:'center'}}>B.Sc. in Hotel Management and Catering Services</th>
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_I_Sem_N.pdf'>First Semeste </Link></td>
          </tr>
          <tr>
          <td>  2  </td>
         
          <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_II_Sem_N.pdf'>Second Semeste </Link></td>
          </tr>


          <tr>
          <td>  3  </td>
         
          <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_III_Sem_N.pdf'>Third Semester </Link></td>
          </tr>

          <tr>
          <td>  4  </td>
         
          <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_IV_Sem_N.pdf'>Fourth Semester </Link></td>
          </tr>

          <tr>
          <td>  5  </td>
         
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_V_Sem_N.pdf'> Fifth Semester </Link></td>
          </tr>

          <tr>
          <td>  6  </td>
         
          <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/bschmcs/Scheme_HMCS_VI_Sem_N.pdf'>Sixth  Semester </Link></td>
          </tr>

                </tbody></table>
           <br></br>
           <br></br>


          
          </div>



          <div className="col-lg-3">

            <ul className='list-icon' >
              <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>Scheme</h4>
              <br></br> 
               <li className='lli'>
                <Link to ='/B.E-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BE </Link></li>
              
                  <li className='lli'>
                <Link to ='/Pharmacy-scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Pharmacy </Link></li>
                  <li className='lli'>
                <Link to ='/M.Tech-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MTECH </Link></li>
                  <li className='lli'>
                <Link to ='/BHMCT-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMCT </Link></li>
                  <li className='lli'>
                <Link to ='/MBA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MBA </Link></li>
                  <li className='lli'>
                <Link to ='/MCA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MCA </Link></li>
                  <li className='lli'>
                <Link to ='/Education-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Education </Link></li>
                  <li className='lli'>
                <Link to ='/PhysicalEducation-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Physical Education </Link></li>
                  <li className='lli'>
                <Link to ='/BscHonsAg-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Sc.(Hons).(Ag) </Link></li>
                  <li className='lli'>
                <Link to ='/BHMS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMS </Link></li>
                  <li className='lli'>
                <Link to ='/Paramedical-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Paramedical </Link></li>
                  <li className='lli'>
                <Link to ='/POLYTECHNIC(ENGINEERING)-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Polytechnic Engineering </Link></li>
                  <li className='lli'>
                <Link to ='/BLIBISC-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Lib.I.Sc </Link></li>
                  <li className='lli'>
                <Link to ='/BachelorofLaws-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Bachelor of Laws  </Link></li>
                  <li className='lli'>
                <Link to ='/BSCHMCS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>B.Sc.[HMCS]  </Link></li>
                
            


            </ul>
          </div>
        </div>

      </div>
      <br></br>

      <Footerpage /></>
  )
}

export default BScHMCSScheme