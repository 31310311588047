import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function BestPractices() {
  return (
    <>
      <Nav />
      <style>
        {
          `
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
   
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}
  table {
            width: 100%;
            margin-top: 20px;
              font-family: 'poppins',sansSerif;
            border-collapse: collapse;
          }

          table th, table td {
            padding: 10px;
            text-align: left;
          }

          table th {
            background-color: #f4f4f4;
            color: #333;
            font-weight: bold;
          }

          table td {
            color: black;
          }

.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;

 list-style-type: none; /* Removes bullets */}


 .lli1{
 font-size:16px; 
// margin-left:20px;
margin-top:10px;
font-weight:700;
,  fontFamily: "'poppins',sansSerif"
 list-style-type: none; /* Removes bullets */}

 }
.list-icon{
margin-top:30px;


  list-style-type: none; /* Removes bullets */
        display: flex;
        flex-direction: column; /* Default to vertical */
}
.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>
      <section className="section_all " id="about" >
        <div className="container">
          <div class="two">
            <h1>BEST PRACTICES

            </h1>
          </div>
          <br></br>

          <div className='row' >

            <div className="col-lg-9">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Best Practices</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td><Link to='https://cdn.sssutms.ac.in/media/static/PDF/ERP_report.pdf'>Enterprise Resources Planning (ERP)</Link></td>
                  </tr>
                  <tr>




                    <td>2</td>
                    <td>Activity Based Continuous Assessment (ABCA) System</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-3">

              <ul className='list-icon' >
                <li className='lli'>
                  <Link to='/History_Milestones'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>About</Link></li>
                <li className='lli'>
                  <Link to='/institutes'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes</Link></li>
                <li className='lli'>
                  <Link to='/promotingsociety'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting Society</Link></li>
                <li className='lli'>
                  <Link to='/vision-mission'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision & Mission</Link></li>
                <li className='lli'>
                  <Link to='/core-approvalsvalues'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values</Link></li>
                <li className='lli'>   <Link to='/best-practices'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Best Practices</Link></li>
              </ul>
            </div></div>

        </div>
      </section>
      <Footerpage /></>
  )
}

export default BestPractices
