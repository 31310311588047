import React from "react";
import { Link, useLocation } from "react-router-dom";
import checked from "../../images/checked.png";
import logo from "../../AdminComponent/12.png";
import jsPDF from "jspdf";
import OnlyHeader from "../../AdminComponent/OnlyHeader";

function E_Success() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderId = queryParams.get("orderId");
  const transactionTime = new Date(queryParams.get("TxDate"));
  const bankTransactionId = queryParams.get("BankTxnId");
  const Amount = queryParams.get("Transamount");
  const paymentMode = queryParams.get("paymentMode");
  const TxnID = queryParams.get("txnId");
  const Name = queryParams.get("Name");
  const FName = queryParams.get("FName");
  const email = queryParams.get("email");
  const mobile = queryParams.get("mobile");
  const courseType = queryParams.get("courseType");
  const courseBranch = queryParams.get("courseBranch");
  const courseName = queryParams.get("courseName");

  // console.log(Amount, "amount")
  const formattedDate = transactionTime.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = transactionTime.toLocaleTimeString("en-IN", {
    hour12: true,
    hour: "2-digit",
    minute: "2-digit",
    // second: '2-digit'
  });

  

  const generatePDF = () => {
    const doc = new jsPDF();
    doc.addImage(logo, "JPEG", 3, 5, 30, 28);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0); // Set text color to red for the title
    doc.text("EPravesh Fee Receipt", 118, 40, { align: "center" });

    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0); // Set text color back to black

    // Set the initial y position
    let yPos = 60;

    // Define the line height
    const lineHeight = 10;

    doc.text(
      `                                     Name of Candidate  :  ${Name}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Father's Name          :  ${FName}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Email                         :  ${email}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Mobile                       :  ${mobile}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Type            :  ${courseType}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Name           :  ${courseName}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Course Branch        :  ${courseBranch}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     TXN ID                      :   ${TxnID}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     ORDER ID                :  ${orderId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     PAYMENT MODE    :  ${paymentMode}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     BANKTXN ID           :  ${bankTransactionId}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Paid Amount           :  ${Amount}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.text(
      `                                     Date & Time            :  ${formattedDate} ${formattedTime}`,
      10,
      yPos
    );
    yPos += lineHeight;

    doc.save(`${Name} EPravesh Fee`);
  };

  return (
    <>
      <style>
        {`
                p{
                    margin-bottom:8px;
                }
      .card {
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 20px;
        width: 40%; /* Adjust as needed */
        max-width: 800px; /* Set a maximum width if needed */
        height:20%;
        margin-top:80px;
        // margin: 20px auto; /* Center the card horizontally */
        text-align: left; /* Center text within the card */
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      img {
        display: block; /* Ensure the image is a block element */
        margin: 0 auto; /* Center the image within its container */
      }

      h4 {
        margin-top: 20px; /* Add margin to the top of the heading */
      }
      h6 {
        margin-top: 20px; /* Add margin to the top of the heading */
      }
     .top{
        text-align: center; 
        margin-bottom:30px;
     }
      .bottom {
        display: flex;
        justify-content: center;
        gap:60px;
      }
      .animated-button {
        display: inline-block;
        padding: 5px 40px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        border: 2px solid transparent;
        border-radius: 5px;
        background: green;
        // background: linear-gradient(to right, #FF5F6D, #FFC371);
        color: #fff;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .animated-button:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
      }
// .h66{
// color:black;
// margin-top:-20px;
// margin-left:70px;}




.buttoon{
 display: flex;
 margin-top:10px ;
 gap: 180px;
 }

//       .h44{
//       font-family:sans-serif 'roboto';
//       margin-right:50px;
//       flex-grow: 1; /* Allows the text to take up the remaining space */
//   margin: 0; /* Removes default margin */}

//  .check{
//         display:flex;
//         align-items: left;
//         margin-left:6px;
//           flex-shrink: 0; 
//   margin-right: 10px; 
//         }

//        .imagee{height:70px;
//         width:70px;}


.h66 {
  color: black;
  margin-top: -30px;
  margin-left: 70px;
  flex-shrink: 1; /* Allows the text to shrink if needed */
  margin-right: 10px; /* Add space between the h6 and the edge of the card */
}

.h44 {
  font-family: sans-serif 'Roboto';
  margin-right: 10px;
  flex-grow: 1;
  padding:2px;
  margin-top: -20px;
  flex-shrink: 1; /* Allows the text to shrink if needed */
}

.check {
  display: flex;
  align-items: center; /* Vertically center the items */
  margin-left: -10px;
  margin-top:-20px
}

.imagee {
  height: 70px;
  width: 70px;
  margin-right: 10px;
  margin-top:20px
}
      @media screen and (max-width: 480px) {
        .card {
          width: 100%; /* Adjust width for smaller screens */
        margin-top:110px;}


        .h66{
color:black;
margin-top:-10px;
margin-left:30px;
font-size:13px;}




.buttoon{
 display: flex;
 margin-top:10px ;
 gap: 100px;
 }

      .h44{
      font-family:sans-serif 'roboto';
      margin-right:10px;
      font-weight:700;
      margin-top:-1px;}

 .check{
        display:flex;
        margin-left:10px;
         align-items: left;
          flex-shrink: 0; 
        }

       .imagee{

       height:50px;
        width:50px;
        margin-left:-20px}



        p{
        font-size:10px}

       
      }


       @media screen and (max-width: 600px) {
    `}
      </style>

      <OnlyHeader />
      <div
        style={{
          background: "#F5F5F5",
          display: "flex",
          height: "auto",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="card">
          <div className="top">
            <div className="check">
              <img src={checked} className="imagee" alt="check" />
              <h4 className="h44">Thank You {Name}!</h4>
            </div>
            <h6 className="h66">
              Your transaction has been completed successfully.
            </h6>
            <h6>
              Date: {formattedDate} {formattedTime}
            </h6>
            <h5 style={{ color: "red" }}>
              Paid Amount: <b> ₹ {Amount}</b>
            </h5>
          </div>
          <div className="bottom">
            <div>
              <p>Father Name </p>
              <p>Email </p>
              <p>Contact :</p>
              <p>TXN ID</p>
              <p> ORDER ID</p>
              <p>PAYMENT MODE</p>
              <p>BANKTXN ID</p>
              <p>COURSE NAME</p>
              <p>COURSE BRANCH</p>

            </div>
            <div>
              <p> {FName} </p>
              <p>{email} </p>
              <p> {mobile} </p>
              <p style={{ fontWeight: "700" }}>{TxnID} </p>
              <p style={{ fontWeight: "700" }}> {orderId} </p>
              <p>{paymentMode} </p>
              <p> {bankTransactionId} </p>
              <p> {courseName} </p>
              <p> {courseBranch} </p>
            </div>
          </div>
          <div className="buttoon">
            <button
              onClick={generatePDF}
              className="animated-button"
              style={{ backgroundColor: "#FF7F3E" }}
            >
              Print
            </button>
            <Link to="/erp/studentlogin">
              <button
                className="animated-button"
                style={{ backgroundColor: "#6295A2" }}
              >
                Close
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default E_Success;
