import React, { useState } from "react";
import Website from "./Website";
import b from "../images/sssutms.jpg";
import { Link } from "react-router-dom";

function MainPage() {
  const [isPopupVisible, setPopupVisible] = useState(true);

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  return (
    <>
      <style>
        {`
        .addclass {
        height: 300px;
        width: 300px;
       }
      .spann{
       color: #C80036;
       font-weight: 700;
       font-size: 15px;
       }


      .hh5{
      color:#433878;
      margin-left:-30px;
      font-weight:700;
      text-align:left;
      font-family: "Poppins", sans-serif;
      font-size:20px;
      margin-top:-15px;
     }

    .popup-container {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      margin-top:-45px;
    }

    .popup-content {
      background-color: white;
      padding: 20px;
      border-radius: 5px;
      position: relative;
      width: 80%;
      height: 440px;
      max-width: 450px;
      text-align: center;
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      cursor: pointer;
    }

    img {
      max-width: 100%;
      object-fit: cover;
    }

    .d-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .main-content {
      padding: 20px;
    }
   .custom-line {
    border: 2px solid red; /* Adjust the color and thickness */
    margin-top: 5px; /* Adjust spacing if needed */
    }

    .sticker {
      background-color: orange; /* Sticker background color */
      color: white;          /* Text color */
      font-size: 10px;       /* Sticker text size */
      padding: 2px 6px;      /* Padding for the sticker */
      border-radius: 5px;    /* Rounded corners */
      position: absolute;
      top: -30px;            /* Adjust the position relative to the text */
      right: -165px;          /* Adjust the sticker position */



      cursor: pointer;
    font-weight: bold;
    display: inline-block;
    position: relative;
    animation: blink 1s infinite;
    transition: transform 0.3s ease;
    }

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      padding: 3rem 0;
    }

    .wrapper {
      padding: 2rem;
      height: 300px;
      width: 600px;
      border-radius: 15px;
    }

    h1 {
      font-size: 1.1rem;
      font-family: sans-serif;
    }

    .noti {
      background-color: #D8DFEA;
      height: 40px;
      padding: 5px;
      width: 420px;
      margin-left: -40px;
      border-radius: 5px;
      margin-bottom: 10px;
    }

    .sessions {
      margin-top: 2rem;
      border-radius: 12px;
      position: relative;
    }

    .ooo {
      position: relative;
      list-style: none;
      padding: 0;
    }
      
    .hh6{
margin-left:10px}

    .ooo a {
      position: relative;
      display: block;
      padding: .4em 2em; /* Adjust padding for full width */
      color: #444;
      text-decoration: none;
      border-radius: .3em;
      left: -10px;
      transition: background 0.3s; /* Smooth transition */
    }

    // .ooo a:hover {
    //   background: #D8DFEA; /* Keep the same background on hover */
    // }

    .arrow {
      position: absolute;
      left: 10px; /* Adjust based on padding */
      color: #4B79C2; /* Arrow color */
      font-size: 16px; /* Size of the arrow */
      line-height: 1; /* Centering the arrow vertically */
      // transition: transform 0.3s ease; /* Transition effect for hover */
      margin-right: 10px; /* Add space between arrow and text */
    }

    .ooo a:hover .arrow {
      transform: translateX(5px); /* Move the arrow on hover */
    }

    .lihai {
      padding-bottom: 1.5rem;
      position: relative;
      padding-left: 30px; /* Increase padding for the arrow */
      margin-left: 10px;
    }

    @media screen and (max-width: 480px) {
      .noti {
        background-color: #D8DFEA;
        height: 40px;
        padding: 5px;
        width: 280px;
        margin-left: -40px;
        border-radius: 5px;
        margin-bottom: 10px;
      }
.popup-container
{
margin-left:10px;
}
      .image{
      height:40px;
      width:40px;
      margin-left:-2px
      }

.spann{
 color: #C80036;
  font-weight: 700;
  font-size: 12px;
  width:250px
  }
.hh6{
margin-left:10px}


      p {
        font-size: 0.9rem;
      }
    }
  `}
      </style>

      <div>
        {isPopupVisible && (
          <div className="popup-container">
            <div className="popup-content">
              <span className="close-btn" onClick={handleClosePopup}>
                &times;
              </span>
              <div className="nav-bar d-flex">
                <Link to ="tel:0098 765 432" className="contact-number d-flex">
                  <span>
                    <img src={b} className="image" alt="Logo" />
                  </span>
                  <span className="spann">
                    SRI SATYA SAI UNIVERSITY OF TECHNOLOGY & MEDICAL SCIENCES
                  </span>
                  <br />
                  <hr className="custom-line" />{" "}
                </Link>
              </div>

              <div className="wrapper">
                <h5 className="hh5">Important Notice</h5>
                <ol className="ooo">
                  <div className="noti">
                    <li>
                      <Link
                        to="https://cdn.sssutms.ac.in/media/static/PDF/cvbnm.pdf"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6">Notification For RDC</h6>{" "}
                        <span className="sticker">Open</span>
                      </Link>
                    </li>
                  </div>

                  <div className="noti">
                    <li className="lii">
                      <Link
                        to="https://sssutms.ac.in/Epravesh/Student/Register"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6">EPravesh Admission 2024</h6>
                        <span className="sticker" style={{ cursor: "pointer" }}>
                          Open
                        </span>
                      </Link>
                    </li>
                  </div>

                  <div className="noti">
                    <li className="lii">
                      <Link
                        to="https://sssutms.ac.in/admission/enquiry"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6"> Admission Enquiry 2024</h6>
                        <span className="sticker" style={{ cursor: "pointer" }}>
                          Open
                        </span>
                      </Link>
                    </li>
                  </div>
                  <div className="noti">
                    <li className="lii">
                      <Link
                        to="https://cdn.sssutms.ac.in/media/static/TIME_TABLE/BHMSTT.pdf"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6">
                          BHMS Examination Time-Table 2024
                        </h6>
                        <span className="sticker" style={{ cursor: "pointer" }}>
                          Open
                        </span>
                      </Link>
                    </li>
                  </div>
                  <div className="noti">
                    <li>
                      <Link
                        to="https://sssutms.ac.in/erp/studentlogin"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6"> Enrollment Form 2024</h6>{" "}
                        <span className="sticker">Open</span>
                      </Link>
                    </li>
                  </div>

                  <div className="noti">
                    <li>
                      <Link
                        to="https://sssutms.ac.in/alumini-form"
                        target="_blank"
                        style={{ fontWeight: 700 }}
                      >
                        <span className="arrow">➔</span>
                        <h6 className="hh6"> Alumni Form 2024</h6>{" "}
                        <span className="sticker">Open</span>
                      </Link>
                    </li>
                  </div>
                </ol>
              </div>
            </div>
          </div>
        )}
        <div>
          <Website />
        </div>
      </div>
    </>
  );
}

export default MainPage;
