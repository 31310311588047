import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function PromotingSociety() {
  return (
    <>
      <Nav />
      <style>
        {
          `
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>
      <section className="section_all " id="about" >
        <div className="container">
          <div class="two">
            <h1>Promoting Society

            </h1>
          </div>
          <br></br>

          <div className='row' >
            <div className="col-lg-9">


              <p className="mt-3" style={{ textAlign: 'justify', color: 'black', fontFamily: "'poppins',sansSerif" }}>
                Ayushmati  Education and Social Society, Bhopal was established in 1999 with objective to imparts Medical, Technical & Higher Education in state of Madhya Pradesh. Society was established by visionaries of repute from versatile background including those from Medical, Finance, Professional & social service. The decision of starting Technical Campus in interior was a debatable topic in those days. After gaining experience more than a decade of operating more than hundred Technical and Medical Institutes in the state of Madhya Pradesh, the Society established Private University at Sri Satya Campus at Sehore in year 2013.
                <br></br><br></br>With blessings of Sri Satya Sai, Government of Madhya Pradesh on recommendations of Madhya Pradesh Niji Vishwavidyalaya Niyamak Aayog accorded permission to Sri Satya Sai University of Technology & Medical Sciences at Sehore from Academic Session 2013-14.
                <br></br>

              </p>

            </div>
            <div className="col-lg-3">

              <ul className='list-icon' >
                <li className='lli'>
                  <Link to='/History_Milestones'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>About</Link></li>
                <li className='lli'>
                  <Link to='/institutes'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes</Link></li>
                <li className='lli'>
                  <Link to='/promotingsociety'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting Society</Link></li>
                <li className='lli'>
                  <Link to='/vision-mission'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision & Mission</Link></li>
                <li className='lli'>
                  <Link to='/core-approvalsvalues'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values</Link></li>
                <li className='lli'>   <Link to='/best-practices'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Best Practices</Link></li>
              </ul>
            </div></div>
          <h4 style={{ marginTop: '30px', textAlign: 'center', color: '#CB6040' }}>SPONSORING DETAIL</h4>
          <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/sponsoring_detail.pdf'>Click here to see details</Link>
        </div>
      </section>


      <Footerpage /></>
  )
}

export default PromotingSociety