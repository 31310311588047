import React, { useState, useEffect } from "react";
import { Container, Form, Button, Spinner, Row, Col } from "react-bootstrap";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import EligibilityForm from "./ElegibilyForm";

export default function Course () {
  const [courseData, setCourseData] = useState({});
  const [selectedType, setSelectedType] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [eligible, setEligible] = useState("");
  const [eligibilityGradPer, setEligibilityGradPer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const session = new Date().getFullYear();

  useEffect(() => {
    const fetchCourses = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v2/admin/courses?session=${session}`
        );
        const data = await response.json();
        const formattedData = {};

        data.forEach((course) => {
          if (!formattedData[course.courseType]) {
            formattedData[course.courseType] = {
              courses: [],
              branches: {},
            };
          }

          course.courseNames.forEach((courseName) => {
            formattedData[course.courseType].courses.push(courseName.courseName);
            formattedData[course.courseType].branches[courseName.courseName] =
              courseName.branches.map((branch) => branch.branchName);
          });
        });

        setCourseData(formattedData);
      } catch (error) {
        console.error("Error fetching course data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, [session]);

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setSelectedCourse("");
    setSelectedBranch("");
  };

  const handleCourseChange = (e) => {
    setSelectedCourse(e.target.value);
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleSearch = async () => {
    setSearchLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_STD_ELIGIBILITY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          courseType: selectedType,
          courseName: selectedCourse,
          courseBranch: selectedBranch,
        }),
      });

      const data = await response.json();
      setEligible(data);
      setEligibilityGradPer(data.eligibilityGradPer);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSearchLoading(false);
    }
  };

  const isValid = selectedBranch !== "" && selectedCourse !== "";

  return (
    <>
      <OnlyHeader />

      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "100px", marginTop: "40px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Container
          className="container-overflow shadow p-3 mb-3 bg-body rounded"
          style={{ maxWidth: "90%", margin: "70px auto" }}
        >
          {/* <Box
          marginBottom={"20px"}
          backgroundColor={"#264653"}
          p={4}
          h={50}
          color="white"
          mt={8}
          borderRadius="md"
          shadow="md"
        >
         Check Eligibility
        </Box> */}
          <div
            style={{
              backgroundColor: "#264653",
              color: "white",
              padding: "10px",
              borderRadius: "5px",
              textAlign: "left",
              fontFamily: "'Poppins', sans-serif",
              fontSize: "18px",

            }}
            className="mb-4"
          >
            Select Course
          </div>

          <Form>
            <Form.Group as={Row} className="mb-3 d-flex align-items-center">
              {/* Course Type */}
              <Col md={4}>
                <Form.Label>Course Type</Form.Label>
                <Form.Select
                  value={selectedType}
                  onChange={handleTypeChange}
                  style={{ height: "35px", fontSize: "14px" }}
                >
                  <option value="">Select Type</option>
                  {Object.keys(courseData).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Col>

              {/* Course Name */}
              <Col md={4}>
                <Form.Label>Course Name</Form.Label>
                <Form.Select
                  value={selectedCourse}
                  onChange={handleCourseChange}
                  style={{ height: "35px", fontSize: "14px" }}
                  disabled={!selectedType}
                >
                  <option value="">Select Course</option>
                  {selectedType &&
                    courseData[selectedType].courses.map((course) => (
                      <option key={course} value={course}>
                        {course}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              {/* Branch */}
              <Col md={4}>
                <Form.Label>Branch</Form.Label>
                <Form.Select
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  style={{ height: "35px", fontSize: "14px" }}
                  disabled={!selectedCourse}
                >
                  <option value="">Select Branch</option>
                  {selectedCourse &&
                    courseData[selectedType].branches[selectedCourse].map((branch) => (
                      <option key={branch} value={branch}>
                        {branch}
                      </option>
                    ))}
                </Form.Select>
              </Col>
            </Form.Group>

            {/* Search Button */}
            <div className="text-center">
              <Button
                // variant="primary"
                onClick={handleSearch}
                disabled={!isValid || searchLoading}
                style={{ fontSize: "15px", padding: "10px 20px", backgroundColor: "#264653", }}
              >
                {searchLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Search"
                )}
              </Button>
            </div>
          </Form>
        </Container>

      )}

      {eligible && (
        <EligibilityForm
          eligible={eligible}
          eligibilityGradPer={eligibilityGradPer}
          courseType={selectedType}
          courseName={selectedCourse}
          courseBranch={selectedBranch}
        />
      )}
    </>
  );
};

