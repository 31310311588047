import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function EResources() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:60px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          margin-left:10%;

          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          E-Resources{" "}
        </h2>
        <hr className="title-hr"></hr> <br />
        {/* <h2
  className="h3 chan text-bold"
  style={{
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    fontSize: "20px", // Font size बढ़ाने के लिए
    fontWeight: "bold", // Bold बनाए रखने के लिए
    color:"#370617"
  }}
>
  RESEARCH & DEVELOPMENT (R&D)
</h2> */}
        <div className="row dirr">
          {/* </div> */}

          <div
            className="col-lg-8 padding-section"
            style={{ marginLeft: "50px" }}
          >
            {/* <div className="left_text_testi_contetn"> */}
            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "1px" }}
              >
                <h5>
                  <b>
                    Places for Access copyright Free or Public Domain Books Link
                    :-{" "}
                  </b>
                </h5>
                <br />
                <h6 style={{ color: "#0077b6" }}>
                  <Link to="https://www.gutenberg.org" target="blanck">
                    <b> www.gutenberg.org </b>{" "}
                  </Link>
                </h6>

                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://manybooks.net/" target="blanck">
                    <b>www.gutenberg.org </b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://booksinmyphone.com/" target="blanck">
                    <b>www.manybooks.net </b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://www.planetebook.com/" target="blanck">
                    <b>www.booksinmyphone.com </b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link
                    to="https://www.amazon.com/b/ref=usbk_surl_books/?node=283155   "
                    target="blanck"
                  >
                    <b>www.planetebook.com</b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link
                    to="https://www.amazon.com/b/ref=usbk_surl_books/?node=283155"
                    target="blanck"
                  >
                    <b>www.bookdepository.com/free </b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link
                    to="https://www.feedbooks.com/publicdomin"
                    target="blanck"
                  >
                    <b>www.feedbooks.com/publicdomin</b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://www.newfreebooks.com/" target="blanck">
                    <b>http://newfreebooks.com</b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://www.scribd.com/home" target="blanck">
                    <b>www.scribd.com </b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link
                    to="https://www.openculture.com/free_ebooks"
                    target="blanck"
                  >
                    <b>www.openculture.com/free_ebooks</b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://www.authorama.com/" target="blanck">
                    <b>www.authorama.com</b>
                  </Link>
                </h6>
                <h6 style={{ marginTop: "10px", color: "#0077b6" }}>
                  <Link to="https://alison.com/" target="blanck">
                    <b>http://alison.com/</b>
                  </Link>
                </h6>
              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
          <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                   </Link>
              </li>
              <li className="lli">
               <Link to="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Patent">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/mou-collaboration">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                   </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default EResources;
