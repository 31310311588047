import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";


const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});
function Sidebar() {
  useEffect(() => {
    let arrow = document.querySelectorAll(".arrow");
    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement;
        arrowParent.classList.toggle("showMenu");
      });
    }
  }, []);
  return (
    <>
      {/* ===============Navbar================================= */}

      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{
              width: "100%",
              top: 0,
              zIndex: (theme) => theme.zIndex.drawer + 1,
              backgroundColor: "#071952",
            }}
          >
            <Toolbar>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Link to="">
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      marginRight: "20px",
                    }}
                    src=''
                    alt="dwsedws"
                  />
                </Link>
                <Typography
                  className="heading"
                  variant="h6"
                  component="div"
                  sx={{
                    flexGrow: 1,
                    textShadow: "2px 2px 10px cadetblue",
                    marginLeft: "25px",
                  }}
                >
                  Sri Satya Sai University Of Technology And Medical Sciences
                  (SSSUTMS)
                </Typography>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
      <>
        <style>
          {`
      @import url('https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@250&family=Tinos&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Tinos', serif;
}
.sidebar {
  margin-Top:60px;
  position: fixed;
  top: 0;
  left: 0;
  height: 150%;
  width: 200px;
  background: #000428;
        background-color:#071952;
  // background: -webkit-linear-gradient(to right, #004e92, #000428);
  // background: linear-gradient(to right, #004e92, #990033);
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close {
  width: 50px;
}
.sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i {
  font-size: 25px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
}
.sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links {
  height: 100%;
  padding: 0 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links {
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar {
  display: none;
}
.sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover {
  background: #1d1b31;
}
.sidebar .nav-links li .icon-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .icon-link {
  display: block;
}
.sidebar .nav-links li i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow {
  display: none;
}
.sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1D2B53;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu {
  display: block;
}
.sidebar .nav-links li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name {
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}


@media (max-width: 420px) {
  .sidebar.close .nav-links li .sub-menu {
    display: none;
  }
}

/* Add some basic styling to the container */
.scrollable-list-container {
  max-height: 500px; /* Set a maximum height for the container */
  overflow-y: auto; /* Enable vertical scrollbar */
  border: 1px solid #ccc; /* Add a border for better visibility */
  padding: 10px; /* Add some padding for better spacing */
}

/* Remove default list styles */
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style your list items as needed */
li {
  margin-bottom: 4px;
}

/* Style your links */
a {
  text-decoration: none;
  color: #333;
  font-size:15px
}

/* Add hover effect to links */
a:hover {
  text-decoration: underline;
}

      `}
        </style>

        <link
          href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
          rel="stylesheet"
        />
        <div className="sidebar close">
          <ul
            className="nav-links"
            style={{ marginTop: "25px", borderRadius: "20px" }}
          >
            <Link to="">
              <li>
              
                  <Link to ="#">
                    <img
                      width="25"
                      height="25"
                      style={{
                        filter: "invert(1)",
                           marginLeft: "10px",
                        marginTop: "20px",
                      }}
                      src="https://img.icons8.com/material-sharp/24/dashboard-layout.png"
                      alt="student-center"
                    />
                  </Link>
    

                <ul className="sub-menu blank">
                  <li>
                    <Link className="link_name">
                      Dashboard
                    </Link>
                  </li>
                </ul>
              </li>
            </Link>

            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                       marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/windows/32/data-center--v3.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                <li>
                  <Link className="link_name">
                    Masters
                  </Link>
                </li>

                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/coursechange">
                      <li>
                       Course Change
                      </li>
                    </Link>
                   
                  </ul>
                </div>
              </ul>
            </li>


            <li>
              <Link to ="#">
                <img
                  width="30"
                  height="30"
                  style={{
                    filter: "invert(1)",
                    marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios/50/search-contacts.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "70%" }}>
                <li>
                  <Link className="link_name" >
                    Search Student
                  </Link>
                </li>

                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/erpdashboard/pending/student/search">
                      <li>
                        <h6>Pending Student List</h6>
                      </li>
                    </Link>
                    <Link to="/erpdashboard/verified/student/search">
                      <li>
                        <h6>Verified Student List</h6>
                      </li>
                    </Link>
                  </ul>
                </div>
              </ul>
            </li>

            <li>
              <div className="icon-link">
                <Link to ="#">
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                         marginLeft: "10px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/ios/50/video-id-verification.png"
                    alt="student-center"
                  />
                </Link>
                <i className="bx bxs-chevron-down arrow" />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-170px" }}>
                <li>
                  <Link className="link_name" >
                    Verification
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <Link to="/student/status">
                      <li>
                        Student Status
                      </li>
                    </Link>
                    <li>
                      <Link to ="">Student Registration Verification</Link>
                    </li>
                    <li>
                      <Link to ="">Document Verification Panel</Link>
                    </li>
                    <li>
                      <Link to ="">Document Verification</Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <div className="icon-link">
                <Link to ="">
                  <img
                    width="25"
                    height="25"
                    style={{
                      filter: "invert(1)",
                         marginLeft: "10px",
                      marginTop: "20px",
                    }}
                    src="https://img.icons8.com/pulsar-line/48/education-fees-payment.png"
                    alt="student-center"
                  />
                </Link>
                <i className="bx bxs-chevron-down arrow" />
              </div>
              <ul className="sub-menu" style={{ marginTop: "-220px" }}>
                <li>
                  <Link className="link_name" to="">
                    Accounts
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    {/* <li>
                      <Link to ="#">Update Manual Tuition Fees</Link>
                    </li> */}
                    <Link to="/entrance/slip">
                      <li>
                        <Link to ="">Entrance Exam Details</Link>
                      </li>
                    </Link>
                    <li>
                      <Link to ="">Exam Fee Master </Link>
                    </li>

                    <Link to="/manually/search/std">
                      <li>
                        Manual Fee Update
                      </li>
                    </Link>

                    <li>
                      <Link to ="#"> Update Manual Enrollment Fee</Link>
                    </li>
                    <Link to="/paym">
                      <li>
                        <Link to ="#">Verify Online Payment</Link>
                      </li>
                    </Link>
                    {/* <li>
                      <Link to ="#">Examination From Activation</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam From Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Enrollment Fee Vrification Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Conditional Link Clear</Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise fee Summary</Link>
                    </li>
                    <li>
                      <Link to ="#">Tuition Fee Verification</Link>
                    </li> */}
                  </ul>
                </div>
              </ul>
            </li>
            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                       marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/pulsar-line/48/test.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "-15px" }}>
                <li>
                  <Link className="link_name" to="">
                    Examination
                  </Link>
                </li>
                {/* <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="#">Examination Form Creation Between Sem</Link>
                    </li>
                    <li>
                      <Link to ="#">Increase Answer Booklet Time</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Wise Center Allocation</Link>
                    </li>
                    <li>
                      <Link to ="#">Bulk Mailing</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Wise Print Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Session Master</Link>
                    </li>
                    <li>
                      <Link to ="#">Class Promotion</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Form Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Manual Exam Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question Paper Upload</Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise Student Strength(Exam)</Link>
                    </li>
                    <li>
                      <Link to ="#">Foil Counter Foil</Link>
                    </li>
                    <li>
                      <Link to ="#">Attendance Sheet (Raba)</Link>
                    </li>
                    <li>
                      <Link to ="#">Increase Answer Booklet Time</Link>
                    </li>
                    <li>
                      <Link to ="#">Course Wise Center Allocation</Link>
                    </li>
                    <li>
                      <Link to ="#">Bulk Mailing</Link>
                    </li>
                    <li>
                      <Link to ="#">Student Wise Print Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Session Master</Link>
                    </li>
                    <li>
                      <Link to ="#">Class Promotion</Link>
                    </li>
                    <li>
                      <Link to ="#">Exam Form Status</Link>
                    </li>
                    <li>
                      <Link to ="#">Manual Exam Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Form Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question For Verification</Link>
                    </li>
                    <li>
                      <Link to ="#">Question Paper </Link>
                    </li>
                    <li>
                      <Link to ="#">Data Wise Student Strength (Exam)</Link>
                    </li>
                    <li>
                      <Link to ="#">Foil Counter Foil</Link>
                    </li>
                    <li>
                      <Link to ="#">Attendance Sheet (Raba)</Link>
                    </li>
                    <li>
                      <Link to ="#">Activate Admit Card</Link>
                    </li>
                    <li>
                      <Link to ="#">Examination Fee Discount</Link>
                    </li>
                    <li>
                      <Link to ="#">Online Exam Paper Status</Link>
                    </li>
                    <li>
                      <Link to ="#">lkjhgfdsa</Link>
                    </li>
                  </ul>
                </div> */}
              </ul>
            </li>
            <li>
              <Link to ="#">
                <img
                  width="25"
                  height="25"
                  style={{
                    filter: "invert(1)",
                       marginLeft: "10px",
                    marginTop: "20px",
                  }}
                  src="https://img.icons8.com/ios-glyphs/30/student-female.png"
                  alt="student-center"
                />
              </Link>
              <ul className="sub-menu blank" style={{ marginTop: "-100px" }}>
                <li>
                  <Link className="link_name">
                    Student
                  </Link>
                </li>
                <div class="scrollable-list-container">
                  <ul>
                    <li>
                      <Link to ="/erpdashboard/student/Pass-search">
                        Password View
                      </Link>
                    </li>
                    {/* <li>
                      <Link to ="#">Otp Verification</Link>
                    </li> */}
                    <Link to="/admin/erpdashboard/Bonafied/certificate">
                      <li>
                        <h6>Bonafide Cretificate </h6>
                      </li>
                    </Link>
                    <Link to="/student/history">
                      <li>
                        <h6>Student History</h6>
                      </li>
                    </Link>
                    
                  </ul>
                </div>
              </ul>
            </li>
         

            <li></li>
          </ul>
        </div>
      </>
    </>
  );
}

export default Sidebar;



// import React from 'react';
// // import posImage from "../../../AdminComponent/12.png";
// import posImage from "../AdminComponent/12.png";


// const Sidebar = () => {
//   return (
//  <>
//  <style>
//   {
//   `/* Googlefont Poppins CDN Link */
// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');


// :root {
//     --main-color: #61b8a2;
//     --main-colorbg: #76c2af;
//     --black: #13131a;
//     --color-light: rgba(200, 200, 200, 0.18);
//     --bg: #f9f9f9;
// }

// // * {
// //     font-family: 'Sora', sans-serif;
// //     margin: 0;
// //     padding: 0;
// //     appearance: 0;
// //     box-sizing: border-box;
// //     outline: 0;
// //     border: 0;
// //     text-decoration: none;
// //     transition: .2s linear;
// // }


// // html {
// //     font-size: 62.5%;
// //     overflow-x: hidden;
// //     scroll-padding-top: 9rem;
// //     scroll-behavior: smooth;
// // }






// /*SECTIONS________*/

// #HOME {
//     display: block;
// }

// #CHIFFRAGE {
//     display: none;
// }

// #PLANNING {
//     display: none;
// }

// #INDICATEURS {
//     display: none;
// }

// #FACTURATION {
//     display: none;
// }

// #DATABASE {
//     display: none;
// }

// /* HEADER */

// .spann{
// font-size:17px;
// text-transform:uppercase;
// margin-left:-80px
// }
// header {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height:70px;
//     background: #fff;
//     box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
//     padding: 1.5rem 7%;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     z-index: 1000;
// }

// header .icons div {
//     color: white;
//     font-size: 2.5rem;
//     margin-left: 2rem;
// }

// header .icons div:hover {
//     color: var(--main-color);
// }

// header .search-box {
//     position: relative;
//     height: 35px;
//     max-width: 900px;
//     min-width: 100px;
//     width: 100%;
//     margin-left: 300px;
// }

// header .search-box input {
//     height: 100%;
//     width: 100%;
//     outline: none;
//     background: #F5F6FA;
//     border: 2px solid #4A4947;
//     border-radius: 6px;
//     font-size: 14px;
//     padding: 5px 20px;
// }


// .logo{
// margin-left:-30px;
// }
// header .search-box .bx-search {
//     position: absolute;
//     right: 1px;
//     height: 30px;
//     width: 30px;
//     // background: var(--main-colorbg);
//     top: 50%;
//     transform: translateY(-50%);
//     border-radius: 4px;
//     line-height: 33px;
//     text-align: center;
//     color: black;
//     font-size: 15px;
//     transition: all 0.4 ease;
// }

// header .navbar {
//     display: inline-block;
//     justify-content: space-between;
//     background: #fff;
//     display: flex;
//     align-items: center;
//     transition: all 0.5s ease;
//     width: 500px;
// }

// /* SIDEBAR */

// .sidebar {
//     padding-top: 20px;
//     position: fixed;
//     height: 100%;
//     width: 60px;
//     // background: transparent;
//         background-color:#16423C ;
//     transition: all 0.5s ease;
//     margin: 0;
//     appearance: 0;
//     box-sizing: border-box;
//     outline: 0;
//     border: 0;
//     text-decoration: none;
//     transition: .2s linear;
//     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
//     user-select: none;

// }

// .sidebar.active {
//     width: 30px;
// }

// .sidebar .nav-links {
//     margin-top: 50px;
// }

// .sidebar .nav-links li {
//     position: relative;
//     list-style: none;
//     height: 50px;
// }

// .sidebar .nav-links li div {
//     height: 100%;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     text-decoration: none;
//     color: var(--black);
//     transition: all 0.4s ease;
//     cursor: pointer;
// }

// .sidebar .nav-links li .active {
//     background: var(--bg);
// }

// .sidebar .nav-links li .active::before {
//     content: "";
//     width: 10px;
//     height: 100%;
//     background: var(--main-color);
// }

// .sidebar .nav-links li:hover {
//     background: var(--bg);
// }


// .sidebar .nav-links li i {
//     min-width: 60px;
//     text-align: center;
//     font-size: 18px;
//     color: var(--black);
// }

// .sidebar .nav-links li .links_name {
//     color: #fff;
//     font-size: 15px;
//     font-weight: 400;
//     white-space: nowrap;
//     color: var(--black);
// }



// /* SECTION HOME */
// .home-section {
//     position: relative;
//     background: #f5f5f5;
//     min-height: 100vh;
//     width: calc(100% - 180px);
//     left: 180px;
//     transition: all 0.5s ease;
//     display: block;
//     user-select: none;
// }

// .sidebar.active~.home-section {
//     width: calc(100% - 60px);
//     left: 60px;
// }

// .home-section .home-content {
//     position: relative;
//     padding-top: 70px;
// }

// .home-content .sales-boxes {
//     display: flex;
//     justify-content: space-between;

//     margin-bottom: 26px;
//     width: 100%;
// }

// /* left box */
// .home-content .sales-boxes .recent-sales {
//     width: 65%;
//     background: #fff;
//     padding: 20px 30px;
//     margin: 0 20px;
//     border-radius: 12px;
//     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
// }

// /* Right box */
// .home-content .sales-boxes .top-sales {
//     width: 35%;
//     background: #fff;
//     padding: 20px 30px;
//     margin: 0 20px 0 0;
//     border-radius: 12px;
//     box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
// }

// /* Responsive Media Query */




// @media (max-width: 1240px) {


//     .sidebar.active {
//         width: 220px;
//     }

//     .home-section {
//         width: calc(100% - 60px);
//         left: 60px;

//     }

//     .sidebar.active~.home-section {
//         /* width: calc(100% - 220px); */
//         overflow: hidden;
//         left: 220px;
//     }

// }

// @media (max-width: 1150px) {
//     .home-content .sales-boxes {
//         flex-direction: column;
//     }

//     .home-content .sales-boxes .box {
//         width: 100%;
//         overflow-x: scroll;
//         margin-bottom: 30px;
//     }

//     .home-content .sales-boxes .top-sales {
//         margin: 0;
//     }

//     .top-sales {
//         display: none;
//     }

//     .home-content .sales-boxes {
//         width: 100%;
//         padding-right: 40px;
//     }
// }

// @media (max-width: 550px) {
//     .recent-sales {
//         width: 100%;
//     }
// }
//   `}</style>
//   <>
//   <meta charSet="UTF-8" />
//   <link
//     rel="stylesheet"
//     href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css"
//   />
//   <link
//     href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css"
//     rel="stylesheet"
//   />
//   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
//   <header className='headd'>
//     <Link to ="#" className="logo">
//     <img
//                     style={{
//                       height: "50px",
//                       width: "50px",
//                       // marginLeft: "-5px",
//                     }}
//                     src={posImage}
//                     alt="dwsedws"
//                   />
//     </Link>
//     <span  className='spann'>  Sri Satya Sai University Of Technology And Medical Sciences</span>
//     <nav className="navbar">
//       <div className="search-box">
//         <input
//           type="text"
      
//         />
//         <i className="bx bx-search" />
//       </div>
//     </nav>
//     {/* <div className="icons">
//       <Link to ="https://github.com/disvoys?tab=repositories" target="_blank"></Link>
//       <div className="icons">
//         <Link to ="https://github.com/disvoys?tab=repositories" target="_blank">
//           <div className="fa-brands fa-github"></div>
//         </Link>
//       </div>
//     </div> */}
//   </header>
//   <div className="sidebar">
//     <ul className="nav-links">
//       <li>
//         <div id="aHOME" className="">
//           <i className="bx bx-grid-alt" />
//           {/* <span className="links_name">Dashboard</span> */}
//         </div>
//       </li>
//       <li>
//         <div id="aCHIFFRAGE" className="">
//           <i className="bx bx-box" />
//           {/* <span className="links_name">Chiffrage</span> */}
//         </div>
//       </li>
//       <li>
//         <div id="aPLANNING" className="">
//           <i className="bx bx-list-ul" />
//           {/* <span className="links_name">Planning</span> */}
//         </div>
//       </li>
//       <li>
//         <div id="aINDICATEURS" className="">
//           <i className="bx bx-line-chart" />
//           {/* <span className="links_name">Indicateurs</span> */}
//         </div>
//       </li>
//       <li>
//         <div id="aFACTURATION" className="">
//           <i className="bx bx-purchase-tag-alt" />
//           {/* <span className="links_name">Facturation</span> */}
//         </div>
//       </li>
//       <li>
//         <div id="aDATABASE" className="">
//           <i className="bx bx-coin-stack" />
//           {/* <span className="links_name">DataBase</span> */}
//         </div>
//       </li>
//     </ul>
//   </div>

// </>
// </>
//   )
// }

// export default Sidebar