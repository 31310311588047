import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import logo from "../../images/sssutms.jpg";
import Naac from "../../images/NavIMG/Resarch/ncc.png";
import malaysia from "../../images/malaysia.png";
import ajay from "../../images/mou_ajayCrowther.png";
import { Link } from "react-router-dom";

function CollaborationMou() {
  return (
    <>
      <Nav />

      <style>
        {`
  .section_all {
    padding: 50px 0;
  }

  .text-center h1 {
    font-size: 2.5rem;
    color: #003161;
    margin-bottom: 20px;
  }

  .mou-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .mou-row {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Added for proper alignment */
    gap: 20px;
  }

  .mou-logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }

  .mou-details {
    flex: 4;
  }

  .mou-title {
    font-size: 1.2rem;
    color: #003161;
    margin-bottom: 10px;
  }

  .mou-link {
    color: #003161;
    text-decoration: none;
    font-weight: 600;
  }

  .mou-link:hover {
    text-decoration: underline;
  }

  .list-icon {
    list-style: none;
    padding: 0;
  }

  .list-icon .lli {
    margin-bottom: 10px;
  }

  .list-icon a {
    text-decoration: none;
    color: #003161;
    font-size: 1rem;
    font-weight: 600;
  }

  .list-icon a:hover {
    text-decoration: underline;
  }
  `}
      </style>

      <section className="section_all" id="about">
        <div className="container">
          {/* Section Title */}
          <div className="text-center mb-1">
            <h1>MEMORANDUM OF UNDERSTANDING (MoU)</h1>
          </div>

          {/* MoU Rows */}
          <div className="mou-content">
            {/* MoU Row 1 */}
            <div className="mou-row">
              {/* Left: Logo */}
              <img src={Naac} alt="SSSUTMS MoU Logo" className="mou-logo" />

              {/* Middle: MoU Details */}
              <div className="mou-details">
                <h5 className="mou-title">SSSUTMS MoU with NCC</h5>
                <p>
                  <Link
                    to="https://cdn.sssutms.ac.in/media/static/PDF/MoU_NCC.pdf"
                    target="_blank"
                    className="mou-link"
                  >
                    View the full MoU document here
                  </Link>
                </p>
              </div>

              {/* Right: List Icon */}
              <ul className="list-icon">
                <li className="lli">
                  <Link to="/mou-collaboration">
                    <i className="fa fa-adjust" aria-hidden="true"></i> NCC MOU
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/activity">
                    <i className="fa fa-adjust" aria-hidden="true"></i> Activity
                  </Link>
                </li>
              </ul>
            </div>

            {/* MoU Row 2 */}
            <div className="mou-row">
              <img src={ajay} alt="AJAY MoU Logo" className="mou-logo" />
              <div className="mou-details">
                <Link to={"https://cdn.sssutms.ac.in/media/static/MOU/MOU.pdf"} target="_blank">
               
                <h5 className="mou-title">
                  SSSUTMS MoU with AJAYI CROWTHER UNIVERSITY (ACU)
                </h5>
                <p>
                  This collaboration aims to foster academic exchanges, joint
                  research initiatives, and student-faculty mobility programs
                  between SSSUTMS and ACU. 
                </p>
                </Link>
              </div>
            </div>

            {/* MoU Row 3 */}
            <div className="mou-row">
              <img
                src={malaysia}
                alt="Malaysia MoU Logo"
                className="mou-logo"
              />
              <div className="mou-details">
              <Link to={""} target="_blank">
                <h5 className="mou-title">
                  SSSUTMS MoU with Malaysia University of Science and Technology
                  (MUST)
                </h5>
                <p>
                  This MoU establishes a partnership to promote cutting-edge
                  research, cultural exchange, and enhanced educational
                  opportunities for students of both universities.
                </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footerpage />
    </>
  );
}

export default CollaborationMou;
