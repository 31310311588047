import React from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function TransferSubjectGroup() {
  return (
    <>
      <AdminDashboard/>
      <h1 style={{ marginTop: "10%", marginLeft: "50%" }}>
        {" "}
        TransferSubjectGroup
      </h1>
    </>
  );
}

export default TransferSubjectGroup;
