
import React from 'react'
import AdminDashboard from './Admin_Dashboard/AdminDashboard'


function  ProgramScheduling () {
  return (
    <>
   <AdminDashboard/>
    <h1 style={{marginTop:"10%", marginLeft:"50%"}}>  ProgramScheduling</h1>
    </>
  )
}

export default ProgramScheduling

