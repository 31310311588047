import React from 'react';
import Nav from '../HomePage/Nav';
import Footerpage from '../HomePage/Footerpage';
import { Link } from 'react-router-dom';

function Finance_Committe() {
  return (
    <>
      <Nav />
      <style>
        {
          `
.contentleader
{
text-align:justify;
width:100%;
margin-left:20px;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */
}
  
.leadership{
position:relative;
height:300px;
width:100%;
}

.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }

.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 
.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}


@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont"

      >
        <h2 className="h2 title-main" style={{ color: '#780000' }}>Finance Committee</h2>
        <hr className="title-hr"></hr>




        <div className="row dirr">




          <div className="col-lg-9 padding-section">




            <br />

            <h5 style={{ fontFamily: "'poppins',sansSerif", textAlign: 'center', }} >FINANCE COMMITTEE</h5><br></br>








            <style>
              {
                `
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `
              }
            </style>
            <div className="tann">
              <table className="responsive-table">

                <thead>
                  <tr>
                    <th scope="col">S. No.</th>

                    <th scope="col">Name</th>
                    <th scope="col">Profession</th>

                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>1  </td><td>Mr. Sidharth Kapoor</td><td> Chancellor</td></tr>
                  <tr>
                    <td>2</td><td> Dr. Mukesh Tiwari </td><td> Vice-Chancellor</td></tr>   <tr>
                    <td>3 </td><td>  Dr. Hemant Sharma</td><td>  Registrar</td></tr>   <tr>
                    <td>4  </td><td>Mr. Vimal Nath </td><td> Chief Finance & Account Officer</td></tr>
                  <tr><td>5 </td><td>  Mr. Vijay Sharda</td><td> Member of Governing Body</td></tr>
                  <tr><td>6</td><td> Ms. Deepika Pathak</td><td> Nominated By Sponsoring Body</td></tr>
                </tbody>
              </table>
            </div>




          </div>
          <div className="col-lg-3">

            <ul className='list-icon' >
              <h5 style={{ fontFamily: "'poppins',sansSerif", color: '#243642' }}>University Officials</h5>
              <br></br>  <li className='lli'>
               <Link to ='/chancellor'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Chancellor</Link></li>
              <li className='lli'>
               <Link to ='/vice_chancellor'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Vice Chancellor</Link></li>
              <li className='lli'>
               <Link to ='/registrar'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Registrar </Link></li>
              <li className='lli'>
               <Link to ='/exam_controller'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exam Controller</Link></li>
              <li className='lli'>
               <Link to ='/deputy_registrar'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Deputy Registrar </Link></li>
              <li className='lli'>  <Link to ='/governing_body'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Governing Body </Link></li>




              <li className='lli'>  <Link to ='/board_of_management'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Board of Management  </Link></li>


              <li className='lli'>  <Link to ='/academic_council'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Academic Council </Link></li>



              <li className='lli'>  <Link to ='/finance_committee'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Finance committee  </Link></li>



              <li className='lli'>  <Link to ='/standing_committee'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Standing Committee  </Link></li>

              <li className='lli'>  <Link to ='/statutes'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Statutes  </Link></li>



              <li className='lli'>  <Link to ='/audit_report'>
                <i class="fa fa-adjust" aria-hidden="true"></i>AUDIT REPORT   </Link></li>



              <li className='lli'>  <Link to ='/dean'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Dean  </Link></li>



              <li className='lli'>  <Link to ='/head_of_department'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Head of the Department </Link></li>
            </ul>
          </div>
        </div>







      </div>
      {/* </section> */}
      <Footerpage /></>
  )
}

export default Finance_Committe