import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function Workshop_Seminar() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:40px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          Work shop And Seminars
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <br />
            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              WORKSHOP AND CONFERENCE
            </h5>
            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>
            <table
              style={{
                width: "90%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th colSpan="4">UPCOMING EVENT</th>
                </tr>
                <tr>
                  <th>Date</th> <th>Department</th> <th>Event</th>
                  <th>Title</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    1{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    PHYSICAL EDUCATION
                  </td>
                  <td> WORKSHOP </td>
                  <td> STATISTICAL ANALYSIS OF RESEARCH METHODOLOGY</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    MANAGEMENT
                  </td>
                  <td> SEMINAR </td>
                  <td>PRACTICAL APPLICATIONS OF GEETA IN PRESENT SCENARIO </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    3{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    MANAGEMENT
                  </td>
                  <td> WORKSHOP </td>
                  <td> NEW TRENDS IN THESIS WRITING</td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    4
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    SCHOOL OF PHARMACY
                  </td>
                  <td> WORKSHOP </td>
                  <td> NATIONAL E-WORKSHOP ON “ADVANCED INSTRUMENTATION” </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    5{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    DIPLOMA ENGINEERING
                  </td>
                  <td>EXPERT LECTURE</td>
                  <td>ENTREPRENEURSHIP SKILL DEVELOPMENT AMONG STUDENTS </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <br></br>
            <table
              style={{
                width: "90%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th colSpan="5">2021-2022</th>
                </tr>
                <tr>
                  <th>S.No</th>
                  <th>Date</th> <th>Department</th> <th>Event</th>
                  <th>Title</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    1{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2 May 2022- 7 May 2022
                  </td>
                  <td> COMPUTER SCIENCE ENGINEERING / MCA </td>
                  <td> 5 Days WORKSHOP </td>
                  <td>
                    SQL Using Oracle{" "}
                    <span style={{ color: "red" }}>More Info</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <br></br>{" "}
            <table
              style={{
                width: "90%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th colSpan="4">WORKSHOP</th>
                </tr>
                <tr>
                  <th>S.NO.</th> <th>ACADEMIC YEAR</th> <th>NUMBER</th>
                  <th>TITLE</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    1{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2014-15
                  </td>
                  <td> 01 </td>
                  <td> ROBOTRYST </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2015-16
                  </td>
                  <td> 02 </td>
                  <td>
                    {" "}
                    WORKSHOP ON IMPACT OF MENTAL HEALTH ON ACADEMICS, WORKSHOP
                    ON MATLAB PROGRAMMING FOR IMAGE PROCESSING{" "}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    3{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2016-17
                  </td>
                  <td> 01 </td>
                  <td> WORKSHOP ON HYDRAULIC AND PNEUMATIC SYSTEM </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    4
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2017-18
                  </td>
                  <td> 02 </td>
                  <td>
                    {" "}
                    WORKSHOP ON INTEGRITY & CIVILITY IN LEADERSHIP, HANDS ON
                    WORKSHOP ON EMBEDDED SYSTEM DESIGN WITH IOT{" "}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    5{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2018-19
                  </td>
                  <td> 03 </td>
                  <td>
                    {" "}
                    AWS CLOUD COMPUTING, WORKSHOP ON ATHLETIC PERFORMANCE
                    ENHANCEMENT, ANSYS FLUENT WORKSHOP.{" "}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    6{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2019-20
                  </td>
                  <td> 03 </td>
                  <td>
                    {" "}
                    INNOVATING STUDENT LEADERSHIP, BUILDING PLANNING DESIGN AND
                    ANALYSIS, HAZARD IDENTIFICATION OF PROCESS PLANTS{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <br></br>{" "}
            <table
              style={{
                width: "90%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th colSpan="4">CONFERENCE</th>
                </tr>
                <tr>
                  <th>S.NO.</th> <th>ACADEMIC YEAR</th> <th>NUMBER</th>
                  <th>TITLE</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    1
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2014-15
                  </td>
                  <td> 01 </td>
                  <td>
                    International Conference on Advances in Mathematics,
                    Engineering & Technology (ICAMET-15){" "}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    2{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2015-16
                  </td>
                  <td> 02 </td>
                  <td>
                    {" "}
                    International Conference on Information and Education
                    Innovations (ICIEI-16), International Conference on
                    Engineering & Technology (ICET-16)
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    3{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2016-17
                  </td>
                  <td> 01 </td>
                  <td>
                    {" "}
                    International Conference on Advance Studies in Engineering
                    and Sciences” (ICASES-16),
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    4{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2017-18
                  </td>
                  <td> 02 </td>
                  <td>
                    {" "}
                    International Conference on Advance Studies in Engineering
                    and Sciences” (ICASES-17), International Conference on
                    Current Trends in STEAM (ICCTS-2018){" "}
                  </td>
                </tr>

                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    5{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2018-19
                  </td>
                  <td> 01 </td>
                  <td>
                    {" "}
                    International Conference on Current Trends in STEAM
                    (ICCTS-2019){" "}
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    6{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    {" "}
                    2019-20
                  </td>
                  <td> 01 </td>
                  <td>
                    {" "}
                    International Conference on Current Trends in STEAM
                    (ICCTS-2020){" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="col-lg-3">
            <ul className="list-icon">
              <h4
                style={{
                  fontFamily: "'poppins',sansSerif",
                  color: "#243642",
                  textDecoration: "underline",
                }}
              >
                Activity
              </h4>
              <br></br>{" "}
              <li className="lli">
                <Link to="/expert_lectures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Expert Lectures
                </Link>
              </li>
              <li className="lli">
                <Link to="/webinar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Webinar
                </Link>
              </li>
              <li className="lli">
                <Link to="/industrial_visits">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Industrial
                  Visits
                </Link>
              </li>
              <li className="lli">
                <Link to="/activity/events">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Events
                </Link>
              </li>
              <li className="lli">
                <Link to="/fdp">
                  <i class="fa fa-adjust" aria-hidden="true"></i>FDP
                </Link>
              </li>
              <li className="lli">
                <Link to="/workshop_seminars">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Workshop &
                  Seminars
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br></br>

      <Footerpage />
    </>
  );
}

export default Workshop_Seminar;
