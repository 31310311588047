import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function Activity_Events() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:40px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          Events
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <br />

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>
            <style>
              {`
        .container {
   padding: 2rem 0rem;
}
 h4 {
   margin: 2rem 0rem 1rem;
}
 .table-image td, .table-image th {
   vertical-align: middle;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
}

.video-thumbnail img {
  display: block;
  width: 100%;
  height: auto;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-button::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 15px;
  border-color: transparent transparent transparent white;
  transform: translateX(2px);
}

 `}
            </style>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <table
                    className="table table-image"
                    style={{ marginLeft: "20px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">S.No</th>
                        <th scope="col">Image</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td className="w-25">
                          <img
                            style={{ height: "150px", width: "150px" }}
                            src="https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/frame.jpg"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>

                        <td>
                          <strong> Constitution Day Celebration:</strong> Join
                          us on 26th November 2024, 2:00 PM onwards at Harbansh
                          Chopra Auditorium, Sri Satya Sai University Campus,
                          Sehore, with esteemed Chief Guests and dignitaries.
                          <br></br>
                          Program Coordinator: Dr. Amrita Soni, Dean, School of
                          Law <br></br> Patron: Dr. Mukesh Tiwari, Vice
                          Chancellor
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">1</th>
                        <td className="w-25">
                          <img
                            src="https://cdn.sssutms.ac.in/media/static/AcadmicPDF/PDF/LinkcadmicPDF/Linke1.jpg"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>

                        <td>
                          Dr. Vivek Bindra PRESENTS CAMPUS-PRENEUR Masterclass
                          For Students of SRI SATYA SAI UNIVERSITY OF TECHNOLOGY
                          AND MEDICAL SCIENCE 7th May 1PM-3PM
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td className="w-25">
                          <Link
                            to="https://youtu.be/rxOK274A2SE?si=Yx4YLGDjSW23_L8N"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="video-thumbnail"
                          >
                            <img
                              src="https://cdn.sssutms.ac.in/media/static/PDF/LinkcadmicPDF/"
                              className="img-fluid img-thumbnail"
                              alt="Event"
                            />
                            <div className="play-button"></div>
                          </Link>
                        </td>
                        <td>
                          मद्य निषेध संकल्प दिवस
                          <br />
                          30- Jan- 2022
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td className="w-25">
                          <img
                            src="https://cdn.sssutms.ac.in/media/static/PDF/LinkcadmicPDF/Linke3.jfif"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          {" "}
                          National Environment Youth parliament 2022 Nurturing
                          Environment (12 Jan 2022) More Info |
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">4</th>
                        <td className="w-25">
                          <img
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          60th NATIONAL PHARMACY WEEK, 2021 More info 23-27 Nov.
                          ’21
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">5</th>
                        <td className="w-25">
                          <img
                            src="https://cdn.sssutms.ac.in/media/static/PDF/LinkcadmicPDF/Linke5.jfif"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          AMRUIT MAHOTSAV ON THE OCCASION OF 75 TH INDEPENDENCE
                          DAY 13 August 2021(12:00 to 12:30 PM) COMPETITION ON
                          POSTER PRESENTATION ESSAY WRITING
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">6</th>
                        <td className="w-25">
                          <img
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          |International Yoga Day Monday, June 21 · 7:00 –
                          8:00am
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">7</th>
                        <td className="w-25">
                          <img
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          स्वतंत्रता की 75वीं वर्षगांठ के अवसर पर ‘‘आजादी का
                          अमृत महोत्सव’’ 05 अप्रैल 2021 Video Link: Vol1 || Vol2
                          || Vol3
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">8</th>
                        <td className="w-25">
                          <img
                            src="https://cdn.sssutms.ac.in/media/static/PDF/LinkcadmicPDF/Linke8.jfif"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          EDP Program by NIESBUD (National Institute For
                          Entrepreneurship And Small Business Development) (3
                          February 2021)
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">9</th>
                        <td className="w-25">
                          <img
                            src="https://cdn.sssutms.ac.in/media/static/PDF/LinkcadmicPDF/Linke9.jfif"
                            className="img-fluid img-thumbnail"
                            alt="Event"
                          />
                        </td>
                        <td>
                          {" "}
                          Environmental Youth Forum 2021 (12 Jan 2021) More Info
                          | Report
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <ul className="list-icon">
              <h4
                style={{
                  fontFamily: "'poppins',sansSerif",
                  color: "#243642",
                  textDecoration: "underline",
                }}
              >
                Activity
              </h4>
              <br></br>{" "}
              <li className="lli">
                <Link to="/expert_lectures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Expert Lectures
                </Link>
              </li>
              <li className="lli">
                <Link to="/webinar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Webinar
                </Link>
              </li>
              <li className="lli">
                <Link to="/industrial_visits">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Industrial
                  Visits
                </Link>
              </li>
              <li className="lli">
                <Link to="/activity/events">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Events
                </Link>
              </li>
              <li className="lli">
                <Link to="/fdp">
                  <i class="fa fa-adjust" aria-hidden="true"></i>FDP
                </Link>
              </li>
              <li className="lli">
                <Link to="/workshop_seminars">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Workshop &
                  Seminars
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br></br>

      <Footerpage />
    </>
  );
}

export default Activity_Events;
