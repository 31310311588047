import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
import { Link } from "react-router-dom";

function FeeStructure() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
        
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
 
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
        
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
                
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Fee Structure
            </h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
                <br />

                <style>
  {
    `
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `
  }
  </style>
            <div className="tann">
  <table className="responsive-table">
  
    <thead>
      <tr>
        <th scope="col">S. No.</th>
        <th scope="col">Course</th>
        <th scope="col">Tution Fees Per Annum</th> 
        <th scope="col">Eligibility</th> 
        <th scope="col">Duration In Year</th> 
      
      
      </tr>
    </thead>
   
    <tbody>
   
      <tr>
        <td >1</td>
        <td>BE</td>
        <td>53000</td>
        <td>10+2 (PCM) With 45% (UR), 40% (ST/SC/OBC)</td>
        <td> 4 Yrs.</td>
    </tr>
    <tr>
        <td >2</td>
        <td>M. Tech.</td>
        <td>63000</td>
        <td>	
        BE/B.Tech./MCA  With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
    </tr>
    <tr>
        <td >3</td>
        <td>B. Arch.</td>
        <td>65000</td>
        <td>	
        10+2 (PCM) With 50% In Each Subject (UR/ST/SC/OBC)</td>
        <td>5 Yrs.</td>
    </tr>
    <tr>
        <td >4</td>
        <td>	  B. Design</td>
        <td>70000</td>
        <td>	
        10+2  (Any Discipline ) With 45% (UR), 40% (ST/SC/OBC)</td>
        <td>4 Yrs.</td>
    </tr>
      <tr>
        <td >5</td>
        <td>	MBA (Full Time)</td>
        <td>50000</td>
        <td>Graduate In Any Discipline With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >6</td>
        <td>MCA</td>
        <td>40000</td>
        <td>A Student Who Has Passed 10+2 Examination Of Secondary School Education Board, Bhopal With Mathematics As One Of The Subject At 10+2 Examinations Or Its Equivalent And Have Passed B.Sc / B.Com/ BCA. With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >7</td>
        <td>B. Pharma</td>
        <td>52000</td>
        <td>10+2 (PCM/PCB) With 45% (UR), 40% (ST/SC/OBC)</td>
        <td>4 Yrs..</td>
      </tr>
      <tr>
        <td >8</td>
        <td>M. Pharma</td>
        <td>	
        154000</td>
        <td>B. Pharma With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs..</td>
      </tr>
      <tr>
        <td >9</td>
        <td>	D. Pharma</td>
        <td>75000</td>
        <td>10+2 (PCM/PCB) With 45% (UR), 40% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >10</td>
        <td>B.Ed.</td>
        <td>41000</td>
        <td>Graduate In Any Discipline With 50% (UR/OBC), 45% (ST/SC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >11</td>
        <td>B.A. B. Ed.(4Year Integrated Course)</td>
        <td>30000</td>
        <td>10+2 Any Discipline With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>4 Yrs.</td>
      </tr>
      <tr>
        <td >12</td>
        <td>B.P. Ed.</td>
        <td>40000</td>
        <td>Graduate In Any Discipline With 50% (UR/OBC/AI), 45% (ST/SC))</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >13</td>
        <td>	
        B.P.E.S.</td>
        <td>41000</td>
        <td>10+2 Or Its Equivalent With 45%</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >14</td>
        <td>	
        BHMCT</td>
        <td>62000</td>
        <td>10+2 Or Its Equivalent With 45% (UR),  40%(ST/SC/OBC)</td>
        <td>4 Yrs.</td>
      </tr>
      <tr>
        <td >15</td>
        <td>B.Sc. (Nursing)</td>
        <td>80000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>4 Yrs.</td>
      </tr>


      <tr>
        <td >16</td>
        <td>BBA</td>
        <td>21000</td>
        <td>10+2 Or Its Equivalent With 40% (UR/OBC), 35% (ST/SC)</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >17</td>
        <td>BCA</td>
        <td>21000</td>
        <td>10+2 (PCM) Or Its Equivalent With 50% (UR/OBC), 45% (ST/SC)</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >18</td>
        <td>B.Sc. </td>
        <td>16000</td>
        <td> 10+2 (PCM/PCB/Agri.) With 40% (UR), 33% (ST/SC/OBC)</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >19</td>
        <td>B.A.</td>
        <td>13000</td>
        <td>10+2 Or Its Equivalent With 40% (UR), 33% (ST/SC/OBC)</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >20</td>
        <td>B.Com.</td>
        <td>16000</td>
        <td>10+2 (PCM/PCB/Comm.) With 40% (UR), 33% (ST/SC/OBC)</td>
        <td>3 Yrs.</td>
      </tr>
      <tr>
        <td >21</td>
        <td>	
        M.Sc.</td>
        <td>26000</td>
        <td>B.Sc. (Hons) Maths/CS/IT/Physics/Chemistry/Biology As One Subject In B.Sc. With 40% (UR), 33% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >22</td>
        <td>M.A.</td>
        <td>26000</td>
        <td>UG With 40% For All Categories</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >23</td>
        <td>M.COM.</td>
        <td>	
        26000</td>
        <td>B.COM Passed For All Categories</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >24</td>
        <td>PGDCA</td>
        <td>21000</td>
        <td>	
        A Student Who Has Passes 10+2 Examination Of Secondary School Education Board, Bhopal With Mathematics As One Of The Subject At 10+2 Examinations Or Its Equivalent And Have Passed BA/B.Sc / B.Com/ BCA. With 40% (UR), 33% (ST/SC/OBC)</td>
        <td>1 Yr.</td>
      </tr>
      <tr>
        <td >25</td>
        <td>Bachelor Of Physiotherapy</td>
        <td>	
        42000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>4½ Yrs.</td>
      </tr>
      <tr>
        <td >26</td>
        <td>Diploma In X-Ray Radiographer Technician</td>
        <td>30000</td>
        <td>	
        10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >27</td>
        <td>D. Pharma (Ayurved)</td>
        <td>40000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >28</td>
        <td>	
        DMLT</td>
        <td>37000</td>
        <td>	
        10+2 (PCB) With 50% (UR), 40% (ST/SC/OBC)</td>
        <td>	
        2 Yrs.</td>
      </tr>
      <tr>
        <td >29</td>
        <td>Dialysis Technician</td>
        <td>40000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>	
        2 Yrs.</td>
      </tr>
      <tr>
        <td >30</td>
        <td>	
        Diploma In Human Nutrition</td>
        <td>28000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>	
        2 Yrs.</td>
      </tr>
      <tr>
        <td >31</td>
        <td>	
        Diploma In Blood Transfusion Technician</td>
        <td>38000</td>
        <td>	
        10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >32</td>
        <td>Diploma In Yoga</td>
        <td>30000</td>
        <td>10+2 (PCB) With 50% (UR), 45% (ST/SC/OBC)</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >33</td>
        <td>	
        Medical Laboratory Technology [Haematology] MMLT</td>
        <td>125000</td>
        <td>BMLT With 50% For All Category</td>
        <td>2 Yrs.</td>
      </tr>
      <tr>
        <td >34</td>
        <td>Master Of Physiotherapy [MPT]</td>
        <td>120000</td>
        <td>	
        BPT With 50% For All Category</td>
        <td>	
        2 Yrs.</td>
      </tr>
      <tr>
        <td >35</td>
        <td>B.Sc. (Hons.) Agriculture</td>
        <td>61000</td>
        <td>10+2 In PCM/PCB/ Agriculture With 45% </td>
        <td>	
        4 Yrs.</td>
      </tr>
     


      <tr>
        <td >36</td>
        <td>B. Tech. (Dairy Technology)</td>
        <td>25000</td>
        <td>10+2 (PCM/PCB/Agri.) With 50% (UR),, 45% (OBC), 33% (ST/SC) </td>
        <td>	
        4 Yrs.</td>
      </tr>
      <tr>
        <td >37</td>
        <td>Diploma(Engineering)</td>
        <td>30000</td>
        <td>10th Or Equivalent Examination Passed  </td>
        <td>	
        3 Yrs.</td>
      </tr>
      <tr>
        <td >38</td>
        <td>L.L.B.</td>
        <td>25600</td>
        <td>Graduate In Any Discipline With 45% (UR/OBC), 40% (ST/SC)</td>
        <td>	
        3 Yrs.</td>
      </tr>
      <tr>
        <td >39</td>
        <td>B. Lib.</td>
        <td>15000</td>
        <td>	
        Graduate In Any Discipline </td>
        <td>	
        1 Yrs.</td>
      </tr>
      <tr>
        <td >40</td>
        <td>	
        B.H.M.S.</td>
        <td>150000</td>
        <td>10+2 In PCB With Minimum 45% For SC/ST/OBC And 50% For UR</td>
        <td>	
        5.5 Yrs.</td>
      </tr>
      <tr>
        <td >41</td>
        <td>B.A.M.S.</td>
        <td>247300</td>
        <td>10+2 In PCB With Minimum 45% For SC/ST/OBC And 50% For UR</td>
        <td>	
        5.5 Yrs.</td>
      </tr>
    
    
     
    

  
    
    </tbody>
  </table>
</div>
              </p>
              <br />
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to = "/Admission-Registration">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Registration
                  </Link>
                </li>
                </Link>
                <Link to = "/Admission-Notice">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Notice
                  </Link>
                </li>
                </Link>
                <Link to = "/University-Account-Detail">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>University Account Detail
                  </Link>
                </li>
                </Link>
                <Link to = "/Fees-Structure">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Fees Structure
                  </Link>
                </li>
                </Link>
                <Link to = "/Admission-Procedure">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Admission Procedure
                  </Link>
                </li>
                </Link>
                <Link to = "/Brochures">
                <li className="lli">
                 <Link to ="">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Brochures
                  </Link>
                </li>
                </Link>
             
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default FeeStructure;
