import React from "react";
import Signup from "././components/Register&Login/Signup";
import Signin from "././components/Register&Login/Signin";
import { Routes, Route, Navigate } from "react-router-dom";
import Course from "././components/Register&Login/Course";
import Erp from "./components/Register&Login/Erp";
import Registration from "./AdminComponent/HodMainDetails";
import Registrationn from "./AdminComponent/StudentMainDetails";
import "bootstrap/dist/css/bootstrap.min.css";
import Activity from "./Navbarr/AboutUs/Activity.jsx";
/*--------------------STUDENT Routes----------------------------------------------*/
import StudentDashboardHome from "./components/Register&Login/StudentDashboardHome";
import StudentMainDetails from "./AdminComponent/StudentMainDetails";
import StudentHome from "./components/Register&Login/StdentHome";
import StudentDashboard from "./AdminComponent/StudentDashboard";
import StudentWaiting from "./components/Register&Login/StudentWaiting";
import StudentVerify from "./AdminComponent/StudentVerify";
import EnrollementForm from "./AdminComponent/EnrollementForm";
import OnlyHeader from "./AdminComponent/OnlyHeader";
import Success from "./AdminComponent/Payment/Success";
import Failure from "./AdminComponent/Payment/Failure";
import PaymentPage from "./AdminComponent/Payment/PaymentPage";
import StudentAllDetail from "./components/Register&Login/StudentAllDetail";

/*-------------------- Website Main Page Routes----------------------------------------------*/
import MainPage from "./HomePage/MainPage";

/*--------------------HOD Routes----------------------------------------------*/
import HodDashboard from "./AdminComponent/HodDashboard";
import HodMainDetails from "./AdminComponent/HodMainDetails";
import HodSignUp from "./AdminComponent/HodSignup";
import HodSignin from "./AdminComponent/HodSignin";
import Dashboard from "./AdminComponent/HodMainDas";
import Pending from "./AdminComponent/SudentPending";
import VerifyDetailed from "./AdminComponent/VerifyDetailed";

/*--------------------Admin Routes----------------------------------------------*/
import AdminLogin from "./Main_Admin/Masters/Registered_Login/Admin_login";
import AdminDashboard from "./Main_Admin/Masters/Admin_Dashboard/AdminDashboard";
import EnrollementG from "./Main_Admin/Masters/EnrollmentG";
import Branchmapping_Search from "./Main_Admin/Masters/Branchmapping_Search.js";
import Centermaster from "./Main_Admin/Masters/Centermaster";
import SpecializationMaster from "./Main_Admin/Masters/SpecializationMaster";
import SpecializationSubjectMapping from "./Main_Admin/Masters/SpecializationSubjectMapping";
import SpecializationTypeMaster from "./Main_Admin/Masters/SpecializationTypeMaster";
import SubjectHeaderInsert from "./Main_Admin/Masters/SubjectHeaderInsert";
import UploadSubjectMasterFromExcelData from "./Main_Admin/Masters/UploadSubjectMasterFromExcelData";
import CreateCollege from "./Main_Admin/Masters/CreateCollege";
import AdmissionSessionMaster from "./Main_Admin/Masters/AdmissionSessionMaster";
import FacultyMaster from "./Main_Admin/Masters/FacultyMaster";
import DepartmentMaster from "./Main_Admin/Masters/DepartmentMaster";
import CourseType from "./Main_Admin/Masters/CourseType";
import CourseMaster from "./Main_Admin/Masters/CourseMaster";
import BranchMaster from "./Main_Admin/Masters/BranchMaster";
import SubBranchMaster from "./Main_Admin/Masters/SubBranchMaster";
import CourseGroupMaster from "./Main_Admin/Masters/CourseGroupMaster";
import AdmissionOpen from "./Main_Admin/Masters/AdmissionOpen";
import ReservationMaster from "./Main_Admin/Masters/ReservationMaster";
import CreateCounsellingRound from "./Main_Admin/Masters/CreateCounsellingRound";
import LastExamSubjectGroup from "./Main_Admin/Masters/LastExamSubjectGroup";
import GradePointMaster from "./Main_Admin/Masters/GradePointMaster";
import SubjectMasterExcelDownload from "./Main_Admin/Masters/SubjectMasterExcelDownload";
import SubjectMasterPaperWise from "./Main_Admin/Masters/SubjectMasterPaperWise";
import SubGroupMaster from "./Main_Admin/Masters/SubGroupMaster";
import TransferSubject from "./Main_Admin/Masters/TransferSubject";
import TransferSubjectGroup from "./Main_Admin/Masters/TransferSubjectGroup";
import ProgramScheduling from "./Main_Admin/Masters/ProgramScheduling";
import ErpDashboard from "./Main_Admin/Masters/ErpDashboard";
import Search from "./Main_Admin/Masters/Search";
import Edetails from "./HomePage/Edetails.jsx";
import EnrolledStudentlist from "./Main_Admin/Masters/EnrolledStudent_list";
import EnrollementStudentSearch from "./Main_Admin/Masters/EnrolledStudent_search";
import StudentRegistrationList from "./Main_Admin/Masters/StudentRegistrationList";
import Admin_Pending from "./Main_Admin/Masters/Admin_Pending.jsx";
import TotalFeePaid from "./Main_Admin/Masters/TotalFeePaid";
import TotalRegistered from "./Main_Admin/Masters/TotalRegistered";
import TotalEnrolled from "./AdminComponent/TotalEnrolled";
import TotalSession from "./AdminComponent/Total_Std_HOD.jsx";
import TotalRegisStdtHod from "./AdminComponent/HodRegistStdt";
import TotalEnrolledAdmin from "./Main_Admin/Masters/AdminTotalEnrollStdt";
import AdminStdtSearch from "./Main_Admin/Masters/AdminStdtSearch";
import AdminUpdtStdt_Detail from "./Main_Admin/Masters/AdminUpdtStdt_Detail";
import AdminUpdateAllDetail from "./Main_Admin/Masters/AdminUpdateAllDetail";
import AdminUpdate_Documents from "./Main_Admin/Masters/AdminUpdate_Documents";
import AdminDocumentSearch from "./Main_Admin/Masters/AdminDocumentSearch";

import VisionMission from "./Navbarr/AboutUs/VisionMission.jsx";
import CoreValues from "./Navbarr/CoreValues";
import BestPractices from "./Navbarr/AboutUs/BestPractices.jsx";
import EntranceExamAlert from "./Navbarr/Examination/EntranceExamAlert";
import ExamNotifications from "./Navbarr/Examination/ExamNotifications";
import Result from "./Navbarr/Examination/Result";
import ExamSchedule from "./Navbarr/Examination/ExamSchedule";
import Interface from "./Navbarr/Examination/Interface";
import PhdPage from "./Navbarr/Academic/PhdPage";
import AcademicCalendar from "./Navbarr/Academic/AcademicCalendar";
import Scholarship from "./Navbarr/Academic/Scholarship";
import ConstituentUnits from "./Navbarr/Academic/ConstituentUnits";
import HEIHandbook from "./Navbarr/Academic/HEIHandbook";
import Director from "./Navbarr/Research/Director";
import RDCell from "./Navbarr/Research/RDCell";
import ConsultancyServices from "./Navbarr/Research/ConsultancyServices";
import TotalFeePaidHod from "./Main_Admin/Masters/TotalFeePaidHod";
import AdminPenSearch from "./Main_Admin/Masters/AdminPenSearch.jsx";
import AdminVeriStdSearch from "./Main_Admin/AdminVerified_Std_Search.jsx";

import SearchPen from "./Main_Admin/Masters/SearchPen.jsx";
import SearchVer from "./Main_Admin/Masters/SearchVer.jsx";
import Admissionstatus from "./Main_Admin/Masters/Admissionstatus.jsx";
import Adminser from "./Main_Admin/Masters/Adminser.jsx";
import ManualEnrol_G from "./Main_Admin/Masters/ManualEnrol_G.jsx";
import Paym from "./Main_Admin/Masters/Admin_Dashboard/Paym.jsx";
import Paymen from "./Main_Admin/Masters/Admin_Dashboard/Paymen.jsx";
import TermsConditions from "./HomePage/Footer/TermsConditions.jsx";
import PrivacyPolicy from "./HomePage/Footer/PrivacyPolicy.jsx";
import RefoundCancellation from "./HomePage/Footer/RefoundCancellation.jsx";
import Admission_Slip from "./Main_Admin/Masters/Admission_Slip.jsx";
import Admission_Slip_Search from "./Main_Admin/Masters/Admission_Slip_Search.jsx";

import Grievance from "./HomePage/Gravience.jsx";
import Bonafied_Search from "./Main_Admin/Masters/Bonafied_Certificate_Search.jsx";
import EntranceForm from "./ERP/Student_Erp/EntranceForm.jsx";
import Entrance_Payment from "./ERP/Student_Erp/Entrance_Payment.jsx";
import Entrance_Success from "./ERP/Student_Erp/Entrance_Success.jsx";
import Entrance_Slip from "./Main_Admin/Masters/Admin_Dashboard/Admin_Entrance/Entrance_Slip.jsx";
import E_Pravesh_Course from "./E-Pravesh/Student_E-pravesh/E-Courses.jsx";
import E_RegistrationForm from "./E-Pravesh/Student_E-pravesh/E-RegistrationForm.jsx";
import E_Payment from "./E-Pravesh/Student_E-pravesh/E-Payment.jsx";
import E_Success from "./E-Pravesh/Student_E-pravesh/E-Success.jsx";

import BESchema from "../src/Scheme/Scheme/BESchema.jsx";
import PharmacySchema from "../src/Scheme/Scheme/PharmacySchema.jsx";
import MtechScheme from "../src/Scheme/Scheme/MtechScheme.jsx";
import BHMCTSchemee from "../src/Scheme/Scheme/BHMCTScheme.jsx";
import MBAScheme from "../src/Scheme/Scheme/MBAScheme.jsx";
import MCAScheme from "../src/Scheme/Scheme/MCAScheme.jsx";
import EducationScheme from "../src/Scheme/Scheme/EducationScheme.jsx";
import PhysicalEducationScheme from "../src/Scheme/Scheme/PhysicalEducationScheme.jsx";
import BScHonsAg from "../src/Scheme/Scheme/BScHonsAg.jsx";
import BHMSScheme from "../src/Scheme/Scheme/BHMSScheme.jsx";
import UTDScheme from "../src/Scheme/Scheme/UTDScheme.jsx";
import ParamedicalScheme from "../src/Scheme/Scheme/ParamedicalScheme.jsx";
import PolytechniEngineering from "../src/Scheme/Scheme/PolytechniEngineering.jsx";
import BLibIScScheme from "../src/Scheme/Scheme/BLibIScScheme.jsx";
import BachelorofLawsScheme from "../src/Scheme/Scheme/BachelorofLawsScheme.jsx";
import BScHMCSScheme from "../src/Scheme/Scheme/BScHMCSScheme.jsx";
import Contact from "../src/HomePage/Footer/Contact.jsx";
import Approval from "../src/Navbarr/Approval.jsx";
import NotFound from "./Notfound/NotFound.jsx";
import National from "../src/Events/National.jsx";
import Lok from "../src/Events/Lok.jsx";
import Bams from "../src/Events/Bams.jsx";
import Bhms from "../src/Events/Bhms.jsx";
import RD from "../src/Events/RD.jsx";
import Eng from "../src/Events/Eng.jsx";
import Uni from "../src/Events/Uni.jsx";
import Wday from "../src/Events/Wday.jsx";
import Entrance_Inst from "./ERP/Student_Erp/Entrance_Inst.jsx";
import Online_Form from "./ERP/Student_Erp/Online_Form.jsx";
import Leadership from "./Navbarr/AboutUs/Leadership.jsx";
import PressMedia from "./Navbarr/AboutUs/PressMedia.jsx";
import CollaborationMou from "./Navbarr/Research/MOU.jsx";
import DoctoralProgramsPhD from "./Navbarr/Research/Doc_phd.jsx";
import Researchpromotionpolicy from "./Navbarr/Research/Resarch_Promo_Policy.jsx";
import HistoryMilestone from "./Navbarr/AboutUs/History_Milestone.jsx";
import Comm_cell from "./Navbarr/Academic/Commite&Cell/Commite&cell.jsx";
import Kargil from "./Events/Kargil.jsx";

import GrievanceRedressal from "./Navbarr/Academic/Commite&Cell/GrievanceRedressal.jsx";
import Campus from "./Navbarr/Campus.jsx";
import AdminClgSearch from "./Main_Admin/Masters/Admin_Clg_Search.jsx";
import AdminUpdate_Clg_Name_Branch from "./Main_Admin/Masters/Admin_Dashboard/AdminUpdate_Clg_Name_Branch.jsx";
import PassView_Search from "./Main_Admin/Masters/PassView_Search.jsx";
import Placement from "./Navbarr/Placement.jsx";
import Student_Rst_Pass from "./components/Register&Login/Student_Rst_Pass.jsx";
import EntrPr_Dev_cell from "./Navbarr/Academic/Commite&Cell/EntrPr_Dev_cell.jsx";
import Internal_Complaint from "./Navbarr/Academic/Commite&Cell/Internal_Complaint.jsx";
import Scst from "./Navbarr/Academic/Commite&Cell/Sc-St.jsx";
import Inter_High_Edu_cell from "./Navbarr/Academic/Commite&Cell/Inter_High_Edu_cell.jsx";
import Proctorial_Board from "./Navbarr/Academic/Commite&Cell/Proctorial_Board.jsx";
import E_Pending from "./E-Pravesh/Student_E-pravesh/E_Pending.jsx";
import E_Waiting from "./AdminComponent/Payment/E-Pravesh_Waiting.jsx";
// /////////////////////NAVBAR // SCHOOL & dEPT////////////////////////////////////////////////////////////////////
import EnggTechno from "./Navbarr/School&Dept/EnggTechno.jsx";
import Pharmacy from "./Navbarr/School&Dept/Pharmacy.jsx";
import E_approve from "./E-Pravesh/Student_E-pravesh/E_approve.jsx";
import Edu from "./Navbarr/School&Dept/Edu.jsx";
import Design from "./Navbarr/School&Dept/Design.jsx";
import Off from "./HomePage/Off.js";
import Human_Lang from "./Navbarr/School&Dept/Human_Lang.jsx";

import AdminAddCourse from "./Main_Admin/Masters/Admin_Dashboard/AdminAddCourse.jsx";
import AdminUpdt_Del_Course from "./Main_Admin/Masters/Admin_Dashboard/AdminUpdt_Del_Course.jsx";

import Admission_Enquiry from "./Admission/Admission_enquiry.jsx";
import BEsyllabus from "./HomePage/Syllabus/BEsyllabus.jsx";
import MBAsyllabus from "./HomePage/Syllabus/MBAsyllabus.jsx";
import MCAsyllabus from "./HomePage/Syllabus/MCAsyllabus.jsx";
import Mtechsyllabus from "./HomePage/Syllabus/Mtechsyllabus.jsx";
import UTDsyllabus from "./HomePage/Syllabus/UTDsyllabus.jsx";
import BHMSsyllabus from "./HomePage/Syllabus/BHMSsyllabus.jsx";
import Educationsyllabus from "./HomePage/Syllabus/Educationsyllabus.jsx";
import Pharmacysyllabus from "./HomePage/Syllabus/Pharmacysyllabus.jsx";
import BHMCTsyllabus from "./HomePage/Syllabus/BHMCTsyllabus.jsx";
import BLibsyllabus from "./HomePage/Syllabus/B.Libsyllabus.jsx";
import BSCHMCSsyllabus from "./HomePage/Syllabus/BSCHMCSsyllabus.jsx";
import BScHonsAGsyllabus from "./HomePage/Syllabus/B.Sc.(Hons.) AGsyllabus.jsx";
import Paramedicalsyllabus from "./HomePage/Syllabus/Paramedicalsyllabus.jsx";
import PhysicalEducationsyllabus from "./HomePage/Syllabus/PhysicalEducationsyllabus.jsx";
import Bacheloroflawsyllabus from "./HomePage/Syllabus/Bacheloroflawsyllabus.jsx";
import PolytechnicEngineeringsyllabus from "./HomePage/Syllabus/PolytechnicEngineeringsyllabus.jsx";
import Enrollement_Pay from "./E-Pravesh/Student_E-pravesh/Enrollement_Pay.jsx";
import Enrollement_Success from "./E-Pravesh/Student_E-pravesh/Enrollement_Success.jsx";
import EStudent_Slip from "./components/Register&Login/Student_Fee_Receipt/Student_ESlip.jsx";
import Std_Select_Slip from "./components/Register&Login/Student_Fee_Receipt/Student_Select_Slip.jsx";
import Search_Student_History from "./Main_Admin/Masters/Admin_Dashboard/Search_Student_History.jsx";
import Show_Student_History from "./Main_Admin/Masters/Admin_Dashboard/Show_Student_History.jsx";
import Manual_Fee_Updt from "./Main_Admin/Masters/Admin_Dashboard/Manual_Fee_Updt.jsx";
import Mannual_Pay_Search from "./Main_Admin/Masters/Admin_Dashboard/Mannual_Pay_Updt_Search.jsx";
import Manuual_Fee_Receipt from "./Main_Admin/Masters/Admin_Dashboard/Manuual_Fee_Receipt.jsx";
import Provisional_Payment from "./AdminComponent/HOD/Provisional_Pay.jsx";
import Provisional_Pay_Search from "./AdminComponent/HOD/Privisional_Pay_Search.jsx";
import Provisional_Receipt from "./AdminComponent/HOD/Provisional_Pay_Slip.jsx";
import Alumini_Form from "./ALUMINI_FORM/Alumini_Form.jsx";
import Science from "./Navbarr/School&Dept/Science.jsx";
import Commerce from "./Navbarr/School&Dept/Commerce.jsx";
import Hod_Std_Status from "./AdminComponent/Hod_Std_Status.jsx";
import Entrance_Fee_Updt from "./Main_Admin/Masters/Admin_Dashboard/Entrance_Fee_Updt.jsx";
import Id_Dash from "./Id_Card/Id_Dash.jsx";
import Id_Sidebar from "./Id_Card/Id_Sidebar.jsx";
import Id_Card_Search from "./Id_Card/Id_Card_Search.jsx";
import Id_Card_Table from "./Id_Card/Id_Card_Table.jsx";
import Antiragging from "./Navbarr/Academic/Commite&Cell/Anti_Raging.jsx";
import PromotingSociety from "./Navbarr/AboutUs/PromotingSociety.jsx";
import Institutes from "./Navbarr/AboutUs/Institutes.jsx";
import Sidebar from "./Exam/Sidebar.jsx";
import CourseChange from "./Exam/Masters/CourseChange.jsx";
import CourseChangeList from "./Exam/Masters/CourseChangeList.jsx";
import BranchMapping from "./Main_Admin/Masters/BranchMapping.jsx";
import Tranprotation from "./Amientie/Tranprotation.jsx";
import Hostel from "./Amientie/Hostel.jsx";
import Library from "./Amientie/Library.jsx";
import MedicalFacility from "./Amientie/MedicalFacility.jsx";
import GuestHouse from "./Amientie/GuestHouse.jsx";
import Chanceller from "./Univesity Officials/Chanceller.jsx";
import Vicechanceller from "./Univesity Officials/Vicechanceller.jsx";
import Registrar from "./Univesity Officials/Registrar.jsx";
import Examconroller from "./Univesity Officials/Examconroller.jsx";
import DuptyRegistrar from "./Univesity Officials/DuptyRegistrar.jsx";
import Exam_Session_Search from "./Main_Admin/Masters/Exam_Session_Search.jsx";
import ExamForm_Creation_Search from "./Main_Admin/Masters/ExamForm_Creation_Search.jsx";
import Governing_Body from "./Univesity Officials/Governing_Body.jsx";
import BoardOfMgt from "./Univesity Officials/BOM.jsx";
import Academic_Council from "./Univesity Officials/Acadmic_Council.jsx";
import Finance_Committe from "./Univesity Officials/Finance_Commite.jsx";
import Standing_Committe from "./Univesity Officials/Standing_Committe.jsx";
import Forms from "./DownloadNAV/DownloadNAV/Forms.jsx";
import PHDnotofication from "./DownloadNAV/DownloadNAV/PHDnotofication.jsx";
import { Engineering } from "@mui/icons-material";
import Engineeringg from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Engineeringg.jsx";
import Pharmacyy from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Pharmacyy.jsx";
import Statues from "./Univesity Officials/Statutes.jsx";
import Audit_Report from "./Univesity Officials/Audit_Report.jsx";
import HeadOfDept from "./Univesity Officials/HeadOfDept.jsx";
import Dean_Principle from "./Univesity Officials/Dean.jsx";
import Education from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Education.jsx";
import PhysicalEducation from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/PhysicalEducation.jsx";
import ComputerApplication from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/ComputerApplication.jsx";
import Management from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Management.jsx";
import BHMCT from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/BHMCT.jsx";
import LifeScience from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/LifeScience.jsx";
import ArtsAndHumanities from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/ArtsAndHumanities.jsx";
import Commercee from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Commercee.jsx";
import Sciencee from "./DownloadNAV/DownloadNAV/Outcome Based Curriculum/Sciencee.jsx";
import Std_ExamForm from "./components/Register&Login/Std_Exam_Form/Std_ExamForm.jsx";
import IQAC from "./Navbarr/Academic/IQAC.jsx";
import Mandatory_Disclouser from "./Navbarr/Academic/Mandatory_Dis.jsx";
import AdmissionNotice from "./Admission/AdmissionNotice.jsx";
import AdmissionProcedure from "./Admission/AdmissionProcedure.jsx";
import AdmissionRegistration from "./Admission/AdmissionRegistration.jsx";
import Brochures from "./Admission/Brochures.jsx";
import FeeStructure from "./Admission/FeeStructure.jsx";
import UniversityAccountDetail from "./Admission/UniversityAccountDetail.jsx";
import Training_Partner from "./Navbarr/Academic/Training_Partner.jsx";
import Training_PlacementCell from "./Navbarr/Academic/Training&PlacementCell.jsx";
import SSR from "./Navbarr/Academic/SSR.jsx";
import Criteria1 from "./Navbarr/Academic/Criteria1.jsx";
import Critera2 from "./Navbarr/Academic/Criteria2.jsx";
import Criteria4 from "./Navbarr/Academic/Criteria4.jsx";
import Criteria3 from "./Navbarr/Academic/Criteria3.jsx";
import Criteria5 from "./Navbarr/Academic/Criteria5.jsx";
import Criteria6 from "./Navbarr/Academic/Criteria6.jsx";
import Expert_Lecture from "./Navbarr/Academic/Acivities/Expert_Lecture.jsx";
import Webinar from "./Navbarr/Academic/Acivities/Webinar.jsx";
import Industrial_Visit from "./Navbarr/Academic/Acivities/Industrial_Visit.jsx";
import FDP from "./Navbarr/Academic/Acivities/FDP.jsx";
import Workshop_Seminar from "./Navbarr/Academic/Acivities/Workshop&Seminar.jsx";
import EContent from "./DownloadNAV/DownloadNAV/E_Content.jsx";
import Exam_Form_Print from "./components/Register&Login/Exam_Pay/Exam_Form.jsx";
import CouncilForResearch from "./Navbarr/Research/CouncilFor_Resarch.jsx";
import Patents from "./Navbarr/Research/patents.jsx";
import Aware from "./Events/Aware.jsx";
import IICCell from "./Navbarr/Research/IIC_Cell.jsx";
import EResources from "./Navbarr/Research/E_Resources.jsx";
import Exposition from "./Navbarr/Research/Exposition.jsx";
import Ug_Pg_Scholar from "./Navbarr/Research/Ug_Pg_Scholar.jsx";
import NPTEL from "./Navbarr/Research/NPTL.jsx";
import BSC_NursingSyllabus from "./HomePage/Syllabus/BSC_NursingSyllabus.jsx";
import Activity_Events from "./Navbarr/Academic/Acivities/Events.jsx";
import Constitution_Day from "./Events/Constitution.jsx";
import EventCalender from "./Navbarr/Academic/EventCalendar.jsx";
import NSS from "./Navbarr/AboutUs/NSS.jsx";
import Annual_report from "./Navbarr/AboutUs/Annual_Reports.jsx";
import InstitutionalDevPlan from "./Navbarr/AboutUs/Institutional_Develop.jsx";
import Central_Facilities from "./Navbarr/AboutUs/Central_Facilities.jsx";
import PublicSelf_Disclouser from "./Navbarr/AboutUs/PublicSelf_Disclouser.jsx";

//////////////////////////////////////// Exam Pannel/////////////////////////////////////

function App() {
  function isAuthenticated() {
    const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
    const hodData = JSON.parse(sessionStorage.getItem("currentUser"));
    const AdminData = JSON.parse(sessionStorage.getItem("Admin"));
    if (userData || hodData || AdminData) {
      return true;
    }
    return false;
  }
  function PrivateRoute({ element, ...rest }) {
    if (isAuthenticated()) {
      return element;
    } else {
      return (
        <Navigate
          to={`/erp
      `}
        />
      );
    }
  }
  // useEffect(() => {
  // console.log("Location changed:", location.pathname);
  // }, [location]);
  // useEffect(() => {
  //   const logoutTimer = setTimeout(() => {
  //     sessionStorage.clear();
  //     navigate('/erp');
  //   }, 20 * 60 * 1000);
  //   return () => clearTimeout(logoutTimer);
  // }, []);

  return (
    <>
      <Routes>
        {/* {==================== Admission =========================} */}
        <Route path="/admission/enquiry" element={<Admission_Enquiry />} />
        <Route
          path="/Admission/AdmissionRegistration"
          element={<AdmissionRegistration />}
        />
        <Route
          path="/Admission/AdmissionNotice"
          element={<AdmissionNotice />}
        />
        <Route
          path="/Admission/UniversityAccountDetail"
          element={<UniversityAccountDetail />}
        />
        <Route path="/Admission/FeesStructure" element={<FeeStructure />} />
        <Route
          path="/Admission/AdmissionProcedure"
          element={<AdmissionProcedure />}
        />
        <Route path="/Admission/Brochures" element={<Brochures />} />
        {/* {==================== Schema =========================} */}
        <Route path="/B.E-Scheme" element={<BESchema />} />
        <Route path="/Pharmacy-scheme" element={<PharmacySchema />} />
        <Route path="/M.Tech-Scheme" element={<MtechScheme />} />
        <Route path="/BHMCT-Scheme" element={<BHMCTSchemee />} />
        <Route path="/MBA-Scheme" element={<MBAScheme />} />
        <Route path="/MCA-Scheme" element={<MCAScheme />} />
        <Route path="/Education-Scheme" element={<EducationScheme />} />
        <Route
          path="/PhysicalEducation-Scheme"
          element={<PhysicalEducationScheme />}
        />
        <Route path="/BscHonsAg-Scheme" element={<BScHonsAg />} />
        <Route path="/BHMS-Scheme" element={<BHMSScheme />} />
        <Route path="/UTD-Scheme" element={<UTDScheme />} />
        <Route path="/Paramedical-Scheme" element={<ParamedicalScheme />} />
        <Route
          path="/POLYTECHNIC(ENGINEERING)-Scheme"
          element={<PolytechniEngineering />}
        />
        <Route path="/BLIBISC-Scheme" element={<BLibIScScheme />} />
        <Route
          path="/BachelorofLaws-Scheme"
          element={<BachelorofLawsScheme />}
        />
        <Route path="/BSCHMCS-Scheme" element={<BScHMCSScheme />} />
        <Route path="/campus" element={<Campus />} />
        <Route path="/placement" element={<Placement />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms & conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/Refund & Cancellation"
          element={<RefoundCancellation />}
        />
        {/* {============================  Syllabus  ===========================} */}
        <Route path="/activity" element={<Activity />} />
        <Route path="/B.E-Syllabus" element={<BEsyllabus />} />
        <Route path="/MBA-syllabus" element={<MBAsyllabus />} />
        <Route path="/MCA-syllabus" element={<MCAsyllabus />} />
        <Route path="/MTECH-syllabus" element={<Mtechsyllabus />} />
        <Route path="/UTD-syllabus" element={<UTDsyllabus />} />
        <Route path="/BHMS-syllabus" element={<BHMSsyllabus />} />
        <Route path="/Education-syllabus" element={<Educationsyllabus />} />
        <Route path="/Pharmacy-syllabus" element={<Pharmacysyllabus />} />
        <Route path="/BHMCT-syllabus" element={<BHMCTsyllabus />} />
        <Route path="/BLibISc -syllabus" element={<BLibsyllabus />} />
        <Route path="/BScHMCS-syllabus" element={<BSCHMCSsyllabus />} />
        <Route path="/BScNursing-syllabus" element={<BSC_NursingSyllabus />} />
        <Route path="/BScHonsAG-syllabus" element={<BScHonsAGsyllabus />} />
        <Route
          path="/POLYTECHNIC(ENGINEERING)-Scheme"
          element={<PolytechniEngineering />}
        />
        <Route path="/BLIBISC-Scheme" element={<BLibIScScheme />} />
        <Route path="/Paramedical-syllabus" element={<Paramedicalsyllabus />} />
        <Route
          path="/PhysicalEducation-syllabus"
          element={<PhysicalEducationsyllabus />}
        />
        <Route
          path="/Bacheloroflaws-syllabus"
          element={<Bacheloroflawsyllabus />}
        />
        <Route
          path="/PolytechnicEngineerin-syllabus"
          element={<PolytechnicEngineeringsyllabus />}
        />
        {/*-------------------------------------Alumini  form----------------------------------------------*/}
        <Route path="/alumini-form" element={<Alumini_Form />} />
        {/*-----------------------------------------------------------------------------------*/}
        {/*-----------------------------------------------------------------------------------*/}
        {/*------------------------Student Routes-----------------------------------------*/}
        <Route path="/promotingsociety" element={<PromotingSociety />} />
        <Route path="/institutes" element={<Institutes />} />
        <Route path="/erp/studentregister" element={<Signup />} />
        <Route path="/erp/studentlogin" element={<Signin />} />
        <Route
          path="/studentlogin/selectCourse"
          element={<PrivateRoute element={<Course />} />}
        />
        {/*---------------Exam Pannel----------*/}
        <Route path="/erp/student/studentexamform" element={<Std_ExamForm />} />
        <Route path="/erp/studentexamform" element={<Exam_Form_Print />} />
        <Route path="/studentalldetail" element={<StudentAllDetail />} />
        <Route path="/student/slip" element={<EStudent_Slip />} />
        <Route path="/std/select/slip" element={<Std_Select_Slip />} />
        <Route
          path="/onlyheader"
          element={<PrivateRoute element={<OnlyHeader />} />}
        />
        <Route
          path="/erp/studentDashboard"
          element={<PrivateRoute element={<StudentDashboard />} />}
        />
        <Route
          path="/student/reset/password"
          element={<PrivateRoute element={<Student_Rst_Pass />} />}
        />
        <Route path="/studentWaiting" element={<StudentWaiting />} />
        <Route path="/studentDetail" element={<StudentMainDetails />} />
        <Route path="/studentHome" element={<StudentHome />} />
        <Route
          path="/student/Enrollement/Form"
          element={<PrivateRoute element={<EnrollementForm />} />}
        />
        <Route
          path="/student/dashboard/home"
          element={<StudentDashboardHome />}
        />
        <Route path="/studentUpdateDetail" element={<Registrationn />} />
        <Route
          path="/PaymentPage"
          element={<PrivateRoute element={<PaymentPage />} />}
        />
        <Route path="/epravesh/waiting" element={<E_Waiting />} />
        <Route path="/success" element={<Success />} />
        <Route path="/failure" element={<Failure />} />
        <Route
          path="/verifyDetailed"
          element={<PrivateRoute element={<VerifyDetailed />} />}
        />
        <Route path="/" element={<MainPage />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/off" element={<Off />} />
        <Route path="/eventss" element={<Edetails />} />
        <Route path="/aware" element={<Aware />} />
        <Route path="/kargil" element={<Kargil />} />
        <Route path="/women" element={<Wday />} />
        <Route path="/national" element={<National />} />
        <Route path="/lok" element={<Lok />} />
        <Route path="/bams" element={<Bams />} />
        <Route path="/constitutionDay" element={<Constitution_Day />} />
        <Route path="/r&d" element={<RD />} />
        <Route path="/eng" element={<Eng />} />
        <Route path="/uni" element={<Uni />} />
        {/*------------------------Student Entrance form Routes-----------------------------------------*/}
        <Route path="/entrance/form" element={<EntranceForm />}></Route>
        <Route path="/entrance/payment" element={<Entrance_Payment />}></Route>
        <Route
          path="/entrance/payment_success"
          element={<Entrance_Success />}
        ></Route>
        <Route path="/entrance/slip" element={<Entrance_Slip />}></Route>
        <Route path="/entrance/form/start" element={<Entrance_Inst />}></Route>
        <Route path="/entrance/online/form" element={<Online_Form />}></Route>
        {/*------------------------Student E-Pravesh Routes-----   ------------------------------------*/}
        <Route
          path="/Epravesh/Student/Register"
          element={<E_Pravesh_Course />}
        ></Route>
        <Route
          path="/Epravesh/Student/RegisterForm"
          element={<E_RegistrationForm />}
        ></Route>
        <Route path="/Epravesh/Student/payment" element={<E_Payment />}></Route>
        <Route path="/Epravesh/Success" element={<E_Success />}></Route>
        <Route path="/Epravesh/pending/student" element={<E_Pending />}></Route>
        <Route
          path="/Epravesh/verified/student"
          element={<E_approve />}
        ></Route>
        <Route
          path="/Epravesh/Student/EnrollementFee"
          element={<Enrollement_Pay />}
        ></Route>
        <Route
          path="/Epravesh/EnrollementFee/Success"
          element={<Enrollement_Success />}
        ></Route>
        {/*--------------------------------Hod Routes--------------------------------------------------*/}
        <Route path="/erp/hodlogin" element={<HodSignin />} />
        <Route path="/erp/hodregister" element={<HodSignUp />} />
        <Route
          path="/totalenrolled"
          element={<PrivateRoute element={<TotalEnrolled />} />}
        />
        <Route
          path="/total/student/Registered"
          element={<PrivateRoute element={<TotalRegisStdtHod />} />}
        />
        <Route
          path="/total/student/branch"
          element={<PrivateRoute element={<TotalSession />} />}
        />
        <Route
          path="/total/student/feepaid"
          element={<PrivateRoute element={<TotalFeePaidHod />} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="/Registration" element={<Registration />} />
        <Route
          path="/studentverifieddetailed"
          element={<PrivateRoute element={<VerifyDetailed />} />}
        />
        <Route
          path="/hodDashboard"
          element={<PrivateRoute element={<HodDashboard />} />}
        />
        <Route
          path="/hodDetail"
          element={<PrivateRoute element={<HodMainDetails />} />}
        />
        <Route
          path="/hod/std/details"
          element={<PrivateRoute element={<Hod_Std_Status />} />}
        />
        {/*------------------------HOD Routes-----------------------------------------*/}
        {/*------------------------HOD Provisional payment-----------------------------------------*/}
        <Route
          path="/student/provisional/payment"
          element={<PrivateRoute element={<Provisional_Payment />} />}
        />
        <Route
          path="/student/provisional/receipt"
          element={<PrivateRoute element={<Provisional_Receipt />} />}
        />
        <Route
          path="/provisionl/std"
          element={<PrivateRoute element={<Provisional_Pay_Search />} />}
        />
        {/*------------------------HOD Routes-----------------------------------------*/}
        {/*------------------------NRML STD VERIFY Routes-----------------------------------------*/}
        <Route
          path="/studentpending"
          element={<PrivateRoute element={<Pending />} />}
        />
   
        <Route path="/studentverify" element={<StudentVerify />} />
        {/*-------------------------------------------------------------------*/}
        {/*------------------------Admin Routes-----------------------------------------*/}
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route
          path="/admin/erpdasboard"
          element={<PrivateRoute element={<ErpDashboard />} />}
        />
        <Route
          path="/student/totalregistered"
          element={<PrivateRoute element={<TotalRegistered />} />}
        />
        <Route
          path="/student/totalenrolled"
          element={<PrivateRoute element={<TotalEnrolledAdmin />} />}
        />
        <Route
          path="/student/totalfeepaid"
          element={<PrivateRoute element={<TotalFeePaid />} />}
        />
        <Route
          path="/registered/studentlist"
          element={<PrivateRoute element={<StudentRegistrationList />} />}
        />
        {/*------------------Enrollement generation--------------------------------*/}
        <Route
          path="/enrollment"
          element={<PrivateRoute element={<EnrollementG />} />}
        />
        <Route
          path="/search-result/:session/:courseType/:course/:branch/:college"
          element={<PrivateRoute element={<Search />} />}
        />
        {/*-------------------------Manual Search std--------------------------------------------*/}
        <Route
          path="/manual/enrollment/generation"
          element={<ManualEnrol_G />}
        />
        {/*------------------------Enrolled Std list---------------------------------------------*/}
        <Route
          path="/enrolled/student/search-result"
          element={<PrivateRoute element={<EnrollementStudentSearch />} />}
        />
        <Route
          path="/enrolled/student/list/:session/:courseType/:course/:branch/:college"
          element={<PrivateRoute element={<EnrolledStudentlist />} />}
        />
        {/*-----------------Admission status---check krna document----------------*/}
        <Route
          path="/student/status"
          element={<PrivateRoute element={<Admissionstatus />} />}
        />
        <Route
          path="/sea/:admissionSession/:courseType/:courseName/:courseBranch"
          element={<PrivateRoute element={<Adminser />} />}
        />
        {/*---------------get adission slip by updated date-----------------------------*/}
        <Route
          path="/admin/erpdashboard/admissionslip/search"
          element={<PrivateRoute element={<Admission_Slip_Search />} />}
        />
        <Route
          path="/admissionslip/:session/:courseType/:course/:branch/:college"
          element={<PrivateRoute element={<Admission_Slip />} />}
        />
        {/*--------------------std details updt but not updt----------------------------*/}
        <Route
          path="/erpdashboard/student/search"
          element={<PrivateRoute element={<AdminStdtSearch />} />}
        />
        <Route
          path="/erpdashboard/student/updatedetails"
          element={<PrivateRoute element={<AdminUpdtStdt_Detail />} />}
        />
        {/*-------------------------std document updt--------------------------------------------*/}
        <Route
          path="/erpdashboard/student/document/search"
          element={<PrivateRoute element={<AdminDocumentSearch />} />}
        />
        <Route
          path="/erpdashboard/student/updatedocuments/:id"
          element={<PrivateRoute element={<AdminUpdate_Documents />} />}
        />
        {/*---------------Updt course & clg------------------------*/}
        <Route
          path="/erpdashboard/student/updateclg&branch"
          element={<PrivateRoute element={<AdminClgSearch />} />}
        />
        <Route
          path="/erpdashboard/student/updateClgBranch/:id"
          element={<PrivateRoute element={<AdminUpdate_Clg_Name_Branch />} />}
        />
        {/*---------------------course add krna-----------------------*/}
        <Route
          path="/erpdahboard/student/addcourse"
          element={<PrivateRoute element={<AdminAddCourse />} />}
        />
        {/*------------------updt and del course-----------------------*/}
        <Route
          path="/erpdashboard/student/updt/delete/course"
          element={<PrivateRoute element={<AdminUpdt_Del_Course />} />}
        ></Route>
        {/*---------------pending std list--------------------------------------*/}
        <Route
          path="/erpdashboard/pending/student/search"
          element={<PrivateRoute element={<AdminPenSearch />} />}
        />
        <Route
          path="/search/:admissionSession/:courseType/:courseName/:courseBranch"
          element={<PrivateRoute element={<SearchPen />} />}
        />
        {/*----------------verified std -----------------------------------------------------*/}
        <Route
          path="/erpdashboard/verified/student/search"
          element={<PrivateRoute element={<AdminVeriStdSearch />} />}
        />
        <Route
          path="/searchver/:admissionSession/:courseType/:courseName/:courseBranch"
          element={<PrivateRoute element={<SearchVer />} />}
        />
        {/*-----------verify  payment--- details---------------------------------------------*/}
        <Route path="/paym" element={<PrivateRoute element={<Paym />} />} />
        <Route path="/paymen" element={<PrivateRoute element={<Paymen />} />} />
        {/*-----------manuual-------verify  payment--- details---------------------------------------------*/}
        <Route
          path="/manually/search/std"
          element={<PrivateRoute element={<Mannual_Pay_Search />} />}
        />
        <Route
          path="/entrance/search/std"
          element={<PrivateRoute element={<Entrance_Fee_Updt />} />}
        />
        <Route
          path="/manual/fee/updt"
          element={<PrivateRoute element={<Manual_Fee_Updt />} />}
        />
        <Route
          path="/manual/fee/receipt"
          element={<PrivateRoute element={<Manuual_Fee_Receipt />} />}
        />
        {/*-----------------bonafied search----------------------*/}
        <Route
          path="/admin/erpdashboard/Bonafied/certificate"
          element={<Bonafied_Search />}
        />
        {/*--------------------view pass-------------------------------------------------*/}
        <Route
          path="/erpdashboard/student/Pass-search"
          element={<PrivateRoute element={<PassView_Search />} />}
        />
        {/*-----------------------------------------------------------------------------------*/}
        {/*-----------------------------------------------------------------------------------*/}
        {/*-----------------------------------------------------------------------------------*/}
        {/*-----------------------------------------------------------------------------------*/}
        {/*-----------------------------------------------------------------------------------*/}
        <Route
          path="/admindashboard"
          element={<PrivateRoute element={<AdminDashboard />} />}
        />
        <Route
          path="/student/totalenrolled"
          element={<PrivateRoute element={<TotalEnrolledAdmin />} />}
        />
        <Route
          path="/student/history"
          element={<PrivateRoute element={<Search_Student_History />} />}
        />
        <Route
          path="/student/alldetail"
          element={<PrivateRoute element={<Show_Student_History />} />}
        />
        <Route
          path="/erpdashboard/student/updatealldetails/:id"
          element={<PrivateRoute element={<AdminUpdateAllDetail />} />}
        />
        {/*//////////////////////PassViewSearch/////////////////////////////////////*/}
        <Route
          path="/admin/search/student-pending/list"
          element={<PrivateRoute element={<Admin_Pending />} />}
        />
        {/*/////////////////////Masters/////////////////////////////////////*/}
        <Route
          path="/erp/Masters/branchmapping"
          element={<Branchmapping_Search />}
        />
        <Route path="/erp/Masters/branch-table" element={<BranchMapping />} />
        <Route
          path="/erp/Masters/examsessionmaster"
          element={<Exam_Session_Search />}
        />
        <Route
          path="/erp/Examination/examinationformcreation"
          element={<ExamForm_Creation_Search />}
        />
        <Route path="/center-master" element={<Centermaster />} />
        <Route
          path="/specialization-master"
          element={<SpecializationMaster />}
        />
        <Route
          path="/specialization-subject-mapping"
          element={<SpecializationSubjectMapping />}
        />
        <Route
          path="/sprcialization-type-master"
          element={<SpecializationTypeMaster />}
        />
        <Route
          path="/subject-header-insert"
          element={<SubjectHeaderInsert />}
        />
        <Route
          path="/upload-subject-master-from-excel-data"
          element={<UploadSubjectMasterFromExcelData />}
        />
        <Route path="/create-collage" element={<CreateCollege />} />
        <Route path="/antiraging" element={<Antiragging />} />
        <Route path="/grievance" element={<Grievance />} />
        <Route
          path="/admission-session-update"
          element={<AdmissionSessionMaster />}
        />
        <Route path="/faculty-master" element={<FacultyMaster />} />
        <Route path="/department-master" element={<DepartmentMaster />} />
        <Route path="/course-type" element={<CourseType />} />
        <Route path="/course-master" element={<CourseMaster />} />
        <Route path="/branch-master" element={<BranchMaster />} />
        <Route path="/sub-branch-master" element={<SubBranchMaster />} />
        <Route path="/course-group-master" element={<CourseGroupMaster />} />
        <Route path="/admission-open" element={<AdmissionOpen />} />
        <Route path="/reservation-master" element={<ReservationMaster />} />
        <Route
          path="/create-counselling-round"
          element={<CreateCounsellingRound />}
        />
        <Route
          path="/last-exam-sunject-group"
          element={<LastExamSubjectGroup />}
        />
        <Route path="/grade-point-master" element={<GradePointMaster />} />
        <Route
          path="/subject-master-excel-download"
          element={<SubjectMasterExcelDownload />}
        />
        <Route
          path="/subject-master-paper-wise"
          element={<SubjectMasterPaperWise />}
        />
        <Route path="/subject-group-master" element={<SubGroupMaster />} />
        <Route path="/transfer-subject" element={<TransferSubject />} />
        <Route
          path="/transfer-subject-group"
          element={<TransferSubjectGroup />}
        />
        <Route path="/program-scheduling" element={<ProgramScheduling />} />

        {/* ======================================================= */}
        {/* /////////////////////////*About Us/////////////////////////////////////////////*/}
        <Route path="/vision-mission" element={<VisionMission />} />
        <Route path="/leadership" element={<Leadership />}></Route>
        <Route path="/press-media" element={<PressMedia />}></Route>
        <Route path="/best-practices" element={<BestPractices />}></Route>
        <Route path="/NSS" element={<NSS />}></Route>
        <Route path="/annual_reports" element={<Annual_report />}></Route>
        <Route path="/InstitutionalDevPlan" element={<InstitutionalDevPlan/>}></Route>
        <Route path="/Central_facilities" element={<Central_Facilities/>}></Route>
        <Route path="/Public-Self-Disclosure" element={<PublicSelf_Disclouser/>}></Route>
        <Route path="/History_Milestones" element={<HistoryMilestone />}></Route>

        {/* /////////////////////////////commmiittee cell /////////////////////////////// */}
        <Route
          path="/committe&cell/entrepreneurship/cell"
          element={<EntrPr_Dev_cell />}
        ></Route>
        <Route
          path="/committe&cell/internal/complaint"
          element={<Internal_Complaint />}
        ></Route>
        <Route path="/committe&cell/sc-st" element={<Scst />}></Route>
        <Route
          path="/committe&cell/International/higher/education"
          element={<Inter_High_Edu_cell />}
        ></Route>
        <Route
          path="/grievanceredressal"
          element={<GrievanceRedressal />}
        ></Route>
        <Route path="/committe&cell" element={<Comm_cell />}></Route>
        <Route
          path="/committe&cell/proctorial/board"
          element={<Proctorial_Board />}
        ></Route>
        <Route path="/approval" element={<Approval />}></Route>
        <Route path="/core-approvalsvalues" element={<CoreValues />} />
        {/* /////////////////////NAVBAR // SCHOOL & dEPT//////////////////////////////////////////////////////////////////// */}
        <Route path="/enggtechno" element={<EnggTechno />} />
        <Route path="/pharma" element={<Pharmacy />} />
        <Route path="/science" element={<Science />} />
        <Route path="/commerce" element={<Commerce />} />
        {/* ======================== Examination =============================== */}
        <Route path="/entrance-exam-alert" element={<EntranceExamAlert />} />
        <Route path="/exam-notifications" element={<ExamNotifications />} />
        <Route path="/exam-schedule" element={<ExamSchedule />} />
        <Route path="/interface" element={<Interface />} />
        <Route path="/result" element={<Result />} />
        {/* ========================= Academic  =========================================== */}
        <Route path="/phd-page" element={<PhdPage />} />
        <Route path="/academic-calender" element={<AcademicCalendar />} />
        <Route path="/event-calender" element={<EventCalender />} />
        <Route path="/scholarship" element={<Scholarship />} />
        <Route path="/Hei-handbook" element={<HEIHandbook />} />
        <Route
          path="/Academic/TrainingPartner"
          element={<Training_Partner />}
        />
        <Route
          path="/Academic/TrainingAndPlacementCell"
          element={<Training_PlacementCell />}
        />
        <Route path="/constituent-units" element={<ConstituentUnits />} />
        <Route path="/SSR" element={<SSR />} />
        <Route path="/criteria1" element={<Criteria1 />} />
        <Route path="/criteria2" element={<Critera2 />} />
        <Route path="/criteria4" element={<Criteria4 />} />
        <Route path="/criteria3" element={<Criteria3 />} />
        <Route path="/criteria5" element={<Criteria5 />} />
        <Route path="/criteria6" element={<Criteria6 />} />
        <Route path="/iqac_cell" element={<IQAC />} />
        <Route
          path="/mandatory_disclosures"
          element={<Mandatory_Disclouser />}
        />
        <Route path="/expert_lectures" element={<Expert_Lecture />} />
        <Route path="/webinar" element={<Webinar />} />
        <Route path="/industrial_visits" element={<Industrial_Visit />} />
        <Route path="/fdp" element={<FDP />} />
        <Route path="/activity/events" element={<Activity_Events />} />
        <Route path="/workshop_seminars" element={<Workshop_Seminar />} />
        {/* ========================= Research ============================================ */}
        <Route path="/Director" element={<Director />} />
        <Route path="/R&D-Cell" element={<RDCell />} />
        <Route path="/Council-For-Research" element={<CouncilForResearch />} />
        <Route path="/Research-Policy" element={<Researchpromotionpolicy />} />
        <Route path="/Consultancy-Services" element={<ConsultancyServices />} />
        <Route path="/doctoral-phd" element={<DoctoralProgramsPhD />} />
        <Route path="/Patent" element={<Patents />} />
        <Route path="/mou-collaboration" element={<CollaborationMou />} />
        <Route path="/IICCell" element={<IICCell />} />
        <Route path="/E-Resources" element={<EResources />} />
        <Route path="/Exposition" element={<Exposition />} />
        <Route path="/UGAndPGScholarsProject" element={<Ug_Pg_Scholar />} />
        <Route path="/NPTEL" element={<NPTEL />} />
        <Route path="*" element={<NotFound />} />
        {/* =========================  ============================================ */}
        {/* =======================   Amemitie================================ */}
        <Route path="/transprotation" element={<Tranprotation />} />
        <Route path="/Hostel" element={<Hostel />} />
        <Route path="/Library" element={<Library />} />
        <Route path="/Medicalfacility" element={<MedicalFacility />} />
        <Route path="/Guesthouse" element={<GuestHouse />} />
        {/* ======================= University Official  ================================ */}
        <Route path="/chancellor" element={<Chanceller />} />
        <Route path="/vice_chancellor" element={<Vicechanceller />} />
        <Route path="/registrar" element={<Registrar />} />
        <Route path="/exam_controller" element={<Examconroller />} />
        <Route path="/deputy_registrar" element={<DuptyRegistrar />} />
        <Route path="/governing_body" element={<Governing_Body />} />
        <Route path="/board_of_management" element={<BoardOfMgt />} />
        <Route path="/academic_council" element={<Academic_Council />} />
        <Route path="/finance_committee" element={<Finance_Committe />} />
        <Route path="/standing_committee" element={<Standing_Committe />} />
        <Route path="/statutes" element={<Statues />} />
        <Route path="/audit_report" element={<Audit_Report />} />
        <Route path="/head_of_department" element={<HeadOfDept />} />
        <Route path="/dean" element={<Dean_Principle />} />
        {/* ======================= Activites ================================ */}
        {/* <Route path="/expert-lectures" element={<ExpertLectures/>} /> */}
        {/* <Route path="/Hostel" element={<Hostel />} />
        <Route path="/Library" element={<Library/>} />
        <Route path="/Medicalfacility" element={<MedicalFacility/>} />
        <Route path="/Guesthouse" element={<GuestHouse/>} /> */}
        {/* ======================================================================== */}
        {/* /////////////////////////*About Us/////////////////////////////////////////////*/}
        <Route path="/anti-ragging" element={<Antiragging />} />
        <Route path="/proctorial-board" element={<Proctorial_Board />} />
        <Route path="/internal-complaint" element={<Internal_Complaint />} />
        <Route
          path="/grievance-redressal"
          element={<GrievanceRedressal />}
        ></Route>
        <Route path="/sc-st" element={<Scst />}></Route>
        <Route path="/edc" element={<EntrPr_Dev_cell />}></Route>
        <Route
          path="/international-higher-education-cell"
          element={<Inter_High_Edu_cell />}
        ></Route>
        {/*=========================================================================*/}
        <Route path="/edu" element={<Edu />} />
        <Route path="/humanities&language" element={<Human_Lang />} />
        <Route path="/design" element={<Design />} />
        {/*===============================ID CARD==========================================*/}
        <Route path="/id" element={<Id_Card_Table />} />
        <Route path="/id_dash" element={<Id_Dash />} />
        <Route path="/id_sidebar" element={<Id_Sidebar />} />
        <Route path="/id_add" element={<Id_Card_Search />} />
        {/*===============================Exam Pannel ==========================================*/}
        <Route path="/exam/erpdasboard" element={<Sidebar />} />
        <Route path="/coursechange" element={<CourseChange />} />
        <Route path="" element={<CourseChangeList />} />
        {/* =======================   Download  ================================ */}
        <Route path="/forms" element={<Forms />} />
        <Route path="/E-Content" element={<EContent />} />
        <Route path="/phd-notofication" element={<PHDnotofication />} />
        {/* ======================= Outcome Based Curriculum (Download)  ================================ */}
        <Route path="/Engineering" element={<Engineeringg />} />
        <Route path="/Pharmacy" element={<Pharmacyy />} />
        <Route path="/Educaion" element={<Education />} />
        <Route path="/Physical-Education" element={<PhysicalEducation />} />
        <Route path="/Management" element={<Management />} />
        <Route path="/Computer-Application" element={<ComputerApplication />} />
        <Route path="/BHMCT" element={<BHMCT />} />
        <Route path="/Life-Science" element={<LifeScience />} />
        <Route path="/Arts-And-Humanities" element={<ArtsAndHumanities />} />
        <Route path="/outcome/Commerce" element={<Commercee />} />
        <Route path="/outcome/Science" element={<Sciencee />} />
      </Routes>
    </>
  );
}

export default App;
