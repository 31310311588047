import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import Box from "@mui/material/Box";
import { Grid, Button, Tab, Tabs } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DatePicker from "react-datepicker";
import logo from "../../AdminComponent/12.png";
import StudentDashboard from "../../AdminComponent/StudentDashboard";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

function StudentAllDetail() {
  const [isEnrollGenerated, setIsEnrollGenerated] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    if (newValue === 1 && !(isRegistered && isEnrolled)) return;
    if (newValue === 2 && !(isRegistered && isEnrolled)) return;
    if (newValue === 3 && !(isRegistered && isEnrolled)) return;

    setTabValue(newValue);
  };

  const navigate = useNavigate();

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const isEnrolled = userData.isEnrolled;
  const isRegistered = userData.isRegistered;

  const isProfessionalTabEnabled = isRegistered && isEnrolled;
  const isAcademicTabEnabled = isRegistered && isEnrolled;
  const isDocsTabEnabled = isRegistered && isEnrolled;

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("currentUser"));

    setIsEnrollGenerated(userData.IsEnrollGenerated);

    if (userData.courseName) {
      if (userData.isEnrolled === false) {
        swal({
          icon: "warning",
          title: "Enrollment form",
          text: "Please fill enrollment form first",
          buttons: {
            cancel: "Cancel",
            confirm: "OK",
          },
        }).then((value) => {
          if (value) {
            navigate("/student/Enrollement/Form");
          }
        });
      }
    }
  }, []);

  const handleDownloadClick = () => {
    if (userData.isEnrollPaid === true) {
      generatePDF();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: "Please Fill Enrollment Form First!!",
      });
    }
  };

  const Admision_slip = () => {
    // generatePDF1();
  };
  const shouldDisplaySection = (qualification1, qualification) => {
    const qualificationOrder = {
      "10th": 1,
      "12th": 2,
      ITI: 3,
      Diploma: 4,
      UG: 5,
      Graduation: 6,
      BSC: 7,
      PG: 8,
    };

    return (
      qualificationOrder[qualification1] <= qualificationOrder[qualification]
    );
  };

  const getDocumentsByQualification = () => {
    const qualification = userData?.qualification;

    switch (qualification) {
      case "PG":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "domicileCertificate",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "UG":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "domicileCertificate",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];

      case "12th":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "marksheet12th",
          "migrationCertificate",
          "transferCertificate",
          "domicileCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "10th":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "Diploma":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "diplomaMarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "Graduation":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "undergraduateCertificate",
          "diplomaMarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "BSC":
        return [
          "applicantPhoto",
          "aadharCard",
          "applicantSignature",
          "marksheet10th",
          "marksheet12th",
          "undergraduateCertificate",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      case "ITI":
        return [
          "applicantPhoto",
          "applicantSignature",
          "aadharCard",
          "marksheet10th",
          "ItImarksheet",
          "domicileCertificate",
          "migrationCertificate",
          "transferCertificate",
          "incomeCertificate",
          "otherCertificate",
        ];
      default:
        return [];
    }
  };

  const relevantDocuments = getDocumentsByQualification();
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };
  const generatePDF1 = async () => {
    const pdf = new jsPDF();
  
    // Header Section
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      115,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 115, 22, { align: "center" });
    pdf.text("Sehore (M.P.) Pin Code-466001", 115, 28, { align: "center" });
  
    // Adding Applicant Photo to the Header
    if (userData?.Documents?.applicantPhoto) {
      pdf.addImage(userData.Documents.applicantPhoto, "JPEG", 170, 10, 30, 30);
    }
  
    pdf.text(`ACADEMIC YEAR ${userData?.admissionSession || "N/A"}`, 115, 34, {
      align: "center",
    });
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(66, 31, 81, 11, "F");
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Enrollment Form", 105, 38, { align: "center" });
  
    // Student Details Table
    const detailsData = [
      ["Enrollment No.", userData?.enrollmentNumber || "Not Provided"],
      ["Admission Session", userData?.admissionSession || "Not Provided"],
      ["Student Type", userData?.StudentType || "Normal Admission"],
      ["Name", userData?.name || "Not Provided"],
      ["Father's Name", userData?.fathersname || "Not Provided"],
      ["Mother's Name", userData?.mothersname || "Not Provided"],
      ["ABC ID", userData?.AbcId || "Not Provided"],
      ["Gender", userData?.gender || "Not Provided"],
      ["Date of Birth", userData?.dob || "Not Provided"],
      ["Address", userData?.address?.address2 || "Not Provided"],
      ["Domicile", userData?.domicile || "Not Provided"],
      ["Nationality", userData?.nationality || "Not Provided"],
      ["Category", userData?.category || "Not Provided"],
      ["Mobile No.", userData?.mobile || "Not Provided"],
      ["Email", userData?.email || "Not Provided"],
      ["Course Name", userData?.courseName || "Not Provided"],
      ["Course Type", userData?.courseType || "Not Provided"],
      ["Specialization", userData?.courseBranch || "Not Provided"],
      ["Institute", userData?.assignedCollege || "Not Assigned"],
      [
        "Scholarship",
        userData?.professional?.ScholarshipRequired || "Not Eligible",
      ],
      ["Medium", userData?.professional?.Medium || "Not Provided"],
    ];
  
    pdf.autoTable({
      body: detailsData,
      startY: 50,
      margin: { left: 20 },
      styles: { fontSize: 9 },
      columnStyles: { 0: { cellWidth: 65 }, 1: { cellWidth: 120 } },
    });
  
    // Documents Table
    const documentsDetails = [
      ["S.No.", "Document Name", "Status"],
      [
        "1",
        "Applicant Photo",
        userData?.Documents?.applicantPhoto ? "Uploaded" : "Not Uploaded",
      ],
      [
        "2",
        "Signature",
        userData?.Documents?.applicantSignature ? "Uploaded" : "Not Uploaded",
      ],
      [
        "3",
        "10th Marksheet",
        userData?.Documents?.marksheet10th ? "Uploaded" : "Not Uploaded",
      ],
      [
        "4",
        "12th Marksheet",
        userData?.Documents?.marksheet12th ? "Uploaded" : "Not Uploaded",
      ],
      [
        "5",
        "Undergraduate Certificate",
        userData?.Documents?.undergraduateCertificate
          ? "Uploaded"
          : "Not Uploaded",
      ],
      [
        "6",
        "Domicile Certificate",
        userData?.Documents?.domicileCertificate ? "Uploaded" : "Not Uploaded",
      ],
      [
        "7",
        "Transfer Certificate",
        userData?.Documents?.transferCertificate ? "Uploaded" : "Not Uploaded",
      ],
      [
        "8",
        "Income Certificate",
        userData?.Documents?.incomeCertificate ? "Uploaded" : "Not Uploaded",
      ],
      [
        "9",
        "Migration Certificate",
        userData?.Documents?.migrationCertificate ? "Uploaded" : "Not Uploaded",
      ],
    ];
  
    const documentsStartY = pdf.lastAutoTable.finalY + 10;
    pdf.autoTable({
      head: [documentsDetails[0]],
      body: documentsDetails.slice(1),
      startY: documentsStartY,
      theme: "grid",
      styles: { fontSize: 8 },
      margin: { left: 20 },
      columnStyles: { 0: { cellWidth: 15 }, 1: { cellWidth: 80 } },
    });
  
    // Finalize and Save
    pdf.text("Date: ____________________", 20, pdf.lastAutoTable.finalY + 10);
    pdf.text(
      "Signature of Student: ____________________",
      100,
      pdf.lastAutoTable.finalY + 10
    );
    pdf.save(`${userData?.name || "Student"} Enrollment Form.pdf`);
  };
  
  


  const generatePDF = async () => {
    const pdf = new jsPDF();

    // Header
    pdf.addImage(logo, "JPEG", 8, 5, 20, 20);
    pdf.setTextColor(165, 42, 42);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      115,
      10,
      { align: "center" }
    );
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F),1956]",
      115,
      16,
      { align: "center" }
    );
    pdf.text("SH-18, Bhopal-Indore Road, Pachama,", 115, 22, {
      align: "center",
    });
    pdf.text("Sehore (M.P.) Pin Code-466001", 115, 28, { align: "center" });
    pdf.text(`ACADEMIC YEAR ${userData?.admissionSession}`, 115, 34, {
      align: "center",
    });
    pdf.setDrawColor(0);
    pdf.setFillColor(230, 230, 230);
    pdf.rect(66, 31, 81, 11, "F");
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "bold");
    pdf.setTextColor(0, 0, 0);
    pdf.text("Enrollment Form", 105, 38, { align: "center" });

    let y = 50;

    // Student Details Table
  const detailsData = [
    ["Enrollment No.", userData?.enrollmentNumber || ""],
    ["Admission session", userData?.admissionSession || ""],
    ["Student Type", userData?.StudentType || "Normal Admission"],
    ["Name", userData.name],
    ["Father's Name", userData.fathersname],
    ["Mother's Name", userData.mothersname],
    ["Gender", userData.gender],
    ["Date of Birth", formatDate(userData.dob)],
    ["Address", userData.address.address2],
    ["Domicile", userData.domicile],
    ["Category", userData.category],
    ["Mobile No.", userData.mobile],
    ["Email", userData.email],
    ["Course Name", userData.courseName],
    ["Course Type", userData.courseType],
    ["Specialization", userData.courseBranch],
    ["Institute", userData.assignedCollege || "Not assigned"],
    ["Scholarship", userData?.professional?.ScholarshipRequired || "Not Eligible"],
  ];
    pdf.autoTable({
      body: detailsData,
      startY: y,
      margin: { left: 20 },
      styles: { fontSize: 9, halign: "left" },
      columnStyles: { 0: { cellWidth: 65 }, 1: { cellWidth: 120 } },
    });

    // Academic Details Logic
    const academicDetails = [];
    if (userData?.qualification === "12th") {
      academicDetails.push(
        [
          "1",
          "High School",
          userData?.tenthBoard,
          userData?.tenthPassingYear,
          userData?.tenthMarksObtain,
          userData?.tenthMaxMarks,
          userData?.tenthPercentage,
        ],
        [
          "2",
          "Higher Secondary",
          userData?.twelfthBoard,
          userData?.twelfthPassingYear,
          userData?.twelfthMarksObtain,
          userData?.twelfthMaxMarks,
          userData?.twelfthPercentage,
        ]
      );
    } else if (userData?.qualification === "UG") {
      academicDetails.push(
        [
          "1",
          "High School",
          userData?.tenthBoard,
          userData?.tenthPassingYear,
          userData?.tenthMarksObtain,
          userData?.tenthMaxMarks,
          userData?.tenthPercentage,
        ],
        [
          "2",
          "Higher Secondary",
          userData?.twelfthBoard,
          userData?.twelfthPassingYear,
          userData?.twelfthMarksObtain,
          userData?.twelfthMaxMarks,
          userData?.twelfthPercentage,
        ],
        [
          "3",
          "Bachelor Degree",
          userData?.graduationUniversity,
          userData?.graduationPassingYear,
          userData?.graduationMarksObtain,
          userData?.graduationMaxMarks,
          userData?.graduationPercentage,
        ]
      );
    }

    // Adding Academic Details to the second page
    pdf.addPage();
    pdf.setFont("helvetica", "bold");
    pdf.text("Academic Details", 20, 30);
    pdf.autoTable({
      head: [
        [
          "S.No.",
          "Name of Exam",
          "Board/University",
          "Passing Year",
          "Obtain Marks",
          "Total Marks",
          "Percentage",
        ],
      ],
      body: academicDetails,
      startY: 35,
      styles: { fontSize: 8 },
      margin: { left: 20 },
    });

    const documents = [
      [
        "1",
        "Aadhar Card",
        userData?.Documents?.aadharCard ? "Submitted" : "Not Submitted",
      ],
      [
        "2",
        "10th Marksheet",
        userData?.Documents?.marksheet10th ? "Submitted" : "Not Submitted",
      ],
      [
        "3",
        "12th Marksheet",
        userData?.Documents?.marksheet12th ? "Submitted" : "Not Submitted",
      ],
      [
        "4",
        "Graduation Certificate",
        userData?.Documents?.graduationCertificate
          ? "Submitted"
          : "Not Submitted",
      ],
    ];

    pdf.text("Documents Submitted", 20, pdf.lastAutoTable.finalY + 10);
    pdf.autoTable({
      head: [["S.No.", "Document Name", "Status"]],
      body: documents,
      startY: pdf.lastAutoTable.finalY + 15,
      styles: { fontSize: 8 },
      margin: { left: 20 },
    });

    // Adding Footer
    const finalY = pdf.lastAutoTable.finalY + 20;
    pdf.text("Date: ____________________", 20, finalY);
    pdf.text("Signature: ____________________", 150, finalY);
    pdf.text("Certificate", 105, finalY + 10, { align: "center" });
    pdf.text(
      "Certified that the above student has been admitted to this institution through as per procedure laid down by regulatory authorities and the entries above have been checked and verified by me from the records. Therefore, I recommend that he/she should be enrolled as requested by the student.",
      20,
      finalY + 20
    );
    pdf.text("Date: ____________________", 20, finalY + 50);
    pdf.text("Signature of the Dean with Seal", 150, finalY + 50);

    // Save PDF
    pdf.save(`${userData?.name} Enrollment Form.pdf`);
  };

  


  return (
    <>
      <style>
        {`

         @import url("https://fonts.googleapis.com/css?family=Josefin+Sans|Mountains+of+Christmas&display=swap");
 

.table th, .table td {
font-size: 14px;
padding: 2px 5px;
color: #000;
}
.theadd{

                                  color: #2E236C;
                                  font-size: 15px;
                                  font-weight: bold;
                             }
.carad{
 height: 70%;
  border-radius: 20px;
   margin-left: 60px;
    margin-top: 50px;
    }




    .info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.info-row strong {
  flex: 0 0 40%; /* Adjust the width as needed */
}

.info-row span {
  flex: 1;
  text-align: left;
}
.tabu{
margin-top: 25px;
 margin-left: 20px;
 }
    .diss{
display: flex;
 gap: 30px;
 position: sticky;
  top: 550px;
 margin-top: 10px;
  margin-left: 60px;
  }
          .student-profile .card {
            border-radius: 10px;
            height:80px;
            width:80px;
          }

          .infoo{
          margin-left:20px;
          font-size:15px}

.card {
              width: 80%;
              margin-Top:40px;
              height:auto;
           }

          .student-profile .card h3 {
            font-size: 20px;
            font-weight: 700;
          }

          .student-profile .card p {
            font-size: 26px;
            color: #000;
          }


          .strong{
    font-size:15px;
    margin-left:-8px
    }
.boox{

            margin-top: 3%;
            // background-color: #b0c4b1;
            box-shadow: 2px solid black;
            margin-left: 30px;
          }



          .profile_img{
       
                      width: 120px;
                      height: 120px;
                      object-fit: cover;
                      border-radius: 50%;
                    }
          .student-profile .table th,
          .student-profile .table td {
            font-size: 40px;
            padding: 0px 10px;
            color: #000;
          }

          @media (max-width: 800px) {
            .card {
              width: 100%;
              margin-Top:75px;
            }
          }

       

          @media screen and (max-width: 480px) {
       
     
         .card {
              width: 80%;
              margin-Top:40px;
           }

    .diss{
display: flex;
 gap: 30px;

 position:sticky;
 
 
 margin-top: 50px;
  margin-left:-2px;
  }
       
  .tabu{
margin-top: 25px;
 margin-left: -30px;
 }
 
   .profile_img{
       
                      // width: 80px;
                      // height: 80px;
                      // object-fit: cover;
                      // border-radius: 50%;
                      display:none
                    }
 
  .student-profile .table th,
          .student-profile .table td {
            font-size: 25px;
            padding: 0px 5px;
            color: #000;
          }




             .card-body{
             margin-left:10px}

        .carad{
 height: auto;
 width:auto;
  border-radius: 20px;
    // margin-left: -5px;
    margin-left:-40px;
    margin-top: 50px;
    }
       
     .card-header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .card-header img {
      margin-right: 10px;
      margin-right: auto; /* Pushes the image to the right */
    }
    .card-header h3 {
      margin-top: -50px;
      text-align: right;
     
    }    
       
       . table-bordered{
       margin-left:-10px}
          .bunnn{
          height:30px;
          width:160px;
           margin-top:-20px;
            font-size: 9px; /* Reduce the font size for smaller screens */
    padding: 5px 10px; /* Optionally adjust padding for a better fit */
          }
  .MuiTab-root {
    width: 80%; /* Make each tab take up the full width */
 
    font-size: 11px; /* Reduce the font size */
    padding: 0px ; /* Adjust padding to match the smaller text */

  }

  .MuiTabs-flexContainer {
    flex-direction: row; /* Stack tabs vertically */
  }

  .MuiTabs-root {
    width: 100%; /* Ensure the Tabs container takes up the full width */
  }
}

         
        `}
      </style>

      <ThemeProvider theme={theme}>
        <StudentDashboard />
        <Box className="boox">
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid item xs={12} sm={5}>
              <div
                class="card shadow-lg carad"
                style={{
                  position: "sticky",
                  top: "70px",
                  zIndex: 10,
                }}
              >
                <div
                  className="card-header bg-transparent text-right"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="profile_img"
                    src={userData?.Documents?.applicantPhoto}
                    alt="img"
                    style={{ marginRight: "10px" }}
                  />
                  <h3 style={{ color: "#921A40", margin: 0 }}>
                    {userData?.name}
                  </h3>
                </div>

                <div class="card-body">
                  <div class="info-row">
                    <strong className="strong">Admission Session:</strong>
                    <span className="infoo">{userData?.admissionSession}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Student Type:</strong>
                    <span className="infoo">
                      {userData?.StudentType || "Normal Admission"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Course Type:</strong>
                    <span className="infoo">{userData?.courseType}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Course Name:</strong>
                    <span className="infoo">{userData?.courseName}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Branch:</strong>
                    <span className="infoo">{userData?.courseBranch}</span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Fee Status:</strong>
                    <span
                      className="infoo"
                      style={{ color: userData?.isPaid ? "green" : "red" }}
                    >
                      {userData?.isPaid ? "Paid" : "Not Paid"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Enrollment Form Status:</strong>
                    <span
                      className="infoo"
                      style={{ color: userData?.isEnrolled ? "green" : "red" }}
                    >
                      {userData?.isEnrolled ? "Filled" : "Pending"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">
                      Enrollment Form Fee Status:
                    </strong>
                    <span
                      className="infoo"
                      style={{
                        color: userData?.isEnrollPaid ? "green" : "red",
                      }}
                    >
                      {userData?.isEnrollPaid ? "Paid" : "Pending"}
                    </span>
                  </div>
                  <div class="info-row">
                    <strong className="strong">Enrollment Number</strong>
                    <span
                      className="infoo"
                      style={{
                        color: "red",
                      }}
                    >
                      {userData?.enrollmentNumber || "Not Generated"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="diss">
                <Button
                  className="bunnn"
                  style={{ backgroundColor: "#405D72" }}
                  variant="contained"
                  color="success"
                  // onClick={handleDownloadClick}
                  disabled={true}
                >
                  Enrollment Details
                  <span style={{ marginLeft: "8px" }}>
                    <i className="fa-solid fa-download"></i>
                  </span>
                </Button>
                <Button
                  className="bunnn"
                  style={{ backgroundColor: "#405D72" }}
                  variant="contained"
                  color="success"
                  disabled={true}
                  onClick={Admision_slip}
                >
                  Admission Slip
                  <span style={{ marginLeft: "8px" }}>
                    <i className="fa-solid fa-download"></i>
                  </span>
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Box className="tabu">
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Personal"
                        : "Personal Information"
                    }
                  />
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Professional"
                        : "Professional Information"
                    }
                  />
                  <Tab
                    label={
                      window.innerWidth <= 480
                        ? "Academic"
                        : "Academic Information"
                    }
                  />
                  <Tab
                    label={window.innerWidth <= 480 ? "Docs" : "Documents"}
                  />
                </Tabs>

                {tabValue === 0 && (
                  <Box p={1}>
                    <hr></hr>

                    <div className="card-body pt-0">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Gender
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.gender}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Category
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.category}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Father's Name
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.fathersname}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Mother's Name
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.mothersname}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Contact
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.mobile}</td>
                          </tr>{" "}
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Nationality
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.nationality || "Not Uploaded"}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              DOB
                            </th>
                            <td width="2%">:</td>
                            <td>
                              <DatePicker
                                dateFormat="dd/MM/yyyy"
                                selected={
                                  userData?.dob ? new Date(userData?.dob) : null
                                }
                                readOnly
                              />
                            </td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Email
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.email}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Domicile
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.domicile || "Not Uploaded"}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Exam Type
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.lastExamType}</td>
                          </tr>
                          <tr>
                            <th
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Scholarship Required
                            </th>

                            <td width="2%">:</td>

                            <td>
                              {userData?.professional?.ScholarshipRequired
                                ? userData.professional.ScholarshipRequired
                                : "Not Eligible"}
                            </td>
                          </tr>{" "}
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Qualification
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.qualification}</td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Percentage
                            </th>
                            <td width="2%">:</td>
                            <td>
                              {userData?.qualificationPercentage ||
                                userData?.LastPercentage ||
                                "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Passed Subject
                            </th>
                            <td width="2%">:</td>
                            <td>
                              {userData?.lastPassedSubject || "Not Uploaded"}
                            </td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              Last Passing Year
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.passingYear}</td>
                          </tr>
                          <tr>
                            <th
                              width="30%"
                              style={{
                                color: "#16423C",
                                fontSize: "15px",
                                fontWeight: 700,
                              }}
                            >
                              ABC Id
                            </th>
                            <td width="2%">:</td>
                            <td>{userData?.AbcId || "Not Available"}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Box>
                )}

                {tabValue === 1 && isProfessionalTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0 ">
                      <hr style={{ marginTop: "-15px" }}></hr>

                      <div className="card-body pt-0 ">
                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th
                                width="50%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Handicapped
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.Handicapped}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Medium
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.Medium}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Aadhar Number
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.AadharNumber}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Samagra ID
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.SamagraId
                                  ? userData.professional.SamagraId
                                  : "Not Available"}
                              </td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Father's Occupation
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.FathersOccupation}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Mother's Occupation
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.professional?.MothersOccupation}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Father's Income
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.FathersIncome}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Mother's Income
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.MothersIncome}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Parent Mobile
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.professional?.ParentMobile}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Current Address{" "}
                              </th>
                              <td width="2%">:</td>
                              <td>
                                {userData?.address?.address1
                                  ? userData?.address?.address1
                                  : "Not Fill"}
                              </td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Permanent Address{" "}
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.address2}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Country
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.country}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                State
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.state}</td>
                            </tr>

                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                District
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.district}</td>
                            </tr>
                            <tr>
                              <th
                                width="30%"
                                style={{
                                  color: "#16423C",
                                  fontSize: "15px",
                                  fontWeight: 700,
                                }}
                              >
                                Pin Code
                              </th>
                              <td width="2%">:</td>
                              <td>{userData?.address?.pinCode}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                )}

                {tabValue === 2 && isAcademicTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0">
                      <hr style={{ marginTop: "-10px" }}></hr>
                      <div className="card-body pt-0">
                        <table className="table table-bordered">
                          {shouldDisplaySection(
                            "10th",
                            userData?.qualification
                          ) && (
                            <>
                              <th style={{ fontSize: "20px" }}>
                                <b>10th</b>
                              </th>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  School
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthSchool}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Board
                                </th>
                                <td width="2%">:</td>
                                <td>{userData?.academicDetails?.tenthBoard}</td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthPassingYear}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthMaxMarks}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthMarksObtain}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthPercentage}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.tenthRollNo}
                                </td>
                              </tr>
                              <br></br>

                              {/* Add other 10th fields here */}
                            </>
                          )}

                          {shouldDisplaySection(
                            "12th" || "Diploma" || "ITI",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>
                                  {" "}
                                  {userData?.qualification === "ITI"
                                    ? "ITI"
                                    : "12th"}
                                </b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  School
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthSchool}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Board
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthBoard}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {
                                    userData?.academicDetails
                                      ?.twelfthPassingYear
                                  }
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthMaxMarks}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {
                                    userData?.academicDetails
                                      ?.twelfthMarksObtain
                                  }
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthPercentage}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails?.twelfthRollNo}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}

                          {shouldDisplaySection(
                            "UG",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>Graduation</b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  College
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationCollege || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  University
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationUniversity || "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationPassingYear || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationMaxMarks || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationMarksObtain || "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationPercentage || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.graduationRollNo || "Not Uploaded"}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}

                          {shouldDisplaySection(
                            "PG",
                            userData?.qualification
                          ) && (
                            <>
                              <tr style={{ fontSize: "20px" }}>
                                <b>Post Graduation</b>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  College
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationCollege || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  University
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationUniversity ||
                                    "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Passing Year
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationPassingYear ||
                                    "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Max. Marks
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationMaxMarks || "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Marks Obtained
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationMarksObtain ||
                                    "Not Uploaded"}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Percentage
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationPercentage ||
                                    "Not Uploaded"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  width="30%"
                                  style={{
                                    color: "#16423C",
                                    fontSize: "15px",
                                    fontWeight: 700,
                                  }}
                                >
                                  Roll No
                                </th>
                                <td width="2%">:</td>
                                <td>
                                  {userData?.academicDetails
                                    ?.postGraduationRollNo || "Not Uploaded"}
                                </td>
                              </tr>
                              <br></br>
                            </>
                          )}
                        </table>
                      </div>
                    </div>
                  </Box>
                )}

                {tabValue === 3 && isDocsTabEnabled && (
                  <Box p={3}>
                    <div className="card-header bg-transparent border-0 ">
                      <hr />

                      <div class="card-body pt-0">
                        <table className="table table-bordered">
                          <tbody>
                            {relevantDocuments.includes("applicantPhoto") && (
                              <tr>
                                <th>Applicant Photo</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.applicantPhoto ? (
                                    <a
                                      href={userData.Documents.applicantPhoto}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Photo
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "applicantSignature"
                            ) && (
                              <tr>
                                <th>Applicant Signature</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.applicantSignature ? (
                                    <a
                                      href={
                                        userData.Documents.applicantSignature
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Signature
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("marksheet10th") && (
                              <tr>
                                <th>10th Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.marksheet10th ? (
                                    <a
                                      href={userData.Documents.marksheet10th}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("marksheet12th") && (
                              <tr>
                                <th>12th Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.marksheet12th ? (
                                    <a
                                      href={userData.Documents.marksheet12th}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "undergraduateCertificate"
                            ) && (
                              <tr>
                                <th>Undergraduate Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents
                                    ?.undergraduateCertificate ? (
                                    <a
                                      href={
                                        userData.Documents
                                          .undergraduateCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("ItImarksheet") && (
                              <tr>
                                <th>ITI Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.ItImarksheet ? (
                                    <a
                                      href={userData.Documents.ItImarksheet}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("diplomaMarksheet") && (
                              <tr>
                                <th>Diploma Marksheet</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.diplomaMarksheet ? (
                                    <a
                                      href={userData.Documents.diplomaMarksheet}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}

                            {relevantDocuments.includes("aadharCard") && (
                              <tr>
                                <th>Aadhar Card</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.aadharCard ? (
                                    <a
                                      href={userData.Documents.aadharCard}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Aadhar
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "migrationCertificate"
                            ) && (
                              <tr>
                                <th>Migration</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.migrationCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.migrationCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Migration
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "domicileCertificate"
                            ) && (
                              <tr>
                                <th>Domicile Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.domicileCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.domicileCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "transferCertificate"
                            ) && (
                              <tr>
                                <th>Transfer Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.transferCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.transferCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Marksheet
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes(
                              "incomeCertificate"
                            ) && (
                              <tr>
                                <th>Income Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.incomeCertificate ? (
                                    <a
                                      href={
                                        userData.Documents.incomeCertificate
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                            {relevantDocuments.includes("otherCertificate") && (
                              <tr>
                                <th>Other Certificate</th>
                                <td>:</td>
                                <td>
                                  {userData?.Documents?.otherCertificate ? (
                                    <a
                                      href={userData.Documents.otherCertificate}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      View Certificate
                                    </a>
                                  ) : (
                                    "Not Uploaded"
                                  )}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default StudentAllDetail;
