import React, { useEffect, useRef } from 'react';
import library from '../../images/libr.webp';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


const VisionMission = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = sectionRef.current;
      if (element && window.scrollY > element.offsetTop - window.innerHeight / 2) {
        element.classList.add('fadeInRight-animation');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <style>
        {`
      .image-2-icon {
        position: absolute;
        top: 86px;
        left: 748px;
        width: 3017px;
        height: 1583px;
        object-fit: cover;
      }
      .log-in {
        width: 100%;
        height: 2095px;
        position: relative;
        background-color: #fff;
        overflow: hidden;
        letter-spacing: normal;
      }
      
   .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 10px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
  
      `}
      </style>


      <Nav />
      {/* <div style={{ position: 'relative', height: '300px', width: '100%' }}>
        <img src={library} style={{ width: '100%', height: '300px', filter: 'brightness(50%)' }} alt="Library" />
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          pointerEvents: 'none',
        }}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              padding: '10px 20px',
              fontSize: '20px',
              backgroundColor: '#780000',
              color: 'white',
              border: '1px solid #ccc',
              borderRadius: '5px',
              cursor: 'pointer',
              fontWeight: 'bold'
            }}
          >
            Vision & Mission
          </div>
        </div>
      </div> */}

      <section className="section_all " id="about" >
        <div className="container">
          <div class="two" style={{ alignItems: 'center' }}>
            <h1>VISION
            </h1>
          </div>


          <div className='row' >
            <div className="col-lg-9">


              <p className="mt-3" style={{ textAlign: 'justify', color: 'black', fontFamily: "'poppins',sansSerif" }}>
                "To emerge as World’s one of the finest Universities in the field of Higher, Technical  and Medical Education to develop Professionals who are Technically competent, ethically sensitive and  environment friendly, for the betterment of society."


              </p>  <br></br>  <br></br>
              <div class="two">
                <h1>MISSION
                </h1>
              </div>





              <p className="mt-3" style={{ textAlign: 'justify', color: 'black', fontFamily: "'poppins',sansSerif" }}>
                "Accomplish stimulating learning environment for students through quality teaching, research and outreach activity by providing state of the art facilities, industry exposure and guidance of dedicated faculty."


              </p>
            </div>







            <div className="col-lg-3">

              <ul className='list-icon' >
                <li className='lli'>
                  <Link to='/History_Milestones'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>About</Link></li>
                <li className='lli'>
                  <Link to='/institutes'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes</Link></li>
                <li className='lli'>
                  <Link to='/promotingsociety'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting Society</Link></li>
                <li className='lli'>
                  <Link to='/vision-mission'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision & Mission</Link></li>
                <li className='lli'>
                  <Link to='/core-approvalsvalues'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values</Link></li>
                <li className='lli'>   <Link to='/best-practices'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Best Practices</Link></li>
              </ul>
            </div></div>

        </div>
      </section>
      <Footerpage />

    </>
  );
};

export default VisionMission;



