import React, { useState } from "react";
import { Form } from "react-bootstrap";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import HodDashboard from "./HodDashboard";

const theme = createTheme({
  typography: { fontWeightBold: 700 },
});

 export default function  HodMainDetails (){
  const [selectedMainOption, setSelectedMainOption] = useState("");
  const [selectedStudentListOption, setSelectedStudentListOption] =
    useState("");
  const [showForm, setShowForm] = useState(true);
  const navigate = useNavigate();

  const handleMainOptionChange = (event) => {
    setSelectedMainOption(event.target.value);
  };

  const handleStudentListOptionChange = (event) => {
    setSelectedStudentListOption(event.target.value);
  };

  const handleSearchDetails = () => {
    setShowForm(false);
    if (selectedMainOption === "E-Pravesh") {
      navigate(
        selectedStudentListOption === "Pending Student List"
          ? "/Epravesh/pending/student"
          : "/Epravesh/verified/student"
      );
    } else if (selectedMainOption === "Student Registration") {
      navigate(
        selectedStudentListOption === "Pending Student List"
          ? "/studentpending"
          : "/studentverify"
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HodDashboard />
      {showForm && (
        <div
          className="shadow p-3 bg-body rounded"
          style={{
            backgroundColor: "#e8e5d5",
            width: "90%",
            marginLeft: "90px",
            marginRight: "auto",
            marginTop: "100px",
            maxWidth: "350px",
          }}
        >
          <div
            style={{
              backgroundImage: "linear-gradient(#1d2671, #C33764)",
              color: "#ffff",
              borderRadius: "7px",
              width: "300px",
            }}
          >
            <h5 style={{ padding: "10px" }}>Registration Verification</h5>
          </div>

          <div
            className="shadow p-3 mb-3 bg-body rounded"
            style={{ backgroundColor: "#e8e5d5", marginTop: "20px" }}
          >
            <RadioGroup
              value={selectedMainOption}
              onChange={handleMainOptionChange}
            >
              <FormControlLabel
                value="E-Pravesh"
                control={<Radio />}
                label="E-Pravesh"
              />
              <FormControlLabel
                value="Student Registration"
                control={<Radio />}
                label="Student Registration"
              />
            </RadioGroup>

            <h5>
              <b>Admission Session</b>
            </h5>
            <Form.Group className="mb-3">
              {/* If you need admissionSession to display something here, uncomment and set accordingly */}
              {/* <b>{admissionSession}</b> */}
            </Form.Group>

            <RadioGroup
              value={selectedStudentListOption}
              onChange={handleStudentListOptionChange}
            >
              <FormControlLabel
                value="Pending Student List"
                control={<Radio />}
                label="Check Pending Student List"
              />
              <FormControlLabel
                value="Verified Student List"
                control={<Radio />}
                label="Check Verified Student List"
              />
            </RadioGroup>

            <Button
            variant="contained"
              size="small"
              style={{ padding: "10px" }}
              onClick={handleSearchDetails}
            >
              Search Details
            </Button>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};


