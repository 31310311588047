import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import axios from "axios";
import SearchPen from "./SearchPen";

const ExamForm_Creation_Search = () => {
  const [session, setSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);

  const handleSearch = () => {
    if (!session || !courseType || !course || !branch) {
      alert("please fill all fields first");
      return;
    }
    setShowTable(true);
  };

  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses2")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };

  return (
    <>
      <AdminDashboard />
      <style>
        {`

      .custom-dropdown select {
     font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: black; /* default color for the select element */
  }

  .custom-dropdown option {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight:700
  }
             .shadow-box {
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
         border-radius: 10px;
         padding: 20px;
         margin-top: 70px;
         width: 100%; /* Adjusted width */
         margin-left:130px;
         height:170px;
         width:1180px
        
      }
         
  
            .header {
        height: 50px;
        // background: linear-gradient(#004e92, #990033);
        border-radius: 10px;
        color: #003049;
        font-weight:700;
        display: flex;
        margin-top:-50px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        
      }`}
      </style>

      <div
        className="shadow-box"
        fluid
        style={{ paddingTop: "50px", height: "220px" }}
      >
        <div className="header">Exam Form Creation Between Sem </div>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setSession(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>Admission Year</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourseType(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>Course Type Code</option>
                {getCourseTypes().map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourse(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>Course Name</option>
                {getCourses().map((courseName, index) => (
                  <option key={index} value={courseName}>
                    {courseName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setBranch(e.target.value)}
                autoFocus={true}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>Branch</option>
                {getBranches().map((branchName, index) => (
                  <option key={index} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setSession(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>From Semester</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourseType(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option selected>To Semester</option>
                {getCourseTypes().map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="includeDestination"
                name="includeDestination"
                color="green"
                //   onChange={(e) => setIncludeDestination(e.target.checked)}
              />
              <label htmlFor="includeDestination" style={{ marginLeft: "5px" }}>
                Include Destination Exam Session
              </label>
            </div>
            <div className="custom-dropdown" style={{ marginTop: "2px" }}>
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourse(e.target.value)}
                style={{ width: "250px", height: "35px" }}
              >
                <option value="" selected disabled></option>
                {getCourses().map((courseName, index) => (
                  <option key={index} value={courseName}>
                    {courseName}
                  </option>
                ))}
              </select>
            </div>
          </Col>

          <Col>
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="includeDestination"
                name="includeDestination"
                color="green"
                //   onChange={(e) => setIncludeDestination(e.target.checked)}
              />
              <label htmlFor="includeDestination" style={{ marginLeft: "5px" }}>
                Show All Form
              </label>
            </div>
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="includeDestination"
                name="includeDestination"
                color="green"
                //   onChange={(e) => setIncludeDestination(e.target.checked)}
              />
              <label htmlFor="includeDestination" style={{ marginLeft: "5px" }}>
                Show Last Year Form
              </label>
            </div>
            <div className="custom-checkbox">
              <input
                type="checkbox"
                id="includeDestination"
                name="includeDestination"
                color="green"
                //   onChange={(e) => setIncludeDestination(e.target.checked)}
              />
              <label htmlFor="includeDestination" style={{ marginLeft: "5px" }}>
                Don't Show Form
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              style={{
                backgroundColor: "#001d3d",
                height: "35px",
                padding: "5px",
              }}
              onClick={handleSearch}
            >
              View Student List
            </Button>
          </Col>
          <Col>
            <Button
              style={{
                backgroundColor: "#001d3d",
                height: "35px",
                padding: "5px",
                marginLeft: "-80%",
              }}
              onClick={handleSearch}
            >
              Create Examination Form
            </Button>
          </Col>
        </Row>
      </div>

      <div style={{ marginTop: "-50px", width: "95%", marginLeft: "30px" }}>
        {showTable && (
          <div>
            <SearchPen
              session={session}
              courseType={courseType}
              courseName={course}
              branch={branch}
              college={college}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ExamForm_Creation_Search;
