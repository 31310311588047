import React from 'react';
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from 'react-router-dom';


function Antiragging() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
    
.contentleader
{
text-align:justify;
width:100%;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */}
   
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        // margin-left:60px;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}>  Anti Ragging</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-8 padding-section">

            <br />

            <h5 style={{ color: 'orange', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>ANTI-RAGGING CELL</h5>






            <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif",marginTop:'-10px' ,marginLeft:'10px'}}
              >
               
               The Administration believes that Ragging is a social evil and has adopted “Zero Tolerance” regarding the same. The campus has one central anti-ragging committee and each constituent college has independent committees. These committees are headed by Head of Institutions, male and female faculty members and student representatives from each class. Regular meetings are called for monitoring. Complaint boxes are available in all Colleges, which are maintained regularly. Similar anti- ragging committees are functioning in Hostels. Senior and Junior Students are also counselled time to time regarding evils of ragging. Surprise checks are conducted by Anti- ragging committees. Multi-colour flexes in hindi are displayed in Campus, at prominent places for creating awareness regarding evils of Ragging.
           
           <br></br>  <br></br>
           During admissions all admitted regular students and their parents/ guardians have to submit affidavit subscribed by Honourable Supreme Court of India.
           
           
              </div>
            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>





            <table style={{ width: '80%',marginLeft:'70px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody><tr> <th>S.No</th> <th>ANTI-RAGGING COMMITTEE</th> <th>DESIGNATION</th>
             </tr>

             <tr>
              <td>1</td>
              <td>Dr. Dheeraj Shinde</td>
              <td>Dean/Professor</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Dr. Shahab Ahmed</td>
              <td>Dean/Professor</td>
            </tr>
            <tr>
              <td>3</td>
              <td> Mr. Ghanshyam Singh</td>
              <td> Parents of M.Tech Student</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Mr. Mahesh Verma</td>
              <td> Parents of B.Pharma Student</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Mr. Ashish Mishra</td>
              <td> Local Media Person</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Town Inspector, Sehore</td>
              <td> or his nominee</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Mr. Ankit Navgeet Joshi </td>
              <td> Representative of Students</td>
            </tr>

            <tr>
              <td>8</td>
              <td>Ms. Nuzhat Parveen</td>
              <td>Librarian (Non-Teaching)</td>
            </tr>

            <tr>
              <td>9</td>
              <td>Mr. C.S.Verma</td>
              <td>Asstt.Registrar (Non Teaching)</td>{" "}
            </tr>




             
              </tbody>
                          </table>
                     
                       
<br></br><br></br>


<h4
        style={{
          textTransform: "uppercase",
          textAlign: "center",
          fontFamily: "'PT Sans', sans-serif",
          fontWeight: 700,
          fontStyle: "normal",
          color: "#740938",
        }}
      >
        {" "}
        Anti-ragging Squad
      </h4>

    
      <table style={{ width: '80%',marginLeft:'70px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody><tr> <th>S.No</th> <th>ANTI-RAGGING SQUAD</th> <th>DESIGNATION</th>
             </tr>
         
    
            <tr>
              <td>1</td>
              <td>Ms. Shefali</td>
              <td>MCA Department</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Mr. Narendra Patel</td>
              <td> Pharmacy Department</td>
            </tr>
            <tr>
              <td>3</td>
              <td> Dr. Rajesh Sharma </td>
              <td> MBA Department</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Dr. Dhiraj Shinde</td>
              <td> Education Department</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Dr. Shahab Ahmad</td>
              <td> Hotel Mgmt. Department</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Mr. Veerbal Kushwaha</td>
              <td> Agriculture Department</td>
            </tr>
            <tr>
              <td>7</td>
              <td>Dr. Susan Thomas </td>
              <td> Homeopathy College</td>
            </tr>

            <tr>
              <td>8</td>
              <td>Mr. Pradeep Maheshwari</td>
              <td>Commerce Department</td>
            </tr>

            <tr>
              <td>9</td>
              <td>Dr. Abhilasha Pathak</td>
              <td>Sociology Department</td>{" "}
            </tr>

            <tr>
              <td>10</td>
              <td>Mr. Shailendra Thakur</td>
              <td>Engineering Department</td>
            </tr>
          </tbody>
        </table>
    
     

         
     
              </div>  
              <div className="col-lg-4" >

<ul className='list-icon' >
  <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>Committee</h4>
  <br></br>  <li className='lli'>
    <Link to ='/anti-ragging'>
      <i class="fa fa-adjust" aria-hidden="true"></i>Anti Ragging</Link></li>
  <li className='lli'>
    <Link to ='/proctorial-board'>
      <i class="fa fa-adjust" aria-hidden="true"></i>Proctorial Board</Link></li>
  <li className='lli'>
    <Link to ='/internal-complaint'>
      <i class="fa fa-adjust" aria-hidden="true"></i>Internal Complaint</Link></li>
  <li className='lli'>
    <Link to ='/grievance-redressal'>
      <i class="fa fa-adjust" aria-hidden="true"></i>Grievance Redressal</Link></li>

  <li className='lli'>
    <Link to ='/sc-st'>
      <i class="fa fa-adjust" aria-hidden="true"></i>For SC-ST</Link></li>

<li className='lli'>
    <Link to ='/edc'>
      <i class="fa fa-adjust" aria-hidden="true"></i>EDC</Link></li>

  <li className='lli'>
    <Link to ='/international-higher-education-cell'>
      <i class="fa fa-adjust" aria-hidden="true"></i>International Higher Education Cell</Link></li>

    


</ul>
</div>
              
                </div>



               
                </div>

     
      <br></br>
    
      <Footerpage /></>
  )
}

export default Antiragging