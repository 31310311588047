import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import { TableCell, Box, CardContent } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Table from "react-bootstrap/Table";
import { Button } from "@mui/material";
import axios from "axios";
import Sidebar from "../../AdminComponent/HodDashboard";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "react-bootstrap/Spinner";
import * as XLSX from "xlsx";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const E_approve = () => {
  const [approvedStudents, setApprovedStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));

  const navigate = useNavigate();
  const branch1 = userData?.Branch || "";
  const course1 = userData?.courseName || "";
  const college = userData?.college || "";
  const associate = userData?.Associated_branch;

  const fetchApprovedStudents = async (branch = branch1, course = course1) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/admin/v1/epravesh/approve?branch=${branch}&course=${course}&college=${college}`
      );

      setApprovedStudents(response.data.students);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // ///////////////////pagination///////////////////////////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // /////////////////////////////////name sort/////////////////////////
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const filteredStudents = approvedStudents.filter((student) => {
    const studentName = student.name || ""; // Ensure name is a string
    const studentCollege = student.assignedCollege || ""; // Ensure assignedCollege is a string
    return (
      student.assignedCollege|| "Not assigned".toLowerCase().includes(searchQuery.toLowerCase()) ||
      student.name.toLowerCase().includes(searchQuery.toLowerCase())||
      student.StudentType.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (sortBy === "name") {
      const nameA = a.name || ""; // Ensure name is a string
      const nameB = b.name || ""; // Ensure name is a string
      return sortOrder === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    } else if (sortBy === "assignedCollege") {
      const collegeA = a.assignedCollege || ""; // Ensure assignedCollege is a string
      const collegeB = b.assignedCollege || ""; // Ensure assignedCollege is a string
      return sortOrder === "asc"
        ? collegeA.localeCompare(collegeB)
        : collegeB.localeCompare(collegeA);
    }
    return 0;
  });

  /////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    fetchApprovedStudents();
  }, []);

  /////////////////////datepicker/////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleButtonClick = (id, enrolled) => {

    if (!enrolled) {
      // swal({
      //   title: "Error",
      //   text: "student is not enrolled So here is no any full details!",
      //   icon: "",
      //   buttons: "OK",
      // });
      swal({
        icon: "info",
        title: "Details not found",
        text: "student is not enrolled So here is no any full details!",
        buttons: "OK",
      });
    } else {
      navigate(`/studentverifieddetailed/${id}`);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  ////////////////////////
  // Handle course dropdown change
  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);

    // Filter branches based on the selected course name
    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];

    setBranches(filteredBranches); // Update branches based on selected course

  };

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);
  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value); // Set selected branch
  };
  const handleSearch = () => {
    fetchApprovedStudents(selectedBranch, selectedCourse);
  };
  ////////////////////////Export to Excel//////////////////////////////////

  const Excel = approvedStudents.map((student) => ({
    Student_ID: student.randomId,
    Student_Password: student.randomPassword,
    Admitted_Date: new Date(student.createdAt).toLocaleDateString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    }),
    Name: student.name,
    Fathers_Name: student.fathersname,
    Mothers_Name: student.mothersname,
    Email: student.email,
    Mobile: student.mobile,
    Course_Type: student.courseType,
    Course: student.courseName,
    Branch: student.courseBranch,
    college: student.college,
    // applicantPhoto:student.Documents.applicantPhoto
  }));

  const ExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(Excel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
    XLSX.writeFile(workbook, "Student_Data.xlsx");
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <Sidebar />

        <style>
          {`
        .conttable{

            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;
         
        }


        .login-input{
        width:200px;
        margin-bottom:20px;
        height:35px;}
        @media screen and (max-width: 500px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

          h4{
            margin-top:90px;
          }
        }

    `}
        </style>

        <div className="conttable shadow p-3 bg-body rounded">
          <h4 style={{ fontFamily: "fantasy" }}> VERIFIED STUDENT</h4>
          <Button
            type="button"
            style={{
              marginLeft: "5px",
              marginTop: "-10px",
              width: "140px",
              padding: "2px",
              height: "35px",

              color: "red",
              float: "right",
            }}
            variant="outlined"
            onClick={ExportToExcel}
          >
            Export Data
          </Button>

          <br />
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-left"
              style={{ minHeight: "200px", marginTop: "40px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error || approvedStudents.length === 0 ? (
            <CardContent>
              <Paper sx={{ width: "100%", overflowX: "auto" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedCourse}
                    onChange={handleChangeCourse} // Update the selected course
                  >
                    <option value="">Select Course</option>
                    {associate.map((course, index) => (
                      <option key={index} value={course.courseName}>
                        {course.courseName}
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedBranch}
                    onChange={handleChangeBranch} // Update the selected branch
                    disabled={!selectedCourse} // Disable until a course is selected
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>

                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "-15px",
                      width: "90px",
                      padding: "2px",
                      height: "35px",
                      backgroundColor: "#023047",
                      color: "white",
                    }}
                    onClick={handleSearch}
                    disabled={!selectedBranch}
                  >
                    Submit
                  </Button>
                </div>

                <TableContainer sx={{ maxHeight: "440px", overflowX: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            S.No.
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "80px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Session
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "120px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Type
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Student Name
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Email
                          </h5>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            DOB
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "80px",
                              fontWeight: "bold",
                            }}
                          >
                            Father's Name
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "80px",
                              fontWeight: "bold",
                            }}
                          >
                            Mother's Name
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Contact
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "100px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Nationality
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "150px",
                              fontWeight: "bold",
                            }}
                          >
                            Qualification
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Qualification %
                          </h5>
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "150px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Type
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Name
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "300px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Branch
                          </h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          align="center"
                          style={{
                            padding: "50px",
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          NO DATA FOUND
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          ) : (
            <CardContent>
              <Paper sx={{ width: "100%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedCourse}
                    onChange={handleChangeCourse}
                  >
                    <option value="">Select Course</option>
                    {associate.map((course, index) => (
                      <option key={index} value={course.courseName}>
                        {course.courseName}
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedBranch}
                    onChange={handleChangeBranch} // Update the selected branch
                    disabled={!selectedCourse} // Disable until a course is selected
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>

                  {/* Submit Button */}
                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "-15px",
                      width: "90px",
                      padding: "2px",
                      height: "35px",
                      backgroundColor: "#023047",
                      color: "white",
                    }}
                    onClick={handleSearch}
                    disabled={!selectedBranch} // Disable search button until branch is selected
                  >
                    Submit
                  </Button>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      width: "30%",
                      marginLeft: "28%",
                    }}
                  >
                    <SearchIcon />
                    <input
                      type="text"
                      placeholder="Search  college to  calculate seats"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </Box>
                </div>

                <TableContainer sx={{ maxHeight: "440px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: 0,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            S.No
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "80px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Session
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "50px",
                          }}
                          // /////////////for sorting name//////////////////
                          onClick={() => handleSort("name")}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Student Name
                            {/* /////////////name sort////////////////////////// */}
                            {sortBy === "name" && (
                              <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                            )}
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "240px",
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Enrollment No.
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "240px",
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Student Type
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "240px",
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Email
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5>
                            <h5
                              style={{
                                fontFamily: "montserrat",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              DOB
                            </h5>
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "200px",
                              fontWeight: "bold",
                            }}
                          >
                            Father's Name
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "180px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Mother's Name
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Contact
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "120px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Nationality
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "150px",
                              fontWeight: "bold",
                            }}
                          >
                            Qualification
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Qualification percentage
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              minWidth: "150px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Type
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "250px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Name
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "250px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Course Branch
                          </h5>
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "250px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            College Assigned
                          </h5>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              minWidth: "150px",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Enrolled Status
                          </h5>
                        </TableCell>

                        {/* <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            Edit
                          </h5>
                        </TableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {sortedStudents
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((student, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              style={{
                                position: "sticky",
                                top: 0,
                                left: "0px",
                                zIndex: "1",
                                color: "red",
                                fontWeight: "bold",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {student.admissionSession}{" "}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: "#6a040f",
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                                position: "sticky",
                                top: 0,
                                left: "50px",
                                zIndex: "1",
                              }}
                            >
                              {student.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                               
                                position: "sticky",
                                left: "230px",
                                top: 0,
                                zIndex: "1",
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                                {student?.enrollmentNumber ? (
                            <span
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {student?.enrollmentNumber}
                            </span>
                          ) : (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              Not Generated
                            </span>
                          )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{               
                                position: "sticky",
                                left: "230px",
                                top: 0,
                                zIndex: "1",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.StudentType}
                            </TableCell>
                           
                            <TableCell
                              align="left"
                              style={{               
                                position: "sticky",
                                left: "230px",
                                top: 0,
                                zIndex: "1",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.email}
                            </TableCell>
                            {/* Replace the line below with the DatePicker component */}
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              <DatePicker
                                selected={selectedDate || new Date(student.dob)}
                                readOnly
                                dateFormat="dd/MM/yyyy"
                                className="text-left"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.fathersname}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.mothersname}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.mobile}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ fontFamily: "'Open Sans', sans-serif" }}
                            >
                              {student.domicile}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.qualification}
                            </TableCell>
                            <TableCell align="left">
                              {student.LastPercentage}%
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.courseType}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                color: "#344e41",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.courseName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.courseBranch}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.assignedCollege || "Not assigned"}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                color: student?.isEnrollPaid ? "green" : "red",
                                fontWeight: 700,
                              }}
                            >
                              {student?.isEnrollPaid ? "Yes" : "No"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {/* <Link to={`/studentverifieddetailed/${student._id}`}> */}
                              {/* <Button
                                style={{
                                  height: "40px",
                                  width: "90px",
                                  padding: "2px",
                                }}
                                variant="success"
                                // disabled={!student.isEnrolled}
                                onClick={() =>
                                  handleButtonClick(
                                    student._id,
                                    student.isEnrolled
                                  )
                                }
                              >
                                More...
                              </Button> */}
                              {/* <Button style={{ height: '40px', width: '90px', padding: '2px' }} variant="success" disabled={!student.isEnrolled} readOnly>More....</Button> */}
                              {/* </Link> */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  component="div"
                  count={sortedStudents.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[100, 200,500]}
                  labelRowsPerPage="Rows per page"
                  style={{ marginTop: "20px" }}
                />
              </Paper>
            </CardContent>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default E_approve;
