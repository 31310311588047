import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import AdminDashboard from "./AdminDashboard";
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../../../AdminComponent/Style";
import axios from "axios";
import swal from "sweetalert";

const theme = createTheme();

const AdminUpdtStd_Manually = ({ userData }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(2);
  const [enrollmentNumber, setEnrollmentNumber] = useState("");
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = async (userId) => {
    try {
      const response = await axios.put(
        "https://sssutms.ac.in/apitest/admin/manualenrollment-generate",
        {
          enrollmentNumber,
          studentId: userId,
        }
      );


      swal({
        title: "Success",
        text: "Enrollment Generated Successfully!",
        icon: "success",
        buttons: "OK",
      });
    } catch (error) {

    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />
      <Box sx={{ width: "100%", marginLeft: "100px", overflow: "hidden" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "420px" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "150px",
                    position: "sticky",
                    zIndex: "2",
                    left: 0,
                    top: 0,
                  }}
                >
                  {" "}
                  Student Name
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "150px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Enrollment No.
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "70px",
                    position: "sticky",
                    zIndex: "2",
                    left: 150,
                    top: 0,
                  }}
                >
                  Registration No
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "50px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Contact
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "150px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Email
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "80px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Enrolled{" "}
                </StyledTableCell>

                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "80px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Enrollment Fee{" "}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "80px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Enrollment Generate
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    backgroundColor: "rgb(2, 48, 71)",
                    color: "white",
                    minWidth: "150px",
                    position: "sticky",
                    zIndex: "1",
                    top: 0,
                  }}
                >
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userData.map((user, index) => (
                <StyledTableRow key={index}>
                  {user ? (
                    <>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          color: "#CD5C08",
                          fontWeight: 700,
                          zIndex: "1",
                          backgroundColor: "white",
                          top: 0,
                          position: "sticky",
                          left: 0,
                        }}
                      >
                        {user.name}
                      </StyledTableCell>
                      <Box sx={{ p: 2 }}>
                        <Form.Control
                          type="text"
                          name="enrollmentNumber"
                          value={enrollmentNumber}
                          onChange={(e) => setEnrollmentNumber(e.target.value)}
                        />
                      </Box>
                      <StyledTableCell
                        align="right"
                        style={{
                          fontWeight: 700,
                          zIndex: "1",
                          backgroundColor: "white",
                          top: 0,
                          position: "sticky",
                          left: 150,
                        }}
                      >
                        {user.randomId}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {user.mobile}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {user.email}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          color: user?.isEnrolled ? "green" : "red",
                          font: 700,
                          minWidth: "10px",
                        }}
                      >
                        {user?.isEnrolled ? "Yes" : "No"}
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{
                          color: user?.isEnrollPaid ? "green" : "red",
                          font: 700,
                          minWidth: "10px",
                        }}
                      >
                        {user?.isEnrollPaid ? "Yes" : "No"}
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          color: user?.IsEnrollGenerated ? "green" : "red",
                          font: 700,
                          minWidth: "10px",
                        }}
                      >
                        {user?.IsEnrollGenerated ? "Yes" : "No"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="contained"
                          color="success"
                          onClick={() => handleClick(user._id)}
                        >
                          Generate
                        </Button>
                      </StyledTableCell>
                    </>
                  ) : (
                    <p>No user data available</p>
                  )}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={userData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* </Container> */}
      </Box>
    </ThemeProvider>
  );
};

export default AdminUpdtStd_Manually;
