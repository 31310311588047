// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import checked from "../../images/checked.png";
// import jsPDF from "jspdf";

// function Success() {
//   const location = useLocation();
//   const queryParams = new URLSearchParams(location.search);

//   const orderId = queryParams.get("orderId");
//   const transactionTime = new Date(queryParams.get("TxDate"));
//   const bankTransactionId = queryParams.get("BankTxnId");
//   const Amount = queryParams.get("Transamount");
//   const paymentMode = queryParams.get("paymentMode");
//   const TxnID = queryParams.get("txnId");

//   const formattedDate = transactionTime.toLocaleDateString('en-IN', {
//     day: '2-digit',
//     month: '2-digit',
//     year: 'numeric'
//   });

//   // Formatting time (Indian time)
//   const formattedTime = transactionTime.toLocaleTimeString('en-IN', {
//     hour12: false, // Use 24-hour format
//     hour: '2-digit',
//     minute: '2-digit',
//     second: '2-digit'
//   });
//   // console.log(formattedDate,formattedTime)

//   return (
//     <>
//       <style>
//         {`
//                 p{
//                     margin-bottom:8px;
//                 }
//       .card {
//         background-color: #fff;
//         border: 1px solid #ddd;
//         border-radius: 8px;
//         box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//         padding: 20px;
//         width: 40%; /* Adjust as needed */
//         max-width: 800px; /* Set a maximum width if needed */
//         height:80%;
//         margin: 20px auto; /* Center the card horizontally */
//         text-align: left; /* Center text within the card */
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//       }

//       img {
//         display: block; /* Ensure the image is a block element */
//         margin: 0 auto; /* Center the image within its container */
//       }

//       h4 {
//         margin-top: 20px; /* Add margin to the top of the heading */
//       }
//      .top{
//         text-align: center;
//         margin-bottom:50px;
//      }
//       .bottom {
//         display: flex;
//         justify-content: space-between;
//       }
//       .animated-button {
//         display: inline-block;
//         padding: 8px 60px;
//         font-size: 16px;
//         text-align: center;
//         cursor: pointer;
//         border: 2px solid transparent;
//         border-radius: 5px;
//         background: linear-gradient(to right, #FF5F6D, #FFC371);
//         color: #fff;
//         transition: all 0.3s ease;
//         box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
//       }

//       .animated-button:hover {
//         transform: translateY(-2px);
//         box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
//       }

//       @media screen and (max-width: 600px) {
//         .card {
//           width: 80%; /* Adjust width for smaller screens */
//         }
//       }
//     `}
//       </style>
//       <div
//         style={{
//           background: "linear-gradient(to right, #A9F1DF , #FFBBBB)",
//           minHeight: "100vh",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <div className="card">
//           <div className="top">
//             <img src={checked} alt="check" height="20%" width="20%" />
//             <h4> Your Payment has been Successful</h4>
//             <h6>Date:  {formattedDate}{" "}{formattedTime}</h6>
//             <h5 style={{ color: "red" }}>Paid Amount: <b> ₹ {Amount}</b></h5>
//           </div>
//           <div className="bottom">
//             <div>

//               <p>TXN ID</p>
//               <p> ORDER ID</p>
//               <p>PAYMENT MODE</p>
//               <p> BANKTXN ID</p>

//             </div>
//             <div>
//               <p>{TxnID} </p>
//               <p>{orderId} </p>
//               <p>{paymentMode} </p>
//               <p>{bankTransactionId} </p>

//             </div>
//           </div>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             <button className="animated-button">Print</button>
//             <Link to='/'><button className="animated-button">Close</button></Link>
//           </div>
//         </div>
//       </div>

//     </>
//   );
// }

// export default Success;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import checked from "../../images/checked.png";
import jsPDF from "jspdf";
import logo from "../../AdminComponent/12.png";
import OnlyHeader from "../OnlyHeader";

function Success() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Retrieve query parameters with fallback values
  const orderId = queryParams.get("orderId") || "N/A";
  const txDate = queryParams.get("TxDate");
  const bankTransactionId = queryParams.get("BankTxnId") || "N/A";
  const Amount = queryParams.get("Transamount") || "0";
  const paymentMode = queryParams.get("paymentMode") || "N/A";
  const TxnID = queryParams.get("txnId") || "N/A";
  const courseType = queryParams.get("courseType") || "N/A";
  const courseBranch = queryParams.get("courseBranch") || "N/A";
  const courseName = queryParams.get("courseName") || "N/A";
  const Name = queryParams.get("Name") || "User";
  const FName = queryParams.get("FName") || "N/A";
  const email = queryParams.get("email") || "N/A";
  const mobile = queryParams.get("mobile") || "N/A";

  // Handle transactionTime safely
  let transactionTime = txDate ? new Date(txDate) : new Date();
  
  // Check if transactionTime is a valid date
  if (isNaN(transactionTime.getTime())) {
    transactionTime = new Date();
  }

  const formattedDate = transactionTime.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const formattedTime = transactionTime.toLocaleTimeString("en-IN", {
    hour12: false, // Use 24-hour format
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  // Function to generate PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    // Add Logo
    if (logo) {
      doc.addImage(logo, "PNG", 10, 10, 30, 30); // Adjust positioning and size as needed
    }

    // Add University Details
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.setTextColor(0, 0, 0);
    doc.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL", 105, 20, {
      align: "center",
    });
    doc.text("SCIENCES SEHORE", 105, 27, { align: "center" });
    doc.setFontSize(13);
    doc.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      105,
      34,
      { align: "center" }
    );
    doc.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      105,
      40,
      { align: "center" }
    );

    // Add Receipt Title
    doc.setFontSize(14);
    doc.setTextColor(255, 0, 0); // Red color
    doc.text("Entrance Fee Receipt", 105, 50, { align: "center" });

    // Reset text color
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // Set the initial y position
    let yPos = 60;
    const lineHeight = 10;

    // Define an array of labels and their corresponding values
    const details = [
      { label: "Name of Candidate", value: Name.toUpperCase() },
      { label: "Father's Name", value: FName },
      { label: "Email", value: email },
      { label: "Mobile", value: mobile },
      { label: "Course Type", value: courseType },
      { label: "Course Name", value: courseName },
      { label: "Course Branch", value: courseBranch },
      { label: "TXN ID", value: TxnID },
      { label: "ORDER ID", value: orderId },
      { label: "PAYMENT MODE", value: paymentMode },
      { label: "BANKTXN ID", value: bankTransactionId },
      { label: "Paid Amount", value: `₹ ${Amount}` },
      { label: "Date & Time", value: `${formattedDate} ${formattedTime}` },
    ];

    // Add details to the PDF
    details.forEach((detail) => {
      doc.text(
        `${detail.label.padEnd(30)}: ${detail.value}`,
        10,
        yPos
      );
      yPos += lineHeight;
    });

    // Save the generated PDF
    doc.save(`${Name}_Entrance_Fee_Receipt.pdf`);
  };

  return (
    <>
    <OnlyHeader/>
    <style>
        {`
                  p{
                      margin-bottom:8px;
                  }
        .card {
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          padding: 20px;
          width: 40%; /* Adjust as needed */
          max-width: 800px; /* Set a maximum width if needed */
          height:20%;
          margin-top:80px;
          // margin: 20px auto; /* Center the card horizontally */
          text-align: left; /* Center text within the card */
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
  
        img {
          display: block; /* Ensure the image is a block element */
          margin: 0 auto; /* Center the image within its container */
        }
  
        h4 {
          margin-top: 20px; /* Add margin to the top of the heading */
        }
        h6 {
          margin-top: 20px; /* Add margin to the top of the heading */
        }
       .top{
          text-align: center; 
          margin-bottom:30px;
       }
        .bottom {
          display: flex;
          justify-content: center;
          gap:60px;
        }
        .animated-button {
          display: inline-block;
          padding: 5px 40px;
          font-size: 16px;
          text-align: center;
          cursor: pointer;
          border: 2px solid transparent;
          border-radius: 5px;
          background: green;
          // background: linear-gradient(to right, #FF5F6D, #FFC371);
          color: #fff;
          transition: all 0.3s ease;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
  
        .animated-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
        }
  // .h66{
  // color:black;
  // margin-top:-20px;
  // margin-left:70px;}
  
  
  
  
  .buttoon{
   display: flex;
   margin-top:10px ;
   gap: 180px;
   }
  
  //       .h44{
  //       font-family:sans-serif 'roboto';
  //       margin-right:50px;
  //       flex-grow: 1; /* Allows the text to take up the remaining space */
  //   margin: 0; /* Removes default margin */}
  
  //  .check{
  //         display:flex;
  //         align-items: left;
  //         margin-left:6px;
  //           flex-shrink: 0; 
  //   margin-right: 10px; 
  //         }
  
  //        .imagee{height:70px;
  //         width:70px;}
  
  
  .h66 {
    color: black;
    margin-top: -30px;
    margin-left: 70px;
    flex-shrink: 1; /* Allows the text to shrink if needed */
    margin-right: 10px; /* Add space between the h6 and the edge of the card */
  }
  
  .h44 {
    font-family: sans-serif 'Roboto';
    margin-right: 10px;
    flex-grow: 1;
    padding:2px;
    margin-top: -20px;
    flex-shrink: 1; /* Allows the text to shrink if needed */
  }
  
  .check {
    display: flex;
    align-items: center; /* Vertically center the items */
    margin-left: -10px;
    margin-top:-20px
  }
  
  .imagee {
    height: 70px;
    width: 70px;
    margin-right: 10px;
    margin-top:20px
  }
        @media screen and (max-width: 480px) {
          .card {
            width: 100%; /* Adjust width for smaller screens */
          margin-top:110px;}
  
  
          .h66{
  color:black;
  margin-top:-10px;
  margin-left:30px;
  font-size:13px;}
  
  
  
  
  .buttoon{
   display: flex;
   margin-top:10px ;
   gap: 100px;
   }
  
        .h44{
        font-family:sans-serif 'roboto';
        margin-right:10px;
        font-weight:700;
        margin-top:-1px;}
  
   .check{
          display:flex;
          margin-left:10px;
           align-items: left;
            flex-shrink: 0; 
          }
  
         .imagee{
  
         height:50px;
          width:50px;
          margin-left:-20px}
  
  
  
          p{
          font-size:10px}
  
         
        }
  
  
         @media screen and (max-width: 600px) {
      `}
      </style>

      <div
        style={{
          background: "#F5F5F5",
          display: "flex",
          height: "auto",

          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="card">
          <div className="top">
            <div className="check">
              <img src={checked} className="imagee" alt="check" />
            
              <h4 className="h44">Thank You {Name.toUpperCase()}!</h4>
            </div>
            <h6 className="h66">
              Your transaction has been completed successfully.
            </h6>
            <h6>
              Date: {formattedDate} {formattedTime}
            </h6>
            <h5 style={{ color: "red" }}>
              Paid Amount: <b> ₹ {Amount}</b>
            </h5>
          </div>
          <div className="bottom">
            <div>
              <p>Father Name </p>
              <p>Email </p>
              <p>Contact :</p>
              <p>TXN ID</p>
              <p> ORDER ID</p>
              <p>PAY MODE</p>
              <p>BANKTXN ID</p>
              <p>COURSE NAME</p>
              <p>COURSE BRANCH</p>
            </div>
            <div>
              <p> {FName} </p>
              <p>{email} </p>
              <p> {mobile} </p>
              <p style={{ fontWeight: "700" }}>{TxnID} </p>
              <p style={{ fontWeight: "700" }}> {orderId} </p>
              <p>{paymentMode} </p>
              <p> {bankTransactionId} </p>
              <p> {courseName} </p>
              <p> {courseBranch} </p>
            </div>
          </div>
          <div className="buttoon">
            <button
              onClick={generatePDF}
              className="animated-button"
              style={{ backgroundColor: "#FF7F3E" }}
            >
              Print
            </button>
            <Link to="/erp/studentlogin">
              <button
                className="animated-button"
                style={{ backgroundColor: "#6295A2" }}
              >
                Close
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;

