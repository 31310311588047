import React from 'react'
import { Link } from 'react-router-dom'

function Entrance_Inst() {
  return (
    <div>
       <Link to="/entrance/online/form"> <button><h2>Start</h2></button></Link> 
    </div>
  )
 //dfsd
}

export default Entrance_Inst