import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import grievance from "../../../images/grievance.jpg";
import { Link } from 'react-router-dom';


const Grievance = () => {
  return (
    <>
      <style>
        {`

           
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-40px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  
            .title h4::before{
                background: orange;
                height:2px;
                width:45px;
                content:"";
                position:absolute;
                top:0px;
                left:0px;
                bottom:0px;
                margin:auto;
          
               }
               
               
.title{
    font-family:inherit;
    font-weight:350;
   
    margin-left:80px;
  }
       .title h4{
        text-transform:uppercase;
        font-weight:500;
        font-size:20px;
        position:relative;
        padding-left:60px;
        font-family: "poppins",sans-serif;
        margin-top:0px;
        margin-bottom:15px;
        display:inline-block;
        color:rgb(53,56,88);
        font-style:normal;
       }
       .title p{
 margin-left:-60px;
color:black;
font-size:15px;
       }

       .title h3 {
        font-size: 50px;
        font-weight: 700;
        line-height: 49px;
        text-transform: uppercase;
        margin-bottom: 20px;
        color: #780000;
        font-style: normal;
    }
    .manual p{
        color:black;
        font-size:15px;
        margin-left:80px;
      font-family: "poppins",sans-serif;
    }
    .manual h4{
        margin-left:80px;
        font-size:16px;
          font-family: "poppins",sans-serif;
        font-weight:bolder;
        margin-top:0px
    }

    .manual hr{
        margin-left:80px;
        width:60%

    }
    .manu h4{
        margin-left:90px;
        font-size:17px;
         font-family: "poppins",sans-serif;
        // font-weight:bolder;
        margin-top:none;
        margin-bottom: 4px;
    }
    .under{
        margin-left: 78px; 
    }
    .under li {
        // list-item-style:disc;
        // padding-left:25px;
        
        font-size: 16px;
        font-weight: normal;
    }
    .contact-detail{
        margin-left:70px;
    }
  
    //   ///////////////////////////////////////////////////////////////////////////////////
 .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 40%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 80%; /* Make tables full-width on smaller screens */
      }
    }
      
   
  `}
      </style>
      <Nav />

      <div
        data-aos="fade-right"
        className="title aos-init aos-animate"
        style={{
          marginTop: "40px",
          marginLeft: "80px",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        <h4>Grievance Redressal Committee</h4>
      </div>

      <div className="row dirr">
        <div className="col-lg-8 padding-section">
          <div className="manual">
            <p>
              The Grievance Redressal Committee was constituted for the Academic
              Year 2024-2025.
            </p>
            <h4>Objective:</h4>
            <p>
              To provide an avenue for the aggrieved students to redress their
              individual grievances in order to have a healthy atmosphere among
              students, staff<br></br> and management in the University.
            </p>
            <h4>Responsibilities:</h4>
            <p>
              (a) Committee members meet once a month to discuss and resolve the
              grievances, if any received in writing from the concerned
              students.
            </p>
            <p style={{ marginTop: "-10px" }}>
              (b) To maintain the minutes of the meetings and submit the copy of
              the same to the Vice Chancellor.
            </p>
          </div>
        </div>
        <div className="col-lg-4">
          <ul className="list-icon">
            <h4
              style={{
                fontFamily: "'poppins',sansSerif",
                color: "#243642",
                textDecoration: "underline",
              }}
            >
              Committee
            </h4>
            <br></br>{" "}
            <li className="lli">
              <Link to="/anti-ragging">
                <i class="fa fa-adjust" aria-hidden="true"></i>Anti Ragging
              </Link>
            </li>
            <li className="lli">
              <Link to="/proctorial-board">
                <i class="fa fa-adjust" aria-hidden="true"></i>Proctorial Board
              </Link>
            </li>
            <li className="lli">
              <Link to="/internal-complaint">
                <i class="fa fa-adjust" aria-hidden="true"></i>Internal
                Complaint
              </Link>
            </li>
            <li className="lli">
              <Link to="/grievance-redressal">
                <i class="fa fa-adjust" aria-hidden="true"></i>Grievance
                Redressal
              </Link>
            </li>
            <li className="lli">
              <Link to="/sc-st">
                <i class="fa fa-adjust" aria-hidden="true"></i>For SC-ST
              </Link>
            </li>
            <li className="lli">
              <Link to="/edc">
                <i class="fa fa-adjust" aria-hidden="true"></i>EDC
              </Link>
            </li>
            <li className="lli">
              <Link to="/international-higher-education-cell">
                <i class="fa fa-adjust" aria-hidden="true"></i>International
                Higher Education Cell
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <br></br>
      <h5 style={{ marginLeft: "80px", fontFamily: "'poppins',sansSerif" }}>
        Members:
      </h5>
      <table
        style={{
          width: "60%",
          marginLeft: "70px",
          marginTop: "10px",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            {" "}
            <th>S.No</th> <th>NAME</th> <th>DESIGNATION</th>
          </tr>

          <tr>
            <td>1</td>
            <td>Dr. Hemant Kumar Sharma</td>
            <td>Registrar & Chairman</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Mrs. Sujata Kushwaha</td>
            <td>Member</td>
          </tr>
          <tr>
            <td>3</td>
            <td> Dr. R. S. Kushwaha</td>
            <td> Member</td>
          </tr>
          <tr>
            <td>4</td>
            <td> Mrs. Priyanka Jhawar</td>
            <td> Member </td>
          </tr>
          <tr>
            <td>5</td>
            <td> Dr. C. K. Tyagi</td>
            <td> Member Secretary</td>
          </tr>

          <tr>
            <td>6</td>
            <td> Prof. (Dr.) Varsha Namdeo</td>
            <td> OMBUDSMAN</td>
          </tr>
        </tbody>
      </table>

      <div
        data-aos="fade-right"
        className="title aos-init aos-animate"
        style={{
          marginTop: "40px",
          marginLeft: "60px",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        <h4> Student Grievance Redressal Committee</h4>

        <p
          style={{
            marginLeft: "10px",
            fontFamily: "'poppins',sansSerif",
            fontSize: "16px",
            width: "85%",
            textAlign: "justify",
          }}
        >
          A Student Grievance Redressal System at SSSUTMS University, a platform
          that provides students with a means of expressing their concerns and
          complaints regarding their educational experience. The primary
          objective of this system is to ensure that all student complaints are
          heard, addressed, and resolved in a timely and effective manner.The
          Grievance Redressal Cell (GRC) aims to look into the complaints lodged
          by any student and redress it as per requirement.The students can
          state their grievance regarding any academic and non- academic matter
          within the campus.The Grievance Redressal Cell is constituted to probe
          into the student grievances. The Grievance Redressal Cell attempts to
          address genuine problems and complaints of students whatever be the
          nature of the problem.
        </p>
      </div>
      <br></br>
      <h4
        style={{
          marginLeft: "60px",
          fontSize: "18px",
          fontWeight: "bolder",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        MECHANISM FOR REDRESSAL OF GRIEVANCES OF STUDENTS
      </h4>
      <br></br>
      <p
        style={{
          color: "black",
          marginLeft: "60px",
          fontSize: "16px",
          width: "85%",
          textAlign: "justify",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        The students are the main stakeholders in any institution imparting
        education, and it’s our endeavor to make all efforts to ensure
        transparency in all the activities at different stages. Taking this
        spirit in consideration the institute has decided to provide mechanism
        to students for redressal of their grievances as under:
      </p>
      <br></br>
      <p
        style={{
          color: "black",
          marginLeft: "60px",
          fontSize: "16px",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        The Grievances may broadly include the following complaints of the
        aggrieved students:
      </p>

      <div className="manu">
        <h4>1. Academic</h4>
        <h4>2. Non-academic</h4>
        <br></br>
        <p
          style={{
            color: "black",
            marginLeft: "60px",
            fontSize: "16px",
            width: "85%",
            textAlign: "justify",
            fontFamily: "'poppins',sansSerif",
          }}
        >
          It is good to air a grievance rather than to keep it bottled up.
          Protection of human rights is essential for the all round development
          of an individual’s personality to realize the primary needs of the
          students and staff and secure civil liberties for everybody. The cell
          is indented to find solutions for problems like sexual harassment –any
          kind of physical or mental harassment, complaints regarding class room
          teaching-class room management, completion of syllabus, teaching
          methods etc, if and when they arise. The Grievance Redressal Cell
          convenes meetings periodically, record the minutes and takes steps to
          redress the grievance.
        </p>
      </div>

      <br></br>

      <h4
        style={{
          marginTop: "10px",
          marginLeft: "60px",
          fontSize: "18px",
          fontWeight: "bolder",
          fontFamily: "'poppins',sansSerif",
        }}
      >
        Click on the link to lodge your complaint:{" "}
        <Link
          to="https://docs.google.com/forms/d/e/1FAIpQLSehjryL2kuWdvukwo2aMVItPtNHmT_DlcGU_8JmqcS759gDNA/viewform?usp=sf_link"
          target="_blank"
          style={{ color: "#1982c4" }}
        >
          {" "}
          forms/d/e/1FAIpQLSehjryL2kuWdvukwo2aMVItPtNHmT_DlcGU_8JmqcS759gDNA/
        </Link>
      </h4>
      <hr
        style={{
          width: "95%",
          marginLeft: "50px",
          fontFamily: "'poppins',sansSerif",
        }}
      />
      <br></br>

      <Footerpage />
    </>
  );
};

export default Grievance;
