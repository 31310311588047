// import React, { useState } from "react";
// import PropTypes from "prop-types";
// import {
//   AppBar,
//   Box,
//   Drawer,
//   List,
//   Toolbar,
//   Typography,
//   Divider,
//   Hidden,
// } from "@mui/material";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import posImage from "./12.png";
// import { Modal, Button } from "react-bootstrap";

// function MyVerticallyCenteredModal(props) {
//   return (
//     <Modal
//       {...props}
//       size="lg"
//       aria-labelledby="contained-modal-title-vcenter"
//       centered
//     >
//       <Modal.Header closeButton>
//         <Modal.Title id="contained-modal-title-vcenter">Notice</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <p>
//           Dear Student, Your registration can be cancelled without registration
//           and tuition fee Payment. It is mandatory to pay your registration and
//           tuition fees.
//         </p>
//       </Modal.Body>
//       <Modal.Footer>
//         <Button onClick={props.onHide}>Close</Button>
//       </Modal.Footer>
//     </Modal>
//   );
// }

// const drawerWidth = 240;

// const theme = createTheme({
//   typography: {
//     fontWeightBold: 700,
//   },
// });

// function OnlyHeaders(props) {
//   const [modalShow, setModalShow] = useState(false);

//   const navigate = useNavigate();
//   const { window } = props;
//   const [mobileOpen, setMobileOpen] = React.useState(false);

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const handleIconClick = () => {
//     navigate("/studentHome");
//     setModalShow(true);
//   };

//   const handleClick = () => {
//     navigate("/studentDetail");
//   };

//   const drawer = (
//     <div>
//       <Toolbar />
//       <Divider />
//       <List>
//         <img
//           style={{ width: "50px", height: "50px", marginLeft: "60px" }}
//           src="https://static.vecteezy.com/system/resources/previews/000/497/070/original/male-student-icon-design-vector.jpg"
//           alt=""
//           onClick={handleIconClick}
//         />
//       </List>
//       <Divider />
//       <List>
//         <img
//           style={{ width: "50px", height: "50px", marginLeft: "60px" }}
//           src="https://cdn3.iconfinder.com/data/icons/line/36/folder_add-256.png"
//           alt=""
//           onClick={handleClick}
//         />
//       </List>
//     </div>
//   );

//   return (
//     <>
//       <style>
//         {`


// .log{
//   height: 50px;
//    width: 50px ;
//   //  margin-right: px;
//    border-radius:10px;
// }
//       @media (max-width:480px){
//      .heading{
//        width:300px;
//        font-size:15px;
//        margin-left:-10px
//      }

//      .log{
//      height: 60px;
//    width: 60px ;
//    margin-right:15px;
//    border-radius:20px;
//    padding:10px;
//    margin-left:-10px
//    }
//       }
//       `}
//       </style>
//       <ThemeProvider theme={theme}>
//         <Box sx={{ display: "flex" }}>
//           <AppBar
//             position="fixed"
//             sx={{
//               width: "100%",
//               top: 0,
//               zIndex: (theme) => theme.zIndex.drawer + 1,
//               // background: "linear-gradient(to right ,#1f487e, #000000)",
//               background: "linear-gradient(to right ,#272c30, #66798B)",

//               // backgroundImage: "linear-gradient(to right, #ff512f , #dd2476)"
//               // backgroundImage: "linear-gradient(to right, #02aab0 ,
//             }}
//           >
//             <Toolbar>
//               <Box sx={{ display: "flex", alignItems: "center" }}>
//                 <img className="log" src={posImage} alt="" />
//                 <Typography
//                   className="heading"
//                   variant="h6"
//                   component="div"
//                   sx={{
//                     flexGrow: 1,
//                     textShadow: "2px 2px 5px red",
//                     marginLeft: "30px",
//                   }}
//                 >
//                   SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
//                   (SSSUTMS)
//                 </Typography>
//               </Box>
//             </Toolbar>
//           </AppBar>
//           <Hidden xsDown implementation="css">
//             <Drawer
//               sx={{
//                 width: drawerWidth,
//                 flexShrink: 0,
//                 "& .MuiDrawer-paper": {
//                   width: drawerWidth,
//                   boxSizing: "border-box",
//                 },
//               }}
//               variant="temporary"
//               anchor="left"
//               open={mobileOpen}
//               onClose={handleDrawerToggle}
//             >
//               {drawer}
//             </Drawer>
//           </Hidden>
//         </Box>
//         <MyVerticallyCenteredModal
//           show={modalShow}
//           onHide={() => setModalShow(false)}
//         />
//       </ThemeProvider>
//     </>
//   );
// }

// OnlyHeaders.propTypes = {
//   window: PropTypes.func,
// };

// export default OnlyHeaders;


import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Drawer,
  List,
  Toolbar,
  Typography,
  Divider,
  Hidden,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import posImage from "./12.png";
import { Modal, Button } from "react-bootstrap";

function MyVerticallyCenteredModal({ show, onHide }) {
  return (
    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Notice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Dear Student, Your registration can be cancelled without registration
          and tuition fee Payment. It is mandatory to pay your registration and
          tuition fees.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function OnlyHeaders() {
  const [modalShow, setModalShow] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleIconClick = () => {
    navigate("/studentHome");
    setModalShow(true);
  };

  const handleClick = () => {
    navigate("/studentDetail");
  };

  const drawerContent = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <img
          style={{ width: "50px", height: "50px", marginLeft: "60px", cursor: "pointer" }}
          src="https://static.vecteezy.com/system/resources/previews/000/497/070/original/male-student-icon-design-vector.jpg"
          alt="Student Icon"
          onClick={handleIconClick}
        />
      </List>
      <Divider />
      <List>
        <img
          style={{ width: "50px", height: "50px", marginLeft: "60px", cursor: "pointer" }}
          src="https://cdn3.iconfinder.com/data/icons/line/36/folder_add-256.png"
          alt="Folder Icon"
          onClick={handleClick}
        />
      </List>
    </div>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={{
            width: "100%",
            top: 0,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "linear-gradient(to right, #272c30, #66798B)",
          }}
        >
          <Toolbar>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img className="log" src="https://cdn.sssutms.ac.in/media/static/images/SSSUTMS_LOGO.png" alt="University Logo" style={{ width: 60, height: 50, borderRadius: 10 }} />
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  textShadow: "2px 2px 5px red",
                  marginLeft: 3,
                }}
              >
                SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES
                (SSSUTMS)
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Hidden xsDown implementation="css">
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
      </Box>
      <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
    </ThemeProvider>
  );
}

OnlyHeaders.propTypes = {
  window: PropTypes.func,
};

export default OnlyHeaders;