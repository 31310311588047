import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import { TableCell, Box } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import axios from "axios";
import Sidebar from "../AdminComponent/HodDashboard";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const StudentVerify = () => {
  const [branch, setBranch] = useState("");
  const [approvedStudents, setApprovedStudents] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModel, setShowModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const branch1 = userData?.Branch || "";
  const course = userData?.courseName || "";
  const college = userData?.college || "";
  const enrolled = userData?.isEnrolled || "";

  const fetchApprovedStudents = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/admin/v1/normal/approve?branch=${branch1}&course=${course}&college=${college}`
      );

      setApprovedStudents(response.data.students);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // ///////////////////pagination///////////////////////////////////
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // /////////////////////////////////name sort/////////////////////////
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  const filteredStudents = approvedStudents.filter((student) => {
    const studentName = student.name || ""; // Ensure name is a string
    const studentCollege = student.assignedCollege || ""; // Ensure assignedCollege is a string
    return (
      studentName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      studentCollege.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const sortedStudents = [...filteredStudents].sort((a, b) => {
    if (sortBy === "name") {
      const nameA = a.name || ""; // Ensure name is a string
      const nameB = b.name || ""; // Ensure name is a string
      return sortOrder === "asc"
        ? nameA.localeCompare(nameB)
        : nameB.localeCompare(nameA);
    } else if (sortBy === "assignedCollege") {
      const collegeA = a.assignedCollege || ""; // Ensure assignedCollege is a string
      const collegeB = b.assignedCollege || ""; // Ensure assignedCollege is a string
      return sortOrder === "asc"
        ? collegeA.localeCompare(collegeB)
        : collegeB.localeCompare(collegeA);
    }
    return 0;
  });

  /////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    fetchApprovedStudents();
  }, [branch]);

  /////////////////////datepicker/////////////////
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleButtonClick = (id, enrolled) => {
 
    if (!enrolled) {
      // swal({
      //   title: "Error",
      //   text: "student is not enrolled So here is no any full details!",
      //   icon: "",
      //   buttons: "OK",
      // });
      swal({
        icon: "info",
        title: "Details not found",
        text: "student is not enrolled So here is no any full details!",
        buttons: "OK",
      });
    } else {
      navigate(`/studentverifieddetailed/${id}`);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Sidebar />

        <div
          className="shadow p-3 mb-3 bg-body rounded"
          style={{
            backgroundColor: "#e8e5d5",
            width: "90%",
            marginLeft: "120px",
            marginRight: "auto",
            marginTop: "50px",
            textAlign: "center",
            maxWidth: "1200px",
          }}
        >
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <h4 style={{ fontFamily: "fantasy" }}> VERIFIED STUDENT</h4>
            <Box sx={{ border: "1px solid #ccc", width: "40%" }}>
              <SearchIcon sx={{ mr: 1 }} />
              <input
                type="text"
                placeholder="Search  college to  calculate seats"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Box>
          </div>
          {/* <input
            type="text"
            placeholder="Filter students"
            style={{
              padding: "8px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              fontFamily: "Arial, sans-serif",
              maxWidth: "200px",
            }}
            // onChange={handleFilterChange} // Add your filtering logic here
          /> */}

          <br />
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "200px", marginTop: "40px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error ? (
            <span>No Student Available</span>
          ) : (
            <Paper sx={{ width: "100%" }}>
              <TableContainer sx={{ maxHeight: "440px" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          left: 0,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          S.No
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            minWidth: "80px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Session
                        </h5>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "200px",
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          left: "50px",
                        }}
                        // /////////////for sorting name//////////////////
                        onClick={() => handleSort("name")}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Student Name
                          {/* /////////////name sort////////////////////////// */}
                          {sortBy === "name" && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )}
                        </h5>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          left: "240px",
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Email
                        </h5>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5>
                          <h5
                            style={{
                              fontFamily: "montserrat",
                              fontSize: "20px",
                              fontWeight: "bold",
                            }}
                          >
                            DOB
                          </h5>
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "150px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            minWidth: "200px",
                            fontWeight: "bold",
                          }}
                        >
                          Father's Name
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "180px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Mother's Name
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "130px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Contact
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            minWidth: "120px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Nationality
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            minWidth: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Qualification
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "150px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Qualification percentage
                        </h5>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            minWidth: "150px",
                            fontWeight: "bold",
                          }}
                        >
                          Course Type
                        </h5>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "200px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            minWidth: "250px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Course Name
                        </h5>
                      </TableCell>

                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "200px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            minWidth: "250px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Course Branch
                        </h5>
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          minWidth: "200px",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            minWidth: "250px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          College Assigned
                        </h5>
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#023047",
                          color: "white",
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                        }}
                      >
                        <h5
                          style={{
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          Edit
                        </h5>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedStudents
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((student, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="center"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: "0px",
                              zIndex: "1",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="center">
                            {student.admissionSession}{" "}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "#6a040f",
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                              position: "sticky",
                              top: 0,
                              left: "50px",
                              zIndex: "1",
                            }}
                          >
                            {student.name}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "purple",
                              position: "sticky",
                              left: "230px",
                              top: 0,
                              zIndex: "1",
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.email}
                          </TableCell>
                          {/* Replace the line below with the DatePicker component */}
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            <DatePicker
                              selected={selectedDate || new Date(student.dob)}
                              readOnly
                              dateFormat="dd/MM/yyyy"
                              className="text-center"
                            />
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.fathersname}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.mothersname}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.mobile}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{ fontFamily: "'Open Sans', sans-serif" }}
                          >
                            {student.domicile}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.qualification}
                          </TableCell>
                          <TableCell align="center">
                            {student.qualificationPercentage}%
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.courseType}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              color: "#344e41",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.courseName}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.courseBranch}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {student.assignedCollege}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "'Open Sans', sans-serif",
                            }}
                          >
                            {/* <Link to={`/studentverifieddetailed/${student._id}`}> */}
                            <Button
                              style={{
                                height: "40px",
                                width: "90px",
                                padding: "2px",
                              }}
                              variant="success"
                              // disabled={!student.isEnrolled}
                              onClick={() =>
                                handleButtonClick(
                                  student._id,
                                  student.isEnrolled
                                )
                              }
                            >
                              More...
                            </Button>
                            {/* <Button style={{ height: '40px', width: '90px', padding: '2px' }} variant="success" disabled={!student.isEnrolled} readOnly>More....</Button> */}
                            {/* </Link> */}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={approvedStudents.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default StudentVerify;
