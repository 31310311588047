import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function HistoryMilestone() {
  return (
    <>
      <Nav />
      <style>
        {
          `
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>
      <section className="section_all " id="about" >
        <div className="container">
          <div class="two">
            <h1>About SSSUTMS

            </h1>
          </div>
          <br></br>

          <div className='row' >
            <div className="col-lg-9">


              <p className="mt-3" style={{ textAlign: 'justify', color: 'black', fontFamily: "'poppins',sansSerif" }}>
                Sri Satya Sai Campus in Sehore was established in 1999 with the Sri Satya Sai Institute of Science & Technology (SSSIST)
                Initially, SSSIST offered three branches of engineering education with a total intake capacity of 180 .    <br />

                In 2012, because of the vision of promoters, the Sehore Campus was operating twelve Colleges, having twenty undergraduate courses & twenty Postgraduate courses, one post-graduate Diploma course & one Diploma course, with total intake of 3054  students. Sri Satya Sai Group of Institutions attracts large number of students from faraway places & States, due to quality of education at affordable cost, without any hidden fees policy. In its history of fourteen years, various Institutions under umbrella of Sri Satya Sai Group of Institutions were the only Institutes in Sehore & nearby six districts offering Technical education at affordable fees to worthy & needy students belonging to more than six thousand villages, 34 Tehsils.
                Majority of population is agriculture dependent & percentage of population living at low standard of living is 80.6. Growth rate recorded recently is 21.5%.<br></br>
                <br></br>
                Literacy rate of Sehore district is 71.11% which was 63.07% in 2001. Gender wise, male and female literacy were 82.37 and 58.86 respectively in year 2011, which in 2001 census, were 77.28% and 47.36%. 9 % of mothers between 17-55 years of age can read and this percentage is bound to increase in future. Sri Satya Sai Group of Institutions can humbly claim some role in these statistics.<br></br><br></br>
                Now with establishment of Sri Satya Sai University of Technology & Medical Sciences, the overall scenario is expected to improve further.
              </p>

            </div>
            <div className="col-lg-3">

              <ul className='list-icon' >
                <li className='lli'>
                  <Link to='/History_Milestones'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>About </Link></li>
                <li className='lli'>
                  <Link to='/institutes'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes </Link></li>
                <li className='lli'>
                  <Link to='/promotingsociety'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting Society </Link></li>
                <li className='lli'>
                  <Link to='/vision-mission'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision & Mission </Link></li>
                <li className='lli'>
                  <Link to='/core-approvalsvalues'>
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values </Link></li>
                <li className='lli'>   <Link to='/best-practices'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Best Practices </Link></li>
              </ul>
            </div></div>
          <div className="row" style={{ marginTop: '-20px' }} >
            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i class="fa-solid fa-medal"></i>
                  </div>
                  <h5 className="text-dark text-capitalize mt-3 font-weight-bold">
                    Sporting Excellence
                  </h5>
                  <p className="edu_desc mt-3 mb-0 text-muted">
                    An unmatched pursuit for sporting passion, within your academic journey
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i className="fab fa-angellist" />
                  </div>
                  <h5 className="text-dark text-capitalize mt-3 font-weight-bold">
                    Start Ups
                  </h5>
                  <p className="edu_desc mb-0 mt-3 text-muted">
                    An Incubation environment for innovative student entrepreneurship projects
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i className="fas fa-paper-plane" />
                  </div>
                  <h5 className="text-dark text-capitalize mt-3 font-weight-bold">
                    Cutting Edge Research
                  </h5>
                  <p className="edu_desc mb-0 mt-3 text-muted">
                    Leading the search for knowledge with our highly equipped research facilities
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerpage /></>
  )
}

export default HistoryMilestone