import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function ResearchPolicy() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:60px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          margin-left:10%;

          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Research Policy
        </h2>
        <hr className="title-hr"></hr> <br />
        <br />
      
        <div className="row dirr">
          {/* </div> */}

          <div
            className="col-lg-8 padding-section"
            style={{ marginLeft: "50px" }}
          >
            {/* <div className="left_text_testi_contetn"> */}
            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "1px" }}
              >
                <h6>
                  <b>Research Promotion Policies</b>
                </h6>
                <br />
                <Link to="https://www.sssutms.co.in/cms/Areas/Website/Files/Link/RESEARCH_POLICIES_3.1.1_(1)_24062024_1034.pdf">
                  <span style={{ color: "#0077b6" }}>
                    {" "}
                    - Policies and Regulations for ConductingResearch and
                    Consultancy
                  </span>
                </Link>
                <br /> <br />
                <h6>
                  <b>Research Promotion Policies</b>
                </h6>
                <br />
                FROM THE DESK OF DIRECTOR, RESEARCH AND DEVELOPMENT <br />
                <br />
                Research is an important parameter for any Institution. To
                promote Research and Publications by the faculty members and
                students of the University, the guidelines for Research
                promotion is categorized as:- <br />
                <br />
                1) Incentives for Research projects, publications and research
                related Activities. <br />
                2) Financial assistance for pursuing Ph.D. <br />
                3) Financial assistance for attending National and International
                Conferences and Faculty Development programmes. <br />
                <br />
                <h6>
                  <b>
                    INCENTIVES FOR RESEARCH PROJECTS, PUBLICATIONS AND RESEARCH
                    RELATED ACTIVITIES
                  </b>
                </h6>
                <br /> <br />
                i) The faculty (Principal Investigator) submitting a research
                project for extramural funding by government / other agencies
                (for more than Rs.3,00,000), priorly approved by the University
                Research committee / SSSUTMS Council for research, shall be
                awarded a monetary reward of Rs. 5000 per project. <br />
                ii) The faculty (Principal Investigator) submitting a research
                project for extramural funding and getting a sanction by funding
                agency (government / other) shall be awarded a monetary rewards
                of 2% (two percent) of the total funding. <br />
                iii) Faculty members publishing Books / Chapters in the reputed
                publishing house in edited volumes will be awarded a suitable
                cash prize Rs. 10,000. <br /> <br />
                iv) Publication of scientific articles in SCOPUS/ PUBMED/ UGC
                indexed journals, shall be awarded as per the following: <br />
                - Listed journal (A*Category) Rs. 2,00,000(Two lakhs) <br />
                - Listed journal (A Category) Rs. 1,00,000(One lakh) <br />
                - Listed journal (B Category) Rs. 50,000 (Fifty thousand) <br />
                - Scopus Indexed journal Rs. 50,000 (fifty thousand) <br />
                - Web of science indexed journal Rs. 50,000(fifty thousand){" "}
                <br />
                - UGC approved list journal indexed in SCOPUS/ Web of science
                Rs. 50,000(fifty thousand) <br />
                <br />
                a) The incentive applies to members of faculty who publish while
                remaining on rolls of the university.
                <br />
                b) In case the publication is in joint names/ authorship the
                incentive shall be appropriately distributed to Authors of the
                paper as per their contribution in the article. <br /> <br />
                v) Faculty members receiving state/national/International
                award/fellowship for shall be awarded suitably. <br />
                vi) Annual Best Teacher award in each faculty shall be awarded
                according to parameters judged by committee constituted by
                competent authority. <br />
                The award shall include a certificate, medal and a suitably
                awarded cash prize. <br />
                vii) Every faculty member will have an annual assessment based
                on contribution in academic & research spheres. These
                assessments will be given significant weightage for individual
                faculty member & collectively for the department. Research
                performance and achievements are an essential part of the Key
                Performance Indicators (KPIs) for annual increments. For
                promotion to Higher post / salary increment, significant
                contribution in teaching, research and patient care if
                applicable will be awarded and given weightage in professional
                career advancement. <br />
                viii) The researcher is permitted to use the infrastructural
                facilities like research equipments available within the
                University, with prior approval through proper channel. A letter
                of appreciation from the Director, Research & Development would
                be given to researcher for extraordinary research work. <br />
                ix) It has been decided that all assistant professors will do a
                FDP program from NPTEL and examination fee will be refunded
                after producing certificate of successful completion. <br />
                x) The Plagiarism check done by IQAC for scientific papers of
                students/faculty from the University shall not be charged.
                <br />
                <br />
                <h6>
                  <b> FINANCIAL ASSISTANCE FOR PURSUING Ph.D :- </b>
                </h6>
                <br />
                The Objective of these guidelines is to encourage the faculty
                member to improve their qualifications by pursuing Ph.D.
                programme available in the University. Any teacher of the
                University can enroll for the Ph.D. programme as per the
                procedure laid down by the University. <br />
                i) A teacher who is admitted to the Ph.D. course shall be
                provided fee concession to the tune of 50% of the fee charged
                for the course. <br />
                ii) A teacher shall be provided Rs. 10,000/- per annum as
                contingency fund for stationery, travel to research institutes,
                purchase of book etc. However, the books purchased will be
                deposited with the Departmental library after completion of the
                Ph.D. <br />
                iii) The teacher will have to take an undertaking that he/she
                shall continue to be in the employment of the University for one
                year after completing Ph.D. course. <br /> <br />
                Financial assistance for attending National and International
                Conferences and Faculty Development programmes.
                <br />
                <br />
                <h6>
                  <b> 1. OBJECTIVE :- </b>
                </h6>
                <br />
                To encourage the faculty for attending the National /
                International conferences /seminar/ symposia/ workshops and
                short duration training programmes. <br />
                <br /> <br />
                <h6>
                  <b>
                    2. GUIDELINES FOR FINANCIAL ASSISTANCE TO TEACHERS TO ATTEND
                    THE CONFERENCES/SEMINARS/ SYMPOSIA ETC. IN INDIA OR ABROAD
                    :-{" "}
                  </b>
                </h6>
                <br />
                A. Academic leave up 10 days may be granted without any
                financial assistance by the University if a teacher is not
                presenting any paper in the workshop or training programme with
                in India or abroad. <br /> <br />
                B. Teachers going under any international collaboration exchange
                programme with CSIR, DST, ICSSR, ICAR, MCI, DCI and other
                agencies of similar reputation may be provided financial
                assistance up to 50% of the travel expenses or Rs. 20,000/-
                whichever is less in addition to maximum 10 days academic
                leaves. However, the works and detailed plan of such visits
                should be submitted to the Director, R&D of the University .{" "}
                <br /> <br />
                C. Financial assistance to teachers for attending the
                conferences/seminars/symposia etc. at national level will be
                available once in two academic years. In such cases, the
                Financial assistance will be limited to travel expenditure
                actual train fare-AC-2 Tier or equivalent air fare and
                registration fee to a maximum of Rs. 10,000/-. <br />
                <br />
                D. Financial assistance to teachers for attending the
                conferences/seminars/symposia etc. abroad/ internationally will
                be available once in three academic years. In such cases, the
                financial assistance will be limited to travel expenditure and
                registration fee to a maximum of Rs. 20,000/- <br />
                <br />
                Deputation to attend conference in India will not be counted for
                the purpose of availing such assistance. <br />
                <br />
                E. Only one teacher per department will be allowed to attend the
                same conference in India or abroad. In case of two or more
                applicants for attending the same conference/seminars &
                symposium etc, the youngest teacher may be given preference over
                the other senior teacher to promote or update his /her knowledge
                and enable him/her to perform better. <br />
                <br />
                <h6>
                  <b>3. ELIGIBILITY FOR FINANCIAL ASSISTANCE: :- </b>
                </h6>
                <br />
                a). The teachers who are invited to attend
                national/international academic conference/seminars etc. should
                verify that the level of programme and the Institution
                organizing the events is truly the national/international,
                professional and capable of enhancing the skills of the
                participants.
                <br />
                b). The financial assistance may be provided in the following
                order of preferences:
                <br />
                <br />
                1. Teachers delivering keynote address/plenary lectures <br />
                2. Teachers contributing a paper
                <br />
                3. Teachers invited to chair a session <br />
                4. Teachers invited under international collaboration exchange
                programme <br />
                5. Teacher invited to give talks/lectures <br />
                c. The acceptance of papers from organizers should have been
                received. <br />
                d. Subject to all other conditions being equal, preference may
                be given to application who have already raised part financial
                support from other sources who are session Chairman/Organizing
                Committee Official / Award winner in addition to the paper
                presentation. Preference may also be given to those who have
                never been deputed to attend such conferences. <br />
                <br />
                4. PROCEDURE OF APPLYING FOR FINANCAL ASSISTANCE FOR ATTENDING
                SEMINAR/CONFERENCES/SYMPOSIA ETC TO ABROAD AND IN INDIA <br />
                <br />
                a). The prescribed application form for
                conference/symposia/seminar etc. in India and abroad is to be
                used. <br />
                <br />
                b). Application duly forwarding by the head of the Department
                with their specific recommendation (regarding eligibility and
                amount to be given), should reach the Office of the DIRECTOR,
                Research and development preferably 30 days before the date of
                the programme (even if , the acceptance letter is not received
                which should be submitted as soon as it is received) along with
                the following document:
                <br />
                <br />
                I. A soft copy of the full text of documents/papers prepared by
                the teacher for presentation at the National/International
                conference/seminars/symposia/ congress/workshops. The details of
                training programme, even if of short duration should be
                provided.
                <br />
                <br />
                II. Brief details of the organizers, title of the programme,
                place and duration of the conference etc. in which the paper is
                proposed to be presented or participation is desired.
                <br />
                <br />
                III. A copy of the letter of invitation from the organizers of
                the conference/seminar/symposium accepting the paper for
                presentation, immediately after it is received or a copy of the
                letter from the organizer inviting the teacher to chair a
                session/section and mentioning details of the financial support
                offered etc. should also be enclosed.
                <br />
                <br />
                IV. In case of conference / seminars / symposia / congress
                /workshops / training programme of short duration, the
                Invitation or other relevant documents should be attached.
                <br />
                <br />
                <h6>
                  <b>5. FOLLOW-UP ACTION FOR ABROAD AND INDIA :- </b>
                </h6>
                <br />
                a. The teachers not utilizing their sanctioned amount for
                whatever reason should immediately inform the Director, Research
                & Development within a week through the concerned Head to enable
                others to utilize the amount so released.
                <br />
                <br />
                b. Deputed teachers after attending conferences should provide a
                participation certificate and submit the bills within one month
                of return from the conference.
                <br /> <br />
                <h6>
                  <b>
                    6. DEPUTATION WITHOUT FINANCAL SUPPORT TO ABROAD AND IN
                    INDIA :-{" "}
                  </b>
                </h6>
                <br />
                a) Teacher seeking permission to attend
                conferences/seminars/symposia/ workshop / training programme in
                India or abroad without financial support from the University
                but (academic) leave only, should also follow the same procedure
                as mentioned in procedure of applying for financial assistance
                for attending conferences/seminars/symposia etc abroad and
                India.
                <br /> <br />
                b) Such teachers should fulfill all the requirements listed
                above, but the frequency restriction (once in 3 years only for
                conferences abroad and once in 2 years for conferences in India
                ) shall not apply to them.
                <br /> <br />
                c) Such teachers shall be granted leave as per University rules.
                <br />
                <br />
                d) For any conference /academic seminar etc., as a special case,
                Vice Chancellor may allow up to 50% of the Faculty member to
                attend such conference/training etc. without financial
                assistance, so that the teaching of the department should not
                suffer.
                <br /> <br />
              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
          <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                   </Link>
              </li>
              <li className="lli">
               <Link to="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Patent">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/mou-collaboration">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                   </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default ResearchPolicy;
