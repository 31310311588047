// import React, { useEffect, useState } from "react";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { TableCell, Box } from "@mui/material";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import SearchIcon from "@mui/icons-material/Search";
// import { CircularProgress } from "@mui/material";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import CardContent from "@mui/material/CardContent";
// import DatePicker from "react-datepicker";
// import * as XLSX from "xlsx";

// import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
// import { Button } from "@mui/material";

// const theme = createTheme({
//   typography: {
//     fontWeightBold: 700,
//   },
// });

// function TotalRegistered() {
//   const [sortBy, setSortBy] = useState("");
//   const [sortOrder, setSortOrder] = useState("asc");
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(6);

//   const [studentdata, setStudentData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch("https://sssutms.ac.in/apitest/admin/students/totallist"
// );
//         const data = await response.json();
//         // console.log(data, "response data");
//         setStudentData(data);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const filterData = (filteredData) => {
//     if (!Array.isArray(filteredData)) return [];
//     return filteredData.filter((student) => {
//       const studentType = student.StudentType || "Normal";
//       return (
//         student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         student.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
//         studentType.toLowerCase().includes(searchQuery.toLowerCase())
//       );
//     });
//   };

//   const getSortedAndSlicedData = () => {
//     const filteredData = filterData(studentdata);

//     const sortedData = [...filteredData].sort((a, b) => {
//       if (sortBy === "name") {
//         return sortOrder === "asc"
//           ? a.name.localeCompare(b.name)
//           : b.name.localeCompare(a.name);
//       }

//       return 0;
//     });

//     const startIndex = page * rowsPerPage;
//     return sortedData.slice(startIndex, startIndex + rowsPerPage);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleSearchChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const handleSort = (column) => {
//     if (column === sortBy) {
//       setSortOrder(sortOrder === "asc" ? "desc" : "asc");
//     } else {
//       setSortBy(column);
//       setSortOrder("asc");
//     }
//   };

//   const ExportToExcel = () => {
//     if (Array.isArray(studentdata)) {
//       const Excel = studentdata.map((student) => ({
//         Random_Id: student.randomId,
//         Random_Password: student.randomPassword,
//         Admitted_Date: student.createdAt,
//         Name: student.name,
//         Fathers_Name: student.fathersname,
//         Mothers_Name: student.mothersname,
//         Email: student.email,
//         Mobile: student.mobile,
//         Course_Type: student.courseType,
//         Course: student.courseName,
//         Branch: student.courseBranch,
//         StudentType: student.StudentType || "Normal",
//       }));

//       const worksheet = XLSX.utils.json_to_sheet(Excel);
//       const workbook = XLSX.utils.book_new();
//       XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Student");
//       XLSX.writeFile(workbook, "Student_Data.xlsx");
//     } else {
//       console.error("Student data is not an array. Cannot export to Excel.");
//     }
//   };
//   const [selectedDate, setSelectedDate] = useState(null);
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };

//   // console.log(studentdata, "studentdataaa");

//   return (
//     <>
//       <ThemeProvider theme={theme}>
//         <AdminDashboard />
//         <Box
//           sx={{
//             width: "90%",
//             height: "50%",
//             marginLeft: "100px",
//             marginTop: "80px",
//           }}
//         >
//           {loading ? (
//             <CircularProgress
//               color="success"
//               style={{ marginTop: "80px", marginLeft: "50%" }}
//             />
//           ) : (
//             <CardContent>
//               <Paper sx={{ width: "100%", overflow: "auto" }}>
//                 <Button
//                   style={{ float: "right", marginRight: "10px" }}
//                   variant="outlined"
//                   onClick={ExportToExcel}
//                 >
//                   Export to Excel
//                 </Button>
//                 <Box sx={{ p: 2 }}>
//                   <SearchIcon sx={{ mr: 1 }} />
//                   <input
//                     type="text"
//                     placeholder="Search  by ID or Name"
//                     value={searchQuery}
//                     onChange={handleSearchChange}
//                   />
//                 </Box>
//                 <TableContainer sx={{ maxHeight: "400px", overflowX: "auto" }}>
//                   <Table stickyHeader aria-label="sticky table">
//                     <TableHead>
//                       <TableRow>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             S.No.

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Enrollment No.

//                         </TableCell>

//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Registration ID

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Registration Password

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Registered Date

//                         </TableCell>

//                         <TableCell
//                           align="left"
//                           onClick={() => handleSort("name")}
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "190px",
//                             position: "sticky",
//                             zIndex: 2,color:'white',
//                             top: 0,
//                             left: 0,
//                           }}
//                         >

//                             Candidate Name
//                             {sortBy === "name" && sortOrder && (
//                               <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
//                             )}

//                         </TableCell>

//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "150px",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Father's Name

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "150px",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Mother's Name

//                         </TableCell>
//                         <TableCell
//                           align="center"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Email

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',  minWidth: "100px",color:'white',
//                           }}
//                         >

//                             Contact{" "}

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Course Type

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "250px",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Course Name

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "250px",
//                             position: "sticky",
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Course Branch

//                         </TableCell>
//                         <TableCell
//                           align="left"
//                           style={{
//                             backgroundColor: "rgb(2, 48, 71)",
//                             minWidth: "150px",
//                             position: "sticky",color:'white',
//                             top: 0,
//                             zIndex: 1,color:'white',
//                           }}
//                         >

//                             Student Type

//                         </TableCell>
//                       </TableRow>
//                     </TableHead>
//                     <TableBody>
//                       {getSortedAndSlicedData().map((student, index) => (
//                         <TableRow key={student.randomId}>
//                           <TableCell>
//                             {index + 1 + page * rowsPerPage}
//                           </TableCell>
//                           <TableCell style={{ color:"red"}}>
//                             {student.enrollmentNumber||"Not generated"}
//                           </TableCell>
//                           <TableCell style={{ fontWeight: 700 }}>
//                             {student.randomId}
//                           </TableCell>
//                           <TableCell
//                             style={{ color: "#780000", fontWeight: 700 }}
//                           >
//                             {student.randomPassword}
//                           </TableCell>
//                           <TableCell>
//                             <DatePicker
//                               selected={
//                                 selectedDate ||
//                                 (student?.createdAt
//                                   ? new Date(student.createdAt)
//                                   : null)
//                               }
//                               readOnly
//                               dateFormat="dd/MM/yyyy HH:mm" // Date and time format
//                               showTimeSelect
//                               timeFormat="HH:mm" // Specify the time format
//                               timeIntervals={15} // Set time intervals if needed
//                               className="text-left"
//                             />
//                           </TableCell>
//                           <TableCell
//                             style={{
//                               color: "#e85d04",
//                               fontWeight: 700,
//                               position: "sticky",
//                               left: 0,
//                               zIndex: 1,
//                               top: 0,

//                               backgroundColor: "white", // Ensure background color to avoid overlap issues
//                               // padding: '8px', // Add padding to create space
//                             }}
//                           >
//                             {student.name}
//                           </TableCell>

//                           <TableCell

//                           >
//                             {student.fathersname}
//                           </TableCell>
//                           <TableCell>{student.mothersname}</TableCell>
//                           <TableCell
//                             style={{ color: "#840032", fontWeight: 700 }}
//                           >
//                             {student.email}
//                           </TableCell>
//                           <TableCell align="left">{student.mobile}</TableCell>
//                           {/* <TableCell>{student.courseType}</TableCell> */}
//                           <TableCell
//                             style={{
//                               fontWeight: 700,
//                               color:
//                                 student.courseType === "UG"
//                                   ? "blue"
//                                   : student.courseType === "PG"
//                                   ? "#8d0801"
//                                   : student.courseType === "Diploma"
//                                   ? "purple"
//                                   : student.courseType === "PG_Diploma"
//                                   ? "green"
//                                   : "black",
//                             }}
//                           >
//                             {student.courseType|| "Not Choose"}
//                           </TableCell>

//                           <TableCell>{student.courseName || "Not Choose"}</TableCell>
//                           <TableCell>{student.courseBranch || "Not Choose"}</TableCell>

//                           <TableCell
//                             style={{
//                               color: student.StudentType ? "red" : "green",
//                             }}
//                           >
//                             {student.StudentType || "Normal"}
//                           </TableCell>
//                         </TableRow>
//                       ))}
//                     </TableBody>
//                   </Table>
//                 </TableContainer>
//                 <TablePagination
//                   rowsPerPageOptions={[10, 100, 200, 500]}
//                   component="div"
//                   count={studentdata.length}
//                   rowsPerPage={rowsPerPage}
//                   page={page}
//                   onPageChange={handleChangePage}
//                   onRowsPerPageChange={handleChangeRowsPerPage}
//                 />
//               </Paper>
//             </CardContent>
//           )}
//         </Box>
//       </ThemeProvider>
//     </>
//   );
// }

// export default TotalRegistered;

import React, { useEffect, useState, useMemo } from "react";
import {
  createTheme,
  ThemeProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CardContent from "@mui/material/CardContent";
import DatePicker from "react-datepicker";
import Skeleton from "@mui/material/Skeleton";
import * as XLSX from "xlsx";

import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function TotalRegistered() {
  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://sssutms.ac.in/apitest/admin/students/totallist"
        );
        const data = await response.json();
        setStudentData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Memoized filtered data
  const filteredData = useMemo(() => {
    return studentData.filter((student) =>
      [student.randomId, student.name].some((field) =>
        field.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  }, [studentData, searchQuery]);

  const handleSearchChange = (event) => setSearchQuery(event.target.value);
  const handlePageChange = (event, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const ExportToExcel = () => {
    if (filteredData.length) {
      const excelData = filteredData.map(
        ({
          randomId,
          randomPassword,
          createdAt,
          name,
          fathersname,
          mothersname,
          email,
          mobile,
          courseType,
          courseName,
          courseBranch,
          StudentType,
        }) => ({
          Random_Id: randomId,
          Random_Password: randomPassword,
          Admitted_Date: createdAt,
          Name: name,
          Fathers_Name: fathersname,
          Mothers_Name: mothersname,
          Email: email,
          Mobile: mobile,
          Course_Type: courseType,
          Course: courseName,
          Branch: courseBranch,
          StudentType: StudentType || "Normal",
        })
      );
      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Enrolled Students");
      XLSX.writeFile(workbook, "Student_Data.xlsx");
    }
  };

  return (
    <>
      <>
        <AdminDashboard />
        <Box
          sx={{
            width: "90%",
            height: "50%",
            marginLeft: "100px",
            marginTop: "80px",
          }}
        >
          {loading ? (
            <Skeleton
            variant="rectangular"
            width="100%"
            height={400}
            animation="wave"
            />
          ) : (
            <CardContent>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <Button
                  style={{ float: "right", marginRight: "10px" }}
                  variant="outlined"
                  onClick={ExportToExcel}
                >
                  Export to Excel
                </Button>
                <Box sx={{ p: 2 }}>
                  <SearchIcon sx={{ mr: 1 }} />
                  <input
                    type="text"
                    placeholder="Search  by ID or Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                <TableContainer sx={{ maxHeight: "400px", overflowX: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            color: "white",
                            left: 0,
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Registration ID
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Registration Password
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Registered Date
                        </TableCell>

                        <TableCell
                          align="left"
                          
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "190px",
                            position: "sticky",
                            zIndex: 2,
                            color: "white",
                            top: 0,
                            left: 50,
                          }}
                        >
                          Candidate Name
                          {/* {sortBy === "name" && sortOrder && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )} */}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Mother's Name
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "100px",
                            color: "white",
                          }}
                        >
                          Contact{" "}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Course Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Course Branch
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            minWidth: "150px",
                            position: "sticky",
                            color: "white",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Student Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((student, index) => (
                          <TableRow key={student.randomId}>
                            <TableCell
                              style={{
                                position: "sticky",
                                left: 0,
                                top: 0,
                                zIndex: 1,
                                backgroundColor: "white",
                              }}
                            >
                              {index + 1 + page * rowsPerPage}
                            </TableCell>
                            <TableCell
                              style={{ color: "#780000", fontWeight: 700 }}
                            >
                              {student.randomId}
                            </TableCell>
                            <TableCell>{student.randomPassword}</TableCell>
                            <TableCell>
                              <DatePicker
                                selected={
                                  student?.createdAt
                                    ? new Date(student.createdAt)
                                    : null
                                }
                                readOnly
                                dateFormat="dd/MM/yyyy HH:mm" // Date and time format
                                showTimeSelect
                                timeFormat="HH:mm" // Specify the time format
                                timeIntervals={15} // Set time intervals if needed
                                className="text-left"
                              />
                            </TableCell>
                            <TableCell
                              style={{
                                // color: "#e85d04",
                                fontSize: "15px",
                                fontWeight: 700,
                                position: "sticky",
                                left: 50,
                                zIndex: 1,
                                top: 0,

                                backgroundColor: "white", // Ensure background color to avoid overlap issues
                                // padding: '8px', // Add padding to create space
                              }}
                            >
                              {student.name}
                            </TableCell>

                            <TableCell
                              style={
                                {
                                  // padding: '8px', // Ensure consistent padding
                                }
                              }
                            >
                              {student.fathersname}
                            </TableCell>
                            <TableCell>{student.mothersname}</TableCell>
                            <TableCell
                              style={{ color: "#840032", fontWeight: 700 }}
                            >
                              {student.email}
                            </TableCell>
                            <TableCell align="left">{student.mobile}</TableCell>
                            {/* <TableCell>{student.courseType}</TableCell> */}
                            <TableCell
                              style={{
                                fontWeight: 700,
                                color:
                                  student.courseType === "UG"
                                    ? "blue"
                                    : student.courseType === "PG"
                                    ? "#8d0801"
                                    : student.courseType === "Diploma"
                                    ? "purple"
                                    : student.courseType === "PG_Diploma"
                                    ? "green"
                                    : "black",
                              }}
                            >
                              {student.courseType || "Not Choose"}
                            </TableCell>

                            <TableCell>
                              {student.courseName || "Not Choose"}
                            </TableCell>
                            <TableCell>
                              {student.courseBranch || "Not Choose"}
                            </TableCell>
                            {/* <TableCell>
                            {student.StudentType || "Normal"}
                          </TableCell> */}

                            <TableCell
                              style={{
                                color: student.StudentType ? "red" : "green",
                              }}
                            >
                              {student.StudentType || "Normal"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 100, 500]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Paper>
            </CardContent>
          )}
        </Box>
      </>
    </>
  );
}

export default TotalRegistered;
