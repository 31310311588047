import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function Webinar() {
  return (
    <>
      <Nav />

      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:40px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          Webinar
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-9 padding-section">
            <br />

            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              Webinar Detail{" "}
            </h5>

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>

            <table
              style={{
                width: "90%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }} colSpan="4">
                    UPCOMING EVENT{" "}
                  </th>
                </tr>
                <tr>
                  {" "}
                  <th>S.No</th> <th>Department</th> <th>Event</th>
                  <th>Title</th>
                </tr>
                <tr>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    1{" "}
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    POLYTECHNIC (ENGINEERING)
                  </td>
                  <td style={{ border: "1px solid #999", padding: "7px" }}>
                    WEBINAR
                  </td>
                  <td>RECENT TRENDS IN SUPPLY CHAIN MANAGEMENT</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>POLYTECHNIC (ENGINEERING)</td>
                  <td>WEBINAR</td>
                  <td>WASTE MANAGEMENT AND IT”S UTILIZATION</td>
                </tr>
              </tbody>
            </table>
            <br></br>
            <br></br>
            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              2020-2021{" "}
            </h5>

            <style>
              {`
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `}
            </style>

            <div className="tann">
              <table className="responsive-table">
                {" "}
                <thead>
                  <tr>
                    <th scope="col">Date</th> <th scope="col">Department</th>
                    <th scope="col">Topic</th>
                  </tr>
                </thead>{" "}
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}> 05/May/2021</td>
                    <td style={{ textAlign: "left" }}>
                      School of Homoeopathy, SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Role of AYUSH System & Medicines during Second Wave of
                      COVID-19
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Report
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>14/June/2021</td>{" "}
                    <td style={{ textAlign: "left" }}>
                      Faculty of Pharmacy, SSSUTMS
                    </td>{" "}
                    <td style={{ textAlign: "left" }}>
                      Webinar on Blood Donation Awareness
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure
                      </span>{" "}
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>19/June/2021</td>{" "}
                    <td style={{ textAlign: "left" }}>
                      School of Homoeopathy, SSSUTMS
                    </td>{" "}
                    <td style={{ textAlign: "left" }}>
                      Impact of Yogic Practices to Achieve Life Long Health on
                      The Occasion of International Yoga Day
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Report
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>05/July/2021</td>{" "}
                    <td style={{ textAlign: "left" }}>
                      School of Design, SSSUTMS
                    </td>{" "}
                    <td style={{ textAlign: "left" }}>
                      Understanding the Profession of Architecture{" "}
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        More Info
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <br></br>
            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              2019-20{" "}
            </h5>

            <div className="tann">
              <table className="responsive-table">
                {" "}
                <thead>
                  <tr>
                    <th scope="col">Date</th> <th scope="col">Department</th>
                    <th scope="col">Topic</th>
                  </tr>
                </thead>{" "}
                <tbody>
                  <tr>
                    <td style={{ textAlign: "left" }}>09/ May /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Design , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Designer’s Era: Post Covid 19
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>28-29/ May /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Engineering , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      {" "}
                      Fundamental Of MATLAB and Simulation
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>01/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Hotel Management , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      CAREER Management Post COVID-19
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>02/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Homoeopathy, SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Role of AYUSH System and Medicines in COVID-19
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>05/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Engineering , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      STTP on "Web-Development Using PHP"
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>06/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      College of Pharmacy, SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Massive Open Online Course(MOOC)
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>06/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Engineering , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Innovations in Solar Energy
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>07/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Pharmacy, SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Herbal Drugs for Boosting Immunity Against Novel CORONA
                      Virus
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>09/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Engineering , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Evolution Prospects of Technical Education
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }}>09/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Nursing , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      Holistic Nursing Approach toward COVID-19
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ textAlign: "left" }}>10/ June /2020</td>
                    <td style={{ textAlign: "left" }}>
                      School of Physical Education , SSSUTMS
                    </td>
                    <td style={{ textAlign: "left" }}>
                      International webinar on Healing & Health in Pandemic
                      <span style={{ color: "red", fontSize: "13px" }}>
                        {" "}
                        Brochure{" "}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-lg-3">
            <ul className="list-icon">
              <h4
                style={{
                  fontFamily: "'poppins',sansSerif",
                  color: "#243642",
                  textDecoration: "underline",
                }}
              >
                Activity
              </h4>
              <br></br>{" "}
              <li className="lli">
                <Link to="/expert_lectures">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Expert Lectures
                </Link>
              </li>
              <li className="lli">
                <Link to="/webinar">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Webinar
                </Link>
              </li>
              <li className="lli">
                <Link to="/industrial_visits">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Industrial
                  Visits
                </Link>
              </li>
              <li className="lli">
                <Link to="/activity/events">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Events
                </Link>
              </li>
              <li className="lli">
                <Link to="/fdp">
                  <i class="fa fa-adjust" aria-hidden="true"></i>FDP
                </Link>
              </li>
              <li className="lli">
                <Link to="/workshop_seminars">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Workshop &
                  Seminars
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br></br>

      <Footerpage />
    </>
  );
}

export default Webinar;
