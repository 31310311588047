
import React from 'react'
import AdminDashboard from './Admin_Dashboard/AdminDashboard'


function CourseType () {
  return (
    <>
   <AdminDashboard/>
    <h1 style={{marginTop:"10%", marginLeft:"50%"}}> CourseType</h1>
    </>
  )
}

export default  CourseType
