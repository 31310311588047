import React from "react";
import StudentDashboard from "../../AdminComponent/StudentDashboard";

function StudentDashboardHome() {
  return (
    <>
      <StudentDashboard />
      <h5 style={{ marginLeft: "250px", marginTop: "-600px" }}>
        Student Dashboard Home Coming soon
      </h5>
    </>
  );
}

export default StudentDashboardHome;
