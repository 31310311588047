import React from "react";
import VC from "../images/VC.png"
import v from "../images/Hemant.jpg";
import k from "../images/kanchan.jpg";
import s from "../images/sid.jpg";

const New = () => {
  return (
    <>
      <style>
        {`
  .onee h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .onee h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .onee h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
  }

  h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .con-fluid {
      width: 1350px;
  }
  .con{
      max-width: 1250px;
      margin-left:80px;
      margin-bottom:0;
  }


  .banner-flex {
    display: flex;
    align-items: center;
    height: 100vh;
  }

  .pp {
    width: 800px;
    margin-left: 160px;
  }
  .bnr-content {
    width: 480px;
    margin-left: 50px;
  }
  .bnr-content h6 {
    font-weight: 400;
    color: #e73a3e;
    font-size: 17px;
  }
  .bnr-content h1 {
    margin-top: 20px;
    font-size: 63px;
  }
  .bnr-content p {
    margin-top: 15px;
    font-size: 17px;
    color: #606060;
    line-height: 1.8rem;
  }
  .bnr-content .btn {
    padding: 11px 40px;
    margin-top: 30px;
  }

  .team-member-image {
    position: relative;
  }
  .team-member-image img {
    max-width: 100%;
  }
  .team-member-image .tm-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .team-member-image .tm-icon ul {
  }
  .team-member-image .tm-icon ul li {
    transition: .5s ease-in-out;
    transform: translateY(40px);
    visibility: hidden;
    opacity: 0;
  }
  .team-member-image:hover .tm-icon ul li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .team-member-image:hover .tm-icon ul li:nth-child(1) {
    transition-delay: .2s;
  }
  .team-member-image:hover .tm-icon ul li:nth-child(2) {
    transition-delay: .3s;
  }
  .team-member-image:hover .tm-icon ul li:nth-child(3) {
    transition-delay: .4s;
  }
  .team-member-image:hover .tm-icon ul li:nth-child(4) {
    transition-delay: .5s;
  }
  .team-member-image .tm-icon ul li a {
    color: #000;
    width: 35px;
    height: 35px;
    background: #fff;
    border-radius: 50%;
    line-height: 35px;
    display: inline-block;
    font-size: 20px;
    transition: .5s;
  }
  .team-member-image .tm-icon ul li a:hover {
    color: #fff;
    background: #e73a3e;
  }
  .team-member-content {
    margin-top: 20px;
  }
  .team-member-content h5 {
    color: #173B45;
    font-family: "Inter", sans-serif;
  }
  .team-member-content span {
    color: #5F6F65;
    font-size: 16px;
    position: relative;
    top: -5px;
    font-weight: 400;
  }

@media screen and (max-width: 480px) {

.pp{
width:320px;
text-align:justify;

margin-left:-44px;
margin-top:15px;
}

.img {
    vertical-align: middle;
    border-style: none;
    margin-left: -30px;
}
.onee h1 {
   width:321px;
   font-size:31px;
   margin-left:-46px;
  }


  .team-member-content h5 {
    color: #173B45;
    font-family: "Inter", sans-serif;
    margin-top:-12px;
  }


  .team-member-content{
  margin-bottom:20px;}


   .bnr-content h1{
   font-size:20px;
   width:255px;}
   }
  `}
      </style>

      <section className="team-member active" style={{ marginTop: "-60px" }}>
        <div className="con">
          <div className="row">
            <div className="row">
              <div className="col-lg-12">
                <div className="apply-now-top text-center py-5">
                  <div className="onee">
                    <h1>University Officials</h1>

                    <p className="pp">
                      Senior faculty members of SSSUTMS play a significant role
                      they build the university and provide their contribution
                      to teaching and development within their department.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pb-5">
              <div className="col-lg-3">
                <div className="team-member-image">
                  <img
                    className="img"
                    src={s}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                </div>
                <div className="team-member-content">
                  <h5>Mr. Siddharth Kapoor</h5>
                  <span>Chancellor</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="team-member-image">
                  <img
                    className="img"
                    src={VC}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                </div>
                <div className="team-member-content">
                  <h5>Dr. Mukesh Tiwari </h5>
                  <span> Vice Chancellor</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="team-member-image">
                  <img
                    className="img"
                    src={v}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                </div>
                <div className="team-member-content">
                  <h5> Dr. Hemant Kumar Sharma</h5>
                  <span>Registrar</span>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="team-member-image">
                  <img
                    className="img"
                    src={k}
                    alt=""
                    style={{ height: "400px", width: "400px" }}
                  />
                </div>
                <div className="team-member-content">
                  <h5>Dr. Kanchan Shrivastava</h5>
                  <span>Deputy Registrar</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default New;
