import React, { useState, useEffect, useRef } from "react";
import { Table, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import Sidebar from "../AdminComponent/HodDashboard";
import DatePicker from "react-datepicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import swal from "sweetalert";
import SearchIcon from "@mui/icons-material/Search";
import {
  CardContent,
  InputAdornment,
  TableRow,
  Paper,
  TableBody,
  TextField,
  TableHead,
  TableContainer,
  TableCell,
} from "@mui/material";

const theme = createTheme();

const Pending = () => {
  const [approvedStudents, setApprovedStudents] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState({});
  const [collegeAssignments, setCollegeAssignments] = useState({});
  const [buttonLoading, setButtonLoading] = useState(null); // Tracks loading state for buttons
  const [colleges, setColleges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [branches, setBranches] = useState([]); // Holds branches for the selected course
  const [allBranches, setAllBranches] = useState([]); // Holds all course and branches data
  const [selectedBranch, setSelectedBranch] = useState(""); // State for selected branch
  const initialFetchDone = useRef(false);
  const [selectedCourse, setSelectedCourse] = useState(""); // State for the selected course
  const [searchQuery, setSearchQuery] = useState("");

  const userData = JSON.parse(sessionStorage.getItem("AnkitHOD"));
  const hodId = userData?.user;
  const branch1 = userData?.Branch || "";
  const course1 = userData?.courseName || "";
  const college = userData?.college || "";
  const name = userData?.name || "";
  const associate = userData?.Associated_branch || [];

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);
  ////////////////search///////////////
  const filteredStudents = approvedStudents.filter((student) => {
    const query = searchQuery.toLowerCase();
    return (
      student.name?.toLowerCase().includes(query) ||
      student.id?.toString().includes(query)
    );
  });
  
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClick = async (studentId) => {
    setButtonLoading(studentId);
    if (selectedCourses[studentId]) {
      try {
        const response = await axios.put(
          "https://sssutms.ac.in/apitest/approve",
          {
            hodId: hodId,
            studentId,
            assignedCollege: selectedCourses[studentId],
          }
        );
        swal({
          title: "Success",
          text: `${name} Approved Successfully!`,
          icon: "success",
          buttons: "OK",
        });

        setApprovedStudents((prevStudents) =>
          prevStudents.filter((student) => student._id !== studentId)
        );

        if (Array.isArray(response.data.students)) {
          setApprovedStudents(response.data.students);

          const initialAssignments = {};
          response.data.students.forEach((student) => {
            initialAssignments[student._id] = null;
          });
          setCollegeAssignments(initialAssignments);
        }
      } catch (error) {
        console.error(error);
        setApprovedStudents([]);
      } finally {
        setButtonLoading(null); // Clear loading state after completion
      }
    } else {
      swal({
        title: "Error",
        text: "Please assign college first!",
        icon: "error",
        buttons: "OK",
      });
      setButtonLoading(null); // Clear loading state
    }
  };

  const handleChangeCourse1 = (studentId, event) => {
    setSelectedCourses({
      ...selectedCourses,
      [studentId]: event.target.value,
    });
  };

  const handleCancel = async (studentId) => {
    try {
      const confirm = await swal({
        title: "Are you sure you want to cancel this student?",
        text: "If you cancel this student, a registration cancellation email will be sent to the student's email address.",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      });

      if (confirm) {
        // If the user confirms, proceed with the cancellation
        const response = await axios.post(
          process.env.REACT_APP_REQ_CANCEL_STD,
          {
            studentId,
          }
        );

        // Show success alert after cancellation
        swal({
          title: "Success",
          text: "Request Cancelled Successfully!",
          icon: "success",
          buttons: "OK",
        });

        // Update the state by removing the canceled student
        setApprovedStudents((prevStudents) =>
          prevStudents.filter((student) => student._id !== studentId)
        );
      }
    } catch (error) {
      console.error(error);
      // Optionally, you can show an error alert
      swal({
        title: "Error",
        text: "There was an issue cancelling the student.",
        icon: "error",
        buttons: "OK",
      });
    }
  };

  const fetchApprovedStudents = async (branch = branch1, course = course1) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/NewRegistrationRequests?branch=${branch1}&course=${course}&college=${college}`
      );

      if (Array.isArray(response.data.students)) {
        setApprovedStudents(response.data.students);

        const initialAssignments = {};
        response.data.students.forEach((student) => {
          initialAssignments[student._id] = null;
        });
        setCollegeAssignments(initialAssignments);
      }
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    fetchApprovedStudents(selectedBranch, selectedCourse);
  };

  const handleChangeBranch = (event) => {
    setSelectedBranch(event.target.value);
  };

  const handleChangeCourse = (e) => {
    const courseName = e.target.value;
    setSelectedCourse(courseName);

    // Filter branches based on the selected course name
    const filteredCourse = associate.find(
      (course) => course.courseName === courseName
    );
    const filteredBranches = filteredCourse
      ? filteredCourse.branches.map((branch) => branch.branchName)
      : [];

    setBranches(filteredBranches); // Update branches based on selected course
  };

  useEffect(() => {
    fetchApprovedStudents();
  }, []);

  useEffect(() => {
    const associatedBranches = JSON.parse(
      sessionStorage.getItem("Associated_branch")
    );
    if (associatedBranches) {
      setAllBranches(associatedBranches);
    }
  }, []);

  ///////////////////////////////////////colleges//////
  const fetchColleges = async (courseName) => {
    try {
      const response = await axios.get(
        `https://sssutms.ac.in/apitest/v1/hod/colleges/course?courseName=${courseName}`
      );
      setColleges(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (approvedStudents.length > 0 && !initialFetchDone.current) {
      initialFetchDone.current = true;
      const courseName = approvedStudents[0]?.courseName;
      fetchColleges(courseName);
    }
  }, [approvedStudents]);

  

  return (
    <>
      <ThemeProvider theme={theme}>
        <Sidebar />

        <style>
          {`
        .conttable{

            background-color: #e8e5d5;
            width: 90%;
            margin-left: 120px;
            margin-right: auto;
            margin-top: 80px;
            text-align: center;
            max-width: 1200px;

        }


        .login-input{
        width:200px;
        margin-bottom:20px;
        height:35px;}
        @media screen and (max-width: 500px){
          .custom-card-content{
            width: 100%; /* Adjust the percentage as needed */
            max-width: 1200px; /* Set a maximum width */
            margin: 0 auto; /* Center align */
          }

          h4{
            margin-top:90px;
          }
        }

    `}
        </style>

        <div className="conttable shadow p-3 bg-body rounded">
          <h4 style={{ fontFamily: "fantasy" }}>PENDING STUDENT</h4>

          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ minHeight: "200px", marginTop: "40px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : error || approvedStudents.length === 0 ? (
            <CardContent>
              <Paper sx={{ width: "100%", overflowX: "auto" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedCourse}
                    onChange={handleChangeCourse}
                  >
                    <option value="">Select Course</option>
                    {associate.map((course, index) => (
                      <option key={index} value={course.courseName}>
                        {course.courseName}
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedBranch}
                    onChange={handleChangeBranch} // Update the selected branch
                    disabled={!selectedCourse} // Disable until a course is selected
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>

                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "-15px",
                      width: "90px",
                      padding: "2px",
                      height: "35px",
                      backgroundColor: "#023047",
                      color: "white",
                    }}
                    onClick={handleSearch}
                    disabled={!selectedBranch}
                  >
                    Submit
                  </Button>
                  <TextField
                  
                    variant="outlined"
                    placeholder="Search by Name or ID"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: 300 }}
                  />
                </div>

                <TableContainer sx={{ maxHeight: "440px", overflowX: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            fontSize: "15px",
                            zIndex: 2,
                          }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            position: "sticky",
                            fontSize: "15px",
                            top: 0,
                          }}
                        >
                          Session
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "120px",
                            fontSize: "15px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            fontSize: "15px",
                            top: 0,
                            zIndex: 2,
                            left: 40,
                          }}
                        >
                          Student Name
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          DOB
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "150px",
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Mother's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Contact
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Nationality
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Qualification
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Qualification %
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Course Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Course Branch
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          align="center"
                          style={{
                            padding: "50px",
                            fontFamily: "montserrat",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          NO DATA FOUND
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          ) : (
            <CardContent>
              <Paper sx={{ width: "100%", overflowX: "auto" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedCourse}
                    onChange={handleChangeCourse} // Update the selected course
                  >
                    <option value="">Select Course</option>
                    {associate.map((course, index) => (
                      <option key={index} value={course.courseName}>
                        {course.courseName}
                      </option>
                    ))}
                  </select>

                  <select
                    className="form-select form-select-sm login-input"
                    required={true}
                    value={selectedBranch}
                    onChange={handleChangeBranch} // Update the selected branch
                    disabled={!selectedCourse} // Disable until a course is selected
                  >
                    <option value="">Select Branch</option>
                    {branches.map((branch, index) => (
                      <option key={index} value={branch}>
                        {branch}
                      </option>
                    ))}
                  </select>

                  <Button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      marginTop: "-15px",
                      width: "90px",
                      padding: "2px",
                      height: "35px",
                      backgroundColor: "#023047",
                      fontSize: "15px",
                      color: "white",
                    }}
                    onClick={handleSearch}
                    disabled={!selectedBranch}
                  >
                    Submit
                  </Button>
                  <TextField
                   style={{
                    float: "right",
                    marginLeft: "30%",
                    marginBottom: "10px",
                 
                  }}
                  variant="outlined"
                  placeholder="Search by Name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: 300 }}
                />
                </div>

                <TableContainer sx={{ maxHeight: "440px", overflowX: "auto" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 2,
                          }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Session
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "120px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "60px",
                          }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "50px",
                          }}
                        >
                          Student Name
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: "250px",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          DOB
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Mother's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Contact
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Nationality
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Qualification
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "150px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Qualification %
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "130px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Course Type
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Course Branch
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "250px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Assign College
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            minWidth: "200px",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Check Marksheet
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{
                            backgroundColor: "#023047",
                            fontSize: "15px",
                            color: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(filteredStudents) &&
                        filteredStudents.map((student, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              style={{
                                position: "sticky",
                                left: 0,
                                color: "red",
                                fontWeight: "bold",
                                backgroundColor: "#fff",
                                zIndex: 1,
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">
                              {student.admissionSession}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                // fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                                top: "0",
                                left: "60px",
                                zIndex: "1",
                                position: "sticky",
                              }}
                            >
                              {student.StudentType || "Normal"}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: "#6a040f",
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                                top: "0",
                                left: "50px",
                                zIndex: "1",
                                position: "sticky",
                              }}
                            >
                              {student.name}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                fontWeight: "bold",
                                // color: "purple",
                                fontFamily: "'Open Sans', sans-serif",
                                position: "sticky",
                                left: "240px",
                                zIndex: "1",
                                top: "0",
                              }}
                            >
                              {student.email}
                            </TableCell>
                            <TableCell align="left">
                              <DatePicker
                                selected={new Date(student.dob)}
                                readOnly
                                dateFormat="dd/MM/yyyy"
                                className="text-center"
                              />
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.fathersname}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.mothersname}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {student.mobile}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontFamily: "'Open Sans', sans-serif" }}
                            >
                              {student.domicile}
                            </TableCell>
                            <TableCell align="center">
                              {student.qualification}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {`${student.LastPercentage || student.qualificationPercentage}%`}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ fontFamily: "'Open Sans', sans-serif" }}
                            >
                              {student.courseType}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                color: "#344e41",
                                fontWeight: "bold",
                                fontFamily: "'Open Sans', sans-serif",
                              }}
                            >
                              {student.courseName}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                                fontWeight: "bold",
                              }}
                            >
                              {student.courseBranch}
                            </TableCell>

                            <TableCell align="center">
                              <Form.Select
                                style={{ height: "40px", width: "45vh" }}
                                value={selectedCourses[student._id] || ""}
                                onChange={(event) =>
                                  handleChangeCourse1(student._id, event)
                                }
                              >
                                <option value="">Select College</option>
                                {colleges.map((college) => (
                                  <option
                                    key={college._id}
                                    value={college.name}
                                  >
                                    {college.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </TableCell>

                            <TableCell align="center">
                              {student && student.Documents ? (
                                student.Documents.marksheet12th ? (
                                  <a
                                    href={student.Documents.marksheet12th}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View 12th Marksheet
                                  </a>
                                ) : student.Documents
                                    .undergraduateCertificate ? (
                                  <a
                                    href={
                                      student.Documents.undergraduateCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View Undergraduate Marksheet
                                  </a>
                                ) : student.Documents.ItImarksheet ? (
                                  <a
                                    href={student.Documents.ItImarksheet}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View ITI Marksheet
                                  </a>
                                ) : student.Documents
                                    .postgraduateCertificate ? (
                                  <a
                                    href={
                                      student.Documents.postgraduateCertificate
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View Postgraduate Marksheet
                                  </a>
                                ) : student.Documents.marksheet10th ? (
                                  <a
                                    href={student.Documents.marksheet10th}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View 10th Marksheet
                                  </a>
                                ) : student.Documents.diplomaMarksheet ? (
                                  <a
                                    href={student.Documents.diplomaMarksheet}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "blue" }}
                                  >
                                    View Diploma Marksheet
                                  </a>
                                ) : (
                                  "No Marksheet Available"
                                )
                              ) : (
                                "No Marksheet Available"
                              )}
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "20px",
                              }}
                            >
                              <Button
                                variant="success"
                                style={{
                                  height: "35px",
                                  padding: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => handleClick(student._id)}
                                disabled={buttonLoading === student._id} // Disable button while loading
                              >
                                {buttonLoading === student._id ? (
                                  <Spinner
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    style={{ marginRight: "5px" }}
                                  />
                                ) : (
                                  "Approve"
                                )}
                              </Button>

                              <Button
                                variant="danger"
                                style={{
                                  height: "35px",
                                  padding: "5px",
                                  marginTop: "-5px",
                                }}
                                onClick={() => handleCancel(student._id)}
                              >
                                Cancel
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          )}
        </div>
      </ThemeProvider>
    </>
  );
};

export default Pending;
