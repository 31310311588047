import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import { Button, Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Id_Search from "./Id_Card_Table";
import Id_Sidebar from "./Id_Sidebar";
import * as XLSX from "xlsx";

function Id_Card_Search() {
  const [key, setKey] = useState("first");
  const [session, setSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [filteredColleges, setFilteredColleges] = useState([]);
  const [studentdata, setStudentData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [searchResult, setSearchResult] = useState(null);
  const [dataNotFound, setDataNotFound] = useState(false);

  const handleSearch = async () => {
    if (!session || !courseType || !course || !branch || !college) {
      alert("Please fill all fields first");
      return;
    }
    try {
      const url = "https://sssutms.ac.in/apitest/v2/admin/idcard";
      const requestData = {
        admissionSession: session,
        courseType,
        courseName: course,
        courseBranch: branch,
      };

      const response = await axios.post(url, requestData);
      const data = response.data;

      if (
        !data ||
        !Array.isArray(data.students) ||
        data.students.length === 0
      ) {
        setDataNotFound(true);
      } else {
        setDataNotFound(false);
        setStudentData(data.students);
   
      }
    } catch (error) {
      console.log(error);
    }

    setShowTable(true);
  };

  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses3")
      .then((response) => {
        setData(response.data.courses);
        setData2(response.data.colleges);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    const shadowBox = document.querySelector(".shadow-box");
    if (shadowBox) {
      shadowBox.style.height = key === "first" ? "440px" : "250px";
    }
  }, [key]);

  useEffect(() => {
    const colleges = data2
      .flatMap((college) => college.courses)
      .filter((courseItem) => courseItem.courseName === course)
      .map((college) => college.collegeName);

    setFilteredColleges(colleges);
  }, [course, data2]);

  useEffect(() => {
    setBranch("");
    setCollege("");
  }, [course]);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };

  const getColleges = () => {
    if (!course) return [];
    const colleges = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) => courseItem.colleges);
    return colleges;
  };

  const labelStyle = {
    color: "#264653",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "'Rubik', sans-serif",
  };
 
  const handleExportToExcel = async() => {
    // if (studentdata.length === 0) {
    //   alert("No student data available for export.");
    //   return;
    // }
    const apiUrl = "https://sssutms.ac.in/apitest/v1/all-record-excel";
  
    const response = await  axios({
        url: apiUrl,
        method: "GET",
    })
     const studentdata = response.data.students

    const excelData = studentdata.map((student) => ({
      Name: student.name,
      Fathers_Name: student.fathersname,
      Admission_Session: student.admissionSession,
      college:student.assignedCollege,
      Enrollment_number: student.enrollmentNumber,
      Course_Type: student.courseType,
      Course_Name: student.courseName,
      Branch: student.courseBranch,
      Random_Id: student.randomId,
      Email: student.email,
      Mobile: student.mobile,
      photoUrl:student.Documents.applicantPhoto,
      Signature:student.Documents.applicantSignature
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Paid Student");
    XLSX.writeFile(workbook, "All Students.xlsx");
  };

  return (
    <>
      <Id_Sidebar />
      <style>
        {`
          .shadow-box {
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 10px;
            padding: 10px;
            margin-top: 20px;
            width: 100%;
            margin-left:75px;
            height:390px;
          }
          .custom-tabs .nav-item {
            height: 30px;
          }
          .custom-tabs .nav-link {
            padding: 0px;
            font-size: 14px;
          }
          .custom-tab {
            padding: 5px 10px;
            font-size: 14px;
          }
          #admission-tabs .nav-link {
            padding: 5px 10px;
            font-size: 14px;
          }
          .header {
            height: 40px;
            background-color: #001d3d;
            border-radius: 10px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            font-family: italic;
          }
          .login-input {
            margin-bottom:8px;
          }
          .login-input:focus {
            outline: none;
            border-bottom: 2px solid #344e41;
          }
          @media (max-width: 768px) {
            .shadow-box {
              padding: 10px;
              margin-top: 10px;
            }
            .header {
              font-size: 16px;
              height: 40px;
            }
            .login-input {
              font-size: 0.9rem;
            }
          }
          @media (max-width: 576px) {
            .shadow-box {
              margin: 10px;
              margin-Left:76px;
            }
            .container-fluid {
              width: 100%;
              padding-right: 0;
              padding-left: 0;
            }
          }
          @media (min-width: 992px) {
            .custom-laptop-style {
              margin-left: 5%;
            }
          }
        `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "550px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box">
            <h1
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Generate ID Cards
            </h1>

            <Button
              type="button"
              style={{
                backgroundColor: "#004e92",
                width: "120px",
                height: "35px",
                padding: "5px",
              }}
            onClick={handleExportToExcel}
            >
              Export to Excel
            </Button>
            <form style={{ padding: "15px", marginTop: "-10px" }}>
              <Row>
                <FormControl>
                  <label style={labelStyle}>Session</label>
                  <select
                    className="form-select form-select-sm login-input"
                    required
                    onChange={(e) => setSession(e.target.value)}
                    value={session}
                  >
                    <option
                      style={{
                        fontFamily: "'Rubik', sans-serif",
                        fontWeight: 700,
                      }}
                      value=""
                    >
                      Select
                    </option>
                    <option
                      style={{
                        fontFamily: "'Rubik', sans-serif",
                        fontWeight: 700,
                      }}
                      value="2023"
                    >
                      2023
                    </option>
                    <option
                      style={{
                        fontFamily: "'Rubik', sans-serif",
                        fontWeight: 700,
                      }}
                      value="2024"
                    >
                      2024
                    </option>
                  </select>
                </FormControl>
              </Row>
              <Row>
                <FormControl>
                  <label style={labelStyle}>Course Type</label>
                  <select
                    className="form-select form-select-sm login-input"
                    required
                    onChange={(e) => setCourseType(e.target.value)}
                    value={courseType}
                  >
                    <option
                      style={{
                        fontFamily: "'Rubik', sans-serif",
                        fontWeight: 700,
                      }}
                      value=""
                    >
                      Select
                    </option>
                    {getCourseTypes().map((type, index) => (
                      <option
                        style={{
                          fontFamily: "'Rubik', sans-serif",
                          fontWeight: 700,
                        }}
                        key={index}
                        value={type}
                      >
                        {type}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </Row>
              <Row>
                <FormControl>
                  <label style={labelStyle}>Course</label>
                  <select
                    className="form-select form-select-sm login-input"
                    required
                    onChange={(e) => {
                      setCourse(e.target.value);
                      setBranch("");
                      setCollege("");
                    }}
                    value={course}
                  >
                    <option value="">Select</option>
                    {getCourses().map((courseName, index) => (
                      <option
                        style={{
                          fontFamily: "'Rubik', sans-serif",
                          fontWeight: 700,
                        }}
                        key={index}
                        value={courseName}
                      >
                        {courseName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </Row>
              <Row>
                <FormControl>
                  <label style={labelStyle}>Branch</label>
                  <select
                    className="form-select form-select-sm login-input"
                    required
                    onChange={(e) => setBranch(e.target.value)}
                    value={branch}
                  >
                    <option
                      style={{
                        fontFamily: "'Rubik', sans-serif",
                        fontWeight: 700,
                      }}
                      value=""
                    >
                      Select
                    </option>
                    {getBranches().map((branchName, index) => (
                      <option
                        style={{
                          fontFamily: "'Rubik', sans-serif",
                          fontWeight: 700,
                        }}
                        key={index}
                        value={branchName}
                      >
                        {branchName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </Row>

              <Row>
                <FormControl>
                  <label style={labelStyle}>College *</label>
                  <select
                    className="form-select form-select-sm login-input"
                    required
                    onChange={(e) => setCollege(e.target.value)}
                    value={college}
                  >
                    <option value="">Select</option>
                    {getColleges().map((collegeName, index) => (
                      <option
                        style={{
                          fontFamily: "'Rubik', sans-serif",
                          fontWeight: 700,
                        }}
                        key={index}
                        value={collegeName}
                      >
                        {collegeName}
                      </option>
                    ))}
                  </select>
                </FormControl>
              </Row>

              <Col>
                <Button
                  type="button"
                  style={{
                    // marginLeft: "70px",
                    backgroundColor: "#004e92",
                    width: "90px",
                    height: "35px",
                    padding: "5px",
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Col>
              <Col></Col>
            </form>
          </div>
        </div>
       
        <div style={{ width: "90%" }}>
          {showTable && (
            <div>
              <Id_Search
                studentdata={studentdata}
                searchResult={searchResult}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Id_Card_Search;
