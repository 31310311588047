import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function NPTEL() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:60px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-50px;
          margin-left:10%;

          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
        NPTEL
        </h2>
        <hr className="title-hr"></hr> <br/>
    
        {/* <h2
  className="h3 chan text-bold"
  style={{
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    fontSize: "20px", // Font size बढ़ाने के लिए
    fontWeight: "bold", // Bold बनाए रखने के लिए
    color:"#370617"
  }}
>
  RESEARCH & DEVELOPMENT (R&D)
</h2> */}
        <div className="row dirr">
          
         
          {/* </div> */}

          <div className="col-lg-9 padding-section" style={{marginLeft:"50px"}}>
            {/* <div className="left_text_testi_contetn"> */}
            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif",marginTop:"1px" }}
              >
        <h6><b>About NPTEL</b></h6>
<br/>
The National Programme on Technology Enhanced Learning (NPTEL) is a project initiated and handled by seven Indian Institute of Technology (IIT-Bombay, Delhi, Kanpur, Kharagpur, Madras, Roorkee and Guwahati) and Indian Institute of Science, Bangalore. It is project funded by MHRD, Government of India, to develop and promote multimedia and web technology-based learning open for all. Currently more than 940 courses are available on web portal http://nptel.ac.in for viewing and downloading. NPTEL has also initiated open online courses with certification where courses in different domains are regularly launched. Courses are free to enroll and are available at http://onlinecourses.nptel.ac.in. At the end of the course a certification exam (optional) is held on specific dates at specific centers. Certificate from IIT is awarded to those who register and appear for the examination. These exams have nominal fees with facility of scholarship and partial fee waiver for SC/ST candidates <br/><br/>

<h6><b>Current ongoing courses are available at  :<span style={{color:"#588157", marginLeft:"10px"}}>Onlinecourses.np</span> </b></h6> <br/><br/>

<h6><b>NOTE: Kindly make note while enrolling the course: :-</b></h6>  <br/>

Use a unique email id throughout the course run. <br/>
Select 'Yes' to the query - Are you a part of NPTEL Local Chapter. <br/>
Choose the correct Local Chapter from the drop-down list while enrolling. <br/>
Students - Enter college roll number (college id number) .
Faculty - Enter college Employee id no. <br/><br/>


<h6><b>Important Link :-</b></h6> <br/>
<span style={{color:"#0077b6"}}><Link to = "https://nptel.ac.in/">Video on How to Enroll to the NPTEL Online Courses </Link></span> <br/>
<span style={{color:"#0077b6"}}><Link to = "https://nptel.ac.in/">Video on How to Enroll to the NPTEL Online Courses </Link></span> <br/>
<span style={{color:"#0077b6"}}><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/NPTEL/Guidelines for Mentors.pdf'>Guidelines for Mentors </Link> </span><br/>
<span style={{color:"#0077b6"}}><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/NPTEL/Mentors flow in NPTEL Local Chapters.pdf'>Mentors flow in NPTEL Local Chapters </Link> </span><br/>





              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
          <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                   </Link>
              </li>
              <li className="lli">
               <Link to="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Patent">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/mou-collaboration">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                   </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default NPTEL;

