import React from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function SubBranchMaster() {
  return (
    <>
      <AdminDashboard/>
      <h1 style={{ marginTop: "10%", marginLeft: "50%" }}>
        {" "}
        SubBranchMaster
      </h1>
    </>
  );
}

export default SubBranchMaster;

