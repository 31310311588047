import React, { useEffect, useState } from "react";
import {
  Box,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import SearchIcon from "@mui/icons-material/Search";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

const StudentRegistrationList = () => {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [studentdata, setStudentData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_STD_TODAY_LIST);
        const data = await response.json();
        setStudentData(data.students);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    filterData();
  }, [searchQuery, studentdata]);

  const filterData = () => {
    const filtered = studentdata.filter(
      (student) =>
        student.randomId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // /////////////////////////////////name sort/////////////////////////
  const getSortedAndSlicedData = () => {
    // Sort the data
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      }
      // Handle other columns for sorting if needed
      return 0;
    });

    // Get the sliced data based on current page and rows per page
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />
      <>
        <Box sx={{ width: "90%", marginLeft: "100px", marginTop: "80px" }}>
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent>
              <Paper sx={{ width: "100%", overflow: "auto" }}>
                <Box sx={{ p: 2 }}>
                  <SearchIcon sx={{ mr: 1 }} />
                  <input
                    type="text"
                    placeholder="Search  by ID or Name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Box>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: 0,
                            color: "white",
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            minWidth: "160px",
                            left: 50,
                            color: "white",
                          }}
                        >
                          Enrollment Status
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Registered Date
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "100px",
                          }}
                        >
                          Registration ID
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "120px",
                          }}
                        >
                          Registration Password
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            left: 210,
                            zIndex: 2,
                            color: "white",
                            minWidth: "200px",
                          }}
                          // /////////////for sorting name//////////////////
                          onClick={() => handleSort("name")}
                        >
                          Student Name
                          {/* /////////////name sort////////////////////////// */}
                          {sortBy === "name" && (
                            <span>{sortOrder === "asc" ? " ↑" : " ↓"}</span>
                          )}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "200px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Father's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "200px",
                            color: "white",
                          }}
                        >
                          Mother's Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Contact
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "250px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Course Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "250px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Branch
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedAndSlicedData().map((student, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 50,
                              top: 0,
                              fontWeight: 700,
                              zIndex: 1,

                              backgroundColor: "white",
                              color: "red",
                            }}
                          >
                            {student?.enrollmentNumber || "Not Generated"}
                          </TableCell>
                          <TableCell align="left">
                            <DatePicker
                              selected={
                                student?.createdAt
                                  ? new Date(student.createdAt)
                                  : null
                              }
                              readOnly
                              dateFormat="dd/MM/yyyy HH:mm"
                              className="text-left"
                            />
                          </TableCell>

                          <TableCell align="left">
                            {student?.randomId}
                          </TableCell>
                          <TableCell align="left">
                            {student?.randomPassword}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              // color: "#e85d04",
                              fontWeight: "bold",
                              position: "sticky",
                              left: 210,
                              backgroundColor: "white",
                            }}
                          >
                            {student?.name}
                          </TableCell>
                          <TableCell align="left">
                            {student?.fathersname}
                          </TableCell>
                          <TableCell align="left">
                            {student?.mothersname}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ color: "#840032", fontWeight: 700 }}
                          >
                            {student?.email}
                          </TableCell>
                          <TableCell align="left">{student?.mobile}</TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            {student && student.courseName
                              ? student.courseName
                              : "Not Choose"}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ fontWeight: "bold" }}
                          >
                            {student && student.courseBranch
                              ? student.courseBranch
                              : "Not Choose"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[1, 2, 25, 100]}
                  component="div"
                  count={filteredData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </CardContent>
          )}
          {/* </Card> */}
          <br></br>
        </Box>
      </>
    </ThemeProvider>
  );
};

export default StudentRegistrationList;
