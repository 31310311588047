import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from 'react-router-dom';


function Proctorial_Board() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
    
.contentleader
{
text-align:justify;
width:100%;
margin-top:20px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */}
   
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:80px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        // margin-left:60px;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          {" "}
          Proctorial Board
        </h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-8 padding-section">
            <br />

            <h6
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              MEMBER OF PROCTORIAL BOARD
            </h6>

            <div
              className="contentleader"
              style={{
                fontFamily: "'poppins',sansSerif",
                marginTop: "-10px",
                marginLeft: "10px",
              }}
            >
              To maintain the cordial atmosphere in the university campus (among
              the Students, faculty members and non-teaching staff). Sri Satya
              Sai University of Technology & Medical Sciences, Sehore hereby
              constituted the Proctorial Board headed by the Vice Chancellor,
              Chief Proctor, helps students, faculty members and non-teaching
              staffs from any difficulty and to see that the disciplinary rules
              are followed properly. Proctorial Board comprises of following
              members :-
            </div>
            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>

            <br></br>

            <table
              style={{
                width: "80%",
                marginLeft: "70px",
                marginTop: "10px",
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  {" "}
                  <th>S.No</th> <th>NAME</th> <th>DESIGNATION</th>
                </tr>

                <tr>
                  <td>1</td>
                  <td>Dr. Mukesh Tiwari</td>
                  <td>Vice-Chancellor</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Dr. Hemant Sharma</td>
                  <td> Registrar</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td> Dr. R.S. Kushwaha</td>
                  <td> Dean (Engg)</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Dr. Neelesh Choubey</td>
                  <td>Professor (Pharm.)</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Mr. H. S. Raghuvanshi</td>
                  <td>Chief Administrative Officer (CAO) </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-lg-4">
            <ul className="list-icon">
              <h4
                style={{
                  fontFamily: "'poppins',sansSerif",
                  color: "#243642",
                  textDecoration: "underline",
                }}
              >
                Committee
              </h4>
              <br></br>{" "}
              <li className="lli">
                <Link to="/anti-ragging">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Anti Ragging
                </Link>
              </li>
              <li className="lli">
                <Link to="/proctorial-board">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Proctorial
                  Board
                </Link>
              </li>
              <li className="lli">
                <Link to="/internal-complaint">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Internal
                  Complaint
                </Link>
              </li>
              <li className="lli">
                <Link to="/grievance-redressal">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Grievance
                  Redressal
                </Link>
              </li>
              <li className="lli">
                <Link to="/sc-st">
                  <i class="fa fa-adjust" aria-hidden="true"></i>For SC-ST
                </Link>
              </li>
              <li className="lli">
                <Link to="/edc">
                  <i class="fa fa-adjust" aria-hidden="true"></i>EDC
                </Link>
              </li>
              <li className="lli">
                <Link to="/international-higher-education-cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>International
                  Higher Education Cell
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <br></br>

      <Footerpage />
    </>
  );
}

export default Proctorial_Board;
