import React, { useState, useEffect } from "react";
import { Button, Row, Col } from "react-bootstrap";
import axios from "axios";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";
import BranchMapping from "./BranchMapping";


function Branchmapping_Search() {
  const [session, setSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [subBranches, setSubBranches] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses2")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    if (branch) {
      axios
        .get(
          `https://sssutms.ac.in/apitest/v1/semester-scheme2?courseType=${courseType}&courseName=${course}&courseBranch=${branch}`
        )
        .then((response) => {
          setSubBranches(response.data.SubBranches || []);
        })
        .catch((error) => {
          console.error("Error fetching sub-branches:", error);
        });
    }
  }, [branch]);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };

  const handleSearch = () => {
    setShowTable(true)
  };

  return (
    <>
      <AdminDashboard />
      <style>
        {`

      .custom-dropdown select {
     font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: black; /* default color for the select element */
  }

  .custom-dropdown option {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight:700
  }
             .shadow-box {
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
         border-radius: 10px;
         padding: 20px;
         margin-top: 70px;
         width: 100%; /* Adjusted width */
         margin-left:130px;
         height:170px;
         width:1180px
        
      }
         
  
            .header {
        height: 50px;
        // background: linear-gradient(#004e92, #990033);
        border-radius: 10px;
        color: #003049;
        font-weight:700;
        display: flex;
        margin-top:-50px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        
      }`}
      </style>

      <div
        className="shadow-box"
        fluid
        style={{ paddingTop: "50px", height: "170px" }}
      >
        <div className="header">BRANCH MAPPING WITH SESSION</div>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <select
              className="form-select"
              required
              onChange={(e) => setCourseType(e.target.value)}
              style={{ width: "250px", height: "45px" }}
            >
              <option value="">Course Type</option>
              {getCourseTypes().map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              required
              onChange={(e) => setCourse(e.target.value)}
              style={{ width: "250px", height: "45px" }}
            >
              <option value="">Course</option>
              {getCourses().map((courseName, index) => (
                <option key={index} value={courseName}>
                  {courseName}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              required
              onChange={(e) => setBranch(e.target.value)}
              style={{ width: "250px", height: "45px" }}
            >
              <option value="">Branch</option>
              {getBranches().map((branchName, index) => (
                <option key={index} value={branchName}>
                  {branchName}
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <select
              className="form-select"
              required
              onChange={(e) => setSession(e.target.value)}
              style={{ width: "250px", height: "45px" }}
            >
              <option value="">Sub Branch</option>
              {subBranches.map((subBranch, index) => (
                <option key={index} value={subBranch}>
                  {subBranch}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <Row>
          <Button
            style={{
              marginLeft: "50px",
              backgroundColor: "#001d3d",
              width: "110px",
              marginTop: "10px",
              height: "40px",
              padding: "5px",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Row>
      </div>
      <div style={{marginTop:'-50px',width:'95%',marginLeft:'30px'}}>
        {showTable && (
          <div>
          <BranchMapping/>
          </div>
        )}
      </div>
    </>
  );
}

export default Branchmapping_Search;
