import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";


function ExamSchedule() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1 style={{ marginTop: "-3%" }}> Exam Schedule</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
                <style>
                  {
                    `
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `
                  }
                </style>

                <div className="tann">
                  <h4 style={{ textDecoration: "underline", color: "#4f000b" }}><b >  Examination September – 2024  :- </b></h4><br />
                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BHMSTT.pdf'><b>Bachelor of Homoeopathic Medicine & Surgery [1 Professional As per 2022 New Scheme] 1 Year</b></Link></td>
                      </tr>


                    </tbody>
                  </table>

                  <br />
                  <h4 style={{ textDecoration: "underline", color: "#4f000b" }}><b > Supplementary Examination September-2024 :- </b></h4><br />
                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table ( NEP YEARLY SCHME )</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BA_III_YEAR_SEPTEMBER_2024_NEP_ANNUAL.pdf'><b>Bachelor of Arts Third Year (NEP Annual)</b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BBA_III_YEAR_SEPTEMBER_2024_NEP_ANNUAL.pdf'><b>
                          Bachelor of Business Administration Third Year (NEP Annual)</b></Link></td>
                      </tr>
                      <tr>
                        <td >3</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BCA_III_YEAR_SEPTEMBER_2024_NEP_ANNUAL.pdf'><b>Bachelor of Computer Application Third Year (NEP Annual)</b></Link></td>
                      </tr>
                      <tr>
                        <td >4</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BCOM_III_YEAR_SEPTEMBER_2024_NEP_ANNUAL.pdf'><b>

                          Bachelor of commerce Third Year (NEP Annual)</b></Link></td>
                      </tr>
                      <tr>
                        <td >5</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BSC_III_YEAR_SEPTEMBER_2024_NEP_ANNUAL.pdf'><b>Bachelor of Science Third Year (NEP Annual)</b></Link></td>
                      </tr>
                      <tr>
                        <td >6</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/PHD_CW_JUNE_2024.pdf'><b>Bachelor of Arts Third Year (NEP Annual)</b></Link></td>
                      </tr>
                      <tr>
                        <td >7</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/Diploma_Pharmacy_I___II_YEAR_SEPTEMBER_2024__1_.pdf'><b>Diploma in Pharmacy (I-II Year) Supplementary Examination Sept. – 2024</b></Link></td>
                      </tr>
                      <tr>
                        <td >8</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPES_I-III_YEAR_SEPTEMBER.pdf'><b>
                          Bachelor of Physical Education and Sports (I-III Year) SupplementaryExamination Sept. – 2024</b></Link></td>
                      </tr>


                    </tbody>
                  </table>

                  <br />
                  <h4 style={{ textDecoration: "underline", color: "#4f000b" }}><b >   Examination August – 2024 :- </b></h4><br />
                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table [ Examination Aug – 2024]</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BMLT_ALL_SUPPLY_AUG_2024.pdf'><b>Bachelor in Medical Lab Technician (I -III Year)  Supplementary</b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPT_1_YEAR_AUG_2024.pdf'><b>
                          Bachelor of Physiotherapy [First Year]</b></Link></td>
                      </tr>
                      <tr>
                        <td >3</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPT_ALL_SUPPLY_AUG_2024.pdf'><b>Bachelor of Physiotherapy (I - IV Year)  Supplementary</b></Link></td>
                      </tr>
                      <tr>
                        <td >4</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MMLT_1_YEAR_AUG_2024.pdf'><b>

                          Master of Medical Lab Technology (Haemotology) First Year</b></Link></td>
                      </tr>
                      <tr>
                        <td >5</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MPT_1_YEAR_AUG_2024.pdf'><b>
                          Master of Physiotherapy (Orthopaedic) First Year</b></Link></td>
                      </tr>
                      <tr>
                        <td >6</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/PARAMEDICAL_DIPLOMA_1_YEAR_ALL_AUG_2024.pdf'><b>

                          PARAMEDICAL Diploma Courses All [First Year]</b></Link></td>
                      </tr>
                      <tr>
                        <td >7</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/diploma sup.pdf'><b>PARAMEDICAL Diploma Courses All [First &  Second Year] Supplementary  </b></Link></td>
                      </tr>
                      <tr>
                        <td >8</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/DAIRY TECH ALL SEM JUNE 2022.pdf'><b>

                          B. Tech. (Dairy Technology) I to  VIII Semester (Regular/Ex) </b></Link></td>
                      </tr>


                    </tbody>
                  </table>


                  <br />
                  <h4 style={{ textDecoration: "underline", color: "#4f000b" }}><b >  Examination May-June-  2024   :- </b></h4><br />
                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table [ Examination June – 2024]</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BBA.pdf'><b>Bachelor of Business Administration  I To IV Semester (NEP)  (Regular/Ex) </b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/BA.pdf'><b>
                          Bachelor of Arts I To IV Semester (NEP) (Regular/Ex)</b></Link></td>
                      </tr>
                      <tr>
                        <td >3</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BSC.pdf'><b>
                          Bachelor of Science I To IV Semester (NEP) (Regular/Ex)</b></Link></td>
                      </tr>
                      <tr>
                        <td >4</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BCOM.pdf'><b>

                          Bachelor of Commerce I To IV Semester (NEP) (Regular/Ex)</b></Link></td>
                      </tr>
                      <tr>
                        <td >5</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/BCA.pdf'><b>
                          Bachelor of Computer Application I To IV Semester (NEP) (Regular/Ex)</b></Link></td>
                      </tr>
                      <tr>
                        <td >6</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/b.pharma1ex.pdf'><b>

                          Bachelor of Pharmacy I Semester (Ex)  <span style={{ color: "#ce4257" }}>  <b>Revised</b></span></b></Link></td>
                      </tr>



                    </tbody>
                  </table>

                  <br />
                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table Examination June – 2024</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BARCH1SEM.pdf'><b>Bachelor of Architecture I Semeste <b>(Ex) </b><span style={{ color: "#ce4257" }}>  <b>Revised</b></span> </b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BARCH2SEM.pdf'><b> Bachelor of Architecture II Semester  <b>(Regular/Ex)</b> <b>(Ex) </b><span style={{ color: "#ce4257" }}>  <b>Revised</b></span> </b></Link></td>
                      </tr>
                      <tr>
                        <td >3</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPED_I_II_SEM_JUNE_2024.pdf'><b>
                          Bachelor of Physical Education (1 & 2 Sem)<b>(Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >4</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/B.PHARMA_I_TO_VI_SEM_JUNE_2024.pdf'><b>

                          Bachelor of Pharmacy II to IV Semester <b>(Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >5</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/AG_ALL_SEM_JUNE_2024.pdf'><b>
                          B.Sc. (Hons.) Agriculture I to VI Semester |<b>[Regular/Ex] </b> <span style={{ color: "#ce4257" }}>  <b>Revised</b></span> </b></Link></td>
                      </tr>
                      <tr>
                        <td >6</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MBA_I_II_SEM_JUNE_2024.pdf'><b>

                          Master of Business Administration I to  II Semester (Regular/Ex)  </b></Link></td>
                      </tr>
                      <tr>
                        <td >7</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MA_I_II_SEM_JUNE-2024.pdf'><b>

                          Master of Arts  I to  II Semester<b>(Regular/Ex)</b>  </b></Link></td>
                      </tr>
                      <tr>
                        <td >8</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/LAW_I_TO_IV_SEM_JUNE_2024.pdf'><b>

                          Bachelor of Law I to  IV Semester<b>(Regular/Ex)</b>  </b></Link></td>
                      </tr>
                      <tr>
                        <td >9</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/DIPLOMA_ENGG_4__TO_1_SEM_JUNE-_2023.pdf'><b>

                          Diploma in  Engineering I  to IV  Semester <b>(Regular/Ex)</b> <span style={{ color: "#ce4257" }}>  <b>Revised</b></span>  </b></Link></td>
                      </tr>
                      <tr>
                        <td >10</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BHMCT_I_TO_VI_SEM_JUNE_2024.pdf'><b>

                          Bachelor of Hotel Management & Catering Technology I  to VI Semester <b>[Regular/EX]  </b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >11</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BABED_I_TO_IV_SEM_JUNE_2024.pdf'>
                          <b>Bachelor of Arts Bachelor of Education I to VI Semester <b>(Regular/Ex) </b>  <span style={{ color: "#ce4257" }}>  <b>Revised</b></span></b>  </Link></td>
                      </tr>
                      <tr>
                        <td >12</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MTECH_I_TO_III_SEM_JUNE_2024.pdf'><b>

                          Master of Technology I to III Semester<b> [Regular/Ex]</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >13</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MPHARMA_I_II_JUNE_2024.pdf'><b>

                          Master of Pharmacy I & II Semester <b>(Regular/Ex) </b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >14</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MCOM_I_II_SEM_JUNE_2024.pdf'><b>

                          Master of Commerce I & II Semester  <b>(Regular/Ex) </b></b></Link></td>
                      </tr>
                      <tr>
                        <td >15</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MSC_I_II_SEM_JUNE_2024.pdf'><b>

                          Master of Science I & II Semester  <b>(Regular/Ex) </b></b></Link></td>
                      </tr>
                      <tr>
                        <td >16</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MCA_I_II_SEM_JUNE_2024.pdf'><b>

                          Masters in Computer Application I & II Semester <b>[Regular/Ex]</b>  New Scheme</b></Link></td>
                      </tr>
                      <tr>
                        <td >17</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BDIPLOMA ENGG I-II SEM AICTE JUNE 202.pdf'><b>

                          Diploma in Engineering I & II Semester <b>(Regular/Ex)</b> As per AICTE Scheme</b></Link></td>
                      </tr>
                      <tr>
                        <td >18</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BED_I_II_SEM_JUNE_2024.pdf'><b>

                          Bachelor of Education (1 & 2  Sem) <b>(Regular/Ex)</b></b></Link></td>
                      </tr>
                      <tr>
                        <td >19</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BE_AICTE_I_TO_VI_SEM_JUNE_2024.pdf'><b>

                          Bachelor of Engineering I to VI Semester (Regular/Ex) As per AICTE Scheme</b></Link></td>
                      </tr>
                    </tbody>
                  </table>



                  <br />

                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table [ Examination June – 2024]</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BAMS 1 YEAR JUNE 2024.pdf'><b>BACHELOR OF AYURVEDIC MEDICINE AND SURGERY I YEAR <b>(Regular) </b>  </b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BHMS JUNE 2024.pdf'><b>BACHELOR OF HOMOEOPATHIC MEDICINE & SURGERY 2 nd to 4 th Year  <b>(Regular)</b> </b></Link></td>
                      </tr>

                    </tbody>
                  </table>

                  <br />

                  <table className="responsive-table">

                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Time Table</th>
                      </tr>
                    </thead>

                    <tbody>

                      <tr>
                        <td >1</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BABED 8 SEM MAY JUNE 2024.pdf'><b>
                          Bachelor of Arts Bachelor of Education (8 th Sem) </b></Link></td>
                      </tr>

                      <tr>
                        <td >2</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BABED 8 SEM MAY JUNE 202BED 4 & 3 SEM MAY JUNE 2024.pdf'><b>Bachelor of Education (3 & 4  Sem) </b></Link></td>
                      </tr>

                      <tr>
                        <td >3</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPED 4 & 3 SEM MAY JUNE 2024.pdf'><b>Bachelor of Physical Education (3 & 4 Sem) </b></Link></td>
                      </tr>
                      <tr>
                        <td >4</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BPHARMA 8 & 7 SEM MAY JUNE 2024.pdf'><b>Bachelor of Pharmacy VII & VIII Semester (Regular/Ex) </b></Link></td>
                      </tr>
                      <tr>
                        <td >5</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/LAW 6 & 5 SEM MAY JUNE 2024.pdf'><b>Bachelor of Law  ( V & VI Sem) (Regular/Ex) </b></Link></td>
                      </tr>
                      <tr>
                        <td >6</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BE AICTE 8 & 7 SEM JUNE 2024 .pdf'><b>Bachelor of Engineering VII & VIII Semester (Regular/Ex) As per AICTE Scheme</b></Link></td>
                      </tr>
                      <tr>
                        <td >7</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MBA 4 & 3 SEM MAY JUNE 2024.pdf'><b>Masters in Business Administration  lII &IV Semester <b>(Regular/Ex)</b>  </b></Link></td>
                      </tr>
                      <tr>
                        <td >8</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MCA 4 & 3 SEM MAY JUNE 2024.pdf'><b>Masters in Computer Application III & IV Semester  <b>[Regular/Ex] </b></b></Link></td>
                      </tr>
                      <tr>
                        <td >9</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/DIPLOMA ENGG 6 & 5SEM MAY JUNE 2024.pdf'><b>
                          Diploma in Engineering V & VI Semester (Regular/Ex) </b></Link></td>
                      </tr>

                      <tr>
                        <td >10</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MA 4 & 3 SEM MAY JUNE 2024.pdf'><b>

                          Master of Arts IIl & IV Semester <b>(Regular/Ex)</b>  </b></Link></td>
                      </tr>
                      <tr>
                        <td >11</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MSC 4 & 3 SEM MAY JUNE 2024.pdf'><b>

                          Master of Science III & IV Semester  <b>(Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >12</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BLIB 2 & 1 SEM MAY JUNE 2024.pdf'><b>
                          Bachelor of Library and Information Science <b>(Regular/Ex) </b> </b></Link></td>
                      </tr>
                      <tr>
                        <td > 13  </td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/MCOM 4 & 3 SEM MAY JUNE 2024.pdf'><b>
                          Master Of Commerce III & IV Semester <b>(Regular/Ex) </b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >14</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/PGDCA 2 & 1 SEM MAY JUNE 2024.pdf'><b>
                          Post-Graduation Diploma in Computer Application 1 to 2  Sem  <b>(Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >15</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BHMCT 8 & 7 SEM MAY JUNE 2024.pdf'><b>
                          Bachelor of Hotel Management & Catering Technology  VII & VIII Sem <b> (Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >16</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BARCH 8 & 7 SEM MAY JUNE 2024.pdf'><b>
                          Bachelor of Architecture VII &VIII Semester  <b>(Regular/Ex)</b> </b></Link></td>
                      </tr>
                      <tr>
                        <td >17</td>
                        <td style={{ textAlign: "left" }}><Link to='https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Exam Schedule/BE CBCS ALL SEM MAY JUNE 2024.pdf'><b>
                          Bachelor of Engineering VII & VIII Semester (Regular/Ex) As per CBCS Scheme </b></Link></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </p>
              <br />

            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to="/Entrance-Exam-Alert">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Entrance Exam Alert
                    </Link>
                  </li>
                </Link>
                <Link to="/Exam_Notification">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Exam Notifications
                    </Link>
                  </li>
                </Link>
                <Link to="/Exam-Schedule">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Exam Schedule
                    </Link>
                  </li>
                </Link>
                <Link to="/Result">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Results
                    </Link>
                  </li>
                </Link>
                <Link to="/Interface">
                  <li className="lli">
                    <Link to="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Interface
                    </Link>
                  </li>
                </Link>

              </ul>
            </div>
          </div>

        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default ExamSchedule;
