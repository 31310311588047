import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
import Hos from "../images/Amemities/Hostel.jpg";
import { Link } from "react-router-dom";


function Hostel() {
  return (
    <>
      <Nav />
      <br/>
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>HOSTEL SSSUTMS</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" ,  fontFamily: "'poppins',sansSerif"}}
              >
            For resident students a boy’s hostel and a girl’s hostel is available in the campus. Each Hostel is self-contained with amenities such as a reading room, an indoor games room, a lounge and a dining hall with mess, a computer room and TV in common room. The Board for Hostel Management coordinates the various hostel activities. At present, Boys Hostel has capacity of accommodating 252 students and Girls Hostel has capacity of 100 girls. The mess of both Hostels is run on cooperative basis. Provision of separate rooms with air-conditioners is available.
  <br/> <br/>
            For the working of hostels, representatives from the hostels take decisions on all policies of common interest. The administrative head of each hostel is Warden, who is a faculty member., Icchawar.
              </p>
              <br/>
              <img src={Hos} alt="" />
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to = "/transprotation">
                <li className="lli">
                  <Link to ="/History_Milestones">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Transportation
                 </Link>
                </li>
                </Link>
                <Link to = "/Hostel">
                <li className="lli">
                  <Link to ="/institutes">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Hostel
                 </Link>
                </li>
                </Link>
                <Link to = "/Library">
                <li className="lli">
                  <Link to ="/promotingsociety">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Library
                    Society
                 </Link>
                </li>
                </Link>
                <Link to = "/Medicalfacility">
                <li className="lli">
                  <Link to ="/vision-mission">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Medical Facility
                 </Link>
                </li>
                </Link>
                <Link to = "/Guesthouse">
                <li className="lli">
                  <Link to ="/core-approvalsvalues">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Guest House
                 </Link>
                </li>
                </Link>
             
              </ul>
            </div>
          </div>
         
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Hostel;



