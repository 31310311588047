import React from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function TransferSubject() {
  return (
    <>
      <AdminDashboard />
      <h1 style={{ marginTop: "10%", marginLeft: "50%" }}> TransferSubject</h1>
    </>
  );
}

export default TransferSubject;
