import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from 'react-router-dom';

function MtechScheme() {
  return (
    <>
      <Nav />
      <style>
        {`

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 900;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 90px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
           margin-left:-20px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 900;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 90px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont">
        <h2 className="h2 title-main" style={{ color: '#780000', marginTop: '10px' }}>MTECH</h2>
        <hr className="title-hr"></hr>
        <div className="row dirr">
          <div className="col-lg-10 padding-section">

            <br />

            <h5 style={{ color: '#22177A', textAlign: 'center', fontFamily: "'poppins',sansSerif", marginBottom: '10px' }}>Scheme: MTECH </h5>

            <style>
              {`
    .table-container {
      display: flex;
      margin-left:80px;
      flex-direction: row; /* Align tables in a row */
      gap: 20px; /* Space between tables */
      flex-wrap: wrap; /* Allows wrapping on smaller screens */
    } 
      
    
    table {
      width: 48%; /* Set width to 48% to fit both tables side by side */
      border-collapse: collapse;
    } 
      
    
    th, td {
      padding: 5px;
      border: 1px solid #999;
      text-align: left;
      font-family: 'poppins',sansSerif;
    }    @media screen and (max-width: 768px) {
      .table-container {
        flex-direction: column; /* Stack tables on smaller screens */
      }      table {
        width: 100%; /* Make tables full-width on smaller screens */
      }
    }
  `}
            </style>



<br></br>

            <table style={{ width: '90%', marginLeft: '50px', marginTop: '10px', borderCollapse: 'collapse' }}>
              <tbody>

<tr><th>S.No</th><th>Course

</th> <th >FIRST SEM</th> <th > SECOND SEM

</th> <th>THIRD SEM</th><th>FOURTH SEM</th>
              </tr>
              <tr>
                <th>1</th>
                <th rowSpan='11' style={{textAlign:'center'}}>MTECH</th>
                <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MCSE_I.pdf'> Computer Science and Engineering</Link></td>
                <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHMCSE_II.pdf'> Computer Science and Engineering</Link></td>
                <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/CS_III.pdf'> Computer Science and Engineering</Link></td>
                <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/.pdf'> Computer Science and Engineering</Link></td>
</tr>


<tr>
  <th>2</th>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHEME-MCTA I SEM.pdf'>Computer Technology and Application</Link></td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHEME-MCTA-II-SEM.pdf'>Computer Technology and Application</Link></td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHEME-MCTA-III-SEM.pdf'>Computer Technology and Application</Link></td>
<td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHEME-MCTA-IV-SEM.pdf'>Computer Technology and Application</Link></td>



</tr>

         <tr>
          <th>3</th>
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTDC_I.pdf'>Digital Communication</Link></td>
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTDC_II.pdf'>Digital Communication</Link></td>
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/DC_III.pdf'>Digital Communication</Link></td>
          <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/DC.pdf'>Digital Communication</Link></td>

         </tr>
<tr>
  <th>4</th>
  <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MEPS_I.pdf'>Electrical Power System</Link></td>
  <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTEPS_II.pdf'>Electrical Power System</Link></td>
  <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/EPS_III.pdf'>Electrical Power System</Link></td>
  <td> <Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/EPS4TH.pdf'>Electrical Power System</Link></td>

</tr>

<tr>
  <th>5</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTID_I.pdf'>Industrial Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTID_II.pdf'>Industrial Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/ID_III.pdf'>Industrial Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/ID.pdf'>Industrial Design</Link></td>

</tr>


<tr>
  <th>6</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/MCIT-1-SEM-SCHEME.pdf'>Information Technology</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/MCIT-2-SEM-SCHEME.pdf'>Information Technology</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/MCIT-III-SEM-SCHEME.pdf'>Information Technology</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/MCIT-4-SEM-SCHEME.pdf'>Information Technology</Link></td>


</tr>

<tr>
  <th>7</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTPE_I.pdf'>Power Electronics</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTPE_II.pdf'>Power Electronics</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/PE_III.pdf'>Power Electronics</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/PE.pdf'>Power Electronics</Link></td>

</tr>


<tr>
  <th>8</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTSE_I.pdf'>Software Engineering</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SCHMSE_II.pdf'>Software Engineering</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SE_III.pdf'>Software Engineering</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/.pdf'>Software Engineering</Link></td>

</tr>

<tr>
  <th>9</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTSD_I.pdf'>Structural Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTSD_II.pdf'>Structural Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SD_III.pdf'>Structural Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/CESD.pdf'>Structural Design</Link></td>

</tr>

<tr>
  <th>10</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTH_I.pdf'>Thermal Engineering</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTH_II.pdf'>Thermal Engineering</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/TH_III.pdf'>Thermal Engineering</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/METH.pdf'>Thermal Engineering</Link></td>


</tr>

<tr>
  <th>11</th>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTVD_I.pdf'>VLSI Design</Link></td>
  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/SC_MTVLSI_II.pdf'>VLSI Design</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/VL_III.pdf'>VLSI Design</Link></td>

  <td><Link to ='https://cdn.sssutms.ac.in/media/static/PDF/SCHEME/MTECH/VLSI.pdf'>VLSI Design</Link></td>

</tr>
              </tbody>
            </table>
           
       
           
          </div>



          <div className="col-lg-2">

            <ul className='list-icon' >
              <h4 style={{ fontFamily: "'poppins',sansSerif", color: '#243642', textDecoration: 'underline' }}>Scheme</h4>
              <br></br> 
               <li className='lli'>
                <Link to ='/B.E-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BE</Link></li>
              
                  <li className='lli'>
                <Link to ='/Pharmacy-scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Pharmacy</Link></li>
                  <li className='lli'>
                <Link to ='/M.Tech-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MTECH</Link></li>
                  <li className='lli'>
                <Link to ='/BHMCT-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMCT</Link></li>
                  <li className='lli'>
                <Link to ='/MBA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MBA</Link></li>
                  <li className='lli'>
                <Link to ='/MCA-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> MCA</Link></li>
                  <li className='lli'>
                <Link to ='/Education-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Education</Link></li>
                  <li className='lli'>
                <Link to ='/PhysicalEducation-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Physical Education</Link></li>
                  <li className='lli'>
                <Link to ='/BscHonsAg-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Sc.(Hons).(Ag)</Link></li>
                  <li className='lli'>
                <Link to ='/BHMS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> BHMS</Link></li>
                  <li className='lli'>
                <Link to ='/Paramedical-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Paramedical</Link></li>
                  <li className='lli'>
                <Link to ='/POLYTECHNIC(ENGINEERING)-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> Polytechnic Engineering</Link></li>
                  <li className='lli'>
                <Link to ='/BLIBISC-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i> B.Lib.I.Sc</Link></li>
                  <li className='lli'>
                <Link to ='/BachelorofLaws-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Bachelor of Laws </Link></li>
                  <li className='lli'>
                <Link to ='/BSCHMCS-Scheme'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>B.Sc.[HMCS] </Link></li>
                
            


            </ul>
          </div>
        </div>

      </div>
      <br></br>

      <Footerpage /></>
  )
}

export default MtechScheme