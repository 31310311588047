// import React from "react";
// import { Link } from "react-router-dom";
// import "./Erp.css";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";
// import student from "../../images/student.png";
// import employee from "../../images/employee.png";
// import certificate from "../../images/ErpCertificate.png";
// import shop from "../../images/ErpShop.png";
// import library from "../../images/ErpLibrary.png";
// import adminn from "../../images/adminn.png";

// const Card = ({ image, title, description, link }) => (
//   <div className="col-lg-3 col-sm-6 col-md-4 col-xl-3 col-xs-12">
//     <Link to={link} style={{ textDecoration: "none" }}>
//       <div className="card" style={{ marginTop: "20px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
//         <div className="card-body">
//           <div className="card-image">
//             <img
//               src={image}
//               style={{ width: "100px", height: "100px", textAlign: "center" }}
//               alt={title}
//             />
//           </div>
//           <h5 className="card-text">{title}</h5>
//           <p style={{ color: "black", fontSize: "14px", textAlign: "center" }}>
//             {description}
//           </p>
//         </div>
//       </div>
//     </Link>
//   </div>
// );

// function Erp() {
//   return (
//     <>
//       <OnlyHeader />
//       <div className="erpBG">
//         <div className="cardCont">
//           <div className="container" style={{ marginTop: 65 }}>
//             <div className="row justify-content-center">
//               <Card
//                 image={student}
//                 title="STUDENT LOGIN"
//                 description="Click here for Student Login"
//                 link="/erp/studentlogin"
//               />
//               <Card
//                 image={"https://cdn.sssutms.ac.in/media/static/images/employee.png"}
//                 title="HOD LOGIN"
//                 description="Click here for Hod Login"
//                 link="/erp/hodlogin"
//               />
//               <Card
//                 image={adminn}
//                 title="ADMIN LOGIN"
//                 description="Click here for Admin Login"
//                 link="/adminlogin"
//               />
//               <Card
//                 image={library}
//                 title="WEB MAIL LOGIN"
//                 description="Click here for Web Mail Login"
//                 link="https://sssutms.ac.in:2096/"
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Erp;

// import React from "react";
// import { Link } from "react-router-dom";
// import "./Erp.css";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";
// import student from "../../images/student.png";
// import employee from "../../images/employee.png";
// import certificate from "../../images/ErpCertificate.png";
// import shop from "../../images/ErpShop.png";
// import library from "../../images/ErpLibrary.png";
// import adminn from "../../images/adminn.png";

// const Card = ({ image, title, description, link }) => (

//   <div className="col-lg-4 col-sm-4 col-md-4 col-xl-4 col-xs-12">
//     <Link to={link} style={{ textDecoration: "none" }}>
//       <div className="card" style={{ marginTop: "20px" }}>
//         <div className="card-body">
//           <div className="card-image" style={{ color: "#213661" }}>
//             <img
//               src={image}
//               style={{ width: "100px", height: "100px", textAlign: "center" }}
//               alt={title}
//             />
//           </div>
//           <p className="card-text">{title}</p>
//           <p style={{ color: "black", fontSize: "14px", textAlign: "center" }}>
//             {description}
//           </p>
//         </div>
//       </div>
//     </Link>
//   </div>
// );

// function Erp() {
//   return (
//     <>
//       <OnlyHeader />
//       <div className="erpBG">
//         <div className="cardCont">
//           <div className="container" style={{ marginTop: 65 }}>
//             <div className="row">
//               <Card
//                 image={student}
//                 title="STUDENT LOGIN"
//                 description="Click here for Student Login"
//                 link="/erp/studentlogin"
//               />
//               <Card
//                 image={"https://cdn.sssutms.ac.in/media/static/images/employee.png"}
//                 title="HOD LOGIN"
//                 description="Click here for Hod Login"
//                 link="/erp/hodlogin"
//               />

//               <Card
//                 image={adminn}
//                 title="ADMIN LOGIN"
//                 description="Click here for Admin Login"
//                 link="/adminlogin"
//               />
//                 <Card
//                 image={library}
//                 title="WEB MAIL LOGIN"
//                 description="Click here for Web Mail Login"
//                 link="https://sssutms.ac.in:2096/"
//               />
//             </div>
//               {/* <Card
//                 image={certificate}
//                 title="ACTIVE USER LOGIN"
//                 description="Click here for Active your user Login"
//                 link=""
//               /> */}
//               {/* <Card
//                 image={shop}
//                 title="QUICK ADMISSION PORTAL"
//                 description="Click here for Quick admission portal"
//                 link=""
//               /> */}

//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Erp;

// import React from "react";
// import { Link } from "react-router-dom";
// import student from "../../images/student.png";
// import employee from "../../images/employee.png";
// import OnlyHeader from "../../AdminComponent/OnlyHeader";
// import library from "../../images/ErpLibrary.png";
// import adminn from "../../images/adminn.png";
// import bg from "./../../../src/images/SOD-BUILDING.png";

// function Erp() {
//   return (
//     <>
//       <style>
//         {`
//           .bgimage {
//             background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${bg}');
//             background-size: cover;
//             // margin-top:-90px;
//             background-position: center;
//             height: 100vh;
//             width: 100%;
//             position: absolute;
//             z-index: -1;
//             filter: blur(8px); /* Adding blur effect */
//           }

//           .content {
//             position: relative;
//             z-index: 1;
//           }
//              .row{
//           width:100%;
//           margin-left:10px;
//           margin-top:100px;
//        }
            
//   .coll{
//           width:320px;
//           margin-top:40px;
//           }
        
//  .roww{
//           margin-left:300px;
//         }

// //  .container{
// //           -webkit-transform-style: preserve-3d;
// //                   transform-style: preserve-3d;
// //           -webkit-perspective: 1000px;
// //                   perspective: 1000px;
// //         }
        
//         .front,
//         .back{
//           background-size: cover;
//           box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
//           border-radius: 10px;
//           background-color:#edede9;
//           background-position: center;
//           -webkit-backface-visibility: hidden;
//                   backface-visibility: hidden;
//           text-align: center;
//           min-height: 280px;
//           height: auto;
//           border-radius: 10px;
//           color: #fff;
//           font-size: 1.5rem;
//         }
        
//                  .inner{
//             -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
//                     transform: translateY(-50%) translateZ(60px) scale(0.94);
//             top: 50%;
            
//             position: absolute;
//             left: 0;
//             width: 100%;
//             padding: 2rem;
//             -webkit-box-sizing: border-box;
//                     box-sizing: border-box;
//             outline: 1px solid transparent;
//             -webkit-perspective: inherit;
//                     perspective: inherit;
//             z-index: 2;
//         }
    
//          .container .front{
//             -webkit-transform: rotateY(0deg);
//                     transform: rotateY(0deg);
//             -webkit-transform-style: preserve-3d;
//                     transform-style: preserve-3d;
//         }
        
//         .front .inner p{
//           font-size: 18px;
//           text-align: center;
//           text-transform: uppercase;
//           font-weight: bold;
//            margin-top: 18px;
//           line-height: 1;
//           color:black;
//         }
        
//         .front .inner span{
//         font-size: 13px;
//           text-align: center;
//           color:black;
//     }
//       }

// @media (max-width: 768px) {
//    .col,
//   .coll {
//     width: 100%;
//     margin: 1rem 0;
//   }

//   .inner img {
//     max-width: 100%;
//     height: auto;
//   }

//   .front .inner p {
//     font-size: 16px;
//   }

//   .front .inner span {
//     font-size: 12px;
//   }
// }

// /* For screens smaller than 768px (mobile devices) */
// @media (max-width: 767px) {
//   .roww {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin:0px;
//   }
// }

// @media (max-width: 480px) {
//   .front .inner p {
//     font-size: 14px;
//   } 
  
// .front .inner span {
//     font-size: 10px;
//   }

//   .row{
//     margin-top:-112px;
//     margin-left:-7px
//   }

//  .divhai h1{
//     text-align:center;
//     font-size:60px;
//     color:white;
//   }

//   .roww{
//     margin-top:90px;
//     margin-left:-10px;
//         }}
//         `}
//       </style>

//       <OnlyHeader />
//       <div className="bgimage"></div>
//       <div className="content">
//         <div className="row">
//           <div className="coll">
//             <Link to="/erp/studentlogin">
//               <div className="container">
//                 <div className="front">
//                   <div className="inner">
//                     <img
//                       src={student}
//                       style={{
//                         height: "150px",
//                         width: "180px",
//                         marginLeft: "10px",
//                         filter: `invert(30%) sepia(78%) saturate(1000%) hue-rotate(335deg) brightness(90%) contrast(95%)`,
//                       }}
//                     />

//                     <p>student login</p>
//                     <span>Click here for student login</span>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           </div>

//           <div className="coll">
//             <Link to="/adminlogin">
//               <div className="container">
//                 <div className="front">
//                   <div className="inner">
//                     <img
//                       src={adminn}
//                       style={{
//                         height: "150px",
//                         width: "180px",
//                         marginLeft: "10px",
//                         filter: `invert(30%) sepia(78%) saturate(1811%) hue-rotate(335deg) brightness(101%) contrast(107%)`,
//                       }}
//                     ></img>
//                     <p>admin login</p>
//                     <span>Click here for ADMIN login</span>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           </div>

//           <div className="coll">
//             <Link to="/erp/hodlogin">
//               <div className="container">
//                 <div className="front">
//                   <div className="inner">
//                     <img
//                       src={employee}
//                       style={{
//                         height: "150px",
//                         width: "180px",
//                         marginLeft: "10px",
//                         filter: `invert(30%) sepia(78%) saturate(1811%) hue-rotate(335deg) brightness(101%) contrast(107%)`,
//                       }}
//                     ></img>
//                     <p>hod login</p> <span>Click here for HOD login</span>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           </div>

//           <div className="coll">
//             <Link to="">
//               <div className="container">
//                 <div className="front">
//                   <div className="inner">
//                     <img
//                       src={library}
//                       style={{
//                         height: "150px",
//                         width: "180px",
//                         marginLeft: "10px",
//                         filter: `invert(30%) sepia(78%) saturate(1811%) hue-rotate(335deg) brightness(101%) contrast(107%)`,
//                       }}
//                     ></img>
//                     <p>web mail login</p>{" "}
//                     <span>Click here for Webmail login</span>
//                   </div>
//                 </div>
//               </div>
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Erp;

import React from "react";
import { Link } from "react-router-dom";
import "./Erp.css";
import OnlyHeader from "../../AdminComponent/OnlyHeader";
import student from "../../images/student.png";
import employee from "../../images/employee.png";
import certificate from "../../images/ErpCertificate.png";
import shop from "../../images/ErpShop.png";
import library from "../../images/ErpLibrary.png";
import adminn from "../../images/adminn.png";
import sodBuilding from '../../images/SOD-BUILDING.png';

const Card = ({ image, title, description, link }) => (

  <div className="col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xs-12">
    <Link to={link} style={{ textDecoration: "none" }}>
      <div className="card" style={{ marginTop: "20px" }}>
        <div className="card-body">
          <div className="card-image" style={{ color: "#213661" }}>
            <img
              src={image}
              style={{ width: "100px", height: "100px", textAlign: "center" }}
              alt={title}
            />
          </div>
          <p className="card-text">{title}</p>
          <p style={{ color: "black", fontSize: "14px", textAlign: "center" }}>
            {description}
          </p>
        </div>
      </div>
    </Link>
  </div>
);

// function Erp() {
//   return (
//     <>
//       <OnlyHeader />
//       <div
//   className="cardCont"
//   style={{
//     backgroundImage: `url(${sodBuilding})`,
//     backgroundSize: 'cover',
//     backgroundPosition: 'center',
//     backgroundRepeat: 'no-repeat',
//     marginTop:'-100px',
//     height: '92vh', // Set height to 100% of the viewport height
//   }}
// >
//   <div className="container">
//             <div className="row">
//               <Card 
//                 image={student}
//                 title="STUDENT LOGIN"
//                 description="Click here for Student Login"
//                 link="/erp/studentlogin"
//               />
//               <Card
//                 image={"https://cdn.sssutms.ac.in/media/static/images/employee.png"}
//                 title="HOD LOGIN"
//                 description="Click here for Hod Login"
//                 link="/erp/hodlogin"
//               />

//               <Card
//                 image={adminn}
//                 title="ADMIN LOGIN"
//                 description="Click here for Admin Login"
//                 link="/adminlogin"
//               />
//                 <Card
//                 image={library}
//                 title="WEB MAIL LOGIN"
//                 description="Click here for Web Mail Login"
//                 link="https://sssutms.ac.in:2096/"
//               />
//             </div>
              

//           </div>
//         </div>
 
//     </>
//   );
// }
function Erp() {
  return (
    <>
      <OnlyHeader />
      <div className="cardCont" style={{ position: 'relative', height: '100vh' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage: `url(${sodBuilding})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'blur(8px)', // Use filter for blur on the background
            zIndex: -1, // Make sure it's behind the content
          }}
        />
        <div className="container" style={{ paddingTop: '150px', position: 'relative', zIndex: 1 }}>
          <div className="row">
            <Card
              image={student}
              title="STUDENT LOGIN"
              description="Click here for Student Login"
              link="/erp/studentlogin"
            />
            <Card
              image="https://cdn.sssutms.ac.in/media/static/images/employee.png"
              title="HOD LOGIN"
              description="Click here for Hod Login"
              link="/erp/hodlogin"
            />
            <Card
              image={adminn}
              title="ADMIN LOGIN"
              description="Click here for Admin Login"
              link="/adminlogin"
            />
            <Card
              image={library}
              title="WEB MAIL LOGIN"
              description="Click here for Web Mail Login"
              link="https://sssutms.ac.in:2096/"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Erp;