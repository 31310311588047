import React, { useEffect } from "react";
import $ from "jquery";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

const CourseChangeList = () => {
  useEffect(() => {
    $(document).ready(function () {

      document.title = "Simple DataTable";

      $("#example tfoot th").each(function () {
        var title = $(this).text();
        $(this).html('<input type="text" placeholder="Search ' + title + '" />');
      });

      var table = $("#example").DataTable({
        dom: '<"dt-buttons"Bf><"clear">lirtp',
        paging: true,
        autoWidth: true,
        buttons: [
          "colvis",
          "copyHtml5",
          "csvHtml5",
          "excelHtml5",
          "pdfHtml5",
          "print"
        ],
        initComplete: function (settings, json) {
          var footer = $("#example tfoot tr");
          $("#example thead").append(footer);
        }
      });

      // Apply the search functionality to each column
      $("#example thead").on("keyup", "input", function () {
        table
          .column($(this).parent().index())
          .search(this.value)
          .draw();
      });
    });
  }, []);

  return (
    <div>
      <a
        className="btn btn-success"
        style={{ float: "left", marginRight: "20px" }}
        href="https://codepen.io/collection/XKgNLN/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Other examples on Codepen
      </a>
      <table
        id="example"
        className="table table-striped table-bordered"
        cellSpacing="0"
        width="100%"
      >
        <thead>
          <tr>
            <th>Order</th>
            <th>Description</th>
            <th>Deadline</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <th>Order</th>
            <th>Description</th>
            <th>Deadline</th>
            <th>Status</th>
            <th>Amount</th>
          </tr>
        </tfoot>
        <tbody>
          <tr>
            <td>1</td>
            <td>Alphabet puzzle</td>
            <td>2016/01/15</td>
            <td>Done</td>
            <td data-order="1000">€1.000,00</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Layout for poster</td>
            <td>2016/01/31</td>
            <td>Planned</td>
            <td data-order="1834">€1.834,00</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Image creation</td>
            <td>2016/01/23</td>
            <td>To Do</td>
            <td data-order="1500">€1.500,00</td>
          </tr>
          <tr>
            <td>5</td>
            <td>Sticker production</td>
            <td>2016/02/18</td>
            <td>Planned</td>
            <td data-order="2100">€2.100,00</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default  CourseChangeList;

