import React from "react";
import { Link } from "react-router-dom";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";

function Patents() {
  return (
    <>
      <Nav />
      <style>
        {`
    
  
   
.contentleader
{
text-align:justify;
width:100%;
margin-top:60px;   padding: 15px; /* Adds padding around the content */
 line-height: 1.6; /* Adjusts the space between lines */

}
  

 
.leadership{
position:relative;
height:300px;
width:100%;
}




.dean-img{
height:340px;
width:300px
}


h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



  .fa {
      display: inline-block;
      font: normal normal normal 14px / 1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
      
          margin-left:-50%;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  

.img-fluid {
max-width: 100%;
height: 260px;
border-radius:10px;
margin-top:30px;
margin-left:20px;

}


.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }





.cont{
margin-top:20px;
}
.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: 0rem;
margin-bottom:0rem
}

.chan{
margin-left:30px;
font-size:15px;
}
// @media only screen and (min-width: 768px) {
 






.dean-img-box {
max-width: 100%;
background-color: #f3f3f3;
padding: .8rem;
height:340px;


border: 10px solid #023047;
display: inline-block;
position: relative;
margin-left:50px;
margin-top:50px

}


.sidcontent{
margin-left:-60px;
margin-top:30px;
width:95%;
font-size:17px;

}







@media only screen and (max-width: 480px){

.contentleader
{
text-align:justify;
 font-family:poppins,sans-serif;
  font-weight: 900;
  font-style: normal;
}


.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}


.sidcontent{
  margin-left:30px;
   font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;

}


.contentleader{
margin-left:20px;
font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;}
}



h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2 className="h2 title-main" style={{ color: "#780000" }}>
          Patents
        </h2>
        <hr className="title-hr"></hr> <br />
      
        <div className="row dirr">
        

          <div className="col-lg-10 padding-section">
          
            <div className="sidcontent">
              <div
                className="contentleader"
                style={{ fontFamily: "'poppins',sansSerif", marginTop: "1px" }}
              >
                <div className="col-lg-10 padding-section">
                  Objective: The main objective is to impart greater awareness
                  about the issue of Intellectual Property Right (IPR), which
                  has gained a special importance for all the domains of
                  socio-economic development. It aids to understand of
                  patentability criteria in details and viable aspect of the
                  patent.
                </div>{" "}
                <br />
                <h5
                  style={{
                    marginLeft: "40%",
                    color: "#780000",
                    textDecoration: "underline",
                  }}
                >
                  <b>2023</b>
                </h5>{" "}
                <br />
                <style>
                  {`
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 90%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `}
                </style>
                <div className="tann">
                  <table
                    className="responsive-table"
                    style={{ marginLeft: "-3%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Name of Inventors</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">Application No./Granted Patent No.</th>
                        <th scope="col">Granted/ Published</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td>Adjustable Laptop Table</td>
                        <td>391559-001</td>
                        <td>Filed & Published</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td>
                          An intelligent waste /garbage disposal system based on
                          internet of things (IOT){" "}
                        </td>
                        <td>202221065208</td>
                        <td>Published</td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td> Smart Meditation And Rest Table</td>{" "}
                        <td>391547-001</td> <td>Filed & Published</td>{" "}
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td> DEEP DRAWING PUNCH WITH SENSORS</td>{" "}
                        <td>398305-001</td> <td>Published</td>{" "}
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td>MANUALLY OPERATED DEEP DRAWING MACHINE</td>{" "}
                        <td>391560-001</td> <td>Filed & Published</td>{" "}
                      </tr>

                      <tr>
                        <td>6</td>
                        <td>Mr. Manoj Kumar Yadav</td>
                        <td>DEEP DRAWING DIE WITH SENSORS</td>{" "}
                        <td>394723-001</td> <td>Published</td>{" "}
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Dr Gajraj Singh Ahirwar</td>
                        <td>FINGER SLEEVE FOR MOBILE SCREEN</td>{" "}
                        <td>398306-001</td> <td>Filed & Published</td>{" "}
                      </tr>

                      <tr>
                        <td>8</td>
                        <td>Dr. Santosh Jagwani </td>
                        <td>
                          COAL BURNER & HANDLING APPARATUS WITH SMOKE SEPARATION
                          CHAMBER
                        </td>{" "}
                        <td>394722-001</td> <td>Filed & Published</td>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <h5
                  style={{
                    marginLeft: "40%",
                    color: "#780000",
                    textDecoration: "underline",
                  }}
                >
                  <b>2022</b>
                </h5>{" "}
                <br />
                <div className="tann">
                  <table
                    className="responsive-table"
                    style={{ marginLeft: "-3%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Name of Inventors</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">Application No./Granted Patent No.</th>
                        <th scope="col">Granted/ Published</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          ENGINEERED CYCLODEXTRIN NANOSPONGES FOR THE DELIVERY
                          OF ANTIRETROVIRAL DRUG
                        </td>
                        <td>202221001859A</td>
                        <td>Filed & Published</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Dr. Jitendra Sheetlani</td>
                        <td>
                          METHOD OF GARBAGE MONITORING AND CLEARING PROCESS BY
                          IOT{" "}
                        </td>
                        <td>2021104892</td>
                        <td>Granted</td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td>Dr. Kailash Patdar</td>
                        <td>
                          A NOVEL BRAIN ADEQUACY TEST SYSTEM TO IMPROVE
                          COGNITIVE INDEX OF YOUTH USING NON-INVASIVE BRAIN
                          SENSORS AND ARTIFICIAL NEURAL NETWORKS
                        </td>{" "}
                        <td>202241029374</td> <td>Filed & Published</td>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <h5
                  style={{
                    marginLeft: "40%",
                    color: "#780000",
                    textDecoration: "underline",
                  }}
                >
                  <b>2021</b>
                </h5>{" "}
                <br />
                <div className="tann">
                  <table
                    className="responsive-table"
                    style={{ marginLeft: "-3%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Name of Inventors</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">Application No./Granted Patent No.</th>
                        <th scope="col">Granted/ Published</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td>INTELLIGENT GREEN HOUSE USING IOT</td>
                        <td>2021100630</td>
                        <td>Granted</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          LAGERSTROEMIA MICROCARPA WIGHT EXTRACT FOR THE
                          TREATMENT OF DIABETES INDUCED BY STZT{" "}
                        </td>
                        <td>2021105435</td>
                        <td>Granted</td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td>Dr. Sunil Kumar Shah</td>
                        <td>
                          A METHOD FOR THE SIMULTANEOUS ESTIMATION OF
                          ACECLOFENAC AND TRAMADOL BY RP-HPLC IN BULK AND TABLET
                          DOSAGE FORMS
                        </td>{" "}
                        <td>2021106980</td> <td>Granted</td>{" "}
                      </tr>

                      <tr>
                        <td>4</td>
                        <td>Dr. Jitendra Sheetlani</td>
                        <td>
                          SMART AND INTELLIGENT MEDICAL BED FOR CHILD PATIENT
                          CARE
                        </td>{" "}
                        <td>2021105547</td> <td>Granted</td>{" "}
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Dr. Sunil Kumar Shah</td>
                        <td>
                          A METHOD OF PREPARATION OF AQUEOUS EXTRACT OF SIDA
                          RHOMBIFOLIA LINN FOR ANTIBACTERIAL ACTIVITY
                        </td>{" "}
                        <td>2021103621</td> <td>Granted</td>{" "}
                      </tr>

                      <tr>
                        <td>6</td>
                        <td>Dr. Minakshi Pathak</td>
                        <td>
                          A METHOD OF PREPARATION OF EMBELIA TSJERIAM COTTAM FOR
                          PRELIMINARY AND PHYTOCHEMICAL STUDIES
                        </td>{" "}
                        <td>2021106614</td> <td>Granted</td>{" "}
                      </tr>

                      <tr>
                        <td>7</td>
                        <td>Dr. Mukesh Tiwari</td>
                        <td>
                          EFFICIENT ALGORITHM FOR TACKING OF OBJECTS IN VIDEO
                          CAMERA NETWORK AND THEIR ESTABLISHMENT THEREOF
                        </td>{" "}
                        <td>202021055337</td> <td>Granted</td>{" "}
                      </tr>

                      <tr>
                        <td>8</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          “PREPARATION OF SELF-MICRO EMULSIFYING DRUG SYSTEM OF
                          OLMESARTAN MEDOXOMILAND THEIR EVALUATION THEREOF”
                        </td>{" "}
                        <td>20202150733</td> <td>Filed & Published</td>{" "}
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          “NOVEL METHOD DEVELOPMENT TO ESTIMATE PARACETAMOL AND
                          ETODOLAC AND THEIR VALIDATION THEREOF”
                        </td>{" "}
                        <td>202021051205</td> <td>Filed & Published</td>{" "}
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Dr. Jitendra Sheetlani</td>
                        <td>
                          GARBAGE MONITORING AND CLEARING PROCESS BY IOT
                        </td>{" "}
                        <td>2021100426</td> <td>Filed & Published</td>{" "}
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          EUPHORBIA THYMIFOLIA LINN EXTRACT FOR THE TREATMENT OF
                          HYPERLIPIDEMIA AND DIABETES
                        </td>{" "}
                        <td>202121043044</td> <td>Filed & Published</td>{" "}
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>Filed & Published</td>
                        <td>A SMART DUSTBIN FOR DISABLED PEOPLE</td>{" "}
                        <td>202141001895</td> <td>Filed & Published</td>{" "}
                      </tr>

                      <tr>
                        <td>13</td>
                        <td>Dr. PRABODH KUMAR KHAMPARIYA</td>
                        <td>SOLAR POWERED ESCALATOR</td> <td>202141017941</td>{" "}
                        <td>Filed & Published</td>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h5
                  style={{
                    marginLeft: "40%",
                    color: "#780000",
                    textDecoration: "underline",
                  }}
                >
                  <b>2020</b>
                </h5>{" "}
                <br />
                <div className="tann">
                  <table
                    className="responsive-table"
                    style={{ marginLeft: "-3%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">S. No.</th>
                        <th scope="col">Name of Inventors</th>
                        <th scope="col">TITLE</th>
                        <th scope="col">Application No./Granted Patent No.</th>
                        <th scope="col">Granted/ Published</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Dr. G R Selokar</td>
                        <td>Non- Conventional grooved stepped shoe ribs</td>
                        <td>202021003931</td>
                        <td>Filed & Published</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Dr. Jiterndra Sheetlani</td>
                        <td>
                          Night Patrol System Based On Iot And Radio Frequency
                          Identification{" "}
                        </td>
                        <td>202021039031</td>
                        <td>Filed & Published</td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td>Dr. Neelesh Choubey</td>
                        <td>
                          A Poly herbal formulation for treatment of liver
                          disorder and method of preparation
                        </td>{" "}
                        <td>202021051557</td> <td>Filed & Published</td>{" "}
                      </tr>

                      <tr>
                        <td>4</td>
                        <td>Dr. C. K Tyagi</td>
                        <td>
                          “A pharmaceutical composition comprising a compressed
                          polyherbal tablets for the treatment of infections
                          caused by helminthes”
                        </td>{" "}
                        <td>202021042927</td> <td>Filed & Published</td>{" "}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <br />

            {/* </div> */}
          </div>
          <div className="col-lg-2">
          <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Research
              </h5>
              <br></br>{" "}
              <li className="lli">
               <Link to="/Director">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Director (R&D)
                   </Link>
              </li>
              <li className="lli">
               <Link to="/R&D-Cell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>R & D Cell
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Council-For-Research">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Council For
                  Research{" "}
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Research-Policy">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Research Policy
                   </Link>
              </li>
              <li className="lli">
               <Link to="/Consultancy-Services">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Consultancy
                  Services{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Patent">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Patents{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/mou-collaboration">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Collaboration &
                  Mou{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/IICCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>IIC Cell{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/E-Resources">
                  <i class="fa fa-adjust" aria-hidden="true"></i>E-Resources{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/Exposition">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Exposition{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/UGAndPGScholarsProject">
                  <i class="fa fa-adjust" aria-hidden="true"></i>UG & PG
                  Scholars Project{" "}
                   </Link>
              </li>
              <li className="lli">
                {" "}
               <Link to="/NPTEL">
                  <i class="fa fa-adjust" aria-hidden="true"></i>NPTEL{" "}
                   </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default Patents;
