import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import nss_1 from "../../images/ABOUT/NSS/nss_1.png";
import nss_2 from "../../images/ABOUT/NSS/nss_2.png";
import { Link } from "react-router-dom";

function NSS() {
  return (
    <>
      <Nav />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.nss_image{
height:400px;
width:700px;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:10px; 
margin-left:30px;
margin-top:5px;
position:sticky;
 white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Ensures text doesn't overflow the container */
//  text-overflow: ellipsis; /* Adds '...' if text overflows */
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 12px;
    height: 27px;
    line-height: 20px;
    margin: 2px 6px 5px 0;
    text-align: center;
    width:auto;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 10px; 
        margin-left: -10px;
        // margin-top:-40px;
        display: flex;
       flex-direction: column; /* Default to vertical */
        //  position: sticky; /* Makes the list sticky */
  // top: 5; /* Sticks the list to the top of the viewport when scrolling */
    //  background-color: #fff; /* Ensures the background remains visible */
  //  z-index: 1000; /* Keeps the list above other content */
    }



   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:10px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>NSS</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <h5
                style={{
                  color: "#432E54",
                  fontWeight: 700,
                  fontFamily: "'poppins',sansSerif",
                }}
              >
                National Service Scheme (NSS)
              </h5>
              <p
                className="mt-3"
                style={{
                  textAlign: "justify",
                  color: "black",
                  fontFamily: "'poppins',sansSerif",
                }}
              >
                National Service Scheme (NSS) is a Central Sector Scheme of
                Government of India, Ministry of Youth Affairs &
                Sports,Rashtriya Seva Yojana celebrated World Environment Day
                with Jan Seva Mitras Participate volenteers with program.
                <br></br>
                The 55th Foundation Day program of NSS was celebrated in the
                university by the NSS unit of Rashtriya Seva Yojana Sri Sathya
                Sai University of Technology and Medical Sciences, Pachama
                Sehore. Participate volenteers with program.{" "}
              </p>
              <br></br>
              <br></br>
              <img className="nss_image" src={nss_1}></img>
              <br></br>
              <img className="nss_image" src={nss_2}></img>
            </div>
            <div className="col-lg-3">
              <h5 style={{ marginLeft: "50px", marginTop: "-50px" }}>About </h5>

              <ul className="list-icon">
                <li className="lli">
                  <Link to="/History_Milestones">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Background
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/institutes">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutes
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/promotingsociety">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Promoting
                    Society
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/vision-mission">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Vision &
                    Mission
                  </Link>
                </li>
                <li className="lli">
                  <Link to="/core-approvalsvalues">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Core Values
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/best-practices">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Best
                    Practices
                  </Link>
                </li>

                <li className="lli">
                  {" "}
                  <Link to="/Public-Self-Disclosure">
                    <i class="fa fa-adjust" aria-hidden="true"></i> Public Self
                    Disclosure
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/Central_facilities">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Central
                    Facilities{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/InstitutionalDevPlan">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Institutional
                    Development Plan{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/Linknnual_reports">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Annual
                    Reports{" "}
                  </Link>
                </li>
                <li className="lli">
                  {" "}
                  <Link to="/NSS">
                    <i class="fa fa-adjust" aria-hidden="true"></i> NSS
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default NSS;
