import React from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function SubGroupMaster() {
  return (
    <>
      <AdminDashboard />
      <h1 style={{ marginTop: "10%", marginLeft: "50%" }}>
        {" "}
        SubGroupMaster
      </h1>
    </>
  );
}

export default SubGroupMaster;
