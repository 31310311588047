import React from "react";
import bgimage from "../images/b.png";

const Why = () => {
  return (
    <>
      <style>
        {`
          /* our-skills */
          .our-skills {
            padding: 80px 0;
            position: relative;
            background: linear-gradient(#131313e1, #181818e1),
              url('${bgimage}') no-repeat;
            color: #fff;
            background-position: center;
            background-size: cover;
            background-attachment: fixed;
            display: flex;
            // height:500px;
            align-items: center;
          }
          .our-skills .our-skills-text h4 {
            font-weight: bold;
            font-style: italic;
            color: #ffc815;
          }
          .our-skills .our-skills-text p {
            color: #dbdbdb;
            letter-spacing: 1px;
            line-height: 1.5;
          }
          .our-skills .skills-prog {
            height: 400px;
            width: 400px;
            display: flex;
            flex-wrap: wrap;
            margin-left: auto;
          }
          .our-skills .counter-item-outer {
            flex-basis: 50%;
          }
          .our-skills .counter-item {
            height: 100%;
            width: 80%;
            display: flex;
            align-items: left;
            flex-direction: column;
            justify-content: center;
          }
          .our-skills h6 {
            color: #dbdbdb;
            margin-bottom: 10px;
            text-transform: uppercase;
            letter-spacing: 2px;
          }
          .our-skills h2 {
            height: 100px;
            width: 100px;
            border: 1px solid #fff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 50%;
            color: #ffc815;
          }



          @media screen and (max-width: 480px) {
        .our-skills h2{
        margin-left:20px;}

.our-skills{
margin-top:120px;
}
.head4{
margin-left:20px;}

.headdd{
margin-left:20px}


.counter-item{
margin-left:20px;}
        .p{
        font-size: 0.9rem;
        margin-left: 23px;
        width: 351px;
        text-align: justify;}
          }
        `}
      </style>

      <link
        rel="stylesheet"
        href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
        integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
        crossOrigin="anonymous"
      />
      <section className="our-skills">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="our-skills-text">
                <h4 className="head4">Our Mission</h4>
                <p className="p">
                  At Sri Satya Sai University of Technology & Medical Sciences
                  (SSSSUTMS), our mission is to provide high-quality education
                  that equips students with the knowledge, skills, and values
                  needed to excel and contribute to society. We promote
                  innovative research, foster community engagement, and create
                  an inclusive learning environment that celebrates diversity
                  and offers equal opportunities. We are committed to
                  sustainable development, ethical leadership, and lifelong
                  learning, preparing our students to lead with integrity and
                  purpose.
                </p>
              </div>
              <div className="our-skills-text pt-4">
                <h4 className="head4">Our Vision</h4>
                <p className="p">
                  Our vision is to be a leading university that fosters a
                  transformative educational experience through cutting-edge
                  research, inclusive community engagement, and a commitment to
                  sustainability. We aim to nurture future leaders who are not
                  only academically proficient but also socially responsible,
                  innovative, and globally competent.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="skills-prog">
                <div className="counter-item-outer">
                  <div className="counter-item">
                    <h2>10+</h2>
                    <h6 className="headdd">Years In Education</h6>
                  </div>
                </div>
                <div className="counter-item-outer">
                  <div className="counter-item">
                    <h2>600+</h2>
                    <h6 className="headdd"> Experienced Faculties</h6>
                  </div>
                </div>

                <div className="counter-item-outer">
                  <div className="counter-item">
                    <h2>90+</h2>
                    <h6 className="headdd">courses </h6>
                  </div>
                </div>
                <div className="counter-item-outer">
                  <div className="counter-item">
                    <h2>25+</h2>
                    <h6 className="headdd">DEGREES</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Why;
