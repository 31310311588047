
import React, { useState } from "react";
import AdminDashboard from "./AdminDashboard";
import { FormControl } from "@mui/material";
import { Button, Row } from "react-bootstrap";

import axios from "axios";
import Paymen from "./Paymen";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function Paym() {
  const [selectedField, setSelectedField] = useState("option1");
  const [enteredValue, setEnteredValue] = useState("");
  const [userData, setUserData] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const handleSearch = async () => {
    if (selectedField === "option1" || !enteredValue) {
      alert("Please select a valid search field and enter a value.");
      return;
    }

    setShowTable(false);
    try {
      const api = `https://sssutms.ac.in/apitest/admin/searchonlinepayments?search=${enteredValue}`;
      const response = await axios.get(api);
      setUserData(response.data);
      setShowTable(true); // Show the new table when data is fetched
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <AdminDashboard />

      <style>
        {`
            .shadow-box {
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
              border-radius: 10px;
              padding: 10px;
              margin-top: 20px;
              width: 100%;
              margin-left:75px;
              height:390px;
            }
        
            .header {
              height: 40px;
              background-color: #001d3d;
              border-radius: 10px;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 20px;
              font-family: italic;
            }
            .login-input {
              margin-bottom:8px;
            }
            .login-input:focus {
              outline: none;
              border-bottom: 2px solid #344e41;
            }
            @media (max-width: 768px) {
              .shadow-box {
                padding: 10px;
                margin-top: 10px;
              }
              .header {
                font-size: 16px;
                height: 40px;
              }
              .login-input {
                font-size: 0.9rem;
              }
            }
            @media (max-width: 576px) {
              .shadow-box {
                margin: 10px;
                margin-Left:76px;
              }
              .container-fluid {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
              }
            }
            @media (min-width: 992px) { 
              .custom-laptop-style {
                margin-left: 5%;
              }
            }
          `}
      </style>

      <div style={{ display: "flex" }}>
        <div
          style={{
            width: "20%",
            height: "550px",
            padding: "10px",
            marginTop: "60px",
          }}
        >
          <div className="shadow-box" style={{ height: "250px" }}>
            <h1
              style={{
                fontSize: "16px",
                fontWeight: 700,
                color: "#A02334",
                marginBottom: "8px",
                fontFamily: "'Rubik', sans-serif",
              }}
            >
              Payment Status
            </h1>

            <form style={{ padding: "15px" }}>
              <Row>
                <FormControl>
                  <label style={labelStyle}> Search by </label>
                  <select
                    className="form-select form-select-sm login-input"
                    value={selectedField}
                    onChange={(e) => setSelectedField(e.target.value)}
                    required={true}
                    autoFocus={true}
                  >
                    <option value="option1">Select</option>
                    <option value="Regestration No">Registration No</option>
                  </select>

                  <label style={labelStyle}>Enter :</label>
                  <input
                    style={{ marginTop: "1px" }}
                    type="text"
                    value={enteredValue}
                    onChange={(e) => setEnteredValue(e.target.value)}
                    className="form-control"
                  />
                </FormControl>
              </Row>
              <Row>
                <Button
                  onClick={handleSearch}
                  style={{
                    marginLeft: "70px",
                    marginTop: "20px",
                    backgroundColor: "#004e92",
                    width: "90px",
                    height: "35px",
                    padding: "5px",
                  }}
                >
                  Search
                </Button>
              </Row>
            </form>
          </div>
        </div>
        <div style={{ width: "80%", marginTop: "3px", marginLeft: "-20px" }}>
          {showTable && (
            <div>
              <Paymen userData={userData} />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}
const labelStyle = {
  color: "#264653",
  fontSize: "15px",
  fontWeight: 700,
  fontFamily: "'Rubik', sans-serif",
};

export default Paym;
