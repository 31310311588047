import React from "react";
import Nav from "../../../HomePage/Nav";
import Footerpage from "../../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function Management() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
    
        .ddd {
            margin: 10px auto; /* Center the table */
            padding: 30px;
            overflow-x: auto; /* Allow horizontal scrolling on small screens */
            counter-reset: examples approaches;
            // background-color: Gainsboro;
            color: #333;
            font-size: 93.75%;
            line-height: 1.5;
            max-width: 1680px;
          }

          table {
            width: 90%; /* Make the table fill the container */
            border-collapse: collapse; /* Remove default spacing between table cells */
          }

          th, td {
            padding: .5em;
            border: 1px solid #999;
          }

          @media screen and (max-width: 768px) {
            /* Apply responsive styles for screens smaller than 768px */
            .ddd {
              overflow-x: auto;
            }

            table {
              max-width: 100%; /* Allow horizontal scrolling on small screens */
            }
          }
          h2 {
            text-align: center;
            // background-color:#14adad;
            // Width : 100%;
            // margin-left:10px;
            border-radius: 25px;
            font-size: 30px; /* Default font size */
            margin: 0; /* Remove default margin */
           
          }
          @media screen and (max-width: 600px) {
            h2 {
                font-size: 18px; /* Adjust font size for smaller screens */
            }
        }
        @media screen and (max-width: 768px) {
          table {
            width: 100%;
          }
          table thead {
            display: none;
          }
          table, table tbody, table tr, table td {
            display: block;
            width: 100%;
          }
          table tr {
            margin-bottom: 15px;
            display: block;
            border: 1px solid #ccc;
          }
          table td {
            text-align: left;
            padding: 10px;
          }
        }
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Management</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
                <h5 style={{ color: "#432818" }}>
                  <b>VISION</b>
                </h5>{" "}
                <br />
                “To be a world class school of Management Education focusing on excellence value and ethics”
                <br />
                <br />
                <h5 style={{ color: "#432818" }}>
                  <b>MISSION</b>
                </h5>{" "}
                <br />
                “To provide an effective learning and innovative environment for transforming management
                 experience into professionally competent, sharp capable of adapting to the changing needs of the Industry and Society, value driven ethically sensitive reaching out to the weak & less privilege.”

 
              </p>
              <br />
              <table style={{ marginLeft: "5%", textAlign: "center" }}>
                <thead style={{ backgroundColor: "#588157" }}>
                  <tr>
                  
                    <th scope="col">
                      <h6>
                        <b>OUTCOME BASED CURRICULUM</b>
                      </h6>
                    </th>
                   
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row"><Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Management/MBA.pdf">MBA</Link></th>
                  </tr>

                  <tr>
                    <th scope="row"><Link to = "https://cdn.sssutms.ac.in/media/static/PDF/Download_E/Management/BBA.pdf">BBA</Link></th>
                  </tr>
              
                </tbody>
              </table>{" "}





            </div>
            <div className="col-lg-3">
              <ul className="list-icon">
                <Link to="/Engineering">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Engineering
                   </Link>
                  </li>
                </Link>
                <Link to="/Pharmacy ">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Pharmacy
                   </Link>
                  </li>
                </Link>
                <Link to="/Educaion">
                  <li className="lli">
                   <Link to ="/">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Education
                   </Link>
                  </li>
                </Link>
                <Link to="/Physical-Education">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Physical
                      Education
                   </Link>
                  </li>
                </Link>
                <Link to="/Management">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Management
                     
                   </Link>
                  </li>
                </Link>
                <Link to="/Computer-Application">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> Computer
                      Application
                   </Link>
                  </li>
                </Link>
                <Link to="/BHMCT">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> BHMCT
                   </Link>
                  </li>
                </Link>
                <Link to="/Science">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> Science
                   </Link>
                  </li>
                </Link>
                <Link to="/Life-Science">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> Life
                      Science
                   </Link>
                  </li>
                </Link>
            
                <Link to="/Arts-And-Humanities">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> Arts And Humanitie
                   </Link>
                  </li>
                </Link>
                <Link to="/Commerce">
                  <li className="lli">
                   <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i> Commerce
                   </Link>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Management;
