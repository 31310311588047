import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import Trans from "../../images/Amemities/Transprotation.jpg";
import { Link } from "react-router-dom";


function  Forms() {
  return (
    <>
      <Nav />
      <br/>
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>Download Forms SSSUTMS</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
           <style>
  {
    `
  .tann{
  margin-left:40px;}
 @media (min-width: 48em) {
   .container {
     margin: 2%;
  }
}
 @media (min-width: 75em) {
   .container {
     margin: 2em auto;
     max-width: 75em;
  }
}
 .responsive-table {
   width: 100%;
   margin-bottom: 1.5em;
   border-spacing: 0;
}
 @media (min-width: 48em) {
   .responsive-table {
     font-size: 0.9em;
  }
}
 @media (min-width: 62em) {
   .responsive-table {
     font-size: 1em;
  }
}
 .responsive-table thead {
   position: absolute;
   clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
   padding: 0;
   border: 0;
   height: 1px;
   width: 1px;
   overflow: hidden;
}
 @media (min-width: 48em) {
   .responsive-table thead {
     position: relative;
     clip: auto;
     height: auto;
     width: auto;
     overflow: auto;
  }
}
 .responsive-table thead th {
   background-color: #387478;
   border: 1px solid rgba(134, 188, 37, 1);
   font-weight: normal;
   text-align: center;
   color: white;
}
 .responsive-table thead th:first-of-type {
   text-align: left;
}
 .responsive-table tbody, .responsive-table tr, .responsive-table th, .responsive-table td {
   display: block;
   padding: 0;
   text-align: left;
   white-space: normal;
}
 @media (min-width: 48em) {
   .responsive-table tr {
     display: table-row;
  }
}
 .responsive-table th, .responsive-table td {
   padding: 0.5em;
   vertical-align: middle;
}
 @media (min-width: 30em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 48em) {
   .responsive-table th, .responsive-table td {
     display: table-cell;
     padding: 0.5em;
  }
}
 @media (min-width: 62em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em 0.5em;
  }
}
 @media (min-width: 75em) {
   .responsive-table th, .responsive-table td {
     padding: 0.75em;
  }
}
 .responsive-table caption {
   margin-bottom: 1em;
   font-size: 1em;
   font-weight: bold;
   text-align: center;
}
 @media (min-width: 48em) {
   .responsive-table caption {
     font-size: 1.5em;
  }
}
 .responsive-table tfoot {
   font-size: 0.8em;
   font-style: italic;
}
 @media (min-width: 62em) {
   .responsive-table tfoot {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody {
     display: table-row-group;
  }
}
 .responsive-table tbody tr {
   margin-bottom: 1em;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr {
     display: table-row;
     border-width: 1px;
  }
}
 .responsive-table tbody tr:last-of-type {
   margin-bottom: 0;
}
 @media (min-width: 48em) {
   .responsive-table tbody tr:nth-of-type(even) {
     background-color: rgba(0, 0, 0, .12);
  }
}
 .responsive-table tbody th[scope="row"] {
   background-color: rgba(38, 137, 13, 1);
   color: white;
}
 @media (min-width: 30em) {
   .responsive-table tbody th[scope="row"] {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody th[scope="row"] {
     background-color: transparent;
     color: rgba(0, 0, 1, );
     text-align: left;
  }
}
 .responsive-table tbody td {
   text-align: right;
}
 @media (min-width: 48em) {
   .responsive-table tbody td {
     border-left: 1px solid rgba(134, 188, 37, 1);
     border-bottom: 1px solid rgba(134, 188, 37, 1);
     text-align: center;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td:last-of-type {
     border-right: 1px solid rgba(134, 188, 37, 1);
  }
}
 .responsive-table tbody td[data-type=currency] {
   text-align: right;
}
 .responsive-table tbody td[data-title]:before {
   content: attr(data-title);
   float: left;
   font-size: 0.8em;
   color: rgba(0, 0, 0, .54);
}
 @media (min-width: 30em) {
   .responsive-table tbody td[data-title]:before {
     font-size: 0.9em;
  }
}
 @media (min-width: 48em) {
   .responsive-table tbody td[data-title]:before {
     content: none;
  }
}
 `
  }
  </style>
            <div className="tann">
  <table className="responsive-table">
  
    <thead>
      <tr>
        <th scope="col">S. No.</th>
        <th scope="col">Form</th>
        <th scope="col">Link</th> 
      
      
      </tr>
    </thead>
   
    <tbody>
   
      <tr>
        <td >1</td>
        <td> Registration Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG Form.pdf'><b>Download</b></Link></td>    
      </tr>
      <tr>
        <td >2</td>
        <td> 	
        Enrolment Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG/Enrollment Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >3</td>
        <td>Hostel Admission Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG/Hostel Registration Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >4</td>
        <td> Entrance Examination Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG/ENTRANCE APPLICATION FORM.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >5</td>
        <td>	
        Exam Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG/Examination Application Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >6</td>
        <td> 	
        Revaluation forms</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/REG/REVALForm.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >7</td>
        <td>	
        Degree Form (in English)</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/DEGREE_Eng form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >8</td>
        <td> Degree Form (in Hindi)</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/DEGREE_FORM_Hindi Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >9</td>
        <td>Migration Form (In English)</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/MIGRATION_Eng Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >10</td>
        <td> Migration Form (In Hindi)</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/MIGRATION FORM_Hindi Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >11</td>
        <td>Alumni Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/alumni-form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >12</td>
        <td>Transfer Certificate Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/TC Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >13</td>
        <td>Registration In Higher Semester/Year</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/Registration_higher_Sem_year_form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >14</td>
        <td>Transcript form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/Transcript Form.pdf'><b>Download</b></Link></td>
      </tr>
      <tr>
        <td >15</td>
        <td>Mark-sheet Correction Form</td>
        <td style={{color:'#2a6f97'}}><Link to ='https://cdn.sssutms.ac.in/media/static/FORMS/Marksheet_Correction_Form.pdf'><b>Download</b></Link></td>
      </tr>
  
    
    </tbody>
  </table>
</div>
              </p>
              <br/>
          
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">

                <Link to = "/phd-notofication">
                <li className="lli">
                  <Link to ="/phd-notofication">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Notification Of Phd Award
                  </Link>
                </li>
                </Link>
                <Link to = "/forms">
                <li className="lli">
                  <Link to ="/forms">
                    <i class="fa fa-adjust" aria-hidden="true"></i>Forms
                  </Link>
                </li>
                </Link>
                <Link to = "/E-Content">
                <li className="lli">
                  <Link to ="/E-Content">
                    <i class="fa fa-adjust" aria-hidden="true"></i>E-Content
                  </Link>
                </li>
                </Link>
             
              </ul>
            </div>
          </div>
         
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default Forms;

