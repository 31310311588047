import React from "react";
import Nav from "../HomePage/Nav";
import Footerpage from "../HomePage/Footerpage";
import scan from "../images/SCAN.jpg"
import { Link } from "react-router-dom";

function UniversityAccountDetail() {
  return (
    <>
      <Nav />
      <br />
      <style>
        {`
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
         
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
        
    }
    
    .about_content_box_all {
        padding: 28px;
    }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
 
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
        
    }


   @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
                
        }
   
   }
    
    `}
      </style>
      <section className="section_all " id="about">
        <div className="container">
          <div class="two">
            <h1>University Account Detail</h1>
          </div>
          <br></br>

          <div className="row">
            <div className="col-lg-9">
              <p
                className="mt-3"
                style={{ textAlign: "justify", color: "black" }}
              >
                <br />

                <div className="tann" style={{ marginTop: "-2%" }}>
                  Sri Satya Sai Group of Institutions has a full-fledged branch
                  of Punjab National Bank and its ATM in the college premises.
                  It is a Nationalized Bank which has given all kinds of
                  transactional facility to students and staff. The bank also
                  provides zero balance accounts to students, helps them in
                  procuring Education loan and promotes their students friendly
                  schemes.
                  <h6 style={{ marginTop: "5%" }}>
                    <b>
                      Bank Account Detail :
                      <br />
                      SSSUTMS
                      <br />
                      Account Number: 7162002100000506
                      <br />
                      IFSC Code: PUNB0716200
                    </b>
                  </h6>
                  <h5
                    style={{
                      marginTop: "5%",
                      color: "#6f1d1b",
                      textDecoration: "underline",
                    }}
                  >
                    <b>Online Banking</b>
                  </h5>{" "}
                  <br />
                  <h5 style={{ color: "#432818" }}>
                    <b>SCAN & PAY USING ANY BHIM UPI</b>
                  </h5>
                  <br />
                  <img src={scan} style={{ width: "40%" }} />
                  <br />
                  <style>
                    {`
//     body {
// 	 padding: 30px;
// 	 background-color: antiquewhite;
// }
 .Table, .Table-row, .Table-row td {
	 border: 1px solid black;
}
 .Table {
	 margin-bottom: 20px;
}
 .Table-row td {
	 padding: 1px;
}
 .Table-head, .Table-foot {
	 background-color: #777;
	 color: white;
	 text-align: center;
	 padding: 10px;
}
 
    `}
                  </style>
                  <div className="Page">
                    <table
                      className="Table yolo js-ColumnTable"
                      id="CoolBeans"
                      data-breakpoint-width={700}
                    >
                      <thead className="Table-head">
                        <tr className="Table-row">
                          <td>Instruments</td>
                          <td>Charges</td>
                        </tr>
                      </thead>
                      <tbody className="Table-body">
                        <tr className="Table-row">
                          <td>
                            <b>UPI</b>
                          </td>
                          <td> No Charges</td>
                        </tr>
                        <tr className="Table-row otherClass">
                          <td>
                            <b>Debit Card (Rupay Card)</b>
                          </td>
                          <td>No Charges</td>
                        </tr>
                        <tr className="Table-row">
                          <td>
                            <b>Debit Card (Other Cards)</b>
                          </td>
                          <td>
                            0.40% = INR 2000 per transaction <br />
                            0.90% = INR 2000 per transaction
                          </td>
                        </tr>
                        <tr className="Table-row">
                          <td>
                            <b>Credit Card</b>
                          </td>
                          <td>1.1% per transaction </td>
                        </tr>
                        <tr className="Table-row">
                          <td>
                            <b>Netbanking</b>{" "}
                          </td>
                          <td>INR 15 per transaction </td>
                        </tr>
                        <tr className="Table-row">
                          <td>
                            <b>Wallet</b>
                          </td>
                          <td>1.50% per transaction </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </p>
              <br />
            </div>
            <div className="col-lg-3">
              <ul className="list-icon">
                <Link to="/Admission-Registration">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Admission
                      Registration
                    </Link>
                  </li>
                </Link>
                <Link to="/Admission-Notice">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Admission
                      Notice
                    </Link>
                  </li>
                </Link>
                <Link to="/University-Account-Detail">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>University
                      Account Detail
                    </Link>
                  </li>
                </Link>
                <Link to="/Fees-Structure">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Fees
                      Structure
                    </Link>
                  </li>
                </Link>
                <Link to="/Admission-Procedure">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Admission
                      Procedure
                    </Link>
                  </li>
                </Link>
                <Link to="/Brochures">
                  <li className="lli">
                    <Link to ="">
                      <i class="fa fa-adjust" aria-hidden="true"></i>Brochures
                    </Link>
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footerpage />
    </>
  );
}

export default UniversityAccountDetail;
