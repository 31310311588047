

//////////////////////////////////////////////shi vala form h data14-11-2024 /////////////////////////////////////////////////////////////
import React, { useState, useEffect } from "react";
import "./Button.css";
import { Container, Button, Form, FormControl } from "react-bootstrap";
import { Grid } from "@mui/material";
import { State, City } from "country-state-city";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "react-bootstrap/Table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import { Progress } from "@chakra-ui/react";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import OnlyHeaders from "./OnlyHeaders";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const theme = createTheme();

const EnrollementForm = () => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState({});

  // ////////////////pagination table states/////////////////////////////
  const navigate = useNavigate();
  const [secondFunctionCalled, setSecondFunctionCalled] = useState(false);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));
  const studentId = userData._id;

  /////////////////////////////////////////PERSONAL FORM API///////////////////////////////////////
  const [formData, setFormData] = useState({
    name: "",
    fatherName: "",
    motherName: "",
    dob: "",
    gender: "",
    mobile: "",
    category: "",
    email: "",
    qualification: "",
    lastExamPassingYear: "",
    passingYear: "",
    qualifyingExamPercentage: "",
    QualifiedCourse: "",
    admissionSession: "",
    undergraduateCertificate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  /////////////////////////////PERSONAL FORM API///////////////////////////////////////////////////////////////
  ////////////////////////////////PROFESSIONAL FORM API & Validation//////////////////////////////////////////////////

  const [formData1, setFormData1] = useState({
    Handicapped: "",
    Medium: "",
    Nationality: "",
    Domicile: "",
    ScholarshipRequired: "",
    FathersOccupation: "",
    MothersOccupation: "",
    FathersIncome: "",
    MothersIncome: "",
    SamagraId: "",
    AadharNumber: "",
    ParentMobile: "",
  });

  const [errors1, setErrors1] = useState({});

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData1((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors1((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  // ///////////////////////////PROFESSIONAL FORM ////////////////////////////////////////////////
  // //////////////////////////////////ADDRESS FORM API & Validation////////////////////////////////////////

  const [formData2, setFormData2] = useState({
    address1: "",
    address2: "",
    country: "",
    state: "",
    district: "",
    pinCode: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange4 = (e) => {
    const { name, value } = e.target;

    if (name === "country") {
      setFormData2((prevData) => ({
        ...prevData,
        [name]: value,
        state: "",
        district: "",
      }));
      setCities([]);
    } else if (name === "state") {
      setFormData2((prevData) => ({
        ...prevData,
        [name]: value,
        district: "",
      }));
      try {
        const stateCities = City.getCitiesOfState("IN", value);
        setCities(stateCities);
      } catch (err) {
        // Handle error if necessary
      }
    } else {
      // Update form data
      setFormData2((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    // Clear errors
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
  };

  useEffect(() => {
    const getAllStates = async () => {
      try {
        const statesData = await State.getStatesOfCountry("IN");
        setStates(statesData);
      } catch (err) {
        // console.log(err);
      }
    };

    getAllStates();
  }, []);

  /////////////////////////////ADDRESS FORM API/////////////////////////////////////
  ///////////////////////////////////////////STATE API///////////////////////////////////////
  useEffect(() => {
    const getAllStates = async () => {
      try {
        const statesData = await State.getStatesOfCountry("IN");
        setStates(statesData);
      } catch (err) {
        console.log(err);
      }
    };

    getAllStates();
  }, []);

  // //////////////////////Academic logic/////////////////////
  const [formData3, setFormData3] = useState({
    tenthSchool: "",
    tenthPassingYear: "",
    tenthRollNo: "",
    tenthBoard: "",
    tenthMarksObtain: "",
    tenthMaxMarks: "",
    tenthPercentage: "",
    twelfthSchool: "",
    twelfthPassingYear: "",
    twelfthRollNo: "",
    twelfthBoard: "",
    twelfthMarksObtain: "",
    twelfthMaxMarks: "",
    twelfthPercentage: "",
    graduationCollege: "",
    graduationPassingYear: "",
    graduationRollNo: "",
    graduationUniversity: "",
    graduationMarksObtain: "",
    graduationMaxMarks: "",
    graduationPercentage: "",
    postGraduationCollege: "",
    postGraduationPassingYear: "",
    postGraduationRollNo: "",
    postGraduationUniversity: "",
    postGraduationMarksObtain: "",
    postGraduationMaxMarks: "",
    postGraduationPercentage: "",
    otherExam: "",
    otherSchool: "",
    otherPassingYear: "",
    otherRollNo: "",
    otherBoard: "",
    otherExamType: "",
    otherMarksObtain: "",
    otherMaxMarks: "",
    otherPercentage: "",
  });

  const [selectedQualification, setSelectedQualification] = useState(
    userData?.qualification
  );

  const renderRowsBasedOnQualification = () => {
    switch (selectedQualification) {
      case "10th":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              autoComplete="off"
                              value={formData3.tenthPercentage}
                              onChange={handleChange3}
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );
      case "12th":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>
                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "150px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={formData3.tenthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "150px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={formData3.twelfthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );

      case "UG":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={formData3.tenthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={formData3.twelfthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Graduation</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationCollege"
                              value={formData3.graduationCollege}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationCollege && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationCollege}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationPassingYear"
                              value={formData3.graduationPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationRollNo"
                              value={formData3.graduationRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationUniversity"
                              value={formData3.graduationUniversity}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationUniversity && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationUniversity}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMaxMarks"
                              value={formData3.graduationMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMarksObtain"
                              value={formData3.graduationMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="graduationPercentage"
                              value={
                                formData3.graduationMarksObtain &&
                                formData3.graduationMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.graduationMarksObtain
                                      ) /
                                        parseFloat(
                                          formData3.graduationMaxMarks
                                        )) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );

      case "Diploma":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={formData3.tenthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={formData3.twelfthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );

      case "PG":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            />
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={formData3.tenthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={formData3.twelfthPercentage}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Graduation</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationCollege"
                              value={formData3.graduationCollege}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationCollege && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationCollege}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationPassingYear"
                              value={formData3.graduationPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationPassingYear}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationRollNo"
                              value={formData3.graduationRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationRollNo}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationUniversity"
                              value={formData3.graduationUniversity}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationUniversity && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationUniversity}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMaxMarks"
                              value={formData3.graduationMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMarksObtain"
                              value={formData3.graduationMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="graduationPercentage"
                              value={
                                formData3.graduationMarksObtain &&
                                formData3.graduationMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.graduationMarksObtain
                                      ) /
                                        parseFloat(
                                          formData3.graduationMaxMarks
                                        )) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Post Graduation</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="postGraduationCollege"
                              value={formData3.postGraduationCollege}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationCollege && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationCollege}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="postGraduationPassingYear"
                              value={formData3.postGraduationPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationPassingYear}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="postGraduationRollNo"
                              value={formData3.postGraduationRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationRollNo}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="postGraduationUniversity"
                              value={formData3.postGraduationUniversity}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationUniversity && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationUniversity}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="postGraduationMaxMarks"
                              value={formData3.postGraduationMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="postGraduationMarksObtain"
                              value={formData3.postGraduationMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.postGraduationMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.postGraduationMarksObtain}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              name="postGraduationPercentage"
                              value={
                                formData3.postGraduationMarksObtain &&
                                formData3.postGraduationMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.postGraduationMarksObtain
                                      ) /
                                        parseFloat(
                                          formData3.postGraduationMaxMarks
                                        )) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );

      case "Graduation":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            />
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={
                                formData3.tenthMarksObtain &&
                                formData3.tenthMaxMarks
                                  ? `${(
                                      (parseFloat(formData3.tenthMarksObtain) /
                                        parseFloat(formData3.tenthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {/* {errors2.tenthPercentage && (
                              <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
                            )} */}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={
                                formData3.twelfthMarksObtain &&
                                formData3.twelfthMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.twelfthMarksObtain
                                      ) /
                                        parseFloat(formData3.twelfthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Graduation</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationCollege"
                              value={formData3.graduationCollege}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationCollege && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationCollege}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationPassingYear"
                              value={formData3.graduationPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationRollNo"
                              value={formData3.graduationRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationUniversity"
                              value={formData3.graduationUniversity}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationUniversity && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationUniversity}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMaxMarks"
                              value={formData3.graduationMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMarksObtain"
                              value={formData3.graduationMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="graduationPercentage"
                              value={
                                formData3.graduationMarksObtain &&
                                formData3.graduationMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.graduationMarksObtain
                                      ) /
                                        parseFloat(
                                          formData3.graduationMaxMarks
                                        )) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );
      case "BSC":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            />
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={
                                formData3.tenthMarksObtain &&
                                formData3.tenthMaxMarks
                                  ? `${(
                                      (parseFloat(formData3.tenthMarksObtain) /
                                        parseFloat(formData3.tenthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {/* {errors2.tenthPercentage && (
                                <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
                              )} */}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/Diploma</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={
                                formData3.twelfthMarksObtain &&
                                formData3.twelfthMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.twelfthMarksObtain
                                      ) /
                                        parseFloat(formData3.twelfthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">Graduation</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationCollege"
                              value={formData3.graduationCollege}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationCollege && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationCollege}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationPassingYear"
                              value={formData3.graduationPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationRollNo"
                              value={formData3.graduationRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="graduationUniversity"
                              value={formData3.graduationUniversity}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationUniversity && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationUniversity}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMaxMarks"
                              value={formData3.graduationMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="graduationMarksObtain"
                              value={formData3.graduationMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.graduationMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.graduationMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="graduationPercentage"
                              value={
                                formData3.graduationMarksObtain &&
                                formData3.graduationMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.graduationMarksObtain
                                      ) /
                                        parseFloat(
                                          formData3.graduationMaxMarks
                                        )) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );

      case "ITI":
        return (
          <>
            {
              <Container
                className="shadow p-3 mb-3 bg-body rounded"
                style={{ marginTop: "20px" }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#ffff",
                    }}
                  >
                    Academic Details
                  </h5>
                </div>
                <br></br>

                <Paper sx={{ width: "100%" }}>
                  <TableContainer sx={{ maxHeight: "440px" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Exam </b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>School/College</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Passing Year</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Roll No</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Name of Board/University</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Total Marks</b>
                            </h5>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Marks Obtain</b>
                            </h5>
                          </TableCell>

                          <TableCell
                            align="left"
                            style={{ backgroundColor: "#e6ecf0" }}
                          >
                            <h5>
                              <b>Percentage</b>
                            </h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell align="left">10th</TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthSchool"
                              value={formData3.tenthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            />
                            {errors2.tenthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthSchool}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthPassingYear"
                              value={formData3.tenthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthRollNo"
                              value={formData3.tenthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="tenthBoard"
                              value={formData3.tenthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMaxMarks"
                              value={formData3.tenthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMaxMarks}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="tenthMarksObtain"
                              value={formData3.tenthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.tenthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.tenthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="tenthPercentage"
                              value={
                                formData3.tenthMarksObtain &&
                                formData3.tenthMaxMarks
                                  ? `${(
                                      (parseFloat(formData3.tenthMarksObtain) /
                                        parseFloat(formData3.tenthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {/* {errors2.tenthPercentage && (
                                  <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
                                )} */}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell align="left">12th/ITI</TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthSchool"
                              value={formData3.twelfthSchool}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthSchool && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthSchool}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthPassingYear"
                              value={formData3.twelfthPassingYear}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthPassingYear && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthPassingYear}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthRollNo"
                              value={formData3.twelfthRollNo}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "200px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthRollNo && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthRollNo}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="text"
                              name="twelfthBoard"
                              value={formData3.twelfthBoard}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "180px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthBoard && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthBoard}
                              </p>
                            )}
                          </TableCell>

                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMaxMarks"
                              value={formData3.twelfthMaxMarks}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMaxMarks && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMaxMarks}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              type="number"
                              name="twelfthMarksObtain"
                              value={formData3.twelfthMarksObtain}
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                            {errors2.twelfthMarksObtain && (
                              <p style={{ color: "red" }}>
                                {errors2.twelfthMarksObtain}
                              </p>
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Form.Control
                              name="twelfthPercentage"
                              value={
                                formData3.twelfthMarksObtain &&
                                formData3.twelfthMaxMarks
                                  ? `${(
                                      (parseFloat(
                                        formData3.twelfthMarksObtain
                                      ) /
                                        parseFloat(formData3.twelfthMaxMarks)) *
                                      100
                                    ).toFixed(2)}%`
                                  : ""
                              }
                              onChange={handleChange3}
                              autoComplete="off"
                              style={{
                                borderRadius: "5px",
                                width: "80px",
                                height: "50px",
                              }}
                            ></Form.Control>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </Container>
            }
          </>
        );
      default:
        return null;
    }
  };

  const [errors2, setErrors2] = useState({});

  const calculatePercentage = (obtainedMarks, maxMarks) => {
    const obtained = parseFloat(obtainedMarks);
    const max = parseFloat(maxMarks);

    if (!isNaN(obtained) && !isNaN(max) && max !== 0) {
      return ((obtained / max) * 100).toFixed(2) + "%";
    }

    return "";
  };

  const handleChange3 = (e) => {
    const { name, value } = e.target;

    setFormData3((prevData) => ({
      ...prevData,
      [name]: value,
      tenthPercentage: calculatePercentage(
        name === "tenthMarksObtain" ? value : prevData.tenthMarksObtain,
        name === "tenthMaxMarks" ? value : prevData.tenthMaxMarks
      ),
      twelfthPercentage: calculatePercentage(
        name === "twelfthMarksObtain" ? value : prevData.twelfthMarksObtain,
        name === "twelfthMaxMarks" ? value : prevData.twelfthMaxMarks
      ),
      graduationPercentage: calculatePercentage(
        name === "graduationMarksObtain"
          ? value
          : prevData.graduationMarksObtain,
        name === "graduationMaxMarks" ? value : prevData.graduationMaxMarks
      ),
      postGraduationPercentage: calculatePercentage(
        name === "postGraduationMarksObtain"
          ? value
          : prevData.postGraduationMarksObtain,
        name === "postGraduationMaxMarks"
          ? value
          : prevData.postGraduationMaxMarks
      ),
    }));
  };

  const handleSubmit4 = (e) => {

    const errors2 = {};

    if (selectedQualification === "10th") {

      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }
      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }

    }

    if (selectedQualification === "12th") {

      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }
      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }
    }

    if (selectedQualification === "UG") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }
      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }

      if (!formData3.graduationCollege.trim()) {
        errors2.graduationCollege = "graduationCollege is required";
      }
      if (!formData3.graduationPassingYear.trim()) {
        errors2.graduationPassingYear = "graduationPassingYear is required";
      }
      if (!formData3.graduationRollNo.trim()) {
        errors2.graduationRollNo = "graduationRollNo is required";
      }
      if (!formData3.graduationUniversity.trim()) {
        errors2.graduationUniversity = "graduationUniversity is required";
      }
      if (!formData3.graduationMarksObtain.trim()) {
        errors2.graduationMarksObtain = "graduationMarksObtain is required";
      }
      if (!formData3.graduationMaxMarks.trim()) {
        errors2.graduationMaxMarks = "graduationMaxMarks is required";
      }
      if (!formData3.graduationPercentage.trim()) {
        errors2.graduationPercentage = "graduationPercentage is required";
      }
    }

    if (selectedQualification === "BSC") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }

      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }

      if (!formData3.graduationCollege.trim()) {
        errors2.graduationCollege = "graduationCollege is required";
      }
      if (!formData3.graduationPassingYear.trim()) {
        errors2.graduationPassingYear = "graduationPassingYear is required";
      }
      if (!formData3.graduationRollNo.trim()) {
        errors2.graduationRollNo = "graduationRollNo is required";
      }
      if (!formData3.graduationUniversity.trim()) {
        errors2.graduationUniversity = "graduationUniversity is required";
      }
      // if (!formData3.graduationExamType.trim()) {
      //   errors2.graduationExamType = "graduationExamType is required";
      // }
      if (!formData3.graduationMarksObtain.trim()) {
        errors2.graduationMarksObtain = "graduationMarksObtain is required";
      }
      if (!formData3.graduationMaxMarks.trim()) {
        errors2.graduationMaxMarks = "graduationMaxMarks is required";
      }
      if (!formData3.graduationPercentage.trim()) {
        errors2.graduationPercentage = "graduationPercentage is required";
      }
    }

    if (selectedQualification === "Graduation") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }
      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }

      if (!formData3.graduationCollege.trim()) {
        errors2.graduationCollege = "graduationCollege is required";
      }
      if (!formData3.graduationPassingYear.trim()) {
        errors2.graduationPassingYear = "graduationPassingYear is required";
      }
      if (!formData3.graduationRollNo.trim()) {
        errors2.graduationRollNo = "graduationRollNo is required";
      }
      if (!formData3.graduationUniversity.trim()) {
        errors2.graduationUniversity = "graduationUniversity is required";
      }
      // if (!formData3.graduationExamType.trim()) {
      //   errors2.graduationExamType = "graduationExamType is required";
      // }
      if (!formData3.graduationMarksObtain.trim()) {
        errors2.graduationMarksObtain = "graduationMarksObtain is required";
      }
      if (!formData3.graduationMaxMarks.trim()) {
        errors2.graduationMaxMarks = "graduationMaxMarks is required";
      }
      if (!formData3.graduationPercentage.trim()) {
        errors2.graduationPercentage = "graduationPercentage is required";
      }
    }

    if (selectedQualification === "PG") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }

      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }

      if (!formData3.graduationCollege.trim()) {
        errors2.graduationCollege = "graduationCollege is required";
      }
      if (!formData3.graduationPassingYear.trim()) {
        errors2.graduationPassingYear = "graduationPassingYear is required";
      }
      if (!formData3.graduationRollNo.trim()) {
        errors2.graduationRollNo = "graduationRollNo is required";
      }
      if (!formData3.graduationUniversity.trim()) {
        errors2.graduationUniversity = "graduationUniversity is required";
      }
      // if (!formData3.graduationExamType.trim()) {
      //   errors2.graduationExamType = "graduationExamType is required";
      // }
      if (!formData3.graduationMarksObtain.trim()) {
        errors2.graduationMarksObtain = "graduationMarksObtain is required";
      }
      if (!formData3.graduationMaxMarks.trim()) {
        errors2.graduationMaxMarks = "graduationMaxMarks is required";
      }
      if (!formData3.graduationPercentage.trim()) {
        errors2.graduationPercentage = "graduationPercentage is required";
      }
      if (!formData3.postGraduationCollege.trim()) {
        errors2.postGraduationCollege = "postGraduationCollege is required";
      }
      if (!formData3.postGraduationPassingYear.trim()) {
        errors2.postGraduationPassingYear =
          "postGraduationPassingYear is required";
      }
      if (!formData3.postGraduationRollNo.trim()) {
        errors2.postGraduationRollNo = "postGraduationRollNo is required";
      }
      if (!formData3.postGraduationUniversity.trim()) {
        errors2.postGraduationUniversity =
          "postGraduationUniversity is required";
      }

      if (!formData3.postGraduationMarksObtain.trim()) {
        errors2.postGraduationMarksObtain =
          "postGraduationMarksObtain is required";
      }
      if (!formData3.postGraduationMaxMarks.trim()) {
        errors2.postGraduationMaxMarks = "postGraduationMaxMarks is required";
      }
      if (!formData3.postGraduationPercentage.trim()) {
        errors2.postGraduationPercentage =
          "postGraduationPercentage is required";
      }
    }

    if (selectedQualification === "Diploma") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }

      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
    }

    if (selectedQualification === "ITI") {
      if (!formData3.tenthSchool.trim()) {
        errors2.tenthSchool = "tenthSchool is required";
      }
      if (!formData3.tenthPassingYear.trim()) {
        errors2.tenthPassingYear = "tenthPassingYear is required";
      }
      if (!formData3.tenthRollNo.trim()) {
        errors2.tenthRollNo = "tenthRollNo is required";
      }
      if (!formData3.tenthMarksObtain.trim()) {
        errors2.tenthMarksObtain = "tenthMarksObtain is required";
      }
      if (!formData3.tenthMaxMarks.trim()) {
        errors2.tenthMaxMarks = " tenthMaxMarks is required";
      }
      if (!formData3.tenthPercentage.trim()) {
        errors2.tenthPercentage = "tenthPercentage is required";
      }
      if (!formData3.tenthBoard.trim()) {
        errors2.tenthBoard = "tenthBoard is required";
      }
      if (!formData3.twelfthSchool.trim()) {
        errors2.twelfthSchool = "twelthSchool is required";
      }
      if (!formData3.twelfthPassingYear.trim()) {
        errors2.twelfthPassingYear = "twelthPassingYear is required";
      }
      if (!formData3.twelfthRollNo.trim()) {
        errors2.twelfthRollNo = "twelthRollNo is required";
      }
      if (!formData3.twelfthBoard.trim()) {
        errors2.twelfthBoard = "twelthBoard is required";
      }
      if (!formData3.twelfthMarksObtain.trim()) {
        errors2.twelfthMarksObtain = "twelthMarksObtain is required";
      }
      if (!formData3.twelfthMaxMarks.trim()) {
        errors2.twelfthMaxMarks = "twelthMaxMarks is required";
      }
      if (!formData3.twelfthPercentage.trim()) {
        errors2.twelfthPercentage = "twelthPercentage is required";
      }
    }

    
    // if (Object.keys(errors2).length === 0) {
    //   return true;
    // }
    return Object.keys(errors2).length === 0;
    // return false;
  };
  // /////////////////////////////////////photo & document validation/////////////////////////////////////////////////////////////////
  const [selectedDocQualification, setSelectedDocQualification] = useState( userData?.qualification);

  const renderRowsBasedDocuments = () => {
    switch (selectedDocQualification) {
      case "10th":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantPhoto && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></input>

                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.aadharCard && (
                    <div style={{ color: 'red' }}>{errors7.aadharCard}</div>
                  )}
                    <hr></hr>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                        </h5>
                      </div>

                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></input>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet10th && (
                    <div style={{ color: 'red' }}>{errors7.marksheet10th}</div>
                  )}
                    <hr></hr>
                  </Grid> */}

                  <Grid item xs={12}>
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ display: "flex" }}>
                      <h5>Marksheet of 12th Examination</h5>
                    </div>
                    <div style={{ display: "flex" }}>
                      <input
                        type="file"
                        placeholder="choose"
                        onChange={(e) => handleFileChange("marksheet12th", e)}
                      ></input>
                      <h6 style={{ backgroundColor: " #ffe6e6" }}>
                        Maximum 1000KB
                      </h6>
                    </div>
                  </div> */}
                    {/* {errors7.marksheet12th && (
                    <div style={{ color: 'red' }}>{errors7.marksheet12th}</div>
                  )} */}

                    {/* <hr></hr> */}
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {/* {errors7.domicileCertificate && (
                    <div style={{ color: 'red' }}>{errors7.domicileCertificate}</div>
                  )} */}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        />
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {/* {errors7.migrationCertificate && (
                    <div style={{ color: 'red' }}>{errors7.migrationCertificate}</div>
                  )} */}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );

      case "12th":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant Photo{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.applicantPhoto && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {/* {errors7.aadharCard && (
                    <div style={{ color: 'red' }}>{errors7.aadharCard}</div>
                  )} */}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></input>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Domicile Certificate </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Transfer Certificate </h5>
                      </div>

                      {isUploading.transferCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {/* {errors7.transferCertificate && (
                    <div style={{ color: 'red' }}>{errors7.transferCertificate}</div>
                  )} */}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        />
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {/* {errors7.migrationCertificate && (
                    <div style={{ color: 'red' }}>{errors7.migrationCertificate}</div>
                  )} */}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certcsdcdscsdificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <input
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></input>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    <hr></hr>
                  </Grid>
                </Grid>

                {/* {userData?.courseName === "BACHELOR OF MEDICINE BACHELOR OF SURGERY(MBBS)" && (
                <Grid item xs={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <h5>
                      NEET Score Card <span style={{ color: "red" }}>*</span>
                    </h5>
                    <input
                      type="file"
                      onChange={(e) => handleFileChange("neetScoreCard", e)}
                    />
                  </div>
                </Grid>
              )} */}
              </Container>

        
            }
          </>
        );

      case "UG":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {/* {isUploading.applicantPhoto && (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      )} */}
                      {isUploading.applicantPhoto && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>

                        {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />} */}
                    {/* {isUploading ? <Progress size="xs" isIndeterminate /> : ""} */}

                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        {isUploading.applicantSignature && "Loading..."}
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 12th Examination
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );
      case "Diploma":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>

                      {isUploading.applicantPhoto && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>

                        {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        {isUploading.applicantSignature && "Loading..."}
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}>*</span>{" "}
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Marksheet of 12th Examination</h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Domicile Certificate </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );
      case "PG":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>

                      {isUploading.applicantPhoto && <p>Loading...</p>}
                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>

                        {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        {isUploading.applicantSignature && "Loading..."}
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 12th Examination
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Under Graduate Certificate
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.undergraduateCertificate && (
                        <p>Loading...</p>
                      )}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("undergraduateCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.undergraduateCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.undergraduateCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.incomeCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.incomeCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );
      case "Graduation":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantPhoto && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 12th Examination
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.incomeCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.incomeCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );

      case "BSC":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantPhoto && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 12th Examination
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.incomeCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.incomeCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );

      case "ITI":
        return (
          <>
            {
              <Container
                className="shadow p-3 bg-body rounded"
                style={{
                  width: "100%",
                  backgroundColor: "#e8e5d1",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#1A3636",
                    width: "100%",
                    borderRadius: "10px",
                  }}
                >
                  <h5
                    style={{
                      padding: "10px",
                      margin: 0,
                      marginTop: "-3px",
                      color: "#fff",
                    }}
                  >
                    Documents & Signature
                  </h5>
                </div>
                <br></br>

                <Grid container>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Photo{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantPhoto && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantPhoto", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantPhoto && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantPhoto}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Applicant's Signature{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.applicantSignature && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("applicantSignature", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.applicantSignature && (
                      <div style={{ color: "red" }}>
                        {errors7.applicantSignature}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Aadhar Card <span style={{ color: "red" }}>*</span>
                        </h5>
                      </div>
                      {isUploading.aadharCard && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("aadharCard", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.aadharCard && (
                      <div style={{ color: "red" }}>{errors7.aadharCard}</div>
                    )}
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Marksheet of 10th Examination{" "}
                          <span style={{ color: "red" }}> *</span>
                        </h5>
                      </div>
                      {isUploading.marksheet10th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet10th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet10th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet10th}
                      </div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Marksheet of ITI Examination </h5>
                      </div>
                      {isUploading.ItImarksheet && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("ItImarksheet", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: "#ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.ItImarksheet && (
                      <div style={{ color: "red" }}>{errors7.ItImarksheet}</div>
                    )}
                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Marksheet of 12th Examination</h5>
                      </div>
                      {isUploading.marksheet12th && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) => handleFileChange("marksheet12th", e)}
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.marksheet12th && (
                      <div style={{ color: "red" }}>
                        {errors7.marksheet12th}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Domicile Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.domicileCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("domicileCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.domicileCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.domicileCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>
                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Transfer Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.transferCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("transferCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.transferCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.transferCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Income Certificate</h5>
                      </div>
                      {isUploading.incomeCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("incomeCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.incomeCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.incomeCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>
                          Migration Certificate{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </h5>
                      </div>
                      {isUploading.migrationCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("migrationCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>
                    {errors7.migrationCertificate && (
                      <div style={{ color: "red" }}>
                        {errors7.migrationCertificate}
                      </div>
                    )}

                    <hr></hr>
                  </Grid>

                  <Grid item xs={12}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <h5>Other Certificate</h5>
                      </div>
                      {isUploading.otherCertificate && <p>Loading...</p>}

                      <div style={{ display: "flex" }}>
                        <Form.Control
                          type="file"
                          placeholder="choose"
                          onChange={(e) =>
                            handleFileChange("otherCertificate", e)
                          }
                        ></Form.Control>
                        <h6 style={{ backgroundColor: " #ffe6e6" }}>
                          Maximum 1000KB
                        </h6>
                      </div>
                    </div>

                    <hr></hr>
                  </Grid>
                </Grid>
              </Container>
            }
          </>
        );

      default:
        return null;
    }
  };

  const [fileUrls, setFileUrls] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    aadharCard: "",
    marksheet10th: "",
    diplomaMarksheet: "",
    marksheet12th: "",
    ItImarksheet: "",
    undergraduateCertificate: "",
    postgraduateCertificate: "",
    domicileCertificate: "",
    transferCertificate: "",
    incomeCertificate: "",
    migrationCertificate: "",
    otherCertificate: "",
  });

  const [errors7, setErrors7] = useState({
    applicantPhoto: "",
    applicantSignature: "",
    aadharCard: "",
    marksheet10th: "",
    marksheet12th: "",
    ItImarksheet: "",
    // diplomaMarksheet: "",
    undergraduateCertificate: "",
    postgraduateCertificate: "",
    domicileCertificate: "",
    transferCertificate: "",
    incomeCertificate: "",
    migrationCertificate: "",
    otherCertificate: "",
  });

  const handleFileChange = async (fieldName, e) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileSizeKB = file.size / 1024;
    if (fileSizeKB > 1000) {
      setErrors7((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "File size exceeds 1000KB",
      }));

      swal("Error", "File size exceeds 1000KB", "error");
      return;
    }

    try {
      const allowedTypes = ["image/jpg", "image/jpeg", "image/png"];
      if (!allowedTypes.includes(file.type)) {
        setErrors7((prevErrors) => ({
          ...prevErrors,
          [fieldName]: "Please choose a JPEG, JPG, or PNG file.",
        }));
        return;
      }

      const formData4 = new FormData();
      formData4.append("file", file);
      formData4.append("upload_preset", "upload");

      setIsUploading((prevUploading) => ({
        ...prevUploading,
        [fieldName]: true,
      }));

      const cloudinaryResponse = await fetch(
        `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
        {
          method: "POST",
          body: formData4,
        }
      );

      const cloudinaryData = await cloudinaryResponse.json();
      const fileUrl = cloudinaryData.secure_url;

      setFileUrls((prevFileUrls) => ({
        ...prevFileUrls,
        [fieldName]: fileUrl,
      }));

      setErrors7((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading((prevUploading) => ({
        ...prevUploading,
        [fieldName]: false,
      }));
    }
  };


  const handleSubmit5 = () => {
    const selectedQualification = userData?.qualification;

    const requiredFields = getRequiredFieldsByQualification(
      selectedQualification
    );

    setErrors7({});
    let isFormValid = true;

    requiredFields.forEach((field) => {
      if (!fileUrls[field]) {
        setErrors7((prevErrors) => ({
          ...prevErrors,
          [field]: "This field is required.",
        }));
        isFormValid = false;
      }
    });
    return isFormValid;

    // if (Object.keys(errors7).length === 0) {
    //   return true;
    // } else {
    //   return false;
    // }
    // });
  };

  const getRequiredFieldsByQualification = (qualification) => {
    const fieldsByQualification = {
      "10th": [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard"
        // "marksheet10th",
      ],
      "12th": [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        // "domicileCertificate",
        // "transferCertificate",
        // "migrationCertificate",
      ],
      UG: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        "marksheet12th",
        // "domicileCertificate",
        // "transferCertificate",
        // "migrationCertificate",
      ],
      BSC: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        "marksheet12th",
      ],
      PG: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        "marksheet12th",
        "undergraduateCertificate",

      ],
      Diploma: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
      ],
      ITI: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
      ],
      Graduation: [
        "applicantPhoto",
        "applicantSignature",
        "aadharCard",
        "marksheet10th",
        "marksheet12th",
        // "undergraduateCertificate",
        // "domicileCertificate",
        // "transferCertificate",
        // "migrationCertificate",
        // "otherCertificate",
      ],
    };
    return fieldsByQualification[qualification] || [];
  };

  ////////////////////////Document end////////////////////////////////////////////////////////
  const CurrentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  const studentType = CurrentUser.StudentType;
  const isEnrolled = CurrentUser.isEnrolled;
  const isPaid = CurrentUser.isPaid;

  const updateStudentData = async () => {
    try {
      setLoading(true);
      const apiUrl = "https://sssutms.ac.in/apitest/updatestudent";

      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          studentId,
          personalFormData: formData,
          professionalFormData: formData1,
          addressFormData: formData2,
          educationFormData: formData3,
          fileUrls,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        swal({
          icon: "error",
          title: "Error",
          text: `Error: ${errorData.errors.join(", ")}`,
        });
        return;
      }
      const data = await response.json();
      const updatedStudent = data.updatedStudent;
      sessionStorage.setItem("currentUser", JSON.stringify(updatedStudent));
      if (
        studentType === "EPravesh" &&
        isPaid === true &&
        isEnrolled === true
      ) {
        swal({
          title: "Success",
          text: "Enrolled Successfully",
          icon: "success",
          buttons: "OK",
        }).then(() => {
          navigate("/Epravesh/Student/EnrollementFee");
        });
      } else {
        swal({
          title: "Success",
          text: "Enrolled Successfully!",
          icon: "success",
          buttons: "OK",
        }).then(() => {
          navigate("/Epravesh/Student/EnrollementFee");
        });
      }
    } catch (error) {
      swal({
        icon: "error",
        title: "Error",
        text: `Something went Wrong!`,
      });
    } finally {
      setLoading(false);
    }
    setSecondFunctionCalled(true);
  };

  const handleAllValidations = () => {
    const isValid4 = handleSubmit4();

    return isValid4;
  };

  // const handleButtonClick = () => {
  //   const isValid4 = handleSubmit4();
  //   const isValid = handleSubmit5();
  //   console.log(isValid, "Is valid je aa rha hai ");

  //   if (isValid4 && isValid) {
  //     updateStudentData();
  //   } else {
  //     swal({
  //       icon: "error",
  //       title: "Error",
  //       text: "Please fill out all required fields",
  //     });
  //   }
  // };
  const handleButtonClick = () => {
    const isValid4 = handleSubmit4();
    const isValid = handleSubmit5();
  

    if (isValid4 && isValid) {
      updateStudentData();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: "Please fill out all required fields",
      });
    }
  };
  const handleBackClick = () => {
  
   
    navigate('/studentalldetail'); // Navigate to the specified path
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
       <style>
        {`
   h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 25px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
h1 em {
  font-style: normal;
  font-weight: 600;
}

/* === HEADING STYLE #1 === */
.one h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-top:90px;
  margin-left:400px
}
.one h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.one h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 10px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
.baack{
font-size: 15px;
color:white;
 margin-left: 5px;
 font-weight: 700;

 }
.disp{
display:flex;
}

  h1 {
 
  font-family: "Raleway", sans-serif;
margin-top:40px;
  font-size: 20px;
 
}
.oon{
  color:white;
        margin-top: 80px;
        height:40px;
        width:90px;
 padding:8px;
 background-color: #1A3636;
        margin-left: 20px
        }


         @media (max-width:480px){
      .disp{
display:flex;
width:400px;
}

.one h1 {
// width: 112px;
//         height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 74px;
        left: 106px;
        margin-left: -50px;
        // background-color: #b80000;
}







.oon{
  color:white;
        margin-top: 80px;
        height:30px;
        width:40px;
 padding:5px;
 background-color: #1A3636;
        margin-left: 20px
        }

.baack{
display:none;
 }
.disp{
display:flex;
}

}
`}

      </style>

      <OnlyHeaders />

      <ThemeProvider theme={theme}>
        <div>
        <div className="disp">
     
     <Button 
    onClick={handleBackClick} 
 
   className="oon"
  >
    <ArrowBackIcon />
    <span className="baack">
      Back
    </span>
  </Button>
        <div class="one">
          <h1>Enrollment Form</h1>
        </div>
        </div>
          <Container
            className="shadow p-3 bg-body rounded"
            style={{
              width: "100%",
              backgroundColor: "#1A3636",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                backgroundColor: "#1A3636",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <h5
                style={{
                  padding: "10px",
                  margin: 0,
                  marginTop: "-3px",
                  color: "#ffff",
                }}
              >
                Personal Details
              </h5>
            </div>

            <Grid container spacing={2} mt="5px">
              <Grid item xs={12} md={4}>
                <Form.Group controlId="name">
                  <Form.Label>
                    <b>
                      Name <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={userData?.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>

              <Grid item xs={12} md={4}>
                <Form.Group controlId="father's name">
                  <Form.Label>
                    <b>
                      Father's Name <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherName"
                    value={userData?.fathersname}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="mother's name">
                  <Form.Label>
                    <b>
                      Mother's Name <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="fatherName"
                    value={userData?.mothersname}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="dateOfBirth">
                  <Form.Label>
                    <b>
                      Date of Birth <span style={{ color: "red" }}>*</span>
                    </b>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <DatePicker
                        selected={
                          userData?.dob ? new Date(userData?.dob) : null
                        }
                        readOnly
                        dateFormat="dd/MM/yyyy"
                        customInput={<FormControl style={{ width: "165%" }} />}
                      />
                    </div>
                  </Form.Label>
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="gender">
                  <Form.Label>
                    <b>
                      Gender <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="gender"
                    value={userData?.gender}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="mobile">
                  <Form.Label>
                    <b>
                      Mobile <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="mobile"
                    value={userData?.mobile}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="category">
                  <Form.Label>
                    <b>
                      Category <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="category"
                    value={userData?.category}
                    onChange={handleChange}
                  ></Form.Control>
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="email">
                  <Form.Label>
                    <b>
                      Email <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={userData?.email}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="qualification">
                  <Form.Label>
                    <b>
                      Qualification <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="qualification"
                    value={userData?.qualification}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="last exam passing year">
                  <Form.Label>
                    <b>
                      Last Exam Type <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="last exam passing year"
                    value={userData?.lastExamType}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="admissionSession">
                  <Form.Label>
                    <b>
                      Admission Session<span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="admissionSession"
                    value={userData?.admissionSession}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group controlId="passing year">
                  <Form.Label>
                    <b>
                      Passing Year<span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="passing year"
                    value={userData?.passingYear}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4} mb={7}>
                <Form.Group controlId="qualifying exam percentage">
                  <Form.Label>
                    <b>
                      Qualifying Exam Percentage{" "}
                      <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="qualifying exam percentage"
                    value={`${
                      userData?.LastPercentage ||
                      userData?.qualificationPercentage
                    }%`}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4} mb={7}>
                <Form.Group controlId="QualifiedCourse">
                  <Form.Label>
                    <b>
                      Qualified Course Qualification <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="QualifiedCourse"
                    value={
                      userData?.lastPassedSubject || userData.qualification
                    }
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
              </Grid>
            </Grid>
          </Container>

          {/* //////////////////////////////////PROFESSIONAL DETAILS/////////////////////////////////////////////////////////////////////////////// */}
          <Container
            className="shadow p-3  bg-body rounded"
            style={{
              width: "100%",
              backgroundColor: "#e8e5d1",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                backgroundColor: "#1A3636",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <h5
                style={{
                  padding: "10px",
                  margin: 0,
                  marginTop: "-3px",
                  color: "#ffff",
                }}
              >
                Professional Details
              </h5>
            </div>
            <br></br>

            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Form.Group className="mb-3" controlId="Handicapped">
                  <Form.Label>
                    <b>
                      Handicapped <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="Handicapped"
                    value={formData1.Handicapped}
                    onChange={handleChange2}
                  >
                    <option value="">--Select Please--</option>
                    <option value="Yes">YES</option>
                    <option value="No">NO</option>
                  </Form.Select>
                  {errors1.Handicapped && (
                    <p style={{ color: "red" }}>{errors1.Handicapped}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group className="mb-3" controlId="Medium">
                  <Form.Label>
                    <b>
                      Medium <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="Medium"
                    value={formData1.Medium}
                    onChange={handleChange2}
                  >
                    <option value="">--Select Medium--</option>
                    <option value="English">English</option>
                    <option value="Hindi">Hindi</option>
                  </Form.Select>
                  {errors1.Medium && (
                    <p style={{ color: "red" }}>{errors1.Medium}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group className="mb-3" controlId="Nationality">
                  <Form.Label>
                    <b>
                      Nationality <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    aria-label="Default select example"
                    name="Nationality"
                    value={userData?.nationality}
                    onChange={handleChange2}
                  ></Form.Control>
                  {errors1.nationality && (
                    <p style={{ color: "red" }}>{errors1.nationality}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={4}>
                <Form.Group className="mb-3" controlId="Domicile">
                  <Form.Label>
                    <b>
                      Domicile <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    name="Domicile"
                    value={userData?.domicile}
                    onChange={handleChange2}
                  ></Form.Control>
                </Form.Group>
              </Grid>

              <Grid item xs={12} md={4}>
                <Form.Group className="mb-2" controlId="ScholarshipRequired">
                  <Form.Label>
                    <b>
                      Scholarship Required{" "}
                      <span style={{ color: "red" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Select
                    name="ScholarshipRequired"
                    value={formData1.ScholarshipRequired}
                    onChange={handleChange2}
                    required
                    // disabled={
                    //   userData?.category === "gen" ||
                    //   userData?.category === "General" ||
                    //   userData?.category === "GENERAL"
                    // }
                  >
                    <option value="">--Select Please--</option>
                    <option value="Yes">YES</option>
                    <option value="No">NO</option>
                  </Form.Select>
                  {/* {!userData?.category === "gen" &&
                    !userData?.category === "GENERAL" &&
                    !formData1.ScholarshipRequired && (
                    )} */}
                     {/* <p style={{ color: "red" }}>Scholarship is required.</p> */}
                </Form.Group>
              </Grid>

              <Grid item xs={12} md={4}>
                <Form.Group className="mb-3" controlId="FathersOccupation">
                  <Form.Label>
                    <b>
                      Father's Occupation{" "}
                      <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="FathersOccupation"
                    value={formData1.FathersOccupation}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                  {errors1.FathersOccupation && (
                    <p style={{ color: "red" }}>{errors1.FathersOccupation}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="MothersOccupation">
                  <Form.Label>
                    <b>
                      Mother's Occupation{" "}
                      <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="MothersOccupation"
                    value={formData1.MothersOccupation}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                  {errors1.MothersOccupation && (
                    <p style={{ color: "red" }}>{errors1.MothersOccupation}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="FathersIncome">
                  <Form.Label>
                    <b>
                      Father's Income <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="FathersIncome"
                    value={formData1.FathersIncome}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                  {errors1.FathersIncome && (
                    <p style={{ color: "red" }}>{errors1.FathersIncome}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="MothersIncome">
                  <Form.Label>
                    <b>
                      Mother's Income <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="MothersIncome"
                    value={formData1.MothersIncome}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                  {errors1.MothersIncome && (
                    <p style={{ color: "red" }}>{errors1.MothersIncome}</p>
                  )}
                </Form.Group>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="SamagraId">
                  <Form.Label>
                    <b>Samagra Id</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="SamagraId"
                    value={formData1.SamagraId}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                </Form.Group>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="AadharNumber">
                  <Form.Label>
                    <b>Aadhar Number</b>
                    <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="AadharNumber"
                    value={formData1.AadharNumber}
                    onChange={handleChange2}
                    placeholder="0000-0000-0000"
                    autoComplete="off"
                    required
                  />
                  {errors1.AadharNumber && (
                    <p style={{ color: "red" }}>{errors1.AadharNumber}</p>
                  )}
                </Form.Group>
              </Grid>

              <Grid
                item
                xs={12}
                md={4}
                //   mt={2}
              >
                <Form.Group className="mb-3" controlId="ParentMobile">
                  <Form.Label>
                    <b>
                      Parent Mobile <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="ParentMobile"
                    value={formData1.ParentMobile}
                    onChange={handleChange2}
                    autoComplete="off"
                    required
                  />
                  {errors1.ParentMobile && (
                    <p style={{ color: "red" }}>{errors1.ParentMobile}</p>
                  )}
                </Form.Group>
              </Grid>
            </Grid>
          </Container>

          {/* ////////////////////////////////////////////////////ADDRESS DETAILS////////////////////////////////////////////////////////////////////////////////// */}
          <Container
            className="shadow p-3 bg-body rounded"
            style={{
              width: "100%",
              backgroundColor: "red",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                backgroundColor: "#1A3636",
                width: "100%",
                borderRadius: "10px",
              }}
            >
              <h5
                style={{
                  padding: "10px",
                  margin: 0,
                  marginTop: "-3px",
                  color: "#ffff",
                }}
              >
                Address Details
              </h5>
            </div>

            <Grid container spacing={2} mt="5px">
              <Grid item xs={12} md={6}>
                <Form.Group controlId="address1">
                  <Form.Label>
                    <b>
                    Local Address <span style={{ color: "red" }}>*</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address1"
                    value={formData2.address1}
                    onChange={handleChange4}
                    required
                    placeholder="House No, Street No or Road No"
                    autoComplete="off"
                  />
                  {errors.address1 && (
                    <p style={{ color: "red" }}>{errors.address1}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>
                <Form.Group controlId="address2">
                  <Form.Label>
                    <b>
                      Permanent Address <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="address2"
                    value={formData2.address2}
                    onChange={handleChange4}
                    required
                    autoComplete="off"
                    placeholder="Colony, Ward, Village, City"
                  />
                  {errors.address2 && (
                    <p style={{ color: "red" }}>{errors.address2}</p>
                  )}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>
                <Form.Group controlId="country">
                  <Form.Label>
                    <b>
                      Country <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="country"
                    value={formData2.country}
                    onChange={handleChange4}
                    autoComplete="off"
                  >
                    <option value="">--Select Please--</option>
                    <option value="India">INDIA</option>
                    <option value="other">Other</option>
                  </Form.Select>
                  {errors.country && (
                    <p style={{ color: "red" }}>{errors.country}</p>
                  )}
                </Form.Group>
              </Grid>

              {formData2.country === "other" ? (
                <>
                  <Grid item xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="State">
                      <Form.Label>
                        <b>
                          State <span style={{ color: "red" }}> *</span>
                        </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        value={formData2.state}
                        autoComplete="off"
                        onChange={handleChange4}
                      />
                      {errors.state && (
                        <p style={{ color: "red" }}>{errors.state}</p>
                      )}
                    </Form.Group>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Form.Group controlId="district">
                      <Form.Label>
                        <b>
                          District <span style={{ color: "red" }}> *</span>
                        </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="district"
                        value={formData2.district}
                        autoComplete="off"
                        onChange={handleChange4}
                      />
                      {errors.district && (
                        <p style={{ color: "red" }}>{errors.district}</p>
                      )}
                    </Form.Group>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12} md={6}>
                    <Form.Group className="mb-3" controlId="State">
                      <Form.Label>
                        <b>
                          State <span style={{ color: "red" }}> *</span>
                        </b>
                      </Form.Label>
                      <Form.Select
                        className="form-select"
                        id="inputState"
                        name="state"
                        value={formData2.state}
                        autoComplete="off"
                        onChange={handleChange4}
                      >
                        <option value="">Select state</option>
                        {states.map((item, index) => (
                          <option key={index} value={item.isoCode}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.state && (
                        <p style={{ color: "red" }}>{errors.state}</p>
                      )}
                    </Form.Group>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Form.Group controlId="district">
                      <Form.Label>
                        <b>
                          District <span style={{ color: "red" }}> *</span>
                        </b>
                      </Form.Label>
                      <Form.Select
                        className="form-select"
                        name="district"
                        value={formData2.district}
                        onChange={handleChange4}
                        autoComplete="off"
                      >
                        <option value="">Select district</option>
                        {cities.map((city, index) => (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.district && (
                        <p style={{ color: "red" }}>{errors.district}</p>
                      )}
                    </Form.Group>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <Form.Group controlId="pincode">
                  <Form.Label>
                    <b>
                      Pin Code <span style={{ color: "red" }}> *</span>
                    </b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    name="pinCode"
                    value={formData2.pinCode}
                    onChange={handleChange4}
                    autoComplete="off"
                    required
                  />
                  {errors.pinCode && (
                    <p style={{ color: "red" }}> {errors.pinCode}</p>
                  )}
                </Form.Group>
              </Grid>
            </Grid>
          </Container>

          {/* /////////////////////////////////////////////////ACADEMIC DETAILS///////////////////////////////////// */}

          <Table stickyHeader aria-label="sticky table">
            <TableBody>{renderRowsBasedOnQualification()}</TableBody>
          </Table>

          {/* /////////////////////////////////////////////DOCUMENTS & PHOTOS/////////////////////////////////////////// */}
          <div>{renderRowsBasedDocuments()}</div>
          <Button
            style={{
              marginLeft: "77%",
              marginTop: "10px",
              marginBottom: "10px",
              backgroundColor: "#1A3636",
              color: "white",
              width: "15%",
              border: "none",
              borderRadius: "5px",
              fontSize: "20px",
            }}
            type="submit"
            onClick={handleButtonClick}
          >
            {loading ? "Loading..." : "Submit"}
          </Button>
        </div>
      </ThemeProvider>
    </>
  );
};

export default EnrollementForm;



// import React, { useState, useEffect } from "react";
// import { Container, Button, Form, FormControl, Table } from "react-bootstrap";
// import {
//   Grid,
//   Paper,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   createTheme,
//   ThemeProvider,
// } from "@mui/material";
// import { Progress } from "@chakra-ui/react";
// import DatePicker from "react-datepicker";
// import { useNavigate } from "react-router-dom";
// import OnlyHeaders from "./OnlyHeaders";
// import { State, City } from "country-state-city";
// import swal from "sweetalert";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import "react-datepicker/dist/react-datepicker.css";

// const theme = createTheme();

// const EnrollementForm = () => {
//   const [states, setStates] = useState([]);
//   const [cities, setCities] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [isUploading, setIsUploading] = useState({});

//   // ////////////////pagination table states/////////////////////////////
//   const navigate = useNavigate();
//   const [secondFunctionCalled, setSecondFunctionCalled] = useState(false);

//   const userData = JSON.parse(sessionStorage.getItem("currentUser"));
//   const studentId = userData._id;

//   /////////////////////////////////////////PERSONAL FORM API///////////////////////////////////////
//   const [formData, setFormData] = useState({
//     name: "",
//     fatherName: "",
//     motherName: "",
//     dob: "",
//     gender: "",
//     mobile: "",
//     category: "",
//     email: "",
//     qualification: "",
//     lastExamPassingYear: "",
//     passingYear: "",
//     qualifyingExamPercentage: "",
//     QualifiedCourse: "",
//     admissionSession: "",
//     undergraduateCertificate: "",
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//   /////////////////////////////PERSONAL FORM API///////////////////////////////////////////////////////////////
//   ////////////////////////////////PROFESSIONAL FORM API & Validation//////////////////////////////////////////////////

//   const [formData1, setFormData1] = useState({
//     Handicapped: "",
//     Medium: "",
//     Nationality: "",
//     Domicile: "",
//     ScholarshipRequired: "",
//     FathersOccupation: "",
//     MothersOccupation: "",
//     FathersIncome: "",
//     MothersIncome: "",
//     SamagraId: "",
//     AadharNumber: "",
//     ParentMobile: "",
//   });

//   const [errors1, setErrors1] = useState({});

//   const handleChange2 = (e) => {
//     const { name, value } = e.target;
//     setFormData1((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));

//     setErrors1((prevErrors) => ({
//       ...prevErrors,
//       [name]: undefined,
//     }));
//   };

//   // ///////////////////////////PROFESSIONAL FORM ////////////////////////////////////////////////
//   // //////////////////////////////////ADDRESS FORM API & Validation////////////////////////////////////////

//   const [formData2, setFormData2] = useState({
//     address1: "",
//     address2: "",
//     country: "",
//     state: "",
//     district: "",
//     pinCode: "",
//   });

//   const [errors, setErrors] = useState({});

//   const handleChange4 = (e) => {
//     const { name, value } = e.target;

//     if (name === "country") {
//       setFormData2((prevData) => ({
//         ...prevData,
//         [name]: value,
//         state: "",
//         district: "",
//       }));
//       setCities([]);
//     } else if (name === "state") {
//       setFormData2((prevData) => ({
//         ...prevData,
//         [name]: value,
//         district: "",
//       }));
//       try {
//         const stateCities = City.getCitiesOfState("IN", value);
//         setCities(stateCities);
//       } catch (err) {
//         // Handle error if necessary
//       }
//     } else {
//       // Update form data
//       setFormData2((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }

//     // Clear errors
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [name]: undefined,
//     }));
//   };

//   useEffect(() => {
//     const getAllStates = async () => {
//       try {
//         const statesData = await State.getStatesOfCountry("IN");
//         setStates(statesData);
//       } catch (err) {
//         // console.log(err);
//       }
//     };

//     getAllStates();
//   }, []);

//   /////////////////////////////ADDRESS FORM API/////////////////////////////////////
//   ///////////////////////////////////////////STATE API///////////////////////////////////////
//   useEffect(() => {
//     const getAllStates = async () => {
//       try {
//         const statesData = await State.getStatesOfCountry("IN");
//         setStates(statesData);
//       } catch (err) {
//         console.log(err);
//       }
//     };

//     getAllStates();
//   }, []);

//   // //////////////////////Academic logic/////////////////////
//   const [formData3, setFormData3] = useState({
//     tenthSchool: "",
//     tenthPassingYear: "",
//     tenthRollNo: "",
//     tenthBoard: "",
//     tenthMarksObtain: "",
//     tenthMaxMarks: "",
//     tenthPercentage: "",
//     twelfthSchool: "",
//     twelfthPassingYear: "",
//     twelfthRollNo: "",
//     twelfthBoard: "",
//     twelfthMarksObtain: "",
//     twelfthMaxMarks: "",
//     twelfthPercentage: "",
//     graduationCollege: "",
//     graduationPassingYear: "",
//     graduationRollNo: "",
//     graduationUniversity: "",
//     graduationMarksObtain: "",
//     graduationMaxMarks: "",
//     graduationPercentage: "",
//     postGraduationCollege: "",
//     postGraduationPassingYear: "",
//     postGraduationRollNo: "",
//     postGraduationUniversity: "",
//     postGraduationMarksObtain: "",
//     postGraduationMaxMarks: "",
//     postGraduationPercentage: "",
//     otherExam: "",
//     otherSchool: "",
//     otherPassingYear: "",
//     otherRollNo: "",
//     otherBoard: "",
//     otherExamType: "",
//     otherMarksObtain: "",
//     otherMaxMarks: "",
//     otherPercentage: "",
//   });

//   const [selectedQualification, setSelectedQualification] = useState(
//     userData?.qualification
//   );

//   const renderRowsBasedOnQualification = () => {
//     switch (selectedQualification) {
//       case "10th":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>
//                 {/* <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
                         
//                             Exam
                      
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            School/College
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Passing Year
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Roll No
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Name of Board/University
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Total Marks
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Marks Obtain
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                            Percentage
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>

//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">10th</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               name="tenthPercentage"
//                               autoComplete="off"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper> */}

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       {/* Table Header for larger screens */}
//                       <TableHead className="table-header">
//                         <TableRow>
//                           {[
//                             "Exam",
//                             "School/College",
//                             "Passing Year",
//                             "Roll No",
//                             "Name of Board/University",
//                             "Total Marks",
//                             "Marks Obtain",
//                             "Percentage",
//                           ].map((header) => (
//                             <TableCell
//                               key={header}
//                               align="left"
//                               style={{ backgroundColor: "#e6ecf0" }}
//                             >
//                               {header}
//                             </TableCell>
//                           ))}
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">10th</h6>{" "}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               10th School/College
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               10th Passing Year
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Roll No
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Board/University
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Total Marks
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Marks Obtain
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Percentage
//                             </label>
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//                 <style>
//                   {`
//       .form-control-responsive {
//         border-radius: 5px;
//         height: 50px;
//       }
//       .responsive-label {
//         display: none; /* Hide labels by default */
//       }
//       @media (max-width: 480px) {
//         .form-control-responsive {
//           width: 100%;
//            border-color:#507687
//         }
// .h66{
// color:#FF6500;
// font-weight: bold; /* Optional: Style for labels */
// }

      

       
//         .table-header {
//           display: none; /* Hide table header in responsive view */
//         }
//         .responsive-label {
//           display: block; /* Show labels in responsive view */
//           margin-bottom: 5px; /* Spacing between label and input */
//           font-weight: bold; /* Optional: Style for labels */
//         }
         
//         .MuiTableBody-root .MuiTableRow-root {
//           display: flex;
//           flex-direction: column;
//           align-items: flex-start;
//           margin-bottom: 15px;
//         }
//       }
//     `}
//                 </style>
//               </Container>
//             }
//           </>
//         );
//       case "12th":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>
//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       {/* Table Header for larger screens */}
//                       <TableHead className="table-header">
//                         <TableRow>
//                           {[
//                             "Exam",
//                             "School/College",
//                             "Passing Year",
//                             "Roll No",
//                             "Name of Board/University",
//                             "Total Marks",
//                             "Marks Obtain",
//                             "Percentage",
//                           ].map((header) => (
//                             <TableCell
//                               key={header}
//                               align="left"
//                               style={{ backgroundColor: "#e6ecf0" }}
//                             >
//                               {header}
//                             </TableCell>
//                           ))}
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">10th</h6>{" "}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th School/College
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Passing Year
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Roll No
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Board/University
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Total Marks
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Marks Obtain
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Percentage
//                             </label>
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           {/* <TableCell align="left">12th/Diploma</TableCell> */}
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">12th/Diploma</h6>{" "}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma School/College
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Passing Year
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Roll No
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Board/University
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Total Marks
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Marks Obtain
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Percentage
//                             </label>
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={formData3.twelfthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             />
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>

//                   {/* CSS Styles */}
//                   <style>
//                     {`
//         .form-control-responsive {
//           border-radius: 5px;
//           height: 50px;
//         }
//         .responsive-label {
//           display: none; /* Hide labels by default */
//         }
//         @media (max-width: 480px) {
//           .form-control-responsive {
//             width: 100%;
//             border-color:#507687
//           }
  
  
//           .h66{
//           color:#FF6500;
//           font-weight:700}
  
  
//           .tenn{
//           }
//           .table-header {
//             display: none; /* Hide table header in responsive view */
//           }
//           .responsive-label {
//             display: block; /* Show labels in responsive view */
//             margin-bottom: 5px; /* Spacing between label and input */
//             font-weight: bold; /* Optional: Style for labels */
//           }
//           .MuiTableBody-root .MuiTableRow-root {
//             display: flex;
//             flex-direction: column;
//             align-items: flex-start;
//             margin-bottom: 15px;
//           }
//         }
//       `}
//                   </style>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );

//       case "UG":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead className="table-header">
//                         <TableRow>
//                           {[
//                             "Exam",
//                             "School/College",
//                             "Passing Year",
//                             "Roll No",
//                             "Name of Board/University",
//                             "Total Marks",
//                             "Marks Obtain",
//                             "Percentage",
//                           ].map((header) => (
//                             <TableCell
//                               key={header}
//                               align="left"
//                               style={{ backgroundColor: "#e6ecf0" }}
//                             >
//                               {header}
//                             </TableCell>
//                           ))}
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">10th </h6>{" "}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th School/College
//                             </label>

//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Passing Year
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Roll No
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Board/University
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Total Marks
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Marks Obtain
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Percentage
//                             </label>
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">12th/Diploma</h6>
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma School/College
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Passing Year
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Roll No
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Board/University
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Total Marks
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Marks Obtain
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Percentage
//                             </label>
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={formData3.twelfthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">
//                             {" "}
//                             <h6 className="h66">Graduation</h6>
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation School/College
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="graduationCollege"
//                               value={formData3.graduationCollege}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationCollege && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationCollege}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Passing Year{" "}
//                             </label>
//                             <Form.Control
//                               type="number"
//                               name="graduationPassingYear"
//                               value={formData3.graduationPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Roll No.{" "}
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="graduationRollNo"
//                               value={formData3.graduationRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Board/University{" "}
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="graduationUniversity"
//                               value={formData3.graduationUniversity}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationUniversity && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationUniversity}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Total Marks
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="graduationMaxMarks"
//                               value={formData3.graduationMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Marks Obtain
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="graduationMarksObtain"
//                               value={formData3.graduationMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.graduationMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               Graduation Percentage
//                             </label>{" "}
//                             <Form.Control
//                               name="graduationPercentage"
//                               value={
//                                 formData3.graduationMarksObtain &&
//                                 formData3.graduationMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.graduationMarksObtain
//                                       ) /
//                                         parseFloat(
//                                           formData3.graduationMaxMarks
//                                         )) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                   <style>
//                     {`
//           .form-control-responsive {
//             border-radius: 5px;
//             height: 50px;
//           }
//           .responsive-label {
//             display: none; /* Hide labels by default */
//           }
//           @media (max-width: 480px) {
//             .form-control-responsive {
//               width: 100%;
//               border-color:#507687
//             }
    
    
//             .h66{
//             color:#FF6500;
//             font-weight:700}
    
    
//             .tenn{
//             }
//             .table-header {
//               display: none; /* Hide table header in responsive view */
//             }
//             .responsive-label {
//               display: block; /* Show labels in responsive view */
//               margin-bottom: 5px; /* Spacing between label and input */
//               font-weight: bold; /* Optional: Style for labels */
//             }
//             .MuiTableBody-root .MuiTableRow-root {
//               display: flex;
//               flex-direction: column;
//               align-items: flex-start;
//               margin-bottom: 15px;
//             }
//           }
//         `}
//                   </style>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );

//       case "Diploma":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead className="table-header">
//                         <TableRow>
//                           {[
//                             "Exam",
//                             "School/College",
//                             "Passing Year",
//                             "Roll No",
//                             "Name of Board/University",
//                             "Total Marks",
//                             "Marks Obtain",
//                             "Percentage",
//                           ].map((header) => (
//                             <TableCell
//                               key={header}
//                               align="left"
//                               style={{ backgroundColor: "#e6ecf0" }}
//                             >
//                               {header}
//                             </TableCell>
//                           ))}
//                         </TableRow>
//                       </TableHead>

//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">
//                             <h6 className="h66">10th </h6>
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th School/College
//                             </label>
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Passing Year
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Roll No
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Board/University
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Total Marks
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Marks Obtain
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               10th Percentage
//                             </label>{" "}
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">
//                             <h6 className="h66">12th/Diploma</h6>
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma School/College
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Passing Year
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Roll No
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               {" "}
//                               12th/Diploma Board/University
//                             </label>{" "}
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Total Marks
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Marks Obtain
//                             </label>{" "}
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <label className="responsive-label">
//                               12th/Diploma Percentage
//                             </label>{" "}
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={formData3.twelfthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               className="form-control-responsive"
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );

//       case "PG":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Exam
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             School/College
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Passing Year
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Roll No
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Name of Board/University
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Total Marks
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Marks Obtain
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Percentage
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>

//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">10th</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={formData3.tenthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">12th/Diploma</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={formData3.twelfthPercentage}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">Graduation</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationCollege"
//                               value={formData3.graduationCollege}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationCollege && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationCollege}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationPassingYear"
//                               value={formData3.graduationPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationPassingYear}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationRollNo"
//                               value={formData3.graduationRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationRollNo}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationUniversity"
//                               value={formData3.graduationUniversity}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationUniversity && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationUniversity}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMaxMarks"
//                               value={formData3.graduationMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMarksObtain"
//                               value={formData3.graduationMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="graduationPercentage"
//                               value={
//                                 formData3.graduationMarksObtain &&
//                                 formData3.graduationMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.graduationMarksObtain
//                                       ) /
//                                         parseFloat(
//                                           formData3.graduationMaxMarks
//                                         )) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">Post Graduation</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="postGraduationCollege"
//                               value={formData3.postGraduationCollege}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationCollege && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationCollege}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="postGraduationPassingYear"
//                               value={formData3.postGraduationPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationPassingYear}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="postGraduationRollNo"
//                               value={formData3.postGraduationRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationRollNo}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="postGraduationUniversity"
//                               value={formData3.postGraduationUniversity}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationUniversity && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationUniversity}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="postGraduationMaxMarks"
//                               value={formData3.postGraduationMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="postGraduationMarksObtain"
//                               value={formData3.postGraduationMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.postGraduationMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.postGraduationMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               name="postGraduationPercentage"
//                               value={
//                                 formData3.postGraduationMarksObtain &&
//                                 formData3.postGraduationMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.postGraduationMarksObtain
//                                       ) /
//                                         parseFloat(
//                                           formData3.postGraduationMaxMarks
//                                         )) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );

//       case "Graduation":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Exam
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             School/College
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Passing Year
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Roll No
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Name of Board/University
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Total Marks
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Marks Obtain
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             Percentage
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">10th</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={
//                                 formData3.tenthMarksObtain &&
//                                 formData3.tenthMaxMarks
//                                   ? `${(
//                                       (parseFloat(formData3.tenthMarksObtain) /
//                                         parseFloat(formData3.tenthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {/* {errors2.tenthPercentage && (
//                                         <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
//                                       )} */}
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">12th/Diploma</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={
//                                 formData3.twelfthMarksObtain &&
//                                 formData3.twelfthMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.twelfthMarksObtain
//                                       ) /
//                                         parseFloat(formData3.twelfthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">Graduation</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationCollege"
//                               value={formData3.graduationCollege}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationCollege && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationCollege}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationPassingYear"
//                               value={formData3.graduationPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationRollNo"
//                               value={formData3.graduationRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationUniversity"
//                               value={formData3.graduationUniversity}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationUniversity && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationUniversity}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMaxMarks"
//                               value={formData3.graduationMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMarksObtain"
//                               value={formData3.graduationMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="graduationPercentage"
//                               value={
//                                 formData3.graduationMarksObtain &&
//                                 formData3.graduationMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.graduationMarksObtain
//                                       ) /
//                                         parseFloat(
//                                           formData3.graduationMaxMarks
//                                         )) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );
//       case "BSC":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Exam </b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>School/College</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Passing Year</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Roll No</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Name of Board/University</b>
//                             </h5>
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Total Marks</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Marks Obtain</b>
//                             </h5>
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5 className="h55">
//                               <b>Percentage</b>
//                             </h5>
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">10th</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={
//                                 formData3.tenthMarksObtain &&
//                                 formData3.tenthMaxMarks
//                                   ? `${(
//                                       (parseFloat(formData3.tenthMarksObtain) /
//                                         parseFloat(formData3.tenthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {/* {errors2.tenthPercentage && (
//                                             <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
//                                           )} */}
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">12th/Diploma</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={
//                                 formData3.twelfthMarksObtain &&
//                                 formData3.twelfthMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.twelfthMarksObtain
//                                       ) /
//                                         parseFloat(formData3.twelfthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">Graduation</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationCollege"
//                               value={formData3.graduationCollege}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationCollege && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationCollege}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationPassingYear"
//                               value={formData3.graduationPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationRollNo"
//                               value={formData3.graduationRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="graduationUniversity"
//                               value={formData3.graduationUniversity}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationUniversity && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationUniversity}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMaxMarks"
//                               value={formData3.graduationMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="graduationMarksObtain"
//                               value={formData3.graduationMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.graduationMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.graduationMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="graduationPercentage"
//                               value={
//                                 formData3.graduationMarksObtain &&
//                                 formData3.graduationMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.graduationMarksObtain
//                                       ) /
//                                         parseFloat(
//                                           formData3.graduationMaxMarks
//                                         )) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );
//       case "ITI":
//         return (
//           <>
//             {
//               <Container
//                 className="shadow p-3 mb-3 bg-body rounded"
//                 style={{ marginTop: "20px" }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#ffff",
//                     }}
//                   >
//                     Academic Details
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Paper sx={{ width: "100%" }}>
//                   <TableContainer sx={{ maxHeight: "440px" }}>
//                     <Table stickyHeader aria-label="sticky table">
//                       <TableHead>
//                         <TableRow>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Exam </b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>School/College</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Passing Year</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Roll No</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Name of Board/University</b>
//                             </h5>
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Total Marks</b>
//                             </h5>
//                           </TableCell>
//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Marks Obtain</b>
//                             </h5>
//                           </TableCell>

//                           <TableCell
//                             align="left"
//                             style={{ backgroundColor: "#e6ecf0" }}
//                           >
//                             <h5>
//                               <b>Percentage</b>
//                             </h5>
//                           </TableCell>
//                         </TableRow>
//                       </TableHead>
//                       <TableBody>
//                         <TableRow>
//                           <TableCell align="left">10th</TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthSchool"
//                               value={formData3.tenthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             />
//                             {errors2.tenthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthSchool}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthPassingYear"
//                               value={formData3.tenthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthRollNo"
//                               value={formData3.tenthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="tenthBoard"
//                               value={formData3.tenthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMaxMarks"
//                               value={formData3.tenthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="tenthMarksObtain"
//                               value={formData3.tenthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.tenthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.tenthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="tenthPercentage"
//                               value={
//                                 formData3.tenthMarksObtain &&
//                                 formData3.tenthMaxMarks
//                                   ? `${(
//                                       (parseFloat(formData3.tenthMarksObtain) /
//                                         parseFloat(formData3.tenthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {/* {errors2.tenthPercentage && (
//                                   <p style={{ color: "red" }}>{errors2.tenthPercentage}</p>
//                                 )} */}
//                           </TableCell>
//                         </TableRow>

//                         <TableRow>
//                           <TableCell align="left">12th/ITI</TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthSchool"
//                               value={formData3.twelfthSchool}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthSchool && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthSchool}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthPassingYear"
//                               value={formData3.twelfthPassingYear}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthPassingYear && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthPassingYear}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthRollNo"
//                               value={formData3.twelfthRollNo}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "200px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthRollNo && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthRollNo}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="text"
//                               name="twelfthBoard"
//                               value={formData3.twelfthBoard}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "180px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthBoard && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthBoard}
//                               </p>
//                             )}
//                           </TableCell>

//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMaxMarks"
//                               value={formData3.twelfthMaxMarks}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMaxMarks && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMaxMarks}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               type="number"
//                               name="twelfthMarksObtain"
//                               value={formData3.twelfthMarksObtain}
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                             {errors2.twelfthMarksObtain && (
//                               <p style={{ color: "red" }}>
//                                 {errors2.twelfthMarksObtain}
//                               </p>
//                             )}
//                           </TableCell>
//                           <TableCell align="left">
//                             <Form.Control
//                               name="twelfthPercentage"
//                               value={
//                                 formData3.twelfthMarksObtain &&
//                                 formData3.twelfthMaxMarks
//                                   ? `${(
//                                       (parseFloat(
//                                         formData3.twelfthMarksObtain
//                                       ) /
//                                         parseFloat(formData3.twelfthMaxMarks)) *
//                                       100
//                                     ).toFixed(2)}%`
//                                   : ""
//                               }
//                               onChange={handleChange3}
//                               autoComplete="off"
//                               style={{
//                                 borderRadius: "5px",
//                                 width: "80px",
//                                 height: "50px",
//                               }}
//                             ></Form.Control>
//                           </TableCell>
//                         </TableRow>
//                       </TableBody>
//                     </Table>
//                   </TableContainer>
//                 </Paper>
//               </Container>
//             }
//           </>
//         );
//       default:
//         return null;
//     }
//   };

//   const [errors2, setErrors2] = useState({});

//   const calculatePercentage = (obtainedMarks, maxMarks) => {
//     const obtained = parseFloat(obtainedMarks);
//     const max = parseFloat(maxMarks);

//     if (!isNaN(obtained) && !isNaN(max) && max !== 0) {
//       return ((obtained / max) * 100).toFixed(2) + "%";
//     }

//     return "";
//   };

//   const handleChange3 = (e) => {
//     const { name, value } = e.target;

//     setFormData3((prevData) => ({
//       ...prevData,
//       [name]: value,
//       tenthPercentage: calculatePercentage(
//         name === "tenthMarksObtain" ? value : prevData.tenthMarksObtain,
//         name === "tenthMaxMarks" ? value : prevData.tenthMaxMarks
//       ),
//       twelfthPercentage: calculatePercentage(
//         name === "twelfthMarksObtain" ? value : prevData.twelfthMarksObtain,
//         name === "twelfthMaxMarks" ? value : prevData.twelfthMaxMarks
//       ),
//       graduationPercentage: calculatePercentage(
//         name === "graduationMarksObtain"
//           ? value
//           : prevData.graduationMarksObtain,
//         name === "graduationMaxMarks" ? value : prevData.graduationMaxMarks
//       ),
//       postGraduationPercentage: calculatePercentage(
//         name === "postGraduationMarksObtain"
//           ? value
//           : prevData.postGraduationMarksObtain,
//         name === "postGraduationMaxMarks"
//           ? value
//           : prevData.postGraduationMaxMarks
//       ),
//     }));
//   };

//   const handleSubmit4 = (e) => {
//     const errors2 = {};

//     if (selectedQualification === "10th") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }
//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//     }

//     if (selectedQualification === "12th") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }
//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }
//     }

//     if (selectedQualification === "UG") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }
//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }

//       if (!formData3.graduationCollege.trim()) {
//         errors2.graduationCollege = "graduationCollege is required";
//       }
//       if (!formData3.graduationPassingYear.trim()) {
//         errors2.graduationPassingYear = "graduationPassingYear is required";
//       }
//       if (!formData3.graduationRollNo.trim()) {
//         errors2.graduationRollNo = "graduationRollNo is required";
//       }
//       if (!formData3.graduationUniversity.trim()) {
//         errors2.graduationUniversity = "graduationUniversity is required";
//       }
//       if (!formData3.graduationMarksObtain.trim()) {
//         errors2.graduationMarksObtain = "graduationMarksObtain is required";
//       }
//       if (!formData3.graduationMaxMarks.trim()) {
//         errors2.graduationMaxMarks = "graduationMaxMarks is required";
//       }
//       if (!formData3.graduationPercentage.trim()) {
//         errors2.graduationPercentage = "graduationPercentage is required";
//       }
//     }

//     if (selectedQualification === "BSC") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }

//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }

//       if (!formData3.graduationCollege.trim()) {
//         errors2.graduationCollege = "graduationCollege is required";
//       }
//       if (!formData3.graduationPassingYear.trim()) {
//         errors2.graduationPassingYear = "graduationPassingYear is required";
//       }
//       if (!formData3.graduationRollNo.trim()) {
//         errors2.graduationRollNo = "graduationRollNo is required";
//       }
//       if (!formData3.graduationUniversity.trim()) {
//         errors2.graduationUniversity = "graduationUniversity is required";
//       }
//       // if (!formData3.graduationExamType.trim()) {
//       //   errors2.graduationExamType = "graduationExamType is required";
//       // }
//       if (!formData3.graduationMarksObtain.trim()) {
//         errors2.graduationMarksObtain = "graduationMarksObtain is required";
//       }
//       if (!formData3.graduationMaxMarks.trim()) {
//         errors2.graduationMaxMarks = "graduationMaxMarks is required";
//       }
//       if (!formData3.graduationPercentage.trim()) {
//         errors2.graduationPercentage = "graduationPercentage is required";
//       }
//     }

//     if (selectedQualification === "Graduation") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }
//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }

//       if (!formData3.graduationCollege.trim()) {
//         errors2.graduationCollege = "graduationCollege is required";
//       }
//       if (!formData3.graduationPassingYear.trim()) {
//         errors2.graduationPassingYear = "graduationPassingYear is required";
//       }
//       if (!formData3.graduationRollNo.trim()) {
//         errors2.graduationRollNo = "graduationRollNo is required";
//       }
//       if (!formData3.graduationUniversity.trim()) {
//         errors2.graduationUniversity = "graduationUniversity is required";
//       }
//       // if (!formData3.graduationExamType.trim()) {
//       //   errors2.graduationExamType = "graduationExamType is required";
//       // }
//       if (!formData3.graduationMarksObtain.trim()) {
//         errors2.graduationMarksObtain = "graduationMarksObtain is required";
//       }
//       if (!formData3.graduationMaxMarks.trim()) {
//         errors2.graduationMaxMarks = "graduationMaxMarks is required";
//       }
//       if (!formData3.graduationPercentage.trim()) {
//         errors2.graduationPercentage = "graduationPercentage is required";
//       }
//     }

//     if (selectedQualification === "PG") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }

//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }

//       if (!formData3.graduationCollege.trim()) {
//         errors2.graduationCollege = "graduationCollege is required";
//       }
//       if (!formData3.graduationPassingYear.trim()) {
//         errors2.graduationPassingYear = "graduationPassingYear is required";
//       }
//       if (!formData3.graduationRollNo.trim()) {
//         errors2.graduationRollNo = "graduationRollNo is required";
//       }
//       if (!formData3.graduationUniversity.trim()) {
//         errors2.graduationUniversity = "graduationUniversity is required";
//       }
//       // if (!formData3.graduationExamType.trim()) {
//       //   errors2.graduationExamType = "graduationExamType is required";
//       // }
//       if (!formData3.graduationMarksObtain.trim()) {
//         errors2.graduationMarksObtain = "graduationMarksObtain is required";
//       }
//       if (!formData3.graduationMaxMarks.trim()) {
//         errors2.graduationMaxMarks = "graduationMaxMarks is required";
//       }
//       if (!formData3.graduationPercentage.trim()) {
//         errors2.graduationPercentage = "graduationPercentage is required";
//       }
//       if (!formData3.postGraduationCollege.trim()) {
//         errors2.postGraduationCollege = "postGraduationCollege is required";
//       }
//       if (!formData3.postGraduationPassingYear.trim()) {
//         errors2.postGraduationPassingYear =
//           "postGraduationPassingYear is required";
//       }
//       if (!formData3.postGraduationRollNo.trim()) {
//         errors2.postGraduationRollNo = "postGraduationRollNo is required";
//       }
//       if (!formData3.postGraduationUniversity.trim()) {
//         errors2.postGraduationUniversity =
//           "postGraduationUniversity is required";
//       }

//       if (!formData3.postGraduationMarksObtain.trim()) {
//         errors2.postGraduationMarksObtain =
//           "postGraduationMarksObtain is required";
//       }
//       if (!formData3.postGraduationMaxMarks.trim()) {
//         errors2.postGraduationMaxMarks = "postGraduationMaxMarks is required";
//       }
//       if (!formData3.postGraduationPercentage.trim()) {
//         errors2.postGraduationPercentage =
//           "postGraduationPercentage is required";
//       }
//     }

//     if (selectedQualification === "Diploma") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }

//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//     }

//     if (selectedQualification === "ITI") {
//       if (!formData3.tenthSchool.trim()) {
//         errors2.tenthSchool = "tenthSchool is required";
//       }
//       if (!formData3.tenthPassingYear.trim()) {
//         errors2.tenthPassingYear = "tenthPassingYear is required";
//       }
//       if (!formData3.tenthRollNo.trim()) {
//         errors2.tenthRollNo = "tenthRollNo is required";
//       }
//       if (!formData3.tenthMarksObtain.trim()) {
//         errors2.tenthMarksObtain = "tenthMarksObtain is required";
//       }
//       if (!formData3.tenthMaxMarks.trim()) {
//         errors2.tenthMaxMarks = " tenthMaxMarks is required";
//       }
//       if (!formData3.tenthPercentage.trim()) {
//         errors2.tenthPercentage = "tenthPercentage is required";
//       }
//       if (!formData3.tenthBoard.trim()) {
//         errors2.tenthBoard = "tenthBoard is required";
//       }
//       if (!formData3.twelfthSchool.trim()) {
//         errors2.twelfthSchool = "twelthSchool is required";
//       }
//       if (!formData3.twelfthPassingYear.trim()) {
//         errors2.twelfthPassingYear = "twelthPassingYear is required";
//       }
//       if (!formData3.twelfthRollNo.trim()) {
//         errors2.twelfthRollNo = "twelthRollNo is required";
//       }
//       if (!formData3.twelfthBoard.trim()) {
//         errors2.twelfthBoard = "twelthBoard is required";
//       }
//       if (!formData3.twelfthMarksObtain.trim()) {
//         errors2.twelfthMarksObtain = "twelthMarksObtain is required";
//       }
//       if (!formData3.twelfthMaxMarks.trim()) {
//         errors2.twelfthMaxMarks = "twelthMaxMarks is required";
//       }
//       if (!formData3.twelfthPercentage.trim()) {
//         errors2.twelfthPercentage = "twelthPercentage is required";
//       }
//     }

//     // if (Object.keys(errors2).length === 0) {
//     //   return true;
//     // }
//     return Object.keys(errors2).length === 0;
//     // return false;
//   };
//   // /////////////////////////////////////photo & document validation/////////////////////////////////////////////////////////////////
//   const [selectedDocQualification, setSelectedDocQualification] = useState(
//     userData?.qualification
//   );

//   const renderRowsBasedDocuments = () => {
//     switch (selectedDocQualification) {
//       case "10th":
//         return (
//           <>
//             <style>
//               {`
//      @media (max-width: 480px) {
//      .h55{
//      font-size:15px;
//      width:100px;
//      }
// .maxx{
// font-size:10px;
// margin-left:-180px;
// margin-top:30px
// }

//      .app{
//      margin-left:30px;
//      display:flex;
//      flex-direction:row}
//      }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5>
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantPhoto && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div className="app" style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Domicile Certificate </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         />
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );

//       case "12th":
//         return (
//           <>
//             <style>
//               {`
//      @media (max-width: 480px) {
//      .h55{
//      font-size:15px;
//      width:100px;
//      }
// .maxx{
// font-size:10px;
// margin-left:-180px;
// margin-top:30px
// }

//      .app{
//      margin-left:30px;
//      display:flex;
//      flex-direction:row}
//      }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant Photo{" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantPhoto && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {/* {errors7.aadharCard && (
//                     <div style={{ color: 'red' }}>{errors7.aadharCard}</div>
//                   )} */}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Domicile Certificate </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Transfer Certificate </h5>
//                       </div>

//                       {isUploading.transferCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {/* {errors7.transferCertificate && (
//                     <div style={{ color: 'red' }}>{errors7.transferCertificate}</div>
//                   )} */}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         />
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {/* {errors7.migrationCertificate && (
//                     <div style={{ color: 'red' }}>{errors7.migrationCertificate}</div>
//                   )} */}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <input
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></input>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );

//       case "UG":
//         return (
//           <>
//             <style>
//               {`
//      @media (max-width: 480px) {
//      .h55{
//      font-size:15px;
//      width:100px;
//      }
// .maxx{
// font-size:10px;
// margin-left:-100px;
// margin-top:30px
// }

//      .app{
//      margin-left:30px;
//      display:flex;
//      flex-direction:row}
//      }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>

//                       {isUploading.applicantPhoto && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>

//                         {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />} */}
//                     {/* {isUploading ? <Progress size="xs" isIndeterminate /> : ""} */}

//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         {isUploading.applicantSignature && "Loading..."}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 12th Examination
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Domicile Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );
//       case "Diploma":
//         return (
//           <>
//             <style>
//               {`
      
//       @media (max-width: 480px) {
//        .h55{
//        font-size:15px;
//        width:100px;
//        }
//      .maxx{
//         font-size:10px;
//          margin-left:-180px;
//          margin-top:30px
//   }
  
//        .app{
//             margin-left:30px;
//             display:flex;
//             flex-direction:row}
//        }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>

//                       {isUploading.applicantPhoto && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>

//                         {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         {isUploading.applicantSignature && "Loading..."}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}>*</span>{" "}
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Marksheet of 12th Examination</h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Domicile Certificate </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}

//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );
//       case "PG":
//         return (
//           <>
//             <style>
//               {`
      
//       @media (max-width: 480px) {
//        .h55{
//        font-size:15px;
//        width:100px;
//        }
//      .maxx{
//         font-size:10px;
//          margin-left:-180px;
//          margin-top:30px
//   }
  
//        .app{
//             margin-left:30px;
//             display:flex;
//             flex-direction:row}
//        }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>

//                       {isUploading.applicantPhoto && <p>Loading...</p>}
//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>

//                         {/* {isUploading.applicantPhoto && <Progress size="xs" isIndeterminate />}  */}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         {isUploading.applicantSignature && "Loading..."}
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {isUploading ? <Progress size="xs" isIndeterminate /> : ""}
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 12th Examination
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Under Graduate Certificate
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.undergraduateCertificate && (
//                         <p>Loading...</p>
//                       )}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("undergraduateCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.undergraduateCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.undergraduateCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Domicile Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.incomeCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.incomeCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );
//       case "Graduation":
//         return (
//           <>
//             <style>
//               {`
      
//       @media (max-width: 480px) {
//        .h55{
//        font-size:15px;
//        width:100px;
//        }
//      .maxx{
//         font-size:10px;
//          margin-left:-180px;
//          margin-top:30px
//   }
  
//        .app{
//             margin-left:30px;
//             display:flex;
//             flex-direction:row}
//        }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantPhoto && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 12th Examination
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Domicile Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.incomeCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.incomeCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );

//       case "BSC":
//         return (
//           <>
//             <style>
//               {`
      
//       @media (max-width: 480px) {
//        .h55{
//        font-size:15px;
//        width:100px;
//        }
//      .maxx{
//         font-size:10px;
//          margin-left:-180px;
//          margin-top:30px
//   }
  
//        .app{
//             margin-left:30px;
//             display:flex;
//             flex-direction:row}
//        }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantPhoto && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 12th Examination
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Domicile Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.incomeCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.incomeCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );

//       case "ITI":
//         return (
//           <>
//             <style>
//               {`
      
//       @media (max-width: 480px) {
//        .h55{
//        font-size:15px;
//        width:100px;
//        }
//      .maxx{
//         font-size:10px;
//          margin-left:-180px;
//          margin-top:30px
//   }
  
//        .app{
//             margin-left:30px;
//             display:flex;
//             flex-direction:row}
//        }`}
//             </style>
//             {
//               <Container
//                 className="shadow p-3 bg-body rounded"
//                 style={{
//                   width: "100%",
//                   backgroundColor: "#e8e5d1",
//                   marginTop: "20px",
//                 }}
//               >
//                 <div
//                   style={{
//                     backgroundColor: "#1A3636",
//                     width: "100%",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   <h5
//                     style={{
//                       padding: "10px",
//                       margin: 0,
//                       marginTop: "-3px",
//                       color: "#fff",
//                     }}
//                   >
//                     Documents & Signature
//                   </h5>
//                 </div>
//                 <br></br>

//                 <Grid container>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Photo{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantPhoto && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantPhoto", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantPhoto && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantPhoto}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Applicant's Signature{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.applicantSignature && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("applicantSignature", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.applicantSignature && (
//                       <div style={{ color: "red" }}>
//                         {errors7.applicantSignature}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Aadhar Card <span style={{ color: "red" }}>*</span>
//                         </h5>
//                       </div>
//                       {isUploading.aadharCard && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("aadharCard", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.aadharCard && (
//                       <div style={{ color: "red" }}>{errors7.aadharCard}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Marksheet of 10th Examination{" "}
//                           <span style={{ color: "red" }}> *</span>
//                         </h5>
//                       </div>
//                       {isUploading.marksheet10th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet10th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet10th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet10th}
//                       </div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Marksheet of ITI Examination </h5>
//                       </div>
//                       {isUploading.ItImarksheet && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("ItImarksheet", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.ItImarksheet && (
//                       <div style={{ color: "red" }}>{errors7.ItImarksheet}</div>
//                     )}
//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Marksheet of 12th Examination</h5>
//                       </div>
//                       {isUploading.marksheet12th && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) => handleFileChange("marksheet12th", e)}
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.marksheet12th && (
//                       <div style={{ color: "red" }}>
//                         {errors7.marksheet12th}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Domicile Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.domicileCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("domicileCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.domicileCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.domicileCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>
//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Transfer Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.transferCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("transferCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.transferCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.transferCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Income Certificate</h5>
//                       </div>
//                       {isUploading.incomeCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("incomeCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.incomeCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.incomeCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">
//                           Migration Certificate{" "}
//                           {/* <span style={{ color: "red" }}>*</span> */}
//                         </h5>
//                       </div>
//                       {isUploading.migrationCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("migrationCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 style={{ backgroundColor: " #ffe6e6" }}>
//                           Maximum 1000KB
//                         </h6>
//                       </div>
//                     </div>
//                     {errors7.migrationCertificate && (
//                       <div style={{ color: "red" }}>
//                         {errors7.migrationCertificate}
//                       </div>
//                     )}

//                     <hr></hr>
//                   </Grid>

//                   <Grid item xs={12}>
//                     <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                       }}
//                     >
//                       <div style={{ display: "flex" }}>
//                         <h5 className="h55">Other Certificate</h5>
//                       </div>
//                       {isUploading.otherCertificate && <p>Loading...</p>}

//                       <div style={{ display: "flex" }}>
//                         <Form.Control
//                           type="file"
//                           placeholder="choose"
//                           onChange={(e) =>
//                             handleFileChange("otherCertificate", e)
//                           }
//                         ></Form.Control>
//                         <h6 className="maxx" style={{ color: "#347928" }}>
//                           Maximum 1 MB
//                         </h6>
//                       </div>
//                     </div>

//                     <hr></hr>
//                   </Grid>
//                 </Grid>
//               </Container>
//             }
//           </>
//         );

//       default:
//         return null;
//     }
//   };

//   const [fileUrls, setFileUrls] = useState({
//     applicantPhoto: "",
//     applicantSignature: "",
//     aadharCard: "",
//     marksheet10th: "",
//     diplomaMarksheet: "",
//     marksheet12th: "",
//     ItImarksheet: "",
//     undergraduateCertificate: "",
//     postgraduateCertificate: "",
//     domicileCertificate: "",
//     transferCertificate: "",
//     incomeCertificate: "",
//     migrationCertificate: "",
//     otherCertificate: "",
//   });

//   const [errors7, setErrors7] = useState({
//     applicantPhoto: "",
//     applicantSignature: "",
//     aadharCard: "",
//     marksheet10th: "",
//     marksheet12th: "",
//     ItImarksheet: "",
//     // diplomaMarksheet: "",
//     undergraduateCertificate: "",
//     postgraduateCertificate: "",
//     domicileCertificate: "",
//     transferCertificate: "",
//     incomeCertificate: "",
//     migrationCertificate: "",
//     otherCertificate: "",
//   });

//   const handleFileChange = async (fieldName, e) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     const fileSizeKB = file.size / 1024;
//     if (fileSizeKB > 1000) {
//       setErrors7((prevErrors) => ({
//         ...prevErrors,
//         [fieldName]: "File size exceeds 1000KB",
//       }));

//       swal("Error", "File size exceeds 1000KB", "error");
//       return;
//     }

//     try {
//       const allowedTypes = ["image/jpg", "image/jpeg", "image/png"];
//       if (!allowedTypes.includes(file.type)) {
//         setErrors7((prevErrors) => ({
//           ...prevErrors,
//           [fieldName]: "Please choose a JPEG, JPG, or PNG file.",
//         }));
//         return;
//       }

//       const formData4 = new FormData();
//       formData4.append("file", file);
//       formData4.append("upload_preset", "upload");

//       setIsUploading((prevUploading) => ({
//         ...prevUploading,
//         [fieldName]: true,
//       }));

//       const cloudinaryResponse = await fetch(
//         `https://api.cloudinary.com/v1_1/dszawxz7y/image/upload`,
//         {
//           method: "POST",
//           body: formData4,
//         }
//       );

//       const cloudinaryData = await cloudinaryResponse.json();
//       const fileUrl = cloudinaryData.secure_url;

//       setFileUrls((prevFileUrls) => ({
//         ...prevFileUrls,
//         [fieldName]: fileUrl,
//       }));

//       setErrors7((prevErrors) => ({
//         ...prevErrors,
//         [fieldName]: "",
//       }));
//     } catch (error) {
//       console.error("Error uploading file:", error);
//     } finally {
//       setIsUploading((prevUploading) => ({
//         ...prevUploading,
//         [fieldName]: false,
//       }));
//     }
//   };

//   const handleSubmit5 = () => {
//     const selectedQualification = userData?.qualification;

//     const requiredFields = getRequiredFieldsByQualification(
//       selectedQualification
//     );

//     setErrors7({});
//     let isFormValid = true;

//     requiredFields.forEach((field) => {
//       if (!fileUrls[field]) {
//         setErrors7((prevErrors) => ({
//           ...prevErrors,
//           [field]: "This field is required.",
//         }));
//         isFormValid = false;
//       }
//     });
//     return isFormValid;

//     // if (Object.keys(errors7).length === 0) {
//     //   return true;
//     // } else {
//     //   return false;
//     // }
//     // });
//   };

//   const getRequiredFieldsByQualification = (qualification) => {
//     const fieldsByQualification = {
//       "10th": [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         // "marksheet10th",
//       ],
//       "12th": [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//         // "domicileCertificate",
//         // "transferCertificate",
//         // "migrationCertificate",
//       ],
//       UG: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//         "marksheet12th",
//         // "domicileCertificate",
//         // "transferCertificate",
//         // "migrationCertificate",
//       ],
//       BSC: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//         "marksheet12th",
//       ],
//       PG: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//         "marksheet12th",
//         "undergraduateCertificate",
//       ],
//       Diploma: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//       ],
//       ITI: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//       ],
//       Graduation: [
//         "applicantPhoto",
//         "applicantSignature",
//         "aadharCard",
//         "marksheet10th",
//         "marksheet12th",
//         // "undergraduateCertificate",
//         // "domicileCertificate",
//         // "transferCertificate",
//         // "migrationCertificate",
//         // "otherCertificate",
//       ],
//     };
//     return fieldsByQualification[qualification] || [];
//   };

//   ////////////////////////Document end////////////////////////////////////////////////////////
//   const CurrentUser = JSON.parse(sessionStorage.getItem("currentUser"));
//   const studentType = CurrentUser.StudentType;
//   const isEnrolled = CurrentUser.isEnrolled;
//   const isPaid = CurrentUser.isPaid;

//   const updateStudentData = async () => {
//     try {
//       setLoading(true);
//       const apiUrl = "https://sssutms.ac.in/apitest/updatestudent";

//       const response = await fetch(apiUrl, {
//         method: "PUT",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           studentId,
//           personalFormData: formData,
//           professionalFormData: formData1,
//           addressFormData: formData2,
//           educationFormData: formData3,
//           fileUrls,
//         }),
//       });

//       if (!response.ok) {
//         const errorData = await response.json();
//         swal({
//           icon: "error",
//           title: "Error",
//           text: `Error: ${errorData.errors.join(", ")}`,
//         });
//         return;
//       }
//       const data = await response.json();
//       const updatedStudent = data.updatedStudent;
//       sessionStorage.setItem("currentUser", JSON.stringify(updatedStudent));
//       if (
//         studentType === "EPravesh" &&
//         isPaid === true &&
//         isEnrolled === true
//       ) {
//         swal({
//           title: "Success",
//           text: "Enrolled Successfully",
//           icon: "success",
//           buttons: "OK",
//         }).then(() => {
//           navigate("/Epravesh/Student/EnrollementFee");
//         });
//       } else {
//         swal({
//           title: "Success",
//           text: "Enrolled Successfully!",
//           icon: "success",
//           buttons: "OK",
//         }).then(() => {
//           navigate("/Epravesh/Student/EnrollementFee");
//         });
//       }
//     } catch (error) {
//       swal({
//         icon: "error",
//         title: "Error",
//         text: `Something went Wrong!`,
//       });
//     } finally {
//       setLoading(false);
//     }
//     setSecondFunctionCalled(true);
//   };

//   const handleAllValidations = () => {
//     const isValid4 = handleSubmit4();

//     return isValid4;
//   };
//   const handleButtonClick = () => {
//     const isValid4 = handleSubmit4();
//     const isValid = handleSubmit5();

//     if (isValid4 && isValid) {
//       updateStudentData();
//     } else {
//       swal({
//         icon: "error",
//         title: "Error",
//         text: "Please fill out all required fields",
//       });
//     }
//   };
//   const handleBackClick = () => {
//     navigate("/studentalldetail"); // Navigate to the specified path
//   };

//   ///////////////////////////////////////////////////////////////////////////////////////////////////////////
//   return (
//     <>
//       <style>
//         {`
//    h1 {
//   position: relative;
//   padding: 0;
//   margin: 0;
//   font-family: "Raleway", sans-serif;
//   font-weight: 300;
//   font-size: 25px;
//   color: #080808;
//   -webkit-transition: all 0.4s ease 0s;
//   -o-transition: all 0.4s ease 0s;
//   transition: all 0.4s ease 0s;
// }

// h1 span {
//   display: block;
//   font-size: 0.5em;
//   line-height: 1.3;
// }
// h1 em {
//   font-style: normal;
//   font-weight: 600;
// }

// /* === HEADING STYLE #1 === */
// .one h1 {
//   text-align: center;
//   text-transform: uppercase;
//   padding-bottom: 5px;
//   margin-top:100px
// }
// .one h1:before {
//   width: 28px;
//   height: 5px;
//   display: block;
//   content: "";
//   position: absolute;
//   bottom: 3px;
//   left: 50%;
//   margin-left: -14px;
//   background-color: #b80000;
// }
// .one h1:after {
//   width: 100px;
//   height: 1px;
//   display: block;
//   content: "";
//   position: relative;
//   margin-top: 25px;
//   left: 50%;
//   margin-left: -50px;
//   background-color: #b80000;
// }
// `}
//       </style>

//       <OnlyHeaders />

//       <ThemeProvider theme={theme}>
//         <div>
//           <div className="disp">
//             <Button onClick={handleBackClick} className="oon">
//               <ArrowBackIcon />
//               <span className="baack">Back</span>
//             </Button>
//             <div class="one">
//               <h1>Enrollment Form</h1>
//             </div>
//           </div>
//           <Container
//             className="shadow p-3 bg-body rounded"
//             style={{
//               width: "100%",
//               backgroundColor: "#1A3636",
//               marginTop: "10px",
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: "#1A3636",
//                 width: "100%",
//                 borderRadius: "10px",
//               }}
//             >
//               <h5
//                 style={{
//                   padding: "10px",
//                   margin: 0,
//                   marginTop: "-3px",
//                   color: "#ffff",
//                 }}
//               >
//                 Personal Details
//               </h5>
//             </div>

//             <Grid container spacing={2} mt="5px">
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="name">
//                   <Form.Label>
//                     <b>
//                       Name <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="name"
//                     value={userData?.name}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>

//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="father's name">
//                   <Form.Label>
//                     <b>
//                       Father's Name <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherName"
//                     value={userData?.fathersname}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="mother's name">
//                   <Form.Label>
//                     <b>
//                       Mother's Name <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherName"
//                     value={userData?.mothersname}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="dateOfBirth">
//                   <Form.Label>
//                     <b>
//                       Date of Birth <span style={{ color: "red" }}>*</span>
//                     </b>
//                     <div style={{ display: "flex", flexDirection: "column" }}>
//                       <DatePicker
//                         selected={
//                           userData?.dob ? new Date(userData?.dob) : null
//                         }
//                         readOnly
//                         dateFormat="dd/MM/yyyy"
//                         customInput={
//                           <FormControl
//                             style={{ width: "175%", marginTop: "6px" }}
//                           />
//                         }
//                       />
//                     </div>
//                   </Form.Label>
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="gender">
//                   <Form.Label>
//                     <b>
//                       Gender <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="gender"
//                     value={userData?.gender}
//                     onChange={handleChange}
//                   ></Form.Control>
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="mobile">
//                   <Form.Label>
//                     <b>
//                       Mobile <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="mobile"
//                     value={userData?.mobile}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="category">
//                   <Form.Label>
//                     <b>
//                       Category <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="category"
//                     value={userData?.category}
//                     onChange={handleChange}
//                   ></Form.Control>
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="email">
//                   <Form.Label>
//                     <b>
//                       Email <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="email"
//                     name="email"
//                     value={userData?.email}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="qualification">
//                   <Form.Label>
//                     <b>
//                       Qualification <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="qualification"
//                     value={userData?.qualification}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="last exam passing year">
//                   <Form.Label>
//                     <b>
//                       Last Exam Type <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="last exam passing year"
//                     value={userData?.lastExamType}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="admissionSession">
//                   <Form.Label>
//                     <b>
//                       Admission Session<span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="admissionSession"
//                     value={userData?.admissionSession}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group controlId="passing year">
//                   <Form.Label>
//                     <b>
//                       Passing Year<span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="passing year"
//                     value={userData?.passingYear}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4} mb={7}>
//                 <Form.Group controlId="qualifying exam percentage">
//                   <Form.Label>
//                     <b>
//                       Qualifying Exam Percentage{" "}
//                       <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="qualifying exam percentage"
//                     value={`${
//                       userData?.LastPercentage ||
//                       userData?.qualificationPercentage
//                     }%`}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4} mb={7}>
//                 <Form.Group controlId="QualifiedCourse">
//                   <Form.Label>
//                     <b>
//                       Qualified Course <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="QualifiedCourse"
//                     value={
//                       userData?.lastPassedSubject || userData.qualification
//                     }
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Grid>
//             </Grid>
//           </Container>

//           {/* //////////////////////////////////PROFESSIONAL DETAILS/////////////////////////////////////////////////////////////////////////////// */}
//           <Container
//             className="shadow p-3  bg-body rounded"
//             style={{
//               width: "100%",
//               backgroundColor: "#e8e5d1",
//               marginTop: "20px",
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: "#1A3636",
//                 width: "100%",
//                 borderRadius: "10px",
//               }}
//             >
//               <h5
//                 style={{
//                   padding: "10px",
//                   margin: 0,
//                   marginTop: "-3px",
//                   color: "#ffff",
//                 }}
//               >
//                 Professional Details
//               </h5>
//             </div>
//             <br></br>

//             <Grid container spacing={3}>
//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-3" controlId="Handicapped">
//                   <Form.Label>
//                     <b>
//                       Handicapped <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Select
//                     aria-label="Default select example"
//                     name="Handicapped"
//                     value={formData1.Handicapped}
//                     onChange={handleChange2}
//                   >
//                     <option value="">--Select Please--</option>
//                     <option value="Yes">YES</option>
//                     <option value="No">NO</option>
//                   </Form.Select>
//                   {errors1.Handicapped && (
//                     <p style={{ color: "red" }}>{errors1.Handicapped}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-3" controlId="Medium">
//                   <Form.Label>
//                     <b>
//                       Medium <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Select
//                     aria-label="Default select example"
//                     name="Medium"
//                     value={formData1.Medium}
//                     onChange={handleChange2}
//                   >
//                     <option value="">--Select Medium--</option>
//                     <option value="English">English</option>
//                     <option value="Hindi">Hindi</option>
//                   </Form.Select>
//                   {errors1.Medium && (
//                     <p style={{ color: "red" }}>{errors1.Medium}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-3" controlId="Nationality">
//                   <Form.Label>
//                     <b>
//                       Nationality <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     aria-label="Default select example"
//                     name="Nationality"
//                     value={userData?.nationality}
//                     onChange={handleChange2}
//                   ></Form.Control>
//                   {errors1.nationality && (
//                     <p style={{ color: "red" }}>{errors1.nationality}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-3" controlId="Domicile">
//                   <Form.Label>
//                     <b>
//                       Domicile <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     name="Domicile"
//                     value={userData?.domicile}
//                     onChange={handleChange2}
//                   ></Form.Control>
//                 </Form.Group>
//               </Grid>

//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-2" controlId="ScholarshipRequired">
//                   <Form.Label>
//                     <b>
//                       Scholarship Required{" "}
//                       <span style={{ color: "red" }}>*</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Select
//                     name="ScholarshipRequired"
//                     value={formData1.ScholarshipRequired}
//                     onChange={handleChange2}
//                     disabled={
//                       userData?.category === "gen" ||
//                       userData?.category === "General" ||
//                       userData?.category === "GENERAL"
//                     }
//                   >
//                     <option value="">--Select Please--</option>
//                     <option value="Yes">YES</option>
//                     <option value="No">NO</option>
//                   </Form.Select>
//                   {!userData?.category === "gen" &&
//                     !userData?.category === "GENERAL" &&
//                     !formData1.ScholarshipRequired && (
//                       <p style={{ color: "red" }}>Scholarship is required.</p>
//                     )}
//                 </Form.Group>
//               </Grid>

//               <Grid item xs={12} md={4}>
//                 <Form.Group className="mb-3" controlId="FathersOccupation">
//                   <Form.Label>
//                     <b>
//                       Father's Occupation{" "}
//                       <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="FathersOccupation"
//                     value={formData1.FathersOccupation}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.FathersOccupation && (
//                     <p style={{ color: "red" }}>{errors1.FathersOccupation}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="MothersOccupation">
//                   <Form.Label>
//                     <b>
//                       Mother's Occupation{" "}
//                       <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="MothersOccupation"
//                     value={formData1.MothersOccupation}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.MothersOccupation && (
//                     <p style={{ color: "red" }}>{errors1.MothersOccupation}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="FathersIncome">
//                   <Form.Label>
//                     <b>
//                       Father's Income <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="FathersIncome"
//                     value={formData1.FathersIncome}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.FathersIncome && (
//                     <p style={{ color: "red" }}>{errors1.FathersIncome}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="MothersIncome">
//                   <Form.Label>
//                     <b>
//                       Mother's Income <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="MothersIncome"
//                     value={formData1.MothersIncome}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.MothersIncome && (
//                     <p style={{ color: "red" }}>{errors1.MothersIncome}</p>
//                   )}
//                 </Form.Group>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="SamagraId">
//                   <Form.Label>
//                     <b>Samagra Id</b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="SamagraId"
//                     value={formData1.SamagraId}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                 </Form.Group>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="AadharNumber">
//                   <Form.Label>
//                     <b>Aadhar Number</b>
//                     <span style={{ color: "red" }}> *</span>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="AadharNumber"
//                     value={formData1.AadharNumber}
//                     onChange={handleChange2}
//                     placeholder="0000-0000-0000"
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.AadharNumber && (
//                     <p style={{ color: "red" }}>{errors1.AadharNumber}</p>
//                   )}
//                 </Form.Group>
//               </Grid>

//               <Grid
//                 item
//                 xs={12}
//                 md={4}
//                 //   mt={2}
//               >
//                 <Form.Group className="mb-3" controlId="ParentMobile">
//                   <Form.Label>
//                     <b>
//                       Parent Mobile <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="ParentMobile"
//                     value={formData1.ParentMobile}
//                     onChange={handleChange2}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors1.ParentMobile && (
//                     <p style={{ color: "red" }}>{errors1.ParentMobile}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//             </Grid>
//           </Container>

//           {/* ////////////////////////////////////////////////////ADDRESS DETAILS////////////////////////////////////////////////////////////////////////////////// */}
//           <Container
//             className="shadow p-3 bg-body rounded"
//             style={{
//               width: "100%",
//               backgroundColor: "red",
//               marginTop: "20px",
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: "#1A3636",
//                 width: "100%",
//                 borderRadius: "10px",
//               }}
//             >
//               <h5
//                 style={{
//                   padding: "10px",
//                   margin: 0,
//                   marginTop: "-3px",
//                   color: "#ffff",
//                 }}
//               >
//                 Address Details
//               </h5>
//             </div>

//             <Grid container spacing={2} mt="5px">
//               <Grid item xs={12} md={6}>
//                 <Form.Group controlId="address1">
//                   <Form.Label>
//                     <b>
//                       Address1 <span style={{ color: "red" }}>*</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="address1"
//                     value={formData2.address1}
//                     onChange={handleChange4}
//                     required
//                     placeholder="House No, Street No or Road No"
//                     autoComplete="off"
//                   />
//                   {errors.address1 && (
//                     <p style={{ color: "red" }}>{errors.address1}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Form.Group controlId="address2">
//                   <Form.Label>
//                     <b>
//                       Address2 <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="address2"
//                     value={formData2.address2}
//                     onChange={handleChange4}
//                     required
//                     autoComplete="off"
//                     placeholder="Colony, Ward, Village, City"
//                   />
//                   {errors.address2 && (
//                     <p style={{ color: "red" }}>{errors.address2}</p>
//                   )}
//                 </Form.Group>
//               </Grid>
//               <Grid item xs={12} md={6}>
//                 <Form.Group controlId="country">
//                   <Form.Label>
//                     <b>
//                       Country <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Select
//                     aria-label="Default select example"
//                     name="country"
//                     value={formData2.country}
//                     onChange={handleChange4}
//                     autoComplete="off"
//                   >
//                     <option value="">--Select Please--</option>
//                     <option value="India">INDIA</option>
//                     <option value="other">Other</option>
//                   </Form.Select>
//                   {errors.country && (
//                     <p style={{ color: "red" }}>{errors.country}</p>
//                   )}
//                 </Form.Group>
//               </Grid>

//               {formData2.country === "other" ? (
//                 <>
//                   <Grid item xs={12} md={6}>
//                     <Form.Group className="mb-3" controlId="State">
//                       <Form.Label>
//                         <b>
//                           State <span style={{ color: "red" }}> *</span>
//                         </b>
//                       </Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="state"
//                         value={formData2.state}
//                         autoComplete="off"
//                         onChange={handleChange4}
//                       />
//                       {errors.state && (
//                         <p style={{ color: "red" }}>{errors.state}</p>
//                       )}
//                     </Form.Group>
//                   </Grid>
//                   <Grid item xs={12} md={6}>
//                     <Form.Group controlId="district">
//                       <Form.Label>
//                         <b>
//                           District <span style={{ color: "red" }}> *</span>
//                         </b>
//                       </Form.Label>
//                       <Form.Control
//                         type="text"
//                         name="district"
//                         value={formData2.district}
//                         autoComplete="off"
//                         onChange={handleChange4}
//                       />
//                       {errors.district && (
//                         <p style={{ color: "red" }}>{errors.district}</p>
//                       )}
//                     </Form.Group>
//                   </Grid>
//                 </>
//               ) : (
//                 <>
//                   <Grid item xs={12} md={6}>
//                     <Form.Group className="mb-3" controlId="State">
//                       <Form.Label>
//                         <b>
//                           State <span style={{ color: "red" }}> *</span>
//                         </b>
//                       </Form.Label>
//                       <Form.Select
//                         className="form-select"
//                         id="inputState"
//                         name="state"
//                         value={formData2.state}
//                         autoComplete="off"
//                         onChange={handleChange4}
//                       >
//                         <option value="">Select state</option>
//                         {states.map((item, index) => (
//                           <option key={index} value={item.isoCode}>
//                             {item.name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                       {errors.state && (
//                         <p style={{ color: "red" }}>{errors.state}</p>
//                       )}
//                     </Form.Group>
//                   </Grid>
//                   <Grid item xs={12} md={6}>
//                     <Form.Group controlId="district">
//                       <Form.Label>
//                         <b>
//                           District <span style={{ color: "red" }}> *</span>
//                         </b>
//                       </Form.Label>
//                       <Form.Select
//                         className="form-select"
//                         name="district"
//                         value={formData2.district}
//                         onChange={handleChange4}
//                         autoComplete="off"
//                       >
//                         <option value="">Select district</option>
//                         {cities.map((city, index) => (
//                           <option key={index} value={city.name}>
//                             {city.name}
//                           </option>
//                         ))}
//                       </Form.Select>
//                       {errors.district && (
//                         <p style={{ color: "red" }}>{errors.district}</p>
//                       )}
//                     </Form.Group>
//                   </Grid>
//                 </>
//               )}

//               <Grid item xs={12} md={6}>
//                 <Form.Group controlId="pincode">
//                   <Form.Label>
//                     <b>
//                       Pin Code <span style={{ color: "red" }}> *</span>
//                     </b>
//                   </Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="pinCode"
//                     value={formData2.pinCode}
//                     onChange={handleChange4}
//                     autoComplete="off"
//                     required
//                   />
//                   {errors.pinCode && (
//                     <p style={{ color: "red" }}> {errors.pinCode}</p>
//                   )}
//                   {/*
//                  <Form.Control.Feedback type="invalid">{errors.pincode}</Form.Control.Feedback> */}
//                 </Form.Group>
//               </Grid>
//             </Grid>
//           </Container>

//           {/* /////////////////////////////////////////////////ACADEMIC DETAILS///////////////////////////////////// */}

//           <Table stickyHeader aria-label="sticky table">
//             <TableBody>{renderRowsBasedOnQualification()}</TableBody>
//           </Table>

//           {/* /////////////////////////////////////////////DOCUMENTS & PHOTOS/////////////////////////////////////////// */}
//           <div>{renderRowsBasedDocuments()}</div>
//           <style>
//             {`
//              .bu{
//               margin-left: 40%;
//               margin-top: 10px;
//               margin-bottom: 10px;
//               padding:5px;
//               background-color: #1A3636;
//                height:50px;
//               color: white;
//               width: 15%;
//               border: none;
//               border-radius: 5px;
//               font-size: 20px;
//             }
              


//               @media (max-width: 480px) {
//                .bu{
//               margin-left: 25%;
//               margin-top: 10px;
//               margin-bottom: 10px;
//               background-color: #1A3636;

//               color: white;
//               width: 40%;
//               border: none;
//               border-radius: 5px;
//               font-size: 20px;
//             }
//               }
//             `}
//           </style>
//           <Button className="bu" type="submit" onClick={handleButtonClick}>
//             {loading ? "Loading..." : "Submit"}
//           </Button>
//         </div>
//       </ThemeProvider>
//     </>
//   );
// };

// export default EnrollementForm;