import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
 import ncc_image from '../../images/ncc_image.jfif'
 import { Link } from "react-router-dom";



function Activity() {
  return (
    <>
      <Nav />
      <style>
        {
        `
    
    
    .section_all {
        position: relative;
        padding-top: 20px;
        margin-left:-100px;
        padding-bottom: 80px;
        min-height: 100vh;
    }
   
    
    .about_icon i {
        font-size: 22px;
        height: 65px;
        width: 65px;
        line-height: 65px;
        display: inline-block;
        background: #fff;
        border-radius: 35px;
        color: #00bd2a;
        box-shadow: 0 8px 20px -2px rgba(158, 152, 153, 0.5);
    }
    
   
    .about_icon span {
        position: relative;
        top: -10px;
    }
    
    .about_content_box_all {
        padding: 28px;
    }


     table {
            width: 100%;
            margin-top: 10px;
              font-family: 'poppins',sansSerif;
            border-collapse: collapse;
          }

          table th, table td {
            padding: 5px;
            text-align: left;
          }

          table th {
            background-color: #f4f4f4;
            color: #333;
            font-weight: bold;
          }

          table td {
            color: black;
          }
h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; margin-left:-30px;
}


.lli{
font-size:16px; 
margin-left:50px;
margin-top:10px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}
.fa {
    display: inline-block;
    font: normal normal normal 14px / 1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



 @media (max-width: 480px) {
   .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>
      <section className="section_all " id="about" >
        <div className="container">
        <div class="two">
  <h1>  Activity
  
  </h1>
</div>
         <br></br><br></br>
       
         <div className='row' >
            <div className="col-lg-9">
            
                              
            <h5 style={{textAlign:'center',marginTop:'-30px',color:'#CB6040'}}>National Cadet Corps (NCC)</h5>        
            <br></br>
            <table style={{marginTop:'20px'}}>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Date</th>
                    <th>Place</th>
                    <th>Type of Camp</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>14/01/2020-23/01/2020</td>
                    <td>BIRTS, Bhopal</td>
                    <td>PRE-TRDC Camp</td>
                  </tr>
                  <tr>




                    <td>2</td>
                    <td>14/06/2018-23/06/2018</td>
                    <td>Bhopal</td>
                    <td>Combined annual training Camp (CATC)</td>
                  </tr>

                  <tr>
                    <td>3</td>
                    <td>14/06/2019-2306/2019



</td>
                    <td>Bansal, Bhopal</td>
                    <td>Combined annual training Camp (Yoga)</td>
                  </tr>
                </tbody>
              </table>








         <br></br><br></br>
<br></br>
<br></br>

              <h5 style={{textAlign:'center',color:'#CB6040'}}>NCC Activity:</h5>        
<div style={{display:'flex'}}>
              <table style={{marginTop:'20px'}}>
    <thead>
      <tr>
        <th>S.No.</th>
        <th>Date</th>
        <th>Acitivity</th>
    
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>25/08/2018</td>
      <td>Plantation</td>
      </tr>




      <tr>
        <td>2</td>
        <td>01/12/2019</td>
      <td>World AIDS Day Celebration</td>
      </tr>
   


    

     
    </tbody>
  </table>


 </div>
</div>






           
              <div className="col-lg-3">

            <ul className='list-icon' >
            <li className='lli'>
                <Link to ='/mou-collaboration'>
                <i class="fa fa-adjust" aria-hidden="true"></i>NCC MOU</Link></li>
                <li className='lli'>
                <Link to ='/activity'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Activity</Link></li>
             
            </ul>

<br></br><br></br><br></br>

            <img src={ncc_image} style={{height:'300px',width:'400px'}}></img>
              </div></div>
             
        </div>


      
      </section>

 
      <Footerpage /></>
  )
}

export default Activity

