import React from 'react';
import Nav from '../../HomePage/Nav';
import Footerpage from '../../HomePage/Footerpage';
import { Link } from "react-router-dom";


function Mandatory_Disclouser() {
  return (
    <>
      <Nav />
      <style>
        {
          `

  

.cont{
margin-top:20px;}



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  .alt-two h1 {
    text-align:center;
  }
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }
  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
.listy{
font-size:21px; 
 margin-left:15px;
 margin-top:8px;
// font-weight:700;
 list-style-type: disc; /* This will add bullets to the list */

  
}

   .llis{
  font-size:17px; 
 margin-left:15px;
 margin-top:18px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



 
  
     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:-18px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 118px;
border-top: 5px solid #FA812F;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}


 



@media only screen and (max-width: 480px){




.h2 title-main{
margin-left:18px}




h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}</style>


      <div className="cont"

      >
        <h2 className="h2 title-main" style={{ color: '#780000' }}>Mandatory Disclosures</h2>
        <hr className="title-hr"></hr>




        <div className="row dirr">

          <br></br>
          {/* <h5 style={{ fontFamily: "'poppins',sansSerif", textAlign: 'center' ,color:'#FA812F'}}>Academic Calendar for the Year 2024-25
        </h5> */}

          <div className="col-lg-9 padding-section">





            <ul className='list-icon' style={{ marginTop: '50px' }}>

              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700, fontSize: '17px' }}>  <Link to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/SOE_MD2022_23.pdf'>School of Engineering</Link> </span></li>


              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}> Faculty of Pharmacy (College of Pharmacy) </span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>Faculty of Pharmacy (School of Pharmacy)</span></li>

              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>Faculty of Pharmacy (Polytechnic (Pharmacy))</span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>School of Design</span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}> <Link to='https://cdn.sssutms.ac.in/media/static/PDF/AcadmicPDF/SOCA_MD2022_23.pdf'>School of Computer Application</Link></span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>School of Hotel Management</span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>School of Management Studies</span></li>
              <li className='llis'>

                <i class="fa fa-share" aria-hidden="true"></i>  <span style={{ fontWeight: 700 }}>Polytechnic Engineering</span></li>




            </ul>

            <br></br>





          </div>
          <div className="col-lg-3">

            <ul className='list-icon' >
              <h5 style={{ fontFamily: "'poppins',sansSerif", color: '#243642' }}>Academic</h5>
              <br></br>  <li className='lli'>
                <Link to='/phd-page'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>PHD</Link></li>
              <li className='lli'>
                <Link to='/academic-calender'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Academic Calender</Link></li>
              <li className='lli'>
                <Link to='/scholarship'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Scholarship </Link></li>
              <li className='lli'>
                <Link to='/constituent-units'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>Constituent Units</Link></li>
              <li className='lli'>
                <Link to='/Hei-handbook'>
                  <i class="fa fa-adjust" aria-hidden="true"></i>HEI Handbook </Link></li>
              <li className='lli'>   <Link to='/iqac_cell'>
                <i class="fa fa-adjust" aria-hidden="true"></i>IQAC Cell</Link></li>




              <li className='lli'>   <Link to='/mandatory_disclosures'>
                <i class="fa fa-adjust" aria-hidden="true"></i>Mandatory Disclosures  </Link></li>



            </ul>
          </div>
        </div>







      </div>
      {/* </section> */}
      <Footerpage /></>
  )
}

export default Mandatory_Disclouser