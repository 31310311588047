import React from "react";
import AdminDashboard from "./Admin_Dashboard/AdminDashboard";

function UploadSubjectMasterFromExcelData() {
  return (
    <>
      <AdminDashboard/>
      <h1 style={{ marginTop: "10%", marginLeft: "50%" }}>
        {" "}
        UploadSubjectMasterFromExcelData
      </h1>
    </>
  );
}

export default UploadSubjectMasterFromExcelData;
