import React from "react";
import TableContainer from "@mui/material/TableContainer";
import AdminDashboard from "./AdminDashboard.jsx";
import Paper from "@mui/material/Paper";

import {
  TableCell,
  Table,
  TableBody,
  Box,
  TableHead,
  TableRow,
  TablePagination,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "react-bootstrap";
import logo from "../../../AdminComponent/12.png";
const theme = createTheme();

const Paymen = ({ userData }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  // Logic for paginated data
  const paginatedData = userData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generatePDF = () => {
    const pdf = new jsPDF();

    pdf.setFillColor(255, 255, 255); 
    pdf.rect(0, 0, 210, 297, "F"); 

    pdf.addImage(logo, "JPEG", 3, 5, 30, 28);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(16);
    pdf.setTextColor(0, 0, 0);
    pdf.text("SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL ", 118, 10, {
      align: "center",
    });
    pdf.text("SCIENCES SEHORE", 118, 17, { align: "center" });
    pdf.setFontSize(13);
    pdf.text(
      "[Established Under Govt. of (M.P.) & Registered UGC 2(F), 1956]",
      118,
      24,
      { align: "center" }
    );
    pdf.text(
      "Sh-18 Bhopal-Indore Road, Opposite Oilfed Plant, Pachama,",
      118,
      30,
      { align: "center" }
    );
    pdf.setFontSize(14);
    pdf.setTextColor(255, 0, 0);
   

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0); 
    pdf.setDrawColor(0, 0, 0); // Red color
    pdf.line(10, 45, 200, 45);
    pdf.setFontSize(14);
    pdf.setTextColor(255, 0, 0); // Red color
    pdf.text("Payment Details", 105, 54, { align: "center" });
    pdf.setTextColor(0, 0, 0); // Black color
    pdf.setFontSize(12);
    pdf.text(
      `                                          Student name                :    ${userData[0].name}`,
      10,
      70
    );
    pdf.text(`Father's Name               :   ${userData[0].fathersname}`, 60, 80);
    pdf.text(`Payment Mode              :   ${userData[0].paymentMode}`, 60, 90);
    pdf.text(`Transaction Amount    :   ${userData[0].txnAmount}`, 60, 100);
    pdf.text(`Transaction Date          :   ${userData[0].txnDate}`, 60, 110);
    pdf.text(`Bank Transaction ID    :   ${userData[0].bankTxnId}`, 60, 120);
    pdf.text(`Transaction ID              :   ${userData[0].txnId}`, 60, 130);
    pdf.text(`Course Name                :   ${userData[0].courseName}`, 60, 140);
    pdf.text(`Course Branch              :   ${userData[0].courseBranch}`, 60, 150);
    pdf.save(`${userData[0].name} Fee Receipt`);
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        <AdminDashboard />
        <>
          <Box
            sx={{
              width: "90%",
              marginLeft: "100px",
              marginTop: "90px",
              overflow: "hidden",
            }}
          >
            <Paper sx={{ width: "100%", marginBottom: "0px" }}>
              <TableContainer sx={{ maxHeight: "420px" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "2",
                          minWidth: "30px",
                          top: 0,
                          left: 0,
                        }}
                      >
                        S.No.
                      </TableCell>{" "}
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          minWidth: "180px",
                          position: "sticky",
                          zIndex: "2",
                          top: 0,
                          left: 65,
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          minWidth: "250px",
                          position: "sticky",
                          zIndex: "2",
                          top: 0,
                          left: 220, // Adjust the padding to reduce height
                          height: "40px", // Set a fixed height
                        }}
                      >
                        orderId
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          minWidth: "350px",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                        }}
                      >
                        txnId
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          minWidth: "150px",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                        }}
                      >
                        bank TxnId
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                        }}
                      >
                        Mode
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "130px",
                        }}
                      >
                        Txn Date
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "100px",
                        }}
                      >
                        Txn Amount
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "150px",
                        }}
                      >
                        Id
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "130px",
                        }}
                      >
                        Contact
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "150px",
                        }}
                      >
                        Father's Name
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          backgroundColor: "#001d3d",
                          color: "white",
                          position: "sticky",
                          zIndex: "1",
                          top: 0, // Adjust the padding to reduce height
                          height: "40px",
                          minWidth: "150px",
                        }}
                      >
                        Mother's Name
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedData.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell
                          align="left"
                          style={{
                            zIndex: "1",
                            backgroundColor: "white",
                            top: 0,
                            left: 0,
                            position: "sticky",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#bc6c25",
                            fontWeight: 700,
                            zIndex: "1",
                            backgroundColor: "white",
                            top: 0,
                            left: 60,
                            position: "sticky",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            color: "#134074",
                            fontWeight: 700,
                            zIndex: "1",
                            backgroundColor: "white",
                            top: 0,
                            left: 220,
                            position: "sticky",
                          }}
                        >
                          {row.orderId}
                        </TableCell>
                        <TableCell align="left">{row.txnId}</TableCell>
                        <TableCell
                          align="left"
                          style={{ color: "#6a994e", fontWeight: 700 }}
                        >
                          {row.bankTxnId}
                        </TableCell>
                        <TableCell align="left">{row.paymentMode}</TableCell>
                        <TableCell align="left">{row.txnDate}</TableCell>
                        <TableCell
                          align="left"
                          style={{ fontWeight: 700, color: "#9d0208" }}
                        >
                          {row.txnAmount}
                        </TableCell>
                        {/* <TableCell
                                                align="left">{row.responseMessage}</TableCell> */}
                        {/* <TableCell
                                                align="left">{row.status}</TableCell> */}

                        <TableCell align="left">{row.randomId}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left" style={{ fontWeight: 700 }}>
                          {row.mobile}
                        </TableCell>
                        <TableCell align="left">{row.fathersname}</TableCell>
                        <TableCell align="left">{row.mothersname}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={userData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />{" "}
            </Paper>
          </Box>
          <Button
            onClick={generatePDF}
            style={{
              backgroundColor: "#001d3d",
              color: "white",
              marginLeft: "100px",
              width: "110px",
              height: "40px",
              padding: "5px",
            }}
          >
            Download
          </Button>
        </>
      </ThemeProvider>
    </>
  );
};

export default Paymen;
