// import React, { useState  , useEffect} from "react";
// import { FormControl } from "@mui/material";
// import { Button, Row, Col, Container } from "react-bootstrap";
// import SearchVer from "./Masters/SearchVer";

// import AdminDashboard from "./Masters/Admin_Dashboard/AdminDashboard";
// import axios from "axios";



// function AdminVer_Search() {

//   const [session, setSession] = useState('');
//   const [courseType, setCourseType] = useState('');
//   const [course, setCourse] = useState('');
//   const [branch, setBranch] = useState('');
//   const [college, setCollege] = useState('');
//   const [showTable, setShowTable] = useState(false)
//   const [data, setData] = useState([]);

//   const handleSearch = () => {
//     if(!session || !courseType || !course || !branch ){
//       alert("please fill all fields first")
//       return;
//     }
//     setShowTable(true);
//   };
//   console.log(showTable , "show table")

//   useEffect(() => {
//     axios.get("https://sssutms.ac.in/apitest/v2/admin/courses2")
//       .then(response => {
//         setData(response.data);
//       })
//       .catch(error => {
//         console.error("Error fetching data:", error);
//       });
//   }, []);



//   const getCourseTypes = () => {
//     const types = new Set(data.map(item => item.courseType));
//     return Array.from(types);
//   };

//   const getCourses = () => {
//     if (!courseType) return [];
//     const courses = data
//       .filter(item => item.courseType === courseType)
//       .flatMap(item => item.courseNames.map(course => course.courseName));
//     return courses;
//   };

//   const getBranches = () => {
//     if (!course) return [];
//     const branches = data
//       .flatMap(item => item.courseNames)
//       .filter(courseItem => courseItem.courseName === course)
//       .flatMap(courseItem => courseItem.branches.map(branch => branch.branchName));
//     return branches;
//   };

//   console.log(showTable, "showwwwwww")

//   return (
//     <>
//       <AdminDashboard />
      
//       <style>
//         {`
//       .shadow-box {
//         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
//         border-radius: 10px;
//         padding: 10px;
//         margin-top: 20px;
//         width: 100%; /* Adjusted width */
//         margin-left:75px;
//         height:80%;
//       }

//       .header {
//         height: 50px;
//         background: linear-gradient(#004e92, #990033);
//         border-radius: 10px;
//         color: white;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         font-size: 20px;
//         font-family: italic;
//       }

//       .scroll-container {
//         overflow-y: auto;
//         margin-top: 10px;
//         height: 458px; /* Adjusted height */
//       }

//       .login-input {
//         width: 100%;
//         border: none;
//         border-bottom: 2px solid rgba(0, 0, 0, 0.2);
//         background: transparent;
//         font-size: 1rem;
//         font-family: 'Roboto', sans-serif;
//         font-weight: 500;
//         transition: border-bottom 0.2s;
//       }

//       .login-input:focus {
//         outline: none;
//         border-bottom: 2px solid #344e41;
//       }

//       @media (max-width: 768px) {
//         .shadow-box {
//           padding: 10px;
//           margin-top: 10px;
//         }

//         .header {
//           font-size: 16px;
//           height: 40px;
//         }

//         .login-input {
//           font-size: 0.9rem;
//         }
//       }
      
//       @media (max-width: 576px) {
//         .shadow-box {
//           margin: 10px;
//           margin-Left:76px;
//         }

//         .container-fluid {
//           width: 100%;
//           padding-right: 0;
//           padding-left: 0;
//         }
//       }

//       @media (min-width: 992px) { 
//         .custom-laptop-style {
//           margin-left: 5%;
//         }
//       }
//     `}
//       </style>

//       <div style={{ display: "flex" }}>
//         <div style={{ width: '25%',height:"400px" }}>
//         <Container fluid style={{ paddingTop: '60px' }}>
//           <Row>
//             <Col>
//               <div className="shadow-box">
//                 <div className="header">Approved STUDENT </div>
//                 <div className="scroll-container">
//                   <form style={{ padding: "30px" }}>
//                     <Row>
//                       <FormControl>
//                         <label>
//                           <h1
//                             style={{
//                               color: "#264653",
//                               fontSize: "18px",
//                               fontWeight: "bold",
//                               fontFamily: "sans-serif",
//                             }}
//                           >
//                             Session *
//                           </h1>
//                         </label>
//                         <select
//                           className="login-input"
//                           required={true}
//                           onChange={(e) => setSession(e.target.value)}
//                           autoFocus={true}
//                         >
//                           <option value="option1"> Select</option>
//                           <option value="2023">2023</option>
//                           <option value="2024">2024</option>
//                         </select>
//                       </FormControl>
//                     </Row>

//                     <br />
//                     <Row>
//                       <FormControl>
//                         <label>
//                           <h1
//                             style={{
//                               color: "#264653",
//                               fontSize: "18px",
//                               fontWeight: "bold",
//                               fontFamily: "sans-serif",
//                             }}
//                           >
//                             Course Type *
//                           </h1>
//                         </label>
//                         <select
//                           className="login-input"
//                           required={true}
//                           onChange={(e) => setCourseType(e.target.value)}
//                           autoFocus={true}
//                         >
//                           <option value="option1"> Select</option>
//                           {getCourseTypes().map((type, index) => (
//                             <option key={index} value={type}>{type}</option>
//                           ))}
//                         </select>
//                       </FormControl>
//                     </Row>

//                     <br />
//                     <Row>
//                       <FormControl>
//                         <label>
//                           <h1
//                             style={{
//                               color: "#264653",
//                               fontSize: "18px",
//                               fontWeight: "bold",
//                               fontFamily: "sans-serif",
//                             }}
//                           >
//                             Course *
//                           </h1>
//                         </label>
//                         <select
//                           className="login-input"
//                           required={true}
//                           onChange={(e) => setCourse(e.target.value)}
//                           autoFocus={true}
//                         >
//                           <option value="option1"> Select</option>
//                           {getCourses().map((courseName, index) => (
//                             <option key={index} value={courseName}>{courseName}</option>
//                           ))}
//                         </select>
//                       </FormControl>
//                     </Row>

//                     <br />
//                     <Row>
//                       <FormControl>
//                         <label>
//                           <h1
//                             style={{
//                               color: "#264653",
//                               fontSize: "18px",
//                               fontWeight: "bold",
//                               fontFamily: "sans-serif",
//                             }}
//                           >
//                             Branch *
//                           </h1>
//                         </label>
//                         <select
//                           className="login-input"
//                           required={true}
//                           onChange={(e) => setBranch(e.target.value)}
//                           autoFocus={true}
//                         >
//                           <option value="option1"> Select</option>
//                           {getBranches().map((branchName, index) => (
//                             <option key={index} value={branchName}>{branchName}</option>
//                           ))}
//                         </select>
//                       </FormControl>
//                     </Row>  
//                     <br />
//                     <Row>
//                       <Button
//                         style={{
//                           marginLeft: "50px",
//                           backgroundColor: "#004e92",
//                           width: "110px",
//                           height: "40px",
//                           padding: "5px"
//                         }}
//                         onClick={handleSearch}
//                       >
//                         Search
//                       </Button>
//                     </Row>
//                   </form>
//                 </div>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//         </div>
//         <div style={{ width: '83%' }}>

//           {showTable && (
//             <div>

//             <SearchVer
//              session={session}
//               courseType={courseType}
//               courseName={course}
//               branch={branch}
//               college={college}
//             />
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// }
// export default AdminVer_Search;
  

import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container } from "react-bootstrap";
import AdminDashboard from "./Masters/Admin_Dashboard/AdminDashboard";
import axios from "axios";
import SearchVer from "./Masters/SearchVer";

const AdminVerified_Std_Search = () => {
  const [session, setSession] = useState("");
  const [courseType, setCourseType] = useState("");
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [college, setCollege] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [data, setData] = useState([]);

  const handleSearch = () => {
    if (!session || !courseType || !course || !branch) {
      alert("please fill all fields first");
      return;
    }
    setShowTable(true);
  };


  useEffect(() => {
    axios
      .get("https://sssutms.ac.in/apitest/v2/admin/courses2")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getCourseTypes = () => {
    const types = new Set(data.map((item) => item.courseType));
    return Array.from(types);
  };

  const getCourses = () => {
    if (!courseType) return [];
    const courses = data
      .filter((item) => item.courseType === courseType)
      .flatMap((item) => item.courseNames.map((course) => course.courseName));
    return courses;
  };

  const getBranches = () => {
    if (!course) return [];
    const branches = data
      .flatMap((item) => item.courseNames)
      .filter((courseItem) => courseItem.courseName === course)
      .flatMap((courseItem) =>
        courseItem.branches.map((branch) => branch.branchName)
      );
    return branches;
  };



  return (
    <>
      <AdminDashboard />
      <style>
        {`

      .custom-dropdown select {
     font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: black; /* default color for the select element */
  }

  .custom-dropdown option {
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-weight:700
  }
             .shadow-box {
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
         border-radius: 10px;
         padding: 20px;
         margin-top: 70px;
         width: 100%; /* Adjusted width */
         margin-left:130px;
         height:170px;
         width:1180px
        
      }
         
  
            .header {
        height: 50px;
        // background: linear-gradient(#004e92, #990033);
        border-radius: 10px;
        color: #003049;
        font-weight:700;
        display: flex;
        margin-top:-50px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        
      }`}
      </style>

      <div
        className="shadow-box"
        fluid
        style={{ paddingTop: "50px", height: "170px" }}
      >
        <div className="header">VERIFIED STUDENT </div>

        <Row style={{ marginTop: "10px" }}>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setSession(e.target.value)}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Session</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourseType(e.target.value)}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Course Type</option>
                {getCourseTypes().map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setCourse(e.target.value)}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Course</option>
                {getCourses().map((courseName, index) => (
                  <option key={index} value={courseName}>
                    {courseName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
          <Col>
            <div className="custom-dropdown">
              <select
                className="form-select"
                required={true}
                onChange={(e) => setBranch(e.target.value)}
                autoFocus={true}
                style={{ width: "250px", height: "45px" }}
              >
                <option selected>Branch</option>
                {getBranches().map((branchName, index) => (
                  <option key={index} value={branchName}>
                    {branchName}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          <Button
            style={{
              marginLeft: "50px",
              backgroundColor: "#001d3d",
              width: "110px",
              marginTop: "10px",
              height: "40px",
              padding: "5px",
            }}
            onClick={handleSearch}
          >
            Search
          </Button>
        </Row>
      </div>

      <div style={{marginTop:'-50px',width:'95%',marginLeft:'30px'}}>
        {showTable && (
          <div>
            <SearchVer
              session={session}
              courseType={courseType}
              courseName={course}
              branch={branch}
              college={college}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AdminVerified_Std_Search;

///////////////////////////////////SearchVer component/////



