import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Box,
  CardContent,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
} from "@mui/material";
import { Form, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import sssutms from "../../../Main_Admin/images/sssutms.jpg";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import StudentDashboard from "../../../AdminComponent/StudentDashboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import OnlyHeader from "../../../AdminComponent/OnlyHeader";

const theme = createTheme({
  typography: {
    fontWeightBold: 700,
  },
});

function Std_ExamForm() {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [examForms, setExamForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ischecked, setIschecked] = useState(false);

  const [formData, setFormData] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("currentUser"));

  const handleCheckbox = (e) => {
    setIschecked(e.target.checked);
  };

  useEffect(() => {
    const fetchExamForms = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/getExamForms?studentId=${userData._id}`
        );
        const result = await response.json();
        if (response.ok) {
          setExamForms(result.data);
        } else {
          console.error(result.message);
        }
      } catch (error) {
        console.error("Error fetching exam forms:", error);
      } finally {
        setLoading(false);
      }
    };

    console.log(examForms.isFilled, "data22");
    const ApiUrl =
      examForms.isFilled && examForms.isPaid
        ? `https://sssutms.ac.in/apitest/v1/student/exam-form?courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`
        : `https://sssutms.ac.in/apitest/v1/student/exam-form2?courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}&id=${userData._id}`;

    const fetchSubjects = async () => {
      try {
        const response = await fetch(
          `https://sssutms.ac.in/apitest/v1/student/exam-form?courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`
        );
        const result = await response.json();

        console.log(result);

        if (response.ok) {
          setFormData(result);
        } else {
          console.log(result.message);
        }
      } catch (error) {
        console.log("Error fetching subjects:", error);
      }
    };

    // const fetchData = async () => {
    //   try {
    //     const response = await fetch(`https://sssutms.ac.in/apitest/v1/student/exam-form?courseName=${userData.courseName}&courseBranch=${userData.courseBranch}&semesterNumber=${userData.currentSemester}`); // Replace with your actual API endpoint
    //     const data = await response.json();
    //     console.log(data , "console")
    //     setFormData(data);
    //   } catch (error) {
    //     console.error('Error fetching data:', error);
    //   }
    // };
    //  fetchData();

    fetchExamForms();
    fetchSubjects();
  }, []);

  console.log(formData, " this data ");

  const getSortedAndSlicedData = () => {
    const sortedData = [...examForms].sort((a, b) => {
      if (sortBy === "name") {
        return sortOrder === "asc"
          ? a.branchName.localeCompare(b.branchName)
          : b.branchName.localeCompare(a.branchName);
      }
      return 0;
    });
    const startIndex = page * rowsPerPage;
    return sortedData.slice(startIndex, startIndex + rowsPerPage);
  };

  const [show, setShow] = useState(false);

  // Handlers for Modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Handlers for Modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const generatePDF = () => {
    const doc = new jsPDF();

    doc.addImage(sssutms, "PNG", 10, 10, 20, 20);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(11);
    doc.text(
      "SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES, SEHORE",
      35,
      15
    );
    doc.setFontSize(11);
    doc.setFont("helvetica");
    doc.text(`                            ${userData?.courseName}`, 35, 20);
    doc.text(
      `                                     (${userData?.courseBranch})`,
      35,
      25
    );
    doc.text(
      `                                                  Session: ${examForms[0]?.examFormId?.sessionName}`,
      36,
      30
    );

    // Candidate Info
    const infoStartY = 40;
    const candidateDetails = [
      ["Enrollment No.", "241440025007"],
      ["Semester/Year", "1"],
      ["Candidate's Name", "TEST"],
      ["Father's Name", "something"],
      ["Mother's Name", "FF"],
      ["Status", "Regular"],
    ];
    candidateDetails.forEach((item, index) => {
      doc.text(`${item[0]}:`, 10, infoStartY + index * 6);
      doc.text(item[1], 60, infoStartY + index * 6);
    });

    // Subjects Table
    const subjects = [
      ["Sr.No", "Subject Code", "Subject Name", "Appearing In"],
      ["1", "BAMS-101", "Paper1:KRIYA SHARIRA", "All"],
      ["2", "BAMS-101", "Paper2:KRIYA SHARIRA", "All"],
      ["3", "BAMS-102", "Paper1:RACHNA SHARIRA", "All"],
      ["4", "BAMS-102", "Paper2:RACHNA SHARIRA", "All"],
    ];
    doc.autoTable({
      startY: infoStartY + candidateDetails.length * 6 + 10,
      head: [subjects[0]],
      body: subjects.slice(1),
      styles: { fontSize: 10, cellPadding: 3 },
      theme: "grid",
    });


    const declarationStartY = doc.lastAutoTable.finalY + 10;
    const declaration = [
      "I certify that this application has been filled by me and the information given their in is correct and I shall be",
      "provisionally responsible for the same proved false later on.",

      "I also certify that I am appearing as a regular candidate only in the examination being conducted by Sri ",
      "Satya Sai University of Technology and Medical Sciences, Sehore.",

      "I further declare that I am neither a regular student of any other Education Institution nor appearing in any",
      " other examination as a regular candidate.",

      "If the information furnished above is false, my examination result will be cancelled.",

      "I assure that I will complete the required attendance and sessional work prescribed for the course of",

      "my registration. Kindly permit me to appear in this examination.",

      "I accept to abide by all the rules and regulations of study and examination as prescribed by the",

      "institution & Sri Satya Sai University of Technology and Medical Sciences, Sehore.",

      "I have read the relevant ordinance applicable to me and have completed all the requirements as",
      "given in it. I have completed my studies and have no objection in appearing in the examination on",
      "the date declared by the university.",

      "I also request that without my written consent, do not disclose my result to anybody in any manner",
      "including RTI.",
    ];
    doc.setFont("helvetica", "normal");
    declaration.forEach((line, index) => {
      doc.text(`• ${line}`, 9, declarationStartY + index * 5);
    });

    // Examination Fee Details
    const feeDetailsStartY = declarationStartY + declaration.length * 5 + 10;
    doc.setFont("helvetica", "bold");
    doc.text("EXAMINATION FEE DETAILS", 15, feeDetailsStartY);
    doc.setFont("helvetica", "normal");
    doc.text(
      "Transaction No:- 348622023220112725dcd4, Paid On:- 20/02/2023 11:27:52, Exam Fee:- 1830",
      10,
      feeDetailsStartY + 5
    );

    // Save PDF
    doc.save("ExaminationForm.pdf");
  };

  console.log(examForms[0]?.examFormId, "this is exam data");

  function isDate(val) {
    // Cross realm comptatible
    return Object.prototype.toString.call(val) === "[object Date]";
  }

  function isObj(val) {
    return typeof val === "object";
  }

  function stringifyValue(val) {
    if (isObj(val) && !isDate(val)) {
      return JSON.stringify(val);
    } else {
      return val;
    }
  }
  function buildForm({ action, params }) {
    const form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", action);

    Object.keys(params)?.forEach((key) => {
      const input = document.createElement("input");
      input.setAttribute("type", "hidden");
      input.setAttribute("name", key);
      input.setAttribute("value", stringifyValue(params[key]));
      form.appendChild(input);
    });
    return form;
  }
  function post(details) {
    const form = buildForm(details);
    document.body.appendChild(form);
    setLoading(false);
    form.submit();
    form.remove();
  }

  const getData = (data) => {
    setLoading(false);
    return fetch("http://localhost:7786/apitest/exam-form/payment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .catch((err) => console.log(err));
  };

  const id = userData.randomId;
  const id2 = userData._id;
  const name = userData.name;
  const email = userData.email;
  const course = userData.courseName;

  const examId = examForms[0]?.examFormId._id;

  console.log(examId, "examId");

  const handlePayment = (e) => {
    <OnlyHeader />;

    setLoading(true);
    setTimeout(() => {
      getData({ amount: 1, id, id2, name, course, examId }).then((response) => {
        var information = {
          action: "https://securegw.paytm.in/order/process",
          params: response,
        };
        console.log(response);
        post(information);
      });
    }, 1000);
  };
  return (
    <ThemeProvider theme={theme}>
      <StudentDashboard />
      <>
        <Box sx={{ width: "90%", marginLeft: "80px", marginTop: "50px" }}>
          {loading ? (
            <CircularProgress
              color="success"
              style={{ marginTop: "80px", marginLeft: "50%" }}
            />
          ) : (
            <CardContent>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ justifyContent: "center", textAlign: "center" }}
              >
                Examination Form
              </Typography>
              <Paper sx={{ width: "105%", overflow: "auto" }}>
                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    Enrollment No: {userData?.enrollmentNumber}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    Candidate Name: {userData?.name}
                  </Typography>
                  <Typography
                    variant="button"
                    gutterBottom
                    sx={{ display: "block" }}
                  >
                    Father Name: {userData?.fathersname}
                  </Typography>
                </Box>

                <TableContainer sx={{ maxHeight: 450 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            left: 0,
                            color: "white",
                          }}
                        >
                          S.No.
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 2,
                            minWidth: "100px",
                            left: 50,
                            color: "white",
                          }}
                        >
                          Session
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Session Name
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            color: "white",
                            minWidth: "70px",
                          }}
                        >
                          Semster/Year
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            minWidth: "100px",
                            zIndex: 1,
                            color: "white",
                          }}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "200px",
                            color: "white",
                          }}
                        >
                          Form Status Account
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{
                            backgroundColor: "rgb(2, 48, 71)",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                            minWidth: "150px",
                            color: "white",
                          }}
                        >
                          Click to show
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getSortedAndSlicedData().map((form, index) => (
                        <TableRow key={index}>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              top: 0,
                              left: 0,
                              zIndex: 1,
                              backgroundColor: "white",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              position: "sticky",
                              left: 50,
                              top: 0,
                              fontWeight: 700,
                              zIndex: 1,

                              backgroundColor: "white",
                              color: "red",
                            }}
                          >
                            {form.examFormId.session || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.examFormId.sessionName || "N/A"}
                          </TableCell>
                          <TableCell align="left">
                            {form.examFormId.semester || "N/A"}
                          </TableCell>

                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: "bold",
                              position: "sticky",
                              left: 210,
                              backgroundColor: "white",
                            }}
                          >
                            {form.examFormId.category || "N/A"}
                          </TableCell>

                          <TableCell align="left">
                            {form.isForwarded === true
                              ? "Forwarded"
                              : "Form Not Forwarded"}
                          </TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (!form.isForwarded) {
                                // Show SweetAlert if form is not forwarded
                                swal({
                                  icon: "warning",
                                  title: "Form Not Forwarded",
                                  text: "Your form has not been forwarded. Please contact the administration.",
                                  buttons: "OK",
                                });
                              } else if (form.isFilled && form.isPaid) {
                                // Handle Print Exam Form
                                handleShow1();
                              } else {
                                // Handle Show Form
                                handleShow();
                              }
                            }}
                          >
                            {form.isFilled && form.isPaid
                              ? "Print Exam Form"
                              : "Show Form"}
                          </Button>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </CardContent>
          )}

          <br></br>
        </Box>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          centered
          style={{ marginTop: "40px" }}
        >
          <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
            <Modal.Title
              variant="button"
              style={{
                height: "8px",
                marginTop: "2px",
                fontFamily: "Poppins,sans-serif",
              }}
            >
              Examination Form
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div id="exam-form" style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={sssutms}
                  alt="Logo"
                  style={{ width: "100px", marginRight: "20px" }}
                />
                <div>
                  <Typography
                    style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
                  >
                    SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
                    SEHORE
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    ( {userData.courseBranch})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    Session: {examForms[0]?.examFormId?.sessionName}
                  </Typography>
                </div>
              </div>

              <Form>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Enrollment No: </Form.Label>
                    <span> {userData?.enrollmentNumber}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Semester/Year: </Form.Label>
                    <span> {examForms[0]?.examFormId?.semester}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Candidate's Name: </Form.Label>
                    <span> {userData?.name}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Father's Name:</Form.Label>
                    <span> {userData?.fathersname}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Mother's Name: </Form.Label>
                    <span> {userData?.mothersname}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status: </Form.Label>
                    <span> {examForms[0]?.examFormId?.category}</span>
                  </Form.Group>
                </Row>

                <Table striped bordered hover>
                  <TableHead
                    style={{
                      backgroundColor: "rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableRow
                      sx={{
                        height: "30px",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Code
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Appearing In
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {formData?.subjects.map((subject, index) => (
                      <TableRow key={index} sx={{ height: "30px" }}>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectCode}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectName}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          All
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <div style={{ marginTop: "20px" }}>
                  <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
                  <hr
                    style={{
                      width: "40%",
                      height: "2px",
                      backgroundColor: "gray",
                      margin: "-2px 0",
                    }}
                  />
                  <Typography variant="body2" gutterBottom>
                    <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <li>
                        I certify that this application has been filled by me
                        and the information given their in is correct and I
                        shall be provisionally responsible for the same proved
                        false later on.
                      </li>
                      <li>
                        I also certify that i am apperaing as regularcandidate
                        only in the examination being conducted by the Sri Satya
                        Sai University of Technology and Medical Sciences,
                        Sehore.
                      </li>
                      <li>
                        I further d eclare that i am neither a regular student
                        of any other Education Instituation on apperaing in any
                        other examination as regular candidate.
                      </li>
                      <li>
                        I understand that if it is found later on that the
                        information furnished above is false then my result of
                        examination will bw cancelled
                      </li>
                      <li>
                        I assure that I will complete the require attendence and
                        sessional work prescribed for thye course of my
                        registration. Kindly permit me to appear in this
                        examination.
                      </li>
                      <li>
                        I accept to abide by all the rules and regulations of
                        study and examination as prescribed by the institution &
                        Sri Satya Sai University of Technology and Medical
                        Sciences, Sehore
                      </li>
                      <li>
                        I have read relevant ordinance applicable to be and have
                        completed all the requirements as given in it. I have
                        completed my studies and have no objection in appearing
                        in examination on the date declared by the university
                      </li>
                      <li>
                        <b>
                          I also requesting to the University that without my
                          written concent, do not disclose my result to anybody
                          in any manner including RTI.
                        </b>
                      </li>
                    </ul>
                    <Checkbox checked={ischecked} onChange={handleCheckbox} /> I
                    accept all above points.
                  </Typography>
                </div>
              </Form>
            </div>
          </Modal.Body>

          {/* <div
            style={{
              padding: "15px 20px",
              marginTop: "-4%",
              backgroundColor: "#f9f9f9", // Light background
              border: "1px solid #ddd", // Border around the box
              borderRadius: "8px", // Rounded corners
            }}
          >
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              EXAMINATION FEE DETAILS
            </Typography>
            <hr
              style={{
                width: "30%",
                height: "2px",
                backgroundColor: "#333",
                margin: "-2px 0 10px 0",
              }}
            />
            <Typography variant="subtitle2" gutterBottom>
              Transaction No:- 348622023220112725dcd4, Paid On:- 20/02/2023
              11:27:52, Exam Fee:- 1830
            </Typography>
          </div> */}

          {/* {ischecked && (
            <div
              style={{
                padding: "15px 20px",
                marginTop: "-4%",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Total Fees to Pay ₹ 350 Only{" "}
                <Button
                  style={{ backgroundColor: "rgb(2,48,71)", color: "white" }}
                  onClick={() => handlePayment()}
                >
                  PAY NOW
                </Button>
              </Typography>
            </div>
          )} */}
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Close
            </Button>
            <Button variant="outlined" onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={show1}
          onHide={handleClose1}
          size="lg"
          centered
          style={{ marginTop: "40px" }}
        >
          <Modal.Header closeButton style={{ color: "rgb(2, 48, 71)" }}>
            <Modal.Title
              variant="button"
              style={{
                height: "8px",
                marginTop: "2px",
                fontFamily: "Poppins,sans-serif",
              }}
            >
              Examination Form
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div id="exam-form" style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={sssutms}
                  alt="Logo"
                  style={{ width: "100px", marginRight: "20px" }}
                />
                <div>
                  <Typography
                    style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }}
                  >
                    SRI SATYA SAI UNIVERSITY OF TECHNOLOGY AND MEDICAL SCIENCES,
                    SEHORE
                  </Typography>

                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    ( {userData.courseBranch})
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ margin: 0, textAlign: "center" }}
                  >
                    Session: {examForms[0]?.examFormId?.sessionName}
                  </Typography>
                </div>
              </div>

              <Form>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Enrollment No: </Form.Label>
                    <span> {userData?.enrollmentNumber}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Semester/Year: </Form.Label>
                    <span> {examForms[0]?.examFormId?.semester}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label>Candidate's Name: </Form.Label>
                    <span> {userData?.name}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Father's Name:</Form.Label>
                    <span> {userData?.fathersname}</span>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label>Mother's Name: </Form.Label>
                    <span> {userData?.mothersname}</span>
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label>Status: </Form.Label>
                    <span> {examForms[0]?.examFormId?.category}</span>
                  </Form.Group>
                </Row>

                <Table striped bordered hover>
                  <TableHead
                    style={{
                      backgroundColor: "rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableRow
                      sx={{
                        height: "30px",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Sr.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Code
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Subject Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "4px 8px",
                          fontSize: "0.9rem",
                        }}
                      >
                        Appearing In
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {formData?.subjects.map((subject, index) => (
                      <TableRow key={index} sx={{ height: "30px" }}>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectCode}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          {subject.subjectName}
                        </TableCell>
                        <TableCell
                          sx={{ padding: "4px 8px", fontSize: "0.85rem" }}
                        >
                          All
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <div style={{ marginTop: "20px" }}>
                  <Typography variant="h6">CANDIDATE'S DESCRIPTION</Typography>
                  <hr
                    style={{
                      width: "40%",
                      height: "2px",
                      backgroundColor: "gray",
                      margin: "-2px 0",
                    }}
                  />
                  <Typography variant="body2" gutterBottom>
                    <ul style={{ marginLeft: "20px", marginTop: "10px" }}>
                      <li>
                        I certify that this application has been filled by me
                        and the information given their in is correct and I
                        shall be provisionally responsible for the same proved
                        false later on.
                      </li>
                      <li>
                        I also certify that i am apperaing as regularcandidate
                        only in the examination being conducted by the Sri Satya
                        Sai University of Technology and Medical Sciences,
                        Sehore.
                      </li>
                      <li>
                        I further d eclare that i am neither a regular student
                        of any other Education Instituation on apperaing in any
                        other examination as regular candidate.
                      </li>
                      <li>
                        I understand that if it is found later on that the
                        information furnished above is false then my result of
                        examination will bw cancelled
                      </li>
                      <li>
                        I assure that I will complete the require attendence and
                        sessional work prescribed for thye course of my
                        registration. Kindly permit me to appear in this
                        examination.
                      </li>
                      <li>
                        I accept to abide by all the rules and regulations of
                        study and examination as prescribed by the institution &
                        Sri Satya Sai University of Technology and Medical
                        Sciences, Sehore
                      </li>
                      <li>
                        I have read relevant ordinance applicable to be and have
                        completed all the requirements as given in it. I have
                        completed my studies and have no objection in appearing
                        in examination on the date declared by the university
                      </li>
                      <li>
                        <b>
                          I also requesting to the University that without my
                          written concent, do not disclose my result to anybody
                          in any manner including RTI.
                        </b>
                      </li>
                    </ul>
                  </Typography>
                </div>
              </Form>
            </div>
          </Modal.Body>

          <div
            style={{
              padding: "15px 20px",
              marginTop: "-4%",
              backgroundColor: "#f9f9f9", // Light background
              border: "1px solid #ddd", // Border around the box
              borderRadius: "8px", // Rounded corners
            }}
          >
            <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
              EXAMINATION FEE DETAILS
            </Typography>
            <hr
              style={{
                width: "30%",
                height: "2px",
                backgroundColor: "#333",
                margin: "-2px 0 10px 0",
              }}
            />
            <Typography variant="subtitle2" gutterBottom>
              Transaction No:- 348622023220112725dcd4, Paid On:- 20/02/2023
              11:27:52, Exam Fee:- 1830
            </Typography>
          </div>

          {ischecked && (
            <div
              style={{
                padding: "15px 20px",
                marginTop: "-4%",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" gutterBottom>
                Total Fees to Pay ₹ 350 Only{" "}
                <Button
                  style={{ backgroundColor: "rgb(2,48,71)", color: "white" }}
                  onClick={() => handlePayment()}
                >
                  PAY NOW
                </Button>
              </Typography>
            </div>
          )}
          <Modal.Footer>
            <Button variant="text" onClick={handleClose}>
              Close
            </Button>
            <Button variant="outlined" onClick={generatePDF}>
              Download as PDF
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </ThemeProvider>
  );
}
export default Std_ExamForm;
