import React from "react";
import PosI2 from "../images/Event1.png";
import PosI from "../images/Event5.png";
import event8 from "../images/Event8.png";
import EVENT6 from "../images/Event6.png";
import web from "../images/bg.webp";

const Gallery = () => {
  return (
    <>
      <style>
        {`


           .one h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
  }
  .one h1:before {
    width: 28px;
    height: 5px;
    display: block;
    content: "";
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -14px;
    background-color: #b80000;
  }
  .one h1:after {
    width: 100px;
    height: 1px;
    display: block;
    content: "";
    position: relative;
    margin-top: 25px;
    left: 50%;
    margin-left: -50px;
    background-color: #b80000;
  }

  h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }

  h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
  }
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
 .section4{
    background-attachment:fixed;
    height:610px;
margin-bottom:10px;
margin-top:20px;

    background: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.3)),
    // url("https://images.pexels.com/photos/2096622/pexels-photo-2096622.jpeg?cs=srgb&dl=pexels-timmossholder-2096622.jpg&fm=jpg");
  


  
}


.flex{
margin-top:10px;}

.section4 section{
    transition:0.5s;
}

.section4 figure{
    position:relative;
    overflow:hidden;
    height:40vh;
}

.section4 figure img{
    width:100%;
    height:100%;
    object-fit:cover;
    object-position:center;
}

.section4 section:nth-child(odd) figure{
    margin-top:-2rem;
}

.section4 section:nth-child(even) figure{
    margin-top:8rem;
}

.section4 article{
    position:absolute;
    top:100%;
    background-color:rgba(1,1,1,0.7);
    width:100%;
    height:100%;
    z-index:1;
    transition:0.5s;
}

.section4 article span{
    display:block;
    width:100%;
    height:100%;
    position:absolute;
    left:-100%;
    transition:0.5s;
    transition-delay:0.5s;
}

.section4 figure:hover article{
    top:0%;
}

.section4 figure:hover article span{
    left:0;
}

.section4 h2, .section4 p{
    color:var(--white);
}



@media (max-width:920px){
    .section4{
        padding:0rem !important;
    }
    
    .section4 figure{
        height:30vh;
    }
    
    .section4 section{
        margin:0px 0;
    }
    
    .section4 section:nth-child(odd) figure{
        margin-top:0rem;
    }

    .section4 section:nth-child(even) figure{
        margin-top:0rem;
    }
}


/*Animate up & down*/
@keyframes arrows{
    0%, 100%{
        color: black;
        transform: translateY(0);
    }
    50%{
        color: #3AB493;
        transform: translateY(20px);
    }
}



@media (max-width:480px){

.section4{
margin-top:300px;
margin-bottom:30px;
height:1200px;}


.flex{
margin-top:-20px;}}

`}
      </style>
      <div className="sections section4 padding_1x" id="engagements">
        <div className="one">
          <h1>Recent Happenings</h1>
          <br></br>
        </div>
        <div className="flex">
          <section className="flex-content padding_1x inline-photo show-on-scroll">
            <figure className="fig">
              <img src={PosI} alt="" />
              <article>
                <span className="padding_1x">
                  <p>
                    Hosted a vibrant Rangoli Making Competition, where students
                    showcased their creativity and cultural artistry. The event
                    was a huge success, bringing color and excitement to our
                    campus.
                  </p>
                </span>
              </article>
            </figure>
          </section>
          <section className="flex-content padding_1x inline-photo show-on-scroll">
            <figure>
              <img src={PosI2} alt="" />
              <article>
                <span className="padding_1x">
                  <p>
                    Hosted a vibrant Rangoli Making Competition, where students
                    showcased their creativity and cultural artistry. The event
                    was a huge success, bringing color and excitement to our
                    campus.
                  </p>
                </span>
              </article>
            </figure>
          </section>
          <section className="flex-content padding_1x inline-photo show-on-scroll">
            <figure>
              <img src={event8} alt="" />
              <article>
                <span className="padding_1x">
                  <p>
                    Hosted an engaging webinar, bringing together faculties and
                    students for insightful discussions. The event was a
                    resounding success, fostering knowledge exchange and
                    professional growth.
                  </p>{" "}
                </span>
              </article>
            </figure>
          </section>
          <section className="flex-content padding_1x inline-photo show-on-scroll">
            <figure>
              <img src={EVENT6} alt="" />
              <article>
                <span className="padding_1x">
                  <p>
                    Visited a solar panel facility, exploring real-world
                    applications of renewable energy technologies. The visit was
                    an enriching experience, providing valuable insights and
                    highlighting the importance of sustainable energy solutions.
                  </p>
                </span>
              </article>
            </figure>
          </section>
        </div>
      </div>
    </>
  );
};

export default Gallery;
