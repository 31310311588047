import React from "react";
import Nav from "../../HomePage/Nav";
import Footerpage from "../../HomePage/Footerpage";
import { Link } from "react-router-dom";

function Training_Partner() {
  return (
    <>
      <Nav />
      <style>
        {`



h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 30px;
    color: #243642;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
  }
  
  
  h1 em {
    font-style: normal;
    font-weight: 600;
  }
  .two h1 {
    text-transform: capitalize;
  }
  .two h1:before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 60px;
    height: 2px;
    content: "";
    background-color: #F09319;
  }
  
  
  
   .uul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              list-style-type: square;
              margin-left: 10px;
            }


  .alt-two h1:before {
    left:50%; margin-left:-30px;
  }
  
  
  .lli{
  font-size:14px; 
//   margin-left:5px;

  margin-top:-3px;
   list-style-type: none; /* Removes bullets */}
  
  
  .list-icon li i {
      color: #555555;
      font-size: 14px;
      height: 28px;
      line-height: 28px;
      margin: 5px 10px 5px 0;
      text-align: center;
      width: 28px;
  }



     .list-icon {
          list-style-type: none; /* Removes bullets */
          padding: 0px; 
          margin-top:10px;
          margin-left:50px;
          display: flex;
          flex-direction: column; /* Default to vertical */
      }
  



.title-main{
margin-left:30px;

 font-family: "Poppins", serif;
  font-weight: 700;
  font-style: normal;
  }


.title-hr {
width: 120px;
border-top: 5px solid #FF0000;
margin-left: 30px;
margin-top: -5px;
margin-bottom:2px;
}



@media only screen and (max-width: 480px){



.h2 title-main{
margin-left:20px}

.img-fluid {
  margin-left:80px;
}






h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: #243642;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}


h1 em {
  font-style: normal;
  font-weight: 600;
}
.two h1 {
  text-transform: capitalize;
}
.two h1:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #F09319;
}


.alt-two h1 {
  text-align:center;
}

 .uul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            list-style-type: square;
            margin-left: 10px;
          }
.alt-two h1:before {
  left:50%; 
  margin-left:-30px;
}


.lli{
font-size:15px; 
// margin-left:px;
margin-top:5px;
 list-style-type: none; /* Removes bullets */}


.list-icon li i {
    color: #555555;
    font-size: 14px;
    height: 28px;
    line-height: 28px;
    margin: 5px 10px 5px 0;
    text-align: center;
    width: 28px;
}

   .list-icon {
        list-style-type: none; /* Removes bullets */
        padding: 0; 
        margin: 0;
        display: flex;
        flex-direction: column; /* Default to vertical */
    }


       @media (max-width: 480px) {

  .section_all{
   margin-left:6px;
   padding:5px;}
   
    .list-icon {
    margin-left:-50px;
            flex-direction: row; /* Makes the list horizontal */
            flex-wrap: wrap; /* Allows wrapping if items are too wide */
            // justify-content: space-between; /* Adds spacing between items */
              flex: 1 1 50%; /* Makes each item take about 45% width */
        }
   
   }
    
    `}
      </style>

      <div className="cont">
        <h2
          className="h2 title-main"
          style={{ color: "#780000", marginTop: "10px" }}
        >
          {" "}
          Training & Placement Cell
        </h2>
        <hr className="title-hr"></hr>

        <div className="row dirr">
          <div className="col-lg-8 padding-section">
            <br />
            <style>
              {`
      //   ///////////////////////////////////////////////////////////////////////////////////
      .tann{
      margin-left:120px;
      }
      
      .table {
           border-collapse: collapse;
           width: 80%;
            margin-left:50px;
         }
         
         .table,
         .table th,
         .table td {
           border: 1px solid #ccc;
         }
         
         .table th,
         .table td {
           padding: 0.5rem;
         }
         
         .table th {
           position: relative;
            cursor: grab;
               user-select: none;
         }   
            .table th:active {
               cursor: grabbing;
             }`}
            </style>
            <h5
              style={{
                color: "orange",
                textAlign: "center",
                fontFamily: "'poppins',sansSerif",
                marginBottom: "10px",
              }}
            >
              Training and Placement Cell
            </h5>
            <div className="tann">
              <table
                id="resizeMe"
                className="resizable-table table table-hover"
              >
                <thead>
                  <tr id="header-row">
                    <th className="draggable-table" data-column={0}>
                      No.
                    </th>
                    <th className="draggable-table" data-column={1}>
                      Name
                    </th>
                    <th className="draggable-table" data-column={2}>
                      Designation
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Dr. Rashmi Dwivedi</td>
                    <td> Head</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Dr. Hemant Sharma</td>
                    <td>Member</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Dr. Harsh Lohiya</td>
                    <td> Member</td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      Mr. Pradeep Maheswari<br></br>Member
                    </td>
                    <td> Member</td>
                  </tr>

                  <tr>
                    <td>5</td>
                    <td>Mrs. Priyanka Jhawar</td>
                    <td> Member</td>
                  </tr>

                  <tr>
                    <td>6</td>
                    <td>Dr. Kanchan Shrivastava</td>
                    <td> Member</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-4">
            <ul className="list-icon">
              <h5
                style={{ fontFamily: "'poppins',sansSerif", color: "#243642" }}
              >
                Training & Placement
              </h5>
              <br></br>{" "}
              <li className="lli">
                <Link to="/Academic/TrainingAndPlacementCell">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Training &
                  Placement Cell
                </Link>
              </li>
              <li className="lli">
                <Link to="/Academic/TrainingPartner">
                  <i class="fa fa-adjust" aria-hidden="true"></i>Training
                  Partner
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br></br>
      {/* </section> */}
      <Footerpage />
    </>
  );
}

export default Training_Partner;
